import { Creatings } from '../pages'

const fission = {
  path: '/fission',
  primary: 'app.sider.fission',
  modular: 'market',
  permission: 'fission',
  component: Creatings,
  exact: true
}
export default fission
