import React, { useEffect, useState, useCallback, useMemo } from 'react'
import {
  Select,
  Card,
  Table,
  Button,
  Typography,
  Space,
  DatePicker,
  Drawer,
  Row,
  Col,
  Input
} from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  bigQueryTableColumns,
  bigQueryChildTableColumns,
  bigQueryChildTableColumns2,
  bigQueryChildTableColumns3,
  bigQueryChildTableColumns5
} from '../../config/tableColumns'
import services from '../../services'
import { getStaticText, storage, checkPerms } from '../../utils'
import dateFormat from '../../config/dateFormat'

const dateFormatDay = dateFormat.day
const [perm] = ['big-query/top-list', 'big-query/top-list']
const { Text } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const BigQuery = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  // 默认请求参数
  const defaultReqData = {
    country: 'All_Country',
    pattern: 'All_Pattern',
    deviceType: '',
    start_date: moment().subtract(1, 'days'),
    end_date: moment(),
    current: 1,
    pageSize: 20
  }

  const [tableData, setTableData] = useState([])
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  // 侧边栏状态
  const [drawerState, setDrawerState] = useState({
    visible: false,
    placement: 'right',
    width: '60%'
  })

  const [showTableType, setShowTableType] = useState(0)

  // 侧边栏子表格数据
  const [childTableData, setChildTableData] = useState({
    data: [],
    pageSize: 20,
    current: 1,
    total: 0,
    type: '',
    id: 0,
    loading: false
  })

  // 子表单loading
  const childToggleLoading = () => {
    setChildTableData((prevState) => ({
      ...prevState,
      loading: !prevState.loading
    }))
  }

  // 获取子表单数据
  const fetchChildrenData = (id, type, current = 1) => {
    setDrawerState((prevState) => {
      return { ...prevState, visible: true }
    })

    childToggleLoading()

    services.bigQuery
      .getChildrenData({
        id,
        type,
        pageSize: childTableData.pageSize,
        current
      })
      .then((result) => {
        const data = result.list || []
        setChildTableData((prevState) => ({
          ...prevState,
          data,
          total: result.total,
          type,
          id
        }))
      })
      .finally(() => {
        childToggleLoading()
      })
  }

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const nextSearchObj = () => {
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatDay)
      : null
    const endDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('end_date'), dateFormatDay)
      : null
    return {
      country: nextSearch.get('country'),
      pattern: nextSearch.get('pattern'),
      deviceType: nextSearch.get('deviceType'),
      start_date: startDate,
      end_date: endDate,
      current: nextSearch.get('current'),
      pageSize: nextSearch.get('pageSize')
    }
  }
  Object.keys(nextSearchObj()).forEach((item) => {
    if (nextSearchObj()[item]) defaultReqData[item] = nextSearchObj()[item]
  })

  const [requestParams, setRequestParams] = useState(() => {
    return {
      country: defaultReqData.country
        .split(',')
        .filter((item) => !(item === '')),
      pattern: defaultReqData.pattern
        .split(',')
        .filter((item) => !(item === '')),
      start_date: defaultReqData.start_date,
      deviceType: defaultReqData.deviceType,
      start_format: defaultReqData.start_date.format(dateFormatDay),
      end_date: defaultReqData.end_date,
      end_format: defaultReqData.end_date.format(dateFormatDay),
      current: defaultReqData.current,
      pageSize: defaultReqData.pageSize
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      country: requestParams.country.toString(),
      pattern: requestParams.pattern.toString(),
      deviceType: requestParams.deviceType,
      start_date: requestParams.start_format,
      end_date: requestParams.end_format,
      current: requestParams.current * 1,
      pageSize: requestParams.pageSize * 1
    }
  }, [requestParams])

  const [exportLoading, setExportLoading] = useState(false)

  const [exportId, setExportId] = useState({ id: null, type: '' })
  // 导出
  const handleExport = (id) => {
    setExportLoading(true)
    services.bigQuery
      .exportBigQuery(id)
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
      .finally(() => {
        setExportLoading(false)
      })
  }

  // 是否显示编辑按钮
  if (
    checkPerms(perm) &&
    bigQueryTableColumns[bigQueryTableColumns.length - 1].key !==
      'table.operating'
  ) {
    bigQueryTableColumns.push({
      title: getStaticText('table.operating'),
      key: 'table.operating',
      render: (row) => {
        if (row.pattern === 'affinity') {
          return (
            <>
              <Space>
                <Button
                  type="primary"
                  loading={childTableData.loading}
                  onClick={() => {
                    setShowTableType(1)
                    fetchChildrenData(row.compain_id, 'top')
                    setExportId({ id: row.compain_id, type: 'top' })
                  }}
                >
                  {getStaticText('top-audience')}
                </Button>
                <Button
                  type="primary"
                  loading={childTableData.loading}
                  onClick={() => {
                    setShowTableType(1)
                    fetchChildrenData(row.compain_id, 'potential')
                    setExportId({ id: row.compain_id, type: 'potential' })
                  }}
                >
                  {getStaticText('potential-audience')}
                </Button>
              </Space>
            </>
          )
        }
        if (row.pattern === 'creative') {
          return (
            <>
              <Space>
                <Button
                  type="primary"
                  loading={childTableData.loading}
                  onClick={() => {
                    setShowTableType(2)
                    fetchChildrenData(row.compain_id, 'drainage')
                    setExportId({ id: row.compain_id, type: 'drainage' })
                  }}
                >
                  {getStaticText('drainage')}
                </Button>
                <Button
                  type="primary"
                  loading={childTableData.loading}
                  onClick={() => {
                    setShowTableType(5)
                    fetchChildrenData(row.compain_id, 'combination')
                    setExportId({ id: row.compain_id, type: 'combination' })
                  }}
                >
                  {getStaticText('combination')}
                </Button>
              </Space>
            </>
          )
        }
        if (row.pattern === 'campaign') {
          return (
            <>
              <Space>
                <Button
                  type="primary"
                  loading={childTableData.loading}
                  onClick={() => {
                    setShowTableType(3)
                    fetchChildrenData(row.compain_id, 'distribution')
                    setExportId({ id: row.compain_id, type: 'distribution' })
                  }}
                >
                  {getStaticText('distribution')}
                </Button>
              </Space>
            </>
          )
        }
      },
      fixed: 'right',
      width: 300,
      align: 'center'
    })
  }

  // countries 判断 disabled 属性
  const countries = useMemo(() => {
    const country = storage.allowedCountries.val || []
    if (requestParams.country.includes('All_Country')) {
      country.forEach((country) => {
        country.disabled = true
      })
      country[0].disabled = false
    } else if (requestParams.country.length === 0) {
      country.forEach((country) => {
        country.disabled = false
      })
    } else {
      country[0].disabled = true
    }
    return country
  }, [requestParams])

  const patterns = useMemo(() => {
    const pattern = storage.allowedPatterns.val || {}
    if (requestParams.pattern.includes('All_Pattern')) {
      pattern.forEach((pattern) => {
        pattern.disabled = true
      })
      pattern[0].disabled = false
    } else if (requestParams.pattern.length === 0) {
      pattern.forEach((pattern) => {
        pattern.disabled = false
      })
    } else {
      pattern[0].disabled = true
    }
    return pattern
  }, [requestParams])

  const fetchData = useCallback(() => {
    toggleLoading()
    services.bigQuery
      .getBigQuery(requestParamsTransformer)
      .then((result) => {
        const data = result.list || []
        setTableData(data)
        setTotal(result.total)
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  useEffect(() => {
    setTotal(0)
    setUrlSearchForState()
    fetchData()
  }, [fetchData, setUrlSearchForState])

  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country,
      current: 1
    }))
  }
  const handlePatternChange = (pattern) => {
    setRequestParams((prevState) => ({
      ...prevState,
      pattern,
      current: 1
    }))
  }
  const handledeviceTypeChange = () => {
    const deviceType = document.getElementById('deviceType').value
    setRequestParams((prevState) => ({
      ...prevState,
      deviceType,
      current: 1
    }))
  }
  const handleDateChange = (date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      start_date: date[0],
      start_format: dateString[0],
      end_date: date[1],
      end_format: dateString[1],
      current: 1
    }))
  }

  const handleReset = () => {
    setRequestParams({
      country: ['All_Country'],
      pattern: ['All_Pattern'],
      deviceType: '',
      start_date: moment().subtract(1, 'days'),
      start_format: moment().subtract(1, 'days').format(dateFormatDay),
      end_date: moment(),
      end_format: moment().format(dateFormatDay),
      current: 1,
      pageSize: 20
    })
  }

  const handleCurrentChange = (current, pageSize) => {
    setRequestParams((prevState) => ({
      ...prevState,
      current,
      pageSize
    }))
  }

  const handleChildCurrentChange = (current, pageSize) => {
    setChildTableData((prevState) => ({
      ...prevState,
      pageSize,
      current
    }))

    fetchChildrenData(childTableData.id, childTableData.type, current)
  }

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text>{getStaticText('component.search.pattern')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.pattern}
            onChange={handlePatternChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {patterns.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text>{getStaticText('component.search.deviceType')}</Text>
          <Input id="deviceType" onBlur={handledeviceTypeChange} />
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.time.text')}
          </Text>
          <RangePicker
            picker="day"
            value={[requestParams.start_date, requestParams.end_date]}
            format={dateFormatDay}
            onChange={handleDateChange}
            allowClear={false}
          />
        </Space>
        <Space style={{ cssFloat: 'right' }}>
          <Button type="primary" onClick={handleReset}>
            {getStaticText('component.search.button.reset')}
          </Button>
        </Space>
      </Card>
      <Table
        size="small"
        bordered
        loading={isLoading}
        columns={bigQueryTableColumns}
        dataSource={tableData}
        rowKey={(record) => record.compain_id}
        scroll={{ x: 'max-content', y: true }}
        sticky
        pagination={{
          size: 'small',
          showSizeChanger: true,
          showQuickJumper: true,
          total,
          showTotal: () => `共${total}条数据`,
          pageSize: requestParamsTransformer.pageSize,
          current: requestParamsTransformer.current,
          onChange: handleCurrentChange
        }}
      />

      <Drawer
        // title="查询结果"
        {...drawerState}
        onClose={() => {
          return setDrawerState((prevState) => ({
            ...prevState,
            visible: false
          }))
        }}
      >
        <Card>
          <Row>
            <Col span={12}>
              <Text>{getStaticText('component.table.header.title')}</Text>
            </Col>
            {checkPerms(perm) && (
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button
                  onClick={() => handleExport(exportId)}
                  loading={exportLoading}
                >
                  {getStaticText('component.table.export.text')}
                </Button>
              </Col>
            )}
          </Row>
        </Card>
        {showTableType === 1 && (
          <Table
            size="small"
            bordered
            loading={childTableData.loading}
            columns={bigQueryChildTableColumns}
            dataSource={childTableData.data}
            rowKey={(record) => record.uuid}
            scroll={{ x: 'max-content', y: true }}
            sticky
            pagination={{
              size: 'small',
              showSizeChanger: true,
              showQuickJumper: true,
              total: childTableData.total,
              showTotal: () => `共${childTableData.total}条数据`,
              pageSize: childTableData.pageSize,
              current: childTableData.current,
              onChange: handleChildCurrentChange
            }}
          />
        )}
        {showTableType === 2 && (
          <Table
            size="small"
            bordered
            loading={childTableData.loading}
            columns={bigQueryChildTableColumns2}
            dataSource={childTableData.data}
            rowKey={(record) => record.uuid}
            scroll={{ x: 'max-content', y: true }}
            sticky
            pagination={{
              size: 'small',
              showSizeChanger: true,
              showQuickJumper: true,
              total: childTableData.total,
              showTotal: () => `共${childTableData.total}条数据`,
              pageSize: childTableData.pageSize,
              current: childTableData.current,
              onChange: handleChildCurrentChange
            }}
          />
        )}
        {showTableType === 3 && (
          <Table
            size="small"
            bordered
            loading={childTableData.loading}
            columns={bigQueryChildTableColumns3}
            dataSource={childTableData.data}
            rowKey={(record) => record.uuid}
            scroll={{ x: 'max-content', y: true }}
            sticky
            pagination={{
              size: 'small',
              showSizeChanger: true,
              showQuickJumper: true,
              total: childTableData.total,
              showTotal: () => `共${childTableData.total}条数据`,
              pageSize: childTableData.pageSize,
              current: childTableData.current,
              onChange: handleChildCurrentChange
            }}
          />
        )}
        {showTableType === 5 && (
          <Table
            size="small"
            bordered
            loading={childTableData.loading}
            columns={bigQueryChildTableColumns5}
            dataSource={childTableData.data}
            rowKey={(record) => record.uuid}
            scroll={{ x: 'max-content', y: true }}
            sticky
            pagination={{
              size: 'small',
              showSizeChanger: true,
              showQuickJumper: true,
              total: childTableData.total,
              showTotal: () => `共${childTableData.total}条数据`,
              pageSize: childTableData.pageSize,
              current: childTableData.current,
              onChange: handleChildCurrentChange
            }}
          />
        )}
      </Drawer>
    </>
  )
}

BigQuery.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default BigQuery
