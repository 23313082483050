import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Card, Space, DatePicker, Typography } from 'antd'
import {
  CountryFilter,
  ProductFilter,
  SourceFilter,
  SubmitButton,
  ResetButton
} from '../../../../../components'

const { Text } = Typography
const { RangePicker } = DatePicker

const Filters = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const history = useHistory()
  const [params, setParams] = useState({
    ___country: searchParams.get('___country') || 'NG',
    name: searchParams.get('name') || '',
    start_date: '',
    end_date: ''
  })
  const [datePair, setDatePair] = useState([null, null])
  const changeParams = (moreParams) => {
    const newParams = {
      ...params,
      ...moreParams
    }
    setParams({
      ...newParams
    })
    const strParams = new URLSearchParams(newParams).toString()
    window.history.pushState({}, 0, `${location.pathname}?${strParams}`)
  }
  const paramChanged = (name) => (value) => {
    changeParams({ [name]: value })
  }
  const handleSubmit = (params) => () => {
    const strParams = new URLSearchParams(params).toString()
    history.push(`${location.pathname}?${strParams}`)
  }
  const handleReset = (initParams) => () => {
    changeParams(initParams)
  }
  const handleChangeDate = (date, dateString) => {
    setDatePair(date)
    changeParams({
      start_date: dateString[0],
      end_date: dateString[1]
    })
  }

  return (
    <Card
      style={{
        marginBottom: 20
      }}
    >
      <Space>
        <CountryFilter
          mode="single"
          value={params.country}
          onChange={paramChanged('___country')}
        />
        <ProductFilter
          mode="multiple"
          country={params.___country}
          onChange={paramChanged('product')}
          defaultValue={params.product}
        />
        <SourceFilter
          label="Status"
          source="cut_share_status"
          onChange={paramChanged('status')}
          defaultValue={params.status}
        />
        <Space>
          <Text>Share Time</Text>
          <RangePicker
            picker="day"
            value={datePair}
            format="YYYY-MM-DD"
            onChange={handleChangeDate}
            allowClear={false}
          />
        </Space>
      </Space>
      <ResetButton onReset={handleReset({}, setParams)} />
      <SubmitButton onSubmit={handleSubmit(params)} />
    </Card>
  )
}

export default Filters
