import React from 'react'
import { connect } from 'react-redux'
import { Dropdown, Menu, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { setCurrentScope, setGlobalConfig } from '../../store/actions/global'
import { loadGlobalConfig } from './services'

interface Props {
  currentScope: Object;
  scopes: Object;
  setCurrentScope: () => {};
}

const ScopeSwitcher = ({
  currentScope,
  scopes,
  setCurrentScope,
  setGlobalConfig
}: Props) => {
  if (scopes.length <= 1) {
    return null
  }
  const switchScope = (e) => {
    scopes.forEach((x) => x.key === e.key && setCurrentScope(x))
    loadGlobalConfig((config) => {
      setGlobalConfig(config)
      window.location.reload()
    })()
  }
  const buildMenu = (scopes) => {
    return (
      <Menu onClick={switchScope}>
        {scopes.map((scope) => (
          <Menu.Item key={scope.key}>{scope.name}</Menu.Item>
        ))}
      </Menu>
    )
  }
  return (
    <Dropdown overlay={buildMenu(scopes)} disabled={scopes.length === 1}>
      <Button>
        {currentScope.name}
        {scopes.length > 1 ? <DownOutlined /> : null}
      </Button>
    </Dropdown>
  )
}
const mapStateToProps = ({ global, users }) => ({
  currentScope: global.currentScope,
  scopes: users.userInfo.user.scopes
    ? global.allScopes.filter(
        (x) => users.userInfo.user.scopes.indexOf(x.key) !== -1
      )
    : global.allScopes
})
export default connect(mapStateToProps, { setCurrentScope, setGlobalConfig })(
  ScopeSwitcher
)
