import React from 'react'

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 100,
    align: 'center'
  },
  {
    title: 'Cutted Price',
    dataIndex: 'cutted_price',
    key: 'cutted_price',
    width: 100,
    align: 'center'
  },
  {
    title: 'Customer name',
    width: 150,
    align: 'center',
    render: (data) => {
      return (
        <span>
          <span
            style={{
              display: 'inline-block',
              borderRadius: '50%',
              marginRight: '10px'
            }}
          >
            <img alt="" src={data.customer_avatar} width="30" />
          </span>
          {data.customer_avatar}
        </span>
      )
    }
  },
  {
    title: 'Customer Email',
    dataIndex: 'customer_email',
    key: 'customer_email',
    width: 150,
    align: 'center'
  },
  {
    title: 'Chopper IP',
    dataIndex: 'chopper_ip',
    key: 'chopper_ip',
    width: 150,
    align: 'center'
  },
  {
    title: 'Created time',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 150,
    align: 'center'
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
