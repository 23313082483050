import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Space,
  Typography,
  Button,
  DatePicker,
  Select,
  Table,
  Card,
  Tabs,
  Switch
} from 'antd'
import ReactEcharts from 'echarts-for-react'
import { getStaticText, storage } from '../../utils'
import dateFormat from '../../config/dateFormat'
import '../../css/dashbord.css'
import services from '../../services'
import UpDownIcon from './Tool/UpDownIcon'
import businessDashboardUsersColumns from '../../config/tableColumns/businessDashboardUsersColumns'
import businessDashboardPvColumns from '../../config/tableColumns/businessDashboardPvColumns'
import businessDashboardOrderColumns from '../../config/tableColumns/businessDashboardOrderColumns'
import businessDashboardOrderGmvColumns from '../../config/tableColumns/businessDashboardOrderGmvColumns'
import MyTooltip from './Tool/MyTooltip'

const { Text } = Typography
const { RangePicker } = DatePicker

const requestParams = {
  key: 'category_sales_map',
  keyTable: 'buy_user_map',
  duration: 'day',
  country: 'All_Country',
  device: 'All_Device',
  diskCatalogId: ['0'],
  brand: ['0'],
  switchKey: 'usd',
  switchFunnelKey: 'uv',
  start_date: moment().subtract('day', 7),
  end_date: moment().subtract('day', 1)
}

const columnsTapList = {
  rs: businessDashboardOrderColumns,
  usd: businessDashboardOrderGmvColumns
}

const columnsFunnlTapList = {
  uv: businessDashboardUsersColumns,
  pv: businessDashboardPvColumns
}

const deviceList = [
  { disabled: false, label: '所有平台', value: 'All_Device' },
  { disabled: false, label: 'App', value: 'app' },
  { disabled: false, label: 'Web', value: 'web' }
]

const filterDuration = [
  { value: 'day', label: '按日' },
  { value: 'week', label: '按周' },
  { value: 'month', label: '按月' }
]

const brandTapList = [
  { name: '类目数量', key: 'category_sales_map' },
  { name: '类目GMV', key: 'category_gmv_map' },
  { name: '品牌数量', key: 'brand_sales_map' },
  { name: '品牌GMV', key: 'brand_gmv_map' }
]

const BusinessTapList = [
  { name: '买家', key: 'buy_user_map' },
  { name: '支付渠道', key: 'buy_channel_map' },
  { name: '平台', key: 'buy_method_map' }
]

const requestOptionLine = {
  legend: {
    data: []
  },
  grid: {
    show: false,
    top: '10%',
    left: '10%',
    right: '10%',
    bottom: '20%'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      animation: 'false'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      name: '$'
    },
    {
      name: ''
    }
  ],
  series: []
}

const requestOptionPie = {
  title: {
    text: '',
    x: 'right',
    y: 'bottom',
    textStyle: {
      fontSize: 12
    }
  },
  tooltip: {
    trigger: 'item',
    formatter(d) {
      return `${d.name} : ${d.value.toLocaleString()} (${d.percent}%)`
    }
  },
  series: {
    name: '',
    type: 'pie',
    radius: '50%',
    avoidLabelOverlap: true,
    data: []
  }
}

const mapPie = {
  name: '',
  type: 'pie',
  radius: '50%',
  avoidLabelOverlap: true
}

const requestOptionFunnel = {
  backgroundColor: '#ffffff',
  series: [
    {
      top: 0,
      type: 'funnel',
      height: 350,
      minSize: 150,
      left: 110,
      gap: 15,
      width: 300,
      z: 3,
      label: {
        show: true,
        position: 'inside',
        fontSize: '14',
        formatter(d) {
          return `${d.name}{aa|} \n${d.data.value.toLocaleString()}`
        },
        rich: {
          aa: {
            padding: [8, 0, 6, 0]
          }
        }
      },
      data: []
    },
    {
      top: 0,
      type: 'funnel',
      height: 350,
      gap: 15,
      itemStyle: {
        color: 'rgba(233, 233, 233, 0.5)'
      },
      minSize: 150,
      left: 220,
      width: 300,
      z: 2,
      label: {
        normal: {
          position: 'insideRight',
          padding: [11, 25],
          formatter(d) {
            return `{aa|${d.data.percent}}`
          },
          rich: {
            aa: {
              align: 'center',
              color: '#333',
              fontSize: '12'
            }
          }
        }
      },
      data: []
    },
    {
      top: 0,
      type: 'funnel',
      height: 350,
      gap: 15,
      itemStyle: {
        color: 'rgba(233, 233, 233, 0.5)'
      },
      minSize: 150,
      left: 0,
      width: 300,
      z: 2,
      label: {
        normal: {
          position: 'insideLeft',
          padding: [11, 25],
          formatter(d) {
            return `{aa|$${d.data.other_value.toLocaleString()}}`
          },
          rich: {
            aa: {
              align: 'center',
              color: '#333',
              fontSize: '12'
            }
          }
        }
      },
      data: []
    }
  ]
}

class DashboardBusiness extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    const duration = nextSearch.get('duration')
      ? nextSearch.get('duration')
      : requestParams.duration
    const dateFormatDuration = this.getDateFormatDuration(duration)
    const dateFormatDurationTable = this.getDateFormatDuration(
      requestParams.duration
    )
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatDuration)
      : requestParams.start_date
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatDuration)
      : requestParams.end_date
    const country = nextSearch.get('country')
      ? nextSearch.get('country')
      : requestParams.country
    const device = nextSearch.get('device')
      ? nextSearch.get('device')
      : requestParams.device
    const brand = nextSearch.get('brand')
      ? nextSearch.get('brand').split(',')
      : requestParams.brand
    const diskCatalogId = nextSearch.get('diskCatalogId')
      ? nextSearch.get('diskCatalogId').split(',')
      : requestParams.diskCatalogId
    const current = nextSearch.get('current') ? nextSearch.get('current') : 1
    const pageSize = nextSearch.get('pageSize')
      ? nextSearch.get('pageSize')
      : 10

    this.state = {
      isLoading: false,
      nameList: [],
      diskCatalogId,
      countries: this.getCountrys(),
      country,
      duration,
      durationTable: requestParams.duration,
      device,
      brand,
      switchKey: requestParams.switchKey,
      switchFunnelKey: requestParams.switchFunnelKey,
      checkList: [],
      disk_catalog: this.checkDiskCatalogList(diskCatalogId),
      brandList: [],
      start_date: startDate,
      start_format: startDate.format(dateFormatDuration),
      end_date: endDate,
      end_format: endDate.format(dateFormatDuration),
      start_date_table: requestParams.start_date,
      start_format_table: requestParams.start_date.format(
        dateFormatDurationTable
      ),
      end_date_table: requestParams.end_date,
      end_format_table: requestParams.end_date.format(dateFormatDurationTable),
      dateFormatDuration,
      dateFormatDurationTable,
      dataTop: {},
      dataFunnel: {},
      dataCategory: {},
      goodsTable: [],
      goods_sum: {
        price: 0,
        price_dollar: 0,
        shipping_amount: 0,
        shipping_dollar: 0,
        grand_total: 0,
        grand_total_dollar: 0
      },
      key: requestParams.key,
      keyTable: requestParams.keyTable,
      countryTable: requestParams.country,
      deviceTable: requestParams.device,
      total: 0,
      current,
      pageSize,
      sorter: {}
    }
    this.getBrandReportsCatalog()
  }

  componentDidMount() {
    this.useEffect()
    this.useEffectTable()
  }

  getCountrys = () => {
    const countrys = storage.allowedCountries.val
    countrys.forEach((country) => {
      if (country.value === 'All_Country') {
        country.label = '所有站点'
      }
    })
    return countrys
  }

  getBrandReportsCatalog = () => {
    services.brand.getBrandReportsCatalog().then((result) => {
      this.setState(
        (prevState) => ({
          catalogs: result.catalog_list,
          brandList: this.checkBrands(prevState.brand, result.catalog_list)
        }),
        () => {
          this.useEffect()
        }
      )
    })
  }

  getDateFormatDuration = (duration = requestParams.duration) => {
    return dateFormat[duration]
  }

  getrequest = () => ({
    country: this.state.country,
    device: this.state.device,
    duration: this.state.duration,
    start_date: this.state.start_format,
    end_date: this.state.end_format
  })

  getrequestTable = (keyWords = '') => ({
    country: this.state.countryTable,
    device: this.state.deviceTable,
    brand: this.state.brand.toString(),
    diskCatalogId: this.state.diskCatalogId.toString(),
    duration: this.state.durationTable,
    start_date: this.state.start_format_table,
    end_date: this.state.end_format_table,
    checkList: this.state.checkList,
    keyWords,
    current: this.state.current,
    pageSize: this.state.pageSize,
    sorter: this.state.sorter
  })

  handleDateChange = (date, dateString) => {
    this.setState({
      start_date: date[0],
      start_format: dateString[0],
      end_date: date[1],
      end_format: dateString[1]
    })
  }

  handleDateChangeTable = (date, dateString) => {
    this.setState({
      start_date_table: date[0],
      start_format_table: dateString[0],
      end_date_table: date[1],
      end_format_table: dateString[1]
    })
  }

  handleCountryChange = (country) => {
    this.setState({
      country
    })
  }

  handledeviceChange = (device) => {
    this.setState({
      device
    })
  }

  handleCountryChangeTable = (countryTable) => {
    this.setState({
      countryTable
    })
  }

  handledeviceChangeTable = (deviceTable) => {
    this.setState({
      deviceTable
    })
  }

  handleDurationChange = (duration) => {
    const dateFormatDuration = this.getDateFormatDuration(duration)
    this.setState({
      duration,
      dateFormatDuration,
      start_date: requestParams.start_date,
      start_format: requestParams.start_date.format(dateFormatDuration),
      end_date: requestParams.end_date,
      end_format: requestParams.end_date.format(dateFormatDuration)
    })
  }

  handleDurationChangeTable = (durationTable) => {
    const dateFormatDurationTable = this.getDateFormatDuration(durationTable)
    this.setState({
      durationTable,
      dateFormatDurationTable,
      start_date_table: requestParams.start_date,
      start_format_table: requestParams.start_date.format(
        dateFormatDurationTable
      ),
      end_date_table: requestParams.end_date,
      end_format_table: requestParams.end_date.format(dateFormatDurationTable)
    })
  }

  handleCurrentChange = (current, pageSize) => {
    this.setState(
      {
        current,
        pageSize
      },
      () => {
        this.useEffectTable()
      }
    )
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState(
      {
        sorter: {
          field: sorter.field.replace('_gmv', ''),
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      },
      () => {
        this.useEffectTable()
      }
    )
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchData()
    this.fetchCategoryData()
    this.fetchFunnelData()
  }

  useEffectTable = () => {
    this.fetchDataTable()
  }

  checkBrands = (brand, catalogs = []) => {
    if ((this.state && this.state.catalogs?.[0]) || catalogs[0]) {
      catalogs = catalogs[0] ? catalogs : this.state.catalogs
      const brandList = catalogs[0].map((catalog) => {
        return {
          label: catalog.title,
          value: catalog.title,
          disabled: false
        }
      })
      brandList.unshift({
        label: '全部',
        value: '0',
        disabled: false
      })
      if (brand.indexOf('0') >= 0) {
        brandList.forEach((brand) => {
          brand.disabled = true
        })
        brandList[0].disabled = false
      } else if (brand.length === 0) {
        brandList.forEach((brand) => {
          brand.disabled = false
        })
      } else {
        brandList[0].disabled = true
      }
      return brandList
    }
    return []
  }

  fetchData = () => {
    services.dashboard.getBusinessTop(this.getrequest()).then((result) => {
      this.gmv_map.getEchartsInstance().clear() // 清空画布
      this.gmv_map.getEchartsInstance().setOption({
        ...requestOptionLine,
        xAxis: [
          {
            data: result.gmv_map_data.catalog
          }
        ],
        series: result.gmv_map_data.series
      })
      this.gmv_map.getEchartsInstance().resize()
      const pieMap = {}
      Object.keys(result.pie).forEach((key) => {
        pieMap[key] = {
          ...requestOptionPie,
          series: {
            ...mapPie,
            data: result.pie[key]
          }
        }
      })
      const line = {}
      Object.keys(result.line).forEach((key) => {
        line[key] = {
          ...requestOptionLine,
          xAxis: [
            {
              data: result.line[key]?.catalog
            }
          ],
          series: result.line[key]?.series
        }
      })
      this.business_tap_map.getEchartsInstance().resize()
      this.setState({
        dataTop: {
          all_gmv: result.all_gmv,
          business_table: result.business_table,
          pie: {
            ...pieMap
          },
          line
        }
      })
    })
  }

  fetchFunnelData = () => {
    services.dashboard
      .getBusinessTopFunnel(this.getrequest())
      .then((result) => {
        this.setState(
          {
            dataFunnel: {
              ...result
            }
          },
          () => {
            this.handleFunnelSwitchChange('default')
          }
        )
      })
  }

  fetchCategoryData = () => {
    services.dashboard
      .getBusinessTopCategory(this.getrequest())
      .then((result) => {
        const maps = {}
        Object.keys(result.pie).forEach((key) => {
          maps[key] = {
            ...requestOptionPie,
            series: {
              ...mapPie,
              data: result.pie[key]
            }
          }
        })
        this.setState({
          dataCategory: {
            pie: {
              ...maps
            }
          }
        })
      })
  }

  fetchDataTable = () => {
    services.dashboard
      .getBusinessGoods(this.getrequestTable())
      .then((result) => {
        this.goods_gmv_map.getEchartsInstance().clear() // 清空画布
        this.goods_gmv_map.getEchartsInstance().setOption({
          ...requestOptionLine,
          xAxis: [
            {
              data: result.goods_gmv_map_data.catalog
            }
          ],
          series: result.goods_gmv_map_data.series
        })
        this.goods_gmv_map.getEchartsInstance().resize()
        this.setState({
          goodsTable: result.goods_table.data,
          total: result.goods_table.total,
          current: result.goods_table.current,
          pageSize: result.goods_table.pageSize,
          goods_sum: {
            ...result.goods_sum
          }
        })
      })
  }

  onSearch = (keyWords) => {
    if (keyWords) {
      services.dashboard
        .getBusinessGoodsNameList(this.getrequestTable(keyWords))
        .then((result) => {
          this.setState({
            nameList: result
          })
        })
    } else {
      this.setState({
        nameList: []
      })
    }
  }

  changeHandlerName = (checkList) => {
    this.setState({
      checkList
    })
  }

  handlediskCatalogChange = (diskCatalogId) => {
    this.setState({
      diskCatalogId,
      disk_catalog: this.checkDiskCatalogList(diskCatalogId)
    })
  }

  checkDiskCatalogList = (diskCatalogId) => {
    const diskCatalogList = storage.disk_catalog_list.val
    if (diskCatalogId.indexOf('0') >= 0) {
      diskCatalogList.forEach((diskCatalog) => {
        diskCatalog.disabled = true
      })
      diskCatalogList[0].disabled = false
    } else if (diskCatalogId.length === 0) {
      diskCatalogList.forEach((diskCatalog) => {
        diskCatalog.disabled = false
      })
    } else {
      diskCatalogList[0].disabled = true
    }
    return diskCatalogList
  }

  handleBrandChange = (brand) => {
    this.setState({
      brand,
      brandList: this.checkBrands(brand)
    })
  }

  handleFunnelSwitchChange = (key) => {
    let { switchFunnelKey } = this.state
    if (key !== 'default') {
      switchFunnelKey = key ? 'pv' : 'uv'
      this.setState({ switchFunnelKey })
    }
    if (this.business_funnel_map) {
      const optionFunnel = {
        ...requestOptionFunnel
      }
      optionFunnel.series[0].data =
        this.state.dataFunnel?.[switchFunnelKey]?.business_funnel_map.value_data
      optionFunnel.series[1].data =
        this.state.dataFunnel?.[switchFunnelKey]?.business_funnel_map.value_data
      optionFunnel.series[2].data =
        this.state.dataFunnel?.[switchFunnelKey]?.business_funnel_map.value_data
      this.business_funnel_map.getEchartsInstance().clear() // 清空画布
      this.business_funnel_map.getEchartsInstance().setOption(optionFunnel)
      this.business_funnel_map.getEchartsInstance().resize()
    }
  }

  handleSwitchChange = (key) => {
    const switchKey = key ? 'rs' : 'usd'
    this.setState({ switchKey })
  }

  switchTab = (key) => {
    this.setState({ key })
  }

  switchTabTable = (keyTable) => {
    this.setState({ keyTable })
  }

  render() {
    const options = this.state.nameList.map((item) => (
      <Select.Option key={item}>{item}</Select.Option>
    ))
    return (
      <>
        <Card>
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 220 }}
              value={this.state.country}
              onChange={this.handleCountryChange}
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.device.text')}
            </Text>
            <Select
              style={{ width: 220 }}
              value={this.state.device}
              onChange={this.handledeviceChange}
              maxTagCount="responsive"
            >
              {deviceList.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <Select
              style={{ width: 80 }}
              value={this.state.duration}
              onChange={this.handleDurationChange}
              maxTagCount="responsive"
            >
              {filterDuration.map((item) => (
                <Select.Option key={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
            <RangePicker
              picker={this.state.duration}
              value={[this.state.start_date, this.state.end_date]}
              format={this.state.dateFormatDuration}
              onChange={this.handleDateChange}
              allowClear={false}
            />
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        <Space
          style={{
            marginTop: 50,
            overflow: 'hidden',
            display: 'block',
            width: '100%'
          }}
        >
          <div className="business-top">
            <div className="business-box floatf">
              <div className="business-title">
                总体GMV
                <MyTooltip account="xpark数据" />
              </div>
              <div className="business-gmv">
                <span className="business-today-gmv">
                  <span>$</span>
                  {this.state.dataTop?.all_gmv}
                </span>
                <UpDownIcon
                  current={this.state.dataTop?.all_gmv}
                  last={this.state.dataFunnel?.last_all_gmv}
                />
              </div>
              <ReactEcharts
                style={{ width: '100%', height: '90%' }}
                ref={(e) => {
                  this.gmv_map = e
                }}
                option={requestOptionLine}
              />
            </div>
            <div className="business-box floatr">
              <div className="business-title">
                类目及品牌构成
                <MyTooltip account="xpark数据" />
              </div>
              <Tabs
                activeKey={this.state.key}
                type="card"
                onChange={this.switchTab}
              >
                {brandTapList.map((item) => {
                  return (
                    <Tabs.TabPane tab={item.name} key={item.key}>
                      <ReactEcharts
                        style={{ width: '90%', height: '300px' }}
                        option={
                          this.state.dataCategory?.pie?.[item.key] ||
                          requestOptionPie
                        }
                        key={item.key}
                      />
                    </Tabs.TabPane>
                  )
                })}
              </Tabs>
            </div>
          </div>
          <div className="business-mid">
            <div
              className="business-box floatf"
              style={{
                position: 'relative',
                overflow: 'hidden'
              }}
            >
              <div className="business-title">
                交易漏斗
                <MyTooltip account="xpark数据" />
                <Switch
                  className="business-title-switch"
                  checkedChildren="pv"
                  unCheckedChildren="uv"
                  onChange={this.handleFunnelSwitchChange}
                />
              </div>
              <div
                style={{ width: '100%', height: '350px', margin: '50px 0px' }}
              >
                <ReactEcharts
                  style={{ width: '100%', height: '100%' }}
                  ref={(e) => {
                    this.business_funnel_map = e
                  }}
                  option={requestOptionFunnel}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  position: 'absolute',
                  bottom: 0
                }}
              >
                <Table
                  style={{ width: '100%' }}
                  size="small"
                  bordered
                  loading={this.state.isLoading}
                  columns={columnsFunnlTapList[this.state.switchFunnelKey]}
                  dataSource={
                    this.state.dataFunnel?.[this.state.switchFunnelKey]
                      ?.business_table
                  }
                  rowKey={(record) => record.name}
                  scroll={{ x: 'max-content', y: true }}
                  sticky
                  pagination={false}
                />
              </div>
            </div>
            <div className="business-box floatr">
              <div className="business-title">
                交易构成
                <MyTooltip account="xpark数据" />
              </div>
              <Tabs
                activeKey={this.state.keyTable}
                type="card"
                onChange={this.switchTabTable}
              >
                {BusinessTapList.map((item) => {
                  return (
                    <Tabs.TabPane tab={item.name} key={item.key}>
                      <div
                        style={{
                          width: '100%',
                          height: '350px',
                          marginBottom: '50px'
                        }}
                      >
                        <ReactEcharts
                          style={{ width: '100%', height: '100%' }}
                          option={
                            this.state.dataTop?.pie?.[item.key] ||
                            requestOptionPie
                          }
                          key={`${item.key}_pie`}
                        />
                      </div>
                      <div
                        style={{
                          width: '100%',
                          height: '250px'
                        }}
                      >
                        <ReactEcharts
                          style={{ width: '100%', height: '100%' }}
                          ref={(e) => {
                            this.business_tap_map = e
                          }}
                          option={
                            this.state.dataTop?.line?.[item.key] ||
                            requestOptionLine
                          }
                          key={`${item.key}_line`}
                        />
                      </div>
                    </Tabs.TabPane>
                  )
                })}
              </Tabs>
            </div>
          </div>
        </Space>
        <Card>
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 220 }}
              value={this.state.countryTable}
              onChange={this.handleCountryChangeTable}
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.device.text')}
            </Text>
            <Select
              style={{ width: 220 }}
              value={this.state.deviceTable}
              onChange={this.handledeviceChangeTable}
              maxTagCount="responsive"
            >
              {deviceList.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.brand.text')}
            </Text>
            <Select
              style={{ width: 120 }}
              value={this.state.brand}
              onChange={this.handleBrandChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.brandList.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.category_type.text')}
            </Text>
            <Select
              style={{ width: 120 }}
              value={this.state.diskCatalogId}
              onChange={this.handlediskCatalogChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.disk_catalog.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text>{getStaticText('component.search.time.text')}</Text>
            <Select
              style={{ width: 80 }}
              value={this.state.durationTable}
              onChange={this.handleDurationChangeTable}
              maxTagCount="responsive"
            >
              {filterDuration.map((item) => (
                <Select.Option key={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
            <RangePicker
              picker={this.state.durationTable}
              value={[this.state.start_date_table, this.state.end_date_table]}
              format={this.state.dateFormatDurationTable}
              onChange={this.handleDateChangeTable}
              allowClear={false}
            />
          </Space>
          <Space
            style={{
              marginTop: 20,
              width: '100%'
            }}
          >
            <Text>{getStaticText('goods.search.goods_name')}</Text>
            <Select
              style={{ width: 500 }}
              showSearch
              value={this.state.checkList}
              placeholder={getStaticText('goods.search.goods_name')}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={this.onSearch}
              onChange={this.changeHandlerName}
              notFoundContent={null}
              mode="multiple"
            >
              {options}
            </Select>
            <Button
              style={{ float: 'right' }}
              type="primary"
              onClick={this.useEffectTable}
            >
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        <Space
          style={{
            display: 'block',
            width: '100%'
          }}
        >
          <div className="business-mid-box">
            <div className="business-title">
              类目及品牌趋势
              <MyTooltip account="xpark数据" />
            </div>
            <ReactEcharts
              style={{
                width: '100%',
                height: '300px',
                marginTop: '30px'
              }}
              ref={(e) => {
                this.goods_gmv_map = e
              }}
              option={requestOptionLine}
            />
          </div>
        </Space>
        <Space>
          <div className="business-table-box">
            <div className="business-title">
              交易明细
              <MyTooltip account="xpark数据" />
              <span className="business-title-span">
                <span>交易总金额: RS.</span>
                {this.state.goods_sum.price}
                <span> /$ </span>
                {this.state.goods_sum.price_dollar}
              </span>
              <span className="business-title-span">
                <span>实付总金额: RS.</span>
                {this.state.goods_sum.grand_total}
                <span> /$ </span>
                {this.state.goods_sum.grand_total_dollar}
              </span>
              <span className="business-title-span">
                <span>运费: RS.</span>
                {this.state.goods_sum.shipping_amount}
                <span> /$ </span>
                {this.state.goods_sum.shipping_dollar}
              </span>
              <Switch
                className="business-title-switch"
                checkedChildren="RS.s"
                unCheckedChildren="USD"
                onChange={this.handleSwitchChange}
              />
            </div>
            <div
              style={{
                width: '100%'
              }}
            >
              <Table
                size="small"
                bordered
                onChange={this.handleChange}
                loading={this.state.isLoading}
                columns={columnsTapList[this.state.switchKey]}
                dataSource={this.state.goodsTable}
                rowKey={(record) => record.sdk_uuid}
                scroll={{ x: 'max-content', y: true }}
                sticky
                pagination={{
                  simple: true,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  total: this.state.total,
                  showTotal: () => `共${this.state.total}条数据`,
                  pageSize: this.state.pageSize,
                  current: this.state.current,
                  onChange: this.handleCurrentChange
                }}
              />
            </div>
          </div>
        </Space>
      </>
    )
  }
}

DashboardBusiness.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default DashboardBusiness
