import {
  Form,
  Select,
  Button,
  Table,
  Col,
  Typography,
  Row,
  Card,
  Modal
} from 'antd'
import React, { Component } from 'react'
import ModelForm2 from './ModelForm2'
import { getStaticText } from '../../utils'
import services from '../../services'

const { Option } = Select
const { Title, Text } = Typography
const columns = [
  {
    title: getStaticText('show-command.model'),
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: getStaticText('show-command.model.step'),
    dataIndex: 'step',
    key: 'step'
  },
  {
    title: getStaticText('show-command.sql'),
    dataIndex: 'sql',
    key: 'sql'
  }
]
class InputParams2 extends Component {
  formRef = React.createRef()

  constructor() {
    super()
    this.state = {
      type: 'affinity',
      sqlist: [],
      postdata: '',
      isModalVisible: false
    }
  }

  onModelChange = (type) => {
    this.setState((prevState) => ({
      ...prevState,
      type
    }))
    // 添加清空之前数据的操作
  }

  onFinish = (values: any) => {
    this.setState((prevState) => ({
      ...prevState,
      postdata: JSON.stringify(values)
    }))
    this.setState((prevState) => ({
      ...prevState,
      isModalVisible: false
    }))
    this.fetchData(this.state.postdata)
  }

  onReset = () => {
    const form = this.formRef.current
    form.resetFields()
  }

  handleExport = () => {
    services.mapList
      .exportCommand({ data: this.state.postdata })
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
  }

  showModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      isModalVisible: true
    }))
  }

  handleCancel = () => {
    this.setState((prevState) => ({
      ...prevState,
      isModalVisible: false
    }))
  }

  fetchData = (post) => {
    services.mapList
      .showCommand({
        data: post
      })
      .then((data) => {
        const sqlist = []
        Object.keys(data).forEach((item) => {
          sqlist.push({
            type: data[item].name,
            step: data[item].step,
            sql: data[item].sql
          })
        })
        this.setState((prevState) => ({
          ...prevState,
          sqlist
        }))
      })
  }

  render() {
    return (
      <>
        <div>
          <Modal
            title="输入参数"
            visible={this.state.isModalVisible}
            onCancel={this.handleCancel}
            okButtonProps={{ style: { display: 'none' } }}
          >
            <div>
              <Form
                ref={this.formRef}
                name="control-hooks"
                layout="vertical"
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="model"
                  label="模型"
                  rules={[{ required: true }]}
                >
                  <Select value={this.state.type} onChange={this.onModelChange}>
                    <Option value="affinity">受众决策模型</Option>
                    <Option value="creative">素材决策模型</Option>
                    <Option value="campaign">广告决策模型</Option>
                  </Select>
                </Form.Item>
                <ModelForm2 type={this.state.type} />
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Search
                  </Button>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={this.onReset}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Modal>
          <Card>
            <Row>
              <Col span={12}>
                <Text>{getStaticText('component.table.header.title')}</Text>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button type="primary" onClick={this.showModal}>
                  输入参数
                </Button>
                <Button onClick={this.handleExport}>
                  {getStaticText('component.table.export.text')}
                </Button>
              </Col>
            </Row>
            <Title level={5}>{getStaticText('app.tips')}</Title>
            <Row>
              <Col>
                <Text type="warning">
                  1、点击“输入参数”按钮，先选择决策模型
                </Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <Text type="warning">
                  2、根据表单提示输入参数，可动态增加的字段，至少输入一条
                </Text>
              </Col>
            </Row>
          </Card>
          <Table
            sticky
            bordered
            size="small"
            columns={columns}
            dataSource={this.state.sqlist}
            scroll={{ x: 'max-content', y: 500 }}
            pagination={false}
          />
        </div>
      </>
    )
  }
}

export default InputParams2
