/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {Divider, Typography, Row, Col, Table, Popover, Form, Button, Modal, Descriptions} from 'antd'
import { AppstoreOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import FilterOrderProcessing from '../../components/1688ProjectComponents/OrdersProcessing/FilterOrderProcessing'
import BlackButton from '../../components/1688ProjectComponents/ButtonComponents/BlackButton'
import BlackInputSelect from '../../components/1688ProjectComponents/inputComponent/BlackInputSelect'
import StickyFooter from '../../components/1688ProjectComponents/FooterComponents/StickyFooter'
import '../../css/order-processing-filter.css'
import {getOrderList, getOrderDetail} from "../../services/1688Projects/collection";
import '../../css/order-processing.css'

const { Title } = Typography

const EnumOrderItemKey = {
  action: 'Action',
  name: 'Name',
  order_amount: 'Order Amount',
  pay_time: 'Paytime',
  qty_ordered: 'Quantity Ordered',
  sku: 'SKU'
};
const EnumStatus = [
  'Pending',
  'Processing',
  'Shipped',
  'Complete'
]

const EnumDataTable = {
  shipping_address: "Shipping address",
  shipping_amount: "Shipping amount",
  shipping_name: "Shipping name",
  sdk_id: "SDK ID",
  entity_id: 'Entity ID'
}

const OrdersProcessing = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sizePage, setSizePage] = useState(10)
  const [totalItem, setTotalItem] = useState(0)
  const [dataRaw, setDataRaw] = useState(null)
  const [dataTable, setDataTable] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNow, setPageNow] = useState(1)
  const [formFilter, setFormFilter] = useState({status: 'pending'})
  const [form] = Form.useForm()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState('')
  const [items, setItems] = useState([])
  const [shippingDesc, setShippingDesc] = useState([])

  useEffect(() => {
    fetchGetOrderList()

  }, [search, formFilter]);

  async function fetchGetOrderList({  goPage = 1 }: GetProduct = {}) {
    try {

      setIsLoading(true)
      const data = settingParamsPayload(goPage)
      const response = await getOrderList(data)
      setDataRaw(response)
      const totalRecords = response.total_records
      if (totalRecords) {
        setTotalCount(Number(totalRecords))
      } else {
        setTotalCount(0)
      }

      const dataFromSetting = settingDataForTable(response)
      setTotalItem(dataFromSetting.length)
      setDataTable(dataFromSetting)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  function settingDataForTable(response) {
    const data = response.data
    const dataReturn = []
    if (data) {
      for (let order of data) {
        dataReturn.push({
          key: order.sdk_uuid || '',
          increment: order.increment_id,
          status: order.status,
          country: order.country_code,
          package_info: {
            shipping_address: order.shipping_address,
            shipping_amount: order.shipping_amount,
            shipping_name: order.shipping_name,
            sdk_id: order.sdk_uuid,
            entity_id: order.entity_id
          },
          time: order.confirmed_at
        })
      }
    }

    return dataReturn
  }

  const fetchOrderItem = async (id) => {
    try {
      const data = {
        sdk_uuid: Number(id)
      }
      const response = await getOrderDetail(data)
      const dataForDescription =  settingForDescription(response, id)
      setItems(dataForDescription)
      showModal()
    } catch (e) {
      console.log(e)
    }
  }

  function settingForDescription(response, id) {
    const dataRes = response[0]
    const dataReturn = []
    const dataTableOne = []
    for (let key in dataTable) {
      if (Number(dataTable[key].key) === Number(id)) {

        const dataPackage = dataTable[key].package_info
        if (dataPackage) {
          for (let key in dataPackage) {
            dataTableOne.push({
              key: key,
              label: EnumDataTable[key],
              children: dataPackage[key] || '-'
            })
          }

        }
        break
      }
    }
    setShippingDesc(dataTableOne)
    // package_info: {
    //   shipping_address: order.shipping_address,
    //     shipping_amount: order.shipping_amount,
    //     shipping_name: order.shipping_name,
    //     sdk_id: order.sdk_uuid,
    //     entity_id: order.entity_id
    // },

    for (let key in dataRes) {
      if (key === "action") {
        dataReturn.push({
          key: key,
          label: EnumOrderItemKey[key],
          children: EnumStatus[dataRes[key]]
        })
      } else if (dataRes[key] ) {
        dataReturn.push({
          key: key,
          label: EnumOrderItemKey[key],
          children: dataRes[key]
        })
      } else {
        dataReturn.push({
          key: key,
          label: EnumOrderItemKey[key],
          children: "-"
        })
      }
    }
    return dataReturn
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  function settingParamsPayload(goPage) {
    const data = {}
    if (goPage) {
      data.current_page = Number(goPage)
      setPageNow(Number(goPage))
    }
    if (sizePage) {
      data.page_size = sizePage
    }
    if (formFilter) {
      const status = formFilter.status
      if (status) {
        data.status = status
      }
      const platform = formFilter.platform
      if (platform) {
        data.country = platform
      }
    }
    if (search) {
      const incrementId = search.increment_id
      if (incrementId) {
        data.increment_id = incrementId
      }
    }
    return data

  }

  const onShowSizeChange = (current, pageSize) => {
    setSizePage(pageSize)
  }

  const hide = () => {
    setOpen(false)
  }
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }

  const handleSelectAll = () => {
    const allRowKeys = dataTable.map((item) => item.key)
    setSelectedRowKeys(allRowKeys)
  }
  const handleDeselectAll = () => {
    setSelectedRowKeys([])
  }

  const onChangePage = (value) => {
    if (value) {
      setPageNow(Number(value))
      fetchGetOrderList({ goPage: Number(value) })
    }
  }

  const content = () => {
    return (
      <Row>
        <Col span={24}>
          <Row>
            <Col span={4}>
              <ExclamationCircleFilled style={{ fontSize: '20px' }} />
            </Col>
            <Col span={20}>
              You can click here to set the product information to be displayed
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Col span={20} />
                <Col span={4}>
                  <BlackButton functionParent={hide} title="Ok" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>
    }
    if (type === 'next') {
      return <a>Next</a>
    }
    return originalElement
  }

  const columns = [
    {
      title: () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div> Increment Id </div>
            <div>
              <Popover
                placement="bottomLeft"
                content={content}
                open={open}
                onOpenChange={handleOpenChange}
              >
                <AppstoreOutlined
                  style={{ fontSize: '20px', cursor: 'pointer' }}
                />
              </Popover>
            </div>
          </div>
        )
      },
      dataIndex: 'increment'
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Country',
      dataIndex: 'country'
    },
    {
      title: 'Purchase order',
      dataIndex: 'purchase',
      render: (_, records) => {
        return (
          <Button onClick={() => fetchOrderItem(records.key)}>Detail order</Button>
        )
      }
    },
    {
      title: 'Time',
      dataIndex: 'time'
    }
  ]

  const titlePage = () => {
    return (
      <Title level={2} style={{ marginBottom: 0, padding: '24px' }}>
        Order Processing
      </Title>
    )
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  }
  return (
    <div className="order-processing" style={{ width: '100%', background: '#F8F8F8', position: 'relative' }}>
      <div style={{ width: '100%' }}>{titlePage()}</div>
      <Divider style={{ color: 'black', margin: 'unset' }} />
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
      >
        <FilterOrderProcessing setSearch={setSearch} setFormFilter={setFormFilter} />
        <Row gutter={[0, 10]} style={{ marginTop: '50px' }}>
          <Col span={4}>
            <BlackButton title="Apply for waybill number" />
          </Col>
          <Col span={4}>
            <BlackButton title="Bulk purchase (1688)" />
          </Col>
          <Col span={2}>
            <BlackInputSelect
              placeHolder="More"
              option={[]}
            />
          </Col>
          <Col span={12} />
          <Col span={24}>
            <Table
              pagination={false}
              columns={columns}
              rowSelection={rowSelection}
              scroll={{
                y: 240
              }}
              dataSource={dataTable}
              loading={isLoading}
            />
          </Col>
        </Row>
        <StickyFooter
          sizePage={sizePage}
          totalItem={totalItem}
          handleCheckAll={handleSelectAll}
          handleUncheckAll={handleDeselectAll}
          itemRender={itemRender}
          totalPage={totalCount}
          onChangePage={onChangePage}
          page={pageNow}
          onShowSizeChange={onShowSizeChange}
          itemSelected={selectedRowKeys?.length || 0}
        />
      </div>
      <Modal
        title="Detail order"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        centered
        footer={
          <Row justify="end">
            <Col span={4}>
              <BlackButton title="Close" functionParent={handleOk}/>
            </Col>
          </Row>
        }
      >
        <Descriptions>
          {items.map((item) => {
            return (
              <Descriptions.Item key={item.key} label={item.label}>{item.children}</Descriptions.Item>
            )
          })}
        </Descriptions>
        <Descriptions>
          {shippingDesc.map((item) => {
            return (
              <Descriptions.Item key={item.key} label={item.label}>{item.children}</Descriptions.Item>
            )
          })}
        </Descriptions>
      </Modal>
    </div>
  )
}

export default OrdersProcessing
