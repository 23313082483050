import React from 'react'
import Actions from './Actions'
import { getTypeLabel } from '../Items/Edit/TypeSelect'

export const createColumns = ({ onChange }) => [
  {
    title: '图片',
    key: 'activity_item_image',
    align: 'center',
    render: (i) => {
      return i.activity_item_image ? (
        <img height="50px" alt={i.activity_item_title} src={i.img_url} />
      ) : (
        '--'
      )
    }
  },
  {
    title: '奖项',
    dataIndex: 'activity_item_title',
    key: 'activity_item_title',
    align: 'center'
  },
  {
    title: '用户',
    dataIndex: 'customer_email',
    key: 'customer_email',
    align: 'center'
  },
  {
    title: '描述',
    dataIndex: 'activity_item_description',
    key: 'activity_item_description',
    align: 'center'
  },
  {
    title: '奖品类型',
    dataIndex: 'activity_item_type',
    key: 'activity_item_type',
    align: 'center',
    render: (v) => getTypeLabel(v)
  },
  {
    title: '附属信息',
    dataIndex: 'target_canonical',
    key: 'activity_item_item_canonical',
    align: 'center'
  },
  {
    title: '订单GMV',
    dataIndex: 'gmv',
    key: 'gmv',
    align: 'center'
  },
  {
    title: '券成本',
    dataIndex: 'discount_gmv',
    key: 'discount_gmv',
    align: 'center'
  },
  {
    title: '兑券数量',
    dataIndex: 'gmv',
    key: 'gmv',
    align: 'center',
    render: (v) => {
      return v > 0 ? 1 : 0
    }
  },
  {
    title: '附属信息2',
    dataIndex: 'target_additional',
    key: 'activity_item_item_additional',
    align: 'center'
  },
  {
    title: '备注',
    dataIndex: 'note',
    key: 'note',
    align: 'center'
  },
  {
    title: '中奖时间',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
  {
    title: '操作',
    key: 'x',
    align: 'center',
    render: (record) => <Actions record={record} onChange={onChange} />
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
