import React, { useEffect, useState } from 'react'

interface Props {
  current: string;
  last: string;
}

const UpDownIcon = ({ current, last }: Props) => {
  const [triangleClass, setTriangleClass] = useState('')
  const [text, setText] = useState(0)

  useEffect(() => {
    const currentInt = current ? parseFloat(current.trim(',')) : 0
    const lastInt = last ? parseFloat(last.trim(',')) : 0
    setText(currentInt - lastInt)

    const triangleClass =
      currentInt >= lastInt ? 'triangle-up' : 'triangle-down'
    setTriangleClass(triangleClass)
  }, [current, last])

  return (
    <>
      <span>同比</span>
      <span className={triangleClass} />
      <span style={{ marginLeft: '5px' }}>{text}</span>
    </>
  )
}
export default UpDownIcon
