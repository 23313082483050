import React, { useState, useCallback, useContext } from 'react'
import { Card, Row, Col, Typography } from 'antd'
import {
  GridFormDrawer,
  PagingTable,
  GridContext
} from '../../../../components'
import { createColumns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getPointHistory } from '../services'

const { Text } = Typography

const ItemList = () => {
  const [gridData, setGridData] = useState(initGridData)
  const [activityId, setActivityId] = useState(0)
  const { record, drawerStack } = useContext(GridContext)
  const onOpen = useCallback(
    (
      record = drawerStack.length && drawerStack[drawerStack.length - 1].record
    ) => {
      getPointHistory(setGridData)({ customer_id: record.id })
      setActivityId(record.id)
    },
    [drawerStack]
  )
  const paginationHandler = (current, pageSize) => {
    getPointHistory(setGridData)({
      customer_id: activityId,
      current,
      pageSize
    })
  }

  return (
    <>
      <GridFormDrawer type="view_points" title="积分记录" onOpen={onOpen}>
        <Card>
          <Row>
            <Col span={24}>
              {record && (
                <Text>
                  <span style={{ fontWeight: 900, marginRight: '20px' }}>
                    当前积分余额:
                  </span>
                  {parseInt(record.points, 10)}
                </Text>
              )}
            </Col>
          </Row>
        </Card>
        <PagingTable
          handler={paginationHandler}
          columns={createColumns({ onChange: () => onOpen(record) })}
          dataSource={dataSourceMap(gridData.data)}
          total={gridData.total}
          initGridData={initGridData}
          current={gridData.page}
          pageSize={gridData.page_size}
        />
      </GridFormDrawer>
    </>
  )
}

export default ItemList
