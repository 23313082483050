import React from 'react'
import { Input, Form, InputNumber } from 'antd'
import LabelInput from './LabelInput'

interface Props {
  title: String;
  placeHolder1: String;
  size: String;
  unit: String;
  space: String;
  colon: Boolean;
  style: Object;
  widthTitle: String;
  name1: String;
  name2: String;
}
const DoubleInputWithUnit = ({
  title,
  placeHolder1 = false,
  placeHolder2 = false,
  size = 'large',
  unit = 'Yuan',
  space,
  colon,
  style,
  widthTitle,
  name1,
  name2
}: Props) => {
  return (
    <Input.Group
      compact
      style={{
        display: 'flex',
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#E3E2E1'
      }}
    >
      <div
        style={{
          width: style?.width || '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          paddingLeft: '10px'
        }}
      >
        {LabelInput({ title, space, colon, widthTitle })}
      </div>
      <Form.Item name={name1} style={{ width: '100%', marginBottom: 'unset' }}>
        <InputNumber
          bordered={false}
          size={size}
          placeholder={placeHolder1}
          style={{ width: '100%' }}
        />
      </Form.Item>
      <Input
        style={{
          width: 30,
          borderLeft: 0,
          borderRight: 0,
          pointerEvents: 'none'
        }}
        placeholder="~"
        disabled
      />
      <Form.Item name={name2} style={{ width: '100%', marginBottom: 'unset' }}>
        <InputNumber
          style={{ width: '100%' }}
          bordered={false}
          addonAfter={unit}
          size={size}
          placeholder={placeHolder2}
        />
      </Form.Item>
    </Input.Group>
  )
}

export default DoubleInputWithUnit
