import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Select, Table, Space, Card, Button } from 'antd'
import Text from 'antd/es/typography/Text'
import { Option } from 'antd/es/mentions'
import { getStaticText } from '../../utils'
import storage from '../../utils/storage'
import services from '../../services'
import { goodsColumns } from '../../config/tableColumns'

class CrowdGoods extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    this.state = {
      country: nextSearch.get('country')
        ? nextSearch.get('country')
        : 'All_Country',
      keyWords: nextSearch.get('keyWords') ? nextSearch.get('keyWords') : '',
      nameList: [],
      isLoading: false,
      tableData: [],
      total: 0,
      current: nextSearch.get('current') ? nextSearch.get('current') : 1,
      pageSize: nextSearch.get('pageSize') ? nextSearch.get('pageSize') : 20
    }
  }

  getRequest = () => ({
    country: this.state.country,
    keyWords: this.state.keyWords,
    current: this.state.current,
    pageSize: this.state.pageSize
  })

  getNameRequest = (keyWords) => ({
    country: this.state.country,
    keyWords
  })

  handleCountryChange = (country) =>
    this.setState({
      country
    })

  changeHandlerName = (keyWords) => {
    this.setState(
      {
        keyWords
      },
      this.setUrlSearchForState
    )
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getRequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchData()
  }

  fetchData = () => {
    services.peopleYard.goodsPeopleGroup(this.getRequest()).then((result) =>
      this.setState({
        tableData: result.data,
        total: result.total,
        pageSize: result.page_size,
        current: result.current_page
      })
    )
  }

  onSearch = (keyWords) => {
    if (keyWords) {
      services.peopleYard
        .getNameList(this.getNameRequest(keyWords))
        .then((result) => {
          this.setState({
            nameList: result,
            keyWords
          })
        })
    } else {
      this.setState({
        nameList: [],
        keyWords
      })
    }
  }

  handleCurrentChange = (current, pageSize) =>
    this.setState(
      {
        current,
        pageSize
      },
      this.useEffect
    )

  render() {
    const options = this.state.nameList.map((item) => (
      <Option key={item.goods_name}>{item.goods_name}</Option>
    ))
    return (
      <>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 220 }}
              value={this.state.country}
              onChange={this.handleCountryChange}
              maxTagCount="responsive"
            >
              {storage.allowedCountries.val.map((item) => (
                <Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('goods.search.goods_name')}
            </Text>
            <Select
              style={{ width: 400 }}
              showSearch
              value={this.state.keyWords}
              placeholder={getStaticText('goods.search.goods_name')}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={this.onSearch}
              onChange={this.changeHandlerName}
              notFoundContent={null}
            >
              {options}
            </Select>
          </Space>
          <Space style={{ cssFloat: 'right' }}>
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        <Table
          size="small"
          bordered
          loading={this.state.isLoading}
          columns={goodsColumns}
          dataSource={this.state.tableData}
          rowKey={(record) => record.id}
          sticky
          pagination={{
            size: 'small',
            showSizeChanger: true,
            showQuickJumper: true,
            total: this.state.total,
            showTotal: () => `共${this.state.total}条数据`,
            pageSize: this.state.pageSize,
            current: this.state.current,
            onChange: this.handleCurrentChange
          }}
        />
      </>
    )
  }
}

CrowdGoods.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default CrowdGoods
