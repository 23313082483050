import React from 'react'
import CycleTime from './CycleTime'
import Category from './Category'

function DashboardResult(props) {
  return (
    <>
      <CycleTime {...props} />
      <Category {...props} />
    </>
  )
}

export default DashboardResult
