import storage from './storage'

const checkPerms = (perms) => {
  const permissions = storage.userInfo.val?.user?.permissions ?? []
  const isAllowed =
    !permissions.length ||
    permissions.includes(perms) ||
    permissions.includes('/')
  return isAllowed
}
export default checkPerms
