import request from './request'

export const getBigQuery = (data) =>
  request.post('/backend/big-query/compain-list', data)

export const getChildrenData = (data) =>
  request.post('/backend/big-query/top-list', data)

export const exportBigQuery = (params) => {
  return request.post('/backend/big-query/export', params)
}
