import React, { useContext, useState } from 'react'
import { Card, Row, Col, Typography, Space, Input } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { getStaticText } from '../../../../utils'
import {
  PermissionButton,
  PagingTable,
  GridContext
} from '../../../../components'
import { toggleProduct, editPosition } from '../services'

const { Text } = Typography

const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}
interface ActionsProp {
  reload: () => {};
  row: Object;
}
const Actions = ({ reload, row }: ActionsProp) => {
  return (
    <PermissionButton
      onClick={() => {
        toggleProduct(() => reload())({
          country: row.country,
          group: row.group_code,
          type: row.type,
          product: row.summary || {
            id: row.product_id
          }
        })
      }}
      style={{ marginLeft: '5px' }}
      icon={<DeleteOutlined />}
      size="small"
      permission="membership/product/toggle-product"
      type="primary"
    />
  )
}

interface PositionProps {
  row: Object;
  reload: () => {};
}

const Position = ({ row, reload }: PositionProps) => {
  const [value, setValue] = useState(row.position)

  return (
    <>
      <Space style={{ width: '50px' }}>
        <Input
          value={value}
          onChange={(e) => setValue(parseInt(e.target.value, 10))}
          onBlur={() => {
            editPosition(() => reload())({ id: row.id, value })
          }}
        />
      </Space>
    </>
  )
}

const columns = ({ reload }) => [
  {
    title: 'Group',
    dataIndex: 'group_code',
    key: 'group_code'
  },
  {
    title: 'Product Id',
    dataIndex: 'product_id',
    key: 'product_id'
  },
  {
    title: 'Name',
    key: 'name',
    render: (data) => data.summary && data.summary.name
  },
  {
    title: 'SKU',
    key: 'sku',
    render: (data) => data.summary && data.summary.sku
  },
  {
    title: 'Type',
    key: 'type_id',
    render: (data) => data.summary && data.summary.type_id
  },
  {
    title: 'Position',
    key: 'position',
    render: (data) => <Position reload={reload} row={data} />
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (data) => <Actions reload={reload} row={data} />
  }
]

interface Props {
  gridData: Object;
}

export default ({ gridData, country, paginationHandler, reload }: Props) => {
  const { toggle, record } = useContext(GridContext)

  return (
    <>
      <Card>
        <Row>
          <Col span={24}>
            <Text>{getStaticText('component.table.header.title')}</Text>
            <Space style={{ cssFloat: 'right' }}>
              <PermissionButton
                onClick={() => toggle({ ...record, country }, 'product_picker')}
                permission="membership/product/toggle-product"
                icon={<PlusOutlined />}
                type="primary"
              >
                Edit Products
              </PermissionButton>
            </Space>
          </Col>
        </Row>
      </Card>
      <PagingTable
        columns={columns({ reload })}
        dataSource={gridData.data}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.current || gridData.page}
        pageSize={gridData.page_size}
        handler={paginationHandler}
      />
    </>
  )
}
