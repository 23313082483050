import React from 'react'
import { connect } from 'react-redux'
import { Select, Space, Typography } from 'antd'
import { getStaticText } from '../../../../utils'

const { Text } = Typography
const { Option } = Select

interface Props {
  allowedCountries: Object;
  mode: String;
  value: String;
  onChange: Function;
}

const CountryFilter = ({
  allCountries,
  allowedCountries,
  mode,
  value,
  defaultValue,
  onChange
}: Props) => {
  if (!allowedCountries) {
    return null
  }
  return (
    <span>
      <Space>
        <Text>{getStaticText('component.search.country.text')}</Text>
        <Select
          style={{ width: '200px' }}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          mode={mode}
          maxTagCount="responsive"
        >
          <Option key="all" value="">
            全部
          </Option>
          {allCountries
            .filter((x) => allowedCountries.indexOf(x.countryCode) !== -1)
            .map((item) => (
              <Option key={item.countryCode}>{item.name}</Option>
            ))}
        </Select>
      </Space>
    </span>
  )
}

const mapStateToProps = ({ users, global }) => {
  return {
    allowedCountries:
      users.userInfo && users.userInfo.user
        ? users.userInfo.user.allowed_countries
        : [],
    allCountries: global.config
  }
}

export default connect(mapStateToProps)(CountryFilter)
