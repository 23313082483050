import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import { Table, Tabs } from 'antd'
import { func } from 'prop-types'
import services from '../../../services'
import dashboardTopGoodsColumns from '../../../config/tableColumns/dashboardTopGoodsColumns'
import dashboardStructureColumns from '../../../config/tableColumns/dashboardStructureColumns'
import dashboardGoodsGmvColumns from '../../../config/tableColumns/dashboardGoodsGmvColumns'
import dashboardFlowGmvColumns from '../../../config/tableColumns/dashboardFlowGmvColumns'
import AddPercent from '../Tool/AddPercent'
import MyTooltip from '../Tool/MyTooltip'
import dashboardGmvColumns from '../../../config/tableColumns/dashboardGmvColumns'

const requestOptionPreass = {
  legend: {
    data: []
  },
  grid: {
    show: false,
    top: '10%',
    left: '10%',
    right: '10%',
    bottom: '20%'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      animation: 'false'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      splitNumber: 2,
      axisLabel: { inside: true, margin: 0 },
      name: ''
    }
  ],
  series: []
}

const requestOption = {
  title: {
    text: '',
    x: 'right',
    y: 'bottom',
    textStyle: {
      fontSize: 12
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)'
  },
  series: {
    name: '',
    type: 'pie',
    radius: '50%',
    avoidLabelOverlap: true,
    data: []
  }
}

const mapPie = {
  name: '',
  type: 'pie',
  radius: '50%',
  avoidLabelOverlap: true
}

const items = [
  {
    key: 'buy_user_map',
    table: 'buy_user_table',
    tab: '买家'
  },
  {
    key: 'category_map',
    table: 'category_table',
    tab: '类目数量'
  },
  {
    key: 'category_gmv_map',
    table: 'category_gmv_table',
    tab: '类目GMV'
  },
  {
    key: 'is_infinix_map',
    table: 'is_infinix_table',
    tab: '外牌数量'
  },
  {
    key: 'is_infinix_gmv_map',
    table: 'is_infinix_gmv_table',
    tab: '外牌GMV'
  }
]

class RightFrame extends Component {
  constructor(props) {
    const { onRef } = props
    super(props)
    onRef(this.useEffect)
    this.state = {
      isLoading: false,
      tableData: [],
      product_rank: [],
      infinix_phone_map: { ...requestOption },
      buy_user_map: { ...requestOption },
      is_infinix_map: { ...requestOption },
      category_map: { ...requestOption },
      category_gmv_map: { ...requestOption },
      is_infinix_gmv_map: { ...requestOption },
      category_table: [],
      category_gmv_table: [],
      is_infinix_table: [],
      is_infinix_gmv_table: [],
      infinix_phone_table: [],
      goodsTap: [],
      canceled_user: {
        today: 0,
        yesterday: '0%'
      },
      canceled_gmv: {
        today: 0,
        yesterday: '0%'
      },
      buy_user_table: [],
      flow_goods_tap: [],
      gmv_goods_tap: [],
      order_goods_tap: []
    }
  }

  useEffect = (params) => {
    this.fetchData(params)
  }

  fetchData = (params) => {
    services.dashboard
      .getRight(params)
      .then((result) => {
        const data = {
          ...result,
          isLoading: true
        }
        data.buy_user_map = {
          ...requestOption,
          series: {
            ...mapPie,
            data: result.buy_user_map
          }
        }
        data.category_map = {
          ...requestOption,
          series: {
            ...mapPie,
            data: result.category_map
          }
        }
        data.category_gmv_map = {
          ...requestOption,
          series: {
            ...mapPie,
            data: result.category_gmv_map
          }
        }
        data.is_infinix_map = {
          ...requestOption,
          series: {
            ...mapPie,
            data: result.is_infinix_map
          }
        }
        data.is_infinix_gmv_map = {
          ...requestOption,
          series: {
            ...mapPie,
            data: result.is_infinix_gmv_map
          }
        }
        this.setState({
          ...data
        })
        this.infinix_phone_map.getEchartsInstance().clear() // 清空画布
        this.infinix_phone_map.getEchartsInstance().setOption({
          ...requestOptionPreass,
          ...requestOptionPreass,
          xAxis: [
            {
              data: result.infinix_phone_map.catalog
            }
          ],
          series: result.infinix_phone_map.series
        })
        this.order_map.getEchartsInstance().clear() // 清空画布
        this.order_map.getEchartsInstance().setOption({
          ...requestOptionPreass,
          xAxis: [
            {
              data: result.order_map.catalog
            }
          ],
          series: result.order_map.series
        })
        this.new_user_map.getEchartsInstance().clear() // 清空画布
        this.new_user_map.getEchartsInstance().setOption({
          ...requestOptionPreass,
          xAxis: [
            {
              data: result.new_user_map.catalog
            }
          ],
          series: result.new_user_map.series
        })
        this.canceled_user_map.getEchartsInstance().clear() // 清空画布
        this.canceled_user_map.getEchartsInstance().setOption({
          ...requestOptionPreass,
          xAxis: [
            {
              data: result.canceled_user.data.catalog
            }
          ],
          series: result.canceled_user.data.series
        })
        this.canceled_gmv.getEchartsInstance().clear() // 清空画布
        this.canceled_gmv.getEchartsInstance().setOption({
          ...requestOptionPreass,
          xAxis: [
            {
              data: result.canceled_gmv.data.catalog
            }
          ],
          series: result.canceled_gmv.data.series
        }) // 实时改变
        // 支付渠道
        this.buy_channel_map.getEchartsInstance().clear() // 清空画布
        this.buy_channel_map.getEchartsInstance().setOption({
          ...requestOption,
          series: {
            name: '',
            type: 'pie',
            radius: '50%',
            avoidLabelOverlap: true,
            data: result.buy_channel_map
          }
        })
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  getCompositionTap = () => {
    return items.map((item) => {
      return (
        <Tabs.TabPane tab={item.tab} key={item.key}>
          <ReactEcharts
            style={{ width: '90%' }}
            option={this.state[item.key]}
            key={item.key}
          />
          <div className="right-table">
            <Table
              size="small"
              bordered
              loading={this.state.isLoading}
              columns={dashboardStructureColumns}
              dataSource={this.state[item.table]}
              rowKey={(record) => record.name}
              scroll={{ x: 'max-content', y: true }}
              sticky
              pagination={false}
            />
          </div>
        </Tabs.TabPane>
      )
    })
  }

  render() {
    return (
      <>
        <div
          className="floatr"
          style={{
            width: '30%'
          }}
        >
          <div className="right-box">
            <div className="right-title">
              infinix手机销量5周趋势图
              <MyTooltip account="结束时间前五周趋势" />
            </div>
            <ReactEcharts
              ref={(e) => {
                this.infinix_phone_map = e
              }}
              style={{ width: '90%', height: '100%' }}
              option={requestOptionPreass}
            />
          </div>
          <div className="right-box">
            <div className="right-title">
              订单五周趋势
              <MyTooltip account="结束时间前五周趋势" />
            </div>
            <ReactEcharts
              ref={(e) => {
                this.order_map = e
              }}
              style={{ width: '90%', height: '100%' }}
              option={requestOptionPreass}
            />
          </div>
          <div className="right-box">
            <div className="right-title">
              新增注册用户五周趋势
              <MyTooltip account="结束时间前五周趋势" />
            </div>
            <ReactEcharts
              ref={(e) => {
                this.new_user_map = e
              }}
              style={{ width: '90%', height: '100%' }}
              option={requestOptionPreass}
            />
          </div>
          <div className="right-box">
            <div className="right-title">
              商品榜单TOP10
              <MyTooltip account="时间段内数据" />
            </div>
            <Table
              size="small"
              bordered
              loading={this.state.isLoading}
              columns={dashboardTopGoodsColumns}
              dataSource={this.state.product_rank}
              rowKey={(record) => record.rank}
              scroll={{ x: 'max-content', y: true }}
              sticky
              pagination={false}
            />
          </div>
          <div className="right-box">
            <div className="right-title">
              订单流失率
              <MyTooltip account="时间段内数据" />
            </div>
            <div>
              <div className="flow-box-top">
                <div className="flow-box-title">
                  流失金额
                  <MyTooltip account="取消订单的gmv" />
                </div>
                <div className="flow-box-value">
                  {this.state.canceled_gmv.today}
                </div>
                <div className="flow-box-span">
                  <span>较前一日</span>
                  <span className="flow-box-value">
                    <AddPercent
                      percentString={this.state.canceled_gmv.yesterday}
                    />
                  </span>
                </div>
              </div>
              <ReactEcharts
                ref={(e) => {
                  this.canceled_gmv = e
                }}
                style={{ width: '90%', height: '200px' }}
                option={requestOptionPreass}
              />
            </div>
            <div>
              <div className="flow-box-top">
                <div className="flow-box-title">
                  流失人数
                  <MyTooltip account="取消订单的人数" />
                </div>
                <div className="flow-box-value">
                  {this.state.canceled_user.today}
                </div>
                <div className="flow-box-span">
                  <span>较前一日</span>
                  <span className="flow-box-value">
                    <AddPercent
                      percentString={this.state.canceled_user.yesterday}
                    />
                  </span>
                </div>
              </div>
              <ReactEcharts
                ref={(e) => {
                  this.canceled_user_map = e
                }}
                style={{ width: '90%', height: '200px' }}
                option={requestOptionPreass}
              />
            </div>
          </div>
          <div className="right-box">
            <div className="right-title">
              支付渠道占比
              <MyTooltip account="时间段内数据" />
            </div>
            <ReactEcharts
              ref={(e) => {
                this.buy_channel_map = e
              }}
              option={requestOption}
            />
          </div>
          <div className="right-box">
            <div className="right-title">
              交易构成
              <MyTooltip account="时间段内数据" />
            </div>
            <Tabs
              activeKey={this.state.key}
              type="card"
              onChange={this.switchTab}
            >
              {this.getCompositionTap()}
            </Tabs>
          </div>
          <div className="right-box">
            <div className="right-title">
              订单排序
              <MyTooltip account="时间段内数据" />
            </div>
            <Tabs type="card">
              <Tabs.TabPane tab="流量榜单" key="flow">
                <Table
                  width="400xp"
                  size="small"
                  bordered
                  loading={this.state.isLoading}
                  columns={dashboardGoodsGmvColumns}
                  dataSource={this.state.order_goods_tap}
                  rowKey={(record) => record.id}
                  scroll={{ x: 'max-content', y: true }}
                  sticky
                  pagination={false}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="GMV榜单" key="gmv">
                <Table
                  width="400xp"
                  size="small"
                  bordered
                  loading={this.state.isLoading}
                  columns={dashboardFlowGmvColumns}
                  dataSource={this.state.flow_goods_tap}
                  rowKey={(record) => record.id}
                  scroll={{ x: 'max-content', y: true }}
                  sticky
                  pagination={false}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="热销榜单" key="order">
                <Table
                  width="400xp"
                  size="small"
                  bordered
                  loading={this.state.isLoading}
                  columns={dashboardGmvColumns}
                  dataSource={this.state.gmv_goods_tap}
                  rowKey={(record) => record.id}
                  scroll={{ x: 'max-content', y: true }}
                  sticky
                  pagination={false}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </>
    )
  }
}

RightFrame.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onRef: func
}

export default RightFrame
