import services, { withTableLoading, withGlobalLoading } from '../../services'

export const getPriceCompareList = (handler) =>
  withTableLoading((params) =>
    services.toolkit.getPriceCompareList(params).then(handler)
  )

export const getPriceCompareDetail = (handler) =>
  withGlobalLoading((id) =>
    services.toolkit.getPriceCompareDetail({ id }).then(handler)
  )

export const deletePriceCompare = (handler) =>
  withGlobalLoading((id) =>
    services.toolkit.deletePriceCompare({ id }).then(handler)
  )

export const createPriceCompare = (handler) =>
  withGlobalLoading((params) =>
    services.toolkit.createPriceCompare(params).then(handler)
  )

export const updatePriceCompare = (handler) =>
  withGlobalLoading((params) =>
    services.toolkit.updatePriceCompare(params).then(handler)
  )
export const getCountryConfigs = (handler) =>
  withGlobalLoading((params) =>
    services.toolkit.getCountryConfigs(params).then(handler)
  )

export const freshPriceCompare = (handler) =>
  withGlobalLoading((id) =>
    services.toolkit.freshPriceCompare({ id }).then(handler)
  )

export const getPriceCompareResult = (handler) => (params) =>
  services.toolkit.getPriceCompareResult(params).then(handler)
