import React, { useState, useEffect, useCallback } from 'react'
import { Form, Input, Button, Typography, message } from 'antd'
import PropTypes from 'prop-types'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { getStaticText, storage } from '../../utils'
import services from '../../services'
import { updateUserInfo } from '../../store/actions/users'
import { setGlobalConfig, setCurrentScope } from '../../store/actions/global'

const { Title } = Typography

const Login = ({
  updateUserInfo,
  history,
  setGlobalConfig,
  setCurrentScope
}) => {
  const [disabled, setDisabled] = useState(true)

  const fetchData = useCallback(async () => {
    const data = await services.users.getConfig()
    setGlobalConfig(data)
  }, [setGlobalConfig])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onValuesChange = (changedValues, allValues) => {
    setDisabled(
      !Object.values(allValues).every((key) => key !== undefined && key !== '')
    )
  }

  const submit = async (values) => {
    let data = {}
    try {
      data = await services.users.login(values)
    } catch {
      return
    }
    const config = storage.config.val
    if (data.token && config) {
      storage.userInfo.val = data
      storage.modular.val = 'index'
      const countries = []
      data.user.allowed_countries
        .map((country) => config.filter((item) => item.countryCode === country))
        .flat()
        .map((item) =>
          countries.push({
            label: getStaticText(item.countryCode),
            value: item.countryCode,
            disabled: false
          })
        )
      countries.unshift({
        label: getStaticText('All_Country'),
        value: 'All_Country',
        disabled: false
      })
      const platforms = config.reduce((obj, item) => {
        item.platforms.forEach((a, b) => {
          item.platforms.splice(b, 1, {
            label: a,
            value: a,
            disabled: false
          })
        })
        item.platforms.unshift({
          label: getStaticText('All_Platform'),
          value: 'All_Platform',
          disabled: false
        })
        obj[item.countryCode] = item.platforms
        return obj
      }, {})

      storage.allowedPlatforms.val = platforms
      storage.allowedCountries.val = countries
      updateUserInfo(data)
      if (data.user.scopes[0] && storage.allScopes.val) {
        storage.allScopes.val.forEach((i) => {
          if (i.key === data.user.scopes[0]) {
            setCurrentScope(i)
          }
        })
      }
      message.info(getStaticText('app.login.message.success'))
      setTimeout(() => history.push('/index'), 1000)
      // const entries = Object.entries(menuPermMap)
      // /* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
      // for (const [k, v] of entries) {
      //   if (
      //     data.user.permissions.includes('/') ||
      //     data.user.permissions.includes(v)
      //   ) {
      //     setTimeout(() => history.push(k), 1000)
      //     return
      //   }
      // }
    }
  }

  const onFinish = (values) => {
    submit(values)
  }

  return (
    <Form
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#2d3a4b',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      size="large"
      initialValues={{
        remember: true
      }}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
    >
      <Form.Item>
        <Title level={2} style={{ color: '#eee' }}>
          {getStaticText('app.login.title')}
        </Title>
      </Form.Item>
      <Form.Item
        name="username"
        style={{ width: 400 }}
        rules={[
          {
            required: true,
            message: getStaticText('app.login.form.username.warn.text')
          }
        ]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder={getStaticText('app.login.form.username')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        style={{ width: 400 }}
        rules={[
          {
            required: true,
            message: getStaticText('app.login.form.password.warn.text')
          }
        ]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder={getStaticText('app.login.form.password')}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={disabled}
          style={{ width: 400 }}
        >
          {getStaticText('app.login.button.text')}
        </Button>
      </Form.Item>
    </Form>
  )
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  setGlobalConfig: PropTypes.func.isRequired,
  setCurrentScope: PropTypes.func.isRequired
}

export default connect(null, {
  updateUserInfo,
  setGlobalConfig,
  setCurrentScope
})(Login)
