import React from 'react'

import { Popover } from 'antd'
import ReactECharts from 'echarts-for-react'
import { getStaticText } from '../../utils'

const TrendChart = (row) => {
  const xAxis = row ? Object.keys(row) : []
  const series = row ? Object.values(row) : []
  const options = {
    grid: {
      bottom: 40,
      top: 40,
      left: 40,
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: xAxis
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: series,
        type: 'line',
        smooth: true,
        label: {
          show: true,
          position: 'top'
        }
      }
    ],
    tooltip: {
      trigger: 'axis'
    }
  }
  return (
    <span style={{ color: '#B30000FF' }}>
      <Popover
        style={{ width: '500px' }}
        content={() => (
          <ReactECharts style={{ width: '500px' }} option={options} />
        )}
        trigger="hover"
      >
        {series.toString()}
      </Popover>
    </span>
  )
}

const weeklyTableActiveColumns = [
  {
    title: <div>{getStaticText('weekly.sales.table.country')}</div>,
    dataIndex: 'country',
    align: 'center',
    width: 50,
    key: 'country'
  },
  {
    title: <div>{getStaticText('weekly.sales.table.platform')}</div>,
    dataIndex: 'platform',
    align: 'center',
    width: 100,
    key: 'platform'
  },
  {
    title: <div>{getStaticText('weekly.sales.table.week_key')}</div>,
    dataIndex: 'week_key',
    align: 'center',
    width: 100,
    key: 'week_key'
  },
  {
    title: 'Infinix手机销量（剔除B单，一单/重复订单超/含3件）30天',
    key: 'phone',
    children: [
      {
        title: <div>Infinix手机销量</div>,
        dataIndex: 'a_phone_sales_volume',
        align: 'center',
        width: 100,
        key: 'a_phone_sales_volume'
      },
      {
        title: <div>infinix手机销量5周趋势(件)</div>,
        align: 'center',
        dataIndex: 'a_phone_sales_volume_last_5week',
        key: 'a_phone_sales_volume_last_5week',
        width: 150,
        render: (row) => {
          return TrendChart(row)
        }
      },
      {
        title: <div>Infinix手机月度销量(件)</div>,
        align: 'center',
        width: 100,
        dataIndex: 'a_month_phone_sales_volume',
        key: 'a_month_phone_sales_volume'
      },
      {
        title: '月度达成（含B单）',
        align: 'center',
        width: 100,
        dataIndex: 'month_sales_amount_target',
        key: 'month_sales_amount_target'
      },
      {
        title: '累计年度达成（含B单）',
        align: 'center',
        width: 100,
        dataIndex: 'year_sales_amount_target',
        key: 'year_sales_amount_target'
      }
    ]
  },
  {
    title: '销售金额全品类(剔除B单)',
    key: 'gmv',
    children: [
      {
        title: <div>{getStaticText('weekly.sales.table.sales_amount')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'a_sales_amount',
        key: 'a_sales_amount'
      },
      {
        title: <div>{getStaticText('weekly.home.table.year_on_year')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'a_sales_amount_rate_year_on_year',
        key: 'a_sales_amount_rate_year_on_year'
      }
    ]
  },
  {
    title: '订单(剔除B单)',
    key: 'order',
    children: [
      {
        title: <div>{getStaticText('weekly.sales.table.orders')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'a_total_orders',
        key: 'a_total_orders'
      },
      {
        title: '订单五周趋势(%)',
        align: 'center',
        width: 150,
        dataIndex: 'a_total_orders_last_5week',
        key: 'a_total_orders_last_5week',
        render: (row) => {
          return TrendChart(row)
        }
      },
      {
        title: <div>{getStaticText('weekly.home.table.year_on_year')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'a_total_orders_rate_year_on_year',
        key: 'a_total_orders_rate_year_on_year'
      },
      {
        title: 'CPA(市场费用/订单数)',
        align: 'center',
        width: 100,
        dataIndex: 'cpa',
        key: 'cpa'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.customer_order_average')}
          </div>
        ),
        align: 'center',
        width: 100,
        dataIndex: 'a_customer_order_average',
        key: 'a_customer_order_average'
      }
    ]
  },
  {
    title: '投放效率ROAS',
    key: 'roas',
    children: [
      {
        title: <div>{getStaticText('total.report.table.market_outlay')}</div>,
        align: 'center',
        width: 100,
        edit: true,
        dataIndex: 'market_outlay',
        key: 'market_outlay'
      },
      {
        title: '投放费用率(市场费用/b单gmv)',
        align: 'center',
        width: 100,
        dataIndex: 'market_outlay_rate',
        key: 'market_outlay_rate'
      },
      {
        title: '费率五周趋势(%)',
        align: 'center',
        width: 150,
        dataIndex: 'market_outlay_rate_last_5week',
        key: 'market_outlay_rate_last_5week',
        render: (row) => {
          return TrendChart(row)
        }
      },
      {
        title: '直链roas',
        align: 'center',
        width: 100,
        edit: true,
        dataIndex: 'roas_gmv',
        key: 'roas_gmv'
      },
      {
        title: 'GG直链roas',
        align: 'center',
        width: 100,
        edit: true,
        dataIndex: 'gg_roas_gmv',
        key: 'gg_roas_gmv'
      },
      {
        title: 'FB直链roas',
        align: 'center',
        width: 100,
        edit: true,
        dataIndex: 'fb_roas_gmv',
        key: 'fb_roas_gmv'
      }
    ]
  },
  {
    title: '注册用户数',
    key: 'user',
    children: [
      {
        title: <div>新增注册用户</div>,
        align: 'center',
        width: 100,
        dataIndex: 'new_users',
        key: 'new_users'
      },
      {
        title: '新增注册用户五周趋势(%)',
        align: 'center',
        width: 150,
        dataIndex: 'new_users_last_5week',
        key: 'new_users_last_5week',
        render: (row) => {
          return TrendChart(row)
        }
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.cumulative_register_totals')}
          </div>
        ),
        align: 'center',
        width: 100,
        dataIndex: 'cumulative_register_totals',
        key: 'cumulative_register_totals'
      }
    ]
  },
  {
    title: '流量',
    key: 'flow',
    children: [
      {
        title: <div>{getStaticText('weekly.sales.table.all_uv')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'all_uv',
        key: 'all_uv'
      },
      {
        title: '全网uv五周趋势',
        align: 'center',
        width: 150,
        dataIndex: 'all_uv_last_5week',
        key: 'all_uv_last_5week',
        render: (row) => {
          return TrendChart(row)
        }
      },
      {
        title: <div>{getStaticText('weekly.home.table.year_on_year')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'all_uv_rate_year_on_year',
        key: 'all_uv_rate_year_on_year'
      },
      {
        title: <div>{getStaticText('weekly.sales.table.uv')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'uv',
        key: 'uv'
      },
      {
        title: 'H5 uv五周趋势(%)',
        align: 'center',
        width: 150,
        dataIndex: 'uv_last_5week',
        key: 'uv_last_5week',
        render: (row) => {
          return TrendChart(row)
        }
      },
      {
        title: <div>{getStaticText('weekly.home.table.year_on_year')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'uv_rate_year_on_year',
        key: 'uv_rate_year_on_year'
      },
      {
        title: <div>{getStaticText('weekly.sales.table.app_uv')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'app_uv',
        key: 'app_uv'
      },
      {
        title: 'APP uv五周趋势(%)',
        align: 'center',
        width: 150,
        dataIndex: 'app_uv_last_5week',
        key: 'app_uv_last_5week',
        render: (row) => {
          return TrendChart(row)
        }
      },
      {
        title: <div>{getStaticText('weekly.home.table.year_on_year')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'app_uv_rate_year_on_year',
        key: 'app_uv_rate_year_on_year'
      }
    ]
  },
  {
    title: '转化率',
    key: 'conversion_rate',
    children: [
      {
        title: <div>总转化率</div>,
        align: 'center',
        width: 100,
        dataIndex: 'total_conversion',
        key: 'total_conversion'
      },
      {
        title: <div>{getStaticText('weekly.home.table.year_on_year')}</div>,
        align: 'center',
        width: 100,
        dataIndex: 'total_conversion_rate_year_on_year',
        key: 'total_conversion_rate_year_on_year'
      },
      {
        title: <div>总转化率五周趋势(%)</div>,
        align: 'center',
        width: 150,
        dataIndex: 'total_conversion_last_5week',
        key: 'total_conversion_last_5week',
        render: (row) => {
          return TrendChart(row)
        }
      },
      {
        title: 'h5 订单',
        align: 'center',
        width: 100,
        dataIndex: 'h5_orders',
        key: 'h5_orders'
      },
      {
        title: 'h5 转化率',
        align: 'center',
        width: 100,
        dataIndex: 'h5_conversion',
        key: 'h5_conversion'
      },
      {
        title: <div>H5转化率五周趋势(%)</div>,
        align: 'center',
        width: 150,
        dataIndex: 'h5_conversion_last_5week',
        key: 'h5_conversion_last_5week',
        render: (row) => {
          return TrendChart(row)
        }
      },
      {
        title: 'app 订单',
        align: 'center',
        width: 100,
        dataIndex: 'app_orders',
        key: 'app_orders'
      },
      {
        title: 'APP转化率',
        align: 'center',
        width: 100,
        dataIndex: 'app_conversion',
        key: 'app_conversion'
      },
      {
        title: <div>APP转化率五周趋势(%)</div>,
        align: 'center',
        width: 150,
        dataIndex: 'app_conversion_last_5week',
        key: 'app_conversion_last_5week',
        render: (row) => {
          return TrendChart(row)
        }
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.APP.data'),
    key: 'APP.data',
    children: [
      {
        title: <div>{getStaticText('weekly.sales.table.download_amount')}</div>,
        dataIndex: 'download_amount',
        width: 100,
        key: 'download_amount',
        align: 'center'
      },
      {
        title: <div>{getStaticText('weekly.sales.table.xpark_use_time')}</div>,
        dataIndex: 'xpark_use_time',
        width: 100,
        key: 'xpark_use_time',
        align: 'center'
      },
      {
        title: <div>{getStaticText('weekly.sales.table.old_user_ratio')}</div>,
        dataIndex: 'old_user_ratio',
        width: 100,
        key: 'old_user_ratio',
        align: 'center'
      },
      {
        title: (
          <div>{getStaticText('daily.sales.table.accumu_preassem_count')}</div>
        ),
        dataIndex: 'accumu_preassem_count',
        width: 100,
        key: 'accumu_preassem_count',
        align: 'center'
      },
      {
        title: (
          <div>{getStaticText('weekly.sales.table.app_activation_number')}</div>
        ),
        dataIndex: 'app_activation_number',
        key: 'app_activation_number',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>{getStaticText('weekly.sales.table.all_active_users')}</div>
        ),
        dataIndex: 'all_active_users',
        key: 'all_active_users',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>{getStaticText('weekly.sales.table.app_active_users')}</div>
        ),
        dataIndex: 'app_active_users',
        key: 'app_active_users',
        width: 100,
        align: 'center'
      }
    ]
  }
]

export default weeklyTableActiveColumns
