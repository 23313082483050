import React, { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { columns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getLogList } from '../services'
import { GridContextProvider, PagingTable } from '../../../../components'

export default () => {
  const [gridData, setGridData] = useState(initGridData)
  const location = useLocation()

  const onLoad = useCallback(() => {
    getLogList(setGridData)(new URLSearchParams(location.search))
  }, [location, setGridData])
  // 默认请求参数
  const [isLoading] = useState(false)

  return (
    <GridContextProvider onLoad={onLoad}>
      <PagingTable
        loading={isLoading}
        columns={columns}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.current}
        pageSize={gridData.page_size || 10}
      />
    </GridContextProvider>
  )
}
