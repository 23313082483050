import React from 'react'
import Actions from './Actions'

export const createColumns = ({ onChange }) => [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    key: 'nickname',
    align: 'center'
  },
  {
    title: 'Comment content',
    dataIndex: 'comment_content',
    key: 'comment_content',
    align: 'center'
  },
  {
    title: 'Comment At',
    dataIndex: 'comment_at',
    key: 'comment_at',
    align: 'center'
  },
  {
    title: 'Likes Count',
    dataIndex: 'comment_likes_count',
    key: 'comment_likes_count',
    width: 100,
    align: 'center'
  },
  {
    title: 'Action',
    dataIndex: '',
    width: 200,
    key: 'y',
    align: 'center',
    render: (record) => <Actions record={record} onChange={onChange} />
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
