import React, { useState } from 'react'
import { Card, Typography, Button, Space, Spin, Upload } from 'antd'
import PropTypes from 'prop-types'
import services from '../../services'
import { getStaticText, storage, checkPerms } from '../../utils'

const { Text } = Typography

const exportTemplateMap = {
  daily: services.dailySales.exportDailySalesTemplate,
  weekly: services.weeklySales.exportWeeklySalesTemplate,
  monthly: services.monthlySales.exportMonthlySalesTemplate
}

const exportMap = {
  daily: services.dailySales.exportDailySales,
  weekly: services.weeklySales.exportWeeklySales,
  monthly: services.monthlySales.exportMonthlySales
}

const TableHeader = (props) => {
  const { token } = storage.userInfo.val
  const version = storage.currentScope.val.key
  const [loading, setLoading] = useState(false)
  const { exportType, startDate, endDate, country, platform, fetchData } = props
  const importPerm = `${exportType}-sales/import`
  const exportTemplatePerm = `${exportType}-sales/export-template`
  const exportPerm = `${exportType}-sales/export`
  const reqData = {
    start_date: startDate,
    end_date: endDate,
    country,
    platform
  }
  const [fileList, setFileList] = useState([])

  const handleUpload = (info) => {
    const { file } = info
    setFileList(info.fileList.filter((file) => !!file.status))
    if (file.response) {
      fetchData()
    }
  }

  const handleExportTemplate = async () => {
    setLoading(true)
    const { url } = await exportTemplateMap[exportType]()
    window.location.href = url
    setLoading(false)
  }

  const handleExport = async () => {
    setLoading(true)
    const { url } = await exportMap[exportType](reqData)
    window.location.href = url
    setLoading(false)
  }

  return (
    <Spin spinning={loading} size="large">
      <Card>
        <Text>{getStaticText('component.table.header.title')}</Text>
        <Space style={{ cssFloat: 'right' }}>
          {checkPerms(importPerm) && (
            <Upload
              accept=".xlsx,.xls"
              showUploadList={false}
              headers={{
                Authorization: `Bearer ${token}`,
                'X-Version': version
              }}
              action={`/backend/${exportType}-sales/import`}
              fileList={fileList}
              onChange={handleUpload}
            >
              <Button type="primary">
                {getStaticText('component.table.import.text')}
              </Button>
            </Upload>
          )}
          {checkPerms(exportTemplatePerm) && (
            <Button type="primary" onClick={handleExportTemplate}>
              {getStaticText('component.table.template.text')}
            </Button>
          )}
          {checkPerms(exportPerm) && (
            <Button onClick={handleExport}>
              {getStaticText('component.table.export.text')}
            </Button>
          )}
        </Space>
      </Card>
    </Spin>
  )
}

TableHeader.propTypes = {
  exportType: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  platform: PropTypes.string,
  fetchData: PropTypes.func.isRequired
}

TableHeader.defaultProps = {
  platform: null
}

export default TableHeader
