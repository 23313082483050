import request from './request'

export const getRealtime = (params) =>
  request.get('/backend/dashboard/realtime', { params })

export const getCore = (params) =>
  request.get('/backend/dashboard/core', { params })

export const getFlow = (params) =>
  request.get('/backend/dashboard/flow', { params })

export const getRight = (params) =>
  request.get('/backend/dashboard/right', { params })

export const getAfterSale = (params) =>
  request.get('/backend/dashboard/after-sale', { params })

export const getProductAnalystics = (params) =>
  request.get('/backend/dashboard/product-analystics', { params })

export const getBusinessTop = (params) =>
  request.get('/backend/dashboard/business-top', { params })

export const getBusinessTopFunnel = (params) =>
  request.get('/backend/dashboard/business-top-funnel', { params })

export const getBusinessTopCategory = (params) =>
  request.get('/backend/dashboard/business-top-category', { params })

export const getBusinessGoodsNameList = (params) =>
  request.get('/backend/dashboard/business-goods-name', { params })

export const getBusinessGoods = (params) =>
  request.get('/backend/dashboard/business-goods', { params })

export const getGoodsTop = (params) =>
  request.get('/backend/dashboard/goods-top', { params })

export const fetchTableMap = (params) =>
  request.get('/backend/dashboard/goods-table-map', { params })

export const getGoodsTable = (params) =>
  request.get('/backend/dashboard/goods-table', { params })

export const getGoodsExport = (params) =>
  request.get('/backend/dashboard/goods-export', { params })

export const getFlowTop = (params) =>
  request.get('/backend/dashboard/flow-top', { params })

export const getFlowGoods = (params) =>
  request.get('/backend/dashboard/flow-goods', { params })
