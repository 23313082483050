import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Space, Card, Typography, Button, DatePicker } from 'antd'
import { getStaticText } from '../../../utils'
import services from '../../../services'
import dateFormat from '../../../config/dateFormat'

const dateFormatMonth = dateFormat.month
const { Text } = Typography
const { RangePicker } = DatePicker

const requestParams = {
  start_date: moment().subtract('months', 12),
  end_date: moment().subtract('months', 1)
}

const requestOption = {
  grid: {
    bottom: 40,
    top: 40,
    left: 40,
    containLabel: true
  },
  toolbox: {
    show: true,
    feature: {
      dataView: {
        readOnly: false,
        title: '编辑'
      },
      restore: {
        show: true,
        title: '重置'
      },
      saveAsImage: {
        show: true,
        title: '下载'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    data: []
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      name: '$'
    },
    {
      name: '%',
      axisLabel: {
        formatter: '{value} %'
      }
    }
  ],
  series: []
}

const getbarLabel = {
  show: true,
  position: 'top',
  formatter: (value) =>
    value.data.value.toString().length > 4
      ? `${parseInt(value.data.value / 10000, 10) + 1}万`
      : value.data.value
}

const getLineLabel = {
  show: true,
  position: 'top',
  formatter: (value) => {
    return `${value.data.value}%`
  }
}

class MonthCrossNgGmv extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatMonth)
      : requestParams.end_date
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatMonth)
      : requestParams.start_date
    this.state = {
      start_date: startDate,
      start_format: startDate.format(dateFormatMonth),
      end_date: endDate,
      end_format: endDate.format(dateFormatMonth),
      series: []
    }
    this.useEffect()
  }

  getrequest = () => {
    return {
      start_date: this.state.start_format,
      end_date: this.state.end_format
    }
  }

  handleDateChange = (date, dateString) => {
    this.setState({
      start_date: moment(dateString[0], dateFormatMonth),
      start_format: dateString[0],
      end_date: moment(dateString[1], dateFormatMonth),
      end_format: dateString[1]
    })
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchData()
  }

  fetchData = () => {
    services.mapList.monthCrossNgGmv(this.getrequest()).then((result) => {
      const series = []
      Object.keys(result.series).forEach((item, index) => {
        if (result.series[item] instanceof Array) {
          result.series[item] = result.series[item].map((value) => {
            if (value.type === 'bar') {
              value.label = getbarLabel
            } else if (value.type === 'line') {
              value.label = getLineLabel
              value.yAxisIndex = 1
            }
            return value
          })
        }
        series.push({
          ...requestOption,
          legend: {
            data: [result.series[item][0].name, result.series[item][1].name]
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              data: result.catalog
            }
          ],
          series: result.series[item],
          index
        })
      })
      this.setState({
        series
      })
    })
  }

  getSeries = () => {
    return this.state.series.map((option) => {
      return (
        <ReactEcharts
          style={{ width: '100%' }}
          option={option}
          key={option.index}
        />
      )
    })
  }

  render() {
    return (
      <>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <RangePicker
              picker="month"
              value={[this.state.start_date, this.state.end_date]}
              format={dateFormatMonth}
              onChange={this.handleDateChange}
              allowClear={false}
            />
          </Space>
          <Space style={{ cssFloat: 'right' }}>
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        {this.getSeries()}
      </>
    )
  }
}

MonthCrossNgGmv.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default MonthCrossNgGmv
