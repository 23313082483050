import { combineReducers } from 'redux'

import users from './users'
import global from './global'
import i18n from './i18n'

export default combineReducers({
  users,
  global,
  i18n
})
