import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Card, Space } from 'antd'
import {
  CountryFilter,
  TextFilter,
  SubmitButton,
  ResetButton,
  SourceFilter
} from '../../../../components'
// import { getStaticText } from '../../../../utils'

const Filters = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const history = useHistory()
  const [params, setParams] = useState({
    country: searchParams.get('country') || '',
    customer_name: searchParams.get('customer_name') || '',
    customer_group: searchParams.get('customer_group') || ''
  })
  const paramChanged = (name) => (value) => {
    setParams({ ...params, [name]: value })
  }
  const handleSubmit = (params) => () => {
    const strParams = new URLSearchParams(params).toString()
    history.push(`${location.pathname}?${strParams}`)
  }
  const handleReset = (initParams, setParams) => () => {
    setParams(initParams)
  }

  return (
    <Card
      style={{
        marginBottom: 20
      }}
    >
      <Space>
        <CountryFilter
          mode="single"
          value={params.country}
          onChange={paramChanged('country')}
        />
        <SourceFilter
          label="Customer Group"
          source="ex_customer_groups"
          value={params.customer_group}
          onChange={paramChanged('customer_group')}
        />
        <TextFilter
          name="Name"
          value={params.customer_name}
          onChange={paramChanged('customer_name')}
        />
      </Space>
      <ResetButton onReset={handleReset({}, setParams)} />
      <SubmitButton onSubmit={handleSubmit(params)} />
    </Card>
  )
}

export default Filters
