/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {Divider, Typography, Input, Form} from 'antd'
import BlackButton from "../../../components/1688ProjectComponents/ButtonComponents/BlackButton";
import BraftEditor from "braft-editor";
import { convertRawToHTML } from "braft-convert";
import '../../../css/editProduct.css'
import WhiteButton from "../../../components/1688ProjectComponents/ButtonComponents/WhiteButton";
import {uploadFileToServer} from "../../Membership/Privileges/services";

const { Title } = Typography



const imageControls = [
  'link',
  'size',
  'remove'
]

const mediaProps = {
  uploadFn: null,
  validateFn: null,
  externalMedias: {
    image: true,
    audio: false,
    video: false,
    embed: false
  }
};

interface Props {
  descriptionData: any;
  setDescription: Function;
};

const DescriptionProduct = ({ descriptionData, setDescription }: Props) => {
  const [isEdit, setIsEdit] = useState(false)
  const [form] = Form.useForm()

  useEffect(() => {
    if (descriptionData) {
      const editorState = htmlToEditorState(descriptionData);
      form.setFieldValue('description', editorState);
    }
  }, [descriptionData]);

  const controls = [
    'bold',
    'italic',
    'underline',
    'text-color',
    'separator',
    'link',
    'separator',
    "list-ul",
    "list-ol",
    "media"
  ]

  function onFormChange(value) {
    let data = form.getFieldValue('description')
    setDescription(convertRawToHTML(JSON.parse(data.toRAW())))
  }

  function htmlToEditorState(htmlContent) {
    return BraftEditor.createEditorState(htmlContent);
  }

  const titlePage = () => {
    return (
      <Title level={2} style={{ marginBottom: 0, padding: '24px' }}>
        Description
      </Title>
    )
  }

  const uploadMedia = (param) => {
    const fd = new FormData()
    fd.append('file', param.file)
    uploadFileToServer({ params: fd })
      .then((res) => {
        param.success({
          url: res.url,
          meta: {
            controls: true,
            poster: res.video_preview
          }
        })
      })
      .catch(() => {
        param.error({
          msg: 'unable to upload.'
        })
      })
  }

  const isHTML = (str) => {
    if (str) {
      const doc = new DOMParser().parseFromString(str, 'text/html')
      return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1)
    }
  }

  return (
    <Form form={form}>
      <div style={{width: '100%'}}>{titlePage()}</div>
      <Divider style={{color: 'black', margin: 'unset'}}/>
      <div
        className="description"
        style={{display: 'flex', flexDirection: 'column', padding: '24px'}}
      >
        {descriptionData && isHTML(descriptionData) ? (
          <div dangerouslySetInnerHTML={{__html: descriptionData}}/>
        ) : null}
      </div>
      <div>
        {isEdit && (
          <>
            <Title level={4} style={{ marginBottom: 0, padding: '24px' }}>
              Edit description here
            </Title>
            <Form.Item label="" name="description">
              <BraftEditor
                imageControls={imageControls}
                className="my-editor"
                controls={controls}
                placeholder="Edit description here"
                media={{ uploadFn: uploadMedia }}
              />
            </Form.Item>
            <WhiteButton
              title={"Save Preview change"}
              functionParent={onFormChange}
            />
          </>
        )}
      </div>
      <div>
        <BlackButton
          title={ !isEdit ? `Edit Description` : 'Cancel Edit'}
          functionParent={() => setIsEdit((prev) => !prev)}
        />
      </div>

    </Form>
  )
}

export default DescriptionProduct
