import React from 'react'

import { getStaticText } from '../../utils'

const goodsColumns = [
  {
    title: <div>{getStaticText('weekly.sales.table.country')}</div>,
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 50,
    align: 'center'
  },
  {
    title: <div>{getStaticText('goods.goods_name')}</div>,
    dataIndex: 'goods_name',
    key: 'goods_name',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('goods.people_group')}</div>,
    dataIndex: 'people_group',
    key: 'people_group',
    fixed: 'left',
    width: 50,
    align: 'center'
  },
  {
    title: <div>{getStaticText('goods.user')}</div>,
    dataIndex: 'user',
    key: 'user',
    fixed: 'left',
    width: 50,
    align: 'center'
  },
  {
    title: <div>{getStaticText('goods.territory')}</div>,
    dataIndex: 'territory',
    key: 'territory',
    fixed: 'left',
    width: 200,
    align: 'center',
    render: (data) => (
      <div className="auth-magin" dangerouslySetInnerHTML={{ __html: data }} />
    )
  },
  {
    title: <div>{getStaticText('goods.preference')}</div>,
    dataIndex: 'preference',
    key: 'preference',
    fixed: 'left',
    width: 200,
    align: 'center',
    render: (data) => (
      <div className="auth-magin" dangerouslySetInnerHTML={{ __html: data }} />
    )
  },
  {
    title: <div>{getStaticText('goods.professional')}</div>,
    dataIndex: 'professional',
    key: 'professional',
    fixed: 'left',
    width: 200,
    align: 'center',
    render: (data) => (
      <div className="auth-magin" dangerouslySetInnerHTML={{ __html: data }} />
    )
  }
]

export default goodsColumns
