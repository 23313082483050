import React, { useCallback, useEffect, useState, useContext } from 'react'
import { Form, Row, Col, Input, Select } from 'antd'
import { GridFormDrawer, GridContext } from '../../../../components'

import { getRewriteDetail, createRewrite, updateRewrite } from '../services'

const RewriteForm = () => {
  const { Option } = Select
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const [urlType, setUrlType] = useState('')

  const openHandler = getRewriteDetail(setFormData)
  const { onLoad, toggle } = useContext(GridContext)
  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }
  const emptyForm = () => {
    setUrlType('')
    setFormData({})
    form.resetFields()
  }

  const onFinish = (values) => {
    if (formData.id) {
      updateRewrite(closeDrawer)({ data: values, id: formData.id })
    } else {
      createRewrite(closeDrawer)({ data: values })
    }
  }

  const handleChange = useCallback((value) => {
    setUrlType(value)
  }, [])

  useEffect(() => {
    setUrlType(formData.redirect_type || '')
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Url' : 'New Url')}
    >
      <Form form={form} onFinish={onFinish} autoComplete="off">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="redirect_type"
              label="Redirect Type"
              rules={[
                { required: true, message: 'Please select redirect type' }
              ]}
            >
              <Select onChange={handleChange}>
                <Option value="page">page</Option>
                <Option value="direct">direct</Option>
                <Option value="301">301</Option>
                <Option value="302">302</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="url_key" label="Url Key">
              <Input placeholder="Please enter url key" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="target_url" label="Target Url">
              <Input
                disabled={urlType === 'page'}
                placeholder="Please enter target url"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="target_id"
              label="Target Id"
              rules={[{ type: 'number', transform: (value) => Number(value) }]}
            >
              <Input
                disabled={urlType !== 'page' && urlType !== ''}
                placeholder="Please enter target id"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="page_type" label="Page Type">
              <Input
                disabled={urlType !== 'page' && urlType !== ''}
                placeholder="Please enter page type"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </GridFormDrawer>
  )
}
export default RewriteForm
