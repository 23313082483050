/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Divider,
  Image,
  message,
  Row,
  Select,
  Typography,
  Form
} from 'antd'
import FilterPublicCollection from '../../components/1688ProjectComponents/PublicCollectionBox/FilterPublicCollection'
import TablePublicCollection from '../../components/1688ProjectComponents/TableComponents/TablePublicCollection'
import StickyFooter from '../../components/1688ProjectComponents/FooterComponents/StickyFooter'
import BlackInputSelect from '../../components/1688ProjectComponents/inputComponent/BlackInputSelect'
import BlackButton from '../../components/1688ProjectComponents/ButtonComponents/BlackButton'
import WhiteButton from '../../components/1688ProjectComponents/ButtonComponents/WhiteButton'
import {
  getPubBoxProductList,
  sendPubBoxClaim,
  deleteProduct,
  getAuthorPubBox
} from '../../services/1688Projects/collection'
import imagePlaceholder from '../../assets/empty.webp'
import GrayInputSelect from '../../components/1688ProjectComponents/inputComponent/GrayInputSelect'

const { Title } = Typography
const { Option } = Select

const CountryEnum = {
  Nigeria: "NG",
  Pakistan: "PH",
  Kenya: "KE",
  Morocco: "MA"
}

interface GetProduct {
  status: Number | null;
  goPage: Number;
}
const PublicCollectionBox = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRegular, setIsLoadingRegular] = useState(false)
  const [productData, setProductData] = useState([])
  const [valueSelect, setValueSelect] = useState(null)
  const [pageNow, setPageNow] = useState(1)
  const [sizePage, setSizePage] = useState(10)
  const [totalItem, setTotalItem] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [totalUnClaimed, setTotalUnClaimed] = useState(0)
  const [totalClaimed, setTotalClaimed] = useState(0)
  const [totalFailed, setTotalFailed] = useState(0)
  const [filterTab, setFilterTab] = useState(4)
  const [totalAll, setTotalAll] = useState(0)
  const [valueBulkClaim, setValueBulkClaim] = useState(null)
  const [tmpStatus, setTmpStatus] = useState(null)
  const [deleteProductValue, setDeleteProductValue] = useState(null)
  const [creatorOption, setCreatorOption] = useState([])
  const [filterFormData, setFilterFormData] = useState({})
  const [platFormOptions, setPlatformOptions] = useState([])
  const [form] = Form.useForm()

  async function getProductList({
    status = null,
    goPage = 1
  }: GetProduct = {}) {

    if (status || status === 0) {
      setTmpStatus(status)
    }
    setIsLoading(true)
    try {
      const data = {}
      if (filterFormData) {
        if (filterFormData['filter[product_title]']) {
          data['filter[product_title]'] = filterFormData['filter[product_title]'] || ''
        }
        if (filterFormData['filter[supply_id]']) {
          data['filter[supply_id]'] = filterFormData['filter[supply_id]'] || ''
        }
        if (filterFormData['filter[min_price]']) {
          data['filter[min_price]'] = filterFormData['filter[min_price]'] || ''
        }
        if (filterFormData['filter[max_price]']) {
          data['filter[max_price]'] = filterFormData['filter[max_price]'] || ''
        }
        if (filterFormData['filter[author]']) {
          data['filter[author]'] = filterFormData['filter[author]']
        }
        if (filterFormData['filter[platform]']) {
          data['filter[platform]'] = filterFormData['filter[platform]']
        }

      }
      if (status) {
        if (status === 4) {
          setFilterTab(status)
        } else {
          data['filter[action]'] = status
          setFilterTab(status)
        }
        data.beginPage = goPage
        setPageNow(goPage)
      } else if (status === 0) {
        data['filter[action]'] = status
        data.beginPage = 1
        setPageNow(1)
        setFilterTab(0)
      } else if (Number(tmpStatus) === 0 && tmpStatus < 4) {
        data['filter[action]'] = tmpStatus
        if (goPage) {
          data.beginPage = goPage
          setPageNow(goPage)
        }
      } else {
        data.beginPage = goPage
        setPageNow(goPage)
      }

      if (sizePage) {
        data.pageSize = sizePage
      }
      const productRes = (await getPubBoxProductList(data)) || []
      if (productRes?.totalRecords) {
        setTotalCount(productRes.totalRecords)

      } else {
        setTotalCount(0)
      }
      const unClaimedData = productRes.totalCountAction0
      if (unClaimedData) {
        setTotalUnClaimed(unClaimedData)
      } else {
        setTotalUnClaimed(0)
      }
      const claimedData = productRes.totalCountAction1
      if (claimedData) {
        setTotalClaimed(claimedData)
      } else {
        setTotalClaimed(0)
      }
      const failedData = productRes.totalCountAction2
      if (failedData) {
        setTotalFailed(failedData)
      } else {
        setTotalFailed(0)
      }
      const totalData = productRes.totalCountAction4
      if (totalData) {
        setTotalAll(totalData)
      } else {
        setTotalAll(0)
      }

      if (productRes.list.length > 0) {
        const newArr = []

        productRes.list.forEach((item) => {
          let priceRange = '-'
          let priceTop = 0
          let priceBottom = 0
          if (item?.sale_info) {
            const { priceRangeList } = item.sale_info
            priceBottom = priceRangeList[priceRangeList.length - 1].price
            priceTop = priceRangeList[0].price
            priceRange = `
            ${priceRangeList[priceRangeList.length - 1].price} ~ 
            ${priceRangeList[0].price}`
          }

          const newObj = {
            key: item.id,
            product: {
              url: item?.images?.length > 0 ? item?.images[0] : null,
              title: item?.subject_trans || '',
              collectionId: item.id,
              supplyId: item?.offer_id
            },
            price: {
              priceTop,
              priceBottom,
              cny: `CNY ${priceRange || '-'}`,
              usd: '-'
            },
            stock: item.sale_info?.amountOnSale || '-',
            status: Number(item.action),
            more: {
              account: item.author || '',
              date: item.created_at || '-'
            },
            platform: item.platform || '-'
          }
          newArr.push(newObj)
        })
        setProductData(newArr)
        setTotalItem(newArr?.length > 0 ? newArr?.length : 0)
      } else {
        setTotalItem(0)
        setProductData([])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }
  const onShowSizeChange = (current, pageSize) => {
    setSizePage(pageSize)
  }
  const handleClaim = async (value) => {
    setIsLoadingRegular(true)
    const [id, platform] = value.split(',')
    const claim = []
    if (id && platform) {
      claim.push({
        id: `${id}`,
        platform: [platform]
      })
    }
    try {
      await sendPubBoxClaim({ claim })
      await getProductList({ goPage: 1, status: tmpStatus })
      message.success('Success claim!')
      setPageNow(1)
    } catch (e) {
      message.success('Failed to claim')
      console.log(e)
    } finally {
      setIsLoadingRegular(false)
    }
  }

  async function getAuthorData() {
    try {
      const response = await getAuthorPubBox()
      if (response && response?.length > 0) {
        const dataRes = [{label: "All", value: null}]
        for (let i = 0; i < response.length; i++) {
          dataRes.push({
            label: response[i].author,
            value: response[i].author
          })
        }
        setCreatorOption(dataRes)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleSelectAll = () => {
    const allRowKeys = productData.map((item) => item.key)
    setSelectedRowKeys(allRowKeys)
  }
  const handleDeselectAll = () => {
    setSelectedRowKeys([])
  }
  const onChangePage = (value) => {
    if (value) {
      setPageNow(Number(value))
      getProductList({ goPage: value })
    }
  }
  const handleFilterClaimed = () => {
    getProductList({ status: 1 })
  }
  const handleFilterAll = () => {
    getProductList({ status: 4 })
  }
  const handleFilterUnClaimed = () => {
    getProductList({ status: 0 })
  }
  const handleFilterFailed = () => {
    getProductList({ status: 2 })
  }

  function setAllowedCountry() {
    let platForm =  [
        {
          label: 'Nigeria',
          value: 'XPARK-NG',
          disabled: true
        },
        {
          label: 'Pakistan',
          value: 'XPARK-PK',
          disabled: true
        },
        {
          label: 'Kenya',
          value: 'XPARK-KE',
          disabled: true
        },
        {
          label: 'Ghana',
          value: 'XPARK-GH',
          disabled: true
        },
        {
          label: 'Morocco',
          value: 'XPARK-MA',
          disabled: true
        }
      ]
    let getFromLocal = JSON.parse(localStorage.getItem('allowedCountries'))
    if (getFromLocal && getFromLocal.length > 0) {
      let newPlatform = []
      for (let platFormKey in platForm) {
        let platFormNow = platForm[platFormKey].value.split("-")[1]
        for (let getFromLocalKey in getFromLocal) {
          let localFormNow = getFromLocal[getFromLocalKey].value
          if (platFormNow === localFormNow) {
            newPlatform.push({
              label: platForm[platFormKey].label,
              value: platForm[platFormKey].value,
              disable: getFromLocal[getFromLocalKey].disabled
            })
          }
        }
      }
      setPlatformOptions(newPlatform)
    }
  }
  function setAllowedClaim(key) {
    let platForm =  [
      {
        label: 'Nigeria',
        value: `${key},XPARK-NG`,
        disabled: true
      },
      {
        label: 'Pakistan',
        value: `${key},XPARK-PK`,
        disabled: true
      },
      {
        label: 'Kenya',
        value: `${key},XPARK-KE`,
        disabled: true
      },
      {
        label: 'Ghana',
        value: `${key},XPARK-GH`,
        disabled: true
      },
      {
        label: 'Morocco',
        value: `${key},XPARK-MA`,
        disabled: true
      }
    ]
    let getFromLocal = JSON.parse(localStorage.getItem('allowedCountries'))
    if (getFromLocal && getFromLocal.length > 0) {
      let newPlatform = []
      for (let platFormKey in platForm) {
        let platFormNow = platForm[platFormKey].value.split("-")[1]
        for (let getFromLocalKey in getFromLocal) {
          let localFormNow = getFromLocal[getFromLocalKey].value
          if (platFormNow === localFormNow) {
            newPlatform.push({
              label: platForm[platFormKey].label,
              value: platForm[platFormKey].value,
              disable: getFromLocal[getFromLocalKey].disabled
            })
          }
        }
      }

      let contents = []

      for (let key in newPlatform) {
        contents.push(
          <Option value={newPlatform[key].value}> {newPlatform[key].label} </Option>
        )
      }

      return contents
    }
  }

  useEffect(() => {
    getProductList({ goPage: 1 })
    // eslint-disable-next-line
  }, [filterFormData])
  useEffect(() => {
    setAllowedCountry()
    console.log({ localCountry: JSON.parse(localStorage.getItem('allowedCountries')) })
    getAuthorData()
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  }
  const titlePage = () => {
    return (
      <Title level={2} style={{ marginBottom: 0, padding: '24px' }}>
        Public Collection Box
      </Title>
    )
  }
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a className="button-pagination previous">Previous</a>
    }
    if (type === 'next') {
      return <a className="button-pagination next">Next</a>
    }
    return originalElement
  }

  const handleDeleteProduct = async (e) => {
    try {
      const newArr = []
      newArr.push(Number(e))
      const newData = {}
      newData.id = newArr
      await deleteProduct(newData)
      await getProductList({ goPage: 1 })
    } catch (e) {
      console.log(e)
    }
  }

  const handleBackToNullBulkClaim = async (value) => {
    try {
      const claim = []

      if (selectedRowKeys?.length > 0 && value) {
        selectedRowKeys.forEach((item) => {
          if (item?.length > 0) {
            claim.push({
              id: `${item}`,
              platform: [value]
            })
          }
        })
        if (claim.length > 0) {
          await sendPubBoxClaim({ claim })
          await getProductList({ goPage: 1, status: tmpStatus })
          setPageNow(1)
        }
        message.success('Success to claim!')
      } else {
        message.error('Failed to claim!')
      }
    } catch (e) {
      message.error('Failed to claim!')
      console.log(e)
    } finally {
      setValueBulkClaim(null)
    }
  }

  const handleDeleteBulkProduct = async (e) => {
    try {
      if (e) {
        setIsLoading(true)
        setDeleteProductValue(null)
        if (productData?.length > 0 && e && selectedRowKeys) {
          const data = productData
            .map((item) => {
              const isInclude = selectedRowKeys.includes(item.key)
              if (isInclude) {
                return Number(item.key)
              }
              return null
            })
            .filter((item) => item !== null)
          await deleteProduct({ id: data })
          await getProductList({ goPage: 1 })
          message.success('Success Delete')
        }
      } else {
        handleDeselectAll()
        message.info('Cancel the process')
        setDeleteProductValue(null)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const columns = [
    {
      title: 'Product Information',
      dataIndex: 'product',
      render: (source) => (
        <div style={{ display: 'flex' }}>
          <div>
            <Image
              style={{ width: '163px', height: 'unset' }}
              src={source?.url || imagePlaceholder}
              preview={false}
            />
          </div>
          <div style={{ width: '200px', marginLeft: '10px' }}>
            <Col span={24}>
              <p style={{ color: 'black' }}>{source.title}</p>
            </Col>
            <Col span={24}>
              <Col span={24}>
                <p style={{ color: '#606266', marginBottom: 'unset' }}>
                  {`采集箱产品ID： ${source.collectionId}`}
                </p>
              </Col>
              <Col span={24}>
                <p style={{ color: '#606266', marginBottom: 'unset' }}>
                  {`货源ID： ${source.supplyId}`}
                </p>
              </Col>
            </Col>
          </div>
        </div>
      )
    },
    {
      title: 'Source Price',
      dataIndex: 'price',
      sorter: (a, b) => {
        return a.price.priceBottom - b.price.priceTop
      },
      render: (price) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div>{price.cny}</div>
          <div>{price.usd}</div>
        </div>
      )
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      sorter: (a, b) => {
        return a.stock - b.stock
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: (a, b) => {
        return a.status - b.status
      },
      render: (status) => (
        <>
          {Number(status) === 0 && <div>Unclaimed</div>}
          {Number(status) === 1 && (
            <div style={{ color: '#0EAA00' }}>Claimed</div>
          )}
          {Number(status) === 2 && (
            <div style={{ color: '##FA1111' }}>
              Collection
              <br />
              Failed
            </div>
          )}
        </>
      )
    },
    {
      title: 'Account Name \n Date - Time Created',
      dataIndex: 'more',
      sorter: (a, b) => {
        return new Date(a.more.date) - new Date(b.more.date)
      },
      render: (more) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div>{more.account}</div>
          <div>{more.date}</div>
        </div>
      )
    },
    {
      title: 'Platform',
      dataIndex: 'platform'
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          {/* <Link */}
          {/*   to={`/common-function/public-collection-box/edit-selected-item?id=${record.product.collectionId}`} */}
          {/*   style={{ paddingInline: '16px', color: 'black' }} */}
          {/* > */}
          {/*   Edit */}
          {/* </Link> */}
          <Button type="text" onClick={() => handleDeleteProduct(record.key)}>
            Delete
          </Button>
          <Select
            loading={isLoadingRegular}
            value={valueSelect}
            onChange={(e) => {
              handleClaim(e)
              setValueSelect(null)
            }}
            bordered={false}
            placeholder="Claim to"
          >
            {setAllowedClaim(record.key)}
          </Select>
          {/* <Button type="text">Remark</Button> */}
        </div>
      )
    }
  ]
  return (
    <div
      style={{ width: '100%', background: '#F8F8F8', position: 'relative' }}
      className="pub-collection-box"
    >
      <div style={{ width: '100%' }}>{titlePage()}</div>
      <Divider style={{ color: 'black', margin: 'unset' }} />
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
      >
        <FilterPublicCollection
          formRef={form}
          creatorOption={creatorOption}
          getProductList={getProductList}
          isloading={isLoading}
          callback={(e) => setFilterFormData(e)}
          platFormOptions={platFormOptions}
        />
        <Row gutter={[0, 2]} style={{ marginTop: '100px' }}>
          <Col span={4}>
            <BlackInputSelect
              funcParent={handleBackToNullBulkClaim}
              value={valueBulkClaim}
              placeHolder="Bulk Claim"
              option={platFormOptions}
            />
          </Col>
          {/* <Col span={4}> */}
          {/* <BlackButton title="Create Product" /> */}
          {/* </Col> */}
          <Col span={4}>
            <GrayInputSelect
              funcParent={handleDeleteBulkProduct}
              value={deleteProductValue}
              placeHolder="Delete Product"
              color="white"
              option={[
                {
                  label: 'Confirm delete',
                  value: 1
                },
                {
                  label: 'Abort delete',
                  value: 0
                }
              ]}
            />
          </Col>
          <Col span={4} />
          <Col span={4} />
          <Col span={4} />
          <Col span={4} />
          <Col span={24} />
          <Col span={24} />
          <Col span={24} />
          <Col span={2}>
            {filterTab === 4 ? (
              <BlackButton
                functionParent={handleFilterAll}
                title={`All (${totalAll})`}
              />
            ) : (
              <WhiteButton
                functionParent={handleFilterAll}
                title={`All (${totalAll})`}
              />
            )}
          </Col>
          <Col span={3}>
            {filterTab === 0 ? (
              <BlackButton
                functionParent={handleFilterUnClaimed}
                title={`Unclaimed (${totalUnClaimed})`}
              />
            ) : (
              <WhiteButton
                functionParent={handleFilterUnClaimed}
                title={`Unclaimed (${totalUnClaimed})`}
              />
            )}
          </Col>
          <Col span={3}>
            {filterTab === 1 ? (
              <BlackButton
                functionParent={handleFilterClaimed}
                title={`Claimed (${totalClaimed})`}
              />
            ) : (
              <WhiteButton
                functionParent={handleFilterClaimed}
                title={`Claimed (${totalClaimed})`}
              />
            )}
          </Col>
          <Col span={4}>
            {filterTab === 2 ? (
              <BlackButton
                functionParent={handleFilterFailed}
                title={`Collection Failed (${totalFailed})`}
              />
            ) : (
              <WhiteButton
                functionParent={handleFilterFailed}
                title={`Collection Failed (${totalFailed})`}
              />
            )}
          </Col>
          <Col span={12} />
        </Row>
        <TablePublicCollection
          columns={columns}
          dataTable={productData}
          rowSelection={rowSelection}
          loading={isLoading}
          rowKey="key"
        />
        <StickyFooter
          totalItem={totalItem}
          handleCheckAll={handleSelectAll}
          handleUncheckAll={handleDeselectAll}
          itemRender={itemRender}
          totalPage={totalCount}
          onChangePage={onChangePage}
          page={pageNow}
          sizePage={sizePage}
          onShowSizeChange={onShowSizeChange}
          itemSelected={selectedRowKeys?.length || 0}
        />
      </div>
    </div>
  )
}

export default PublicCollectionBox
