import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getStaticText } from '../../utils'

const viewTableColumns = [
  {
    title: (
      <div>
        {getStaticText('weekly.sales.table.country')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('weekly.sales.table.country')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.Affinity_name')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('big-query.Affinity_name')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'Affinity_name',
    key: 'Affinity_name',
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.start_time')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('big-query.start_time')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'start_time',
    key: 'start_time',
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.end_time')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('big-query.end_time')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'end_time',
    key: 'end_time',
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.score')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('big-query.score')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'score',
    key: 'score',
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.click_count')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('big-query.click_count')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'click',
    key: 'click',
    align: 'center'
  }
]

export default viewTableColumns
