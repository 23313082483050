import React, { useEffect } from 'react'
import {
  shape,
  bool,
  string,
  func,
  arrayOf,
  number,
  oneOfType
} from 'prop-types'
import {
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Typography,
  Button,
  Space,
  InputNumber
} from 'antd'
import { cloneDeep } from 'lodash'
import { getStaticText } from '../../utils'

const FormItem = Form.Item
const { Title } = Typography

// 字段能调用的组件map
const fieldMap = {
  string: { component: Input, props: {} },
  number: {
    component: InputNumber,
    props: {
      style: { width: '100%' },
      formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      parser: (value) => value.replace(/(,*)/g, '')
    }
  }
}

const PeopleYardDrawer = (props) => {
  const {
    drawerProps,
    columns,
    dataSource,
    onClose,
    colNum,
    handleFinish,
    loading,
    title
  } = props

  const colSpan = 24 / colNum

  const [form] = Form.useForm()

  useEffect(() => {
    const cloneDataSource = cloneDeep(dataSource)

    // 取出数据做格式化处理
    const formValue = Object.keys(cloneDataSource).reduce((prev, next) => {
      prev[next] = cloneDataSource[next].value
      if (typeof cloneDataSource[next].value === 'string') {
        prev[next] = cloneDataSource[next].value.replace(/(,*)/g, '')
      }

      return prev
    }, {})

    // 设置form字段数据
    form.setFieldsValue(formValue)
  }, [form, dataSource])

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        handleFinish(values)
      })
      .catch((error) => {
        form.scrollToField(error.errorFields[0].name.toString())
      })
  }

  const handleClose = () => {
    if (!loading) onClose()
  }

  const footer = (
    <div style={{ textAlign: 'right' }}>
      <Space>
        <Button onClick={handleClose}>
          {getStaticText('component.edit.modal.cancel.text')}
        </Button>
        <Button type="primary" onClick={onFinish} loading={loading}>
          {getStaticText('component.edit.modal.ok.text')}
        </Button>
      </Space>
    </div>
  )

  /**
   * 根据字段类型获取组件
   *
   * @param {String} type
   * @param {Object} prop
   * @returns
   */
  const getComponentForType = (value, prop = {}) => {
    const type = typeof value
    const componentType = fieldMap[type] || { component: Input, props: {} }
    const Component = componentType.component
    return <Component {...componentType.props} {...prop} />
  }

  // render每个form字段
  const renderField = (dataSource, column) => {
    const fieldColumn = dataSource.find((field) => field.key === column.key)
    return (
      <FormItem
        label={column.label}
        name={column.key}
        initialValue={column.value}
        rules={[
          {
            required: false,
            type: column.type,
            transform: (value) => {
              if (column.type === 'number') {
                return Number(value)
              }
              return value
            }
          }
        ]}
      >
        {fieldColumn ? (
          getComponentForType(fieldColumn.value, {
            disabled: !fieldColumn.editable
          })
        ) : (
          <Input />
        )}
      </FormItem>
    )
  }

  return (
    <div className="edit-drawer">
      <Drawer
        title={title}
        key={drawerProps.placement}
        {...drawerProps}
        onClose={handleClose}
        footer={footer}
        getContainer={false}
        destroyOnClose
      >
        <Form layout="vertical" form={form} name="edit">
          {columns.map((column) => {
            const children = column.children || []

            // 有多个子集
            return (
              <div key={column.key} className="column-group">
                <Title level={5}>{column.title}</Title>
                <Row gutter={20}>
                  {children.map((childrenColumn) => {
                    return (
                      <Col key={childrenColumn.key} span={colSpan}>
                        {renderField(dataSource, childrenColumn)}
                      </Col>
                    )
                  })}
                </Row>
              </div>
            )
          })}
        </Form>
      </Drawer>
    </div>
  )
}

PeopleYardDrawer.propTypes = {
  drawerProps: shape({
    width: oneOfType([string, number]),
    visible: bool,
    placement: string
  }).isRequired,
  columns: arrayOf(shape()).isRequired,
  dataSource: arrayOf(shape()).isRequired,
  onClose: func,
  colNum: number,
  handleFinish: func,
  loading: bool,
  title: string
}

PeopleYardDrawer.defaultProps = {
  onClose: () => {},
  colNum: 2,
  handleFinish: () => {},
  loading: false,
  title: getStaticText('table.operating.button.text')
}

export default PeopleYardDrawer
