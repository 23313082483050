/* eslint-disable */
import React, { useCallback } from 'react'
import { Layout, Avatar, Dropdown, Menu, message, Space, Button } from 'antd'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getStaticText, storage } from '../../utils'
import { resetUserInfo } from '../../store/actions/users'
import { setCurrentScope } from '../../store/actions/global'
import services from '../../services'
import ScopeSwitcher from './ScopeSwitcher'
import routes from '../../routes/routes'

const { Header } = Layout
const AppHeader = ({ resetUserInfo, setCurrentScope, user }) => {
  const history = useHistory()
  const handleLogout = async () => {
    await services.users.logout()
    setCurrentScope({})
    resetUserInfo()
    message.info(getStaticText('app.logout.message.success'))
    storage.userInfo.remove()
    history.push('/login')
  }
  const getIndexPath = (routes) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const route of routes) {
      if (route.routes) {
        const path = getIndexPath(route.routes)
        if (path) {
          return path
        }
      }
      if (
        (storage.userInfo.val.user.permissions.indexOf('/') !== -1 ||
          storage.userInfo.val.user.permissions.indexOf(route.permission) !==
            -1) &&
        route.modular &&
        route.modular === storage.modular.val
      ) {
        return route.path
      }
    }
    return ''
  }
  const checkLink = (modular) => {
    storage.modular.val = modular
    // eslint-disable-next-line no-use-before-define
    const path = getIndexPath(routes)
    setTimeout(() => history.push(path), 1000)
  }
  const getPermissionsModular = useCallback((routes) => {
    let modulars = []
    // eslint-disable-next-line no-restricted-syntax,no-undef
    for (const route of routes) {
      if (route.routes) {
        modulars = modulars.concat(getPermissionsModular(route.routes))
      } else if (
        route.modular &&
        (storage.userInfo.val.user.permissions.indexOf('/') !== -1 ||
          storage.userInfo.val.user.permissions.indexOf(route.permission) !==
            -1)
      ) {
        if (route.primary === 'app.sider.daily.sales.dashboard') {
          modulars.push('daily')
        }
        modulars.push(route.modular)
      }
    }
    return modulars.filter((item, index) => modulars.indexOf(item) === index)
  }, [])
  const isHidden = useCallback(
    (modular) => getPermissionsModular(routes).indexOf(modular) === -1,
    [getPermissionsModular]
  )
  const menu = (
    <Menu>
      <Menu.Item onClick={handleLogout} key="logout">
        {getStaticText('component.app.header.menu.logout')}
      </Menu.Item>
    </Menu>
  )

  const goToDataReportSystem = () => {
    window.location.href = '/datafocus'
  }

  return (
    <Header
      style={{
        width: '100%',
        backgroundColor: '#fff',
        alignItems: 'center',
        padding: '0px 25px',
        background: 'rgb(2, 21, 40)'
      }}
    >
      <Button
        className="app-check"
        onClick={() => checkLink('report')}
        hidden={isHidden('report')}
      >
        {getStaticText('app.sider.logo.report')}
      </Button>
      <Button
        className="app-check"
        onClick={() => checkLink('crm')}
        hidden={isHidden('crm')}
      >
        {getStaticText('app.sider.logo.crm')}
      </Button>
      <Button
        className="app-check"
        onClick={() => checkLink('market')}
        hidden={isHidden('market')}
      >
        {getStaticText('app.sider.logo.market')}
      </Button>
      <Button
        className="app-check"
        onClick={() => checkLink('merchant')}
        hidden={isHidden('merchant')}
      >
        {getStaticText('app.sider.logo.merchant')}
      </Button>
      <Button
        className="app-check"
        onClick={() => checkLink('config')}
        hidden={isHidden('config')}
      >
        {getStaticText('app.sider.logo.config')}
      </Button>
      <Button
        className="app-check"
        onClick={goToDataReportSystem}
        hidden={isHidden('config')}
      >
        {getStaticText('app.sider.logo.data.report')}
      </Button>
      <Button
        className="app-check"
        onClick={() => checkLink('product')}
        hidden={isHidden('product')}
      >
        {getStaticText('app.sider.logo.product')}
      </Button>
      <Button
        className="app-check"
        onClick={() => checkLink('order')}
        hidden={isHidden('order')}
      >
        {getStaticText('app.sider.logo.order')}
      </Button>
      <Space wrap style={{ float: 'right' }}>
        <ScopeSwitcher />
        <Dropdown overlay={menu}>
          <Avatar size={40}>{user.username}</Avatar>
        </Dropdown>
      </Space>
    </Header>
  )
}

AppHeader.propTypes = {
  resetUserInfo: PropTypes.func.isRequired,
  setCurrentScope: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired
}

const mapStateToProps = ({ users }) => ({
  user: users.userInfo.user
})
export default connect(mapStateToProps, { resetUserInfo, setCurrentScope })(
  AppHeader
)
