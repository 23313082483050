import React from 'react'
import { Popover, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import ReactECharts from 'echarts-for-react'
import { getStaticText } from '../../utils'

const TrendChart = (val, xAxis, series1, name1, series2, name2) => {
  const options = {
    grid: {
      bottom: 40,
      top: 40,
      left: 40,
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: xAxis
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        name: name1,
        data: series1,
        type: 'line',
        smooth: true,
        label: {
          show: true,
          position: 'top'
        }
      },
      {
        name: name2,
        data: series2,
        type: 'line',
        smooth: true,
        label: {
          show: true,
          position: 'top'
        }
      }
    ],
    legend: {
      data: [name1, name2]
    },
    tooltip: {
      trigger: 'axis'
    }
  }
  return (
    <span style={{ color: '#B30000FF' }}>
      <Popover
        style={{ width: '500px' }}
        content={() => (
          <ReactECharts style={{ width: '500px' }} option={options} />
        )}
        trigger="hover"
      >
        {val}
      </Popover>
    </span>
  )
}

const weeklyTableHomePageColumns = [
  {
    title: <div>{getStaticText('weekly.home.table.week_key')}</div>,
    dataIndex: 'week',
    key: 'week',
    fixed: 'left',
    width: 120,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.country')}</div>,
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.platform')}</div>,
    dataIndex: 'platform',
    key: 'platform',
    width: 110,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.store')}</div>,
    dataIndex: 'store',
    key: 'store',
    width: 110,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.type')}</div>,
    dataIndex: 'type',
    key: 'type',
    width: 110,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.position')}</div>,
    dataIndex: 'position',
    key: 'position',
    width: 110,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.title')}</div>,
    dataIndex: 'title',
    key: 'title',
    width: 110,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.gmv')}</div>,
    dataIndex: 'gmv',
    key: 'gmv',
    width: 110,
    align: 'center',
    render: (val, data) => {
      return TrendChart(
        val,
        data.week_series,
        data.gmv_series,
        data.week,
        data.other_gmv_series,
        data.other_week
      )
    }
  },
  {
    title: <div>{getStaticText('weekly.home.table.orders')}</div>,
    dataIndex: 'orders',
    key: 'orders',
    width: 110,
    align: 'center',
    render: (val, data) => {
      return TrendChart(
        val,
        data.week_series,
        data.orders_series,
        data.week,
        data.other_orders_series,
        data.other_week
      )
    }
  },
  {
    title: <div>{getStaticText('weekly.home.table.qty')}</div>,
    dataIndex: 'qty',
    key: 'qty',
    width: 110,
    align: 'center',
    render: (val, data) => {
      return TrendChart(
        val,
        data.week_series,
        data.qty_series,
        data.week,
        data.other_qty_series,
        data.other_week
      )
    }
  },
  {
    title: (
      <div>
        {getStaticText('weekly.home.table.uv_cr')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>订单量/点击人数</p>
            </div>
          )}
          key={getStaticText('weekly.home.table.store')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'uv_cr',
    key: 'uv_cr',
    width: 110,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('weekly.home.table.pv_cr')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>订单量/点击次数</p>
            </div>
          )}
          key={getStaticText('weekly.home.table.store')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'pv_cr',
    key: 'pv_cr',
    width: 110,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.impression')}</div>,
    dataIndex: 'impression',
    key: 'impression',
    width: 110,
    align: 'center',
    render: (val, data) => {
      return TrendChart(
        val,
        data.week_series,
        data.impression_series,
        data.week,
        data.other_impression_series,
        data.other_week
      )
    }
  },
  {
    title: <div>{getStaticText('weekly.home.table.impression_uv')}</div>,
    dataIndex: 'impression_uv',
    key: 'impression_uv',
    width: 110,
    align: 'center',
    render: (val, data) => {
      return TrendChart(
        val,
        data.week_series,
        data.impression_uv_series,
        data.week,
        data.other_impression_uv_series,
        data.other_week
      )
    }
  },
  {
    title: <div>{getStaticText('weekly.home.table.year_on_year')}</div>,
    dataIndex: 'impression_uv_year_on_year',
    key: 'impression_uv_year_on_year',
    width: 110,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.clicks')}</div>,
    dataIndex: 'clicks',
    key: 'clicks',
    width: 110,
    align: 'center',
    render: (val, data) => {
      return TrendChart(
        val,
        data.week_series,
        data.clicks_series,
        data.week,
        data.other_clicks_series,
        data.other_week
      )
    }
  },
  {
    title: <div>{getStaticText('weekly.home.table.uv')}</div>,
    dataIndex: 'uv',
    key: 'uv',
    width: 110,
    align: 'center',
    render: (val, data) => {
      return TrendChart(
        val,
        data.week_series,
        data.uv_series,
        data.week,
        data.other_uv_series,
        data.other_week
      )
    }
  },
  {
    title: <div>{getStaticText('weekly.home.table.year_on_year')}</div>,
    dataIndex: 'uv_year_on_year',
    key: 'uv_year_on_year',
    width: 110,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('weekly.home.table.clicks_rate')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>点击人数/曝光人数</p>
            </div>
          )}
          key={getStaticText('weekly.home.table.store')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'clicks_rate',
    key: 'clicks_rate',
    width: 110,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.add_to_cart')}</div>,
    dataIndex: 'add_to_cart',
    key: 'add_to_cart',
    width: 110,
    align: 'center',
    render: (val, data) => {
      return TrendChart(
        val,
        data.week_series,
        data.add_to_cart_series,
        data.week,
        data.other_add_to_cart_series,
        data.other_week
      )
    }
  },
  {
    title: <div>{getStaticText('weekly.home.table.add_to_cart_uv')}</div>,
    dataIndex: 'add_to_cart_uv',
    key: 'add_to_cart_uv',
    width: 110,
    align: 'center',
    render: (val, data) => {
      return TrendChart(
        val,
        data.week_series,
        data.add_to_cart_uv_series,
        data.week,
        data.other_add_to_cart_uv_series,
        data.other_week
      )
    }
  },
  {
    title: <div>{getStaticText('weekly.home.table.year_on_year')}</div>,
    dataIndex: 'add_to_cart_uv_year_on_year',
    key: 'add_to_cart_uv_year_on_year',
    width: 110,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('weekly.home.table.add_rate')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>加购人数/点击人数</p>
            </div>
          )}
          key={getStaticText('weekly.home.table.store')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'add_rate',
    key: 'add_rate',
    width: 110,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.orders_uv')}</div>,
    dataIndex: 'orders_uv',
    key: 'orders_uv',
    width: 110,
    align: 'center',
    render: (val, data) => {
      return TrendChart(
        val,
        data.week_series,
        data.orders_uv_series,
        data.week,
        data.other_orders_uv_series,
        data.other_week
      )
    }
  },
  {
    title: <div>{getStaticText('weekly.home.table.year_on_year')}</div>,
    dataIndex: 'orders_uv_year_on_year',
    key: 'orders_uv_year_on_year',
    width: 110,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('weekly.home.table.orders_rate')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>下单人数/加购人数</p>
            </div>
          )}
          key={getStaticText('weekly.home.table.store')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'orders_rate',
    key: 'orders_rate',
    width: 110,
    align: 'center'
  },
  {
    title: <div>{getStaticText('weekly.home.table.image')}</div>,
    render: (image) => {
      // eslint-disable-next-line jsx-a11y/alt-text
      return image ? <img height="50px" src={image} /> : '--'
    },
    dataIndex: 'image',
    key: 'image',
    width: 110,
    align: 'center'
  }
]

export default weeklyTableHomePageColumns
