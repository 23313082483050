import React, { ReactNode } from 'react'
import { Tabs, Form, Row, Col, Input } from 'antd'
import { connect } from 'react-redux'
import { updateField } from '../../../store/actions/i18n'

const { TabPane } = Tabs

interface Props {
  entity: String;
  schemas: Object;
  children: ReactNode;
  stores: Object;
}

const TranslatableForm = ({
  children,
  stores,
  schemas,
  entity,
  formData,
  updateField
}: Props) => {
  return (
    <div>
      <Tabs
        defaultActiveKey="en_us"
        tabPosition="left"
        style={{ height: '100%' }}
      >
        <TabPane tab="English" key="en_us">
          {children}
        </TabPane>
        {stores
          .filter((lo) => lo.locale !== 'en_US')
          .map((i) => (
            <TabPane tab={i.name} key={i.code}>
              <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                autoComplete="off"
              >
                {schemas[entity] &&
                  schemas[entity].map((col) => (
                    <Row gutter={16} key={i.code + col}>
                      <Col span={12}>
                        <Form.Item label={col}>
                          <Input.TextArea
                            value={
                              formData[i.locale] && formData[i.locale][col]
                            }
                            onChange={(e) => {
                              updateField(i.locale, col, e.target.value)
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
              </Form>
            </TabPane>
          ))}
      </Tabs>
    </div>
  )
}

const mapStateToProps = ({ global, i18n }) => {
  return {
    schemas: global.i18n_schemas,
    stores:
      (global.current_country &&
        global.config.filter((i) => global.current_country === i.countryCode)[0]
          .xpark_stores) ||
      [],
    formData: i18n.formData || {}
  }
}

export default connect(mapStateToProps, { updateField })(TranslatableForm)
