import React from 'react'
import Actions from './Actions'
import { OptionColumn } from '../../../../components'

export const columns = [
  {
    title: '标题',
    dataIndex: 'title',
    key: 'title',
    align: 'center'
  },
  {
    title: '国家',
    dataIndex: 'country',
    key: 'country',
    width: 100,
    align: 'center'
  },
  {
    title: '充值类型',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    render: (value) => <OptionColumn source="topup_types" value={value} />
  },
  {
    title: '运营商',
    dataIndex: 'operator_code',
    key: 'operator_code',
    align: 'center',
    render: (value) => <OptionColumn source="topup_operators" value={value} />
  },
  {
    title: '话费/流量',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center'
  },
  {
    title: '数量',
    dataIndex: 'total',
    key: 'total',
    align: 'center'
  },
  {
    title: '套餐ID',
    dataIndex: 'package_id',
    key: 'package_id',
    align: 'center'
  },
  {
    title: '活动开始时间',
    dataIndex: 'start_time',
    key: 'start_time',
    align: 'center'
  },
  {
    title: '活动结束时间',
    dataIndex: 'end_time',
    key: 'end_time',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
  {
    title: '修改时间',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: '',
    width: 140,
    key: 'x',
    fixed: 'right',
    align: 'center',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
