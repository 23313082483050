import request from './request'

export const getUserList = (params) =>
  request.get('/backend/rbac/user/get-list', { params })

export const getUserDetail = (params) =>
  request.get('/backend/rbac/user/detail', { params })

export const saveUser = (params) => {
  return request.post('/backend/rbac/user/save', params)
}

export const createUser = (params) =>
  request.post('/backend/rbac/user/create', params)

export const deleteUser = (params) =>
  request.post('/backend/rbac/user/delete', params)

export const getRoleList = (params) =>
  request.get('/backend/rbac/role/get-list', { params })

export const getPPBusinessUserList = (params) =>
  request.get('/backend/rbac/user/get-pplist', { params })

export const getRoleDetail = (params) =>
  request.get('/backend/rbac/role/detail', { params })

export const getPermissionNodes = () =>
  request.get('/backend/rbac/permission/nodes')

export const saveRole = (params) =>
  request.post('/backend/rbac/role/save', params)

export const createRole = (params) =>
  request.post('/backend/rbac/role/create', params)

export const deleteRole = (params) =>
  request.post('/backend/rbac/role/delete', params)

export const getPermissionList = (params) =>
  request.get('/backend/rbac/permission/get-list', { params })

export const getPermissionDetail = (params) =>
  request.get('/backend/rbac/permission/detail', { params })

export const savePermission = (params) =>
  request.post('/backend/rbac/permission/save', params)

export const createPermission = (params) =>
  request.post('/backend/rbac/permission/create', params)

export const deletePermission = (params) =>
  request.post('/backend/rbac/permission/delete', params)
