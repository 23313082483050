/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Divider,
  Image,
  message,
  Row,
  Select,
  Spin,
  Typography,
  Popover,
  Input, Modal
} from 'antd'
import { Link } from 'react-router-dom'
import FilterCollectingBox from '../../components/1688ProjectComponents/CollectingBox/FilterCollectingBox'
import TablePublicCollection from '../../components/1688ProjectComponents/TableComponents/TablePublicCollection'
import BlackButton from '../../components/1688ProjectComponents/ButtonComponents/BlackButton'
import WhiteButton from '../../components/1688ProjectComponents/ButtonComponents/WhiteButton'
import StickyFooter from '../../components/1688ProjectComponents/FooterComponents/StickyFooter'
import {
  getBoxProductList,
  sendPubBoxClaim,
  sendRelease,
  deleteProductColBox,
  editCategory
} from '../../services/1688Projects/collection'
import { getCategory } from '../../services/1688Projects/exclusive'
import '../../css/collection.css'
import imagePlaceholder from '../../assets/empty.webp'
import BlackInputSelect from '../../components/1688ProjectComponents/inputComponent/BlackInputSelect'
import ModalSetPricingTemplate from "../../components/1688ProjectComponents/PricingTemplate/ModalSetPricingTemplate";

const { Option } = Select
const { Title } = Typography

const sourcePlatformList = [
  { value: "XPARK-NG", label: "XPARK-NG" },
  { value: "XPARK-GH", label: "XPARK-GH" },
  { value: "XPARK-KE", label: "XPARK-KE" },
  { value: "XPARK-PK", label: "XPARK-PK" },
  { value: "XPARK-MA", label: "XPARK-MA" }
];

interface GetProduct {
  status: Number | null;
}
const CollectionBox = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingRegular, setIsLoadingRegular] = useState(false)
  const [productData, setProductData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNow, setPageNow] = useState(1)
  const [sizePage, setSizePage] = useState(10)
  const [totalItem, setTotalItem] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [valueSelect, setValueSelect] = useState(null)
  const [filterTab, setFilterTab] = useState(4)
  const [totalRegularly, setTotalRegularly] = useState(0)
  const [totalPublished, setTotalPublished] = useState(0)
  const [totalNotPublished, setTotalNotPublished] = useState(0)
  const [totalFailed, setTotalFailed] = useState(0)
  const [totalAll, setTotalAll] = useState(0)
  const [tmpStatus, setTmpStatus] = useState(null)
  const [deleteProductValue, setDeleteProductValue] = useState(null)
  const [open, setOpen] = useState(false)
  const [categoryOption, setCategoryOption] = useState([])
  const [categoryFiltered, setCategoryFiltered] = useState([])
  const [filterFormValue, setFilterFormValue] = useState({});
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [platFormOptions, setPlatformOptions] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);



  useEffect(() => {
    setCategoryFiltered(categoryOption)
  }, [categoryOption])

  useEffect(async () => {
    await getProductList()
  }, [filterFormValue]);


  useEffect(() => {
    setAllowedCountry()
  }, []);



  const content = (
    <div
      style={{
        maxWidth: '300px',
        maxHeight: '400px',
        overflowY: 'scroll',
        position: 'relative'
      }}
    >
      <Row>
        <Col span={24} style={{ position: 'sticky', top: 0, zIndex: 2 }}>
          <Input
            onChange={()=>{}}
            placeholder="input search text"
            style={{
              width: '100%',
              marginBottom: '20px'
            }}
          />
        </Col>
        {categoryFiltered?.map((item) => {
          return (
            <Col span={24}>
              <WhiteButton
                functionParent={() => handleSelectCategory(item)}
                title={item?.translatedName}
              />
            </Col>
          )
        })}
      </Row>
    </div>
  )

  const columns = [
    {
      title: 'Product Information',
      dataIndex: 'product',
      render: (source) => (
        <div style={{ display: 'flex' }}>
          <div>
            <Image
              style={{ width: '163px', height: 'unset' }}
              src={
                source?.url && source?.url?.length > 0
                  ? source?.url
                  : imagePlaceholder
              }
              preview={false}
            />
          </div>
          <div style={{ width: '200px', marginLeft: '10px' }}>
            <Col span={24}>
              <p style={{ color: 'black' }}>{source.desc}</p>
            </Col>
            <Col span={24}>
              <Col span={24}>
                <p style={{ color: '#606266', marginBottom: 'unset' }}>
                  {`采集箱产品ID： ${source.collectionId}`}
                </p>
              </Col>
              <Col span={24}>
                <p style={{ color: '#606266', marginBottom: 'unset' }}>
                  {`货源ID： ${source.supplyId}`}
                </p>
              </Col>
            </Col>
          </div>
        </div>
      )
    },
    {
      title: 'Pre-Release Categories',
      dataIndex: 'category'
    },
    {
      title: 'Untaxed Price',
      dataIndex: 'price',
      render: (price) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <div>{price.cny}</div>
          <div>{price.usd}</div>
        </div>
      )
    },
    {
      title: 'Source Price',
      dataIndex: 'localPrice',
      render: (localPrice) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <div>{localPrice.cny}</div>
          <div>{localPrice.usd}</div>
        </div>
      )
    },
    {
      title: 'Selling Price',
      dataIndex: 'selling_price',
      render: () => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          -
        </div>
      )
    },
    {
      title: 'Stock',
      dataIndex: 'stock'
    },
    {
      title: 'Pre-Release Shop',
      dataIndex: 'shop',
      render: (shop) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div>{shop.one}</div>
        </div>
      )
    },
    {
      title: 'Product Price',
      dataIndex: 'oriPrice',
      render: (oriPrice) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}
        >
          <div>{oriPrice.cny}</div>
          <div>{oriPrice.usd}</div>
        </div>
      )
    },
    {
      title: 'PIC / \n Claimed Date',
      dataIndex: 'more',
      render: (more) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div>{more.account}</div>
          <div
            style={{
              textAlign: 'center'
            }}
          >
            {more.date}
          </div>
        </div>
      )
    },
    {
      title: 'status',
      dataIndex: 'Status',
      render: (_, record) => {
        let text = '-'
        if (record?.status === 1) {
          text = 'Published'
        } else if (record?.status === 2) {
          text = 'Publish Failed'
        } else if (record?.status === 3) {
          text = 'Publishing'
        } else if (record?.status === 0) {
          text = 'Not Published'
        }
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {text}
          </div>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <Link
            to={`/fast-delivery/collection-box/edit-selected-item?id=${record.key}`}
            style={{ paddingInline: '16px', color: 'black' }}
          >
            Edit
          </Link>
          <Button type="text" onClick={() => handleDeleteProduct(record.key)}>
            Delete
          </Button>
          <Button
            disabled={record.status !== 0 && record.status !== 2}
            loading={isLoadingRegular}
            onClick={() => handleRelease(record)}
            type="text"
          >
            Release
          </Button>
          <Select
            loading={isLoadingRegular}
            value={valueSelect}
            onChange={(e) => {
              handleClaim(e)
              setValueSelect(null)
            }}
            bordered={false}
            placeholder="Claim to"
          >
            {setAllowedClaim(record.key)}
          </Select>
        </div>
      )
    }
  ]

  function setAllowedClaim(key) {
    let platForm =  [
      {
        label: 'Nigeria',
        value: `${key},XPARK-NG`,
        disabled: true
      },
      {
        label: 'Pakistan',
        value: `${key},XPARK-PK`,
        disabled: true
      },
      {
        label: 'Kenya',
        value: `${key},XPARK-KE`,
        disabled: true
      },
      {
        label: 'Ghana',
        value: `${key},XPARK-GH`,
        disabled: true
      },
      {
        label: 'Morocco',
        value: `${key},XPARK-MA`,
        disabled: true
      }
    ]
    let getFromLocal = JSON.parse(localStorage.getItem('allowedCountries'))
    if (getFromLocal && getFromLocal.length > 0) {
      let newPlatform = []
      for (let platFormKey in platForm) {
        let platFormNow = platForm[platFormKey].value.split("-")[1]
        for (let getFromLocalKey in getFromLocal) {
          let localFormNow = getFromLocal[getFromLocalKey].value
          if (platFormNow === localFormNow) {
            newPlatform.push({
              label: platForm[platFormKey].label,
              value: platForm[platFormKey].value,
              disable: getFromLocal[getFromLocalKey].disabled
            })
          }
        }
      }

      let contents = []

      for (let key in newPlatform) {
        contents.push(
          <Option value={newPlatform[key].value}> {newPlatform[key].label} </Option>
        )
      }

      return contents
    }
  }

  function setAllowedCountry() {

    let getFromLocal = JSON.parse(localStorage.getItem('allowedCountries'))
    if (getFromLocal && getFromLocal.length > 0) {
      let newPlatform = []
      for (let platFormKey in sourcePlatformList) {
        let platFormNow = sourcePlatformList[platFormKey].value.split("-")[1]
        for (let getFromLocalKey in getFromLocal) {
          let localFormNow = getFromLocal[getFromLocalKey].value
          if (platFormNow === localFormNow) {
            newPlatform.push({
              label: sourcePlatformList[platFormKey].label,
              value: sourcePlatformList[platFormKey].value,
              disable: getFromLocal[getFromLocalKey].disabled
            })
          }
        }
      }
      console.log({ newPlatform })
      setPlatformOptions(newPlatform)
    }
  }

  async function getProductList({
    status = null,
    goPage = 1,
  }: GetProduct = {}) {
    setIsLoading(true)
    const categoryData = await getCategory();
    setTmpStatus(status)
    const data = {}
    try {
      if (sizePage) {
        data.pageSize = sizePage
      }
      if (status) {
        if (status === 4) {
          setFilterTab(status)
        } else {
          data['filter[action]'] = status
          setFilterTab(status)
        }
        data.beginPage = goPage
        setPageNow(goPage)
      } else if (status === 0) {
        data['filter[action]'] = status
        data.beginPage = 1
        setPageNow(1)
        setFilterTab(0)
      } else if (Number(tmpStatus) === 0 && tmpStatus < 4) {
        data['filter[action]'] = tmpStatus
        if (goPage) {
          data.beginPage = goPage
        }
      } else {
        data.beginPage = goPage
      }
      if (filterFormValue){
        let filters = ["filter[author]", "filter[start_time]", "filter[end_time]", "filter[max_price]", "filter[min_price]", "filter[product_title]", "filter[platform]", "filter[supply_id]"]
        for (let key in filters){
          let filter = filters[key];
          if (filterFormValue.hasOwnProperty(filter) && filterFormValue[filter] != null){
            data[filter] = filterFormValue[filter];
          }
        }
      }
      const productRes = (await getBoxProductList(data)) || []
      if (
        categoryData &&
        categoryData?.categoryList?.children?.length > 0
      ) {
        setCategoryOption(categoryData.categoryList.children)
      }
      if (productRes?.totalRecords) {
        setTotalCount(productRes.totalRecords)
      } else {
        setTotalCount(0)
      }

      if (!status && productRes?.list?.length > 0) {
        setTotalAll(productRes?.list?.length)
      } else {
        setTotalCount(0)
      }

      const notPublishData = productRes.totalCountAction0
      if (notPublishData) {
        setTotalNotPublished(notPublishData)
      }
      const publishedData = productRes.totalCountAction1
      if (publishedData) {
        setTotalPublished(publishedData)
      }
      const failedData = productRes.totalCountAction2
      if (failedData) {
        setTotalFailed(failedData)
      }
      const regularlyData = productRes.totalCountAction3
      if (regularlyData) {
        setTotalRegularly(regularlyData)
      }
      const totalData = productRes.totalCountAction4
      if (totalData) {
        setTotalAll(totalData)
      }

      if (productRes?.list?.length > 0) {
        let newArr = sanitizeDataResult(productRes, categoryData);
        setTotalItem(newArr?.length || 0)
        setProductData(newArr)
      } else {
        setTotalItem(0)
        setProductData([])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function sanitizeDataResult(productRes, categoryData){
    const newArr = []
    let category = '-'
    let data = productRes.list;

    for (let key in data){
      let item = data[key];

      if (item?.category_id && item?.category_id?.length > 0) {
        const filterCategory =
          categoryData.categoryList?.children?.filter(
            (_category) =>
              Number(_category?.categoryId) === Number(item?.category_id)
          )
        if (filterCategory?.length > 0) {
          const [categoryGet] = filterCategory
          category = categoryGet?.translatedName || '-'
        } else {
          category = item?.category_id
        }
      }
      let priceRange = '-'
      if (item?.sale_info) {
        const { priceRangeList } = item.sale_info
        if (priceRangeList.length < 2) {
          priceRange = priceRangeList[0].price
        } else if (
          priceRangeList[priceRangeList.length - 1].price ===
          priceRangeList[0].price
        ) {
          priceRange = priceRangeList[0].price
        } else {
          priceRange = `
              ${priceRangeList[priceRangeList.length - 1].price} ~ 
              ${priceRangeList[0].price}`
        }
      }
      const getCategory = categoryOption.filter(
        (item) => item.categoryId === Number(item?.category_id)
      )
      let firstProductPrice = '-'
      let lastProductPrice = '-'
      let samePrice = null
      const dataSkuInfo = item.sku_info || []
      if (dataSkuInfo.length > 0) {
        const firstSkuData = dataSkuInfo[0]
        const lastSkuData = dataSkuInfo[dataSkuInfo.length - 1]
        if (firstSkuData) {
          firstProductPrice = firstSkuData.sellingPrice
            ? firstSkuData.sellingPrice
            : firstSkuData.consignPrice
        }
        if (firstSkuData) {
          lastProductPrice = lastSkuData.sellingPrice
            ? lastSkuData.sellingPrice
            : lastSkuData.consignPrice
        }
      }
      if (firstProductPrice === lastProductPrice)
        samePrice = firstProductPrice

      const newObj = {
        key: item?.id,
        product: {
          url: item?.images?.length ? item?.images[0] : null,
          desc: item?.subject_trans || '',
          collectionId: item?.id || '-',
          supplyId:  item?.offer_id || '-'
        },
        category,
        price: {
          cny: `CNY ${priceRange}`,
          usd: '-'
        },
        localPrice: {
          cny: `CNY ${priceRange}`,
          usd: '-'
        },
        status: Number(item?.action),
        shop: {
          one: item.platform || '-',
          two: '-',
          three: '-'
        },
        stock: item?.sale_info?.amountOnSale || '-',
        oriPrice: {
          cny: samePrice
            ? `CNY ${samePrice}` : `CNY ${firstProductPrice} ~ ${lastProductPrice}`
        },
        more: {
          account: item.author || '-',
          date: item.created_at || '-'
        }
      }
      newArr.push(newObj)
    }
    return newArr;
  }
  const onShowSizeChange = (current, pageSize) => {
    setSizePage(pageSize)
  }

  const handleClaim = async (value) => {
    setIsLoadingRegular(true)
    const [id, platform] = value.split(',')
    const claim = []
    if (id && platform) {
      claim.push({
        id: `${id}`,
        platform: [platform]
      })
    }
    try {
      await sendPubBoxClaim({ claim })

      await getProductList({ goPage: 1, status: tmpStatus })
      setPageNow(1)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingRegular(false)
    }
  }


  const titlePage = () => {
    return (
      <Title level={2} style={{ marginBottom: 0, padding: '24px' }}>
        Collection Box
      </Title>
    )
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  }
  const handleSelectAll = () => {
    const allRowKeys = productData.map((item) => item.key)
    setSelectedRowKeys(allRowKeys)
  }
  const handleDeselectAll = () => {
    setSelectedRowKeys([])
  }
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a className="button-pagination previous">Previous</a>
    }
    if (type === 'next') {
      return <a className="button-pagination next">Next</a>
    }
    return originalElement
  }
  const onChangePage = (value) => {
    if (value) {
      setPageNow(Number(value))
      getProductList({ goPage: Number(value) })
    }
  }
  const handleRelease = async (value) => {
    setIsLoadingRegular(true)
    try {
      if (value?.key && value?.key?.length > 0) {
        let getValuePublish = []
        if (value && value?.shop) {
          getValuePublish = Object.values(value?.shop).filter(
            (item) => item !== '-' && item
          )
        }
        if (getValuePublish.length > 0) {
          const data = {
            publish_sites: getValuePublish,
            publish_ids: [Number(value.key)]
          }
          await sendRelease(data)
          message.success('Success released!')
          await getProductList({ goPage: 1, status: tmpStatus })
          setPageNow(1)
        } else {
          message.error('Missing side!')
        }
      } else {
        message.error('Missing id!')
      }
    } catch (e) {
      message.error('Error send to server!')
      console.log(e)
    } finally {
      setIsLoadingRegular(false)
    }
  }

  const handleReLeaseBatch = async () => {
    setIsLoadingRegular(true)
    try {
      const dataFromSelectedKey = selectedRowKeys.map((key) => {
        const getData = productData.filter((item) => key === item.key)
        return getData[0]
      })
      const uniqueShopValues = dataFromSelectedKey.reduce((acc, curr) => {
        if (curr?.shop) {
          const shopValue =
            Object.values(curr.shop).filter((value) => value !== '-') || []
          shopValue.forEach((groupShop) => {
            if (!acc.includes(groupShop)) {
              acc.push(groupShop)
            }
          })
        }
        return acc
      }, [])
      const prepareForPayload = uniqueShopValues
        .map((item) => {
          const getData = dataFromSelectedKey
            .map((_data) => {
              const dataShop = Object.values(_data?.shop)
              if (dataShop.includes(item)) {
                return Number(_data.key)
              }
              return null
            })
            .filter((value) => value !== null)
          if (item && item !== '-') {
            return {
              publish_ids: getData,
              publish_sites: [item]
            }
          }
          return null
        })
        .filter((value) => value !== null)

      if (prepareForPayload.length > 0) {
        await Promise.all(
          prepareForPayload.map(async (file) => {
            await sendRelease(file)
          })
        )
      }
      // await getProductList({ goPage: 1, status: tmpStatus })
      setPageNow(1)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingRegular(false)
    }
  }

  const handleFilterPublished = () => {
    getProductList({ status: 1 })
  }
  const handleFilterAll = () => {
    getProductList({ status: 4 })
  }
  const handleFilterNotPublished = () => {
    getProductList({ status: 0 })
  }
  const handleFilterRegularly = () => {
    getProductList({ status: 3 })
  }
  const handleFilterFailed = () => {
    getProductList({ status: 2 })
  }

  const handleDeleteProduct = async (e) => {
    setIsLoading(true)
    try {
      if (e) {
        const response = await deleteProductColBox({ id: [e] })
        await getProductList()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteBulkProduct = async (e) => {
    setIsLoading(true)
    try {
      if (e) {
        setDeleteProductValue(null)
        if (productData?.length > 0) {
          const data = productData
            .map((item) => {
              const isInclude = selectedRowKeys.includes(item.key)
              if (isInclude && item.status !== 1) {
                return Number(item.key)
              }
              return null
            })
            .filter((item) => item !== null)
          const response = await deleteProductColBox({ id: data })
          await getProductList()
        }
      } else {
        handleDeselectAll()
        setDeleteProductValue(null)
        message.info('Abort the process')
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }
  const onSearch = (e) => {
    if (e.target.value?.length > 0 && e.target.value) {
      const filteredItems = categoryFiltered.filter((item) =>
        item.translatedName
          .toLowerCase()
          .startsWith(e.target.value.toLowerCase())
      )
      setCategoryFiltered(filteredItems)
    } else {
      setCategoryFiltered(categoryOption)
    }
  }

  const handleSelectCategory = async (e) => {
    try {
      setIsLoading(true)
      const dataPayload = {
        id: selectedRowKeys,
        category_id: e.categoryId
      }
      const response = await editCategory(dataPayload)
      handleDeselectAll()
      await getProductList()
      setPageNow(1)
    } catch (e) {
      console.log(e)
    } finally {
      setOpen(false)
      setIsLoading(false)
    }
  }

  function handleOnChangeFilterForm(filterData){
    setPageNow(1)
    setFilterFormValue(filterData)
  }

  return (
    <Spin spinning={isLoadingRegular}>
      <div
        style={{ width: '100%', background: '#F8F8F8', position: 'relative' }}
        className="collection-box"
      >
        <div style={{ width: '100%' }}>{titlePage()}</div>
        <Divider style={{ color: 'black', margin: 'unset' }} />
        <div
          style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
        >
          <FilterCollectingBox platFormOptions={platFormOptions} isLoading={isLoading} handleOnChange={(filterData)=> {handleOnChangeFilterForm(filterData)}} />
          <Row gutter={[0, 10]} style={{ marginTop: '100px' }}>
            <Col span={4} className="select-large-black">
              <BlackInputSelect
                funcParent={handleDeleteBulkProduct}
                value={deleteProductValue}
                placeHolder="Delete Product"
                option={[
                  {
                    label: 'Confirm Delete',
                    value: 1
                  },
                  {
                    label: 'Abort Delete',
                    value: 0
                  }
                ]}
              />
            </Col>
            <Col span={4}>
              <BlackButton
                functionParent={() => handleReLeaseBatch()}
                title="Batch Release"
              />
            </Col>
            <Col span={4}>
              <Popover
                placement="bottom"
                open={open}
                content={content}
                trigger="click"
              >
                <BlackButton
                  functionParent={() => setOpen((prev) => !prev)}
                  title="Select Category"
                />
              </Popover>
            </Col>
            <Col span={4}>
              <BlackButton functionParent={showModal} title="Set Selling Price" />
            </Col>
            {/* <Col span={4}> */}
            {/*  <BlackButton title="Create Product" /> */}
            {/* </Col> */}
            <Col span={4} />
            <Col span={4} />
            <Col span={24} />
            <Col span={24} />
            <Col span={24} />
            <Col span={3}>
              {filterTab === 4 ? (
                <BlackButton
                  functionParent={handleFilterAll}
                  title={`All (${totalAll})`}
                />
              ) : (
                <WhiteButton
                  functionParent={handleFilterAll}
                  title={`All (${totalAll})`}
                />
              )}
            </Col>
            <Col span={3}>
              {filterTab === 0 ? (
                <BlackButton
                  functionParent={handleFilterNotPublished}
                  title={`Not published (${totalNotPublished})`}
                />
              ) : (
                <WhiteButton
                  functionParent={handleFilterNotPublished}
                  title={`Not published (${totalNotPublished})`}
                />
              )}
            </Col>
            <Col span={3}>
              {filterTab === 3 ? (
                <BlackButton
                  functionParent={handleFilterRegularly}
                  title={`Publishing (${totalRegularly})`}
                />
              ) : (
                <WhiteButton
                  functionParent={handleFilterRegularly}
                  title={`Publishing (${totalRegularly})`}
                />
              )}
            </Col>
            <Col span={3}>
              {filterTab === 1 ? (
                <BlackButton
                  functionParent={handleFilterPublished}
                  title={`Published (${totalPublished})`}
                />
              ) : (
                <WhiteButton
                  functionParent={handleFilterPublished}
                  title={`Published (${totalPublished})`}
                />
              )}
            </Col>
            <Col span={3}>
              {filterTab === 2 ? (
                <BlackButton
                  functionParent={handleFilterFailed}
                  title={`Published Failed (${totalFailed})`}
                />
              ) : (
                <WhiteButton
                  functionParent={handleFilterFailed}
                  title={`Published Failed (${totalFailed})`}
                />
              )}
            </Col>
          </Row>
          <TablePublicCollection
            dataTable={productData}
            columns={columns}
            rowSelection={rowSelection}
            loading={isLoading}
          />
          <StickyFooter
            sizePage={sizePage}
            totalItem={totalItem}
            handleCheckAll={handleSelectAll}
            handleUncheckAll={handleDeselectAll}
            itemRender={itemRender}
            totalPage={totalCount}
            onChangePage={onChangePage}
            page={pageNow}
            onShowSizeChange={onShowSizeChange}
            itemSelected={selectedRowKeys?.length || 0}
          />
          <ModalSetPricingTemplate
            isModalOpen={isModalOpen}
            handleOk={handleOk}
            handleCancel={handleCancel}
            selectedRowKeys={selectedRowKeys}
            handleDeselectAll={handleDeselectAll}
          />

        </div>
      </div>
    </Spin>
  )
}

export default CollectionBox
