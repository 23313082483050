import React, { useContext, useState } from 'react'
import { Card, Row, Col, Typography, Space, Input } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { getStaticText } from '../../../../../utils'
import {
  PermissionButton,
  PagingTable,
  GridContext,
  Uploader
} from '../../../../../components'
import { toggleProduct } from '../../../services'

const { Text } = Typography

const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}
interface ActionsProp {
  reload: () => {};
  row: Object;
}
const Actions = ({ reload, row }: ActionsProp) => {
  return (
    <PermissionButton
      onClick={() => {
        toggleProduct(() => reload())({
          activity_id: row.activity_id,
          product: row.summary || {
            id: row.product_id
          }
        })
      }}
      style={{ marginLeft: '5px' }}
      icon={<DeleteOutlined />}
      size="small"
      permission="cut/activity/toggle-product"
      type="primary"
    />
  )
}

interface AttributeProps {
  attr: string;
  row: Object;
  reload: () => {};
}

const AttributeInput = ({ attr, row, setProductAttribute }: AttributeProps) => {
  const [value, setValue] = useState(row[attr] ?? '')
  return (
    <>
      <Space style={{ width: '100px' }}>
        <Input
          value={value}
          onChange={(e) => {
            const attrValue = e.target.value
            setValue(attrValue || '')
            setProductAttribute(row.id, attr, attrValue)
          }}
        />
      </Space>
    </>
  )
}

const CutProductImage = ({
  attr,
  row,
  setProductAttribute
}: AttributeProps) => {
  const [value, setValue] = useState(row[attr] ?? null)
  return (
    <Uploader
      value={value}
      onChange={(e) => {
        setProductAttribute(row.id, attr, e)
        setValue(e)
      }}
    />
  )
}

const columns = ({ reload, setProductAttribute }) => [
  {
    title: 'Image(800x800)',
    key: 'image',
    align: 'center',
    render: (data) => (
      <CutProductImage
        attr="product_pic"
        setProductAttribute={setProductAttribute}
        row={data}
      />
    )
  },
  {
    title: 'Product Image',
    key: 'imgurl',
    align: 'center',
    render: (data) => (
      <img src={data.summary.imgurl} alt={data.summary.name} width="100" />
    )
  },
  {
    title: 'Product Id',
    width: '100px',
    align: 'center',
    dataIndex: 'product_id',
    key: 'product_id'
  },
  {
    title: 'Name',
    key: 'name',
    align: 'center',
    render: (data) => data.summary && data.summary.name
  },
  {
    title: 'Display Name',
    key: 'extra_text',
    align: 'center',
    render: (data) => (
      <AttributeInput
        attr="extra_text"
        setProductAttribute={setProductAttribute}
        row={data}
      />
    )
  },
  {
    title: 'Price',
    key: 'final_price',
    align: 'center',
    render: (data) => data.summary && data.summary.final_price_symbol
  },
  {
    title: 'Stock',
    key: 'stock',
    align: 'center',
    render: (data) => (
      <AttributeInput
        attr="stock"
        setProductAttribute={setProductAttribute}
        row={data}
      />
    )
  },
  {
    title: 'Used',
    align: 'center',
    width: '60px',
    dataIndex: 'cut_stocked',
    key: 'cut_stocked'
  },
  {
    title: 'Actions',
    align: 'center',
    key: 'actions',
    render: (data) => (
      <Actions
        reload={reload}
        setProductAttribute={setProductAttribute}
        row={data}
      />
    )
  }
]

interface Props {
  gridData: Object;
}

export default ({
  gridData,
  country,
  paginationHandler,
  reload,
  setProductAttribute
}: Props) => {
  const { toggle, record } = useContext(GridContext)

  return (
    <>
      <Card>
        <Row>
          <Col span={24}>
            <Text>{getStaticText('component.table.header.title')}</Text>
            <Space style={{ cssFloat: 'right' }}>
              <PermissionButton
                onClick={() => toggle({ ...record, country }, 'product_picker')}
                permission="membership/product/toggle-product"
                icon={<PlusOutlined />}
                type="primary"
              >
                Edit Products
              </PermissionButton>
            </Space>
          </Col>
        </Row>
      </Card>
      <PagingTable
        columns={columns({ reload, setProductAttribute })}
        dataSource={gridData.data}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.current || gridData.page}
        pageSize={gridData.page_size}
        handler={paginationHandler}
      />
    </>
  )
}
