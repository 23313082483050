import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function NewMedia(props) {
  const { tableData } = props
  return (
    <>
      {/* 新社媒 */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="3" width="100" className="sticky-left sticky-second">
          新社媒 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>手填</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">累计KOL合作数量</th>
        <th colSpan="3">本期KOL新增人数</th>
        <th colSpan="3">累计KOL完成订单数</th>
        <th colSpan="3">本期KOL完成订单数</th>
        <th colSpan="3">本期新社媒合作ROI</th>
        <th colSpan="3">本期新社媒引流UV</th>
      </tr>

      {/* row2 */}
      <tr>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.cumulative_of_kol_cooperation_actual}</td>
        <td>{tableData.cumulative_of_kol_cooperation_budget}</td>
        <td>{tableData.cumulative_of_kol_cooperation_rate_year_on_year}</td>
        <td>{tableData.new_of_kol_users_actual}</td>
        <td>{tableData.new_of_kol_users_budget}</td>
        <td>{tableData.new_of_kol_users_rate_year_on_year}</td>
        <td>{tableData.cumulative_of_kol_orders_actual}</td>
        <td>{tableData.cumulative_of_kol_orders_budget}</td>
        <td>{tableData.cumulative_of_kol_orders_rate_year_on_year}</td>
        <td>{tableData.kol_orders_actual}</td>
        <td>{tableData.kol_orders_budget}</td>
        <td>{tableData.kol_orders_rate_year_on_year}</td>
        <td>{tableData.roi_new_social_media_actual}</td>
        <td>{tableData.roi_new_social_media_budget}</td>
        <td>{tableData.roi_new_social_media_rate_year_on_year}</td>
        <td>{tableData.uv_new_social_media_actual}</td>
        <td>{tableData.uv_new_social_media_budget}</td>
        <td>{tableData.uv_new_social_media_rate_year_on_year}</td>
      </tr>
    </>
  )
}

NewMedia.propTypes = {
  tableData: shape({}).isRequired
}

export default NewMedia
