import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Select, Space, Card, Typography, DatePicker, Button } from 'antd'
import { getStaticText, storage } from '../../../utils'
import services from '../../../services'
import dateFormat from '../../../config/dateFormat'

const dateFormatMonth = dateFormat.month
const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const requestParams = {
  country: ['All_Country'],
  start_date: moment().subtract('months', 1),
  end_date: moment().subtract('months')
}

const MapOption = {
  title: {
    text: '',
    x: 'right',
    y: 'bottom',
    textStyle: {
      fontSize: 12
    }
  },
  grid: {
    bottom: 40,
    top: 40,
    left: 40,
    containLabel: true
  },
  toolbox: {
    show: true,
    feature: {
      dataView: {
        readOnly: false,
        title: '编辑'
      },
      restore: {
        show: true,
        title: '重置'
      },
      saveAsImage: {
        show: true,
        title: '下载'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    data: []
  },
  xAxis: [
    {
      data: []
    }
  ],
  yAxis: [
    {
      name: ''
    },
    {
      name: '',
      axisLabel: {
        formatter: '{value} '
      }
    }
  ],
  series: []
}

const getLineLabel = {
  show: true,
  position: 'top',
  formatter: (value) => {
    return `${value.data}`
  }
}

const getLineInLabel = {
  show: true,
  formatter: (value) => {
    return `${value.data}`
  }
}

class BrandGa extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatMonth)
      : requestParams.end_date
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatMonth)
      : requestParams.start_date
    const country = nextSearch.get('country')
      ? nextSearch.get('country').split(',')
      : requestParams.country
    this.state = {
      countries: this.checkCountries(country),
      country,
      start_date: startDate,
      start_format: startDate.format(dateFormatMonth),
      end_date: endDate,
      end_format: endDate.format(dateFormatMonth),
      map_list: [],
      catalogs: [],
      brand: '',
      serie: ''
    }
    this.getBrandReportsCatalog()
  }

  getBrandReportsCatalog = () => {
    services.brand.getBrandReportsCatalog().then((result) => {
      this.setState(
        {
          catalogs: result.catalog_list,
          brand: '0',
          serie: '0'
        },
        () => {
          this.useEffect()
        }
      )
    })
  }

  getrequest = () => ({
    country: this.state.country.toString(),
    start_date: this.state.start_format,
    end_date: this.state.end_format,
    brand: this.state.brand,
    serie: this.state.serie
  })

  handleReset = () => {
    this.setState({
      country: requestParams.country,
      start_date: requestParams.start_date,
      start_format: requestParams.start_date.format(dateFormatMonth),
      end_date: requestParams.end_date,
      end_format: requestParams.end_date.format(dateFormatMonth)
    })
  }

  handleCountryChange = (country) => {
    this.setState({
      country,
      countries: this.checkCountries(country)
    })
  }

  handleDateChange = (date, dateString) => {
    this.setState({
      start_date: moment(dateString[0], dateFormatMonth),
      start_format: dateString[0],
      end_date: moment(dateString[1], dateFormatMonth),
      end_format: dateString[1]
    })
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  checkCountries = (country) => {
    const countries = storage.allowedCountries.val
    if (country.indexOf('All_Country') >= 0) {
      countries.forEach((country) => {
        country.disabled = true
      })
      countries[0].disabled = false
    } else if (country.length === 0) {
      countries.forEach((country) => {
        country.disabled = false
      })
    } else {
      countries[0].disabled = true
    }
    return countries
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchData()
  }

  fetchData = () => {
    services.brand.getBrandGa(this.getrequest()).then((result) => {
      const mapList = []
      if (result.map_list instanceof Array) {
        Object.keys(result.map_list).forEach((item, index) => {
          if (result.map_list[item].series instanceof Array) {
            result.map_list[item].series = result.map_list[item].series.map(
              (value) => {
                if (result.map_list[item].xAxis.type === 'value') {
                  value.label = getLineInLabel
                } else if (value.type !== 'pie') {
                  value.label = getLineLabel
                }
                return value
              }
            )
          }
          const map = []
          map.index = index
          map.height =
            item > 1
              ? `${result.map_list[item].series[0].data.length * 50 + 100}px`
              : '300px'
          map.option = {
            ...MapOption,
            ...result.map_list[item]
          }
          mapList.push(map)
        })
        this.setState({
          map_list: mapList
        })
      }
    })
  }

  getMaps = () => {
    if (this.state.map_list) {
      return this.state.map_list.map((map) => {
        return (
          <ReactEcharts
            style={{ width: '100%', height: map.height }}
            option={map.option}
            key={map.index}
          />
        )
      })
    }
  }

  getBrands = () => {
    if (this.state.catalogs[0]) {
      return this.state.catalogs[0].map((item) => (
        <Option key={item.id}>{item.title}</Option>
      ))
    }
    return ''
  }

  handleBrandChange = (brand) => {
    this.setState({
      brand,
      serie: '0'
    })
  }

  getSeries = () => {
    if (this.state.brand && this.state.catalogs[this.state.brand]) {
      return this.state.catalogs[this.state.brand].map((item) => (
        <Option key={item.id}>{item.title}</Option>
      ))
    }
    return ''
  }

  handleSeriesChange = (serie) => {
    this.setState({
      serie
    })
  }

  render() {
    return (
      <>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 220 }}
              value={this.state.country}
              onChange={this.handleCountryChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Text>{getStaticText('component.search.brand.text')}</Text>
            <Select
              style={{ width: 150 }}
              value={this.state.brand}
              onChange={this.handleBrandChange}
            >
              <Option key="0">全部</Option>
              {this.getBrands()}
            </Select>
            <Text>{getStaticText('component.search.serie.text')}</Text>
            <Select
              style={{ width: 150 }}
              value={this.state.serie}
              onChange={this.handleSeriesChange}
            >
              <Option key="0">全部</Option>
              {this.getSeries()}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <RangePicker
              picker="month"
              value={[this.state.start_date, this.state.end_date]}
              format={dateFormatMonth}
              onChange={this.handleDateChange}
              allowClear={false}
            />
          </Space>
          <Space style={{ cssFloat: 'right' }}>
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        {this.getMaps()}
      </>
    )
  }
}

BrandGa.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default BrandGa
