import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getStaticText } from '../../utils'

const monthlyTableColumns = [
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.country')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.country')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => getStaticText(data.value)
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.year')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.processing_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.year')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'year',
    key: 'year',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.platform')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.platform')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'platform',
    key: 'platform',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.month')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.processing_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.month')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'month',
    key: 'month',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.sales_amount_wb')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.sales_amount_wb')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'sales_amount_wb',
    key: 'sales_amount_wb',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.sales_amount')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
              <p>
                {getStaticText('monthly.sales.table.sales_amount_explain_note')}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.sales_amount')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'sales_amount',
    key: 'sales_amount',
    width: 110,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.uv')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.uv')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'uv',
    key: 'uv',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.conversion_rate')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'monthly.sales.table.conversion_rate_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.conversion_rate')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'conversion_rate',
    key: 'conversion_rate',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.sales_average')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'monthly.sales.table.sales_average_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.sales_average')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'sales_average',
    key: 'sales_average',
    width: 130,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.uv_worth')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText('monthly.sales.table.uv_worth_calculation_note')}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.uv_worth')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'uv_worth',
    key: 'uv_worth',
    width: 130,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.sales_volume')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.sales_volume')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'sales_volume',
    key: 'sales_volume',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.total_online_paid')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'monthly.sales.table.total_online_paid_explain_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.total_online_paid')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'total_online_paid',
    key: 'total_online_paid',
    width: 140,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.total_online_paid_percent')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'monthly.sales.table.total_online_paid_percent_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.total_online_paid_percent')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'total_online_paid_percent',
    key: 'total_online_paid_percent',
    width: 140,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.total_online_product')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'monthly.sales.table.total_online_product_explain_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.total_online_product')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'total_online_product',
    key: 'total_online_product',
    width: 120,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.active_product')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'monthly.sales.table.active_product_explain_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.active_product')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'active_product',
    key: 'active_product',
    width: 110,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.active_product_percent')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'monthly.sales.table.active_product_percent_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.active_product_percent')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'active_product_percent',
    key: 'active_product_percent',
    width: 110,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.total_cross_border_product')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.total_cross_border_product')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'total_cross_border_product',
    key: 'total_cross_border_product',
    width: 110,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText(
          'monthly.sales.table.total_cross_border_product_percent'
        )}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'monthly.sales.table.total_cross_border_product_percent_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText(
            'monthly.sales.table.total_cross_border_product_percent'
          )}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'total_cross_border_product_percent',
    key: 'total_cross_border_product_percent',
    width: 110,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.promotion_consumption')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.fill.inmanually_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.promotion_consumption')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'promotion_consumption',
    key: 'promotion_consumption',
    width: 160,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.promotion_consumption_percent')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'monthly.sales.table.promotion_consumption_percent_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText(
            'monthly.sales.table.promotion_consumption_percent'
          )}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'promotion_consumption_percent',
    key: 'promotion_consumption_percent',
    width: 120,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.gmv_percent')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText('monthly.sales.table.gmv_percent_explain_note')}
              </p>
              <p>
                {getStaticText(
                  'monthly.sales.table.gmv_percent_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.gmv_percent')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'gmv_percent',
    key: 'gmv_percent',
    width: 130,
    align: 'center',
    render: (data) => data.value
  }
]

export default monthlyTableColumns
