import { SettingDemo, PromotionSetting } from '../pages'

const Settings = {
  path: '/settings',
  primary: 'app.sider.settings',
  hideGlobalTips: true,
  routes: [
    {
      path: '/settings/schema',
      modular: 'config',
      permission: 'sys-config/schema',
      primary: 'app.sider.schema.settings',
      component: SettingDemo,
      exact: true
    },
    {
      path: '/settings/promotion',
      modular: 'config',
      permission: 'promotion-config/get-list',
      primary: 'app.sider.promotion.settings',
      component: PromotionSetting,
      exact: true
    }
  ]
}

export default Settings
