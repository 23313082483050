import DashboardBusiness from '../pages/Dashboard/Business'
import DashboardGoods from '../pages/Dashboard/Goods'
import DashboardFlow from '../pages/Dashboard/Flow'
import DashboardIndex from '../pages/Dashboard/Index'

const report = {
  path: '/dashboard',
  primary: 'app.sider.dashboard',
  routes: [
    {
      path: '/dashboard/index',
      primary: 'app.sider.dashboard-index',
      modular: 'index',
      component: DashboardIndex,
      exact: true
    },
    {
      path: '/dashboard/flow',
      primary: 'app.sider.dashboard-flow',
      modular: 'index',
      component: DashboardFlow,
      exact: true
    },
    {
      path: '/dashboard/business',
      primary: 'app.sider.dashboard-business',
      modular: 'index',
      component: DashboardBusiness,
      exact: true
    },
    {
      path: '/dashboard/goods',
      primary: 'app.sider.dashboard-goods',
      modular: 'index',
      component: DashboardGoods,
      exact: true
    }
  ]
}
export default report
