/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { Row, Col, Switch } from 'antd'
import LabelInput from './LabelInput'

interface Props {
  title: String;
  callback: Function;
}
const RegularSwitch = ({ title, callback }: Props) => {
  const [setting, setSetting] = useState(true)
  useEffect(() => {
    if (callback) callback(setting)
  }, [setting]);
  return (
    <Row>
      <Col span={20}>
        <LabelInput title={title} />
      </Col>
      <Col span={4}>
        <Switch
          onChange={(e) => setSetting(e)}
          style={{
            background: 'black'
          }}
          checkedChildren="ON"
          defaultChecked
          unCheckedChildren="OFF"
        />
      </Col>
    </Row>
  )
}

export default RegularSwitch
