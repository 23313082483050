import React from 'react'

import { getStaticText } from '../../utils'

const goodsDashboardFlowColumns = [
  {
    title: <div>{getStaticText('dashboard.goods.product_name')}</div>,
    width: 300,
    dataIndex: 'product_name',
    key: 'product_name'
  },
  {
    title: <div>{getStaticText('dashboard.goods.flow_times')}</div>,
    dataIndex: 'pv',
    key: 'pv',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.flow_uv')}</div>,
    dataIndex: 'uv',
    key: 'uv',
    sorter: true
  }
]

export default goodsDashboardFlowColumns
