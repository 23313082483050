import React, { useContext } from 'react'
import PermissionButton from '../PermissionButton'
import { GridContext } from './GridContext'

interface Props {
  permission: String;
}
export default ({ permission, ...state }: Props) => {
  const { toggle } = useContext(GridContext)
  return (
    <PermissionButton
      onClick={() => toggle({}, 'create')}
      permission={permission}
      {...state}
    >
      Create
    </PermissionButton>
  )
}
