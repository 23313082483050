import request from './request'

export const getCutActivityList = (params) =>
  request.get('/backend/cut/activity/get-list', { params })

export const getCutActivityDetail = (params) =>
  request.get('/backend/cut/activity/detail', { params })

export const saveCutActivity = (params) => {
  return request.post('/backend/cut/activity/save', params)
}

export const createCutActivity = (params) =>
  request.post('/backend/cut/activity/create', params)

export const deleteCutActivity = (params) =>
  request.post('/backend/cut/activity/delete', params)

// order
export const getCutActivityShareList = (params) =>
  request.get('/backend/cut/share/get-list', { params })

export const getCutActivityShareDetail = (params) =>
  request.get('/backend/cut/share/detail', { params })

export const getCutShareRecordList = (params) =>
  request.get('/backend/cut/record/get-list', { params })

export const getCutActivityProducts = (params) =>
  request.get('/backend/cut/activity/get-products', { params })

export const toggleProduct = (params) =>
  request.post('/backend/cut/activity/toggle-product', params)

export const saveProductAttributes = (params) =>
  request.post('/backend/cut/activity/save-product-attributes', params)

export const getCutActivityStatistics = (params) =>
  request.get('/backend/cut/activity/get-statistics', { params })
