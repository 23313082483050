import React from 'react'

import './operationTable.css'

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    align: 'center',
    key: 'id'
  },
  {
    title: 'username',
    dataIndex: 'username',
    align: 'center',
    key: 'username'
  },
  {
    title: 'table',
    dataIndex: 'target_table',
    align: 'center',
    key: 'target_table'
  },
  {
    title: 'id',
    dataIndex: 'target_id',
    align: 'center',
    key: 'target_id'
  },
  {
    title: 'action',
    dataIndex: 'action',
    align: 'center',
    key: 'action'
  },
  {
    title: 'log',
    dataIndex: 'log',
    align: 'center',
    key: 'log'
  },
  {
    title: 'created_at',
    dataIndex: 'created_at',
    align: 'center',
    key: 'created_at'
  },
  {
    title: 'value_changed',
    dataIndex: 'value_changed',
    key: 'value_changed',
    align: 'center',
    width: '400px',
    render: (content) => {
      return <div dangerouslySetInnerHTML={{ __html: content }} />
    }
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
