// import React from 'react'
// import Actions from './Actions'

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    align: 'center'
  },
  {
    title: 'Customer Id',
    dataIndex: 'customer_id',
    key: 'customer_id',
    align: 'center',
    width: 100
  },
  {
    title: 'Customer Email',
    dataIndex: 'customer_email',
    key: 'customer_email',
    align: 'center'
  },
  {
    title: 'Activity Id',
    dataIndex: 'activity_id',
    key: 'activity_id',
    align: 'center',
    width: 100
  },
  {
    title: 'Increment Id',
    dataIndex: 'increment_id',
    key: 'increment_id',
    align: 'center'
  },
  {
    title: 'Qty Ordered',
    dataIndex: 'qty_ordered',
    key: 'qty_ordered',
    align: 'center',
    width: 100
  },
  {
    title: 'Row Total',
    dataIndex: 'row_total',
    key: 'row_total',
    align: 'center'
  }
  // {
  //   title: 'Action',
  //   dataIndex: '',
  //   width: 200,
  //   key: 'x',
  //   align: 'center',
  //   render: (record) => <Actions record={record} />
  // }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
