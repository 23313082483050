import React, { useEffect } from 'react'
import moment from 'moment'
import { Modal, Form, Input, Select, DatePicker, Row, Col } from 'antd'
import PropTypes from 'prop-types'

import { getStaticText, storage, transFormData } from '../../utils'

const notAllowedEdit = [
  'country',
  'report_date',
  'start_date',
  'end_date',
  'week_key',
  'month_key',
  'platform'
]

const EditModal = (props) => {
  const [form] = Form.useForm()
  const countries = storage.allowedCountries.val

  const {
    picker,
    isOpen,
    title,
    salesType: { dateName, type, formatDate },
    onSubmit,
    onCancel,
    list
  } = props

  useEffect(() => {
    const copyData = { ...list }
    Object.keys(copyData).forEach((filedKey) => {
      copyData[filedKey] = transFormData(copyData[filedKey])
    })
    form.setFieldsValue(copyData)
  }, [list, form, picker])

  return (
    <Modal
      forceRender
      width={1200}
      centered
      visible={isOpen}
      title={title}
      onOk={() => {
        form.validateFields().then((values) => {
          values[dateName] = moment(values[dateName]).format(formatDate)
          form.resetFields()
          onSubmit(values)
        })
      }}
      onCancel={onCancel}
      okText={getStaticText('component.edit.modal.ok.text')}
    >
      <Form form={form}>
        <Row gutter={[24, 16]}>
          <Col span={6}>
            <Form.Item
              style={{ display: 'inline-block' }}
              name="country"
              label={getStaticText('daily.sales.table.country')}
            >
              <Select style={{ width: 170 }} options={countries} disabled />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              style={{ display: 'inline-block' }}
              name={dateName}
              label={getStaticText('daily.sales.table.report_date')}
            >
              <DatePicker
                style={{ width: 170 }}
                allowClear={false}
                format={formatDate}
                picker={picker}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              style={{ display: 'inline-block' }}
              name="platform"
              label={getStaticText('daily.sales.table.platform')}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          {Object.keys(list)
            .filter((item) => !notAllowedEdit.includes(item))
            .filter((item) => getStaticText(`${type}${item}`))
            .map((item) => (
              <Col span={6} key={item}>
                <Form.Item
                  style={{ display: 'inline-block' }}
                  name={item}
                  label={getStaticText(`${type}${item}`)}
                >
                  <Input />
                </Form.Item>
              </Col>
            ))}
        </Row>
      </Form>
    </Modal>
  )
}

EditModal.defaultProps = {
  picker: ''
}

EditModal.propTypes = {
  picker: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  salesType: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  list: PropTypes.shape().isRequired
}

export default EditModal
