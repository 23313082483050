import services, {
  withTableLoading,
  withGlobalLoading,
  withTranslation
} from '../../../../services'

export const getCategoryList = (handler) =>
  withTableLoading((params) =>
    services.system.getCategoryList(params).then(handler)
  )

export const getCategoryDetail = (handler) =>
  withGlobalLoading((id) =>
    services.system.getCategoryDetail({ id }).then(handler)
  )

export const deleteCategory = (handler) =>
  withGlobalLoading((id) =>
    services.system.deleteCategory({ id }).then(handler)
  )

export const createCategory = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.system.saveCategory(params).then(handler)
    )
  )

export const updateCategory = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.system.saveCategory(params).then(handler)
    )
  )

export const getBannerList = (handler) =>
  withTableLoading((params) =>
    services.system.getBannerList(params).then(handler)
  )

export const getBannerDetail = (handler) =>
  withGlobalLoading((id) =>
    services.system.getBannerDetail({ id }).then(handler)
  )

export const deleteBanner = (handler) =>
  withGlobalLoading((id) => services.system.deleteBanner({ id }).then(handler))

export const createBanner = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.system.saveBanner(params).then(handler)
    )
  )

export const updateBanner = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.system.saveBanner(params).then(handler)
    )
  )

export const getCategoryActivities = (handler) =>
  withGlobalLoading((params) =>
    services.system.getCategoryActivities(params).then(handler)
  )

export const topActivity = (handler) =>
  withGlobalLoading((params) =>
    services.system.topActivity(params).then(handler)
  )

export const saveActivityRelation = (handler) =>
  withGlobalLoading((params) =>
    services.system.saveActivityRelation(params).then(handler)
  )
