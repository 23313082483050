import request from './request'

export const getBrandReportsCatalog = () =>
  request.get('/backend/brand/get-brand-catalogs')

export const getBrandProducts = (data) =>
  request.post('/backend/brand/get-brand-products', data)

export const getBrandCrowd = (data) =>
  request.post('/backend/brand/get-brand-crowd', data)

export const getBrandGa = (data) =>
  request.post('/backend/brand/get-brand-ga', data)

export const getBrandPie = (data) =>
  request.post('/backend/brand/get-brand-pie', data)

export const getSeriesPie = (data) =>
  request.post('/backend/brand/get-series-pie', data)
