import React, {
  useEffect,
  useCallback,
  useState,
  useMemo,
  useContext
} from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Row, Col, Input, DatePicker, Switch } from 'antd'
import moment from 'moment'

import {
  GridFormDrawer,
  CountrySelect,
  GridContext,
  SearchSelect
} from '../../../../components'
import { createSurvey, saveSurvey, getSurveyDetail } from '../services'
import services from '../../../../services'
import Questions from './Questions'

const dateFormat = 'YYYY-MM-DD HH:mm:ss'

const SurveyForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const location = useLocation()

  const disabledDate = (current) => {
    return current && current < moment().endOf('day')
  }

  const [country, setCountry] = useState('')
  const countryChange = (value) => {
    setCountry(value)
  }

  const filterCountry = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('___country')
  }, [location])

  const { onLoad, toggle, record } = useContext(GridContext)

  const initSurveyData = useMemo(
    () => ({
      name: '',
      type: 'single',
      options: {
        a: { answer: '', score: '' }
      }
    }),
    []
  )
  const [surveyData, setSurveyData] = useState(initSurveyData)
  const [hasUpdate, setHasUpdate] = useState(false)

  const emptyForm = () => {
    setFormData({})
    setCountry('')
    setSurveyData(initSurveyData)
    form.resetFields()
  }

  const closeDrawer = () => {
    emptyForm()
    toggle({}, '')
    onLoad()
  }

  // format survey questions form data
  const formatQuestions = useCallback((question, key = '1') => {
    let questionData = {}
    questionData[key] = {
      question: question.name,
      type: question.type
    }
    if (question.type === 'answer') {
      return questionData
    }
    if (question.options) {
      Object.values(question.options).forEach((item, index) => {
        if (item.sub) {
          const subQuestionData = formatQuestions(
            item.sub,
            `${key}-${index + 1}-1`
          )
          questionData = Object.assign(questionData, subQuestionData)
          questionData[`${key}-${index + 1}`] = {
            option: item.answer,
            score: item.score
          }
        } else {
          questionData[`${key}-${index + 1}`] = {
            option: item.answer,
            score: item.score
          }
        }
      })
    }
    return questionData
  }, [])

  const formatResData = useCallback(
    (value) => {
      const newValue = {
        id: value.id,
        country: filterCountry,
        discussion_id: {
          value: value.discussion.id,
          label: value.discussion.title
        },
        survey_name: value.survey_name,
        end_time: moment(value.end_time, dateFormat),
        is_anonymous: !!value.is_anonymous,
        questions: formatQuestions(value.questions)
      }
      setCountry(filterCountry)
      setSurveyData(value.questions)
      setFormData(newValue)
    },
    [filterCountry, formatQuestions]
  )

  const openHandler = (id) => {
    getSurveyDetail(formatResData)({ id, ___country: filterCountry })
  }

  const onFinish = (values) => {
    const params = {
      discussion_id: values.discussion_id.value,
      survey: {
        name: values.survey_name,
        end_time: moment(values.end_time).format(dateFormat),
        is_anonymous: values.is_anonymous ? 1 : 0
      }
    }

    if (hasUpdate) {
      params.survey = Object.assign(params.survey, {
        questions: surveyData
      })
    }

    if (formData.id) {
      saveSurvey(closeDrawer)({
        id: formData.id,
        country: values.country,
        params: Object.assign(params.survey, {
          discussion_id: values.discussion_id.value
        })
      })
    } else {
      createSurvey(closeDrawer)({ country: values.country, params })
    }
  }

  useEffect(() => {
    form.setFieldsValue({ questions: formatQuestions(surveyData) })
  }, [form, formatQuestions, surveyData])

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={!record.is_involved_survey ? () => form.submit() : null}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Survey' : 'New Survey')}
    >
      <Form form={form} onFinish={onFinish} autoComplete="off">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please select country' }]}
            >
              <CountrySelect onChange={countryChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Discussion Id"
              name="discussion_id"
              rules={[
                { required: true, message: 'Please select discussion id' }
              ]}
            >
              <SearchSelect
                showSearch
                placeholder="Search Discussion"
                country={country}
                fetchApi={services.bbs.getDiscussionList}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Survey Title"
              name="survey_name"
              rules={[{ required: true, message: 'Please enter survey title' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Closing Date"
              name="end_time"
              rules={[
                { required: true, message: 'Please select closing date' }
              ]}
            >
              <DatePicker
                disabledDate={disabledDate}
                format={dateFormat}
                showTime
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Secret Ballot"
              name="is_anonymous"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Questions
          initSurveyData={initSurveyData}
          surveyData={surveyData}
          setSurveyData={setSurveyData}
          setHasUpdate={setHasUpdate}
        />
      </Form>
    </GridFormDrawer>
  )
}

export default SurveyForm
