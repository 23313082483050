import React, { useContext } from 'react'
import { EditOutlined, DeleteOutlined, SyncOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { PermissionButton, GridContext } from '../../../components'
import { deletePriceCompare, freshPriceCompare } from '../services'

interface Props {
  record: Object;
}
const Actions = ({ record }: Props) => {
  const { toggle, onLoad } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit')
  }
  const onDelete = () => {
    deletePriceCompare(onLoad)(record.id)
  }
  const onFresh = () => {
    freshPriceCompare(onLoad)(record.id)
  }
  return (
    <>
      <PermissionButton
        size="small"
        onClick={onEdit}
        icon={<EditOutlined />}
        permission="toolkit/price-compare/save"
        type="primary"
      />
      &nbsp;
      <PermissionButton
        size="small"
        type="primary"
        onClick={onFresh}
        icon={<SyncOutlined />}
        permission="toolkit/price-compare/fresh"
      />
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <PermissionButton
          style={{ marginLeft: '5px' }}
          icon={<DeleteOutlined />}
          size="small"
          permission="toolkit/price-compare/delete"
          type="primary"
          danger
        />
      </Popconfirm>
    </>
  )
}
export default Actions
