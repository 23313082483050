import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  Card,
  DatePicker,
  /*  Row,
  Col, */
  Typography,
  Space,
  Table,
  Button,
  Select
} from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import { cloneDeep } from 'lodash'

import { getStaticText, checkPerms, storage } from '../../utils'
import services from '../../services'
import dateFormat from '../../config/dateFormat'
import { EditDrawer } from '../../components'
import totalReportSiteColumns from '../../config/tableColumns/totalReportSiteColumns'

const { Text } = Typography
const { Option } = Select

const dateFormatDay = dateFormat.year
// 子盘 权限
const permission = 'disk-report/edit'

const SiteTotalReport = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  const [isLoad, setIsLoad] = useState(false)
  const newReportColumns = totalReportSiteColumns

  const [isLoading, setIsLoading] = useState(false)

  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  // let defaultDate = moment()
  const defaultReqData = {
    country: 'All_Country',
    defaultDate: moment()
  }

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const dataForSearch = nextSearch.get('start_date')
  if (dataForSearch) {
    const nowDate = moment(dataForSearch, dateFormatDay)
    if (nowDate.isValid()) {
      defaultReqData.defaultDate = nowDate
    }
  }
  const countryForSearch = nextSearch.get('country')
  if (countryForSearch) {
    defaultReqData.country = countryForSearch
  }

  const [tableData, setTableData] = useState([])

  /* const [requestParams, setRequestParams] = useState(() => {
    return {
      start_date: defaultDate,
      start_date_format: defaultDate.format(dateFormatDay),
      end_date: defaultDate,
      end_date_format: defaultDate.format(dateFormatDay)
    }
  }) */
  const [requestParams, setRequestParams] = useState(() => {
    return {
      country: defaultReqData.country
        .split(',')
        .filter((item) => !(item === '')),
      start_date: defaultReqData.defaultDate,
      start_date_format: defaultReqData.defaultDate.format(dateFormatDay),
      end_date: defaultReqData.defaultDate,
      end_date_format: defaultReqData.defaultDate.format(dateFormatDay)
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      country: requestParams.country.toString(),
      start_date: requestParams.start_date_format,
      end_date: requestParams.end_date_format
    }
  }, [requestParams])

  // countries 判断 disabled 属性
  const countries = useMemo(() => {
    const country = storage.allowedCountries.val || []
    if (requestParams.country.includes('All_Country')) {
      country.forEach((country) => {
        country.disabled = true
      })
      country[0].disabled = false
    } else if (requestParams.country.length === 0) {
      country.forEach((country) => {
        country.disabled = false
      })
    } else {
      country[0].disabled = true
    }
    return country
  }, [requestParams])

  const fetchData = useCallback(() => {
    toggleLoading()

    services.totalReport
      .getSiteTotalReport(requestParamsTransformer)
      .then((result) => {
        setTableData(result.disk_report || [])
      })
      .finally(() => {
        toggleLoading()
      })
  }, [requestParamsTransformer, toggleLoading])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country
    }))
  }

  const handleChangeDate = useCallback((date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      start_date: date,
      start_date_format: dateString,
      end_date: date,
      end_date_format: dateString
    }))
  }, [])

  useEffect(() => {
    if (!isLoad) {
      fetchData()
    }
    setIsLoad(true)
  }, [fetchData, isLoad])

  const filter = () => {
    setUrlSearchForState()
    fetchData()
  }

  // edit drawerProps
  const [drawerProps, setDrawerProps] = useState({
    visible: false,
    placement: 'right',
    width: '30%'
  })

  const handleToggleDrawer = useCallback(() => {
    setDrawerProps((prevState) => ({
      ...prevState,
      visible: !prevState.visible
    }))
  }, [])

  const [editData, setEditData] = useState({})

  const setDataColumns = useCallback(
    (data) => {
      setEditData(data)
      handleToggleDrawer()
    },
    [handleToggleDrawer]
  )

  if (checkPerms(permission)) {
    if (
      newReportColumns[newReportColumns.length - 1].key !== 'table.operating'
    ) {
      newReportColumns.push({
        title: getStaticText('table.operating'),
        key: 'table.operating',
        render: (row) => {
          return (
            <Button type="primary" onClick={() => setDataColumns(row)}>
              {getStaticText('table.operating.button.text')}
            </Button>
          )
        },
        fixed: 'right',
        width: 100,
        align: 'center'
      })
    }
  }

  const cloneColumns = () => {
    if (
      newReportColumns[newReportColumns.length - 1].key === 'table.operating'
    ) {
      const nextReportColumns = cloneDeep(newReportColumns)
      nextReportColumns.pop()
      return nextReportColumns
    }

    return newReportColumns
  }

  const [editLoading, setEditLoading] = useState(false)

  const handleFinish = (values) => {
    setEditLoading(true)
    services.siteTotalReport
      .set(values)
      .then(() => {
        fetchData()
      })
      .finally(() => {
        handleToggleDrawer()
        setEditLoading(false)
      })
  }

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        {/* <Row>
          <Col span={8}>
            <Space>
              <Text>{getStaticText('component.search.time.text')}</Text>
              <DatePicker
                style={{ width: '250px' }}
                picker="year"
                format={dateFormatDay}
                allowClear={false}
                defaultValue={requestParams.start_date}
                onChange={handleChangeDate}
              />
            </Space>
          </Col>
        </Row> */}
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.time.text')}
          </Text>
          <DatePicker
            style={{ width: '250px' }}
            picker="year"
            format={dateFormatDay}
            allowClear={false}
            defaultValue={requestParams.start_date}
            onChange={handleChangeDate}
          />
        </Space>
        <Space style={{ cssFloat: 'right' }}>
          <Button type="primary" onClick={filter}>
            {getStaticText('component.search.button.filter')}
          </Button>
        </Space>
      </Card>

      <Card>
        <Text>{getStaticText('component.table.header.title')}</Text>
      </Card>

      <Table
        sticky
        bordered
        size="small"
        loading={isLoading}
        columns={newReportColumns}
        dataSource={tableData}
        rowKey={(record) => record.id.value}
        scroll={{ x: 'max-content', y: 500 }}
        pagination={false}
      />

      <EditDrawer
        title={getStaticText('table.operating.button.text')}
        drawerProps={drawerProps}
        onClose={handleToggleDrawer}
        columns={cloneColumns()}
        dataSource={editData}
        colNum={2}
        handleFinish={handleFinish}
        loading={editLoading}
      />
    </>
  )
}

SiteTotalReport.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default SiteTotalReport
