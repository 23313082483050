/* eslint-disable */
import React from 'react'
import { Button, Checkbox, Col, Image, Row, Tag } from 'antd'
import imagePlaceholder from '../../../assets/empty.webp'

interface Props {
  data: Array;
  id: Number;
  showModal: Function;
  handleCheckboxChange: Function;
  collectFunction: Function;
  loading: Boolean;
  idProductLoading: Boolean;
}

const CardExclusive = ({
  data,
  id,
  handleCheckboxChange,
  checkedData,
  collectFunction,
  loading,
  idProductLoading
}: Props) => {
  const listRender = (text) => {
    return <li>{text}</li>
  }
  return (
    <Col xs={24} sm={12} md={12} xxl={6} key={id}>
      <Row className="card-wrapper" justify="center">
        <Row
          style={{
            width: '244px',
            height: '600px',
            position: 'relative',
            background: checkedData[id] ? '#00FFFE08' : 'unset'
          }}
        >

          <Checkbox
            onChange={() => handleCheckboxChange(id)}
            checked={checkedData[id] || false}
            style={{
              position: 'absolute',
              top: '5px',
              left: '5px',
              zIndex: 1
            }}
          />
          <Col span={24}>
            <Image
              height={280}
              width={244}
              preview={false}
              style={{ objectFit: 'contain' }}
              src={`${data?.image || imagePlaceholder}`}
            />
          </Col>
          <Col span={24} style={{ fontSize: '15px' }}>
            <p className="description" style={{ marginBottom: 'unset' }}>
              {data.desc}
            </p>
          </Col>
          <Col span={24}>
            {data.tag.map((_tag) => {
              return (
                <Tag
                  color="#C5CFE780"
                  style={{ color: '#4869AF', marginTop: '2px' }}
                >
                  {_tag}
                </Tag>
              )
            })}
          </Col>
          <Col span={24}>
            <p
              style={{
                color: '#FA1111',
                fontSize: '20px',
                marginBottom: 'unset'
              }}
            >
              {`CNY ${data.price.price}`}
            </p>
          </Col>
          <Col span={24}>
            <ul style={{ marginLeft: '-20px' }}>
              {data?.monthlySales &&
                listRender(`Monthly sales volume ${data.monthlySales}`)}
              {data?.repurchase &&
                listRender(`repurchase rate ${data.repurchase}`)}
            </ul>
          </Col>
          <Col span={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button
              style={{ width: '100%', fontSize: '20px', height: '40px' }}
              type="default"
              onClick={() => handleCheckboxChange(id)}
            >
              Select
            </Button>
          </Col>
          <Col span={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Button
              loading={loading && idProductLoading === data.id}
              style={{ width: '100%', fontSize: '20px', height: '40px' }}
              type="default"
              onClick={() => collectFunction(data.id)}
            >
              Collect
            </Button>
          </Col>
        </Row>
      </Row>
    </Col>
  )
}

export default CardExclusive
