import React, { useEffect, useState } from 'react'

interface Props {
  percentString: string;
}

const AddPercent = ({ percentString }: Props) => {
  const [PercentClass, setPercentClass] = useState('')

  useEffect(() => {
    const percent = parseFloat(percentString.trim('%'))

    let percentClass = ''
    percentClass = percent > 0 ? 'percent-up' : ''
    percentClass = percent < 0 ? 'percent-down' : percentClass
    setPercentClass(percentClass)
  }, [percentString])

  return (
    <>
      <span className={PercentClass}>{percentString}</span>
    </>
  )
}
export default AddPercent
