import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function ConversionRatePreSale(props) {
  const { tableData } = props
  return (
    <>
      {/* 转化率-售前 */}
      {/* row1 */}
      <tr className="table-th-color-other">
        <th rowSpan="26" className="sticky-left">
          所有货
        </th>
        <th rowSpan="3" className="sticky-left sticky-second">
          转化率-售前
        </th>
        <th colSpan="3">
          访客数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>GA User</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">平均停留时间</th>
        <th colSpan="3">
          人均浏览量
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>人均页面浏览量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">加购人数</th>
        <th colSpan="3">转化率</th>
        <th colSpan="3">收藏人数</th>
        <th colSpan="3">收藏转化率</th>
        <th colSpan="3">活动入口图点击率</th>
        <th colSpan="3">视频完播率</th>
        <th colSpan="3">视频引导加购率</th>
        <th colSpan="3">视频转化率</th>
        <th colSpan="3">客服询盘转化率</th>
      </tr>

      {/* row2 */}
      <tr>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：访客数/去年同期访客数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：平均停留时间/去年同期平均停留时间-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：人均浏览量/去年同期人均浏览量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：加购人数/去年同期加购人数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：加购并下单人数/UV</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：转换率/去年同期转换率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：MAGENTO查询</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：收藏人数/去年同期收藏人数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：收藏人数并下单的人数/UV</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：收藏转化率/去年同期收藏转化率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：BannerClick事件点击次数/访客数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：活动入口图点击率/去年同期活动入口图点击率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：视频播完量/视频播放量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：视频完播率 /去年同期视频完播率 -1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际视频引流加购数/视频播放量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：视频引导加购率/视频引导加购率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际视频引流下单数/视频播放量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：视频转化率/去年同期视频转化率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：点击LiveChart同时下单的人数/UV</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：客服询盘转化率/去年同期客服询盘转化率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.visitors_actual}</td>
        <td>{tableData.visitors_budget}</td>
        <td>{tableData.visitors_rate_year_on_year}</td>
        <td>{tableData.stickon_time_actual}</td>
        <td>{tableData.stickon_time_budget}</td>
        <td>{tableData.stickon_time_rate_year_on_year}</td>
        <td>{tableData.pv_per_person_actual}</td>
        <td>{tableData.pv_per_person_budget}</td>
        <td>{tableData.pv_per_person_rate_year_on_year}</td>
        <td>{tableData.total_add_to_cart_actual}</td>
        <td>{tableData.total_add_to_cart_budget}</td>
        <td>{tableData.total_add_to_cart_rate_year_on_year}</td>
        <td>{tableData.total_add_to_cart_rate_actual}</td>
        <td>{tableData.total_add_to_cart_rate_budget}</td>
        <td>{tableData.total_add_to_cart_rate_rate_year_on_year}</td>
        <td>{tableData.total_add_to_wishlist_actual}</td>
        <td>{tableData.total_add_to_wishlist_budget}</td>
        <td>{tableData.total_add_to_wishlist_rate_year_on_year}</td>
        <td>{tableData.total_add_to_wishlist_rate_actual}</td>
        <td>{tableData.total_add_to_wishlist_rate_budget}</td>
        <td>{tableData.total_add_to_wishlist_rate_rate_year_on_year}</td>
        <td>{tableData.activity_image_click_rate_actual}</td>
        <td>{tableData.activity_image_click_rate_budget}</td>
        <td>{tableData.activity_image_click_rate_rate_year_on_year}</td>
        <td>{tableData.video_played_completed_rate_actual}</td>
        <td>{tableData.video_played_completed_rate_budget}</td>
        <td>{tableData.video_played_completed_rate_rate_year_on_year}</td>
        <td>{tableData.video_guide_add_to_cart_actual}</td>
        <td>{tableData.video_guide_add_to_cart_budget}</td>
        <td>{tableData.video_guide_add_to_cart_rate_year_on_year}</td>
        <td>{tableData.video_guide_order_actual}</td>
        <td>{tableData.video_guide_order_budget}</td>
        <td>{tableData.video_guide_order_rate_year_on_year}</td>
        <td>{tableData.live_chart_guide_order_actual}</td>
        <td>{tableData.live_chart_guide_order_budget}</td>
        <td>{tableData.live_chart_guide_order_rate_year_on_year}</td>
      </tr>
    </>
  )
}

ConversionRatePreSale.propTypes = {
  tableData: shape({}).isRequired
}

export default ConversionRatePreSale
