import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function PpShop(props) {
  const { tableData } = props
  return (
    <>
      {/* PP商 */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="3" className="sticky-left sticky-second">
          PP商
        </th>
        <th colSpan="3">
          累计PP商总数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          本期PP商新增数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          累计PP商完成订单数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          本期PP商完成订单数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          全球跨品类压货PP商占比 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  统计全球
                  <br />
                  这个产品是跨境产品，同时这个产品属于PP商管理，这个产品在PP商总产品的占比
                  <br />
                  pp商跨品类商品/pp商商品
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          各站点跨品类压货PP商占比 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  这个产品是跨境产品，同时这个产品属于PP商管理，这个产品在PP商总产品的占比
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          全球订单24小时电话确认率% &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：
                  {/* 客服与消费者电话沟通确认订单得到消费者取消订单的单量/订单生成总单量 */}
                  {/* <br /> */}
                  PP商订单电话确认数/PP商订单总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          各站订单24小时电话确认率% &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：
                  {/* 客服与消费者电话沟通确认订单得到消费者取消订单的单量/订单生成总单量 */}
                  {/* <br /> */}
                  PP商订单电话确认数/PP商订单总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          全球订单取消率% &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：
                  取消率即投送前客服与消费者电话沟通确认订单得到消费者取消订单的单量/订单生成总单量
                  <br />
                  PP商订单取消数/PP商订单总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          各站订单取消率% &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：
                  取消率即投送前客服与消费者电话沟通确认订单得到消费者取消订单的单量/订单生成总单量
                  <br />
                  PP商订单取消数/PP商订单总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          全球订单36小时发货成功率 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  {/* 来源： 从订单生成到shipping发运确认在36小时之内 */}
                  {/* <br /> */}
                  来源：PP商36小时内成功发货量/PP商订单总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          各站点订单36小时发货成功率 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  {/* 来源： 从订单生成到shipping发运确认在36小时之内 */}
                  {/* <br /> */}
                  来源：PP商36小时内成功发货量/PP商订单总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          全球订单发货时效（天） &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源： 计算从订单生成到shipping发运确认在多少天之内，平均数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          各站点订单发货时效（天） &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：计算从订单生成到shipping发运确认在多少天之内</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          全球订单订妥投率% &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  妥投率，意味快递成功投送订单给消费者的单量/（on-hold + fail to
                  delivery + complete）单量
                  <br />
                  onhold订单表示消费者电话确认要求发货但投递不成功
                  <br />
                  fail to delivery
                  订单表示虽然消费者电话确认要求发货但上门投递时消费者取消订单
                  <br />
                  complete订单表示消费者电话确认要求发货且签收了的订单
                  <br />
                  <br />
                  来源：PP商订单妥投数/PP商订单总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          各站订单订妥投率% &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  妥投率，意味快递成功投送订单给消费者的单量/（on-hold + fail to
                  delivery + complete）单量
                  <br />
                  onhold订单表示消费者电话确认要求发货但投递不成功
                  <br />
                  fail to delivery
                  订单表示虽然消费者电话确认要求发货但上门投递时消费者取消订单
                  <br />
                  complete订单表示消费者电话确认要求发货且签收了的订单
                  <br />
                  来源：PP商订单妥投数/PP商订单总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          全球订单订退货率% &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：退货率是消费者签收订单后，要求退货的订单/总妥投订单。
                  <br />
                  退货可以接受两种情况：
                  <br />
                  1.发生质量问题
                  <br />
                  2.在退货有效期内且没有拆封可以二次销售
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          各站订单退货率% &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源： 退货率是消费者签收订单后，要求退货的订单/总妥投订单。
                  <br />
                  退货可以接受两种情况：
                  <br />
                  1.发生质量问题
                  <br />
                  2.在退货有效期内且没有拆封可以二次销售
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row2 */}
      <tr>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th> </th>
        <th> </th>
        <th> </th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th> </th>
        <th> </th>
        <th> </th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th> </th>
        <th> </th>
        <th> </th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th> </th>
        <th> </th>
        <th> </th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th> </th>
        <th> </th>
        <th> </th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th> </th>
        <th> </th>
        <th> </th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.complete_of_pp_users_actual}</td>
        <td>{tableData.complete_of_pp_users_budget}</td>
        <td>{tableData.complete_of_pp_users_rate_year_on_year}</td>
        <td>{tableData.new_of_pp_users_actual}</td>
        <td>{tableData.new_of_pp_users_budget}</td>
        <td>{tableData.new_of_pp_users_rate_year_on_year}</td>
        <td>{tableData.complete_of_pp_orders_actual}</td>
        <td>{tableData.complete_of_pp_orders_budget}</td>
        <td>{tableData.complete_of_pp_orders_rate_year_on_year}</td>
        <td>{tableData.pp_orders_during_actual}</td>
        <td>{tableData.pp_orders_during_budget}</td>
        <td>{tableData.pp_orders_during_rate_year_on_year}</td>
        <td>{tableData.global_pp_overseas_sku_rate_actual}</td>
        <td>{tableData.global_pp_overseas_sku_rate_budget}</td>
        <td>{tableData.global_pp_overseas_sku_rate_rate_year_on_year}</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>{tableData.global_pp_orders_phone_rate_actual}</td>
        <td>{tableData.global_pp_orders_phone_rate_budget}</td>
        <td>{tableData.global_pp_orders_phone_rate_rate_year_on_year}</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>{tableData.global_pp_order_cancel_rate_actual}</td>
        <td>{tableData.global_pp_order_cancel_rate_budget}</td>
        <td>{tableData.global_pp_order_cancel_rate_rate_year_on_year}</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>{tableData.global_pp_orders_shiped_rate_actual}</td>
        <td>{tableData.global_pp_orders_shiped_rate_budget}</td>
        <td>{tableData.global_pp_orders_shiped_rate_rate_year_on_year}</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>{tableData.global_pp_orders_ship_delay_days_actual}</td>
        <td>{tableData.global_pp_orders_ship_delay_days_budget}</td>
        <td>{tableData.global_pp_orders_ship_delay_days_rate_year_on_year}</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>{tableData.global_pp_order_dealt_rate_actual}</td>
        <td>{tableData.global_pp_order_dealt_rate_budget}</td>
        <td>{tableData.global_pp_order_dealt_rate_rate_year_on_year}</td>
        <td> </td>
        <td> </td>
        <td> </td>
        <td>{tableData.global_pp_order_return_rate_actual}</td>
        <td>{tableData.global_pp_order_return_rate_budget}</td>
        <td>{tableData.global_pp_order_return_rate_rate_year_on_year}</td>
        <td> </td>
        <td> </td>
        <td> </td>
      </tr>
    </>
  )
}

PpShop.propTypes = {
  tableData: shape({}).isRequired
}

export default PpShop
