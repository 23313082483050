import React from 'react'
import ConversionRatePreSale from './ConversionRatePreSale'
import ConversionRateDeal from './ConversionRateDeal'
import ConversionRateRepurchase from './ConversionRateRepurchase'
import UnitPricePerCustomer from './UnitPricePerCustomer'
import Order from './Order'
import CM from './CM'
import Stock from './Stock'

function DashboardResult(props) {
  return (
    <>
      <tbody>
        <ConversionRatePreSale {...props} />
        <ConversionRateDeal {...props} />
        <ConversionRateRepurchase {...props} />
        <UnitPricePerCustomer {...props} />
        <Order {...props} />
        <CM {...props} />
        <Stock {...props} />
      </tbody>
    </>
  )
}

export default DashboardResult
