import { Creatings } from '../pages'

const userOperation = {
  path: '/user-operation',
  primary: 'app.sider.user-operation',
  routes: [
    {
      path: '/user-operation/chunyin',
      modular: 'crm',
      permission: 'user-operation-chunyin',
      primary: 'app.sider.user-operation.chunyin',
      component: Creatings,
      exact: true
    },
    {
      path: '/user-operation/other',
      modular: 'crm',
      permission: 'user-operation-other',
      primary: 'app.sider.user-operation.other',
      component: Creatings,
      exact: true
    },
    {
      path: '/user-operation/touch',
      modular: 'crm',
      permission: 'user-operation-touch',
      primary: 'app.sider.user-operation.touch',
      component: Creatings,
      exact: true
    }
  ]
}
export default userOperation
