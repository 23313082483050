import React from 'react'
import { Drawer, Table, Tabs } from 'antd'
import { getStaticText } from '../../../utils'

const dashboardTopGoodsColumns = (key) => {
  const allColumns = [
    {
      title: <div>{getStaticText('dashboard.table.goods_name')}</div>,
      width: 500,
      key: 'goods_name',
      dataIndex: 'product_name',
      align: 'left'
    },
    {
      title: <div>零支付</div>,
      width: 50,
      dataIndex: 'zero_paid',
      key: 'zero_paid',
      align: 'center'
    },
    {
      title: <div>高跳出率</div>,
      dataIndex: 'high_jumpout',
      width: 50,
      key: 'high_jumpout',
      align: 'center'
    },
    {
      title: <div>低跳出率</div>,
      dataIndex: 'low_jumpout',
      width: 50,
      key: 'low_jumpout',
      align: 'center'
    },
    {
      title: <div>成交下跌</div>,
      dataIndex: 'purchase_dropped',
      width: 50,
      key: 'purchase_dropped',
      align: 'center'
    },
    {
      title: <div>流量下跌</div>,
      dataIndex: 'flow_dropped',
      width: 50,
      key: 'flow_dropped',
      align: 'center'
    },
    {
      title: <div>支付转化率低</div>,
      dataIndex: 'low_converted',
      width: 50,
      key: 'low_converted',
      align: 'center'
    },
    {
      title: <div>其它</div>,
      dataIndex: 'others',
      width: 50,
      key: 'others',
      align: 'center'
    }
  ]
  return allColumns.filter(
    (item) => item.key === 'goods_name' || item.key === key
  )
}

interface Props {
  onClose: () => {};
  visible: boolean;
  products: [];
}
const tabsHead = [
  { title: '零支付', key: 'zero_paid' },
  { title: '高跳出率', key: 'high_jumpout' },
  { title: '低跳出率', key: 'low_jumpout' },
  { title: '成交下跌', key: 'purchase_dropped' },
  { title: '流量下跌', key: 'flow_dropped' },
  { title: '支付转化率低', key: 'low_converted' },
  { title: '其它', key: 'others' }
]

const getProducts = (products, key) => {
  if (products) {
    return products.filter((item) => item[key])
  }
  return []
}
const GoodsFrame = ({ onClose, visible, products }: Props) => {
  // const [pageSize, setPageSize] = useState(10)
  // const [tableData, setTableData] = useState(products.filter((i) => i <= 10))
  // const [page, setPage] = useState(1)

  return (
    <>
      <Drawer
        title={`异常商品(${(products || []).length})`}
        placement="right"
        width="80%"
        onClose={onClose}
        visible={visible}
        getContainer="body"
        style={{ position: 'fixed', top: 0, right: 0 }}
      >
        <Tabs defaultActiveKey="1">
          {tabsHead.map((tab) => (
            <Tabs.TabPane
              tab={`${tab.title}(${getProducts(products, tab.key).length})`}
              key={tab.key}
            >
              <Table
                size="small"
                bordered
                columns={dashboardTopGoodsColumns(tab.key)}
                dataSource={getProducts(products, tab.key)}
                rowKey={(record) => record.rank}
                scroll={{ x: 'max-content', y: true }}
                sorter={Array.sort}
                sticky
                pagination
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Drawer>
    </>
  )
}

export default GoodsFrame
