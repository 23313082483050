import React, { memo } from 'react'
import { Layout, Typography } from 'antd'
import moment from 'moment'
import { getStaticText } from '../../utils'

const { Footer } = Layout
const { Text } = Typography
const AppFooter = memo(() => {
  const year = moment().format('YYYY')
  return (
    <Footer style={{ textAlign: 'center' }}>
      <Text strong>
        {`${getStaticText('app.footer.copyright')}${year}${getStaticText(
          'app.footer.company'
        )}`}
      </Text>
      <Text>{getStaticText('app.footer.statement')}</Text>
    </Footer>
  )
})

export default AppFooter
