/* eslint-disable */
import React from 'react'
import { Button, Col, Row, Form } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import RegularSelectInput from '../inputComponent/RegularSelectInput'
import RegularInput from '../inputComponent/RegularInput'
import RegularNumberInput from "../inputComponent/RegularNumberInput";

interface Props {
  creatorOption: Array;
  callback: any;
}
const FilterReleaseRecord = ({ creatorOption = [], formRef, callback, isLoadingList, platFormOptions }: Props) => {
  const handleOnFinishFilter = () => {
      const dataForm = formRef.getFieldsValue()
      if (callback) callback(dataForm)
  }

  return (
    <Form form={formRef} onFinish={handleOnFinishFilter}>
      <Row
        gutter={[10, 10]}
        style={{
          background: 'white',
          paddingInline: '5px',
          paddingBlock: '10px'
        }}
      >
        <Col span={12}>
          <RegularNumberInput
            title="Supply ID"
            style={{ width: '221px' }}
            size="large"
            placeHolder="Please enter the product title, separated by commas"
            colon
            space="85px"
            name="filter[offer_id]"
          />
        </Col>
        <Col span={12}>
          <RegularInput
            title="Product Title"
            style={{ width: '221px' }}
            size="large"
            placeHolder="Please enter the product title, separated by commas"
            colon
            space="71px"
            name="filter[subject_trans]"
          />
        </Col>
        <Col span={12}>
          <RegularSelectInput
            title="Site"
            style={{ width: '211px' }}
            space="120px"
            colon
            name="filter[platform]"
            options={platFormOptions}
          />
        </Col>
        <Col span={12}>
          <RegularSelectInput
            title="Creator"
            style={{ width: '200px' }}
            space="100px"
            colon
            name="filter[author]"
            options={creatorOption}
          />
        </Col>
        <Col span={2}>
          <Button
            size="large"
            style={{ background: 'black', color: 'white' }}
            icon={<SearchOutlined />}
            htmlType="submit"
            loading={isLoadingList}
          >
            Search
          </Button>
        </Col>
        <Col span={1}>
          <Col span={5}>
            <Button size="large" onClick={() => formRef.resetFields()}>Reset</Button>
          </Col>
        </Col>
        <Col span={10} />
      </Row>
    </Form>
  )
}

export default FilterReleaseRecord
