import React, { useState, useCallback } from 'react'
import { Card, Row, Col, Typography, Space } from 'antd'
import { useLocation } from 'react-router-dom'
import { getStaticText } from '../../../../utils'
import {
  GridContextProvider,
  PagingTable,
  ExportButton,
  ImportButton
} from '../../../../components'
import { createColumns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getOrderAuditList } from '../../services'
import AuditDetail from '../Detail/ui'

const { Text } = Typography

const ImportTips = () => {
  return (
    <>
      <p>
        Please upload an excel file, with at least 3 fields listed below being
        provided, you can also upload the exported file with the last field
        `status` filled to import
      </p>
      <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Phone Number</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Number</td>
            <td>String</td>
            <td>[1|2] 1 represents approved, 2 represents rejected</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

const ItemList = () => {
  const [gridData, setGridData] = useState(initGridData)
  const location = useLocation()

  const onLoad = useCallback(() => {
    getOrderAuditList(setGridData)(new URLSearchParams(location.search))
  }, [location, setGridData])

  return (
    <GridContextProvider onLoad={onLoad}>
      <Card>
        <Row>
          <Col span={24}>
            <Text>{getStaticText('component.table.header.title')}</Text>
            <Space style={{ cssFloat: 'right' }}>
              <ImportButton
                type="primary"
                reload={onLoad}
                tips={<ImportTips />}
                permission="topup/activity/audit/import"
              >
                Import
              </ImportButton>
              <ExportButton
                type="primary"
                permission="topup/activity/audit/export"
              >
                Export
              </ExportButton>
            </Space>
          </Col>
        </Row>
      </Card>
      <PagingTable
        columns={createColumns()}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.page}
        pageSize={gridData.page_size}
      />
      <AuditDetail />
    </GridContextProvider>
  )
}

export default ItemList
