import request from './request'

export const getPriceCompareList = (params) =>
  request.get('/backend/toolkit/price-compare/get-list', { params })

export const getPriceCompareDetail = (params) =>
  request.get('/backend/toolkit/price-compare/detail', { params })

export const updatePriceCompare = (params) => {
  return request.post('/backend/toolkit/price-compare/save', params)
}

export const createPriceCompare = (params) =>
  request.post('/backend/toolkit/price-compare/create', params)

export const deletePriceCompare = (params) =>
  request.post('/backend/toolkit/price-compare/delete', params)

export const getCountryConfigs = (params) =>
  request.get('/backend/toolkit/price-compare/config', { params })

export const freshPriceCompare = (params) =>
  request.post('/backend/toolkit/price-compare/fresh', params)

export const getPriceCompareResult = (params) =>
  request.get('/backend/toolkit/price-compare/results', { params })

export const exportReport = (params) =>
  request.post('/backend/direct-export/export', params)
