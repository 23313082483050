import React, { useState, useEffect } from 'react'
import { useHistory, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Spin } from 'antd'

const AuthRoute = (props) => {
  const [isCheckingTokenStatus, setIsCheckingTokenStatus] = useState(true)
  const history = useHistory()
  const userInfo = useSelector((state) => state.users.userInfo)

  useEffect(() => {
    if (!userInfo.token) {
      history.push('/login')
    } else {
      setIsCheckingTokenStatus(false)
    }
  }, [history, userInfo])

  return isCheckingTokenStatus ? (
    <Spin spinning size="large" style={{ lineHeight: 50, marginLeft: '50%' }} />
  ) : (
    <Route {...props} />
  )
}

export default AuthRoute
