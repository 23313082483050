import { Creatings } from '../pages'

const netRed = {
  path: '/net-red',
  primary: 'app.sider.net-red',
  modular: 'market',
  permission: 'net-red',
  component: Creatings,
  exact: true
}
export default netRed
