import OrdersProcessing from '../pages/OrderManagements/OrdersProcessing'
import OrderHistory from '../pages/OrderManagements/OrderHistory'

const orderManagements = {
  path: '/order-management',
  primary: 'app.sider.order-management',
  modular: 'order',
  permission: 'order-management',
  routes: [
    {
      path: '/order-management/order-processing',
      primary: 'app.sider.order-management-order-processing',
      component: OrdersProcessing,
      modular: 'order',
      permission: 'order-management/order-processing',
      exact: true
    },
    {
      path: '/order-management/order-history',
      primary: 'app.sider.order-management-order-history',
      component: OrderHistory,
      modular: 'order',
      permission: 'order-management/order-history',
      exact: true
    }
  ]
}

export default orderManagements
