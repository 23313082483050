import { setTableLoading, setGlobalLoading } from '../store/actions/global'
import store from '../store'

export const withTableLoading = (service) => {
  const serviceWithTableLoading = (params) => {
    store.dispatch(setTableLoading(1))
    return service(params)
      .then((data) => {
        store.dispatch(setTableLoading(0))
        return data
      })
      .catch(() => store.dispatch(setTableLoading(0)))
  }
  return serviceWithTableLoading
}

export const withGlobalLoading = (service) => {
  return (params) => {
    store.dispatch(setGlobalLoading(1))
    return service(params)
      .then((data) => {
        store.dispatch(setGlobalLoading(0))
        return data
      })
      .catch(() => store.dispatch(setGlobalLoading(0)))
  }
}
