import React, { useCallback, useState } from 'react'

import moment from 'moment'
import { Button, Card, Col, DatePicker, Space } from 'antd'
import Text from 'antd/es/typography/Text'
import { GridFormDrawer, PagingTable } from '../../../../../components'
import { createColumns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getFortunateList } from '../services'
import { getStaticText } from '../../../../../utils'
import dateFormat from '../../../../../config/dateFormat'
import services from '../../../../../services'

const { RangePicker } = DatePicker

// 默认请求参数 默认今天减1
const defaultReqData = {
  start_date: moment().subtract(1, 'months'),
  end_date: moment()
}
// 日格式化标准
const dateFormatDay = dateFormat.day

const FortunateList = () => {
  const [gridData, setGridData] = useState(initGridData)
  const [baseDate, setBaseDate] = useState(() => {
    return {
      start_date: defaultReqData.start_date.format(dateFormatDay),
      start_format: defaultReqData.start_date,
      end_date: defaultReqData.end_date.format(dateFormatDay),
      end_format: defaultReqData.end_date
    }
  })
  const [activityId, setActivityId] = useState(0)
  const [currentRecord, setCurrentRecord] = useState({})
  const paginationHandler = (current, pageSize) => {
    getFortunateList(setGridData)({
      start_date: baseDate.start_date,
      end_date: baseDate.end_date,
      activity_id: activityId,
      current,
      pageSize
    })
  }
  const handleChangeDate = useCallback(
    (date, dateString) => {
      setBaseDate((prevState) => ({
        ...prevState,
        start_date: dateString[0],
        start_format: date[0],
        end_date: dateString[1],
        end_format: date[1],
        current: 1
      }))
      getFortunateList(setGridData)({
        activity_id: activityId,
        current: 1,
        start_date: dateString[0],
        end_date: dateString[1]
      })
    },
    [activityId]
  )
  const handleExport = () => {
    services.luckyspin
      .exportFortunate({
        activity_id: activityId,
        start_date: baseDate.start_date,
        end_date: baseDate.end_date
      })
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
  }
  const onOpen = (record) => {
    getFortunateList(setGridData)({
      activity_id: record.id,
      start_date: baseDate.start_date,
      end_date: baseDate.end_date
    })
    setActivityId(record.id)
    setCurrentRecord(record)
  }
  return (
    <>
      <GridFormDrawer type="fortunate" title="中奖记录" onOpen={onOpen}>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <RangePicker
              picker="day"
              value={[baseDate.start_format, baseDate.end_format]}
              format={dateFormatDay}
              onChange={handleChangeDate}
              allowClear={false}
            />
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button onClick={handleExport}>
                {getStaticText('component.table.export.text')}
              </Button>
            </Col>
          </Space>
        </Card>
        <PagingTable
          handler={paginationHandler}
          columns={createColumns({
            onChange: () => {
              onOpen(currentRecord)
            }
          })}
          dataSource={dataSourceMap(gridData.data)}
          total={gridData.total}
          initGridData={initGridData}
          current={gridData.current}
          pageSize={gridData.page_size}
        />
      </GridFormDrawer>
    </>
  )
}

export default FortunateList
