/* eslint-disable */
import React from 'react'
import {Row, Col, Dropdown, Button, Space, Form, Radio} from 'antd'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import RegularInput from '../inputComponent/RegularInput'
import BlackButton from '../ButtonComponents/BlackButton'
import GrayButton from '../ButtonComponents/GrayButton'

const items = [
  {
    label: '1st menu item',
    key: '1'
  },
  {
    label: '2nd menu item',
    key: '2'
  },
  {
    label: '3rd menu item',
    key: '3'
  }
]
const FilterOrderProcessing = (props) => {
  const [form] = Form.useForm()
  const [formSearch] = Form.useForm()

  const onFilterFormChange = () => {
    props.setFormFilter(form.getFieldsValue)
  }

  const onFinishSearchFilter = () => {
    props.setSearch(formSearch.getFieldsValue())
  }

  const handleMenuClick = (e) => {
    console.log('click', e)
  }
  return (
    <div style={{ background: 'white', padding: '10px' }}>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Form form={formSearch} onFinish={onFinishSearchFilter}>
            <Row>
              <Col span={19}>
                <RegularInput
                  title="Global search"
                  style={{ width: '200px' }}
                  size="large"
                  placeHolder="Package number/order number/purchase order number/purchase logistics order"
                  colon
                  space="38px"
                  name="increment_id"
                />
              </Col>
              <Col span={3}>
                <Dropdown
                  menu={{
                    items,
                    onClick: handleMenuClick
                  }}
                  placement="bottomRight"
                >
                  <Button style={{ height: '100%' }}>
                    <Space>
                      Exact match
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Col>
              <Col span={2}>
                <Button
                  size="large"
                  style={{
                    background: 'black',
                    color: 'white',
                    width: '100%'
                  }}
                  htmlType="submit"
                  icon={<SearchOutlined />}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <Form
            form={form}
            initialValue={{
              status: 'pending',
              platform: 'All_Country'
            }}
            onValuesChange={() => onFilterFormChange()}
          >
            <Col span={24}>
              <Form.Item name="status">
                <Radio.Group
                  className="radio-group"
                  buttonStyle="solid"
                  style={{ width: '100%' }}
                  defaultValue="pending"
                >
                  <Radio.Button value="pending">Pending (10)</Radio.Button>
                  <Radio.Button value="waiting_for_order_to_be_shipped">Waiting for order to be shipped (10)</Radio.Button>
                  <Radio.Button value="delivery_platform">Delivery platform (5)</Radio.Button>
                  <Radio.Button value="shipped">Shipped (0)</Radio.Button>
                  <Radio.Button value="shelved">Shelved (0)</Radio.Button>
                  <Radio.Button value="platform_after_sales">Platform after-sales (50)</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Platform"
                name="platform"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 22 }}
                style={{ marginBottom: 'unset' }}
              >
                <Radio.Group
                  className="radio-group"
                  buttonStyle="solid"
                  style={{ width: '100%' }}
                  defaultValue="All_Country"
                >
                  <Radio.Button value="All_Country">All</Radio.Button>
                  <Radio.Button value="NG">Nigeria</Radio.Button>
                  <Radio.Button value="PK">Pakistan</Radio.Button>
                  <Radio.Button value="KE">Kenya</Radio.Button>
                  <Radio.Button value="MA">Morocco</Radio.Button>
                  <Radio.Button value="GH">Ghana</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Filter"
                labelCol={{ span: 2 }}
                wrapperCol={{ span: 22 }}
                style={{ marginBottom: 'unset' }}
              >
                <Row gutter={[10, 0]} className="order-processing-filter-2">
                  <Col span={24}>
                    <Radio.Group className="radio-group" buttonStyle="solid" style={{ width: '100%' }}>
                      <Radio.Button value="">All</Radio.Button>
                      <Radio.Button value="">No waybill number applied</Radio.Button>
                      <Radio.Button value="">Application for waybill successful</Radio.Button>
                      <Radio.Button value="">Failed to apply for waybill number</Radio.Button>
                      <Radio.Button value="">Shipping will be delayed soon</Radio.Button>
                      <Radio.Button value="">In stock</Radio.Button>
                      <Radio.Button value="">+ Custom filters</Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Form>
        </Col>

      </Row>
    </div>
  )
}

export default FilterOrderProcessing
