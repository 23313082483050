import React, { useContext } from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { PermissionButton, GridContext } from '../../../../components'
import { deletePermission } from '../services'

interface Props {
  record: Object;
}
const Actions = ({ record }: Props) => {
  const { toggle, onLoad } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit')
  }
  const onDelete = () => {
    deletePermission(onLoad)(record.id)
  }
  return (
    <>
      <PermissionButton
        type="primary"
        size="small"
        icon={<EditOutlined />}
        onClick={onEdit}
        permission="rbac/permission/save"
      >
        Edit
      </PermissionButton>
      <Popconfirm title="Are you sure" onConfirm={onDelete}>
        <PermissionButton
          style={{ marginLeft: '5px' }}
          type="primary"
          size="small"
          permission="rbac/permission/delete"
          icon={<DeleteOutlined />}
        >
          Delete
        </PermissionButton>
      </Popconfirm>
    </>
  )
}
export default Actions
