import { PriceCompareGrid, ReportExporter } from '../pages'

const toolkit = {
  path: '/toolkit',
  primary: 'app.sider.toolkit',
  hideGlobalTips: true,
  routes: [
    {
      path: '/toolkit/price-compare',
      modular: 'report',
      permission: 'toolkit/price-compare/get-list',
      primary: 'app.sider.toolkit.price-compare',
      component: PriceCompareGrid,
      exact: true
    },
    {
      path: '/toolkit/report-exporter',
      modular: 'report',
      permission: 'direct-export/export',
      primary: 'app.sider.toolkit.report-exporter',
      component: ReportExporter,
      exact: true
    }
  ]
}

export default toolkit
