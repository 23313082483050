import CollectionBox from '../pages/FastDelivery/CollectionBox'
import ReleaseRecord from '../pages/FastDelivery/ReleaseRecord'
import PricingTemplate from '../pages/FastDelivery/PricingTemplate'
import EditSelectedItem from '../pages/CommonFunction/EditSelectedItem'

const fastDelivery = {
  path: '/fast-delivery',
  primary: 'app.sider.fast-delivery',
  permission: 'fast-delivery',
  routes: [
    {
      path: '/fast-delivery/collection-box',
      primary: 'app.sider.fast-delivery-collecting-box',
      component: CollectionBox,
      modular: 'product',
      permission: 'fast-delivery/collection-box',
      exact: true
    },
    {
      path: '/fast-delivery/release-record',
      primary: 'app.sider.fast-delivery-release-record',
      component: ReleaseRecord,
      modular: 'product',
      permission: 'fast-delivery/release-record',
      exact: true
    },
    {
      path: '/fast-delivery/pricing-template',
      primary: 'app.sider.fast-delivery-pricing-template',
      component: PricingTemplate,
      permission: 'fast-delivery/pricing-template',
      modular: 'product',
      exact: true
    },
    {
      path: '/fast-delivery/collection-box/edit-selected-item',
      primary: 'app.sider.fast-delivery-collection-box-edit-selected-item',
      permission: 'fast-delivery/collection-box/edit-selected-item',
      component: EditSelectedItem,
      exact: true
    }
  ]
}

export default fastDelivery
