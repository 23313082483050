import React, { useEffect, useState, useCallback, useMemo } from 'react'
import {
  Card,
  DatePicker,
  Row,
  Col,
  Typography,
  Space,
  Table,
  Button,
  Modal,
  Divider,
  Empty
} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import { getStaticText, storage } from '../../utils'
import { DashboardDailyColumns } from '../../config/tableColumns'
import services from '../../services'
import dateFormat from '../../config/dateFormat'
import routes from '../../routes/routes'

const { Text } = Typography

// 日格式化标准
const dateFormatDay = dateFormat.day

const Index = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  // 默认请求参数 默认今天减1
  const defaultReqData = {
    country: 'All_Country',
    platform: 'All_Platform',
    defaultDay: moment().subtract(1, 'day')
  }

  const [isLoading, setIsLoading] = useState(false)

  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  const getPermissionsModular = useCallback((routes) => {
    let modulars = []
    // eslint-disable-next-line no-restricted-syntax,no-undef
    for (const route of routes) {
      if (route.routes) {
        modulars = modulars.concat(getPermissionsModular(route.routes))
      } else if (
        route.modular &&
        (storage.userInfo.val.user.permissions.indexOf('/') !== -1 ||
          storage.userInfo.val.user.permissions.indexOf(route.permission) !==
            -1)
      ) {
        if (route.primary === 'app.sider.daily.sales.dashboard') {
          modulars.push('daily')
        }
        modulars.push(route.modular)
      }
    }
    return modulars.filter((item, index) => modulars.indexOf(item) === index)
  }, [])

  const isHidden = useCallback(
    (modular) => getPermissionsModular(routes).indexOf(modular) === -1,
    [getPermissionsModular]
  )

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const dataForSearch = nextSearch.get('report_date')
  if (dataForSearch) {
    const nowDate = moment(dataForSearch, dateFormatDay)
    if (nowDate.isValid()) {
      defaultReqData.defaultDay = nowDate
    }
  }
  const countryForSearch = nextSearch.get('country')
  if (countryForSearch) {
    defaultReqData.country = countryForSearch
  }
  const platformForSearch = nextSearch.get('platform')
  if (platformForSearch) {
    defaultReqData.platform = platformForSearch
  }

  const [tableData, setTableData] = useState([])
  const columns = useMemo(() => [...DashboardDailyColumns], [])

  const [requestParams, setRequestParams] = useState(() => ({
    platform: defaultReqData.platform
      .split(',')
      .filter((item) => !(item === '')),
    country: defaultReqData.country.split(',').filter((item) => !(item === '')),
    report_date: defaultReqData.defaultDay,
    format: defaultReqData.defaultDay.format(dateFormatDay)
  }))

  // 转换请求的参数
  const requestParamsTransformer = useMemo(
    () => ({
      platform: requestParams.platform.toString(),
      country: requestParams.country.toString(),
      report_date: requestParams.format
    }),
    [requestParams]
  )

  const fetchData = useCallback(() => {
    if (!isHidden('daily')) {
      toggleLoading()
      services.dashboardDaily
        .getDashboardDaily(requestParamsTransformer)
        .then((result) => {
          let data = result.daily_normal_dashboard || []
          if (data) {
            data = data.map((item) => {
              item['app-user-mistakes'] = 'N/A'
              return item
            })
          }
          // 数据格式处理
          setTableData(data)
        })
        .finally(() => {
          toggleLoading()
        })
    }
  }, [toggleLoading, isHidden, requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  const handleChangeDate = useCallback((date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      report_date: date,
      format: dateString
    }))
  }, [])

  useEffect(() => {
    fetchData()
    setUrlSearchForState()
  }, [fetchData, setUrlSearchForState])

  const [reviewsData, setReviewsData] = useState([])
  const [modalVisible, setModalDataVisible] = useState(false)
  const toggleModal = () => {
    setModalDataVisible((prevState) => {
      if (prevState === true) {
        setReviewsData([])
      }
      return !prevState
    })
  }

  const requestReviewParams = useCallback(
    (row) => ({
      country: row.country,
      type: 1, // type: 1 代表日报，type: 2 代表周报
      date: requestParamsTransformer.report_date,
      current: 1,
      pageSize: 20
    }),
    [requestParamsTransformer]
  )

  const setModalData = useCallback(
    (row) => {
      const param = requestReviewParams(row)
      services.common.getBadReviewDetail(param).then((result) => {
        setReviewsData(result.review_details || [])
      })
      toggleModal()
    },
    [requestReviewParams]
  )

  const newColumns = useMemo(() => {
    const reviewColumn = columns.filter(
      (column) => column.key === 'bad_review_total'
    )
    if (reviewColumn && reviewColumn.length) {
      reviewColumn[0].render = (data, row) => {
        return (
          <span
            className="clickLabel"
            onClick={() => setModalData(row)}
            onKeyPress={() => {}}
            role="button"
            tabIndex="0"
          >
            {data}
          </span>
        )
      }
    }
    return columns
  }, [setModalData, columns])

  const checkLink = (modular) => {
    storage.modular.val = modular
    // eslint-disable-next-line no-use-before-define
    const path = getIndexPath(routes)
    setTimeout(() => history.push(path), 1000)
  }

  const getIndexPath = (routes) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const route of routes) {
      if (route.routes) {
        const path = getIndexPath(route.routes)
        if (path) {
          return path
        }
      }
      if (
        (storage.userInfo.val.user.permissions.indexOf('/') !== -1 ||
          storage.userInfo.val.user.permissions.indexOf(route.permission) !==
            -1) &&
        route.modular &&
        route.modular === storage.modular.val
      ) {
        return route.path
      }
    }
    return ''
  }

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Button
            className="index-check color4"
            onClick={() => checkLink('report')}
            loading={isLoading}
            hidden={isHidden('report')}
          >
            {getStaticText('app.sider.logo.report')}
          </Button>
          <Button
            className="index-check color1"
            onClick={() => checkLink('crm')}
            loading={isLoading}
            hidden={isHidden('crm')}
          >
            {getStaticText('app.sider.logo.crm')}
          </Button>
          <Button
            className="index-check color2"
            onClick={() => checkLink('market')}
            loading={isLoading}
            hidden={isHidden('market')}
          >
            {getStaticText('app.sider.logo.market')}
          </Button>
          <Button
            className="index-check color3"
            onClick={() => checkLink('merchant')}
            loading={isLoading}
            hidden={isHidden('merchant')}
          >
            {getStaticText('app.sider.logo.merchant')}
          </Button>
          <Button
            className="index-check color5"
            onClick={() => checkLink('config')}
            loading={isLoading}
            hidden={isHidden('config')}
          >
            {getStaticText('app.sider.logo.config')}
          </Button>
        </Space>
      </Card>
      <Card hidden={isHidden('daily')}>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <DatePicker
              style={{ width: '250px' }}
              picker="date"
              format={dateFormatDay}
              allowClear={false}
              defaultValue={requestParams.report_date}
              onChange={handleChangeDate}
            />
          </Space>
        </Card>

        <Table
          sticky
          size="small"
          loading={isLoading}
          columns={newColumns}
          dataSource={tableData}
          rowKey={(record) => record.id}
          scroll={{ x: 'max-content', y: 500 }}
          tableLayout="fixed"
          bordered={false}
          pagination={false}
        />

        <Modal
          title={getStaticText('bad.review.detail')}
          visible={modalVisible}
          footer={null}
          onCancel={toggleModal}
        >
          {reviewsData.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            reviewsData.map((review, keys) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={keys}>
                  <Row>
                    <Col span={24}>
                      <Text>
                        {getStaticText('review.title')}
                        <span>：</span>
                        {review.title}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        {getStaticText('review.nickname')}
                        <span>：</span>
                        {review.nickname}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        {getStaticText('review.rating')}
                        <span>：</span>
                        {review.rating}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        {getStaticText('review.detail')}
                        <span>：</span>
                        {review.detail}
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text>
                        {getStaticText('review.ppshop')}
                        <span>：</span>
                        {review.pp_user.toString()}
                      </Text>
                    </Col>
                  </Row>
                  <Divider />
                </div>
              )
            })
          )}
        </Modal>
      </Card>
    </>
  )
}

Index.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default Index
