import React, { useEffect, useState, useContext } from 'react'
import { Form, Row, Col, Input, Switch } from 'antd'
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
import {
  GridFormDrawer,
  CountrySelect,
  GridContext
} from '../../../../components'

import { getUserDetail, createUser, updateUser } from '../services'

import RoleSelect from './RoleSelect'
import PPBusinessUsersSelect from './PPBusinessUsersSelect'

const UserForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const [selectedCountries, setSelectedCountries] = useState([])

  const openHandler = getUserDetail(setFormData)
  const { onLoad, toggle } = useContext(GridContext)
  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }
  const emptyForm = () => {
    setFormData({})
    form.resetFields()
  }
  const onFinish = (values) => {
    let formatData
    if (values['pp_user_id-pp_country']) {
      const [PPUserId, PPCountry] = values['pp_user_id-pp_country'].split('-')
      formatData = { ...values, pp_user_id: PPUserId, pp_country: PPCountry }
    } else {
      formatData = { ...values }
    }
    delete formatData['pp_user_id-pp_country']

    if (formData.id) {
      updateUser(closeDrawer)({ data: formatData, id: formData.id })
    } else {
      createUser(closeDrawer)({ data: formatData })
    }
  }

  const handleCountryChange = (values) => {
    setSelectedCountries(values)
  }

  useEffect(() => {
    if (formData?.allowed_countries && formData?.allowed_countries.length) {
      setSelectedCountries(formData.allowed_countries)
    }
    if (formData.pp_user_id) {
      form.setFieldsValue({
        ...formData,
        'pp_user_id-pp_country': `${formData.pp_user_id}-${formData.pp_country}`
      })
    } else {
      form.setFieldsValue(formData)
    }
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title="Edit Form"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Countries" name="allowed_countries">
              <CountrySelect mode="multiple" onChange={handleCountryChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="username"
              label="Name"
              rules={[{ required: true, message: 'Please enter user name' }]}
            >
              <Input placeholder="Please enter user name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Please enter email' }]}
            >
              <Input
                style={{ width: '100%' }}
                placeholder="Please enter your email"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="firstname"
              label="Firstname"
              rules={[
                { required: true, message: 'Please enter your firstname' }
              ]}
            >
              <Input placeholder="Please enter your firstname" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="lastname"
              label="Lastname"
              rules={[
                { required: true, message: 'Please enter your lastname' }
              ]}
            >
              <Input placeholder="Please enter your lastname" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Active" name="active" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Role" name="role_id">
              <RoleSelect />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="PP Business Users" name="pp_user_id-pp_country">
              <PPBusinessUsersSelect countries={selectedCountries} />
            </Form.Item>
          </Col>
        </Row>
        {!formData.id && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' }
                ]}
              >
                <Input.Password
                  placeholder="input password"
                  iconRender={(v) => {
                    return v ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </GridFormDrawer>
  )
}
export default UserForm
