import React, { useState, useCallback } from 'react'
import { Card, Row, Col, Typography } from 'antd'
import { useLocation } from 'react-router-dom'
import { getStaticText } from '../../../../utils'
import { GridContextProvider, PagingTable } from '../../../../components'
import { createColumns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getActivityOrderList } from '../../services'

const { Text } = Typography

const ItemList = () => {
  const [gridData, setGridData] = useState(initGridData)
  const location = useLocation()

  const onLoad = useCallback(() => {
    getActivityOrderList(setGridData)(new URLSearchParams(location.search))
  }, [location, setGridData])

  return (
    <GridContextProvider onLoad={onLoad}>
      <Card>
        <Row>
          <Col span={24}>
            <Text>{getStaticText('component.table.header.title')}</Text>
          </Col>
        </Row>
      </Card>
      <PagingTable
        columns={createColumns()}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.page}
        pageSize={gridData.page_size}
      />
    </GridContextProvider>
  )
}

export default ItemList
