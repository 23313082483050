import React from 'react'

interface Props {
  record: Object;
}

const ProgressBar = ({ record }: Props) => {
  return (
    <>
      <div className="progress">
        <div className="progress-inner">
          <div
            className="progress-bg"
            style={{ width: `${record.progress}%` }}
          />
          <span className="progress-text">{`${record.actual_amount} / ${record.target_amount}`}</span>
        </div>
        <span className="progress-rate">{`${record.progress_display}%`}</span>
      </div>
    </>
  )
}
export default ProgressBar
