import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { getStaticText } from '../../utils'

const weeklyTablePerformanceColumns = [
  {
    title: (
      <div>
        {getStaticText('weekly.sales.table.week_key')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('weekly.sales.table.week_key')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'week_key',
    key: 'week_key',
    fixed: 'left',
    width: 120,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('weekly.sales.table.category_name')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.processing_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('weekly.sales.table.category_name')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'category_name',
    key: 'category_name',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('weekly.sales.table.orders')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.processing_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('weekly.sales.table.orders')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'pp_orders',
    key: 'pp_orders',
    width: 110,
    align: 'center'
  },
  {
    title: getStaticText('weekly.sales.table.customer'),
    key: 'customer',
    children: [
      {
        title: getStaticText('weekly.sales.table.dial'),
        key: 'dial',
        children: [
          {
            title: (
              <div>
                {getStaticText('weekly.sales.table.pp_orders_dial_phone')}
              </div>
            ),
            dataIndex: 'pp_orders_dial_phone',
            key: 'pp_orders_dial_phone',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>
                {getStaticText('weekly.sales.table.pp_orders_dial_phone_rate')}
              </div>
            ),
            dataIndex: 'pp_orders_dial_phone_rate',
            key: 'pp_orders_dial_phone_rate',
            width: 110,
            align: 'center'
          }
        ]
      },
      {
        title: getStaticText('weekly.sales.table.confirm'),
        key: 'confirm',
        children: [
          {
            title: (
              <div>{getStaticText('weekly.sales.table.pp_orders_phone')}</div>
            ),
            dataIndex: 'pp_orders_phone',
            key: 'pp_orders_phone',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>
                {getStaticText('weekly.sales.table.pp_orders_phone_rate')}
              </div>
            ),
            dataIndex: 'pp_orders_phone_rate',
            key: 'pp_orders_phone_rate',
            width: 110,
            align: 'center'
          }
        ]
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.agent'),
    key: 'agent',
    children: [
      {
        title: (
          <div>{getStaticText('weekly.sales.table.pp_orders_shiped')}</div>
        ),
        dataIndex: 'pp_orders_shiped',
        key: 'pp_orders_shiped',
        width: 110,
        align: 'center'
      },
      {
        title: (
          <div>{getStaticText('weekly.sales.table.pp_orders_shiped_rate')}</div>
        ),
        dataIndex: 'pp_orders_shiped_rate',
        key: 'pp_orders_shiped_rate',
        width: 110,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.express'),
    key: 'express',
    children: [
      {
        title: getStaticText('weekly.sales.table.pp_orders_dealt_first'),
        key: 'pp_orders_dealt_first',
        children: [
          {
            title: (
              <div>
                {getStaticText('weekly.sales.table.pp_orders_dealt_one')}
              </div>
            ),
            dataIndex: 'pp_orders_dealt_one',
            key: 'pp_orders_dealt_one',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>
                {getStaticText('weekly.sales.table.pp_orders_dealt_two')}
              </div>
            ),
            dataIndex: 'pp_orders_dealt_two',
            key: 'pp_orders_dealt_two',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>
                {getStaticText('weekly.sales.table.pp_orders_dealt_three')}
              </div>
            ),
            dataIndex: 'pp_orders_dealt_three',
            key: 'pp_orders_dealt_three',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>{getStaticText('weekly.sales.table.pp_orders_dealt_a')}</div>
            ),
            dataIndex: 'pp_orders_dealt_a',
            key: 'pp_orders_dealt_a',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>
                {getStaticText('weekly.sales.table.pp_orders_ship_delay_days')}
              </div>
            ),
            dataIndex: 'pp_orders_ship_delay_days',
            key: 'pp_orders_ship_delay_days',
            width: 110,
            align: 'center'
          }
        ]
      },
      {
        title: getStaticText('weekly.sales.table.pp_orders_dealt_second'),
        key: 'pp_orders_dealt_second',
        children: [
          {
            title: (
              <div>
                {getStaticText(
                  'weekly.sales.table.pp_orders_dealt_second_orders'
                )}
              </div>
            ),
            dataIndex: 'pp_orders_dealt_second_orders',
            key: 'pp_orders_dealt_second_orders',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>
                {getStaticText(
                  'weekly.sales.table.pp_orders_dealt_second_orders_rate'
                )}
              </div>
            ),
            dataIndex: 'pp_orders_dealt_second_orders_rate',
            key: 'pp_orders_dealt_second_orders_rate',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>
                {getStaticText(
                  'weekly.sales.table.pp_orders_dealt_second_confirm'
                )}
              </div>
            ),
            dataIndex: 'pp_orders_dealt_second_confirm',
            key: 'pp_orders_dealt_second_confirm',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>
                {getStaticText(
                  'weekly.sales.table.pp_orders_dealt_second_confirm_rate'
                )}
              </div>
            ),
            dataIndex: 'pp_orders_dealt_second_confirm_rate',
            key: 'pp_orders_dealt_second_confirm_rate',
            width: 110,
            align: 'center'
          }
        ]
      },
      {
        title: getStaticText('weekly.sales.table.pp_orders_dealt_all'),
        key: 'pp_orders_dealt_all',
        children: [
          {
            title: (
              <div>{getStaticText('weekly.sales.table.pp_orders_dealt')}</div>
            ),
            dataIndex: 'pp_orders_dealt',
            key: 'pp_orders_dealt',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>
                {getStaticText('weekly.sales.table.pp_orders_dealt_rate')}
              </div>
            ),
            dataIndex: 'pp_orders_dealt_rate',
            key: 'pp_orders_dealt_rate',
            width: 110,
            align: 'center'
          }
        ]
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.consumer'),
    key: 'consumer',
    children: [
      {
        title: getStaticText('weekly.sales.table.return'),
        key: 'return',
        children: [
          {
            title: (
              <div>{getStaticText('weekly.sales.table.pp_orders_return')}</div>
            ),
            dataIndex: 'pp_orders_return',
            key: 'pp_orders_return',
            width: 110,
            align: 'center'
          },
          {
            title: (
              <div>
                {getStaticText('weekly.sales.table.pp_orders_return_rate')}
              </div>
            ),
            dataIndex: 'pp_orders_return_rate',
            key: 'pp_orders_return_rate',
            width: 110,
            align: 'center'
          }
        ]
      }
    ]
  }
]

export default weeklyTablePerformanceColumns
