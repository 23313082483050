/* eslint-disable */
import React from 'react'
import { Col, Form, Row, Button, Select } from 'antd'
import BlackButton from '../ButtonComponents/BlackButton'
import '../../../css/order-processing-filter.css'

const optionOrderStatus = [
  {
    label: 'Unpaid',
    value: 'unpaid'
  },
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Waiting for order to be shipped',
    value: 'waiting_for_order_to_be_shipped'
  },
  {
    label: 'Delivery successful',
    value: 'delivery_successful'
  },
  {
    label: 'Shipped',
    value: 'shipped'
  },
  {
    label: 'After sale',
    value: 'after_sale'
  },
  {
    label: 'Closed',
    value: 'closed'
  },
  {
    label: 'Shelved',
    value: 'shelved'
  },
  {
    label: 'Completed',
    value: 'completed'
  }
]

interface Props {
  formRef: any;
}
const FilterOrderHistory = (props : Props) => {
  const resetStatus = () => {
    props.formRef.setFieldValue('status', undefined)
    props.setFormFilter((prev) => ({...prev, status: null}))
  }
  return (
    <div
      style={{
        background: 'white',
        paddingInline: '10px',
        paddingTop: '10px',
        paddingBottom: '30px'
      }}
    >
      <Row gutter={[10, 10]}>
        <Col span={12} className="order-history-filter">
          <Form.Item
            label="Platform"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Row gutter={[10, 10]}>
              <Col span={6}>
                <BlackButton title="All" />
              </Col>
              <Col span={18}>
                <Button style={{ width: '100%' }} disabled>
                  You have not authorized the store yet
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={12} className="order-history-filter">
          <Form.Item
            label="Order Status"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Row gutter={[10, 10]}>
              <Col span={6}>
                <BlackButton functionParent={() => resetStatus()}  title="All" />
              </Col>
              <Col span={18}>
                <Form.Item name="status" style={{ width: '100%', marginBottom: 'unset' }}>
                  <Select>
                    {optionOrderStatus.map((item) => (
                      <Select.Option value={item.value} key={item.value}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default FilterOrderHistory
