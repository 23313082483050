import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Space,
  Typography,
  Button,
  DatePicker,
  Select,
  Table,
  Card,
  Tabs,
  Switch
} from 'antd'
import ReactEcharts from 'echarts-for-react'
import { getStaticText, storage } from '../../utils'
import dateFormat from '../../config/dateFormat'
import '../../css/dashbord.css'
import services from '../../services'
import UpDownIcon from './Tool/UpDownIcon'
import goodsDashboardTopUvColumns from '../../config/tableColumns/goodsDashboardTopUvColumns'
import goodsDashboardTopAddColumns from '../../config/tableColumns/goodsDashboardTopAddColumns'
import goodsDashboardTopGmvColumns from '../../config/tableColumns/goodsDashboardTopGmvColumns'
import goodsDashboardTopSalesColumns from '../../config/tableColumns/goodsDashboardTopSalesColumns'
import goodsDashboardFlowColumns from '../../config/tableColumns/goodsDashboardFlowColumns'
import goodsDashboardAddColumns from '../../config/tableColumns/goodsDashboardAddColumns'
import goodsDashboardConversionColumns from '../../config/tableColumns/goodsDashboardConversionColumns'
import MyTooltip from './Tool/MyTooltip'

const { Text } = Typography
const { RangePicker } = DatePicker

const requestParams = {
  key: 'flow',
  topKey: 'top_uv',
  duration: 'day',
  country: 'All_Country',
  diskCatalogId: ['0'],
  brand: ['0'],
  start_date: moment().subtract('day', 7),
  end_date: moment().subtract('day', 1)
}

const columnsTapList = {
  top_uv: goodsDashboardTopUvColumns,
  top_add: goodsDashboardTopAddColumns,
  top_gmv: goodsDashboardTopGmvColumns,
  top_sales: goodsDashboardTopSalesColumns,
  flow: goodsDashboardFlowColumns,
  add: goodsDashboardAddColumns,
  conversion: goodsDashboardConversionColumns
}

const filterDuration = [
  { value: 'day', label: '按日' },
  { value: 'week', label: '按周' },
  { value: 'month', label: '按月' }
]

const brandTapList = [
  { name: '流量', key: 'flow' },
  { name: '加购', key: 'add' },
  { name: '转化', key: 'conversion' }
]

const GoodsTopTapList = [
  { name: '访客', key: 'top_uv' },
  { name: '加购', key: 'top_add' },
  { name: 'GMV', key: 'top_gmv' },
  { name: '成交量', key: 'top_sales' }
]

const requestOptionLine = {
  legend: {
    data: []
  },
  grid: {
    show: false,
    top: 50,
    left: 100
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      animation: 'false'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      name: ''
    },
    {
      name: ''
    }
  ],
  series: []
}

class DashboardGoods extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    const duration = nextSearch.get('duration')
      ? nextSearch.get('duration')
      : requestParams.duration
    const dateFormatDuration = this.getDateFormatDuration(duration)
    const dateFormatDurationTable = this.getDateFormatDuration(
      requestParams.duration
    )
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatDuration)
      : requestParams.start_date
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatDuration)
      : requestParams.end_date
    const country = nextSearch.get('country')
      ? nextSearch.get('country')
      : requestParams.country
    const brand = nextSearch.get('brand')
      ? nextSearch.get('brand').split(',')
      : requestParams.brand
    const diskCatalogId = nextSearch.get('diskCatalogId')
      ? nextSearch.get('diskCatalogId').split(',')
      : requestParams.diskCatalogId
    const current = nextSearch.get('current') ? nextSearch.get('current') : 1
    const pageSize = nextSearch.get('pageSize')
      ? nextSearch.get('pageSize')
      : 10

    this.state = {
      isLoading: false,
      isLoadingGoods: false,
      exportLoading: false,
      nameList: [],
      diskCatalogId,
      countries: this.getCountrys(),
      country,
      duration,
      brand,
      checkList: [],
      brandList: [],
      disk_catalog: this.checkDiskCatalogList(diskCatalogId),
      start_date: startDate,
      start_format: startDate.format(dateFormatDuration),
      end_date: endDate,
      end_format: endDate.format(dateFormatDuration),
      dateFormatDuration,
      topData: {},
      catalogs: [],
      switchKey: false,
      key: requestParams.key,
      topKey: requestParams.topKey,
      tableData: {},
      tableMapData: {},
      dateFormatDurationTable,
      countryTable: requestParams.country,
      durationTable: requestParams.duration,
      start_date_table: requestParams.start_date,
      start_format_table: requestParams.start_date.format(
        dateFormatDurationTable
      ),
      end_date_table: requestParams.end_date,
      end_format_table: requestParams.end_date.format(dateFormatDurationTable),
      total: 0,
      current,
      pageSize,
      sorter: {}
    }
    this.getBrandReportsCatalog()
  }

  componentDidMount() {
    this.useEffect()
    this.useEffectTable()
  }

  getCountrys = () => {
    const countrys = storage.allowedCountries.val
    countrys.forEach((country) => {
      if (country.value === 'All_Country') {
        country.label = '所有站点'
      }
    })
    return countrys
  }

  getBrandReportsCatalog = () => {
    services.brand.getBrandReportsCatalog().then((result) => {
      this.setState(
        (prevState) => ({
          catalogs: result.catalog_list,
          brandList: this.checkBrands(prevState.brand, result.catalog_list)
        }),
        () => {
          this.useEffect()
        }
      )
    })
  }

  checkDiskCatalogList = (diskCatalogId) => {
    const diskCatalogList = storage.disk_catalog_list.val
    if (diskCatalogId.indexOf('0') >= 0) {
      diskCatalogList.forEach((diskCatalog) => {
        diskCatalog.disabled = true
      })
      diskCatalogList[0].disabled = false
    } else if (diskCatalogId.length === 0) {
      diskCatalogList.forEach((diskCatalog) => {
        diskCatalog.disabled = false
      })
    } else {
      diskCatalogList[0].disabled = true
    }
    return diskCatalogList
  }

  checkBrands = (brand, catalogs = []) => {
    if ((this.state && this.state.catalogs?.[0]) || catalogs[0]) {
      catalogs = catalogs[0] ? catalogs : this.state.catalogs
      const brandList = catalogs[0].map((catalog) => {
        return {
          label: catalog.title,
          value: catalog.title,
          disabled: false
        }
      })
      brandList.unshift({
        label: '全部',
        value: '0',
        disabled: false
      })
      if (brand.indexOf('0') >= 0) {
        brandList.forEach((brand) => {
          brand.disabled = true
        })
        brandList[0].disabled = false
      } else if (brand.length === 0) {
        brandList.forEach((brand) => {
          brand.disabled = false
        })
      } else {
        brandList[0].disabled = true
      }
      return brandList
    }
    return []
  }

  getDateFormatDuration = (duration = requestParams.duration) => {
    return dateFormat[duration]
  }

  getrequest = () => ({
    country: this.state.country,
    duration: this.state.duration,
    start_date: this.state.start_format,
    end_date: this.state.end_format
  })

  getrequestTable = (keyWords = '') => ({
    country: this.state.countryTable,
    duration: this.state.durationTable,
    brand: this.state.brand.toString(),
    diskCatalogId: this.state.diskCatalogId.toString(),
    start_date: this.state.start_format_table,
    end_date: this.state.end_format_table,
    checkList: this.state.checkList,
    keyWords,
    current: this.state.current,
    pageSize: this.state.pageSize,
    tableKey: this.state.key,
    sorter: this.state.sorter
  })

  handleDateChange = (date, dateString) => {
    this.setState({
      start_date: date[0],
      start_format: dateString[0],
      end_date: date[1],
      end_format: dateString[1]
    })
  }

  handleDateChangeTable = (date, dateString) => {
    this.setState({
      start_date_table: date[0],
      start_format_table: dateString[0],
      end_date_table: date[1],
      end_format_table: dateString[1]
    })
  }

  handleCountryChange = (country) => {
    this.setState({
      country
    })
  }

  handleCountryTableChange = (countryTable) => {
    this.setState({
      countryTable
    })
  }

  handleDurationChange = (duration) => {
    const dateFormatDuration = this.getDateFormatDuration(duration)
    this.setState({
      duration,
      dateFormatDuration,
      start_date: requestParams.start_date,
      start_format: requestParams.start_date.format(dateFormatDuration),
      end_date: requestParams.end_date,
      end_format: requestParams.end_date.format(dateFormatDuration)
    })
  }

  handleDurationChangeTable = (durationTable) => {
    const dateFormatDurationTable = this.getDateFormatDuration(durationTable)
    this.setState({
      durationTable,
      dateFormatDurationTable,
      start_date_table: requestParams.start_date,
      start_format_table: requestParams.start_date.format(
        dateFormatDurationTable
      ),
      end_date_table: requestParams.end_date,
      end_format_table: requestParams.end_date.format(dateFormatDurationTable)
    })
  }

  handleCurrentChange = (current, pageSize) => {
    this.setState(
      {
        current,
        pageSize
      },
      () => {
        this.fetchTable()
      }
    )
  }

  handleSwitchChange = (switchKey) => {
    const switchKeyWords = switchKey
      ? 'category_sku_map_data'
      : 'category_spu_map_data'
    this.setState({
      switchKey
    })
    this.category_map.getEchartsInstance().clear() // 清空画布
    this.category_map.getEchartsInstance().setOption({
      ...requestOptionLine,
      xAxis: [
        {
          data: this.state.topData?.[switchKeyWords].catalog
        }
      ],
      grid: {
        top: 130
      },
      legend: {
        data: this.state.topData?.[switchKeyWords].legend
      },
      series: this.state.topData?.[switchKeyWords].series
    })
    this.category_map.getEchartsInstance().resize()
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchData()
  }

  useEffectTable = () => {
    this.fetchTable()
    this.fetchTableMap()
  }

  fetchData = () => {
    services.dashboard.getGoodsTop(this.getrequest()).then((result) => {
      this.sku_map.getEchartsInstance().clear() // 清空画布
      this.sku_map.getEchartsInstance().setOption({
        ...requestOptionLine,
        xAxis: [
          {
            data: result.sku_map_data.catalog
          }
        ],
        series: result.sku_map_data.series
      })
      this.sku_map.getEchartsInstance().resize()
      this.setState(
        {
          topData: {
            isLoading: true,
            ...result
          }
        },
        () => {
          this.handleSwitchChange(this.state.switchKey)
        }
      )
    })
  }

  fetchTableMap = () => {
    services.dashboard.fetchTableMap(this.getrequestTable()).then((result) => {
      const tableMapData = {}
      tableMapData.flow = {
        ...requestOptionLine,
        xAxis: [
          {
            data: result.flow.map_data.catalog
          }
        ],
        series: result.flow.map_data.series
      }
      tableMapData.add = {
        ...requestOptionLine,
        xAxis: [
          {
            data: result.add.map_data.catalog
          }
        ],
        series: result.add.map_data.series
      }
      tableMapData.conversion = {
        ...requestOptionLine,
        xAxis: [
          {
            data: result.conversion.map_data.catalog
          }
        ],
        series: result.conversion.map_data.series
      }
      this.setState(
        {
          tableMapData
        },
        () => {
          this.brand_tap_map.getEchartsInstance().resize()
        }
      )
    })
  }

  fetchTable = () => {
    this.setState({ isLoadingGoods: true })
    services.dashboard.getGoodsTable(this.getrequestTable()).then((result) => {
      const { key } = this.state

      this.setState({
        total: result?.[key].total,
        current: result?.[key].current,
        pageSize: result?.[key].pageSize,
        isLoadingGoods: false,
        tableData: { ...result }
      })
    })
  }

  onSearch = (keyWords) => {
    if (keyWords) {
      services.dashboard
        .getBusinessGoodsNameList(this.getrequestTable(keyWords))
        .then((result) => {
          this.setState({
            nameList: result
          })
        })
    } else {
      this.setState({
        nameList: []
      })
    }
  }

  changeHandlerName = (checkList) => {
    this.setState({
      checkList
    })
  }

  handlediskCatalogChange = (diskCatalogId) => {
    this.setState({
      diskCatalogId,
      disk_catalog: this.checkDiskCatalogList(diskCatalogId)
    })
  }

  handleBrandChange = (brand) => {
    this.setState({
      brand,
      brandList: this.checkBrands(brand)
    })
  }

  switchTab = (key) => {
    const { tableData } = this.state
    this.setState({
      key,
      total: tableData?.[key]?.total,
      current: tableData?.[key]?.current,
      pageSize: tableData?.[key]?.pageSize
    })
  }

  topSwitchTab = (topKey) => {
    this.setState({ topKey })
  }

  handleGoodsExport = () => {
    this.setState({ exportLoading: true })
    services.dashboard
      .getGoodsExport(this.getrequestTable())
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
      .finally(() => {
        this.setState({ exportLoading: false })
      })
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState(
      {
        sorter: {
          field: sorter.field,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      },
      () => {
        this.fetchTable()
      }
    )
  }

  render() {
    const options = this.state.nameList.map((item) => (
      <Select.Option key={item}>{item}</Select.Option>
    ))
    return (
      <>
        <Card>
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 220 }}
              value={this.state.country}
              onChange={this.handleCountryChange}
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <Select
              style={{ width: 80 }}
              value={this.state.duration}
              onChange={this.handleDurationChange}
              maxTagCount="responsive"
            >
              {filterDuration.map((item) => (
                <Select.Option key={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
            <RangePicker
              picker={this.state.duration}
              value={[this.state.start_date, this.state.end_date]}
              format={this.state.dateFormatDuration}
              onChange={this.handleDateChange}
              allowClear={false}
            />
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
          <Space
            style={{
              marginTop: 50,
              display: 'block',
              width: '100%'
            }}
          >
            <div className="business-top">
              <div className="business-box floatf">
                <div className="business-title">
                  SKU
                  <MyTooltip account="xpark数据" />
                </div>
                <div className="business-gmv">
                  <span className="business-today-gmv">
                    {this.state.topData.all_sku}
                  </span>
                  <UpDownIcon
                    current={this.state.topData.all_sku}
                    last={this.state.topData.last_all_sku}
                  />
                </div>
                <ReactEcharts
                  style={{ width: '100%', height: 420 }}
                  ref={(e) => {
                    this.sku_map = e
                  }}
                  option={requestOptionLine}
                />
              </div>
              <div className="business-box floatr">
                <div className="business-title">
                  类目占比
                  <MyTooltip account="xpark数据" />
                  <Switch
                    className="business-title-switch"
                    checkedChildren="sku"
                    unCheckedChildren="spu"
                    onChange={this.handleSwitchChange}
                  />
                </div>
                <ReactEcharts
                  style={{ width: '100%', height: '100%' }}
                  ref={(e) => {
                    this.category_map = e
                  }}
                  option={requestOptionLine}
                />
              </div>
            </div>
            <div className="goods-top-table-box">
              <div className="business-title">
                榜单
                <MyTooltip account="GA数据" />
              </div>
              <Tabs
                activeKey={this.state.topKey}
                type="card"
                onChange={this.topSwitchTab}
              >
                {GoodsTopTapList.map((item) => {
                  return (
                    <Tabs.TabPane tab={item.name} key={item.key}>
                      <Table
                        style={{ width: '100%' }}
                        size="small"
                        bordered
                        loading={this.state.isLoading}
                        columns={columnsTapList[item.key]}
                        dataSource={this.state?.topData[item.key]}
                        rowKey={(record) => record.product_id}
                        scroll={{ x: 'max-content', y: true }}
                        sticky
                        pagination={false}
                      />
                    </Tabs.TabPane>
                  )
                })}
              </Tabs>
            </div>
          </Space>
        </Card>
        <Card>
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 120 }}
              value={this.state.countryTable}
              onChange={this.handleCountryTableChange}
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.brand.text')}
            </Text>
            <Select
              style={{ width: 120 }}
              value={this.state.brand}
              onChange={this.handleBrandChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.brandList.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.category_type.text')}
            </Text>
            <Select
              style={{ width: 120 }}
              value={this.state.diskCatalogId}
              onChange={this.handlediskCatalogChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.disk_catalog.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <Select
              style={{ width: 80 }}
              value={this.state.durationTable}
              onChange={this.handleDurationChangeTable}
              maxTagCount="responsive"
            >
              {filterDuration.map((item) => (
                <Select.Option key={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
            <RangePicker
              picker={this.state.durationTable}
              value={[this.state.start_date_table, this.state.end_date_table]}
              format={this.state.dateFormatDurationTable}
              onChange={this.handleDateChangeTable}
              allowClear={false}
            />
          </Space>
          <Space
            style={{
              marginTop: 20,
              width: '100%'
            }}
          >
            <Text>{getStaticText('goods.search.goods_name')}</Text>
            <Select
              style={{ width: 500 }}
              showSearch
              value={this.state.checkList}
              placeholder={getStaticText('goods.search.goods_name')}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={this.onSearch}
              onChange={this.changeHandlerName}
              notFoundContent={null}
              mode="multiple"
            >
              {options}
            </Select>
            <Button type="primary" onClick={this.useEffectTable}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
          <Space
            style={{
              marginTop: 50,
              display: 'block',
              width: '100%'
            }}
          >
            <div className="goods-table-box">
              <div className="business-title">
                商品数据报表
                <MyTooltip account="GA数据" />
              </div>
              <Tabs
                activeKey={this.state.key}
                type="card"
                onChange={this.switchTab}
              >
                {brandTapList.map((item) => {
                  return (
                    <Tabs.TabPane tab={item.name} key={item.key}>
                      <div className="goods-map-box">
                        <ReactEcharts
                          style={{ width: '100%' }}
                          ref={(e) => {
                            this.brand_tap_map = e
                          }}
                          option={
                            this.state.tableMapData?.[item.key] ||
                            requestOptionLine
                          }
                          key={`${item.key}_map`}
                        />
                      </div>
                      <div className="goods-table-box">
                        <div className="business-table-title">
                          详情
                          <MyTooltip account="GA数据" />
                          <span className="business-title-span">
                            <span>交易总金额</span>
                            <span> $ </span>
                            {this.state.tableData?.[item.key]?.gmv}
                          </span>
                          <Button
                            className="business-title-botton"
                            onClick={this.handleGoodsExport}
                            loading={this.state.exportLoading}
                          >
                            报表导出
                          </Button>
                        </div>
                        <Table
                          size="small"
                          bordered
                          onChange={this.handleChange}
                          loading={this.state.isLoadingGoods}
                          columns={columnsTapList[item.key]}
                          dataSource={this.state.tableData?.[item.key]?.table}
                          rowKey={(record) => record.product_id}
                          scroll={{ x: 'max-content', y: true }}
                          sticky
                          key={`${item.key}_table`}
                          pagination={{
                            simple: true,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            total: this.state.total,
                            showTotal: () => `共${this.state.total}条数据`,
                            pageSize: this.state.pageSize,
                            current: this.state.current,
                            onChange: this.handleCurrentChange
                          }}
                        />
                      </div>
                    </Tabs.TabPane>
                  )
                })}
              </Tabs>
            </div>
          </Space>
        </Card>
      </>
    )
  }
}

DashboardGoods.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default DashboardGoods
