/* eslint-disable react/jsx-indent */
import React, { useEffect } from 'react'
import {
  shape,
  bool,
  string,
  func,
  arrayOf,
  number,
  oneOfType
} from 'prop-types'
import {
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Typography,
  Button,
  Space,
  InputNumber
} from 'antd'
import { cloneDeep } from 'lodash'
import { getStaticText } from '../../utils'

const FormItem = Form.Item
const { Title } = Typography

// 字段能调用的组件map
const fieldMap = {
  string: { component: Input, props: {} },
  number: {
    component: InputNumber,
    props: {
      style: { width: '100%' },
      formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      parser: (value) => value.replace(/(,*)/g, '')
    }
  }
}

const EditDrawer = (props) => {
  const {
    drawerProps,
    columns,
    dataSource,
    onClose,
    colNum,
    handleFinish,
    loading,
    title
  } = props

  const colSpan = 24 / colNum

  const [form] = Form.useForm()

  useEffect(() => {
    const cloneDataSource = cloneDeep(dataSource)

    // 取出数据做格式化处理
    const formValue = Object.keys(cloneDataSource).reduce((prev, next) => {
      prev[next] = cloneDataSource[next].value
      if (typeof cloneDataSource[next].value === 'string') {
        prev[next] = cloneDataSource[next].value.replace(/(,*)/g, '')
      }

      return prev
    }, {})

    // 设置form字段数据
    form.setFieldsValue(formValue)
  }, [form, dataSource])

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        handleFinish({ id: dataSource.id.value, data: values })
      })
      .catch((error) => {
        form.scrollToField(error.errorFields[0].name.toString())
      })
  }

  const handleClose = () => {
    if (!loading) onClose()
  }

  const footer = (
    <div style={{ textAlign: 'right' }}>
      <Space>
        <Button onClick={handleClose}>
          {getStaticText('component.edit.modal.cancel.text')}
        </Button>
        <Button type="primary" onClick={onFinish} loading={loading}>
          {getStaticText('component.edit.modal.ok.text')}
        </Button>
      </Space>
    </div>
  )

  /**
   * 根据字段类型获取组件
   *
   * @param {String} type
   * @param {Object} prop
   * @returns
   */
  const getComponentForType = (value, prop = {}) => {
    const type = typeof value
    const componentType = fieldMap[type] || { component: Input, props: {} }
    const Component = componentType.component
    return <Component {...componentType.props} {...prop} />
  }

  // render每个form字段
  const renderField = (dataSource, column, parentcolumn = {}) => {
    if (parentcolumn.title)
      column.title.props.children[0] = `${parentcolumn.title.props.children[0]}（${column.title.props.children[0]}）`
    return (
      <FormItem
        label={column.title}
        name={column.key}
        rules={[
          {
            required: false
          }
        ]}
      >
        {dataSource[column.key] ? (
          getComponentForType(dataSource[column.key].value, {
            disabled: !dataSource[column.key].writable
          })
        ) : (
          <Input />
        )}
      </FormItem>
    )
  }

  return (
    <div className="edit-drawer">
      <Drawer
        title={title}
        key={drawerProps.placement}
        {...drawerProps}
        onClose={handleClose}
        footer={footer}
        getContainer={false}
        destroyOnClose
      >
        <Form layout="vertical" form={form} name="edit">
          {columns.map((column) => {
            const children = column.children || []
            let isGrandchild = false
            children.forEach((child) => {
              if (child.children) isGrandchild = true
            })

            // 有多个二级子集
            if (isGrandchild) {
              return (
                <div key={column.key} className="column-group">
                  <Title level={5}>{column.title}</Title>
                  {children.map((childrenColumn) => {
                    if (childrenColumn.children)
                      return (
                        <Row gutter={20} key={childrenColumn.key}>
                          {childrenColumn.children.map((item) => {
                            return dataSource[item.key] &&
                              dataSource[item.key].visible ? (
                              <Col key={item.key} span={colSpan}>
                                {renderField(dataSource, item, childrenColumn)}
                              </Col>
                            ) : null
                          })}
                        </Row>
                      )
                    return (
                      <Row key={childrenColumn.key} gutter={20}>
                        {dataSource[childrenColumn.key] &&
                        dataSource[childrenColumn.key].visible ? (
                          <Col key={childrenColumn.key} span={colSpan}>
                            {renderField(dataSource, childrenColumn)}
                          </Col>
                        ) : null}
                      </Row>
                    )
                  })}
                </div>
              )
            }

            // 有多个子集
            if (children.length) {
              return (
                <div key={column.key} className="column-group">
                  <Title level={5}>{column.title}</Title>
                  <Row gutter={20}>
                    {children.map((childrenColumn) => {
                      return dataSource[childrenColumn.key] &&
                        dataSource[childrenColumn.key].visible ? (
                        <Col key={childrenColumn.key} span={colSpan}>
                          {renderField(dataSource, childrenColumn)}
                        </Col>
                      ) : null
                    })}
                  </Row>
                </div>
              )
            }

            // 无子集
            return dataSource[column.key] && dataSource[column.key].visible ? (
              <div key={column.key} className="column-group">
                <Row gutter={20}>
                  <Col span={colSpan}>{renderField(dataSource, column)}</Col>
                </Row>
              </div>
            ) : null
          })}
        </Form>
      </Drawer>
    </div>
  )
}

EditDrawer.propTypes = {
  drawerProps: shape({
    width: oneOfType([string, number]),
    visible: bool,
    placement: string
  }).isRequired,
  columns: arrayOf(shape({})).isRequired,
  dataSource: shape().isRequired,
  onClose: func,
  colNum: number,
  handleFinish: func,
  loading: bool,
  title: string
}

EditDrawer.defaultProps = {
  onClose: () => {},
  colNum: 2,
  handleFinish: () => {},
  loading: false,
  title: getStaticText('table.operating.button.text')
}

export default EditDrawer
