import request from './request'

export const getWeeklySales = (data) =>
  request.post('/backend/weekly-sales/dashboard', data)

export const getWeeklyPerformance = (data) =>
  request.post('/backend/weekly-sales/performance', data)

export const getExportPerformance = (data) =>
  request.post('/backend/weekly-sales/export-performance', data)

export const getweeklyHomePage = (data) =>
  request.post('/backend/weekly-sales/home-page', data)

export const getExportHomePage = (data) =>
  request.post('/backend/weekly-sales/export-home-page', data)
