import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Card, DatePicker, Space } from 'antd'
import Text from 'antd/es/typography/Text'
import {
  CountryFilter,
  SubmitButton,
  ResetButton
} from '../../../../../components'
// import { getStaticText } from '../../../../utils'
const { RangePicker } = DatePicker
const Filters = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const history = useHistory()
  const [params, setParams] = useState({
    ___country: searchParams.get('___country'),
    start_date: '',
    end_date: ''
  })
  const paramChanged = (name) => (value) => {
    setParams({ ...params, [name]: value })
  }
  const handleSubmit = (params) => () => {
    const strParams = new URLSearchParams(params).toString()
    history.push(`${location.pathname}?${strParams}`)
  }
  const [datePair, setDatePair] = useState([null, null])
  const handleReset = (initParams, setParams) => () => {
    setParams(initParams)
  }
  const handleChangeDate = (date, dateString) => {
    setDatePair(date)
    setParams({ ...params, start_date: dateString[0], end_date: dateString[1] })
  }

  return (
    <Card
      style={{
        marginBottom: 20
      }}
    >
      <Space>
        <CountryFilter
          mode="single"
          // eslint-disable-next-line dot-notation
          value={params['___country']}
          onChange={paramChanged('___country')}
        />
        <Space>
          <Text>Created Time</Text>
          <RangePicker
            picker="day"
            value={datePair}
            format="YYYY-MM-DD"
            onChange={handleChangeDate}
            allowClear={false}
          />
        </Space>
      </Space>
      <ResetButton onReset={handleReset({}, setParams)} />
      <SubmitButton onSubmit={handleSubmit(params)} />
    </Card>
  )
}

export default Filters
