import React, { useContext } from 'react'
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import { PermissionButton, GridContext } from '../../../../../components'
import { deleteActivity } from '../services'
import { setCurrentCountry } from '../../../../../store/actions/global'
import store from '../../../../../store'

interface Props {
  record: Object;
}
const Actions = ({ record }: Props) => {
  const { toggle, onLoad } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit')
  }
  const onViewItems = () => {
    store.dispatch(setCurrentCountry(record.country))
    toggle(record, 'items')
  }
  const onViewFortunate = () => {
    toggle(record, 'fortunate')
  }
  const onDelete = () => {
    deleteActivity(onLoad)(record.id)
  }
  return (
    <>
      <Tooltip title="detail">
        <PermissionButton
          size="small"
          onClick={onViewItems}
          icon={<EyeOutlined />}
          permission="luckyspin/activity/save"
          type="primary"
        />
      </Tooltip>
      <Tooltip title="fortunate">
        <PermissionButton
          style={{ marginLeft: '5px' }}
          size="small"
          onClick={onViewFortunate}
          icon={<UsergroupAddOutlined />}
          permission="luckyspin/activity/save"
          type="primary"
        />
      </Tooltip>
      <Tooltip title="edit">
        <PermissionButton
          style={{ marginLeft: '5px' }}
          size="small"
          onClick={onEdit}
          icon={<EditOutlined />}
          permission="luckyspin/activity/save"
          type="primary"
        />
      </Tooltip>
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <Tooltip title="delete">
          <PermissionButton
            style={{ marginLeft: '5px' }}
            icon={<DeleteOutlined />}
            size="small"
            permission="luckyspin/activity/delete"
            type="primary"
            danger
          />
        </Tooltip>
      </Popconfirm>
    </>
  )
}
export default Actions
