import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Select, Space, Card, Typography, Button, DatePicker } from 'antd'
import { getStaticText, storage } from '../../../utils'
import services from '../../../services'
import dateFormat from '../../../config/dateFormat'

const dateFormatmonth = dateFormat.month
const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const requestParams = {
  country: ['All_Country'],
  start_date: moment().subtract('months', 1),
  end_date: moment()
}

const requestOption = {
  title: {
    text: '',
    x: 'right',
    y: 'bottom',
    textStyle: {
      fontSize: 12
    }
  },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)'
  },
  series: [
    {
      name: '',
      type: 'pie',
      radius: '50%',
      avoidLabelOverlap: true,
      data: []
    }
  ]
}

const getFormatter = (value) => {
  return `${value.name}:(${value.percent}%)
${
  value.data.value.toString().length > 4
    ? `${parseInt(value.data.value / 10000, 10) + 1}万`
    : value.data.value
}`
}

class BrandPie extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatmonth)
      : requestParams.end_date
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatmonth)
      : requestParams.start_date
    const country = nextSearch.get('country')
      ? nextSearch.get('country').split(',')
      : requestParams.country
    this.state = {
      countries: this.checkCountries(country),
      country,
      start_date: startDate,
      start_format: startDate.format(dateFormatmonth),
      end_date: endDate,
      end_format: endDate.format(dateFormatmonth),
      series: []
    }
    this.useEffect()
  }

  getrequest = () => {
    return {
      country: this.state.country.toString(),
      start_date: this.state.start_format,
      end_date: this.state.end_format
    }
  }

  handleCountryChange = (country) => {
    this.setState({
      country,
      countries: this.checkCountries(country)
    })
  }

  handleDateChange = (date, dateString) => {
    this.setState({
      start_date: moment(dateString[0], dateFormatmonth),
      start_format: dateString[0],
      end_date: moment(dateString[1], dateFormatmonth),
      end_format: dateString[1]
    })
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  checkCountries = (country) => {
    const countries = storage.allowedCountries.val
    if (country.indexOf('All_Country') >= 0) {
      countries.forEach((country) => {
        country.disabled = true
      })
      countries[0].disabled = false
    } else if (country.length === 0) {
      countries.forEach((country) => {
        country.disabled = false
      })
    } else {
      countries[0].disabled = true
    }
    return countries
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchData()
  }

  fetchData = () => {
    services.brand.getBrandPie(this.getrequest()).then((result) => {
      const series = []

      Object.keys(result.series).forEach((item, index) => {
        series.push({
          ...requestOption,
          title: {
            text: result.series[item].name,
            left: 'center'
          },
          series: {
            ...result.series[item],
            label: {
              formatter: getFormatter
            }
          },
          index
        })
      })
      this.setState({
        series
      })
    })
  }

  getSeries = () => {
    return this.state.series.map((option) => {
      return (
        <ReactEcharts
          style={{ height: '500px', width: '40%', float: 'left' }}
          option={option}
          key={option.index}
        />
      )
    })
  }

  render() {
    return (
      <>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 220 }}
              value={this.state.country}
              onChange={this.handleCountryChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <RangePicker
              picker="month"
              value={[this.state.start_date, this.state.end_date]}
              format={dateFormatmonth}
              onChange={this.handleDateChange}
              allowClear={false}
            />
          </Space>
          <Space style={{ cssFloat: 'right' }}>
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        {this.getSeries()}
      </>
    )
  }
}

BrandPie.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default BrandPie
