import React, { useEffect, useState, useCallback, useMemo } from 'react'
import {
  Select,
  Card,
  Table,
  Button,
  Typography,
  Space,
  DatePicker
} from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

import { dailyDataFillTableColumns } from '../../config/tableColumns'
import services from '../../services'
import { getStaticText, storage } from '../../utils'
import dateFormat from '../../config/dateFormat'

const dateFormatDay = dateFormat.day
const { Text } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const DailyDataFill = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  const platforms = useMemo(() => {
    return storage.allowedPlatforms.val || {}
  }, [])

  // 默认请求参数
  const defaultReqData = {
    country: 'All_Country',
    platform: 'All_Platform',
    start_date: moment().subtract(1, 'days'),
    end_date: moment(),
    current: 1,
    pageSize: 20
  }

  const [isLoad, setIsLoad] = useState(false)
  const [tableData, setTableData] = useState([])
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const nextSearchObj = () => {
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatDay)
      : null
    const endDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('end_date'), dateFormatDay)
      : null
    return {
      country: nextSearch.get('country'),
      platform: nextSearch.get('platform'),
      start_date: startDate,
      end_date: endDate,
      current: nextSearch.get('current'),
      pageSize: nextSearch.get('pageSize')
    }
  }
  Object.keys(nextSearchObj()).forEach((item) => {
    if (nextSearchObj()[item]) defaultReqData[item] = nextSearchObj()[item]
  })

  const [requestParams, setRequestParams] = useState(() => {
    return {
      country: defaultReqData.country
        .split(',')
        .filter((item) => !(item === '')),
      platform: defaultReqData.platform
        .split(',')
        .filter((item) => !(item === '')),
      start_date: defaultReqData.start_date,
      start_format: defaultReqData.start_date.format(dateFormatDay),
      end_date: defaultReqData.end_date,
      end_format: defaultReqData.end_date.format(dateFormatDay),
      current: defaultReqData.current,
      pageSize: defaultReqData.pageSize
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      country: requestParams.country.toString(),
      platform: requestParams.platform.toString(),
      start_date: requestParams.start_format,
      end_date: requestParams.end_format,
      current: requestParams.current * 1,
      pageSize: requestParams.pageSize * 1
    }
  }, [requestParams])

  // 判断所有平台
  const isAllPlatform = useCallback(
    (platform) => {
      if (requestParams.platform.includes('All_Platform')) {
        platform.forEach((platform) => {
          platform.disabled = true
        })

        platform[0].disabled = false
      } else if (requestParams.platform.length === 0) {
        platform.forEach((platform) => {
          platform.disabled = false
        })
      } else platform[0].disabled = true
    },
    [requestParams]
  )

  // 过滤出可供选择的平台
  const filterPlatform = useMemo(() => {
    if (
      !requestParams.country.length ||
      requestParams.country.toString() === 'All_Country'
    ) {
      const obj = {}
      const platform = Object.values(platforms)
        .flat()
        .reduce((pre, item) => {
          if (!obj[item.value]) {
            obj[item.value] = true
            pre.push(item)
          }
          return pre
        }, [])
      isAllPlatform(platform)
      return platform
    }
    let filterArray = []
    requestParams.country.forEach((item, index) => {
      filterArray[index] = platforms[item] || []
    })
    filterArray = filterArray.reduce((a, b) =>
      a.filter((c) => {
        let isEqual = false
        b.forEach((d) => {
          if (d.value === c.value) isEqual = true
        })
        return isEqual
      })
    )
    isAllPlatform(filterArray)
    return filterArray
  }, [requestParams, platforms, isAllPlatform])

  // countries 判断 disabled 属性
  const countries = useMemo(() => {
    const country = storage.allowedCountries.val || []
    if (requestParams.country.includes('All_Country')) {
      country.forEach((country) => {
        country.disabled = true
      })
      country[0].disabled = false
    } else if (requestParams.country.length === 0) {
      country.forEach((country) => {
        country.disabled = false
      })
    } else {
      country[0].disabled = true
    }
    return country
  }, [requestParams])

  const fetchData = useCallback(() => {
    toggleLoading()
    services.dailyDataFill
      .getDailyDataFill(requestParamsTransformer)
      .then((result) => {
        const data = result.list || []
        setTableData(data)
        setTotal(result.total)
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  useEffect(() => {
    if (!isLoad) {
      fetchData()
    }
    setIsLoad(true)
  }, [fetchData, isLoad])

  const filter = () => {
    setTotal(0)
    setUrlSearchForState()
    fetchData()
  }
  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country,
      platform: ['All_Platform'],
      current: 1
    }))
  }

  const handlePlatformChange = (platform) => {
    setRequestParams((prevState) => ({
      ...prevState,
      platform,
      current: 1
    }))
  }

  const handleDateChange = (date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      start_date: date[0],
      start_format: dateString[0],
      end_date: date[1],
      end_format: dateString[1],
      current: 1
    }))
  }

  const handleReset = () => {
    setRequestParams({
      country: ['All_Country'],
      platform: ['All_Platform'],
      start_date: moment().subtract(1, 'days'),
      start_format: moment().subtract(1, 'days').format(dateFormatDay),
      end_date: moment(),
      end_format: moment().format(dateFormatDay),
      current: 1,
      pageSize: 20
    })
  }

  const handleCurrentChange = (current, pageSize) => {
    setRequestParams((prevState) => ({
      ...prevState,
      current,
      pageSize
    }))
    filter()
  }

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.platform.text')}
          </Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.platform}
            onChange={handlePlatformChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {filterPlatform.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.time.text')}
          </Text>
          <RangePicker
            picker="day"
            value={[requestParams.start_date, requestParams.end_date]}
            format={dateFormatDay}
            onChange={handleDateChange}
            allowClear={false}
          />
        </Space>
        <Space style={{ float: 'right' }}>
          <Button type="primary" onClick={handleReset}>
            {getStaticText('component.search.button.reset')}
          </Button>
        </Space>
        <Space style={{ cssFloat: 'right' }}>
          <Button type="primary" onClick={filter}>
            {getStaticText('component.search.button.filter')}
          </Button>
        </Space>
      </Card>
      <Table
        size="small"
        bordered
        loading={isLoading}
        columns={dailyDataFillTableColumns}
        dataSource={tableData}
        rowKey={(record) => record.id}
        scroll={{ x: 'max-content', y: true }}
        sticky
        pagination={{
          size: 'small',
          showSizeChanger: true,
          showQuickJumper: true,
          total,
          showTotal: () => `共${total}条数据`,
          pageSize: requestParamsTransformer.pageSize,
          current: requestParamsTransformer.current,
          onChange: handleCurrentChange
        }}
      />
    </>
  )
}

DailyDataFill.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default DailyDataFill
