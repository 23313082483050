import request from './request'

export const getSettingSchema = (params) =>
  request.get('/backend/sys-config/schema', { params })

export const saveSettingSchema = (data) =>
  request.post('/backend/sys-config/save', data)

export const getPromotionSchema = (params) =>
  request.get('/backend/promotion-config/get-list', { params })

export const savePromotionSchema = (data) =>
  request.post('/backend/promotion-config/save', data)
