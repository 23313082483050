import React from 'react'
import Actions from './Actions'

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Role name',
    dataIndex: 'role_name',
    key: 'role_name'
  },
  {
    title: 'Role scope',
    dataIndex: 'scopes',
    key: 'scopes'
  },
  {
    title: 'Alias',
    dataIndex: 'role_alias',
    key: 'role_alias'
  },
  {
    title: 'remark',
    dataIndex: 'remark',
    key: 'remark'
  },
  {
    title: 'action',
    dataIndex: '',
    width: 200,
    key: 'x',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
