import ShowCommand from '../pages/ModelCommand/InputParams2'

const modelCommand = {
  path: '/model-command',
  primary: 'app.sider.model-command',
  hideGlobalTips: true,
  routes: [
    {
      path: '/model-command/show-command',
      modular: 'config',
      permission: 'model-command/generate-command',
      component: ShowCommand,
      primary: 'app.sider.model-command.show-command',
      exact: true
    }
  ]
}
export default modelCommand
