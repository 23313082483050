import React, { useContext, useState, useCallback } from 'react'
import { Checkbox, Card, Space } from 'antd'
import {
  GridFormDrawer,
  GridContext,
  PagingTable,
  TextFilter,
  ResetButton,
  SubmitButton
} from '../../../../components'
import { getProducts, toggleProduct } from '../services'

interface Props {
  selectedProducts: Array;
}

const ToggleProduct = ({
  row,
  selectedProducts,
  setSelectedProducts
}: Props) => {
  const { record } = useContext(GridContext)

  return (
    <Checkbox
      onClick={() => {
        toggleProduct((data) => {
          setSelectedProducts(data.ids)
        })({
          country: record.country,
          group: record.code,
          type: record.pr,
          product: row
        })
      }}
      checked={selectedProducts.indexOf(`${row.id}`) !== -1}
    />
  )
}

const columns = ({ selectedProducts, setSelectedProducts }) => [
  {
    title: 'Select',
    key: 'select',
    render: (row) => {
      return (
        <ToggleProduct
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          row={row}
        />
      )
    }
  },
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Type',
    dataIndex: 'type_id',
    key: 'type_id'
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
interface FiltersProps {
  search: () => {};
}

const Filters = ({ search }: FiltersProps) => {
  const [params, setParams] = useState({})
  const paramChanged = (name) => (value) => {
    setParams({ ...params, [name]: value })
  }
  return (
    <Card
      style={{
        marginBottom: 20
      }}
    >
      <Space>
        <TextFilter
          label="Product ID"
          name="ID"
          value={params.id}
          onChange={paramChanged('entity_id')}
        />
      </Space>
      <Space>
        <TextFilter
          label="Product Name"
          name="Name"
          value={
            params.name &&
            params.name.like &&
            params.name.like.replaceAll('%', '')
          }
          onChange={(v) => {
            if (v) {
              setParams({ ...params, name: { like: `%${v}%` } })
            } else {
              setParams({ ...params, name: undefined })
            }
          }}
        />
      </Space>
      <Space>
        <TextFilter
          label="SKU"
          name="SKU"
          value={
            params.name &&
            params.name.like &&
            params.name.like.replaceAll('%', '')
          }
          onChange={(v) => {
            if (v) {
              setParams({ ...params, sku: { like: `%${v}%` } })
            } else {
              setParams({ ...params, sku: undefined })
            }
          }}
        />
      </Space>
      <ResetButton
        onReset={() => {
          setParams({})
          search({})
        }}
      />
      <SubmitButton
        onSubmit={() => {
          search(params)
        }}
      />
    </Card>
  )
}

interface ComponentProps {
  reload: () => {};
}

const SelectProduct = ({ reload }: ComponentProps) => {
  const initGridData = {
    current_page: 1,
    data: [],
    page_size: 10,
    total: 0
  }

  const [gridData, setGridData] = useState(initGridData)
  const [selectedProducts, setSelectedProducts] = useState([])
  const { record, drawerStack } = useContext(GridContext)
  const [searchParams, setSearchParams] = useState({})
  const onOpen = useCallback(
    (
      record = drawerStack.length && drawerStack[drawerStack.length - 1].record
    ) => {
      getProducts(setGridData)({ ___country: record.country })
      toggleProduct((data) => {
        setSelectedProducts(data.ids)
      })({
        country: record.country,
        group: record.code,
        type: record.pr
      })
    },
    [drawerStack]
  )
  const paginationHandler = (current, pageSize) => {
    getProducts(setGridData)({
      ___country: record.country,
      scenario_params: JSON.stringify(searchParams),
      page: current,
      page_size: pageSize
    })
  }

  const searchHandler = (params) => {
    setSearchParams(params)
    getProducts(setGridData)({
      ___country: record.country,
      scenario_params: JSON.stringify(params)
    })
  }

  return (
    <GridFormDrawer
      onOpen={onOpen}
      onClose={(record) => reload(record.country)}
      type="product_picker"
      title={`Select Products for ${record.label}`}
    >
      <Filters search={searchHandler} />
      <PagingTable
        handler={paginationHandler}
        columns={columns({ selectedProducts, setSelectedProducts })}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.current || gridData.page}
        pageSize={gridData.page_size || 10}
      />
    </GridFormDrawer>
  )
}

export default SelectProduct
