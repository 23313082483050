import React from 'react'

import { getStaticText } from '../../utils'

const dashboardStructureColumns = [
  {
    title: <div>{getStaticText('dashboard.table.name')}</div>,
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: <div>{getStaticText('dashboard.table.number')}</div>,
    dataIndex: 'number',
    key: 'number',
    align: 'center'
  },
  {
    title: <div>{getStaticText('dashboard.table.rate')}</div>,
    dataIndex: 'rate',
    key: 'rate',
    align: 'center'
  }
]

export default dashboardStructureColumns
