import services, {
  withTableLoading,
  withGlobalLoading,
  withTranslation
} from '../../services'

export const getActivityList = (handler) =>
  withTableLoading((params) =>
    services.topup.getActivityList(params).then(handler)
  )

export const getActivityDetail = (handler) =>
  withGlobalLoading((id) =>
    services.topup.getActivityDetail({ id }).then(handler)
  )

export const deleteActivity = (handler) =>
  withGlobalLoading((id) => services.topup.deleteActivity({ id }).then(handler))

export const createActivity = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.topup.createActivity(params).then(handler)
    )
  )

export const updateActivity = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.topup.saveActivity(params).then(handler)
    )
  )

// item
export const getActivityOrderList = (handler) =>
  withTableLoading((params) =>
    services.topup.getActivityOrderList(params).then(handler)
  )

export const getActivityOrderDetail = (handler) =>
  withGlobalLoading((id) =>
    services.topup.getActivityOrderDetail({ id }).then(handler)
  )

export const deleteActivityOrder = (handler) =>
  withGlobalLoading((id) =>
    services.topup.deleteActivityOrder({ id }).then(handler)
  )

export const createActivityOrder = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.topup.createActivityOrder(params).then(handler)
    )
  )

export const updateActivityOrder = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.topup.saveActivityOrder(params).then(handler)
    )
  )
export const makeApproval = (handler) =>
  withGlobalLoading((id) => services.topup.makeApproval({ id }).then(handler))

export const getOrderAuditList = (handler) =>
  withTableLoading((params) =>
    services.topup.getOrderAuditList(params).then(handler)
  )

export const getAuditDetail = (handler) =>
  withGlobalLoading((id) => services.topup.getAuditDetail({ id }).then(handler))

export const topup = (handler) =>
  withGlobalLoading((id) => services.topup.topup({ id }).then(handler))

export const approveToComplete = (handler) =>
  withGlobalLoading((id) =>
    services.topup.approveToComplete({ id }).then(handler)
  )

export const rejectToClose = (handler) =>
  withGlobalLoading((id) => services.topup.rejectToClose({ id }).then(handler))
