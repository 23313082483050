import React, { useContext, ReactNode } from 'react'
import { Checkbox } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { GridContext, PermissionButton } from '../../../../components'
import { togglePrivilege } from '../services'

interface Props {
  row: Object;
  pr: String;
  config: boolean;
}
const PrivilegeToggle = ({ row, pr, config, coupon }: Props) => {
  const { toggle, onLoad } = useContext(GridContext)
  const toggleFunction = (pr) => {
    togglePrivilege(onLoad)(pr)
  }
  return (
    <div>
      <Checkbox
        checked={row.privileges[pr].allowed}
        onClick={() => toggleFunction({ code: row.code, pr })}
      />
      {config && (
        <>
          &nbsp;&nbsp;
          <a
            aria-hidden="true"
            disabled={!row.privileges[pr].allowed}
            onClick={() => {
              if (row.privileges[pr].allowed) {
                toggle({ ...row, pr }, `product_${pr}`)
              }
            }}
          >
            Product
          </a>
        </>
      )}
      {coupon && (
        <>
          &nbsp;&nbsp;
          <a
            aria-hidden="true"
            disabled={!row.privileges[pr].allowed}
            onClick={() => {
              if (row.privileges[pr].allowed) {
                toggle({ ...row, pr }, `coupon_${pr}`)
              }
            }}
          >
            Coupon
          </a>
        </>
      )}
    </div>
  )
}

interface ConfigColumnProps {
  children: ReactNode;
}

const ConfigColumn = ({ pr, children }: ConfigColumnProps) => {
  const { toggle } = useContext(GridContext)
  return (
    <>
      {children}
      &nbsp;&nbsp;
      <a aria-hidden="true" onClick={() => toggle({ pr }, `coupon_${pr}`)}>
        <EditOutlined />
      </a>
    </>
  )
}

const CoinConfigColumn = ({ pr, children, level }: ConfigColumnProps) => {
  const { toggle } = useContext(GridContext)
  return (
    <>
      {children}
      &nbsp;&nbsp;
      <a
        aria-hidden="true"
        onClick={() => toggle({ pr, level }, `coupon_${pr}`)}
      >
        <EditOutlined />
      </a>
    </>
  )
}

const Actions = ({ record }: Props) => {
  const { toggle } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit')
  }
  return (
    <>
      <PermissionButton
        size="small"
        onClick={onEdit}
        icon={<EditOutlined />}
        permission="rbac/user/save"
        type="primary"
      />
    </>
  )
}

export const columns = [
  {
    title: 'icon',
    align: 'center',
    render: (data) => {
      return (
        <span>
          <span
            style={{
              display: 'inline-block',
              borderRadius: '50%',
              marginRight: '10px'
            }}
          >
            <img alt="" src={data.icon} width="30" />
          </span>
        </span>
      )
    }
  },
  {
    title: 'label',
    dataIndex: 'label',
    key: 'label'
  },
  {
    title: (
      <CoinConfigColumn pr="pr_coin_exchange" level="default">
        Coin Exchange
      </CoinConfigColumn>
    ),
    key: 'pr_coin_exchange',
    render: (row) => {
      return (
        <CoinConfigColumn pr="pr_coin_exchange" level={row.code}>
          Coin Exchange
        </CoinConfigColumn>
      )
    }
  },
  {
    title: <ConfigColumn pr="pr_member_products">Member Price</ConfigColumn>,
    key: 'pr_member_products',
    render: (row) => {
      return <PrivilegeToggle row={row} pr="pr_member_products" config />
    }
  },
  {
    title: <ConfigColumn pr="pr_member_new">New User Receive</ConfigColumn>,
    key: 'pr_member_new',
    render: (row) => {
      return <PrivilegeToggle row={row} pr="pr_member_new" config />
    }
  },
  {
    title: <ConfigColumn pr="pr_voucher">Voucher</ConfigColumn>,
    key: 'pr_voucher',
    render: (row) => {
      return <PrivilegeToggle row={row} pr="pr_voucher" config />
    }
  },
  {
    title: <ConfigColumn pr="pr_new_trail">New Trial</ConfigColumn>,
    key: 'pr_new_trail',
    render: (row) => {
      return <PrivilegeToggle row={row} pr="pr_new_trail" config />
    }
  },
  {
    title: <ConfigColumn pr="pr_free_purchase">Free Purchase</ConfigColumn>,
    key: 'pr_free_purchase',
    render: (row) => {
      return <PrivilegeToggle row={row} pr="pr_free_purchase" config />
    }
  },
  {
    title: <ConfigColumn pr="pr_crowd_founding">Crowd Found</ConfigColumn>,
    key: 'pr_crowd_founding',
    render: (row) => {
      return <PrivilegeToggle row={row} pr="pr_crowd_founding" config />
    }
  },
  {
    title: <ConfigColumn pr="pr_lucky_draw">Luckydraw</ConfigColumn>,
    key: 'pr_lucky_draw',
    render: (row) => {
      return <PrivilegeToggle row={row} pr="pr_lucky_draw" config />
    }
  },
  {
    title: 'Actions',
    key: 'actions',
    render: (row) => <Actions record={row} />
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
