import React, { useState, useContext } from 'react'
import { VerticalAlignTopOutlined } from '@ant-design/icons'
import { Checkbox } from 'antd'

import {
  GridFormDrawer,
  GridContext,
  PagingTable,
  PermissionButton
} from '../../../../../components'
import {
  getCategoryActivities,
  topActivity,
  saveActivityRelation
} from '../services'

interface ActionProps {
  record: Object;
  reload: (id: Number) => {};
}

const Actions = ({ record, reload, cat }: ActionProps) => {
  const onTop = () => {
    topActivity(reload)({ id: cat, activity_id: record.id })
  }
  return (
    <>
      <PermissionButton
        style={{ marginLeft: '5px' }}
        icon={<VerticalAlignTopOutlined />}
        size="small"
        permission="crowd-founding/category/top-activity"
        type="primary"
        onClick={onTop}
      >
        Top
      </PermissionButton>
    </>
  )
}

export const initGridData = {
  page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}

const SelectActivity = () => {
  const [gridData, setGridData] = useState(initGridData)
  const [cat, setCat] = useState(0)
  const [selectedIds, setSelectedIds] = useState([])

  const initData = (data) => {
    const ids = []
    data.data.map((v) => {
      return v.checked > 0 && ids.push(v.id)
    })
    setSelectedIds(ids)
    setGridData(data)
  }

  const openHandler = (id) => {
    getCategoryActivities(initData)({ id })
    setCat(id)
  }
  const { toggle, onLoad } = useContext(GridContext)
  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }
  const reload = () => openHandler(cat)
  const pagingHandler = (current, pageSize) =>
    getCategoryActivities(initData)({ id: cat, current, pageSize })

  const toggleId = (id) => {
    const ids = []

    for (let i = 0; i < selectedIds.length; i += 1) {
      ids.push(selectedIds[i])
    }
    if (ids.indexOf(id) !== -1) {
      ids.splice(selectedIds.indexOf(id), 1)
      saveActivityRelation(reload)({ id: cat, activity_id: id, value: 0 })
    } else {
      ids.push(id)
      saveActivityRelation(reload)({ id: cat, activity_id: id, value: 1 })
    }
    setSelectedIds(ids)
  }

  const columns = [
    {
      title: '选择',
      key: 'selected',
      align: 'center',
      width: 80,
      render: (data) => {
        return (
          <Checkbox
            checked={selectedIds.indexOf(data.id) !== -1}
            onChange={() => toggleId(data.id)}
          />
        )
      }
    },
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      width: 150
    },
    {
      title: '点击',
      dataIndex: 'clicked',
      key: 'link',
      width: 100,
      align: 'center'
    },
    {
      title: '下单',
      dataIndex: 'ordered',
      key: 'link',
      width: 100,
      align: 'center'
    },
    {
      title: '排序',
      dataIndex: 'position',
      key: 'link',
      width: 100,
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: '',
      width: 200,
      align: 'center',
      key: 'x',
      render: (record) => <Actions record={record} reload={reload} cat={cat} />
    }
  ]
  return (
    <GridFormDrawer
      onOpen={(record) => openHandler(record.id)}
      onSave={closeDrawer}
      onCancel={closeDrawer}
      type="select_activity"
      title="Select Activity"
    >
      <PagingTable
        columns={columns}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        handler={(current, pageSize) => pagingHandler(current, pageSize)}
        initGridData={initGridData}
        current={gridData.page}
        scroll={{ x: 'max-content', y: true }}
        pageSize={gridData.page_size}
      />
    </GridFormDrawer>
  )
}

export default SelectActivity
