import React from 'react'
import { OptionColumn } from '../../../../components'
import Actions from './Actions'

export const createColumns = () => [
  {
    title: '国家',
    dataIndex: 'country',
    key: 'country',
    align: 'center'
  },
  {
    title: '单号',
    dataIndex: 'order_no',
    key: 'order_no',
    align: 'center'
  },
  {
    title: '充值名称',
    dataIndex: 'activity_title',
    key: 'activity_title',
    align: 'center'
  },
  {
    title: '类型',
    dataIndex: 'activity_type',
    key: 'activity_type',
    align: 'center',
    render: (value) => <OptionColumn source="topup_types" value={value} />
  },
  {
    title: '运营商',
    dataIndex: 'operator_code',
    key: 'operator_code',
    align: 'center',
    render: (value) => <OptionColumn source="topup_operators" value={value} />
  },
  {
    title: '流量/话费',
    dataIndex: 'activity_amount',
    key: 'activity_amount',
    align: 'center'
  },
  {
    title: '用户邮箱',
    dataIndex: 'customer_email',
    key: 'customer_email',
    align: 'center'
  },
  {
    title: '手机号',
    dataIndex: 'phone_number',
    key: 'phone_number',
    align: 'center'
  },
  {
    title: '来源',
    dataIndex: 'source',
    key: 'source',
    align: 'center',
    render: (value) => <OptionColumn source="topup_source" value={value} />
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
  {
    title: '更新时间',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center'
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (value) => (
      <OptionColumn source="topup_order_statuses" value={value} />
    )
  },
  {
    title: '审核状态',
    dataIndex: 'audit_status',
    key: 'audit_status',
    align: 'center',
    render: (value) => (
      <OptionColumn source="topup_order_approval_statuses" value={value} />
    )
  },
  {
    title: '操作',
    key: 'operations',
    align: 'center',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
