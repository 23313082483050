import React from 'react'
import { Tooltip, Tag } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getStaticText } from '../../utils'

const viewTableColumns = [
  {
    title: (
      <div>
        {getStaticText('daily.sales.review.country_code')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.review.country_code')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country_code',
    key: 'country_code',
    align: 'center',
    className: 'reviewPPUser',
    width: 100,
    fixed: 'left'
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.review.pp_user')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.review.pp_user')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'pp_user',
    key: 'pp_user',
    align: 'center',
    width: 300,
    render: (data) => {
      return (
        <div>
          {data.map((item) => {
            return (
              <Tag key={item} style={{ marginBottom: 4, marginRight: 4 }}>
                {item}
              </Tag>
            )
          })}
        </div>
      )
    }
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.review.product_name')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.review.product_name')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'product_name',
    key: 'product_name',
    align: 'center',
    width: 100
    // ellipsis: true
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.review.nickname')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.review.nickname')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'nickname',
    key: 'nickname',
    width: 100,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.review.title')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.review.title')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'title',
    key: 'title',
    width: 100,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.review.detail')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.review.detail')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'detail',
    key: 'detail',
    align: 'center',
    width: 300
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.review.rating')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.review.rating')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'rating',
    key: 'rating',
    align: 'center',
    width: 100
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.review.status')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.review.status')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 100
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.review.created_at')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.review.created_at')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center',
    width: 100
  }
]

export default viewTableColumns
