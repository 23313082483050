import React, { useState, useEffect } from 'react'
import { Tree } from 'antd'
import { getPermissionNodes } from '../services'

const toTreeData = (data) => {
  if (data.length) {
    for (let i = 0; i < data.length; i += 1) {
      data[i] = toTreeData(data[i])
    }
    return data
  }
  if (data.children && data.children.length) {
    for (let i = 0; i < data.children.length; i += 1) {
      data.children[i] = toTreeData(data.children[i])
    }
  }
  data.title = data.name
  if (data.remark) {
    data.title = `${data.title}(${data.remark})`
  }
  if (data.id) {
    data.key = data.id
  } else {
    data.key = Math.random()
  }
  return data
}

const toSelectedKeys = (permissions) => {
  if (!permissions) {
    return []
  }
  if (typeof permissions === 'object') {
    return permissions
  }
  return permissions.split(',')
}

interface Props {
  permission: Object;
  onChange: () => {};
}

export default ({ permissions, onChange }: Props) => {
  const [treeNodes, setTreeNodes] = useState([])
  const [checkedNodes, setCheckedNodes] = useState([])
  useEffect(() => {
    getPermissionNodes((data) => {
      setTreeNodes(toTreeData(data))
      setCheckedNodes(toSelectedKeys(permissions))
    })()
  }, [setTreeNodes, setCheckedNodes, permissions])

  const onUpdate = (values) => {
    setCheckedNodes(values)
    onChange(values)
  }
  return (
    <Tree
      checkable
      autoExpandParent
      expandedKeys={checkedNodes}
      checkedKeys={checkedNodes}
      onCheck={onUpdate}
      treeData={treeNodes}
    />
  )
}
