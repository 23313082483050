import { Component } from 'react'
import services from '../../services'

class Crm extends Component {
  constructor(props) {
    super(props)
    this.fetchData()
  }

  fetchData = () => {
    services.jump.jumpCrm([]).then((result) => {
      window.localStorage.clear()
      window.location.href = result.tourl
    })
  }
}

Crm.propTypes = {}

export default Crm
