import { Creatings } from '../pages'

const advance = {
  path: '/advance',
  primary: 'app.sider.advance',
  modular: 'market',
  permission: 'advance',
  component: Creatings,
  exact: true
}
export default advance
