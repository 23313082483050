import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Select, Space, Card, Typography, Button, DatePicker } from 'antd'
import { getStaticText, storage } from '../../../utils'
import services from '../../../services'
import dateFormat from '../../../config/dateFormat'

const dateFormatMonth = dateFormat.month
const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const requestParams = {
  country: ['All_Country'],
  start_date: moment().subtract('months', 12),
  end_date: moment().subtract('months', 1)
}

const requestOption = {
  grid: {
    bottom: 40,
    top: 40,
    left: 40,
    containLabel: true
  },
  toolbox: {
    show: true,
    feature: {
      dataView: {
        readOnly: false,
        title: '编辑'
      },
      restore: {
        show: true,
        title: '重置'
      },
      saveAsImage: {
        show: true,
        title: '下载'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    data: []
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      name: '$'
    },
    {
      name: '%',
      axisLabel: {
        formatter: '{value} %'
      }
    }
  ],
  series: []
}

const getbarLabel = {
  show: true,
  position: 'top',
  formatter: (value) =>
    value.data.value.toString().length > 4
      ? `${parseInt(value.data.value / 10000, 10) + 1}万`
      : value.data.value
}

const getLineLabel = {
  show: true,
  position: 'top',
  formatter: (value) => {
    return `${value.data.value}%`
  }
}

class MonthCatalogGmv extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatMonth)
      : requestParams.end_date
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatMonth)
      : requestParams.start_date
    const country = nextSearch.get('country')
      ? nextSearch.get('country').split(',')
      : requestParams.country
    this.state = {
      countries: this.checkCountries(country),
      country,
      start_date: startDate,
      start_format: startDate.format(dateFormatMonth),
      end_date: endDate,
      end_format: endDate.format(dateFormatMonth),
      series: [],
      loading: false
    }
    this.useEffect()
  }

  getrequest = () => {
    return {
      country: this.state.country.toString(),
      start_date: this.state.start_format,
      end_date: this.state.end_format
    }
  }

  handleMonthExport = () => {
    this.setState({ loading: true })
    services.mapList
      .monthCatalogExport({})
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleWeekExport = () => {
    this.setState({ loading: true })
    services.mapList
      .weekCatalogExport({})
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleCountryChange = (country) => {
    this.setState({
      country,
      countries: this.checkCountries(country)
    })
  }

  handleDateChange = (date, dateString) => {
    this.setState({
      start_date: moment(dateString[0], dateFormatMonth),
      start_format: dateString[0],
      end_date: moment(dateString[1], dateFormatMonth),
      end_format: dateString[1]
    })
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  checkCountries = (country) => {
    const countries = storage.allowedCountries.val
    if (country.indexOf('All_Country') >= 0) {
      countries.forEach((country) => {
        country.disabled = true
      })
      countries[0].disabled = false
    } else if (country.length === 0) {
      countries.forEach((country) => {
        country.disabled = false
      })
    } else {
      countries[0].disabled = true
    }
    return countries
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchData()
  }

  fetchData = () => {
    services.mapList.monthCatalogGmv(this.getrequest()).then((result) => {
      const series = []
      Object.keys(result.series).forEach((item, index) => {
        if (result.series[item] instanceof Array) {
          result.series[item] = result.series[item].map((value) => {
            if (value.type === 'bar') {
              value.label = getbarLabel
            } else if (value.type === 'line') {
              value.label = getLineLabel
              value.yAxisIndex = 1
            }
            return value
          })
        }
        series.push({
          ...requestOption,
          legend: {
            data: result.series[item].map((key) => {
              return key.name
            })
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true
              },
              data: result.month
            }
          ],
          series: result.series[item],
          index
        })
      })
      this.setState({
        series
      })
    })
  }

  getSeries = () => {
    return this.state.series.map((option) => {
      return (
        <ReactEcharts
          style={{ width: '100%' }}
          option={option}
          key={option.index}
        />
      )
    })
  }

  render() {
    return (
      <>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 220 }}
              value={this.state.country}
              onChange={this.handleCountryChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <RangePicker
              picker="month"
              value={[this.state.start_date, this.state.end_date]}
              format={dateFormatMonth}
              onChange={this.handleDateChange}
              allowClear={false}
            />
          </Space>
          <Space style={{ float: 'right' }}>
            <Button
              onClick={this.handleWeekExport}
              loading={this.state.loading}
            >
              {getStaticText('component.table.export.week.text')}
            </Button>
            <Button
              onClick={this.handleMonthExport}
              loading={this.state.loading}
            >
              {getStaticText('component.table.export.month.text')}
            </Button>
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        {this.getSeries()}
      </>
    )
  }
}

MonthCatalogGmv.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default MonthCatalogGmv
