import React, { useEffect, useState, useMemo, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Row, Col, Input } from 'antd'

import {
  GridFormDrawer,
  CountrySelect,
  GridContext
} from '../../../../components'
import { createBanner, saveBanner } from '../services'
import Upload from './Upload'

const BannerForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const location = useLocation()

  // upload image url
  const [uploadData, setUploadData] = useState([])
  // get upload image url
  const [getuploadData, setGetUploadData] = useState([])
  const [noUpload, setNoUpload] = useState(false)

  const [country, setCountry] = useState('')
  const countryChange = (value) => {
    setCountry(value)
  }

  const filterCountry = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('___country')
  }, [location])

  const { onLoad, toggle } = useContext(GridContext)
  const emptyForm = () => {
    setFormData({})
    setCountry('')
    setUploadData([])
    setGetUploadData([])
    setNoUpload(false)
    form.resetFields()
  }

  const closeDrawer = () => {
    emptyForm()
    toggle({}, '')
    onLoad()
  }

  const openHandler = (record) => {
    setFormData({
      id: record.id,
      country: filterCountry,
      name: record.banner_name || '',
      target: record.target_url
    })
    setNoUpload(false)
    setCountry(filterCountry)
    setUploadData([record.banner_url])
    setGetUploadData([{ url: record.banner_url }])
  }

  const onFinish = (values) => {
    if (!uploadData.length) {
      setNoUpload(true)
      return
    }

    const params = {
      banner_name: values.name || '',
      banner_url: uploadData[0],
      target_url: values.target
    }

    if (params.banner_name === '') delete params.banner_name

    if (formData.id) {
      saveBanner(closeDrawer)({
        id: formData.id,
        country: values.country,
        params
      })
    } else {
      createBanner(closeDrawer)({ country: values.country, params })
    }
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Banner' : 'New Banner')}
    >
      <Form form={form} onFinish={onFinish} autoComplete="off">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please select country' }]}
            >
              <CountrySelect onChange={countryChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Banner Name" name="name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label="Upload" name="upload">
              <>
                <Upload
                  country={country}
                  getuploadData={getuploadData}
                  setUploadData={setUploadData}
                  setNoUpload={setNoUpload}
                />
                {noUpload && (
                  <div style={{ color: 'red', marginTop: -8 }}>
                    please upload image
                  </div>
                )}
              </>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="target"
              label="Target Url"
              rules={[
                { required: true, message: 'Please enter banner target url' }
              ]}
            >
              <Input placeholder="Please enter banner target url" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </GridFormDrawer>
  )
}
export default BannerForm
