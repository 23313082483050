import React, { useContext, useState } from 'react'
import {
  GridFormDrawer,
  GridContext,
  PagingTable
} from '../../../../../components'
import { getCutActivityStatistics } from '../../../services'
import { columns, dataSourceMap, initGridData } from './ColumnsConfig'

const CutStatistics = () => {
  const { record, toggle, onLoad } = useContext(GridContext)
  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }
  const [gridData, setGridData] = useState(initGridData)

  const onOpen = () => {
    getCutActivityStatistics((ret) => {
      setGridData(ret)
    })({ activity_id: record.id })
  }

  return (
    <GridFormDrawer
      onOpen={onOpen}
      onClose={closeDrawer}
      type="cut_statistics"
      title="Cut Statistics"
    >
      <PagingTable
        columns={columns}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.page}
        scroll={{ x: 'max-content', y: true }}
        pageSize={gridData.page_size}
      />
    </GridFormDrawer>
  )
}

export default CutStatistics
