import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { getThemes } from '../services'

const { Option } = Select

interface Props {
  value: String;
  onChange: () => {};
}
export default ({ value, onChange, onThemeChange }: Props) => {
  const [themes, setThemes] = useState([])
  useEffect(() => {
    getThemes(setThemes)()
  }, [setThemes])

  useEffect(() => {
    onThemeChange({})
    themes.forEach((i) => i.code === value && onThemeChange(i))
  }, [value, themes, onThemeChange])

  return (
    <Select onChange={onChange} value={value}>
      {themes.map((theme) => (
        <Option key={theme.code} value={theme.code}>
          {theme.name}
        </Option>
      ))}
    </Select>
  )
}
