import React, { useEffect, useCallback, useState, useContext } from 'react'
import { Form, Row, Col, Input, Switch } from 'antd'

import {
  GridFormDrawer,
  CountrySelect,
  GridContext,
  TranslatableForm
} from '../../../../../components'
import {
  getCategoryDetail,
  updateCategory,
  createCategory,
  getBannerList
} from '../services'
import BannerGrid from './Banner/Grid/ui'

const CategoryForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({
    visible: 1
  })

  const [country, setCountry] = useState('')
  const countryChange = (value) => {
    setCountry(value)
  }
  const [gridData, setGridData] = useState({
    data: [],
    total: 0,
    page: 1,
    page_size: 10
  })

  const { onLoad, toggle } = useContext(GridContext)
  const emptyForm = () => {
    setFormData({})
    setCountry('')
    form.resetFields()
  }
  const closeDrawer = () => {
    emptyForm()
    toggle({}, '')
    onLoad()
  }

  const formatResData = useCallback((value) => {
    const newValue = Object.assign(value, {
      visible: !!Number(value.visible)
    })
    setCountry(newValue.country)
    setFormData(newValue)
  }, [])

  const openHandler = (id) => {
    getCategoryDetail(formatResData)(id)
    getBannerList(setGridData)({ id })
  }

  const onFinish = (values) => {
    const formatValue = {
      country: values.country,
      title: values.title,
      description: values.description,
      visible: values.visible ? '1' : '0',
      url_key: values.url_key
    }
    if (formData.id) {
      updateCategory(closeDrawer)({ id: formData.id, data: formatValue })
    } else {
      createCategory(closeDrawer)({ country, data: formatValue })
    }
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Category' : 'New Category')}
    >
      <TranslatableForm entity="crowd_founding_category">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Countries"
                name="country"
                rules={[{ required: true, message: 'Please select country' }]}
              >
                <CountrySelect onChange={countryChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  { required: true, message: 'Please enter category title' }
                ]}
              >
                <Input placeholder="Please enter category title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: 'Please enter description' }
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Visible" name="visible" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="url_key"
                label="URL Key"
                rules={[{ required: true, message: 'Please enter url key' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </TranslatableForm>
      <BannerGrid cat={formData.id} gridData={gridData} reload={openHandler} />
    </GridFormDrawer>
  )
}

export default CategoryForm
