import request from './request'

export const weekTrend = (data) =>
  request.post('/backend/echarts/get-week-trend', data)

export const weekPie = (data) =>
  request.post('/backend/echarts/get-week-pie', data)

export const monthPie = (data) =>
  request.post('/backend/echarts/get-month-pie', data)

export const monthTrend = (data) =>
  request.post('/backend/echarts/get-month-trend', data)

export const monthCatalogGmv = (data) =>
  request.post('/backend/echarts/get-month-catalog-gmv', data)

export const monthCatalogExport = (data) =>
  request.post('/backend/echarts/get-month-catalog-export', data)

export const weekCatalogExport = (data) =>
  request.post('/backend/echarts/get-week-catalog-export', data)

export const MonthCatalogSales = (data) =>
  request.post('/backend/echarts/get-month-catalog-sales', data)

export const monthCatalogPie = (data) =>
  request.post('/backend/echarts/get-month-catalog-pie', data)

export const MonthCatalogPieSales = (data) =>
  request.post('/backend/echarts/get-month-catalog-pie-sales', data)

export const monthStores = (data) =>
  request.post('/backend/echarts/get-month-stores', data)

export const weekStores = (data) =>
  request.post('/backend/echarts/get-week-stores', data)

export const monthStoresPie = (data) =>
  request.post('/backend/echarts/get-month-stores-pie', data)

export const weekActive = (data) =>
  request.post('/backend/echarts/get-week-active', data)

export const monthActive = (data) =>
  request.post('/backend/echarts/get-month-active', data)

export const monthCrossTotalGmv = (data) =>
  request.post('/backend/echarts/get-month-cross-total-gmv', data)

export const monthCrossNgGmv = (data) =>
  request.post('/backend/echarts/get-month-cross-ng-gmv', data)

export const monthCrossNgSku = (data) =>
  request.post('/backend/echarts/get-month-cross-ng-sku', data)

export const weekUseTime = (data) =>
  request.post('/backend/echarts/get-week-use-time', data)

export const monthUseTime = (data) =>
  request.post('/backend/echarts/get-month-use-time', data)

export const retentionRate = (data) =>
  request.post('/backend/echarts/get-retention-rate', data)

export const showCommand = (data) =>
  request.post('/backend/model-command/generate-command', data)

export const exportCommand = (params) =>
  request.get('/backend/model-command/export-command', { params })

export const yearData = (params) =>
  request.get('/backend/echarts/get-year-data', { params })

export const YearGroup = (params) =>
  request.get('/backend/echarts/get-year-group', { params })

export const YearManage = (params) =>
  request.get('/backend/echarts/get-year-manage', { params })

export const OrdersList = (params) =>
  request.get('/backend/order/export', { params })

export const gmvDay = (data) =>
  request.post('/backend/echarts/get-gmv-day', data)
