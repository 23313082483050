import {
  I18N_EMPTY_FORM,
  I18N_SET_FORM_DATA,
  I18N_UPDATE_FIELD
} from '../actions/i18n'

const initState = {
  formData: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case I18N_EMPTY_FORM:
      return {
        ...state,
        formData: {}
      }
    case I18N_SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload
      }
    case I18N_UPDATE_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.payload.locale]: {
            ...state.formData[action.payload.locale],
            [action.payload.field]: action.payload.value
          }
        }
      }
    default:
      return state
  }
}
