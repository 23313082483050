import React from 'react'
import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import { PermissionButton } from '../../../../../components'
import { deleteFortunateItem } from '../services'

interface Props {
  record: Object;
  onChange: () => {};
}
const Actions = ({ record, onChange }: Props) => {
  const onDelete = () => {
    deleteFortunateItem(onChange)(record.id)
  }
  return (
    <>
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <Tooltip title="delete">
          <PermissionButton
            style={{ marginLeft: '5px' }}
            icon={<DeleteOutlined />}
            size="small"
            permission="luckyspin/activity/fortunate/delete"
            type="primary"
            danger
          />
        </Tooltip>
      </Popconfirm>
    </>
  )
}
export default Actions
