/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { Input, Form } from 'antd'
import LabelInput from './LabelInput'

interface Props {
  style: Object;
  title: String;
  placeHolder: String;
  size: String;
  colon: Boolean;
  space: String;
  name: String;
  callback: Function;
}
const RegularInput = ({
  style,
  title,
  placeHolder = false,
  size = 'large',
  colon,
  space,
  name,
  callback
}: Props) => {
  const [setting, setSetting] = useState('')
  useEffect(() => {
    if (callback) callback(setting)
  }, [setting]);
  return (
    <div
      style={{
        display: 'flex',
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#E3E2E1'
      }}
    >
      <div
        style={{
          width: style?.width || '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          paddingLeft: '10px'
        }}
      >
        {LabelInput({ title, space, colon })}
      </div>
      <Form.Item name={name} style={{ marginBottom: 'unset', width: '100%' }}>
        <Input
          onChange={(e) => setSetting(e.target.value)}
          style={{ width: '100%' }}
          bordered={false}
          size={size}
          placeholder={placeHolder}
        />
      </Form.Item>
    </div>
  )
}

export default RegularInput
