import React, { useCallback } from 'react'
import { Layout, Breadcrumb, Card } from 'antd'
import { useHistory, Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getStaticText } from '../../utils'
import GlobalTips from '../GlobalTips/GlobalTips'

const { Content } = Layout
const PageContainer = (props) => {
  const {
    location: { pathname }
  } = useHistory()
  const { routes } = props

  const pathnameTree = pathname
    .split('/')
    .slice(1)
    .map((pathKey) => `/${pathKey}`)

  const getBreadcrumb = useCallback(
    (routes, prevData = []) => {
      const newData = prevData
      routes.forEach((route) => {
        const childRoute = route.routes ?? []
        if (childRoute.length) {
          if (
            pathnameTree.includes(route.path) ||
            pathnameTree.join('') === route.path
          ) {
            newData.push(
              <Breadcrumb.Item key={route.path}>
                {getStaticText(route.primary)}
              </Breadcrumb.Item>
            )
            getBreadcrumb(childRoute, newData)
          }
        } else if (pathnameTree.join('') === route.path) {
          newData.push(
            <Breadcrumb.Item key={route.path}>
              {getStaticText(route.primary)}
            </Breadcrumb.Item>
          )
        }
      })

      return newData
    },
    [pathnameTree]
  )

  const showGlobalTips = useCallback(() => {
    const route = routes.find((route) => pathnameTree.includes(route.path))
    if (route && route.hideGlobalTips) {
      return false
    }
    return true
  }, [routes, pathnameTree])

  const generatorPages = useCallback((routes) => {
    return routes.map((route) => {
      const childRoute = route.routes ?? []
      if (childRoute.length) {
        return generatorPages(childRoute)
      }
      return (
        <Route
          key={route.path}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      )
    })
  }, [])

  return (
    <Content>
      <Card style={{ margin: 24 }}>
        <Breadcrumb style={{ marginBottom: 16 }}>
          {getBreadcrumb(routes)}
        </Breadcrumb>
        {showGlobalTips() && <GlobalTips />}
      </Card>
      <Card style={{ margin: '0 24px' }}>
        <Switch>
          {generatorPages(routes)}
          <Redirect to="/index" exact />
        </Switch>
      </Card>
    </Content>
  )
}
PageContainer.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
}

export default PageContainer
