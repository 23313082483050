import React, { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { columns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getUserList } from '../services'
import { GridContextProvider, PagingTable } from '../../../../components'
import Filters from './Filters'
import UserForm from '../Edit/ui'
import PointsViewGrid from '../Points/ui'

export default () => {
  const [gridData, setGridData] = useState(initGridData)
  const location = useLocation()

  const onLoad = useCallback(() => {
    getUserList(setGridData)(new URLSearchParams(location.search))
  }, [location, setGridData])

  return (
    <GridContextProvider onLoad={onLoad}>
      <Filters />
      <PagingTable
        columns={columns}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.current}
        pageSize={gridData.page_size || 10}
      />
      <UserForm />
      <PointsViewGrid />
    </GridContextProvider>
  )
}
