/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { Input, Select, Form } from 'antd'
import LabelInput from './LabelInput'

const { Option } = Select
interface Props {
  title: String;
  props: Object;
  space: String;
  colon: Boolean;
  placeHolder: String;
  options: Function;
  callback: Function;
  name: String;
}
const RegularSelectInput = ({
  title,
  style,
  space,
  colon,
  placeHolder,
  options = [],
  callback,
  name
}: Props) => {
  const [setting, setSetting] = useState(null)
  useEffect(() => {
    if (callback) callback(setting)
  }, [setting]);
  return (
    <Input.Group
      style={{
        display: 'flex',
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#E3E2E1'
      }}
    >
      <div
        style={{
          width: style?.width || '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          paddingLeft: '10px'
        }}
      >
        {LabelInput({ title, space, colon })}
      </div>
      <Form.Item name={name} style={{ width: '100%', marginBottom: 'unset' }}>
        <Select
          onChange={(e) => setSetting(e)}
          bordered={false}
          style={{ width: '100%' }}
          size="large"
          placeholder={placeHolder || 'Please select site'}
        >
          {options &&
            options.map((item) => {
              return <Option value={item.value}>{item.label}</Option>
            })}
        </Select>
      </Form.Item>
    </Input.Group>
  )
}

export default RegularSelectInput
