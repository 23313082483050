import React, { useState, useCallback, useContext } from 'react'
import { Card, Row, Col, Typography, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { getStaticText } from '../../../../../../utils'
import {
  GridFormDrawer,
  PermissionButton,
  PagingTable,
  GridContext
} from '../../../../../../components'
import { createColumns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getActivityItemList } from '../../services'
import ActivityItemForm from '../Edit/ui'

const { Text } = Typography

const ItemList = () => {
  const [gridData, setGridData] = useState(initGridData)
  const [activityId, setActivityId] = useState(0)
  const { record, toggle, drawerStack } = useContext(GridContext)
  const onCreateNewItem = () => {
    toggle({ activity_id: record.id }, 'create_item')
  }
  const onOpen = useCallback(
    (
      record = drawerStack.length && drawerStack[drawerStack.length - 1].record
    ) => {
      getActivityItemList(setGridData)({ activity_id: record.id })
      setActivityId(record.id)
    },
    [drawerStack]
  )
  const paginationHandler = (current, pageSize) => {
    getActivityItemList(setGridData)({
      activity_id: activityId,
      current,
      pageSize
    })
  }

  return (
    <>
      <GridFormDrawer type="items" title="奖项列表" onOpen={onOpen}>
        <Card>
          <Row>
            <Col span={24}>
              <Text>{getStaticText('component.table.header.title')}</Text>
              <Space style={{ cssFloat: 'right' }}>
                <PermissionButton
                  onClick={onCreateNewItem}
                  icon={<PlusOutlined />}
                  type="primary"
                  permission="luckyspin/activity/create"
                >
                  Create
                </PermissionButton>
              </Space>
            </Col>
          </Row>
        </Card>
        <PagingTable
          handler={paginationHandler}
          columns={createColumns({ onChange: () => onOpen(record) })}
          dataSource={dataSourceMap(gridData.data)}
          total={gridData.total}
          initGridData={initGridData}
          current={gridData.page}
          pageSize={gridData.page_size}
        />
      </GridFormDrawer>
      <ActivityItemForm onChange={onOpen} />
    </>
  )
}

export default ItemList
