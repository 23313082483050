import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getPostList = (handler) =>
  withTableLoading((params) => services.bbs.getPostList(params).then(handler))

export const getPostDetail = (handler) =>
  withGlobalLoading((params) =>
    services.bbs.getPostDetail(params).then(handler)
  )

export const deletePost = (handler) =>
  withGlobalLoading((params) => services.bbs.deletePost(params).then(handler))

export const createPost = (handler) =>
  withGlobalLoading((params) => services.bbs.createPost(params).then(handler))

export const savePost = (handler) =>
  withGlobalLoading((params) => services.bbs.savePost(params).then(handler))

export const getReviewList = (handler) =>
  withTableLoading((params) => services.bbs.getReviewList(params).then(handler))

export const deleteReview = (handler) =>
  withGlobalLoading((params) => services.bbs.deleteReview(params).then(handler))
