import { UserGrid, RoleGrid, PermissionGrid } from '../pages'

const rbac = {
  path: '/rbac',
  primary: 'app.sider.rbac',
  hideGlobalTips: true,
  scopes: ['xpark'],
  routes: [
    {
      path: '/rbac/user',
      modular: 'config',
      permission: 'rbac/user/get-list',
      primary: 'app.sider.rbac.user',
      component: UserGrid,
      exact: true
    },
    {
      path: '/rbac/role',
      modular: 'config',
      permission: 'rbac/role/get-list',
      primary: 'app.sider.rbac.role',
      component: RoleGrid,
      exact: true
    },
    {
      path: '/rbac/permissions',
      modular: 'config',
      permission: 'rbac/permission/get-list',
      primary: 'app.sider.rbac.permissions',
      component: PermissionGrid,
      exact: true
    }
  ]
}
export default rbac
