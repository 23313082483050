import React from 'react'
import { Select } from 'antd'

const { Option } = Select

interface Props {
  placeHolder: String;
  option: Array;
  funcParent: Function;
  value: any;
}
const GrayInputSelect = ({
  placeHolder,
  option = [],
  funcParent,
  value
}: Props) => {
  return (
    <Select
      value={value}
      onChange={funcParent}
      className="gray-input-container"
      size="large"
      style={{
        color: 'white',
        width: '100%',
        fontSize: '15px'
      }}
      placeholder={placeHolder}
    >
      {option?.map((item) => {
        return <Option value={item.value}>{item.label}</Option>
      })}
    </Select>
  )
}

export default GrayInputSelect
