import React, { useEffect, useState, useContext } from 'react'
import { Form, Row, Col, Input } from 'antd'
import {
  GridFormDrawer,
  TranslatableForm,
  Uploader,
  GridContext,
  CountrySelect
} from '../../../../components'

import { getMembershipDetail, updateMembership } from '../services'

interface Props {
  reload: () => {};
}
const EditForm = ({ reload }: Props) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const openHandler = getMembershipDetail(setFormData)

  const { toggle } = useContext(GridContext)

  const closeDrawerWithChange = () => {
    toggle({}, '')
    reload()
  }
  const onFinish = (values) => {
    updateMembership(closeDrawerWithChange)({
      data: { ...values },
      id: formData.id
    })
  }

  // const handleFormChange = (changedFields) => {
  //   changedFields.forEach((e) => {
  //     if (e.name.indexOf('odds') !== -1) {
  //       setOddsMsg(toReadable(e.value))
  //     }
  //   })
  // }
  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => openHandler({ id: record.id })}
      onSave={() => form.submit()}
      type="edit"
      title="Edit Membership Config"
    >
      <TranslatableForm entity="ex_membership">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={onFinish}
          // onFieldsChange={handleFormChange}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="country" name="country">
                <CountrySelect />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="icon"
                label="图片"
                rules={[{ required: true, message: 'Please enter title' }]}
              >
                <Uploader />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="description" label="Description">
                <Input.TextArea row={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </TranslatableForm>
    </GridFormDrawer>
  )
}
export default EditForm
