import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Input, Row, Col } from 'antd'
import { acceptRequest, rejectRequest } from '../../services/captchaUtil'
import { clearCaptcha, setCaptcha } from '../../store/actions/global'
import services from '../../services'

interface Props {
  captcha: Object;
  clearCaptcha: () => {};
}

const Captcha = ({ captcha, clearCaptcha, setCaptcha }: Props) => {
  const [code, setCode] = useState('')
  const handleSubmit = () => {
    setCaptcha({ ...captcha, loading: true })
    services.captcha
      .continueRequest({
        request_id: captcha.request_id,
        captcha: code
      })
      .then((data) => {
        acceptRequest(captcha.request_id, data)
        clearCaptcha()
      })
      .catch((e) => {
        rejectRequest(captcha.request_id, e)
        clearCaptcha()
      })
  }

  const handleCancel = () => {
    rejectRequest(captcha.request_id)
    clearCaptcha()
  }

  return (
    <Modal
      title="Security Check"
      visible={captcha.captcha_url}
      onOk={handleSubmit}
      onCancel={handleCancel}
      zIndex={1002}
      okButtonProps={{ loading: captcha.loading }}
      cancelButtonProps={{ loading: captcha.loading }}
      closable={false}
      destroyOnClose
      maskClosable={false}
    >
      <Row gutter={24} align="middle">
        <Col span={8}>
          <img src={captcha.captcha_url} alt="Captcha" />
        </Col>
        <Col span={16}>
          <Input
            defaultValue=""
            onChange={(e) => {
              setCode(e.target.value)
            }}
            placeholder="Please enter captcha"
          />
        </Col>
      </Row>
    </Modal>
  )
}

const mapStateToProps = ({ global }) => ({
  captcha: global.captcha
})

export default connect(mapStateToProps, { clearCaptcha, setCaptcha })(Captcha)
