import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function Crowd(props) {
  const { tableData } = props
  return (
    <>
      {/* 人群 */}

      {/* row1 */}

      <tr className="table-th-color-other">
        <th rowSpan="22" width="100" className="sticky-left">
          人
        </th>
        <th rowSpan="3" width="100" className="sticky-left sticky-second">
          人群（user）
        </th>
        <th colSpan="5">
          A人群
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>GA中所有用户</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">
          I人群
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>感兴趣用户：进入商品详情页面。用户浏览时长超过30S</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">
          P人群
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>下单用户</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">
          L人群
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>注册&下单用户和买两次以上用户</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">
          A--&gt;IPL &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  GA来源
                  <br />
                  画像是C3-F3来源
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">A--&gt;PL</th>
        <th colSpan="4">I--&gt;PL</th>
        <th colSpan="4">P--&gt;L</th>
        <th colSpan="4">
          A--&gt;Lost &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>A的所有用户数-(IPL相加) =规模</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">I--&gt;Lost</th>
      </tr>

      {/* row2 */}
      <tr>
        <th>
          规模
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                {/* <p>来源：a人群用户 + app活跃用户 - i人群用户</p> */}
                <p>来源：a人群用户</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          APP活跃用户
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：周报表填入</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          地域
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-地区-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          职业
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-兴趣-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          成交顾客偏好
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-偏好-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          规模
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          地域
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-地区-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          职业
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-兴趣-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          成交顾客偏好
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-偏好-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          规模
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          地域
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-地区-用户数-GMV-客单价
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          职业
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-兴趣-用户数-GMV-客单价
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          成交顾客偏好
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-偏好-用户数-GMV-客单价
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          规模
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          地域
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-地区-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          职业
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-兴趣-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          成交顾客偏好
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-偏好-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          规模
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          地域
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-地区-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          职业
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-兴趣-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          成交顾客偏好
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-偏好-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          规模
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          地域
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-地区-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          职业
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-兴趣-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          成交顾客偏好
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-偏好-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          规模
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          地域
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-地区-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          职业
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-兴趣-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          成交顾客偏好
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-偏好-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          规模
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          地域
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-地区-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          职业
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-兴趣-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          成交顾客偏好
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-偏好-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          规模
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          地域
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-地区-用户数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          职业
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-兴趣-用户数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          成交顾客偏好
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-偏好-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          规模
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GA拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          地域
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-地区-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          职业
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-兴趣-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          成交顾客偏好
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  来源：GA拉取
                  <br />
                  国家-偏好-用户数-GMV
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.people_group_amount_a}</td>
        <td>{tableData.app_active_users_actual}</td>
        <td>
          <pre>{tableData.people_group_territory_a}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_professional_a}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_preference_a}</pre>
        </td>
        <td>{tableData.people_group_amount_i}</td>
        <td>
          <pre>{tableData.people_group_territory_i}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_professional_i}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_preference_i}</pre>
        </td>
        <td>{tableData.people_group_amount_p}</td>
        <td>
          <pre>{tableData.people_group_territory_p}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_professional_p}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_preference_p}</pre>
        </td>
        <td>{tableData.people_group_amount_l}</td>
        <td>
          <pre>{tableData.people_group_territory_l}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_professional_l}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_preference_l}</pre>
        </td>
        <td>{tableData.people_group_amount_a_to_ipl}</td>
        <td>
          <pre>{tableData.people_group_territory_a_to_ipl}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_professional_a_to_ipl}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_preference_a_to_ipl}</pre>
        </td>
        <td>{tableData.people_group_amount_a_to_pl}</td>
        <td>
          <pre>{tableData.people_group_territory_a_to_pl}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_professional_a_to_pl}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_preference_a_to_pl}</pre>
        </td>
        <td>{tableData.people_group_amount_i_to_pl}</td>
        <td>
          <pre>{tableData.people_group_territory_i_to_pl}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_professional_i_to_pl}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_preference_i_to_pl}</pre>
        </td>
        <td>{tableData.people_group_amount_p_to_l}</td>
        <td>
          <pre>{tableData.people_group_territory_p_to_l}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_professional_p_to_l}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_preference_p_to_l}</pre>
        </td>
        <td>{tableData.people_group_amount_a_to_lost}</td>
        <td>
          <pre>{tableData.people_group_territory_a_to_lost}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_professional_a_to_lost}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_preference_a_to_lost}</pre>
        </td>
        <td>{tableData.people_group_amount_i_to_lost}</td>
        <td>
          <pre>{tableData.people_group_territory_i_to_lost}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_professional_i_to_lost}</pre>
        </td>
        <td>
          <pre>{tableData.people_group_preference_i_to_lost}</pre>
        </td>
      </tr>
    </>
  )
}

Crowd.propTypes = {
  tableData: shape({}).isRequired
}

export default Crowd
