import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { getPPBusinessUserList } from '../services'

const { Option } = Select

type PPBusinessUsersSelectProps = {
  countries: []
}

const PPBusinessUsersSelect = (props: PPBusinessUsersSelectProps) => {
  const { countries, ...restProps } = props

  const [loading, setLoading] = useState(false)
  const [ppBusinessUserList, setPPBusinessUserList] = useState([])

  const fetchPPBusinessUserList = async (countries) => {
    if (countries && countries.length) {
      await setLoading(true)
      await getPPBusinessUserList((data) => {
        if (data) {
          setPPBusinessUserList(data)
        }
      })({
        countrys: countries.join(',')
      })
      await setLoading(false)
    } else {
      setPPBusinessUserList([])
    }
  }

  useEffect(() => {
    setPPBusinessUserList([])
    fetchPPBusinessUserList(countries)
  }, [countries])

  return (
    <Select
      {...restProps}
      showSearch
      loading={loading}
      filterOption={(input, option) => {
        return (option?.children ?? '').toLowerCase().includes(input)
      }}
    >
      {ppBusinessUserList.map((ppUser) => (
        <Option
          key={ppUser.pp_user_id}
          value={`${ppUser.pp_user_id}-${ppUser.country_code}`}
        >
          {`${ppUser.email}(${ppUser.country_code})`}
        </Option>
      ))}
    </Select>
  )
}

export default PPBusinessUsersSelect
