import React, { useContext } from 'react'
import { UsergroupAddOutlined } from '@ant-design/icons'
import { PermissionButton, GridContext } from '../../../../../components'
import { setCurrentCountry } from '../../../../../store/actions/global'
import store from '../../../../../store'

interface Props {
  record: Object;
}
const Actions = ({ record }: Props) => {
  const { toggle } = useContext(GridContext)
  const onViewItems = () => {
    store.dispatch(setCurrentCountry(record.country))
    toggle(record, 'items')
  }
  return (
    <>
      <PermissionButton
        size="small"
        onClick={onViewItems}
        icon={<UsergroupAddOutlined />}
        permission="cut/record/get-list"
        type="primary"
      />
    </>
  )
}
export default Actions
