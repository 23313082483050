import React, { useEffect, useCallback, useState, useContext } from 'react'
import { Form, Row, Col, Input, Switch, DatePicker } from 'antd'
import moment from 'moment'

import {
  GridFormDrawer,
  CountrySelect,
  GridContext,
  Uploader,
  TranslatableForm
} from '../../../../../components'
import {
  getCutActivityDetail,
  updateCutActivity,
  createCutActivity
} from '../../../services'

const dateFormat = 'YYYY-MM-DD HH:mm:ss'

const ActivityForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})

  const [country, setCountry] = useState('')
  const countryChange = (value) => {
    setCountry(value)
  }

  const { onLoad, toggle } = useContext(GridContext)
  const emptyForm = () => {
    setFormData({})
    setCountry('')
    form.resetFields()
  }
  const closeDrawer = () => {
    emptyForm()
    toggle({}, '')
    onLoad()
  }

  const formatResData = useCallback((value) => {
    const newValue = Object.assign(value, {
      start_time: moment(value.start_time, dateFormat),
      end_time: moment(value.end_time, dateFormat),
      enabled: !!Number(value.enabled),
      popup_enabled: !!Number(value.popup_enabled)
    })
    setCountry(newValue.country)
    setFormData(newValue)
  }, [])

  const openHandler = (id) => {
    getCutActivityDetail(formatResData)(id)
  }

  const onFinish = (values) => {
    const formatValue = {
      country: values.country,
      title: values.title,
      start_time: values.start_time.format(dateFormat),
      end_time: values.end_time.format(dateFormat),
      // cut_price_min: values.cut_price_min,
      // cut_price_max: values.cut_price_max,
      chances: values.chances,
      chances_max: values.chances_max,
      enabled: values.enabled ? '1' : '0',
      short_description: values.short_description,
      description: values.description,
      url_key: values.url_key,
      image: values.image && values.image.path,
      first_cut_percent_start: values.first_cut_percent_start,
      first_cut_percent_end: values.first_cut_percent_end,
      extend_time: values.extend_time,
      duration: values.duration,
      discount_for_completion: values.discount_for_completion,
      people_numbers_morning: values.people_numbers_morning,
      people_numbers_morning_max: values.people_numbers_morning_max,
      people_numbers_after_morning: values.people_numbers_after_morning,
      people_numbers_after_morning_max: values.people_numbers_after_morning_max,
      help_chop_times: values.help_chop_times,
      popup_enabled: values.popup_enabled ? '1' : '0'
    }
    if (formData.id) {
      updateCutActivity(closeDrawer)({ id: formData.id, data: formatValue })
    } else {
      createCutActivity(closeDrawer)({ country, data: formatValue })
    }
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      onClose={closeDrawer}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Activity' : 'New Activity')}
    >
      <TranslatableForm entity="crowd_founding_activity">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Countries"
                name="country"
                rules={[{ required: true, message: 'Please select country' }]}
              >
                <CountrySelect onChange={countryChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="image" label="图片">
                <Uploader />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  { required: true, message: 'Please enter activity title' }
                ]}
              >
                <Input placeholder="Please enter activity title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="start_time"
                label="Start time"
                rules={[{ required: true, message: 'Please enter start time' }]}
              >
                <DatePicker showTime />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="end_time"
                label="End time"
                rules={[{ required: true, message: 'Please enter end time' }]}
              >
                <DatePicker showTime />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="url_key"
                label="Url key"
                rules={[{ required: true, message: 'Please url key' }]}
              >
                <Input placeholder="Please enter url key" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="chances"
                label="Cutting times Min"
                rules={[
                  { required: true, message: 'Please enter Cutting times Min' }
                ]}
              >
                <Input placeholder="Please enter Cutting times Min" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="chances_max"
                label="Cutting times Max"
                rules={[
                  { required: true, message: 'Please enter Cutting times Max' }
                ]}
              >
                <Input placeholder="Please enter Cutting times Max" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="first_cut_percent_start"
                label="First Cut Per Start%"
                rules={[{ required: true, message: 'First Cut Percent Start' }]}
              >
                <Input placeholder="First Cut Percent Start (%)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="first_cut_percent_end"
                label="First Cut Per End%"
                rules={[{ required: true, message: 'First Cut Percent End' }]}
              >
                <Input placeholder="First Cut Percent End (%)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="duration"
                label="Duration(hours)"
                rules={[{ required: true, message: 'Duration' }]}
              >
                <Input placeholder="Please enter share duration(hours)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="extend_time"
                label="Extend Time(hours)"
                rules={[{ required: true, message: 'extend the time' }]}
              >
                <Input placeholder="Please enter extend the time(hours)" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="discount_for_completion"
                label="Degree%:Discount%"
                rules={[{ required: false, message: 'Discount' }]}
              >
                <Input placeholder="Discount For Completion Degree" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="people_numbers_morning"
                label="People nums(9~12)-min"
                rules={[{ required: false, message: 'Number of people' }]}
              >
                <Input placeholder="Number of persons completing the period" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="people_numbers_morning_max"
                label="People nums(9~12)-max"
                rules={[{ required: false, message: 'Number of people' }]}
              >
                <Input placeholder="Number of persons completing the period" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="people_numbers_after_morning"
                label="People nums(12~24)-min"
                rules={[{ required: false, message: 'Number of people' }]}
              >
                <Input placeholder="Number of persons completing the period" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="people_numbers_after_morning_max"
                label="People nums(12~24)-max"
                rules={[{ required: false, message: 'Number of people' }]}
              >
                <Input placeholder="Number of persons completing the period" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="help_chop_times"
                label="Help Chop Times"
                rules={[{ required: true, message: 'Help Chop Times' }]}
              >
                <Input placeholder="Help Chop Times" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Enabled" name="enabled" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="PDP Popup enabled"
                name="popup_enabled"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="short_description" label="Short Description">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="description" label="Description">
                <Input.TextArea row={4} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </TranslatableForm>
    </GridFormDrawer>
  )
}

export default ActivityForm
