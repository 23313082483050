import React, { useCallback } from 'react'
import { Col, Row, Table } from 'antd'
import '../../../css/publicColection.css'

interface Props {
  columns: Array;
  dataTable: Array;
  rowSelection: Function;
  scroll: Object;
  loading: boolean;
  rowKey: any;
}
const TablePublicCollection = ({
  columns,
  dataTable,
  rowSelection,
  scroll,
  loading,
  rowKey
}: Props) => {
  const RenderTable = useCallback(() => {
    return (
      <Table
        scroll={scroll}
        rowSelection={
          rowSelection && {
            type: 'checkbox',
            ...rowSelection
          }
        }
        columns={columns}
        dataSource={dataTable}
        pagination={false}
        loading={loading}
        rowKey={rowKey}
      />
    )
  }, [dataTable, columns, loading, rowKey, scroll, rowSelection])
  return (
    <Row gutter={[0, 10]} className="table-public-collection">
      <Col span={24}>
        <RenderTable />
      </Col>
    </Row>
  )
}

export default TablePublicCollection
