import React, { useContext } from 'react'
import { AuditOutlined } from '@ant-design/icons'
import { Tooltip, Popconfirm } from 'antd'
import { PermissionButton, GridContext } from '../../../../components'
import { makeApproval } from '../../services'

interface Props {
  record: Object;
}
const Actions = ({ record }: Props) => {
  const { onLoad } = useContext(GridContext)
  const onAudit = () => {
    makeApproval(onLoad)(record.id)
  }
  return (
    <>
      {!record.audit_status && (
        <Popconfirm title="Are you sure?" onConfirm={onAudit}>
          <Tooltip title="audit">
            <PermissionButton
              style={{ marginLeft: '5px' }}
              icon={<AuditOutlined />}
              size="small"
              permission="topup/order/make-approval"
              type="primary"
            />
          </Tooltip>
        </Popconfirm>
      )}
    </>
  )
}
export default Actions
