import React from 'react'
import { shape } from 'prop-types'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

function Order(props) {
  const { tableData } = props
  return (
    <>
      {/* 订单 */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="3" className="sticky-left sticky-second">
          订单
        </th>
        <th colSpan="3">订单数量</th>
        <th colSpan="3">移动支付比例</th>
        <th colSpan="3">传音以外品牌订单占比</th>
        <th colSpan="3">Infinix以外品牌订单占比</th>
      </tr>

      {/* row2 */}
      <tr>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际订单数/去年同期实际订单数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：移动支付订单数/总订单数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：移动支付比例 /去年同期移动支付比例 -1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：传音以外总订单数/总订单数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：传音以外品牌订单占比/去年同期传音以外品牌订单占比-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：infinix以外总订单数/总订单数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：Infinix以外品牌订单占比/去年同期Infinix以外品牌订单占比-1
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.total_orders}</td>
        <td>{tableData.total_orders_budget}</td>
        <td>{tableData.total_orders_rate_year_on_year}</td>

        <td>{tableData.orders_online_paid_rate_actual}</td>
        <td>{tableData.orders_online_paid_rate_budget}</td>
        <td>{tableData.orders_online_paid_rate_rate_year_on_year}</td>

        <td>{tableData.nontranssion_orders_rate_actual}</td>
        <td>{tableData.nontranssion_orders_rate_budget}</td>
        <td>{tableData.nontranssion_orders_rate_rate_year_on_year}</td>

        <td>{tableData.noninfinix_orders_rate_actual}</td>
        <td>{tableData.noninfinix_orders_rate_budget}</td>
        <td>{tableData.noninfinix_orders_rate_rate_year_on_year}</td>
      </tr>
    </>
  )
}

Order.propTypes = {
  tableData: shape({}).isRequired
}

export default Order
