import React from 'react'
import Actions from './Actions'

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: 'Sensitive Words',
    dataIndex: 'words',
    key: 'words',
    width: 200,
    align: 'center'
  },
  {
    title: 'Remarks',
    dataIndex: 'memo',
    key: 'memo',
    align: 'center'
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center'
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    width: 180,
    align: 'center',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 15,
  total: 0
}

export const dataSourceMap = (result) => result || []
