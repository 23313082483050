import React, { useCallback, useState, useMemo, useEffect } from 'react'
import {
  Card,
  DatePicker,
  Row,
  Col,
  Typography,
  Space,
  Select,
  Button,
  Spin,
  Radio
} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import { PeopleYardDrawer } from '../../components'

import { getStaticText, storage, checkPerms } from '../../utils'
import services from '../../services'
import dateFormat from '../../config/dateFormat'
import DashboardResult from './DashboardResult/DashboardResult'
import PeopleResult from './PeopleResult/PeopleResult'
import AllGoods from './Goods/AllGoods'
import FieldResult from './FieldResult/FieldResult'
// import Countries from './Countries/Countries'

const { Text } = Typography
const { Option } = Select

// 月格式化标准
const dateFormatMonth = dateFormat.month
const dateFormatYear = dateFormat.year

// 人货场导出 编辑 权限
const exportPerm = 'comprehensive/export'
const editPerm = 'comprehensive/save'

const PeopleYard = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  // 默认请求参数
  const defaultReqData = {
    country: 'NG',
    defaultMonth: moment()
  }

  // const [isLoading, setIsLoading] = useState(false)

  // const toggleLoading = useCallback(() => {
  //   setIsLoading((prevState) => !prevState)
  // }, [])

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const dataForSearch = nextSearch.get('month_key')
  if (dataForSearch) {
    const nowDate = moment(dataForSearch, dateFormatMonth)
    if (nowDate.isValid()) {
      defaultReqData.defaultMonth = nowDate
    }
  }
  const countryForSearch = nextSearch.get('country')
  if (countryForSearch) {
    defaultReqData.country = countryForSearch
  }

  const [isLoad, setIsLoad] = useState(false)
  const [tableData, setTableData] = useState({})
  // const columns = useMemo(() => [...dailyTableColumns], [])
  const [value, setValue] = React.useState(2)

  const [requestParams, setRequestParams] = useState(() => {
    return {
      country: defaultReqData.country,
      month: defaultReqData.defaultMonth,
      month_key: defaultReqData.defaultMonth.format(dateFormatMonth),
      year: moment(defaultReqData.defaultMonth.year().toString()),
      year_key: defaultReqData.defaultMonth.format(dateFormatYear),
      format: defaultReqData.defaultMonth.format(dateFormatMonth)
    }
  })

  const countries = useMemo(() => {
    const countrys = storage.allowedCountries.val || []
    countrys.shift()
    // if (requestParams.country.length >= 1) {
    //   country.forEach((country) => {
    //     country.disabled = true
    //   })
    //   const selectCountry = country.find((item) => {
    //     return item.value === requestParams.country[0]
    //   })
    //   selectCountry.disabled = false
    // } else {
    //   country.forEach((country) => {
    //     country.disabled = false
    //   })
    // }
    return countrys
  }, [])

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      country: requestParams.country,
      month_key: requestParams.format
    }
  }, [requestParams])

  const fetchData = useCallback(() => {
    // toggleLoading()

    services.peopleYard
      .getPeopleYard(requestParamsTransformer)
      .then((result) => {
        const data = result.comprehensive || {}
        setTableData(data)
      })
      .finally(() => {
        // toggleLoading()
      })
  }, [requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  // edit drawerProps
  const [drawerProps, setDrawerProps] = useState({
    visible: false,
    placement: 'right',
    width: '40%'
  })

  const handleToggleDrawer = useCallback(() => {
    setDrawerProps((prevState) => ({
      ...prevState,
      visible: !prevState.visible
    }))
  }, [])

  const [editData, setEditData] = useState([])

  const fomatEditData = () => {
    const editArray = editData.map((item) => {
      return item.children
    })
    return editArray.flat()
  }

  const handEditDraw = useCallback(() => {
    services.peopleYard
      .editPeopleYard(requestParamsTransformer)
      .then((result) => {
        const data = result.schema || {}
        setEditData(data)
      })
      .finally(() => {
        // toggleLoading()
      })
    handleToggleDrawer()
  }, [handleToggleDrawer, requestParamsTransformer])

  const [editLoading, setEditLoading] = useState(false)

  useEffect(() => {
    if (!isLoad) {
      fetchData()
    }
    setIsLoad(true)
  }, [fetchData, isLoad])

  // 编辑成功回调
  const handleFinish = (values) => {
    setEditLoading(true)

    services.peopleYard
      .savePeopleYard({
        country: requestParamsTransformer.country,
        month_key: requestParamsTransformer.month_key,
        data: values
      })
      .then(() => {
        fetchData()
      })
      .finally(() => {
        setEditLoading(false)
        handleToggleDrawer()
      })
  }

  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country
    }))
  }

  const handleChangeDate = useCallback((date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      month: date,
      month_key: dateString,
      format: dateString
    }))
  }, [])
  const handleChangeDateYear = useCallback((date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      year: date,
      year_key: dateString,
      format: dateString
    }))
  }, [])

  const [exportLoading, setExportLoading] = useState(false)

  const handleExport = () => {
    setExportLoading(true)
    services.peopleYard
      .exportPeopleYard(requestParamsTransformer)
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
      .finally(() => {
        setExportLoading(false)
      })
  }

  const filter = () => {
    fetchData()
    setUrlSearchForState()
  }

  const onChange = (e) => {
    setValue(e.target.value)
    const year = moment(requestParams.year)
    const month = moment(requestParams.month)
    const format =
      e.target.value === 1
        ? year.format(dateFormatYear)
        : month.format(dateFormatMonth)
    setRequestParams((prevState) => ({
      ...prevState,
      month,
      month_key: month.format(dateFormatMonth),
      year,
      year_key: year.format(dateFormatYear),
      format
    }))
  }

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 250 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            // mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 40 }}>
            {getStaticText('component.search.time.text')}
          </Text>
          <Radio.Group onChange={onChange} value={value}>
            <Radio value={1}>年</Radio>
            <Radio value={2}>年-月</Radio>
          </Radio.Group>
          {value === 1 ? (
            <DatePicker
              id="date1"
              style={{ width: 150 }}
              picker="year"
              format={dateFormatYear}
              allowClear={false}
              defaultValue={requestParams.year}
              onChange={handleChangeDateYear}
            />
          ) : (
            <DatePicker
              id="date2"
              style={{ width: 150 }}
              picker="month"
              format={dateFormatMonth}
              allowClear={false}
              defaultValue={requestParams.month}
              onChange={handleChangeDate}
            />
          )}
        </Space>
        <Space style={{ cssFloat: 'right' }}>
          <Button type="primary" onClick={filter}>
            {getStaticText('component.search.button.filter')}
          </Button>
        </Space>
      </Card>

      <Card>
        <Row>
          <Col span={12}>
            <Text>{getStaticText('component.table.header.title')}</Text>
          </Col>

          <Col span={12} style={{ textAlign: 'right' }}>
            {checkPerms(exportPerm) && (
              <Button onClick={handleExport} loading={exportLoading}>
                {getStaticText('component.table.export.text')}
              </Button>
            )}

            {checkPerms(editPerm) && (
              <Button
                style={{ marginLeft: 20 }}
                type="primary"
                onClick={handEditDraw}
                loading={exportLoading}
              >
                {getStaticText('table.operating.button.text')}
              </Button>
            )}
          </Col>
        </Row>
      </Card>

      {/* <Table
        sticky
        bordered
        size="small"
        loading={isLoading}
        columns={totalReportColumns}
        dataSource={tableData}
        rowKey={(record) => record.id.value}
        scroll={{ x: 'max-content', y: 500 }}
        pagination={false}
      /> */}

      <div className="table__wrapper">
        {!Object.values(tableData).length ? (
          <Spin spinning style={{ margin: '50px 0 50px 50%' }} />
        ) : (
          <table className="custom-table center">
            <DashboardResult
              tableData={tableData}
              month={requestParamsTransformer.month_key}
              country={requestParamsTransformer.country}
            />
            <PeopleResult tableData={tableData} />
            <AllGoods tableData={tableData} />
            <FieldResult tableData={tableData} />
            {/* <Countries tableData={tableData} /> */}
          </table>
        )}
      </div>

      <PeopleYardDrawer
        title={getStaticText('table.operating.button.text')}
        drawerProps={drawerProps}
        onClose={handleToggleDrawer}
        columns={editData}
        dataSource={fomatEditData()}
        colNum={2}
        handleFinish={handleFinish}
        loading={editLoading}
      />
    </>
  )
}

PeopleYard.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default PeopleYard
