import React, { useState, useCallback, useContext } from 'react'
import { Card, Row, Col, Typography } from 'antd'
import { getStaticText } from '../../../../../utils'
import {
  GridFormDrawer,
  PagingTable,
  GridContext
} from '../../../../../components'
import { columns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getCutShareRecordList } from '../../../services'

const { Text } = Typography

const RecordList = () => {
  const [gridData, setGridData] = useState(initGridData)
  const [shareId, setShareId] = useState(0)
  const { drawerStack } = useContext(GridContext)
  const onOpen = useCallback(
    (
      record = drawerStack.length && drawerStack[drawerStack.length - 1].record
    ) => {
      getCutShareRecordList(setGridData)({ id: record.id })
      setShareId(record.id)
    },
    [drawerStack]
  )
  const paginationHandler = (current, pageSize) => {
    getCutShareRecordList(setGridData)({
      id: shareId,
      current,
      pageSize
    })
  }

  return (
    <>
      <GridFormDrawer type="items" title="砍价记录" onOpen={onOpen}>
        <Card>
          <Row>
            <Col span={24}>
              <Text>{getStaticText('component.table.header.title')}</Text>
            </Col>
          </Row>
        </Card>
        <PagingTable
          handler={paginationHandler}
          columns={columns}
          dataSource={dataSourceMap(gridData.data)}
          total={gridData.total}
          initGridData={initGridData}
          current={gridData.page}
          pageSize={gridData.page_size}
        />
      </GridFormDrawer>
    </>
  )
}

export default RecordList
