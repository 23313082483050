import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Layout, Spin } from 'antd'
import routes from './routes/routes'
import { AppHeader, SiderBar, PageContainer, AppFooter } from './components'

const App = (props) => {
  const { isLoading } = props
  return (
    <>
      <Layout>
        <SiderBar routes={routes} />
        <Layout className="layout-content">
          <Spin spinning={isLoading} size="large">
            <AppHeader />
            <PageContainer routes={routes} />
            <AppFooter />
          </Spin>
        </Layout>
      </Layout>
    </>
  )
}

const mapStateToProps = ({ global }) => ({
  isLoading: !!global.isGlobalLoading
})

App.propTypes = {
  isLoading: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(App)
