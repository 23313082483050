import { MemberGrid, PrivilegesGrid, MemberGiftGrid } from '../pages'

const membership = {
  path: '/membership',
  primary: 'app.sider.membership',
  hideGlobalTips: true,
  scopes: ['xpark'],
  routes: [
    {
      path: '/membership/user',
      modular: 'crm',
      permission: 'membership/user/get-list',
      primary: 'app.sider.membership.user',
      component: MemberGrid,
      exact: true
    },
    {
      path: '/membership/privileges',
      modular: 'crm',
      permission: 'membership/membership/privileges',
      primary: 'app.sider.membership.privileges',
      component: PrivilegesGrid,
      exact: true
    },
    {
      path: '/membership/member-gift',
      modular: 'crm',
      permission: 'membership/member-gift/get-list',
      primary: 'app.sider.membership.levellog',
      component: MemberGiftGrid,
      exact: true
    }
  ]
}
export default membership
