import React from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

interface Props {
  account: string;
}

const MyTooltip = ({ account }: Props) => {
  if (account.length > 0) {
    return (
      <>
        <Tooltip className="my-tooltip" title={() => <div>{account}</div>}>
          <QuestionCircleOutlined />
        </Tooltip>
      </>
    )
  }
  return ''
}
export default MyTooltip
