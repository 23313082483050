import React, {
  ReactNode,
  useState,
  useEffect,
  useContext,
  useCallback
} from 'react'
import { Drawer, Space, Button, Spin } from 'antd'
import { connect } from 'react-redux'
import { GridContext } from './GridContext'

interface Props {
  type: String;
  title: Function | String;
  isLoading: Boolean;
  children: ReactNode;
  onSave: (record: Object) => void;
  onOpen: (record: Object) => void;
  onClose: (record: Object) => void;
  width: String;
}
const GridFormDrawer = ({
  type,
  title,
  isLoading,
  children,
  onSave,
  onOpen,
  onClose,
  width
}: Props) => {
  const { toggle, record, drawerStack } = useContext(GridContext)
  const [toggleOpen, setToggleOpen] = useState(false)
  const onCloseDrawer = () => {
    toggle({}, '')
    if (onClose) {
      onClose(record)
    }
  }

  const isDrawerInStack = useCallback(
    (type) => {
      let exists = false
      drawerStack.forEach((x) => {
        if (type.split('|').indexOf(x.action) !== -1) {
          exists = true
        }
      })
      return exists
    },
    [drawerStack]
  )

  useEffect(() => {
    if (!toggleOpen && isDrawerInStack(type)) {
      onOpen(record)
      setToggleOpen(true)
    } else if (toggleOpen && !isDrawerInStack(type)) {
      setToggleOpen(false)
    }
  }, [isDrawerInStack, type, onOpen, record, toggleOpen, setToggleOpen])
  /* eslint-disable */
  return (
    <Drawer
      title={typeof title === 'string' ? title : title(record)}
      width={width || '80%'}
      onClose={onCloseDrawer}
      onCancel={onCloseDrawer}
      visible={toggleOpen}
      forceRender
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div style={{ textAlign: 'right' }}>
          <Space>
            <Button onClick={onCloseDrawer}>Cancel</Button>
            {onSave ? (
              <Button onClick={onSave} type="primary">
                Submit
              </Button>
            ) : null}
          </Space>
        </div>
      }
    >
      <Spin spinning={isLoading}>{children}</Spin>
    </Drawer>
  )
}

const mapStateToProps = ({ global }) => ({
  isLoading: !!global.isGlobalLoading
})

export default connect(mapStateToProps)(GridFormDrawer)
