import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getPermissionList = (handler) =>
  withTableLoading((params) =>
    services.rbac.getPermissionList(params).then(handler)
  )

export const getPermissionDetail = (handler) =>
  withGlobalLoading((id) =>
    services.rbac.getPermissionDetail({ id }).then(handler)
  )

export const deletePermission = (handler) =>
  withGlobalLoading((id) =>
    services.rbac.deletePermission({ id }).then(handler)
  )

export const createPermission = (handler) =>
  withGlobalLoading((params) =>
    services.rbac.createPermission(params).then(handler)
  )
export const updatePermission = (handler) =>
  withGlobalLoading((params) =>
    services.rbac.savePermission(params).then(handler)
  )
