import React, { useState } from 'react'
import { Button, Card, Col, DatePicker, Space, Select } from 'antd'
import moment from 'moment'
import { getStaticText } from '../../../utils'
import dateFormat from '../../../config/dateFormat'
import services from '../../../services'
import { CountryFilter } from '../../../components'

const { RangePicker } = DatePicker
const { Option } = Select
const dateFormatDay = dateFormat.day
const defaultReqData = {
  start_date: moment().subtract(1, 'months'),
  end_date: moment()
}
const pickerMap = {
  'YYYY-MM-DD': 'day',
  'YYYY-MM': 'month',
  'gggg-ww': 'week'
}

const ReportExporter = () => {
  const [baseDate, setBaseDate] = useState(() => {
    return {
      start_date: defaultReqData.start_date.format(dateFormatDay),
      start_format: defaultReqData.start_date,
      end_date: defaultReqData.end_date.format(dateFormatDay),
      end_format: defaultReqData.end_date
    }
  })
  const [report, setReport] = useState('luckyspin')
  const [rangeType, setRangeType] = useState(dateFormat.day)
  const [country, setCountry] = useState('NG')
  const [loading, setLoading] = useState(false)
  const [picker, setPicker] = useState('day')

  const handleExport = () => {
    setLoading(true)
    services.toolkit
      .exportReport({
        range_type: rangeType,
        report,
        country,
        start_date: baseDate.start_date,
        end_date: baseDate.end_date
      })
      .then((result) => {
        setLoading(false)
        const { url } = result
        window.location.href = url
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleChangeDate = (date, dateString) => {
    setBaseDate((prevState) => ({
      ...prevState,
      start_date: dateString[0],
      start_format: date[0],
      end_date: dateString[1],
      end_format: date[1],
      current: 1
    }))
  }

  return (
    <Card
      style={{
        marginBottom: 20
      }}
    >
      <Space>
        <CountryFilter
          mode="single"
          value={country}
          onChange={(value) => setCountry(value)}
        />
        <Select
          value={rangeType}
          onChange={(val) => {
            setRangeType(val)
            setPicker(pickerMap[val])
          }}
        >
          <Option value={dateFormat.day}>按日</Option>
          <Option value={dateFormat.week}>按周</Option>
          <Option value={dateFormat.month}>按月</Option>
        </Select>
        <RangePicker
          picker={picker}
          value={[baseDate.start_format, baseDate.end_format]}
          format={rangeType}
          onChange={handleChangeDate}
          allowClear={false}
        />
        <Select
          value={report}
          onChange={(val) => {
            setReport(val)
          }}
        >
          <Option value="luckyspin">注册用户来源统计</Option>
          <Option value="product_uv_and_sales">产品UV及销售</Option>
          <Option value="cut_share">砍一刀报表</Option>
          <Option value="activity">众筹活动页面</Option>
        </Select>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button onClick={handleExport} loading={loading}>
            {getStaticText('component.table.export.text')}
          </Button>
        </Col>
      </Space>
    </Card>
  )
}

export default ReportExporter
