import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { func } from 'prop-types'
import services from '../../../services'
import GoodsFrame from './GoodsFrame'
import AddPercent from '../Tool/AddPercent'
import MyTooltip from '../Tool/MyTooltip'

const requestOptionPreass = {
  legend: {
    data: []
  },
  grid: {
    show: false,
    left: '10%',
    right: '10%'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      animation: 'false'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      splitNumber: 2,
      axisLabel: { inside: true, margin: 0 },
      name: ''
    }
  ],
  series: []
}

class LeftGoodsFrame extends Component {
  constructor(props) {
    const { onRef } = props
    super(props)
    this.state = {
      goodsOpen: false,
      indecies: {
        add_to_cart: 0,
        add_to_cart_incr: '-',
        add_to_wishlist: 0,
        add_to_wishlist_incr: '-',
        jumpout_rate: '0%',
        jumpout_rate_incr: '-',
        uv: 0,
        uv_incr: '-',
        pv: 0,
        pv_incr: '-'
      },
      product_data: {
        all: 0,
        low_jumpout: 0,
        high_jumpout: 0,
        zero_paid: 0,
        flow_dropped: 0,
        purchase_dropped: 0,
        low_converted: 0
      },
      trends: {
        add_to_cart: requestOptionPreass,
        add_to_wishlist: requestOptionPreass,
        jumpout_rate: requestOptionPreass,
        product_uv: requestOptionPreass,
        product_pv: requestOptionPreass
      }
    }
    onRef(this.useEffect)
  }

  useEffect = (params) => {
    this.fetchData(params)
  }

  fetchData = (params) => {
    services.dashboard.getProductAnalystics(params).then((result) => {
      this.setState(result)
    })
  }

  render() {
    return (
      <>
        <div className="left-frame">
          <div className="title">
            <div className="title-name">
              商品分析
              <MyTooltip account="时间段内数据" />
            </div>
          </div>
          <div className="width-max">
            <div className="width-max">
              <div className="goods-box">
                <div className="flow-box-top">
                  <div className="flow-box-title">
                    加购件数
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div className="flow-box-value">
                    {this.state.indecies.add_to_cart || '0'}
                  </div>
                  <div className="flow-box-span">
                    <span>较前一日</span>
                    <span className="flow-box-value">
                      <AddPercent
                        percentString={this.state.indecies.add_to_cart_incr}
                      />
                    </span>
                  </div>
                </div>
                <ReactEcharts
                  ref={(e) => {
                    this.echartsElement = e
                  }}
                  style={{ width: '90%' }}
                  option={this.state.trends.add_to_cart}
                />
              </div>
              <div className="goods-box">
                <div className="flow-box-top">
                  <div className="flow-box-title">
                    商品收藏次数
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div className="flow-box-value">
                    {this.state.indecies.add_to_wishlist || '0'}
                  </div>
                  <div className="flow-box-span">
                    <span>较前一日</span>
                    <span className="flow-box-value">
                      <AddPercent
                        percentString={this.state.indecies.add_to_wishlist_incr}
                      />
                    </span>
                  </div>
                </div>
                <ReactEcharts
                  ref={(e) => {
                    this.echartsElement2 = e
                  }}
                  style={{ width: '90%' }}
                  option={this.state.trends.add_to_wishlist}
                />
              </div>
              <div className="goods-box">
                <div className="flow-box-top">
                  <div className="flow-box-title">
                    详情页跳出率
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div className="flow-box-value">
                    {this.state.indecies.jumpout_rate}
                  </div>
                  <div className="flow-box-span">
                    <span>较前一日</span>
                    <span className="flow-box-value">
                      <AddPercent
                        percentString={this.state.indecies.jumpout_rate_incr}
                      />
                    </span>
                  </div>
                </div>
                <ReactEcharts
                  ref={(e) => {
                    this.echartsElement3 = e
                  }}
                  style={{ width: '90%' }}
                  option={this.state.trends.jumpout_rate}
                />
              </div>
              <div className="goods-box">
                <div className="flow-box-top">
                  <div className="flow-box-title">
                    商品访客数
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div className="flow-box-value">
                    {this.state.indecies.uv || '0'}
                  </div>
                  <div className="flow-box-span">
                    <span>较前一日</span>
                    <span className="flow-box-value">
                      <AddPercent percentString={this.state.indecies.uv_incr} />
                    </span>
                  </div>
                </div>
                <ReactEcharts
                  ref={(e) => {
                    this.echartsElement4 = e
                  }}
                  style={{ width: '90%' }}
                  option={this.state.trends.product_uv}
                />
              </div>
              <div className="goods-box">
                <div className="flow-box-top">
                  <div className="flow-box-title">
                    商品浏览量
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div className="flow-box-value">
                    {this.state.indecies.pv || '0'}
                  </div>
                  <div className="flow-box-span">
                    <span>较前一日</span>
                    <span className="flow-box-value">
                      <AddPercent percentString={this.state.indecies.pv_incr} />
                    </span>
                  </div>
                </div>
                <ReactEcharts
                  ref={(e) => {
                    this.echartsElement5 = e
                  }}
                  style={{ width: '90%' }}
                  option={this.state.trends.product_pv}
                />
              </div>
            </div>
            <div className="width-max">
              <div className="goods-left">
                <div className="goods-icon">
                  <ExclamationCircleFilled
                    style={{ fontSize: '50px', color: '#ffb74f' }}
                  />
                </div>
                <div className="goods-abnormal">
                  <div className="goods-abnormal-name">
                    当前全店有异常商品
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div className="goods-abnormal-value">
                    {this.state.product_data.all}
                  </div>
                </div>
              </div>
              <div className="goods-mid">
                <div className="goods-right-box">
                  <div>
                    高跳出率
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div>{this.state.product_data.high_jumpout}</div>
                </div>
                <div className="goods-right-box">
                  <div>
                    成交下跌
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div>{this.state.product_data.purchase_dropped}</div>
                </div>
                <div className="goods-right-box">
                  <div>
                    流量下跌
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div>{this.state.product_data.flow_dropped}</div>
                </div>
                <div className="goods-right-box">
                  <div>
                    低跳出率
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div>{this.state.product_data.low_jumpout}</div>
                </div>
                <div className="goods-right-box">
                  <div>
                    支付转化率低
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div>{this.state.product_data.low_converted}</div>
                </div>
                <div className="goods-right-box">
                  <div>
                    零支付
                    <MyTooltip account="bigquery拉取" />
                  </div>
                  <div>{this.state.product_data.zero_paid}</div>
                </div>
              </div>
              <div className="goods-right">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/no-unescaped-entities */}
                <span
                  onClick={() => {
                    this.setState({ goodsOpen: true })
                  }}
                >
                  异常商品 &gt;
                </span>
              </div>
            </div>
          </div>
        </div>
        <GoodsFrame
          onClose={() => {
            this.setState({ goodsOpen: false })
          }}
          visible={this.state.goodsOpen}
          products={this.state.product_data.products}
        />
      </>
    )
  }
}

LeftGoodsFrame.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onRef: func
}

export default LeftGoodsFrame
