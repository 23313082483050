import React from 'react'

import { getStaticText } from '../../utils'

const businessDashboardOrderGmvColumns = [
  {
    title: <div>{getStaticText('dashboard.business.increment_id')}</div>,
    width: 100,
    dataIndex: 'increment_id',
    key: 'increment_id'
  },
  {
    title: <div>{getStaticText('dashboard.business.product_name')}</div>,
    width: 300,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: <div>{getStaticText('dashboard.business.price_gmv')}</div>,
    dataIndex: 'price_gmv',
    width: '10%',
    key: 'price_gmv',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.business.shipping_amount_gmv')}</div>,
    dataIndex: 'shipping_amount_gmv',
    width: '10%',
    key: 'shipping_amount_gmv',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.business.grand_total_gmv')}</div>,
    dataIndex: 'grand_total_gmv',
    width: '10%',
    key: 'grand_total_gmv',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.business.pay_method')}</div>,
    dataIndex: 'pay_method',
    width: '10%',
    key: 'pay_method'
  }
]

export default businessDashboardOrderGmvColumns
