import React, { useContext } from 'react'
import { OptionColumn, GridContext } from '../../../../components'

interface Props {
  row: Object;
}
const PointsView = ({ row }: Props) => {
  const points = parseInt(row.points, 10)
  const { toggle } = useContext(GridContext)
  return (
    <a
      aria-hidden="true"
      onClick={() => {
        toggle(row, 'view_points')
      }}
    >
      {points}
    </a>
  )
}

export const columns = [
  {
    title: 'country',
    dataIndex: 'country',
    key: 'country'
  },
  {
    title: 'Avatar',
    align: 'center',
    render: (data) => {
      return (
        <span>
          <span
            style={{
              display: 'inline-block',
              borderRadius: '50%',
              marginRight: '10px'
            }}
          >
            <img alt="" src={data.avatar} width="30" />
          </span>
        </span>
      )
    }
  },
  {
    title: 'Customer name',
    dataIndex: 'customer_name',
    key: 'customer_name'
  },
  {
    title: 'Customer email',
    dataIndex: 'customer_email',
    key: 'customer_email'
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone'
  },
  {
    title: 'XParker',
    dataIndex: 'bbs_id',
    key: 'bbs_id'
  },
  {
    title: 'magento_id',
    dataIndex: 'magento_id',
    key: 'magento_id'
  },
  {
    title: 'Membership',
    dataIndex: 'customer_group',
    key: 'customer_group',
    render: (value) => (
      <OptionColumn source="ex_customer_groups" value={value} />
    )
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    key: 'updated_at'
  },
  {
    title: 'Coins',
    key: 'points',
    render: (row) => <PointsView row={row} />
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
