import React from 'react'
import { Button, Col, Pagination, Row } from 'antd'

interface Props {
  style: Object;
  itemRender: Function;
  modalCom: Function;
  handleCheckAll: Function;
  onShowSizeChange: Function;
  onChangePage: Function;
  page: Number;
  totalPage: Number;
  totalItem: Number;
  itemSelected: Number;
  sizePage: Number;
  pageSizeOption: Array;
  localeSet: String;
}

const StickyFooter = ({
  style,
  itemRender,
  ModalCom,
  handleCheckAll,
  handleUncheckAll,
  onShowSizeChange,
  onChangePage,
  page,
  totalPage,
  totalItem,
  itemSelected,
  pageSizeOption = [],
  localeSet,
  sizePage = 10
}: Props) => {
  const styleData = {
    position: 'sticky',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: style?.padding || '20px',
    background: style?.background || '#F8F8F8',
    zIndex: style?.zIndex || 2,
    ...style
  }
  return (
    <div style={styleData}>
      <Row gutter={[5, 10]}>
        <Col span={3} style={{ paddingRight: 'unset', paddingLeft: 'unset' }}>
          <Button
            onClick={handleCheckAll}
            style={{ width: '100%', fontSize: '20px', height: '40px' }}
            type="default"
          >
            Select All
          </Button>
        </Col>
        <Col span={3} style={{ paddingRight: 'unset', paddingLeft: 'unset' }}>
          <Button
            onClick={handleUncheckAll}
            style={{ width: '100%', fontSize: '20px', height: '40px' }}
            type="default"
          >
            Deselect
          </Button>
        </Col>
        <Col span={6}>
          <Row
            align="middle"
            justify="center"
            style={{ height: '100%', fontSize: '15px' }}
          >
            {`${itemSelected || 0} Selected / ${totalItem || 0} items`}
          </Row>
        </Col>
        <Col
          span={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <Row justify="end">
            <Pagination
              size="small"
              pageSizeOptions={
                pageSizeOption?.length > 0
                  ? pageSizeOption
                  : ['10', '20', '50', '100']
              }
              showSizeChanger
              locale={localeSet ? { items_per_page: localeSet } : false}
              showQuickJumper={(e) => console.log(e)}
              onShowSizeChange={onShowSizeChange}
              totalBoundaryShowSizeChanger={(total) => `Total ${total} items`}
              defaultCurrent={1}
              total={totalPage || 0}
              itemRender={itemRender}
              onChange={onChangePage}
              current={page}
              pageSize={sizePage}
            />
          </Row>
        </Col>
        {ModalCom && <ModalCom />}
      </Row>
    </div>
  )
}

export default StickyFooter
