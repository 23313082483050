import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import { func } from 'prop-types'
import 'remixicon/fonts/remixicon.css'
import services from '../../../services'
import MyTooltip from '../Tool/MyTooltip'

const requestOptionPreass = {
  legend: {
    data: []
  },
  title: {
    left: 'left',
    text: 'GMV趋势图',
    textStyle: {
      fontSize: 12,
      color: '#aaa',
      fontWeight: 'normal'
    }
  },
  grid: {
    show: false,
    top: '20%',
    left: '10%',
    right: '10%',
    bottom: '20%'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      animation: 'false'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      splitNumber: 2,
      axisLabel: { inside: true, margin: 0 },
      name: ''
    }
  ],
  series: []
}

class LeftRealTimeFrame extends Component {
  constructor(props) {
    const { onRef } = props
    super(props)
    this.state = {
      data: {}
    }
    onRef(this.useEffect)
  }

  useEffect = (params) => {
    this.fetchData(params)
  }

  fetchData = (params) => {
    services.dashboard.getRealtime(params).then((result) => {
      this.setState({
        data: result
      })
      requestOptionPreass.xAxis[0].data = result.gmv_series.catalog
      requestOptionPreass.series = result.gmv_series.series
      this.echartsElement.getEchartsInstance().clear() // 清空画布
      this.echartsElement.getEchartsInstance().setOption(requestOptionPreass) // 实时改变
    })
  }

  render() {
    return (
      <>
        <div className="left-frame">
          <div className="title">
            <div className="title-name">
              实时指标
              <MyTooltip account="今日数据" />
            </div>
            <div className="title-icon">
              更新时间：
              {this.state.data?.realtime}
              <MyTooltip account="更新部分数据(GMV,购买用户,支付订单数)" />
            </div>
          </div>
          <div className="width-max">
            <div className="width-max">
              <div className="width-4 top-box">
                <div className="width-max icon-box">
                  <i
                    className="ri-money-dollar-circle-fill icon floatf"
                    style={{ color: '#1090FEFF' }}
                  />
                  <div className="floatf">
                    <p className="icon-box-title">
                      GMV
                      <MyTooltip account="xpark数据" />
                    </p>
                    <p className="icon-box-value">{this.state.data?.gmv}</p>
                  </div>
                </div>
                <div className="top-span-box">
                  <div className="top-span floatf">
                    <p>APP占比</p>
                    <p>{this.state.data?.app_gmv_rate}</p>
                  </div>
                  <div className="top-span floatf">
                    <p>昨日</p>
                    <p>{this.state.data?.yesterday_gmv}</p>
                  </div>
                  <div className="top-span-last floatf">
                    <p>国家平台排名</p>
                    <p>{this.state.data?.gmv_ranking}</p>
                  </div>
                </div>
              </div>
              <div className="width-3 top-box">
                <div className="width-max icon-box">
                  <i
                    className="ri-footprint-fill icon floatf"
                    style={{ color: '#19C487FF' }}
                  />
                  <div className="floatf">
                    <p className="icon-box-title">
                      UV
                      <MyTooltip account="GA获取" />
                    </p>
                    <p className="icon-box-value">{this.state.data?.uv}</p>
                  </div>
                </div>
                <div className="top-span-box">
                  <div className="top-span floatf">
                    <p>APP占比</p>
                    <p>{this.state.data?.app_uv_rate}</p>
                  </div>
                  <div className="top-span floatf">
                    <p>昨日</p>
                    <p>{this.state.data?.yesterday_uv}</p>
                  </div>
                  <div className="top-span-last floatf">
                    <p>国家平台排名</p>
                    <p>{this.state.data?.uv_ranking}</p>
                  </div>
                </div>
              </div>
              <div className="width-3 top-box">
                <div className="width-max icon-box">
                  <i
                    style={{ color: '#FEA607FF' }}
                    className="ri-account-circle-fill icon floatf"
                  />
                  <div className="floatf">
                    <p className="icon-box-title">
                      购买用户
                      <MyTooltip account="xpark数据" />
                    </p>
                    <p className="icon-box-value">{this.state.data?.buy}</p>
                  </div>
                </div>
                <div className="top-span-box">
                  <div className="top-span floatf">
                    <p>APP占比</p>
                    <p>{this.state.data?.app_buy_rate}</p>
                  </div>
                  <div className="top-span floatf">
                    <p>昨日</p>
                    <p>{this.state.data?.yesterday_buy}</p>
                  </div>
                  <div className="top-span-last floatf">
                    <p>国家平台排名</p>
                    <p>{this.state.data?.buy_ranking}</p>
                  </div>
                </div>
              </div>
              <div className="width-4 top-box">
                <ReactEcharts
                  ref={(e) => {
                    this.echartsElement = e
                  }}
                  style={{ width: '90%', height: '100%' }}
                  option={requestOptionPreass}
                />
              </div>
              <div className="width-3 top-box">
                <div className="width-max icon-box">
                  <i
                    className="ri-eye-fill icon floatf"
                    style={{ color: '#FA5B09FF' }}
                  />
                  <div className="floatf">
                    <p className="icon-box-title">
                      PV
                      <MyTooltip account="GA获取" />
                    </p>
                    <p className="icon-box-value">{this.state.data?.pv}</p>
                  </div>
                </div>
                <div className="top-span-box">
                  <div className="top-span floatf">
                    <p>APP占比</p>
                    <p>{this.state.data?.app_pv_rate}</p>
                  </div>
                  <div className="top-span floatf">
                    <p>昨日</p>
                    <p>{this.state.data?.yesterday_pv}</p>
                  </div>
                  <div className="top-span-last floatf">
                    <p>国家平台排名</p>
                    <p>{this.state.data?.pv_ranking}</p>
                  </div>
                </div>
              </div>
              <div className="width-3 top-box">
                <div className="width-max icon-box">
                  <i
                    className="ri-shopping-bag-line icon floatf"
                    style={{ color: '#FB227CFF' }}
                  />
                  <div className="floatf">
                    <p className="icon-box-title">
                      支付订单数
                      <MyTooltip account="xpark数据" />
                    </p>
                    <p className="icon-box-value">{this.state.data?.order}</p>
                  </div>
                </div>
                <div className="top-span-box">
                  <div className="top-span floatf">
                    <p>APP占比</p>
                    <p>{this.state.data?.app_order_rate}</p>
                  </div>
                  <div className="top-span floatf">
                    <p>昨日</p>
                    <p>{this.state.data?.yesterday_order}</p>
                  </div>
                  <div className="top-span-last floatf">
                    <p>国家平台排名</p>
                    <p>{this.state.data?.order_ranking}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

LeftRealTimeFrame.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onRef: func
}

export default LeftRealTimeFrame
