import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function ActivityAssess(props) {
  const { tableData } = props
  return (
    <>
      {/* 活动-履约-评价 */}
      {/* row1 */}
      <tr className="table-th-color-other">
        <th rowSpan="22" className="sticky-left">
          场
        </th>
        <th rowSpan="3" className="sticky-left sticky-second">
          活动-履约-评价
        </th>
        <th colSpan="3">
          流量结构-活动会场 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  然后从GA中拉去活动流量在所有流量的占比，APP也需要做UTM的定义，UTM
                  Manage中也需要定义APP的流量追踪链接
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          营销活动GMV &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                {/* <p>来源：UTM 活动产生的GMV数据</p> */}
                <p>来源：无数据来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          平台产品总差评率%
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：差评数/总评论数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="10">
          各类目核心商品差评率%
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：各类目核心商品差评数/总评论数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="10">
          各类目订单发货时效（天）
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：计算从订单生成到shipping发运确认在多少天之内</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="10">
          各类目订单36小时成功发货率%
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：36/72小时内成功发货订单量预算/总订单数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="10">
          各类目核心商品订单取消率%
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：订单－核心商品实际取消数/总订单</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="10">
          各类目核心商品妥投率%
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：订单－核心商品实际妥投数/总订单</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="10">
          各类目核心商品退货率 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                {/* <p> */}
                {/*  Return单数除以总订单数（PP商以拆单后的数量为准，未启用PP商之前，统计老订单数据） */}
                {/* </p> */}
                <p>来源：订单－核心商品实际退单数/总订单</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row2 */}
      <tr>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>手机</th>
        <th>配件</th>
        <th>美妆</th>
        <th>服饰</th>
        <th>鞋包配饰</th>
        <th>家电</th>
        <th>家居</th>
        <th>运动健身</th>
        <th>玩具</th>
        <th>其他</th>
        <th>手机</th>
        <th>配件</th>
        <th>美妆</th>
        <th>服饰</th>
        <th>鞋包配饰</th>
        <th>家电</th>
        <th>家居</th>
        <th>运动健身</th>
        <th>玩具</th>
        <th>其他</th>
        <th>手机</th>
        <th>配件</th>
        <th>美妆</th>
        <th>服饰</th>
        <th>鞋包配饰</th>
        <th>家电</th>
        <th>家居</th>
        <th>运动健身</th>
        <th>玩具</th>
        <th>其他</th>
        <th>手机</th>
        <th>配件</th>
        <th>美妆</th>
        <th>服饰</th>
        <th>鞋包配饰</th>
        <th>家电</th>
        <th>家居</th>
        <th>运动健身</th>
        <th>玩具</th>
        <th>其他</th>
        <th>手机</th>
        <th>配件</th>
        <th>美妆</th>
        <th>服饰</th>
        <th>鞋包配饰</th>
        <th>家电</th>
        <th>家居</th>
        <th>运动健身</th>
        <th>玩具</th>
        <th>其他</th>
        <th>手机</th>
        <th>配件</th>
        <th>美妆</th>
        <th>服饰</th>
        <th>鞋包配饰</th>
        <th>家电</th>
        <th>家居</th>
        <th>运动健身</th>
        <th>玩具</th>
        <th>其他</th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.flow_structure_activity_actual}</td>
        <td>{tableData.flow_structure_activity_budget}</td>
        <td>{tableData.flow_structure_activity_rate_year_on_year}</td>
        <td>{tableData.promotion_gmv_actual}</td>
        <td>{tableData.promotion_gmv_budget}</td>
        <td>{tableData.promotion_gmv_rate_year_on_year}</td>
        <td>{tableData.total_bad_reviews_rate_actual}</td>
        <td>{tableData.total_bad_reviews_rate_budget}</td>
        <td>{tableData.total_bad_reviews_rate_rate_year_on_year}</td>
        <td>{tableData.phone_bad_reviews_rate_actual}</td>
        <td>{tableData.accessory_bad_reviews_rate_actual}</td>
        <td>{tableData.makeups_bad_reviews_rate_actual}</td>
        <td>{tableData.cloth_bad_reviews_rate_actual}</td>
        <td>{tableData.shoes_bag_bad_reviews_rate_actual}</td>
        <td>{tableData.appliances_bad_reviews_rate_actual}</td>
        <td>{tableData.furniture_bad_reviews_rate_actual}</td>
        <td>{tableData.fitness_bad_reviews_rate_actual}</td>
        <td>{tableData.toys_bad_reviews_rate_actual}</td>
        <td>{tableData.others_bad_reviews_rate_actual}</td>

        <td>{tableData.phone_order_ship_delay_days_actual}</td>
        <td>{tableData.accessory_order_ship_delay_days_actual}</td>
        <td>{tableData.makeups_order_ship_delay_days_actual}</td>
        <td>{tableData.cloth_order_ship_delay_days_actual}</td>
        <td>{tableData.shoes_bag_order_ship_delay_days_actual}</td>
        <td>{tableData.appliances_order_ship_delay_days_actual}</td>
        <td>{tableData.furniture_order_ship_delay_days_actual}</td>
        <td>{tableData.fitness_order_ship_delay_days_actual}</td>
        <td>{tableData.toys_order_ship_delay_days_actual}</td>
        <td>{tableData.others_order_ship_delay_days_actual}</td>

        <td>{tableData.phone_order_shiped_within_supposed_rate_actual}</td>
        <td>{tableData.accessory_order_shiped_within_supposed_rate_actual}</td>
        <td>{tableData.makeups_order_shiped_within_supposed_rate_actual}</td>
        <td>{tableData.cloth_order_shiped_within_supposed_rate_actual}</td>
        <td>{tableData.shoes_bag_order_shiped_within_supposed_rate_actual}</td>
        <td>{tableData.appliances_order_shiped_within_supposed_rate_actual}</td>
        <td>{tableData.furniture_order_shiped_within_supposed_rate_actual}</td>
        <td>{tableData.fitness_order_shiped_within_supposed_rate_actual}</td>
        <td>{tableData.toys_order_shiped_within_supposed_rate_actual}</td>
        <td>{tableData.others_order_shiped_within_supposed_rate_actual}</td>

        <td>{tableData.phone_order_cancel_rate_actual}</td>
        <td>{tableData.accessory_order_cancel_rate_actual}</td>
        <td>{tableData.makeups_order_cancel_rate_actual}</td>
        <td>{tableData.cloth_order_cancel_rate_actual}</td>
        <td>{tableData.shoes_bag_order_cancel_rate_actual}</td>
        <td>{tableData.appliances_order_cancel_rate_actual}</td>
        <td>{tableData.furniture_order_cancel_rate_actual}</td>
        <td>{tableData.fitness_order_cancel_rate_actual}</td>
        <td>{tableData.toys_order_cancel_rate_actual}</td>
        <td>{tableData.others_order_cancel_rate_actual}</td>

        <td>{tableData.phone_order_dealt_rate_actual}</td>
        <td>{tableData.accessory_order_dealt_rate_actual}</td>
        <td>{tableData.makeups_order_dealt_rate_actual}</td>
        <td>{tableData.cloth_order_dealt_rate_actual}</td>
        <td>{tableData.shoes_bag_order_dealt_rate_actual}</td>
        <td>{tableData.appliances_order_dealt_rate_actual}</td>
        <td>{tableData.furniture_order_dealt_rate_actual}</td>
        <td>{tableData.fitness_order_dealt_rate_actual}</td>
        <td>{tableData.toys_order_dealt_rate_actual}</td>
        <td>{tableData.others_order_dealt_rate_actual}</td>

        <td>{tableData.phone_order_return_rate_actual}</td>
        <td>{tableData.accessory_order_return_rate_actual}</td>
        <td>{tableData.makeups_order_return_rate_actual}</td>
        <td>{tableData.cloth_order_return_rate_actual}</td>
        <td>{tableData.shoes_bag_order_return_rate_actual}</td>
        <td>{tableData.appliances_order_return_rate_actual}</td>
        <td>{tableData.furniture_order_return_rate_actual}</td>
        <td>{tableData.fitness_order_return_rate_actual}</td>
        <td>{tableData.toys_order_return_rate_actual}</td>
        <td>{tableData.others_order_return_rate_actual}</td>
      </tr>
    </>
  )
}

ActivityAssess.propTypes = {
  tableData: shape({}).isRequired
}

export default ActivityAssess
