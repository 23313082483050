import React, { useEffect, useState, useContext } from 'react'
import { Form, Row, Col, Input, Switch, DatePicker, Space } from 'antd'
import {
  GridFormDrawer,
  CountrySelect,
  GridContext
} from '../../../../../components'

import { getOrdersDetail, updateOrders } from '../services'

const { RangePicker } = DatePicker

const OrdersForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const openHandler = getOrdersDetail(setFormData)
  const { onLoad, toggle } = useContext(GridContext)
  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }
  const emptyForm = () => {
    setFormData({})
    form.resetFields()
  }
  const onFinish = (values) => {
    if (formData.id) {
      updateOrders(closeDrawer)({ data: values, id: formData.id })
    }
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Order' : 'New Order')}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="country" name="country">
              <CountrySelect />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please enter title' }]}
            >
              <Input
                style={{ width: '100%' }}
                placeholder="Please enter title"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="allowed_guest" label="Allowed Guest">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="chances" label="Chances">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Space>
              <RangePicker showTime />
            </Space>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="short_description" label="Short Description">
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="description" label="Description">
              <Input.TextArea row={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </GridFormDrawer>
  )
}
export default OrdersForm
