import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  Space,
  Typography,
  Button,
  DatePicker,
  Select,
  Table,
  Card,
  Switch
} from 'antd'
import ReactEcharts from 'echarts-for-react'
import { getStaticText, storage } from '../../utils'
import dateFormat from '../../config/dateFormat'
import '../../css/dashbord.css'
import services from '../../services'
import UpDownIcon from './Tool/UpDownIcon'
import flowDashboardUsersColumns from '../../config/tableColumns/flowDashboardUsersColumns'
import MyTooltip from './Tool/MyTooltip'

const { Text } = Typography
const { RangePicker } = DatePicker

const requestParams = {
  topKey: 'uv',
  key: 'category_sales_map',
  keyTable: 'buy_user_map',
  device: 'All_Device',
  duration: 'day',
  country: 'All_Country',
  diskCatalogId: ['0'],
  brand: ['0'],
  switchKey: 'usd',
  start_date: moment().subtract('day', 7),
  end_date: moment().subtract('day', 1)
}

const filterDuration = [
  { value: 'day', label: '按日' },
  { value: 'week', label: '按周' },
  { value: 'month', label: '按月' }
]

const deviceList = [
  { disabled: false, label: '所有平台', value: 'All_Device' },
  { disabled: false, label: 'App', value: 'app' },
  { disabled: false, label: 'Web', value: 'web' }
]

const requestOptionLine = {
  legend: {
    data: []
  },
  grid: {
    show: false,
    top: 50,
    left: 80,
    bottom: 50,
    right: 80
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      animation: 'false'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      name: ''
    },
    {
      name: ''
    }
  ],
  series: []
}

const requestOptionBar = {
  legend: {
    data: []
  },
  grid: {
    show: false,
    top: 50,
    left: 80
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      animation: 'false'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      name: ''
    }
  ],
  series: []
}

const requestOptionPie = {
  title: {
    text: '',
    x: 'right',
    y: 'bottom',
    textStyle: {
      fontSize: 12
    }
  },
  tooltip: {
    trigger: 'item',
    formatter(d) {
      return `${d.name} : ${d.value.toLocaleString()} (${d.percent}%)`
    }
  },
  series: {
    name: '',
    type: 'pie',
    radius: '50%',
    avoidLabelOverlap: true,
    data: []
  }
}

const mapPie = {
  name: '',
  type: 'pie',
  radius: '50%',
  avoidLabelOverlap: true
}

const requestOptionFunnel = {
  backgroundColor: '#ffffff',
  series: [
    {
      top: 0,
      type: 'funnel',
      height: '350',
      minSize: 150,
      left: 10,
      gap: 15,
      width: '60%',
      z: 3,
      label: {
        show: true,
        position: 'inside',
        fontSize: '14',
        formatter(d) {
          return `${d.name}{aa|} \n${d.data.value.toLocaleString()}`
        },
        rich: {
          aa: {
            padding: [8, 0, 6, 0]
          }
        }
      },
      data: []
    },
    {
      top: 0,
      type: 'funnel',
      height: '350',
      gap: 15,
      itemStyle: {
        color: 'rgba(233, 233, 233, 0.5)'
      },
      minSize: 150,
      left: 100,
      width: '60%',
      z: 2,
      label: {
        normal: {
          position: 'insideRight',
          padding: [11, 25],
          formatter(d) {
            return `{aa|${d.data.percent}}`
          },
          rich: {
            aa: {
              align: 'center',
              color: '#333',
              fontSize: '12'
            }
          }
        }
      },
      data: []
    }
  ]
}

class DashboardFlow extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    const duration = nextSearch.get('duration')
      ? nextSearch.get('duration')
      : requestParams.duration
    const dateFormatDuration = this.getDateFormatDuration(duration)
    const dateFormatDurationTable = this.getDateFormatDuration(
      requestParams.duration
    )
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatDuration)
      : requestParams.start_date
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatDuration)
      : requestParams.end_date
    const country = nextSearch.get('country')
      ? nextSearch.get('country')
      : requestParams.country
    const brand = nextSearch.get('brand')
      ? nextSearch.get('brand').split(',')
      : requestParams.brand
    const diskCatalogId = nextSearch.get('diskCatalogId')
      ? nextSearch.get('diskCatalogId').split(',')
      : requestParams.diskCatalogId
    const device = nextSearch.get('device')
      ? nextSearch.get('device')
      : requestParams.device

    this.state = {
      isLoading: false,
      nameList: [],
      diskCatalogId,
      countries: this.getCountrys(),
      country,
      duration,
      durationTable: requestParams.duration,
      device,
      brand,
      checkList: [],
      disk_catalog: this.checkDiskCatalogList(diskCatalogId),
      brandList: [],
      start_date: startDate,
      start_format: startDate.format(dateFormatDuration),
      end_date: endDate,
      end_format: endDate.format(dateFormatDuration),
      start_date_table: requestParams.start_date,
      start_format_table: requestParams.start_date.format(
        dateFormatDurationTable
      ),
      end_date_table: requestParams.end_date,
      end_format_table: requestParams.end_date.format(dateFormatDurationTable),
      dateFormatDuration,
      dateFormatDurationTable,
      dataTop: {},
      countryTable: requestParams.country,
      deviceTable: requestParams.device,
      topKey: requestParams.topKey,
      topKeyTable: requestParams.topKey
    }
    this.getBrandReportsCatalog()
  }

  componentDidMount() {
    this.useEffect()
    this.useEffectTable()
  }

  getCountrys = () => {
    const countrys = storage.allowedCountries.val
    countrys.forEach((country) => {
      if (country.value === 'All_Country') {
        country.label = '所有站点'
      }
    })
    return countrys
  }

  getBrandReportsCatalog = () => {
    services.brand.getBrandReportsCatalog().then((result) => {
      this.setState(
        (prevState) => ({
          catalogs: result.catalog_list,
          brandList: this.checkBrands(prevState.brand, result.catalog_list)
        }),
        () => {
          this.useEffect()
        }
      )
    })
  }

  getDateFormatDuration = (duration = requestParams.duration) => {
    return dateFormat[duration]
  }

  getrequest = () => ({
    country: this.state.country,
    device: this.state.device,
    duration: this.state.duration,
    start_date: this.state.start_format,
    end_date: this.state.end_format
  })

  getrequestTable = (keyWords = '') => ({
    country: this.state.countryTable,
    device: this.state.deviceTable,
    brand: this.state.brand.toString(),
    diskCatalogId: this.state.diskCatalogId.toString(),
    duration: this.state.durationTable,
    start_date: this.state.start_format_table,
    end_date: this.state.end_format_table,
    checkList: this.state.checkList,
    keyWords,
    current: this.state.current,
    pageSize: this.state.pageSize
  })

  handleDateChange = (date, dateString) => {
    this.setState({
      start_date: date[0],
      start_format: dateString[0],
      end_date: date[1],
      end_format: dateString[1]
    })
  }

  handleDateChangeTable = (date, dateString) => {
    this.setState({
      start_date_table: date[0],
      start_format_table: dateString[0],
      end_date_table: date[1],
      end_format_table: dateString[1]
    })
  }

  handleDurationChange = (duration) => {
    const dateFormatDuration = this.getDateFormatDuration(duration)
    this.setState({
      duration,
      dateFormatDuration,
      start_date: requestParams.start_date,
      start_format: requestParams.start_date.format(dateFormatDuration),
      end_date: requestParams.end_date,
      end_format: requestParams.end_date.format(dateFormatDuration)
    })
  }

  handleCountryChange = (country) => {
    this.setState({
      country
    })
  }

  handleDeviceChange = (device) => {
    this.setState({
      device
    })
  }

  handleCountryChangeTable = (countryTable) => {
    this.setState({
      countryTable
    })
  }

  handleDeviceChangeTable = (deviceTable) => {
    this.setState({
      deviceTable
    })
  }

  handleDurationChangeTable = (durationTable) => {
    const dateFormatDurationTable = this.getDateFormatDuration(durationTable)
    this.setState({
      durationTable,
      dateFormatDurationTable,
      start_date_table: requestParams.start_date,
      start_format_table: requestParams.start_date.format(
        dateFormatDurationTable
      ),
      end_date_table: requestParams.end_date,
      end_format_table: requestParams.end_date.format(dateFormatDurationTable)
    })
  }

  handleCurrentChange = (current, pageSize) => {
    this.setState(
      {
        current,
        pageSize
      },
      () => {
        this.useEffectTable()
      }
    )
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchData()
  }

  useEffectTable = () => {
    this.fetchDataTable()
  }

  checkBrands = (brand, catalogs = []) => {
    if ((this.state && this.state.catalogs?.[0]) || catalogs[0]) {
      catalogs = catalogs[0] ? catalogs : this.state.catalogs
      const brandList = catalogs[0].map((catalog) => {
        return {
          label: catalog.title,
          value: catalog.title,
          disabled: false
        }
      })
      brandList.unshift({
        label: '全部',
        value: '0',
        disabled: false
      })
      if (brand.indexOf('0') >= 0) {
        brandList.forEach((brand) => {
          brand.disabled = true
        })
        brandList[0].disabled = false
      } else if (brand.length === 0) {
        brandList.forEach((brand) => {
          brand.disabled = false
        })
      } else {
        brandList[0].disabled = true
      }
      return brandList
    }
    return []
  }

  fetchData = () => {
    services.dashboard.getFlowTop(this.getrequest()).then((result) => {
      const dataTop = {
        funnel_table_data: result.funnel_table_data,
        uv: {
          today: result.uv,
          last_day: result.last_uv,
          mapLine: {
            ...requestOptionLine,
            xAxis: [
              {
                data: result.uv_map_data.catalog
              }
            ],
            series: result.uv_map_data.series
          },
          deviceBar: {
            ...requestOptionBar,
            xAxis: [
              {
                data: result.device_uv_line_map_data.catalog
              }
            ],
            series: result.device_uv_line_map_data.series
          },
          devicePie: {
            ...requestOptionPie,
            series: {
              ...mapPie,
              data: result.device_uv_pie_map_data
            }
          }
        },
        pv: {
          today: result.pv,
          last_day: result.last_pv,
          mapLine: {
            ...requestOptionLine,
            xAxis: [
              {
                data: result.pv_map_data.catalog
              }
            ],
            series: result.pv_map_data.series
          },
          deviceBar: {
            ...requestOptionBar,
            xAxis: [
              {
                data: result.device_pv_line_map_data.catalog
              }
            ],
            series: result.device_pv_line_map_data.series
          },
          devicePie: {
            ...requestOptionPie,
            series: {
              ...mapPie,
              data: result.device_pv_pie_map_data
            }
          }
        }
      }

      this.use_time_map_data.getEchartsInstance().clear() // 清空画布
      this.use_time_map_data.getEchartsInstance().setOption({
        ...requestOptionLine,
        xAxis: [
          {
            data: result.use_time_map_data.catalog
          }
        ],
        series: result.use_time_map_data.series
      })
      this.use_time_map_data.getEchartsInstance().resize()

      const optionFunnel = {
        ...requestOptionFunnel
      }
      // optionFunnel.legend.data = result?.funnel_map_data?.legend
      optionFunnel.series[0].data = result?.funnel_map_data?.value_data
      optionFunnel.series[1].data = result?.funnel_map_data?.value_data
      this.funnel_map_data.getEchartsInstance().clear() // 清空画布
      this.funnel_map_data.getEchartsInstance().setOption(optionFunnel)
      this.funnel_map_data.getEchartsInstance().resize()

      this.setState(
        {
          dataTop
        },
        () => {
          this.topSwitchTab('default')
          this.topSwitchTabTable('default')
        }
      )
    })
  }

  fetchDataTable = () => {
    services.dashboard.getFlowGoods(this.getrequestTable()).then((result) => {
      this.goods_uv_map_data.getEchartsInstance().clear() // 清空画布
      this.goods_uv_map_data.getEchartsInstance().setOption({
        ...requestOptionLine,
        xAxis: [
          {
            data: result.goods_uv_map_data.catalog
          }
        ],
        series: result.goods_uv_map_data.series
      })
      this.goods_uv_map_data.getEchartsInstance().resize()

      this.goods_pv_map_data.getEchartsInstance().clear() // 清空画布
      this.goods_pv_map_data.getEchartsInstance().setOption({
        ...requestOptionLine,
        xAxis: [
          {
            data: result.goods_pv_map_data.catalog
          }
        ],
        series: result.goods_pv_map_data.series
      })
      this.goods_pv_map_data.getEchartsInstance().resize()
    })
  }

  onSearch = (keyWords) => {
    if (keyWords) {
      services.dashboard
        .getBusinessGoodsNameList(this.getrequestTable(keyWords))
        .then((result) => {
          this.setState({
            nameList: result
          })
        })
    } else {
      this.setState({
        nameList: []
      })
    }
  }

  changeHandlerName = (checkList) => {
    this.setState({
      checkList
    })
  }

  handlediskCatalogChange = (diskCatalogId) => {
    this.setState({
      diskCatalogId,
      disk_catalog: this.checkDiskCatalogList(diskCatalogId)
    })
  }

  checkDiskCatalogList = (diskCatalogId) => {
    const diskCatalogList = storage.disk_catalog_list.val
    if (diskCatalogId.indexOf('0') >= 0) {
      diskCatalogList.forEach((diskCatalog) => {
        diskCatalog.disabled = true
      })
      diskCatalogList[0].disabled = false
    } else if (diskCatalogId.length === 0) {
      diskCatalogList.forEach((diskCatalog) => {
        diskCatalog.disabled = false
      })
    } else {
      diskCatalogList[0].disabled = true
    }
    return diskCatalogList
  }

  handleBrandChange = (brand) => {
    this.setState({
      brand,
      brandList: this.checkBrands(brand)
    })
  }

  topSwitchTab = (key) => {
    let { topKey } = this.state
    if (key !== 'default') {
      topKey = key ? 'pv' : 'uv'
      this.setState({
        topKey
      })
    }
    this.mapLine.getEchartsInstance().clear() // 清空画布
    this.mapLine
      .getEchartsInstance()
      .setOption(this.state.dataTop?.[topKey]?.mapLine)
    this.mapLine.getEchartsInstance().resize()
  }

  topSwitchTabTable = (key) => {
    let { topKeyTable } = this.state
    if (key !== 'default') {
      topKeyTable = key ? 'pv' : 'uv'
      this.setState({
        topKeyTable
      })
    }
    this.devicePie.getEchartsInstance().clear() // 清空画布
    this.devicePie
      .getEchartsInstance()
      .setOption(this.state.dataTop?.[topKeyTable]?.devicePie)
    this.devicePie.getEchartsInstance().resize()

    this.deviceBar.getEchartsInstance().clear() // 清空画布
    this.deviceBar
      .getEchartsInstance()
      .setOption(this.state.dataTop?.[topKeyTable]?.deviceBar)
    this.deviceBar.getEchartsInstance().resize()
  }

  render() {
    const options = this.state.nameList.map((item) => (
      <Select.Option key={item}>{item}</Select.Option>
    ))
    return (
      <>
        <Card>
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 220 }}
              value={this.state.country}
              onChange={this.handleCountryChange}
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.device.text')}
            </Text>
            <Select
              style={{ width: 220 }}
              value={this.state.device}
              onChange={this.handleDeviceChange}
              maxTagCount="responsive"
            >
              {deviceList.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <Select
              style={{ width: 80 }}
              value={this.state.duration}
              onChange={this.handleDurationChange}
              maxTagCount="responsive"
            >
              {filterDuration.map((item) => (
                <Select.Option key={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
            <RangePicker
              picker={this.state.duration}
              value={[this.state.start_date, this.state.end_date]}
              format={this.state.dateFormatDuration}
              onChange={this.handleDateChange}
              allowClear={false}
            />
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        <Space
          style={{
            marginTop: 50,
            overflow: 'hidden',
            display: 'block',
            width: '100%'
          }}
        >
          <div className="business-top">
            <div className="business-box floatf">
              <div className="business-title">
                总体流量
                <MyTooltip account="GA获取" />
                <Switch
                  className="business-title-switch"
                  checkedChildren="PV"
                  unCheckedChildren="UV"
                  onChange={this.topSwitchTab}
                />
              </div>
              <div className="business-gmv">
                <span className="business-today-gmv">
                  {this.state.dataTop?.[this.state.topKey]?.today}
                </span>
                <UpDownIcon
                  current={this.state.dataTop?.[this.state.topKey]?.today}
                  last={this.state.dataTop?.[this.state.topKey]?.last_day}
                />
              </div>
              <ReactEcharts
                style={{ width: '100%', height: '80%' }}
                ref={(e) => {
                  this.mapLine = e
                }}
                option={
                  this.state.dataTop?.[this.state.topKey]?.mapLine ||
                  requestOptionLine
                }
              />
            </div>
            <div className="business-box floatr">
              <div className="business-title">
                留存分析
                <MyTooltip account="GA获取" />
              </div>
              <ReactEcharts
                ref={(e) => {
                  this.use_time_map_data = e
                }}
                style={{ width: '100%', height: '90%' }}
                option={requestOptionLine}
              />
            </div>
          </div>
          <div className="business-mid">
            <div
              className="business-box floatf"
              style={{
                position: 'relative',
                overflow: 'hidden'
              }}
            >
              <div className="business-title">
                转化漏斗
                <MyTooltip account="GA获取" />
              </div>
              <div
                style={{ width: '100%', height: '350px', margin: '50px 0px' }}
              >
                <ReactEcharts
                  style={{ width: '100%', height: '100%' }}
                  ref={(e) => {
                    this.funnel_map_data = e
                  }}
                  option={requestOptionFunnel}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  position: 'absolute',
                  bottom: 0
                }}
              >
                <Table
                  style={{ width: '100%' }}
                  size="small"
                  bordered
                  loading={this.state.isLoading}
                  columns={flowDashboardUsersColumns}
                  dataSource={this.state.dataTop?.funnel_table_data || []}
                  rowKey={(record) => record.name}
                  scroll={{ x: 'max-content', y: true }}
                  sticky
                  pagination={false}
                />
              </div>
            </div>
            <div className="business-box floatr">
              <div className="business-title">
                流量来源分析
                <MyTooltip account="GA获取" />
                <Switch
                  className="business-title-switch"
                  checkedChildren="PV"
                  unCheckedChildren="UV"
                  onChange={this.topSwitchTabTable}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  height: '350px',
                  marginBottom: '50px'
                }}
              >
                <ReactEcharts
                  style={{ width: '100%', height: '100%' }}
                  ref={(e) => {
                    this.devicePie = e
                  }}
                  option={
                    this.state.dataTop?.[this.state.topKeyTable]?.devicePie ||
                    requestOptionPie
                  }
                />
              </div>
              <ReactEcharts
                style={{ width: '100%', height: 350 }}
                ref={(e) => {
                  this.deviceBar = e
                }}
                option={
                  this.state.dataTop?.[this.state.topKeyTable]?.deviceBar ||
                  requestOptionBar
                }
              />
            </div>
          </div>
        </Space>
        <Card>
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 220 }}
              value={this.state.countryTable}
              onChange={this.handleCountryChangeTable}
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.device.text')}
            </Text>
            <Select
              style={{ width: 220 }}
              value={this.state.deviceTable}
              onChange={this.handleDeviceChangeTable}
              maxTagCount="responsive"
            >
              {deviceList.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.brand.text')}
            </Text>
            <Select
              style={{ width: 120 }}
              value={this.state.brand}
              onChange={this.handleBrandChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.brandList.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.category_type.text')}
            </Text>
            <Select
              style={{ width: 120 }}
              value={this.state.diskCatalogId}
              onChange={this.handlediskCatalogChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.disk_catalog.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text>{getStaticText('component.search.time.text')}</Text>
            <Select
              style={{ width: 80 }}
              value={this.state.durationTable}
              onChange={this.handleDurationChangeTable}
              maxTagCount="responsive"
            >
              {filterDuration.map((item) => (
                <Select.Option key={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
            <RangePicker
              picker={this.state.durationTable}
              value={[this.state.start_date_table, this.state.end_date_table]}
              format={this.state.dateFormatDurationTable}
              onChange={this.handleDateChangeTable}
              allowClear={false}
            />
          </Space>
          <Space
            style={{
              marginTop: 20,
              width: '100%'
            }}
          >
            <Text>{getStaticText('goods.search.goods_name')}</Text>
            <Select
              style={{ width: 500 }}
              showSearch
              value={this.state.checkList}
              placeholder={getStaticText('goods.search.goods_name')}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={this.onSearch}
              onChange={this.changeHandlerName}
              notFoundContent={null}
              mode="multiple"
            >
              {options}
            </Select>
            <Button
              style={{ float: 'right' }}
              type="primary"
              onClick={this.useEffectTable}
            >
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        <Space
          style={{
            display: 'block',
            width: '100%'
          }}
        >
          <div className="business-mid-box">
            <div className="business-title">
              uv
              <MyTooltip account="GA获取" />
            </div>
            <ReactEcharts
              style={{ width: '100%', height: '300px', marginTop: '30px' }}
              ref={(e) => {
                this.goods_uv_map_data = e
              }}
              option={requestOptionLine}
            />
          </div>
          <div className="business-mid-box">
            <div className="business-title">
              pv
              <MyTooltip account="GA获取" />
            </div>
            <ReactEcharts
              style={{ width: '100%', height: '300px', marginTop: '30px' }}
              ref={(e) => {
                this.goods_pv_map_data = e
              }}
              option={requestOptionLine}
            />
          </div>
        </Space>
      </>
    )
  }
}

DashboardFlow.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default DashboardFlow
