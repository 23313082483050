import React from 'react'

import { Button, Space } from 'antd'
import { getStaticText } from '../../../utils'

interface Props {
  onSubmit: Function;
}

const SubmitButton = ({ onSubmit }: Props) => (
  <Space style={{ cssFloat: 'right' }}>
    <Button type="primary" onClick={onSubmit}>
      {getStaticText('component.search.button.filter')}
    </Button>
  </Space>
)

export default SubmitButton
