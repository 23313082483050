import React from 'react'
import { Button } from 'antd'

interface Props {
  title: String;
  functionParent: Function;
  type: String;
}
const WhiteButton = ({ title, functionParent, type = 'default' }: Props) => {
  return (
    <Button
      onClick={functionParent || false}
      size="large"
      type={type}
      style={{
        background: 'white',
        color: 'black',
        width: '100%',
        fontSize: '15px'
      }}
    >
      {title}
    </Button>
  )
}

export default WhiteButton
