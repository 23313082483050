import React, { useState, useEffect } from 'react'
import { Select } from 'antd'
import { getRoleList } from '../services'

const { Option } = Select

export default (props) => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getRoleList((data) => {
      setOptions(data.data)
      setLoading(false)
    })({ pageSize: 9999 })
  }, [setOptions, setLoading])

  return (
    <Select {...props} loading={loading}>
      {options.map((option) => {
        return (
          <Option key={option.id} value={option.id}>
            {option.role_alias || option.role_name}
          </Option>
        )
      })}
    </Select>
  )
}
