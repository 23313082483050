import React, { useContext } from 'react'
import {
  EditOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
  LineChartOutlined
} from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { PermissionButton, GridContext } from '../../../../../components'
import { deleteCutActivity } from '../../../services'

interface Props {
  record: Object;
}
const Actions = ({ record }: Props) => {
  const { toggle, onLoad } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit')
  }
  const onDelete = () => {
    deleteCutActivity(onLoad)(record.id)
  }
  const toggleProducts = () => {
    toggle(record, 'cut_activity_products')
  }
  const toggleStatistics = () => {
    toggle(record, 'cut_statistics')
  }

  return (
    <>
      <PermissionButton
        size="small"
        onClick={onEdit}
        icon={<EditOutlined />}
        permission="cut/activity/save"
        type="primary"
      />
      <PermissionButton
        style={{ marginLeft: '5px' }}
        size="small"
        onClick={toggleProducts}
        icon={<UnorderedListOutlined />}
        permission="cut/activity/get-products"
        type="primary"
      />
      <PermissionButton
        style={{ marginLeft: '5px' }}
        size="small"
        onClick={toggleStatistics}
        icon={<LineChartOutlined />}
        permission="cut/activity/get-statistics"
        type="primary"
      />
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <PermissionButton
          style={{
            marginLeft: '5px',
            backgroundColor: 'red',
            borderColor: 'red'
          }}
          icon={<DeleteOutlined />}
          size="small"
          permission="cut/activity/delete"
          type="primary"
        />
      </Popconfirm>
    </>
  )
}
export default Actions
