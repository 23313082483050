import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getBannerList = (handler) =>
  withTableLoading((params) => services.bbs.getBannerList(params).then(handler))

export const deleteBanner = (handler) =>
  withGlobalLoading((id) => services.bbs.deleteBanner(id).then(handler))

export const createBanner = (handler) =>
  withGlobalLoading((params) => services.bbs.createBanner(params).then(handler))

export const saveBanner = (handler) =>
  withGlobalLoading((params) => services.bbs.saveBanner(params).then(handler))
