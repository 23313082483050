import {
  LuckySpinActivityGrid,
  ActivityGrid,
  OrdersGrid,
  RewriteGrid,
  CrowdFundingCategoryGrid
} from '../pages'

const system = {
  path: '/system',
  primary: 'app.sider.system',
  hideGlobalTips: true,
  routes: [
    {
      path: '/luckyspin',
      primary: 'app.sider.luckyspin',
      scopes: ['xpark'],
      routes: [
        {
          path: '/system/luckyspin/activity',
          modular: 'market',
          permission: 'luckyspin/activity/get-list',
          primary: 'app.sider.luckyspin.activity',
          component: LuckySpinActivityGrid,
          exact: true
        }
      ]
    },
    {
      path: '/crowd-funding',
      primary: 'app.sider.system.crowd-funding',
      routes: [
        {
          path: '/system/crowd-funding/activity',
          modular: 'market',
          permission: 'crowd-founding/activity/get-list',
          primary: 'app.sider.crowd-funding.activity',
          component: ActivityGrid,
          exact: true
        },
        {
          path: '/system/crowd-funding/category',
          modular: 'market',
          permission: 'crowd-founding/category/get-list',
          primary: 'app.sider.crowd-funding.category',
          component: CrowdFundingCategoryGrid,
          exact: true
        },
        {
          path: '/system/crowd-funding/orders',
          modular: 'market',
          permission: 'crowd-founding/order/get-list',
          primary: 'app.sider.crowd-funding.orders',
          component: OrdersGrid,
          exact: true
        }
      ]
    },
    {
      path: '/system/rewrite',
      modular: 'market',
      permission: 'luckyspin/rewrite/get-list',
      primary: 'app.sider.system.rewrite',
      component: RewriteGrid,
      exact: true
    }
  ]
}

export default system
