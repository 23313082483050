import React, { useState, useMemo, useCallback, useEffect } from 'react'
import {
  Table,
  Card,
  Space,
  Typography,
  DatePicker,
  Row,
  Col,
  Select,
  Button
} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import services from '../../services'
import { getStaticText, storage } from '../../utils'
import dateFormat from '../../config/dateFormat'
import { reviewTableColumns } from '../../config/tableColumns'

const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

// 日格式化标准
const dateFormatDay = dateFormat.day

const ReviewList = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  // 默认请求参数
  const defaultReqData = {
    country: 'All_Country',
    date: moment().subtract(1, 'day'),
    type: 3,
    current: 1,
    pageSize: 20,
    rating: '',
    endDate: moment(),
    viewStar: [
      {
        title: '1',
        id: 1
      },
      {
        title: '2',
        id: 2
      },
      {
        title: '3',
        id: 3
      },
      {
        title: '4',
        id: 4
      },
      {
        title: '5',
        id: 5
      }
    ]
  }

  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const columns = useMemo(() => [...reviewTableColumns], [])

  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  const [tableData, setTableData] = useState([])

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const dataForSearch = nextSearch.get('date')
    ? moment(nextSearch.get('date'), dateFormatDay)
    : null
  const endDate = nextSearch.get('date')
    ? moment(nextSearch.get('endDate'), dateFormatDay)
    : null
  const nextSearchObj = () => {
    return {
      country: nextSearch.get('country'),
      rating: nextSearch.get('rating'),
      type: nextSearch.get('type'),
      current: nextSearch.get('current'),
      pageSize: nextSearch.get('pageSize'),
      date: dataForSearch,
      endDate
    }
  }
  Object.keys(nextSearchObj()).forEach((item) => {
    if (nextSearchObj()[item]) defaultReqData[item] = nextSearchObj()[item]
  })

  const [requestParams, setRequestParams] = useState(() => {
    return {
      country: defaultReqData.country
        .split(',')
        .filter((item) => !(item === '')),
      rating: defaultReqData.rating.split(',').filter((item) => !(item === '')),
      type: defaultReqData.type,
      date: defaultReqData.date,
      format: defaultReqData.date.format(dateFormatDay),
      endDate: defaultReqData.endDate,
      end_format: defaultReqData.endDate.format(dateFormatDay),
      current: defaultReqData.current,
      pageSize: defaultReqData.pageSize,
      viewStar: defaultReqData.viewStar
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      country: requestParams.country.toString(),
      rating: requestParams.rating.toString(),
      date: requestParams.format,
      endDate: requestParams.end_format,
      type: requestParams.type,
      current: requestParams.current * 1,
      pageSize: requestParams.pageSize * 1
    }
  }, [requestParams])

  const fetchData = useCallback(() => {
    toggleLoading()
    services.review
      .getReviewDetail(requestParamsTransformer)
      .then((result) => {
        const data = result.review_details || []
        setTableData(data)
        setTotal(result.total)
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  // countries 判断 disabled 属性
  const countries = useMemo(() => {
    const country = storage.allowedCountries.val || []
    if (requestParams.country.includes('All_Country')) {
      country.forEach((country) => {
        country.disabled = true
      })
      country[0].disabled = false
    } else if (requestParams.country.length === 0) {
      country.forEach((country) => {
        country.disabled = false
      })
    } else {
      country[0].disabled = true
    }
    return country
  }, [requestParams])

  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country
    }))
  }

  const handleViewStarChange = (viewStar) => {
    setRequestParams((prevState) => ({
      ...prevState,
      rating: viewStar
    }))
  }

  useEffect(() => {
    setTotal(0)
    fetchData()
    setUrlSearchForState()
  }, [fetchData, setUrlSearchForState])

  const handleCurrentChange = (current, pageSize) => {
    setRequestParams((prevState) => ({
      ...prevState,
      current,
      pageSize
    }))
  }

  const handleDateChange = (date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      date: date[0],
      format: dateString[0],
      endDate: date[1],
      end_format: dateString[1],
      current: 1
    }))
  }

  const handleReset = () => {
    setRequestParams({
      country: ['All_Country'],
      rating: '',
      type: 3,
      date: moment().subtract(1, 'day'),
      format: moment().subtract(1, 'day').format(dateFormatDay),
      endDate: moment(),
      end_format: moment().format(dateFormatDay),
      current: 1,
      pageSize: 20
    })
  }

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.time.text')}
          </Text>
          <RangePicker
            style={{ width: 220 }}
            picker="day"
            value={[requestParams.date, requestParams.endDate]}
            format={dateFormatDay}
            onChange={handleDateChange}
            allowClear={false}
          />
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.review.text')}
          </Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.rating}
            onChange={handleViewStarChange}
          >
            {defaultReqData.viewStar.map((item) => (
              <Option key={item.id} value={item.title} disabled={item.disabled}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Space>
        <Space style={{ float: 'right' }}>
          <Button type="primary" onClick={handleReset}>
            {getStaticText('component.search.button.reset')}
          </Button>
        </Space>
      </Card>
      <Card>
        <Row>
          <Col span={12}>
            <Text>{getStaticText('component.table.header.title')}</Text>
          </Col>
        </Row>
      </Card>
      <Table
        sticky
        bordered
        size="small"
        loading={isLoading}
        columns={columns}
        dataSource={tableData}
        rowKey={(record) => record.review_id}
        scroll={{ x: 'max-content', y: true }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          showQuickJumper: true,
          total,
          showTotal: () => `共${total}条数据`,
          pageSize: requestParamsTransformer.pageSize,
          current: requestParamsTransformer.current,
          onChange: handleCurrentChange
        }}
      />
    </>
  )
}

ReviewList.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default ReviewList
