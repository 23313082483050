import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Space, Card, Typography, Button, DatePicker, Table } from 'antd'
import { getStaticText } from '../../../utils'
import services from '../../../services'
import dateFormat from '../../../config/dateFormat'
import { yearGroupColumns } from '../../../config/tableColumns'

const dateFormatMonth = dateFormat.month
const { Text } = Typography
const { RangePicker } = DatePicker

const requestParams = {
  start_date: moment('2021-01-01', dateFormatMonth),
  end_date: moment('2021-12-01', dateFormatMonth)
}

const requestOptionPreass = {
  grid: {
    bottom: 40,
    top: 40,
    left: 40,
    containLabel: true
  },
  toolbox: {
    show: true,
    feature: {
      dataView: {
        readOnly: false,
        title: '编辑'
      },
      restore: {
        show: true,
        title: '重置'
      },
      saveAsImage: {
        show: true,
        title: '下载'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    data: []
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      name: '人'
    }
  ],
  series: []
}

const getbarLabel = {
  show: true,
  position: 'top',
  formatter: (value) =>
    value.data.toString().length > 4
      ? `${parseInt(value.data / 10000, 10) + 1}万`
      : value.data
}

class YearGroup extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)

    const nextSearch = new URLSearchParams(props.location.search)
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatMonth)
      : requestParams.end_date
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatMonth)
      : requestParams.start_date
    this.state = {
      start_date: startDate,
      start_format: startDate.format(dateFormatMonth),
      end_date: endDate,
      end_format: endDate.format(dateFormatMonth),
      table: [],
      isLoading: false
    }
    this.useEffect()
  }

  getrequest = () => ({
    start_date: this.state.start_format,
    end_date: this.state.end_format
  })

  handleDateChange = (date, dateString) => {
    this.setState({
      start_date: moment(dateString[0], dateFormatMonth),
      start_format: dateString[0],
      end_date: moment(dateString[1], dateFormatMonth),
      end_format: dateString[1]
    })
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchData()
  }

  fetchData = () => {
    services.mapList.YearGroup(this.getrequest()).then((result) => {
      if (result.series1 instanceof Array) {
        result.series1 = result.series1.map((value) => {
          value.label = getbarLabel
          return value
        })
      }

      this.setState({ table: result.table })
      requestOptionPreass.xAxis[0].data = result.xAxis1
      requestOptionPreass.series = result.series1
      requestOptionPreass.legend.data = result.legend1
      this.echartsElement.getEchartsInstance().clear() // 清空画布
      this.echartsElement.getEchartsInstance().setOption(requestOptionPreass) // 实时改变
    })
  }

  getDivMap = () => {
    return this.state.data.map((item, key) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className={item.itemStyle} key={key}>
          <div>{item.value}</div>
          <div>{item.name}</div>
        </div>
      )
    })
  }

  render() {
    return (
      <>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <RangePicker
              picker="month"
              value={[this.state.start_date, this.state.end_date]}
              format={dateFormatMonth}
              onChange={this.handleDateChange}
              allowClear={false}
            />
          </Space>
          <Space style={{ cssFloat: 'right' }}>
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        <ReactEcharts
          ref={(e) => {
            this.echartsElement = e
          }}
          style={{ width: '80%' }}
          option={requestOptionPreass}
        />
        <div>
          <h3 style={{ marginLeft: 20 }}>XPARK四大核心国AIPL人群数据</h3>
        </div>
        <Space
          style={{
            marginTop: 50,
            width: '100%',
            overflow: 'hidden',
            display: 'block'
          }}
        >
          <Table
            sticky
            bordered
            size="small"
            loading={this.state.isLoading}
            columns={yearGroupColumns}
            dataSource={this.state.table}
            rowKey={(record) => record.id}
            scroll={{ x: 'max-content', y: 500 }}
            pagination={false}
          />
        </Space>
      </>
    )
  }
}

YearGroup.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default YearGroup
