import React from 'react'
import { Select } from 'antd'

const { Option } = Select

export const itemTypes = [
  {
    value: 'coupon',
    label: '优惠券'
  },
  {
    value: 'topup',
    label: '充值'
  },
  {
    value: 'thankyou',
    label: '未中奖'
  }
]

export const getTypeLabel = (value) => {
  let res = ''
  itemTypes.forEach((item) => {
    if (item.value === value) {
      res = item.label
    }
  })
  return res
}

export default (props) => {
  return (
    <Select {...props}>
      {itemTypes.map((type) => (
        <Option value={type.value}>{type.label}</Option>
      ))}
    </Select>
  )
}
