import React, { Component } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Space, Card, Typography, Button, DatePicker, Select } from 'antd'
import { getStaticText, storage } from '../../utils'
import dateFormat from '../../config/dateFormat'
import RightFrame from './Frame/RightFrame'
import LeftCoreFrame from './Frame/LeftCoreFrame'
import LeftRealTimeFrame from './Frame/LeftRealTimeFrame'
import LeftFlowFrame from './Frame/LeftFlowFrame'
import '../../css/dashbord.css'
import LeftGoodsFrame from './Frame/LeftGoodsFrame'
import LeftAfterSaleFrame from './Frame/LeftAfterSaleFrame'

const dateFormatDay = dateFormat.day
const { Text } = Typography
const { RangePicker } = DatePicker

const requestParams = {
  country: 'All_Country',
  start_date: moment().subtract('day', 7),
  end_date: moment().subtract('day', 1)
}

class DashboardIndex extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatDay)
      : requestParams.start_date
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatDay)
      : requestParams.end_date
    const country = nextSearch.get('country')
      ? nextSearch.get('country')
      : requestParams.country
    this.state = {
      countries: this.getCountrys(),
      country,
      goodsLoading: false,
      start_date: startDate,
      start_format: startDate.format(dateFormatDay),
      end_date: endDate,
      end_format: endDate.format(dateFormatDay)
    }
    this.fetchers = []
  }

  // eslint-disable-next-line react/sort-comp
  getCountrys = () => {
    const countrys = storage.allowedCountries.val
    countrys.forEach((country) => {
      if (country.value === 'All_Country') {
        country.label = '所有站点'
      }
    })
    return countrys
  }

  componentDidMount() {
    this.useEffect()
  }

  getrequest = () => ({
    country: this.state.country,
    start_date: this.state.start_format,
    end_date: this.state.end_format
  })

  handleDateChange = (date, dateString) => {
    this.setState({
      start_date: date[0],
      start_format: dateString[0],
      end_date: date[1],
      end_format: dateString[1]
    })
  }

  handleCountryChange = (country) => {
    this.setState({
      country
    })
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  addFetcher = (fetcher) => {
    this.fetchers.push(fetcher)
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchers.forEach((fetcher) => {
      fetcher(this.getrequest())
    })
  }

  handleToggleDrawer = () => {
    this.setState((state) => ({
      goodsLoading: !state.goodsLoading
    }))
  }

  render() {
    return (
      <>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text>{getStaticText('component.search.country.text')}</Text>
            <Select
              style={{ width: 220 }}
              value={this.state.country}
              onChange={this.handleCountryChange}
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Select.Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Text style={{ marginLeft: 20 }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <RangePicker
              picker="day"
              value={[this.state.start_date, this.state.end_date]}
              format={dateFormatDay}
              onChange={this.handleDateChange}
              allowClear={false}
            />
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        <Space
          style={{
            marginTop: 50,
            overflow: 'hidden',
            display: 'block',
            width: '100%'
          }}
        >
          <RightFrame onRef={this.addFetcher} />
          <LeftRealTimeFrame onRef={this.addFetcher} />
          <LeftCoreFrame onRef={this.addFetcher} />
          <LeftFlowFrame onRef={this.addFetcher} />
          <LeftGoodsFrame
            onRef={this.addFetcher}
            checkgoods={this.handleToggleDrawer}
          />
          <LeftAfterSaleFrame onRef={this.addFetcher} />
        </Space>
      </>
    )
  }
}

DashboardIndex.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default DashboardIndex
