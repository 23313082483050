import React, { useState, useEffect } from 'react'
import { Upload, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import store from '../../../store'
import request from '../../../services/request'

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const getToken = () => store.getState().users.userInfo.token
const token = {
  authorization: `Bearer ${getToken()}`
}
interface Props {
  value: Object;
  onChange: () => {};
}

const UploadList = ({ value, onChange }: Props) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    if (value) {
      setFileList([value])
    } else {
      setFileList([])
    }
  }, [value, setFileList])

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    )
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  const handleChange = (e) => {
    if (e.fileList.length === 0) {
      onChange([])
    }
  }
  const onUploaded = (ret) => {
    ret.status = 'done'
    ret.name = 'img'
    onChange(ret)
  }

  return (
    <>
      <Upload
        action="/backend/file-system/upload"
        headers={token}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        maxCount="1"
        customRequest={(e) => {
          const formData = new FormData()
          formData.append('file', e.file)
          request
            .post('/backend/file-system/upload', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
            .then(onUploaded)
        }}
      >
        {uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

export default UploadList
