import React, { useState, useCallback } from 'react'
import { Card, Row, Col, Space, Typography } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { columns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getPrivilegesConfig, freshCache } from '../services'
import {
  GridContextProvider,
  PagingTable,
  PermissionButton
} from '../../../../components'
import MemberProductsPicker from '../Product/ui'
import EditForm from '../Edit/ui'
import SettingForm from '../Config/SettingForm'
import CoinReceiveForm from '../Config/CoinReceiveForm'

const { Text } = Typography

export default () => {
  const [gridData, setGridData] = useState(initGridData)
  const [freshCacheLoading, setFreshCacheLoading] = useState(false)

  const onLoad = useCallback(() => {
    getPrivilegesConfig(setGridData)()
  }, [setGridData])

  return (
    <GridContextProvider onLoad={onLoad}>
      <Card>
        <Row>
          <Col span={24}>
            <Text>Privileges</Text>
            <Space style={{ cssFloat: 'right' }}>
              <PermissionButton
                icon={<SyncOutlined />}
                type="primary"
                onClick={() => {
                  setFreshCacheLoading(true)
                  freshCache(() => setFreshCacheLoading(false))()
                }}
                loading={freshCacheLoading}
                permission="membership/membership/fresh-cache"
              >
                Refresh Cache
              </PermissionButton>
            </Space>
          </Col>
        </Row>
      </Card>
      <PagingTable
        columns={columns}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.current}
        pageSize={gridData.page_size || 10}
        pagination={false}
      />
      <MemberProductsPicker />
      <EditForm />
      <SettingForm />
      <CoinReceiveForm />
    </GridContextProvider>
  )
}
