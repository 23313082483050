import React, { useEffect, useCallback, useState, useContext } from 'react'
import { Form, Row, Col, Input } from 'antd'

import {
  GridFormDrawer,
  GridContext,
  Uploader
} from '../../../../../../../components'
import { getBannerDetail, updateBanner, createBanner } from '../../../services'

interface Props {
  cat: Number;
  reload: (id: Number) => {};
}

const CategoryForm = ({ cat, reload }: Props) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({
    category_id: cat
  })

  const { toggle } = useContext(GridContext)
  const emptyForm = () => {
    setFormData({})
    form.resetFields()
  }
  const closeDrawer = () => {
    emptyForm()
    toggle({}, '')
    reload(cat)
  }

  const formatResData = useCallback((value) => {
    const newValue = Object.assign(value, {})
    setFormData(newValue)
  }, [])

  const openHandler = (id) => {
    getBannerDetail(formatResData)(id)
  }

  const onFinish = (values) => {
    const formatValue = {
      url: values.url,
      category_id: cat,
      title: values.title,
      image: values.image
    }
    if (formData.id) {
      updateBanner(closeDrawer)({ id: formData.id, data: formatValue })
    } else {
      createBanner(closeDrawer)({ data: formatValue })
    }
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit_banner|create_banner"
      title={(record) => (record.id ? 'Edit Banner' : 'New Banner')}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please enter banner title' }]}
            >
              <Input placeholder="Please enter banner title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="url"
              label="链接"
              rules={[{ required: true, message: 'Please enter description' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="image"
              label="图片"
              rules={[{ required: true, message: 'Please enter title' }]}
            >
              <Uploader />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </GridFormDrawer>
  )
}

export default CategoryForm
