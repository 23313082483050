import WeekTrend from '../pages/Echarts/GmvTrend/WeekTrend'
import WeekPie from '../pages/Echarts/GmvPie/WeekPie'
import MonthPie from '../pages/Echarts/GmvPie/MonthPie'
import MonthTrend from '../pages/Echarts/GmvTrend/MonthTrend'
import WeekActive from '../pages/Echarts/PreassemActive/WeekActive'
import MonthActive from '../pages/Echarts/PreassemActive/MonthActive'
import MonthCrossTotalGmv from '../pages/Echarts/CrossTrend/MonthCrossTotalGmv'
import MonthCrossNgGmv from '../pages/Echarts/CrossNgGmv/MonthCrossNgGmv'
import MonthCrossNgSku from '../pages/Echarts/CrossNgSku/MonthCrossNgSku'
import WeekUseTime from '../pages/Echarts/UseTime/WeekUseTime'
import MonthUseTime from '../pages/Echarts/UseTime/MonthUseTime'
import WeekStores from '../pages/Echarts/Stores/WeekStores'
import MonthStores from '../pages/Echarts/Stores/MonthStores'
import MonthStoresPie from '../pages/Echarts/Stores/MonthStoresPie'
import MonthCatalogGmv from '../pages/Echarts/GmvTrend/MonthCatalogGmv'
import MonthCatalogPie from '../pages/Echarts/GmvPie/MonthCatalogPie'
import YearData from '../pages/Echarts/Year/YearData'
import MonthCatalogSales from '../pages/Echarts/GmvTrend/MonthCatalogSales'
import MonthCatalogPieSales from '../pages/Echarts/GmvPie/MonthCatalogPieSales'
import YearManage from '../pages/Echarts/Year/YearManage'
import YearGroup from '../pages/Echarts/Year/YearGroup'
import {
  BrandProducts,
  BrandGa,
  BrandPie,
  SeriesPie,
  RetentionRate
} from '../pages'

const echart = {
  path: '/echarts',
  primary: 'echarts.total',
  routes: [
    {
      path: '/brand',
      primary: 'brand.products',
      routes: [
        {
          path: '/brand/products',
          permission: 'brand/get-brand-products',
          modular: 'report',
          component: BrandProducts,
          primary: 'echarts.brand-products',
          exact: true
        },
        {
          path: '/brand/ga',
          permission: 'brand/get-brand-ga',
          modular: 'report',
          component: BrandGa,
          primary: 'echarts.brand-ga',
          exact: true
        },
        {
          path: '/brand/brand/pie',
          permission: 'brand/get-brand-pie',
          modular: 'report',
          component: BrandPie,
          primary: 'echarts.brand-pie',
          exact: true
        },
        {
          path: '/brand/series/pie',
          permission: 'series/get-series-pie',
          modular: 'report',
          component: SeriesPie,
          primary: 'echarts.series-pie',
          exact: true
        }
      ]
    },
    {
      path: '/gmv-trend',
      primary: 'echarts.gmv-trend',
      routes: [
        {
          path: '/echarts/gmv-trend/week-trend',
          permission: 'echarts/get-week-trend',
          modular: 'report',
          component: WeekTrend,
          primary: 'echarts.gmv-trend.week-trend',
          exact: true
        },
        {
          path: '/echarts/gmv-trend/month-trend',
          permission: 'echarts/get-month-trend',
          modular: 'report',
          component: MonthTrend,
          primary: 'echarts.gmv-trend.month-trend',
          exact: true
        },
        {
          path: '/echarts/gmv-trend/month-catalog-gmv',
          permission: 'echarts/get-month-catalog-gmv',
          modular: 'report',
          component: MonthCatalogGmv,
          primary: 'echarts.gmv-trend.month-catalog-gmv',
          exact: true
        },
        {
          path: '/echarts/gmv-trend/month-catalog-sales',
          permission: 'echarts/get-month-catalog-sales',
          modular: 'report',
          component: MonthCatalogSales,
          primary: 'echarts.gmv-trend.month-catalog-sales',
          exact: true
        }
      ]
    },
    {
      path: '/gmv-pie',
      primary: 'echarts.gmv-pie',
      routes: [
        {
          path: '/echarts/gmv-pie/week-pie',
          permission: 'echarts/get-week-pie',
          modular: 'report',
          component: WeekPie,
          primary: 'echarts.gmv-pie.week-pie',
          exact: true
        },
        {
          path: '/echarts/gmv-pie/month-pie',
          modular: 'report',
          permission: 'echarts/get-month-pie',
          component: MonthPie,
          primary: 'echarts.gmv-pie.month-pie',
          exact: true
        },
        {
          path: '/echarts/gmv-pie/month-catalog-pie',
          modular: 'report',
          permission: 'echarts/get-month-catalog-pie',
          component: MonthCatalogPie,
          primary: 'echarts.gmv-pie.month-catalog-pie',
          exact: true
        },
        {
          path: '/echarts/gmv-pie/month-catalog-pie-sales',
          modular: 'report',
          permission: 'echarts/get-month-catalog-pie-sales',
          component: MonthCatalogPieSales,
          primary: 'echarts.gmv-pie.month-catalog-pie-sales',
          exact: true
        }
      ]
    },
    {
      path: '/stores',
      primary: 'echarts.stores',
      routes: [
        {
          path: '/echarts/stores/week-stores',
          modular: 'report',
          permission: 'echarts/get-week-stores',
          component: WeekStores,
          primary: 'echarts.stores.week-stores',
          exact: true
        },
        {
          path: '/echarts/stores/month-stores',
          modular: 'report',
          permission: 'echarts/get-month-stores',
          component: MonthStores,
          primary: 'echarts.stores.month-stores',
          exact: true
        },
        {
          path: '/echarts/stores/month-stores-pie',
          modular: 'report',
          permission: 'echarts/get-month-stores-pie',
          component: MonthStoresPie,
          primary: 'echarts.stores.month-stores-pie',
          exact: true
        }
      ]
    },
    {
      path: '/preassem-active',
      primary: 'echarts.preassem-active',
      routes: [
        {
          path: '/echarts/preassem-active/week-active',
          modular: 'report',
          permission: 'echarts/get-week-active',
          component: WeekActive,
          primary: 'echarts.preassem-active.week-active',
          exact: true
        },
        {
          path: '/echarts/preassem-active/month-active',
          modular: 'report',
          permission: 'echarts/get-month-active',
          component: MonthActive,
          primary: 'echarts.preassem-active.month-active',
          exact: true
        }
      ]
    },
    {
      path: '/cross-trend',
      primary: 'echarts.cross',
      routes: [
        {
          path: '/echarts/cross-trend/month-total-gmv',
          modular: 'report',
          permission: 'echarts/get-month-cross-total-gmv',
          component: MonthCrossTotalGmv,
          primary: 'echarts.cross-trend.month-total-gmv',
          exact: true
        },
        {
          path: '/echarts/cross-trend/month-cross-ng-gmv',
          modular: 'report',
          permission: 'echarts/get-month-cross-ng-gmv',
          component: MonthCrossNgGmv,
          primary: 'echarts.cross-ng-gmv.month-total-gmv',
          exact: true
        },
        {
          path: '/echarts/cross-trend/month-cross-ng-sku',
          modular: 'report',
          permission: 'echarts/get-month-cross-ng-sku',
          component: MonthCrossNgSku,
          primary: 'echarts.cross-ng-sku.month-total-sku',
          exact: true
        }
      ]
    },
    {
      path: '/use-time',
      primary: 'echarts.use-time',
      routes: [
        {
          path: '/echarts/use-time/week-use-time',
          modular: 'report',
          permission: 'echarts/get-week-use-time',
          component: WeekUseTime,
          primary: 'echarts.use-time.week-use-time',
          exact: true
        },
        {
          path: '/echarts/use-time/month-use-time',
          modular: 'report',
          permission: 'echarts/get-month-use-time',
          component: MonthUseTime,
          primary: 'echarts.use-time.month-use-time',
          exact: true
        },
        {
          path: '/echarts/use-time/retention-rate',
          modular: 'report',
          permission: 'echarts/get-retention-rate',
          component: RetentionRate,
          primary: 'echarts.use-time.retention-rate',
          exact: true
        }
      ]
    },
    {
      path: '/year',
      primary: 'echarts.year',
      routes: [
        {
          path: '/echarts/year/yearManage',
          modular: 'report',
          permission: 'echarts/get-year-manage',
          component: YearManage,
          primary: 'echarts.year-manage',
          exact: true
        },
        {
          path: '/echarts/year/yearFlow',
          modular: 'report',
          permission: 'echarts/get-year-data',
          component: YearData,
          primary: 'echarts.year-flow',
          exact: true
        },
        {
          path: '/echarts/year/yearGroup',
          modular: 'report',
          permission: 'echarts/get-year-group',
          component: YearGroup,
          primary: 'echarts.year-group',
          exact: true
        }
      ]
    }
  ]
}

export default echart
