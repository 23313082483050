/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Divider, Typography, Row, Col } from 'antd'
import FilterPricingTemplateComponents from '../../components/1688ProjectComponents/PricingTemplate/FilterPricingTemplateComponents'
import BlackButton from '../../components/1688ProjectComponents/ButtonComponents/BlackButton'
import TablePublicCollection from '../../components/1688ProjectComponents/TableComponents/TablePublicCollection'
import StickyFooter from '../../components/1688ProjectComponents/FooterComponents/StickyFooter'
import ModalPricingTemplate from '../../components/1688ProjectComponents/PricingTemplate/ModalPricingTemplate'
import { getPricingTemplate } from '../../services/1688Projects/exclusive'

const { Title } = Typography
interface GetProduct {
  status: Number | null;
  goPage: Number | null;
}
const PricingTemplate = () => {
  const [open, setOpen] = useState(false)
  const [getPricing, setGetPricing] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [totalItem, setTotalItem] = useState(0)
  const [sizePage, setSizePage] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [pageNow, setPageNow] = useState(1)
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [platFormOptions, setPlatformOptions] = useState([])

  const openModal = () => {
    setOpen(true)
  }
  const titlePage = () => {
    return (
      <Title level={2} style={{ marginBottom: 0, padding: '24px' }}>
        Pricing Template
      </Title>
    )
  }
  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'name'
    },
    {
      title: 'Site',
      dataIndex: 'site'
    },
    {
      title: 'Creator',
      dataIndex: 'creator'
    },
    {
      title: 'Creation Time',
      dataIndex: 'createTime',
      render: (createTime) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <div>{createTime.date}</div>
          <div>{createTime.time}</div>
        </div>
      )
    }
  ]

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>
    }
    if (type === 'next') {
      return <a>Next</a>
    }
    return originalElement
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  }

  const onShowSizeChange = (current, pageSize) => {
    setSizePage(pageSize)
  }

  const getPricingData = async ({ goPage = 1, dataForm }: GetProduct = {}) => {
    try {
      setIsLoadingTable(true)
      const data = {}
      if (dataForm) {
        const dataSite = dataForm['filter[site]']
        const templateName = dataForm['filter[template_name]']
        if (dataSite) {
          data['filter[site]'] = dataSite
        }
        if (templateName) {
          data['filter[template_name]'] = templateName
        }
      }
      if (goPage) {
        data.beginPage = goPage
      }
      if (sizePage) {
        data.pageSize = sizePage
      }

      const response = await getPricingTemplate(data)
      if (response?.totalRecords) {
        setTotalCount(response.totalRecords)
      } else {
        setTotalCount(0)
      }
      if (response?.list?.length > 0) {
        const newData = response.list

        const dataPayload = newData.map((item) => {
          const dataReturn = {
            key: item.id || '-',
            name: item.template_name || '-',
            site: item.site || '-',
            // remark: item.profit_method || '-',
            creator: item?.author || '-',
            createTime: {
              date: item?.created_at?.split(' ')[0] || '',
              time: item?.created_at?.split(' ')[1] || ''
            }
          }
          return dataReturn
        })
        setTotalItem(dataPayload?.length || 0)
        setGetPricing(dataPayload)
      } else {
        setTotalCount(0)
        setGetPricing([])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingTable(false)
    }
  }

  const handleSelectAll = () => {
    const allRowKeys = getPricing.map((item) => item.key)
    setSelectedRowKeys(allRowKeys)
  }
  const handleDeselectAll = () => {
    setSelectedRowKeys([])
  }

  const onChangePage = (value) => {
    if (value) {
      setPageNow(Number(value))
      getPricingData({ goPage: Number(value) })
    }
  }

  function setAllowedCountry() {
    let platForm =  [
      {
        label: 'Nigeria',
        value: 'XPARK-NG',
        disabled: true
      },
      {
        label: 'Pakistan',
        value: 'XPARK-PK',
        disabled: true
      },
      {
        label: 'Kenya',
        value: 'XPARK-KE',
        disabled: true
      },
      {
        label: 'Ghana',
        value: 'XPARK-GH',
        disabled: true
      },
      {
        label: 'Morocco',
        value: 'XPARK-MA',
        disabled: true
      }
    ]
    let getFromLocal = JSON.parse(localStorage.getItem('allowedCountries'))
    if (getFromLocal && getFromLocal.length > 0) {
      let newPlatform = []
      for (let platFormKey in platForm) {
        let platFormNow = platForm[platFormKey].value.split("-")[1]
        for (let getFromLocalKey in getFromLocal) {
          let localFormNow = getFromLocal[getFromLocalKey].value
          if (platFormNow === localFormNow) {
            newPlatform.push({
              label: platForm[platFormKey].label,
              value: platForm[platFormKey].value,
              disable: getFromLocal[getFromLocalKey].disabled
            })
          }
        }
      }
      setPlatformOptions(newPlatform)
    }
  }

  useEffect(() => {
    setAllowedCountry()
    getPricingData()
  }, [])

  return (
    <div style={{ width: '100%', background: '#F8F8F8', position: 'relative' }}>
      <div style={{ width: '100%' }}>{titlePage()}</div>
      <Divider style={{ color: 'black', margin: 'unset' }} />
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
      >
        <FilterPricingTemplateComponents platFormOptions={platFormOptions} isLoadingTable={isLoadingTable} getPricingData={getPricingData} />
        <Row gutter={[10, 10]}>
          <Col span={4}>
            <BlackButton
              functionParent={openModal}
              icon={<PlusOutlined />}
              title="Create Template"
            />
          </Col>
          <Col span={20} />
          <Col span={24}>
            <TablePublicCollection
              loading={isLoadingTable}
              columns={columns}
              dataTable={getPricing}
              rowSelection={rowSelection}
            />
          </Col>
          <StickyFooter
            sizePage={sizePage}
            totalItem={totalItem}
            handleCheckAll={handleSelectAll}
            handleUncheckAll={handleDeselectAll}
            itemRender={itemRender}
            totalPage={totalCount}
            onChangePage={onChangePage}
            page={pageNow}
            onShowSizeChange={onShowSizeChange}
            itemSelected={selectedRowKeys?.length || 0}
          />
        </Row>
      </div>
      <ModalPricingTemplate
        loading={isLoadingTable}
        getPricingData={getPricingData}
        open={open}
        setOpen={setOpen}
        setPageNow={setPageNow}
      />
    </div>
  )
}

export default PricingTemplate
