import request from '../request'

export const getCategory = (params) =>
  request.get('/backend/alibaba/selection/getCategoryList', { params })

export const getProductList = (params) =>
  request.get('/backend/alibaba/selection/getProductList', { params })

export const sendCollectProduct = (data) =>
  request.post('/backend/alibaba/selection/collectionTasks', data)

export const getPricingTemplate = (params) =>
  request.get('/backend/alibaba/pricingTemplate/getPricingTemplate', { params })

export const sendCreatePricing = (data) =>
  request.post('/backend/alibaba/pricingTemplate/createPricingTemplate', data)

export const getAuthorPubColBox = (params) =>
  request.get('/backend/alibaba/pubBox/getAuthorList', { params })
