import { OperationLogGrid } from '../pages'

const log = {
  path: '/logs',
  primary: 'app.sider.log',
  hideGlobalTips: true,
  scopes: ['xpark'],
  routes: [
    {
      path: '/log/operation-log',
      modular: 'config',
      permission: 'external-system/operation-log/get-list',
      primary: 'app.sider.log.operationlog',
      component: OperationLogGrid,
      exact: true
    }
  ]
}
export default log
