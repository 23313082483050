import { TotalReport, SiteTotalReport } from '../pages'

const totalReport = {
  path: '/total-report',
  primary: 'app.sider.total-report',
  routes: [
    {
      path: '/total-report/total',
      modular: 'report',
      permission: 'disk-report/get-total-list',
      component: TotalReport,
      primary: 'app.sider.total-report.total',
      exact: true
    },
    {
      path: '/total-report/site',
      modular: 'report',
      permission: 'disk-report/get-site-list',
      component: SiteTotalReport,
      primary: 'app.sider.total-report.site',
      exact: true
    }
    // {
    //   path: '/total-report/big-query',
    //   modular: 'report',
    //   permission: 'big-query/compain-list',
    //   component: BigQuery,
    //   primary: 'app.sider.total-report.big-query',
    //   exact: true
    // }
  ]
}

export default totalReport
