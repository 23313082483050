import React, { ReactNode, useState, useRef } from 'react'
import { Modal } from 'antd'

import PermissionButton from './PermissionButton'
import request from '../../services/request'

interface Props {
  permission: String;
  children: ReactNode;
  reload: () => {};
}

const ImportButton = ({
  children,
  permission,
  reload,
  tips,
  ...props
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [tipsVisible, setTipsVisible] = useState(false)
  const fileRef = useRef(null)
  const importAction = (params) =>
    request.post(`/backend/${permission}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  const doImport = (e) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    setLoading(true)
    importAction(formData)
      .then((data) => {
        setLoading(false)
        reload(data)
      })
      .catch(() => setLoading(false))
  }
  const fileDialog = () => {
    setTipsVisible(false)
    fileRef.current.click()
  }
  return (
    <>
      <PermissionButton
        permission={permission}
        onClick={() => (tips ? setTipsVisible(true) : fileDialog())}
        loading={loading}
        {...props}
      >
        {children}
      </PermissionButton>
      <input
        id="import"
        type="file"
        style={{ display: 'none' }}
        onChange={doImport}
        ref={fileRef}
      />

      {tips && (
        <Modal
          onCancel={() => setTipsVisible(false)}
          onOk={fileDialog}
          title="Tips"
          okText="Continue"
          cancelText="Cancel"
          visible={tipsVisible}
        >
          {tips}
        </Modal>
      )}
    </>
  )
}
export default ImportButton
