import axios from 'axios'
import { message } from 'antd'
import store from '../store'
import { setCurrentCountry, setCaptcha } from '../store/actions/global'
import { setFormData } from '../store/actions/i18n'
import { addPromisePair } from './captchaUtil'

const request = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL
})

const getToken = () => store.getState().users.userInfo.token
const getScope = () => store.getState().global.currentScope.key

const isApiAllowScopeSwitch = (url) => {
  if (/rbac/.test(url)) return false
  return ['/backend/login', '/backend/logout'].indexOf(url) === -1
}

request.interceptors.request.use((config) => {
  console.log({ request })
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${getToken()}`,
    'X-Version': isApiAllowScopeSwitch(config.url) ? getScope() : null
  }
  return config
})
request.interceptors.response.use((resp) => {
  if (typeof resp.data === 'string') {
    message.error(resp.data)
    throw new Error(resp.data)
  }
  const { code, data } = resp.data

  if (code === 9999 || code === 10000 || code === 100) {
    message.error(resp.data.message)
    throw new Error(resp.data.message)
  }
  if (code === 401) {
    message.error(resp.data.message)
    throw new Error(resp.data.message)
  }

  if (code === 101) {
    message.error(resp.data.message)
    window.localStorage.clear()
    window.location.href = '/login'
  }
  if (code === 9901) {
    store.dispatch(setCaptcha({ ...resp.data.data, loading: false }))
    return new Promise((resolve, reject) => {
      addPromisePair(resp.data.data.request_id, resolve, reject)
    })
  }
  if (data.i18n && data.country) {
    store.dispatch(setCurrentCountry(data.country))
    store.dispatch(setFormData(data.i18n || {}))
  }
  return data
})
export default request
