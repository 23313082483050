import React from 'react'

interface Props {
  title: String;
  space: String;
  colon: Boolean;
  widthTitle: String;
}
const LabelInput = ({ title, space, colon, widthTitle }: Props) => {
  return (
    <p
      style={{
        fontSize: '15px',
        marginBottom: 'unset',
        display: 'flex',
        alignItems: 'center',
        minWidth: '144px',
        width: widthTitle || '100%'
      }}
    >
      <div>{title}</div>
      {colon && <div style={{ marginLeft: space }}>:</div>}
    </p>
  )
}

export default LabelInput
