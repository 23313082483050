import React from 'react'
import Actions from './Actions'

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: 50
  },
  {
    title: 'Survey Name',
    dataIndex: 'survey_name',
    key: 'survey_name',
    align: 'center',
    width: 200
  },
  {
    title: 'Discussion ID',
    dataIndex: 'discussion_id',
    key: 'discussion_id',
    align: 'center',
    width: 100
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
  {
    title: 'Updated At',
    dataIndex: 'updated_at',
    key: 'updated_at',
    align: 'center'
  },
  {
    title: 'End Time',
    dataIndex: 'end_time',
    key: 'end_time',
    align: 'center'
  },
  {
    title: 'Anonymous',
    dataIndex: 'is_anonymous',
    key: 'is_anonymous',
    align: 'center',
    width: 100,
    render: (data) => {
      if (data === 0) return '否'
      if (data === 1) return '是'
    }
  },
  {
    title: 'Action',
    dataIndex: '',
    width: 200,
    key: 'x',
    align: 'center',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 15,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
