import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function Stock(props) {
  const { tableData } = props
  return (
    <>
      {/* 库存 */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="3" className="sticky-left sticky-second">
          库存
        </th>
        <th colSpan="10">
          前置仓商品或手机分货的库存周转天数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>时间段天数固定一个月，用30天来计算</p>
                <p>
                  期初数量+期末数量：2月份报表，则拉上一月的期初数量+期末数量
                </p>
                <p>销量是上一个月的总销量</p>
                <p>如果天数出现小数，则保留一位小数</p>
                <p>来源： 天数*（期初库存+期末库存）/（2*上个月总销量）</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row2 */}
      <tr>
        <th>手机</th>
        <th>配件</th>
        <th>美妆</th>
        <th>服饰</th>
        <th>鞋包配饰</th>
        <th>家电</th>
        <th>家居</th>
        <th>运动健身</th>
        <th>玩具</th>
        <th>其它</th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.phone_product_turnover_days}</td>
        <td>{tableData.accessory_product_turnover_days}</td>
        <td>{tableData.makeups_product_turnover_days}</td>
        <td>{tableData.cloth_product_turnover_days}</td>
        <td>{tableData.shoes_bag_product_turnover_days}</td>
        <td>{tableData.appliances_product_turnover_days}</td>
        <td>{tableData.furniture_product_turnover_days}</td>
        <td>{tableData.fitness_product_turnover_days}</td>
        <td>{tableData.toys_product_turnover_days}</td>
        <td>{tableData.others_product_turnover_days}</td>
      </tr>
    </>
  )
}

Stock.propTypes = {
  tableData: shape({}).isRequired
}

export default Stock
