import request from './request'

// xpark products
export const getBbsProduct = (params) =>
  request.get('/backend/xpark/products', { params })

// post
export const getPostList = (params) =>
  request.get('/backend/bbs/discussion', { params })

export const getPostDetail = ({ id, ___country }) =>
  request.get(`/backend/bbs/discussion/${id}/show`, { params: { ___country } })

export const savePost = ({ id, country, params }) =>
  request.patch(
    `/backend/bbs/discussion/${id}/update?___country=${country}`,
    params
  )

export const createPost = ({ country, params }) => {
  return request.post(
    `/backend/bbs/discussion/store?___country=${country}`,
    params
  )
}

export const deletePost = ({ id, country }) => {
  return request.delete(
    `/backend/bbs/discussion/${id}/delete?___country=${country}`
  )
}

export const upload = ({ country, params }) => {
  return request.post(`/backend/bbs/upload?___country=${country}`, params)
}

// banner
export const getBannerList = (params) =>
  request.get('/backend/bbs/banner', { params })

export const saveBanner = ({ id, country, params }) =>
  request.patch(
    `/backend/bbs/banner/${id}/update?___country=${country}`,
    params
  )

export const createBanner = ({ country, params }) => {
  return request.post(`/backend/bbs/banner/store?___country=${country}`, params)
}

export const deleteBanner = ({ id, country }) => {
  return request.delete(
    `/backend/bbs/banner/${id}/delete?___country=${country}`
  )
}

// tags
export const getTagsList = (params) =>
  request.get('/backend/bbs/discussion_tags', { params })

export const saveTags = ({ id, country, params }) =>
  request.patch(`/backend/bbs/tags/${id}/update?___country=${country}`, params)

export const createTags = ({ country, params }) => {
  return request.post(`/backend/bbs/tags/store?___country=${country}`, params)
}

export const deleteTags = ({ id, country }) => {
  return request.delete(`/backend/bbs/tags/${id}/delete?___country=${country}`)
}

// sensitive words
export const getWordsList = (params) =>
  request.get('/backend/bbs/filter', { params })

export const saveWords = ({ id, country, params }) =>
  request.patch(
    `/backend/bbs/filter/${id}/update?___country=${country}`,
    params
  )

export const createWords = ({ country, params }) => {
  return request.post(`/backend/bbs/filter/store?___country=${country}`, params)
}

export const deleteWords = ({ id, country }) => {
  return request.delete(
    `/backend/bbs/filter/${id}/delete?___country=${country}`
  )
}

// bbs reviews
export const getReviewList = ({ id, params }) =>
  request.get(`/backend/bbs/discussion/${id}/comment`, {
    params
  })

export const deleteReview = ({ id, country }) => {
  return request.delete(`/backend/bbs/post/${id}/delete?___country=${country}`)
}

// bbs survey
export const getDiscussionList = (params) =>
  request.get('/backend/bbs/discussion/index_prune', { params })

export const getSurveyList = (params) =>
  request.get('/backend/bbs/survey', { params })

export const getSurveyDetail = ({ id, ___country }) =>
  request.get(`/backend/bbs/survey/${id}/show`, { params: { ___country } })

export const saveSurvey = ({ id, country, params }) =>
  request.patch(
    `/backend/bbs/survey/${id}/update?___country=${country}`,
    params
  )

export const createSurvey = ({ country, params }) => {
  return request.post(`/backend/bbs/survey/store?___country=${country}`, params)
}

export const deleteSurvey = ({ id, country }) => {
  return request.delete(
    `/backend/bbs/survey/${id}/delete?___country=${country}`
  )
}
