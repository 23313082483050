import React, { useState, useCallback, useEffect } from 'react'
import { Upload, Modal, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import store from '../../../../store'

interface Props {
  country: String;
  getuploadData: Array;
  setUploadData: Function;
  setNoUpload: Function;
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const UploadList = ({
  country,
  getuploadData,
  setUploadData,
  setNoUpload
}: Props) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState([])
  const [hasVedio, setHasVedio] = useState(false)
  const [isVedio, setIsVedio] = useState(false)

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const getToken = () => store.getState().users.userInfo.token
  const token = {
    authorization: `Bearer ${getToken()}`
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    if (
      (file.response && file.response.data.video_preview) ||
      file.video_preview
    ) {
      setIsVedio(true)
    } else {
      setIsVedio(false)
    }
    setPreviewImage(file.response ? file.response.data.url : file.url)
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    )
  }

  const formatValue = useCallback((value) => {
    return value.reduce((pre, item, index) => {
      if (
        ((item.response && item.response.data.type === 'video/mp4') ||
          item.video_preview) &&
        index === 0
      ) {
        pre.push({
          video_preview: item.response
            ? item.response.data.video_preview
            : item.video_preview
        })
      }
      pre.push(item.response ? item.response.data.url : item.url)
      return pre
    }, [])
  }, [])

  const handleChange = ({ file, fileList }) => {
    if (file.response && file.response.code !== 200) {
      file.status = 'error'
    }
    if (
      fileList.some((item) => item.type === 'video/mp4') ||
      fileList.video_preview
    ) {
      setHasVedio(true)
    } else {
      setHasVedio(false)
    }
    if (fileList.length) {
      setNoUpload(false)
    }
    setFileList(fileList)
    setUploadData(formatValue(fileList))
  }

  const beforeUpload = useCallback(
    (file) => {
      // validate at most one video
      if (hasVedio && file.type === 'video/mp4') {
        message.error('One video can be uploaded at most!')
        return Upload.LIST_IGNORE
      }
      // only support mp4 in vedio
      if (
        file.type.split('/')[0] === 'video' &&
        file.type.split('/')[1] !== 'mp4'
      ) {
        message.error('Only MP4 format is supported in video!')
        return Upload.LIST_IGNORE
      }
    },
    [hasVedio]
  )

  useEffect(() => {
    if (getuploadData.some((item) => item.video_preview)) {
      setHasVedio(true)
    }
    setFileList(getuploadData)
  }, [getuploadData])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  return (
    <>
      <Upload
        action={`/backend/bbs/upload?___country=${country}`}
        headers={token}
        disabled={!country}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        {isVedio ? (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video controls type="video" width="100%">
            <source src={previewImage} type="video/mp4" />
            暂不支持video预览
          </video>
        ) : (
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        )}
      </Modal>
    </>
  )
}

export default UploadList
