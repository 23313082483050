import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Select, Space, Card, Typography, DatePicker, Button } from 'antd'
import { getStaticText, storage } from '../../../utils'
import services from '../../../services'
import dateFormat from '../../../config/dateFormat'

const dateFormatMonth = dateFormat.month
const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const requestParams = {
  country: ['All_Country'],
  diskCatalogId: ['0'],
  brand: ['0'],
  serie: ['0'],
  generation: ['0'],
  start_date: moment().subtract('months', 1),
  end_date: moment().subtract('months')
}

const requestOption = {
  grid: {
    left: 10,
    containLabel: true
  },
  toolbox: {
    show: true,
    feature: {
      dataView: {
        readOnly: false,
        title: '编辑'
      },
      restore: {
        show: true,
        title: '重置'
      },
      saveAsImage: {
        show: true,
        title: '下载'
      }
    }
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {
    data: []
  },
  xAxis: {
    name: '',
    type: 'value',
    boundaryGap: [0, '100%']
  },
  yAxis: [
    {
      axisLabel: {
        inside: true,
        fontWeight: 400,
        fontSize: 14,
        color: '#000'
      },
      z: 10,
      type: 'category',
      data: []
    }
  ],
  series: [
    {
      itemStyle: {
        color: 'rgb(137,205,233)'
      },
      name: '',
      type: 'bar',
      data: []
    }
  ]
}

class BrandProducts extends Component {
  constructor(props) {
    // 必须在这里通过super调用父类的constructor
    super(props)
    // 从URL获取参数
    const nextSearch = new URLSearchParams(props.location.search)
    const endDate = nextSearch.get('end_date')
      ? moment(nextSearch.get('end_date'), dateFormatMonth)
      : requestParams.end_date
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatMonth)
      : requestParams.start_date
    const country = nextSearch.get('country')
      ? nextSearch.get('country').split(',')
      : requestParams.country
    const diskCatalogId = nextSearch.get('disk_catalog_id')
      ? nextSearch.get('country').split(',')
      : requestParams.diskCatalogId
    const brand = nextSearch.get('brand')
      ? nextSearch.get('brand').split(',')
      : requestParams.brand
    this.state = {
      countries: this.checkCountries(country),
      disk_catalog: this.checkDiskCatalogList(diskCatalogId),
      diskCatalogId,
      country,
      start_date: startDate,
      start_format: startDate.format(dateFormatMonth),
      end_date: endDate,
      end_format: endDate.format(dateFormatMonth),
      map_list: [],
      catalogs: [],
      brand,
      brandList: this.checkBrands(brand),
      serie: requestParams.serie,
      seriesList: this.getSeries(requestParams.serie, brand, diskCatalogId),
      generation: requestParams.generation,
      generationList: this.getGeneration(
        requestParams.generation,
        requestParams.serie
      )
    }
    this.getBrandReportsCatalog()
  }

  getBrandReportsCatalog = () => {
    services.brand.getBrandReportsCatalog().then((result) => {
      this.setState(
        (prevState) => ({
          catalogs: result.catalog_list,
          brandList: this.checkBrands(prevState.brand, result.catalog_list)
        }),
        () => {
          this.useEffect()
        }
      )
    })
  }

  getrequest = () => ({
    country: this.state.country.toString(),
    diskCatalogId: this.state.diskCatalogId.toString(),
    start_date: this.state.start_format,
    end_date: this.state.end_format,
    brand: this.state.brand.toString(),
    serie: this.state.serie.toString(),
    generation: this.state.generation.toString()
  })

  handleWeekExport = () => {
    services.mapList.weekCatalogExport({}).then((result) => {
      const { url } = result
      window.location.href = url
    })
  }

  handleReset = () => {
    this.setState({
      country: requestParams.country,
      diskCatalogId: requestParams.diskCatalogId,
      start_date: requestParams.start_date,
      start_format: requestParams.start_date.format(dateFormatMonth),
      end_date: requestParams.end_date,
      end_format: requestParams.end_date.format(dateFormatMonth)
    })
  }

  handleCountryChange = (country) => {
    this.setState({
      country,
      countries: this.checkCountries(country)
    })
  }

  handlediskCatalogChange = (diskCatalogId) => {
    this.setState({
      diskCatalogId,
      disk_catalog: this.checkDiskCatalogList(diskCatalogId),
      seriesList: this.getSeries([], [], diskCatalogId),
      serie: requestParams.serie,
      generation: requestParams.generation,
      generationList: this.getGeneration(
        requestParams.generation,
        requestParams.serie
      )
    })
  }

  handleDateChange = (date, dateString) => {
    this.setState({
      start_date: moment(dateString[0], dateFormatMonth),
      start_format: dateString[0],
      end_date: moment(dateString[1], dateFormatMonth),
      end_format: dateString[1]
    })
  }

  setUrlSearchForState = () => {
    const params = new URLSearchParams(this.getrequest())
    this.props.history.push(
      `${this.props.location.pathname}?${params.toString()}`
    )
  }

  checkCountries = (country) => {
    const countries = storage.allowedCountries.val
    if (country.indexOf('All_Country') >= 0) {
      countries.forEach((country) => {
        country.disabled = true
      })
      countries[0].disabled = false
    } else if (country.length === 0) {
      countries.forEach((country) => {
        country.disabled = false
      })
    } else {
      countries[0].disabled = true
    }
    return countries
  }

  checkDiskCatalogList = (diskCatalogId) => {
    const diskCatalogList = storage.disk_catalog_list.val
    if (diskCatalogId.indexOf('0') >= 0) {
      diskCatalogList.forEach((diskCatalog) => {
        diskCatalog.disabled = true
      })
      diskCatalogList[0].disabled = false
    } else if (diskCatalogId.length === 0) {
      diskCatalogList.forEach((diskCatalog) => {
        diskCatalog.disabled = false
      })
    } else {
      diskCatalogList[0].disabled = true
    }
    return diskCatalogList
  }

  useEffect = () => {
    this.setUrlSearchForState()
    this.fetchData()
  }

  fetchData = () => {
    services.brand.getBrandProducts(this.getrequest()).then((result) => {
      const mapList = []
      Object.keys(result.series).forEach((item, index) => {
        const map = []
        map.index = index
        map.height = `${result.series[item].data.length * 50 + 100}px`
        map.option = {
          ...requestOption,
          title: {
            text: ''
          },
          yAxis: [
            {
              type: 'category',
              axisLabel: {
                inside: true,
                fontWeight: 400,
                fontSize: 14,
                color: '#000'
              },
              z: 10,
              data: result.series[item].product_name
            }
          ],
          series: [
            {
              itemStyle: {
                color: 'rgb(137,205,233)'
              },
              name: '销量',
              type: 'bar',
              data: result.series[item].data
            }
          ]
        }
        mapList.push(map)
      })
      this.setState({
        map_list: mapList
      })
    })
  }

  getMaps = () => {
    if (this.state.map_list) {
      return this.state.map_list.map((map) => {
        return (
          <ReactEcharts
            style={{ width: '100%', height: map.height }}
            option={map.option}
            key={map.index}
          />
        )
      })
    }
  }

  checkBrands = (brand, catalogs = []) => {
    if ((this.state && this.state.catalogs[0]) || catalogs[0]) {
      catalogs = catalogs[0] ? catalogs : this.state.catalogs
      const brandList = catalogs[0].map((catalog) => {
        return {
          label: catalog.title,
          value: catalog.id,
          disabled: false
        }
      })
      brandList.unshift({
        label: '全部',
        value: '0',
        disabled: false
      })
      if (brand.indexOf('0') >= 0) {
        brandList.forEach((brand) => {
          brand.disabled = true
        })
        brandList[0].disabled = false
      } else if (brand.length === 0) {
        brandList.forEach((brand) => {
          brand.disabled = false
        })
      } else {
        brandList[0].disabled = true
      }
      return brandList
    }
    return []
  }

  handleBrandChange = (brand) => {
    this.setState({
      brand,
      brandList: this.checkBrands(brand),
      serie: requestParams.serie,
      seriesList: this.getSeries(requestParams.serie, brand),
      generationList: this.getGeneration(
        requestParams.generation,
        requestParams.serie
      ),
      generation: requestParams.generation
    })
  }

  getSeries = (series = [], brand = [], diskCatalogId = []) => {
    series = series.length > 0 ? series : this.state.series
    brand = brand.length > 0 ? brand : this.state.brand
    diskCatalogId =
      diskCatalogId.length > 0 ? diskCatalogId : this.state.diskCatalogId

    const seriesList = []
    if (this.state && this.state.catalogs[0] && brand.length > 0) {
      brand.forEach((b) => {
        this.state.catalogs[b].forEach((catalog) => {
          if (
            diskCatalogId.indexOf('0') >= 0 ||
            diskCatalogId.indexOf(catalog.category_type) >= 0
          ) {
            seriesList.push({
              label: catalog.title,
              value: catalog.id,
              disabled: false
            })
          }
        })
      })
    }
    seriesList.unshift({
      label: '全部',
      value: '0',
      disabled: false
    })
    if (series && series.length > 0) {
      if (series.indexOf('0') >= 0) {
        seriesList.forEach((brand) => {
          brand.disabled = true
        })
        seriesList[0].disabled = false
      } else if (series.length === 0) {
        seriesList.forEach((brand) => {
          brand.disabled = false
        })
      } else {
        seriesList[0].disabled = true
      }
    }
    return seriesList
  }

  handleSeriesChange = (serie) => {
    this.setState({
      serie,
      seriesList: this.getSeries(serie),
      generation: requestParams.generation,
      generationList: this.getGeneration(requestParams.generation, serie)
    })
  }

  getGeneration = (generation, serie = []) => {
    serie = serie.length > 0 ? serie : this.state.serie
    const generationList = []
    if (this.state && this.state.catalogs[0] && serie.length > 0) {
      serie.forEach((b) => {
        if (this.state.catalogs[b] && this.state.catalogs[b].length > 0) {
          this.state.catalogs[b].forEach((catalog) => {
            generationList.push({
              label: catalog.title,
              value: catalog.id,
              disabled: false
            })
          })
        }
      })
    }
    generationList.unshift({
      label: '全部',
      value: '0',
      disabled: false
    })
    if (generation && generation.length > 0) {
      if (generation.indexOf('0') >= 0) {
        generationList.forEach((generation) => {
          generation.disabled = true
        })
        generationList[0].disabled = false
      } else if (generation.length === 0) {
        generationList.forEach((generation) => {
          generation.disabled = false
        })
      } else {
        generationList[0].disabled = true
      }
    }
    return generationList
  }

  handleGenerationChange = (generation) => {
    this.setState({
      generation,
      generationList: this.getGeneration(generation)
    })
  }

  render() {
    return (
      <>
        <Card
          style={{
            marginBottom: 20
          }}
        >
          <Space>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.country.text')}
            </Text>
            <Select
              style={{ width: 150 }}
              value={this.state.country}
              onChange={this.handleCountryChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.countries.map((item) => (
                <Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.brand.text')}
            </Text>
            <Select
              style={{ width: 150 }}
              value={this.state.brand}
              onChange={this.handleBrandChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.brandList.map((item) => (
                <Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.category_type.text')}
            </Text>
            <Select
              style={{ width: 150 }}
              value={this.state.diskCatalogId}
              onChange={this.handlediskCatalogChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.disk_catalog.map((item) => (
                <Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.serie.text')}
            </Text>
            <Select
              style={{ width: 150 }}
              value={this.state.serie}
              onChange={this.handleSeriesChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.seriesList.map((item) => (
                <Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.generation.text')}
            </Text>
            <Select
              style={{ width: 150 }}
              value={this.state.generation}
              onChange={this.handleGenerationChange}
              mode="multiple"
              maxTagCount="responsive"
            >
              {this.state.generationList.map((item) => (
                <Option key={item.value} disabled={item.disabled}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Text style={{ width: 35, display: 'inline-block' }}>
              {getStaticText('component.search.time.text')}
            </Text>
            <RangePicker
              picker="month"
              value={[this.state.start_date, this.state.end_date]}
              format={dateFormatMonth}
              onChange={this.handleDateChange}
              allowClear={false}
            />
          </Space>
          <Space style={{ cssFloat: 'right' }}>
            <Button type="primary" onClick={this.useEffect}>
              {getStaticText('component.search.text')}
            </Button>
          </Space>
        </Card>
        {this.getMaps()}
      </>
    )
  }
}

BrandProducts.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default BrandProducts
