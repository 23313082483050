import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'
import services from '../../../services'
import { setOptionSources } from '../../../store/actions/global'

const { Option } = Select

interface Props {
  source: String;
  optionSources: Object;
  getOptionSources: () => {};
}

const OptionColumnComponent = ({
  source,
  optionSources,
  getOptionSources,
  value
}: Props) => {
  useEffect(() => {
    if (!optionSources) {
      getOptionSources()
    }
  }, [getOptionSources, optionSources])

  if (!optionSources) {
    return null
  }
  // eslint-disable-next-line eqeqeq
  return <>{optionSources[source].map((v) => v.value == value && v.label)}</>
}

const OptionSelect = ({
  source,
  optionSources,
  getOptionSources,
  ...props
}: Props) => {
  useEffect(() => {
    if (!optionSources) {
      getOptionSources()
    }
  }, [getOptionSources, optionSources])

  if (!optionSources) {
    return null
  }
  return (
    <Select {...props}>
      {optionSources[source].map((type) => (
        <Option key={type.value} value={type.value}>
          {type.label}
        </Option>
      ))}
    </Select>
  )
}
const mapStateToProps = ({ global }) => ({
  optionSources: global.option_sources
})
let iFetch = 0
const mapDispatchToProps = (dispatch) => ({
  getOptionSources: () =>
    // eslint-disable-next-line no-plusplus
    iFetch++ === 0 &&
    services.system
      .getOptionSources()
      .then((resp) => dispatch(setOptionSources(resp)))
})
export const OptionColumn = connect(
  mapStateToProps,
  mapDispatchToProps
)(OptionColumnComponent)

export default connect(mapStateToProps, mapDispatchToProps)(OptionSelect)
