import React from 'react'
import { getStaticText } from '../../utils'

const viewTableColumns = [
  {
    title: (
      <div>
        {getStaticText('weekly.sales.table.country')}
        &nbsp;
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.name')}
        &nbsp;
      </div>
    ),
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.start_time')}
        &nbsp;
      </div>
    ),
    dataIndex: 'start_time',
    key: 'start_time',
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.end_time')}
        &nbsp;
      </div>
    ),
    dataIndex: 'end_time',
    key: 'end_time',
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.period')}
        &nbsp;
      </div>
    ),
    dataIndex: 'period',
    key: 'period',
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.user_count')}
        &nbsp;
      </div>
    ),
    dataIndex: 'user_count',
    key: 'user_count',
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.budget')}
        &nbsp;
      </div>
    ),
    dataIndex: 'budget',
    key: 'budget',
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('big-query.percentage')}
        &nbsp;
      </div>
    ),
    dataIndex: 'percentage',
    key: 'percentage',
    align: 'center'
  }
]

export default viewTableColumns
