import React, { useContext } from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import { PermissionButton, GridContext } from '../../../../../../components'
import { deleteActivityItem } from '../../services'

interface Props {
  record: Object;
  onChange: () => {};
}
const Actions = ({ record, onChange }: Props) => {
  const { toggle } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit_item')
  }
  const onDelete = () => {
    deleteActivityItem(onChange)(record.id)
  }
  return (
    <>
      <Tooltip title="edit">
        <PermissionButton
          size="small"
          onClick={onEdit}
          icon={<EditOutlined />}
          permission="luckyspin/activity/item/save"
          type="primary"
        />
      </Tooltip>
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <Tooltip title="delete">
          <PermissionButton
            style={{ marginLeft: '5px' }}
            icon={<DeleteOutlined />}
            size="small"
            permission="luckyspin/activity/item/delete"
            type="primary"
            danger
          />
        </Tooltip>
      </Popconfirm>
    </>
  )
}
export default Actions
