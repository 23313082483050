import { ReviewList } from '../pages'

const Review = {
  path: '/review',
  primary: 'app.sider.review.menu',
  routes: [
    {
      path: '/review/review-list',
      permission: 'get-review-details',
      primary: 'app.sider.review.list',
      component: ReviewList,
      exact: true
    }
  ]
}

export default Review
