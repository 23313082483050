import React, { useEffect, useState, useContext } from 'react'
import { Form, Row, Col, Input } from 'antd'
import { GridFormDrawer, GridContext } from '../../../../components'
import { updatePermission, createPermission } from '../services'

const PermissionForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const { record, onLoad, toggle } = useContext(GridContext)
  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }
  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  const onFinish = (values) => {
    if (record.id) {
      updatePermission(closeDrawer)({ data: values, id: record.id })
    } else {
      createPermission(closeDrawer)({ data: values })
    }
  }

  return (
    <GridFormDrawer
      onOpen={setFormData}
      onSave={() => form.submit()}
      type="edit|create"
      title="Edit Form"
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: 'Please enter permission name' }
              ]}
            >
              <Input placeholder="Please enter permission name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="path"
              label="Path"
              rules={[{ required: true, message: 'Please enter path' }]}
            >
              <Input placeholder="Please enter path" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="remark" label="Remark">
              <Input placeholder="Remark" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </GridFormDrawer>
  )
}
export default PermissionForm
