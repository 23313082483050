import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'

import App from './App'
import { Login, UploadSales, DashboardDaily } from './pages'
import { AuthRoute, Captcha } from './components'
import store from './store'
import 'antd/dist/antd.css'
import './index.css'
// import PotentialError from './components/PotentialError/potentialError';

moment.locale('zh-cn', {
  week: {
    dow: 0 // 周天为一周的第一天
  }
})

ReactDOM.render(
  // <PotentialError>
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL || '/'}>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/upload-sales" component={UploadSales} exact />
          <Route path="/daily.html" component={DashboardDaily} exact />
          <AuthRoute path="/" component={App} />
        </Switch>
      </Router>
      <Captcha />
    </Provider>
  </ConfigProvider>,
  // </PotentialError>,
  document.getElementById('root')
)
