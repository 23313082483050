import React, { useEffect, useState, useMemo, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Row, Col, Input } from 'antd'

import {
  GridFormDrawer,
  CountrySelect,
  GridContext
} from '../../../../components'
import { createTags, saveTags } from '../services'

const TagsForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const location = useLocation()

  const filterCountry = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('___country')
  }, [location])

  const { onLoad, toggle } = useContext(GridContext)
  const emptyForm = () => {
    setFormData({})
    form.resetFields()
  }

  const closeDrawer = () => {
    emptyForm()
    toggle({}, '')
    onLoad()
  }

  const openHandler = (record) => {
    setFormData({
      id: record.id,
      country: filterCountry,
      name: record.name,
      description: record.description
    })
  }

  const onFinish = (values) => {
    const formatValues = {
      name: values.name,
      slug: values.name,
      description: values.description || '',
      color: '',
      icon: '',
      isHidden: false,
      primary: true
    }

    if (formatValues.description === '') delete formatValues.description
    if (formData.id) delete formatValues.slug

    const params = {
      data: {
        type: 'tags',
        attributes: {
          ...formatValues
        }
      }
    }

    if (formData.id) {
      params.data = Object.assign(params.data, { id: formData.id })
      saveTags(closeDrawer)({
        id: formData.id,
        country: values.country,
        params
      })
    } else {
      createTags(closeDrawer)({ country: values.country, params })
    }
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Tag' : 'New Tag')}
    >
      <Form form={form} onFinish={onFinish} autoComplete="off">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please select country' }]}
            >
              <CountrySelect />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Tag Name"
              name="name"
              rules={[{ required: true, message: 'Please enter tag name' }]}
            >
              <Input placeholder="Please enter tag name" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="description" label="Description">
              <Input placeholder="Please enter description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </GridFormDrawer>
  )
}
export default TagsForm
