import React, { useEffect, useCallback, useState, useContext } from 'react'
import { Form, Row, Col, Input, Switch, DatePicker } from 'antd'
import moment from 'moment'

import {
  GridFormDrawer,
  SearchSelect,
  CountrySelect,
  GridContext,
  TranslatableForm
} from '../../../../../components'
import { getActivityDetail, updateActivity, createActivity } from '../services'
import services from '../../../../../services'

const dateFormat = 'YYYY-MM-DD hh:mm:ss'

const ActivityForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})

  const [country, setCountry] = useState('')
  const countryChange = (value) => {
    setCountry(value)
  }

  const { onLoad, toggle } = useContext(GridContext)
  const emptyForm = () => {
    setFormData({})
    setCountry('')
    form.resetFields()
  }
  const closeDrawer = () => {
    emptyForm()
    toggle({}, '')
    onLoad()
  }

  const formatResData = useCallback((value) => {
    const newValue = Object.assign(value, {
      start_time: moment(value.start_time, dateFormat),
      end_time: moment(value.end_time, dateFormat),
      enabled: !!Number(value.enabled),
      product: { value: value.product.id, label: value.product.name }
    })
    setCountry(newValue.country)
    setFormData(newValue)
  }, [])

  const openHandler = (id) => {
    getActivityDetail(formatResData)(id)
  }

  const onFinish = (values) => {
    const formatValue = {
      country: values.country,
      title: values.title,
      start_time: values.start_time.format(dateFormat),
      end_time: values.end_time.format(dateFormat),
      initial_ordered: values.initial_ordered,
      target_amount: values.target_amount,
      enabled: values.enabled ? '1' : '0',
      product_id: values.product.value.toString()
    }
    if (formData.id) {
      updateActivity(closeDrawer)({ id: formData.id, data: formatValue })
    } else {
      createActivity(closeDrawer)({ country, data: formatValue })
    }
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Activity' : 'New Activity')}
    >
      <TranslatableForm entity="crowd_founding_activity">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Countries"
                name="country"
                rules={[{ required: true, message: 'Please select country' }]}
              >
                <CountrySelect onChange={countryChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[
                  { required: true, message: 'Please enter activity title' }
                ]}
              >
                <Input placeholder="Please enter activity title" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="start_time"
                label="Start time"
                rules={[{ required: true, message: 'Please enter start time' }]}
              >
                <DatePicker showTime />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="end_time"
                label="End time"
                rules={[{ required: true, message: 'Please enter end time' }]}
              >
                <DatePicker showTime />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="initial_ordered"
                label="Initial Ordered"
                rules={[
                  {
                    required: true,
                    message: 'Please enter initial ordered quantity'
                  }
                ]}
              >
                <Input placeholder="Please enter initial ordered quantity" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="target_amount"
                label="Target amount"
                rules={[
                  { required: true, message: 'Please enter target amount' }
                ]}
              >
                <Input placeholder="Please enter target amount" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Enabled" name="enabled" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Select product" name="product">
                <SearchSelect
                  showSearch
                  country={country}
                  fetchApi={services.bbs.getBbsProduct}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </TranslatableForm>
    </GridFormDrawer>
  )
}

export default ActivityForm
