import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Input, DatePicker, Switch } from 'antd'

const Vote = (props) => {
  const { formData } = props
  return (
    <>
      {formData.vote_title && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Vote Title" name="vote_title">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      )}
      {formData.vote_end_time && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="vote_end_time" label="Closing Date">
              <DatePicker showTime />
            </Form.Item>
          </Col>
        </Row>
      )}
      {formData.vote_title && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Secret Ballot"
              name="vote_public_poll"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      )}
      {formData.vote_options && (
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Vote Options" />
          </Col>
        </Row>
      )}
      {formData.vote_options &&
        Object.keys(formData.vote_options).map((option, index) => {
          return (
            <Row key={option} gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={String.fromCharCode('A'.charCodeAt(0) + index)}
                  name={['vote_options', option]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          )
        })}
    </>
  )
}

Vote.propTypes = {
  formData: PropTypes.shape().isRequired
}

export default Vote
