import request from './request'

// crowd funding
export const getActivityDetail = (params) =>
  request.get('/backend/crowd-founding/activity/detail', { params })

export const getActivityList = (params) =>
  request.get('/backend/crowd-founding/activity/get-list', { params })

export const saveActivity = ({ id, data }) => {
  return request.post(`/backend/crowd-founding/activity/save?id=${id}`, {
    data
  })
}

export const createActivity = ({ country, data }) =>
  request.post(
    `/backend/crowd-founding/activity/create?___country=${country}`,
    { data }
  )

export const deleteActivity = ({ id, data }) =>
  request.post(`/backend/crowd-founding/activity/delete?id=${id}`, {
    data
  })

export const getOrderList = (params) =>
  request.get('/backend/crowd-founding/order/get-list', { params })

// rewrite
export const getRewriteList = (params) =>
  request.get('/backend/external-system/rewrite/get-list', { params })

export const getRewriteDetail = (params) =>
  request.get('/backend/external-system/rewrite/detail', { params })

export const updateRewrite = (params) => {
  return request.post('/backend/external-system/rewrite/edit', params)
}

export const createRewrite = (params) =>
  request.post('/backend/external-system/rewrite/create', params)

export const deleteRewrite = (params) =>
  request.post('/backend/external-system/rewrite/delete', params)

export const getOptionSources = (params) =>
  request.get('/backend/external-system/option-source', params)

export const getCategoryList = (params) =>
  request.get('/backend/crowd-founding/category/get-list', { params })

export const getCategoryDetail = (params) =>
  request.get('/backend/crowd-founding/category/detail', { params })

export const saveCategory = (params) =>
  request.post('/backend/crowd-founding/category/save', params)

export const deleteCategory = (params) =>
  request.post('/backend/crowd-founding/category/delete', params)

export const getBannerList = (params) =>
  request.get('/backend/crowd-founding/banner/get-list', { params })

export const getBannerDetail = (params) =>
  request.get('/backend/crowd-founding/banner/detail', { params })

export const saveBanner = (params) =>
  request.post('/backend/crowd-founding/banner/save', params)

export const deleteBanner = (params) =>
  request.post('/backend/crowd-founding/banner/delete', params)

export const topActivity = (params) =>
  request.post('/backend/crowd-founding/category/top-activity', params)

export const saveActivityRelation = (params) =>
  request.post(
    '/backend/crowd-founding/category/save-activity-relation',
    params
  )

export const getCategoryActivities = (params) =>
  request.get('/backend/crowd-founding/category/get-activities', { params })
