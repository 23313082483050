import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Card,
  DatePicker,
  Row,
  Col,
  Typography,
  Space,
  Table,
  Select,
  Button,
  Input
} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import { getStaticText, storage } from '../../utils'
import services from '../../services'
import dateFormat from '../../config/dateFormat'
import { daylyTableHomePageColumns } from '../../config/tableColumns'

const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

// 周日期格式化标准
const dateFormatDay = dateFormat.day

const DailyHomePage = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  // 默认请求参数 默认当前周减12
  const defaultReqData = {
    country: 'All_Country',
    platform: 'app',
    type: 'All_Types',
    title: '',
    start_day: moment().subtract('day', 12),
    end_day: moment().subtract('day', 1),
    current: 1,
    pageSize: 20
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const nextSearchObj = () => {
    return {
      country: nextSearch.get('country'),
      platform: nextSearch.get('platform'),
      start_day: nextSearch.get('start_day')
        ? moment(nextSearch.get('start_day'), dateFormatDay)
        : null,
      end_day: nextSearch.get('end_day')
        ? moment(nextSearch.get('end_day'), dateFormatDay)
        : null
    }
  }
  Object.keys(nextSearchObj()).forEach((item) => {
    if (nextSearchObj()[item]) defaultReqData[item] = nextSearchObj()[item]
  })

  const [tableTopData, setTableTopData] = useState([])
  const [total, setTotal] = useState([])

  const [requestParams, setRequestParams] = useState(() => {
    return {
      country: defaultReqData.country
        .split(',')
        .filter((item) => !(item === '')),
      platform: defaultReqData.platform
        .split(',')
        .filter((item) => !(item === '')),
      type: defaultReqData.type.split(',').filter((item) => !(item === '')),
      title: defaultReqData.title,
      start_day: defaultReqData.start_day,
      end_day: defaultReqData.end_day,
      start_format: defaultReqData.start_day.format(dateFormatDay),
      end_format: defaultReqData.end_day.format(dateFormatDay),
      current: defaultReqData.current,
      pageSize: defaultReqData.pageSize
    }
  })
  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      country: requestParams.country.toString(),
      platform: requestParams.platform.toString(),
      type: requestParams.type.toString(),
      title: requestParams.title,
      start_day: requestParams.start_format,
      end_day: requestParams.end_format,
      current: requestParams.current,
      pageSize: requestParams.pageSize
    }
  }, [requestParams])

  // countries 判断 disabled 属性
  const countries = useMemo(() => {
    const countrys = storage.allowedCountries.val || []
    if (requestParams.country.length) {
      countrys.forEach((country) => {
        country.disabled = !requestParams.country.includes(country.value)
      })
    } else {
      countrys.forEach((country) => {
        country.disabled = false
      })
    }
    return countrys
  }, [requestParams])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const platforms = useMemo(() => {
    const platformes = [
      {
        value: 'All_Platform',
        disabled: false,
        label: '所有平台'
      },
      {
        value: 'app',
        disabled: true,
        label: 'app'
      },
      {
        value: 'pc',
        disabled: true,
        label: 'pc'
      },
      {
        value: 'mb',
        disabled: true,
        label: 'mb'
      }
    ]

    if (requestParams.platform.length) {
      platformes.forEach((platform) => {
        platform.disabled = !requestParams.platform.includes(platform.value)
      })
    } else {
      platformes.forEach((platform) => {
        platform.disabled = false
      })
    }
    return platformes
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const types = useMemo(() => {
    const types = [
      {
        value: 'All_Types',
        disabled: true,
        label: '全部模块'
      },
      {
        value: 'search',
        disabled: true,
        label: '搜索'
      },
      {
        value: 'banner',
        disabled: false,
        label: 'banner'
      },
      {
        value: 'icon',
        disabled: true,
        label: 'icon'
      },
      {
        value: 'middles',
        disabled: true,
        label: '腰通'
      }
    ]

    if (requestParams.type.length) {
      types.forEach((type) => {
        type.disabled = !requestParams.type.includes(type.value)
      })
    } else {
      types.forEach((type) => {
        type.disabled = false
      })
    }
    return types
  })

  const fetchData = useCallback(() => {
    toggleLoading()
    services.dailySales
      .getdailyHomePage(requestParamsTransformer)
      .then((result) => {
        setTableTopData(result.data)
        setTotal(result.total)
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  useEffect(() => {
    if (!isLoad) {
      fetchData()
    }
    setIsLoad(true)
  }, [fetchData, isLoad])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country
    }))
  }

  const handlePlatformChange = (platform) => {
    setRequestParams((prevState) => ({
      ...prevState,
      platform
    }))
  }

  const handleTileChange = (title) => {
    setRequestParams((prevState) => ({
      ...prevState,
      title: title.target.value
    }))
  }

  const handleTypeChange = (type) => {
    setRequestParams((prevState) => ({
      ...prevState,
      type
    }))
  }

  const handleChangeDate = useCallback((date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      start_day: date[0],
      end_day: date[1],
      start_format: dateString[0],
      end_format: dateString[1]
    }))
  }, [])

  const filter = () => {
    fetchData()
    setUrlSearchForState()
  }

  const handleCurrentChange = (current, pageSize) => {
    setRequestParams((prevState) => ({
      ...prevState,
      current,
      pageSize
    }))
    filter()
  }

  const [exportLoading, setExportLoading] = useState(false)

  const handleExport = () => {
    setExportLoading(true)

    services.dailySales
      .getExportHomePage(requestParamsTransformer)
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
      .finally(() => {
        setExportLoading(false)
      })
  }

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text>{getStaticText('weekly.home.table.platform')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.platform}
            onChange={handlePlatformChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {platforms.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text>{getStaticText('weekly.home.table.type')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.type}
            onChange={handleTypeChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {types.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text>{getStaticText('weekly.home.table.title')}</Text>
          <Input
            style={{ width: '200px' }}
            value={requestParams.title}
            onChange={handleTileChange}
            placeholder={getStaticText('weekly.home.table.title')}
          />
        </Space>
        <Space style={{ marginTop: 20 }}>
          <Text>{getStaticText('component.search.time.text')}</Text>
          <RangePicker
            picker="day"
            value={[requestParams.start_day, requestParams.end_day]}
            format={dateFormatDay}
            allowClear={false}
            onChange={handleChangeDate}
          />
          <Button type="primary" onClick={filter}>
            {getStaticText('component.search.text')}
          </Button>
        </Space>
      </Card>

      <Card>
        <Row>
          <Col span={12}>
            <Text>{getStaticText('component.table.header.title')}</Text>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button onClick={handleExport} loading={exportLoading}>
              {getStaticText('component.table.export.text')}
            </Button>
          </Col>
        </Row>
      </Card>
      <Table
        sticky
        bordered
        size="small"
        loading={isLoading}
        columns={daylyTableHomePageColumns}
        dataSource={tableTopData}
        rowKey={(record) => record.id}
        scroll={{ x: 'max-content' }}
        pagination={{
          size: 'small',
          showSizeChanger: true,
          showQuickJumper: true,
          total,
          showTotal: () => `共${total}条数据`,
          pageSize: requestParamsTransformer.pageSize,
          current: requestParamsTransformer.current,
          onChange: handleCurrentChange
        }}
      />
    </>
  )
}

DailyHomePage.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default DailyHomePage
