import React, { Component } from 'react'
import { Tabs } from 'antd'
import ReactEcharts from 'echarts-for-react'
import { func } from 'prop-types'
import services from '../../../services'
import AddPercent from '../Tool/AddPercent'
import MyTooltip from '../Tool/MyTooltip'

const requestOptionPreass = {
  legend: {
    data: []
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      animation: 'false'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  yAxis: [
    {
      name: ''
    }
  ],
  series: []
}

class LeftCoreFrame extends Component {
  constructor(props) {
    const { onRef } = props
    super(props)
    onRef(this.useEffect)
    this.state = {
      list: [],
      option_list: {},
      key: 'uv'
    }
  }

  useEffect = (params) => {
    this.fetchData(params)
  }

  getTabsModel = (item) => {
    return (
      <div className="core-tab">
        <div className="core-head">
          <div className="core-head-title">
            <h4>
              {item.name}
              <MyTooltip account={item.account} />
            </h4>
            <div>{item.today}</div>
          </div>
        </div>
        <div className="core-body">
          <div>
            <span>
              较前一日
              <MyTooltip account="较前一时间段" />
            </span>
            <span className="mgl30">
              <AddPercent percentString={item.yesterday} />
            </span>
          </div>
          <div>
            <span>APP占比</span>
            <span className="mgl30">{item.app_rate}</span>
          </div>
        </div>
      </div>
    )
  }

  getTabPane = () => {
    if (this.state.list.length > 1) {
      return this.state.list.map((item) => {
        return (
          <Tabs.TabPane tab={this.getTabsModel(item)} key={item.key}>
            <ReactEcharts
              style={{ width: '100%', height: '300px' }}
              option={this.state.option_list[item.key]}
              key={item.key}
            />
          </Tabs.TabPane>
        )
      })
    }
    return ''
  }

  fetchData = (params) => {
    services.dashboard.getCore(params).then((result) => {
      // eslint-disable-next-line camelcase
      const option_list = {}
      result.map_data_list.forEach((item) => {
        option_list[item.key] = {
          ...requestOptionPreass
        }
        option_list[item.key].xAxis[0].data = item.catalog
        option_list[item.key].series = item.series
        option_list[item.key].legend.data = item.legend
      })
      this.setState({
        list: result.list,
        option_list
      })
    })
  }

  switchTab = (key) => {
    this.setState({ key })
  }

  render() {
    return (
      <>
        <div className="left-frame">
          <div className="title">
            <div className="title-name">
              核心指标
              <MyTooltip account="时间段内数据" />
            </div>
          </div>
          <div className="width-max">
            <Tabs
              activeKey={this.state.key}
              type="card"
              onChange={this.switchTab}
            >
              {this.getTabPane()}
            </Tabs>
          </div>
        </div>
      </>
    )
  }
}

LeftCoreFrame.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onRef: func
}

export default LeftCoreFrame
