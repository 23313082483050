import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function FlowPlatform(props) {
  const { tableData } = props
  return (
    <>
      {/* 流量-平台 */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="3" width="100" className="sticky-left sticky-second">
          流量-平台
        </th>
        <th colSpan="3">平台总体月活（UV）</th>
        <th colSpan="3">
          获客成本 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                {/* <p>来源：(DM所有实际费用+补贴)/所有下单数</p> */}
                <p>来源：(市场总费用)/所有下单数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          UV价值 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：成交金额/A人群数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          平台注册用户总数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：日报累计</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          新增注册用户
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          APP下载量
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据导入</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          APP激活数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据导入</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          APP用户平均使用时长
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：firebase拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row2 */}
      <tr>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：ga数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据导入</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：UV/去年UV-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际市场总费用/实际总订单数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：预算市场总费用/预算总订单数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：获客成本/去年同期获客成本-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际GMV/实际A人群</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：预算GMV/预算A人群</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际UV价值/去年实际UV-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：来源无</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前累计注册用户数/去年同期累计注册用户数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：来源无</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前新增注册用户数/去年同期新增注册用户数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：APP下载量/去年同期APP下载量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：APP激活数/去年同期APP激活数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：firebase拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：APP用户平均使用时长/APP用户平均使用时长-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.uv_actual}</td>
        <td>{tableData.uv_budget}</td>
        <td>{tableData.uv_rate_year_on_year}</td>
        <td>{tableData.cost_on_customer_actual}</td>
        <td>{tableData.cost_on_customer_budget}</td>
        <td>{tableData.cost_on_customer_rate_year_on_year}</td>
        <td>{tableData.people_uv_value_actual}</td>
        <td>{tableData.people_uv_value_budget}</td>
        <td>{tableData.people_uv_value_rate_year_on_year}</td>
        <td>{tableData.cumulative_register_totals_actual}</td>
        <td>{tableData.cumulative_register_totals_budget}</td>
        <td>{tableData.cumulative_register_totals_rate_year_on_year}</td>
        <td>{tableData.new_register_totals_actual}</td>
        <td>{tableData.new_register_totals_budget}</td>
        <td>{tableData.new_register_totals_rate_year_on_year}</td>
        <td>{tableData.download_amount_actual}</td>
        <td>{tableData.download_amount_budget}</td>
        <td>{tableData.download_amount_rate_year_on_year}</td>
        <td>{tableData.app_activation_number_actual}</td>
        <td>{tableData.app_activation_number_budget}</td>
        <td>{tableData.app_activation_number_rate_year_on_year}</td>
        <td>{tableData.app_use_time_actual}</td>
        <td>{tableData.app_use_time_budget}</td>
        <td>{tableData.app_use_time_rate_year_on_year}</td>
      </tr>
    </>
  )
}

FlowPlatform.propTypes = {
  tableData: shape({}).isRequired
}

export default FlowPlatform
