import services, {
  withTableLoading,
  withGlobalLoading,
  withTranslation
} from '../../../../services'

export const getActivityList = (handler) =>
  withTableLoading((params) =>
    services.luckyspin.getActivityList(params).then(handler)
  )

export const getActivityDetail = (handler) =>
  withGlobalLoading((id) =>
    services.luckyspin.getActivityDetail({ id }).then(handler)
  )

export const deleteActivity = (handler) =>
  withGlobalLoading((id) =>
    services.luckyspin.deleteActivity({ id }).then(handler)
  )

export const createActivity = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.luckyspin.createActivity(params).then(handler)
    )
  )

export const updateActivity = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.luckyspin.saveActivity(params).then(handler)
    )
  )

// item
export const getActivityItemList = (handler) =>
  withTableLoading((params) =>
    services.luckyspin.getActivityItemList(params).then(handler)
  )

export const getActivityItemDetail = (handler) =>
  withGlobalLoading((id) =>
    services.luckyspin.getActivityItemDetail({ id }).then(handler)
  )

export const deleteActivityItem = (handler) =>
  withGlobalLoading((id) =>
    services.luckyspin.deleteActivityItem({ id }).then(handler)
  )

export const createActivityItem = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.luckyspin.createActivityItem(params).then(handler)
    )
  )

export const updateActivityItem = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.luckyspin.saveActivityItem(params).then(handler)
    )
  )

// fortunate
export const getFortunateList = (handler) =>
  withTableLoading((params) =>
    services.luckyspin.getFortunateList(params).then(handler)
  )

export const deleteFortunateItem = (handler) =>
  withGlobalLoading((id) =>
    services.luckyspin.deleteFortunateItem({ id }).then(handler)
  )

export const getThemes = (handler) =>
  withGlobalLoading((params) =>
    services.luckyspin.getThemes(params).then(handler)
  )
