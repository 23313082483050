import services, {
  withTableLoading,
  withGlobalLoading,
  withTranslation
} from '../../../../services'

export const getActivityList = (handler) =>
  withTableLoading((params) =>
    services.system.getActivityList(params).then(handler)
  )

export const getActivityDetail = (handler) =>
  withGlobalLoading((id) =>
    services.system.getActivityDetail({ id }).then(handler)
  )

export const deleteActivity = (handler) =>
  withGlobalLoading((id) =>
    services.system.deleteActivity({ id }).then(handler)
  )

export const createActivity = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.system.createActivity(params).then(handler)
    )
  )

export const updateActivity = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.system.saveActivity(params).then(handler)
    )
  )
