/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Button, Col, Divider, Image, Row, Select, Typography, Form } from 'antd'
import { Link } from 'react-router-dom'
import BlackInputSelect from '../../components/1688ProjectComponents/inputComponent/BlackInputSelect'
import BlackButton from '../../components/1688ProjectComponents/ButtonComponents/BlackButton'
import WhiteButton from '../../components/1688ProjectComponents/ButtonComponents/WhiteButton'
import TablePublicCollection from '../../components/1688ProjectComponents/TableComponents/TablePublicCollection'
import StickyFooter from '../../components/1688ProjectComponents/FooterComponents/StickyFooter'
import FilterReleaseRecord from '../../components/1688ProjectComponents/ReleaseRecordComponents/FilterReleaseRecord'
import ModalReleaseRecord from '../../components/1688ProjectComponents/ReleaseRecordComponents/ModalReleaseRecord'
import {deleteReleaseRecord, getReleaseRecord} from '../../services/1688Projects/collection'
import { getCategory, getAuthorPubColBox } from '../../services/1688Projects/exclusive'
import {value} from "lodash/seq";

const { Title } = Typography
const { Option } = Select

interface GetProduct {
  status: Number | null;
}

const DeleteEnum = {
  confirm_delete: 1,
  abort_delete: 0
}

const ReleaseRecord = () => {
  const [open, setOpen] = useState(false)
  const [isLoadingList, setIsLoadingList] = useState(false)
  const [tableData, setTableData] = useState([])
  const [tmpStatus, setTmpStatus] = useState(null)
  const [sizePage, setSizePage] = useState(10)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [totalItem, setTotalItem] = useState(0)
  const [filterTab, setFilterTab] = useState(4)
  const [pageNow, setPageNow] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [totalFailed, setTotalFailed] = useState(0)
  const [totalSuccess, setTotalSuccess] = useState(0)
  const [totalProcess, setTotalProcess] = useState(0)
  const [creatorOption, setCreatorOption] = useState([])
  const [deleteProductValue, setDeleteProductValue] = useState(null)
  const [filterFormData, setFilterFormData] = useState([])
  const [keyInDeleted, setKeyInDeleted] = useState(null)
  const [platFormOptions, setPlatformOptions] = useState([])
  const [from] = Form.useForm()

  const openModal = () => {
    setOpen(true)
  }

  async function getAuthorData() {
    try {
      const response = await getAuthorPubColBox()
      if (response) {
        const dataResList = response
        const dataRes = []
        for (let i = 0; i < response.length; i++) {
          const dataToPush = {
            label : dataResList[i].author,
            value: dataResList[i].author
          }
          dataRes.push(dataToPush)
        }
        setCreatorOption(dataRes)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getReleaseRecordData = async ({
    status = 1,
    goPage = 1
  }: GetProduct = {}) => {
    try {
      setTmpStatus(status)
      setIsLoadingList(true)
      const data = {}

      if (filterFormData) {
        if (filterFormData['filter[offer_id]']) {
          data['filter[offer_id]'] = filterFormData['filter[offer_id]'] || ''
        }
        if (filterFormData['filter[subject_trans]']) {
          data['filter[subject_trans]'] = filterFormData['filter[subject_trans]'] || ''
        }
        if (filterFormData['filter[platform]']) {
          data['filter[platform]'] = filterFormData['filter[platform]'] || ''
        }
        if (filterFormData['filter[author]']) {
          data['filter[author]'] = filterFormData['filter[author]'] || ''
        }
      }

      if (sizePage) {
        data.pageSize = sizePage
      }
      if (status) {
        if (status === 4) {
          setFilterTab(status)
        } else {
          data['filter[action]'] = status
          setFilterTab(status)
        }
        data.beginPage = goPage
        setPageNow(goPage)
      } else if (status === 0) {
        data['filter[action]'] = status
        data.beginPage = 1
        setPageNow(1)
        setFilterTab(0)
      } else if (Number(tmpStatus) === 0 && tmpStatus < 4) {
        data['filter[action]'] = tmpStatus
        if (goPage) {
          data.beginPage = goPage
        }
      } else {
        data.beginPage = goPage
      }
      const response = await getReleaseRecord(data)
      const getCategoryData = await getCategory()
      if (response?.totalRecords) {
        setTotalCount(response.totalRecords)
      } else {
        setTotalCount(0)
      }

      const successData = response.totalCountAction1
      if (successData) {
        setTotalSuccess(successData)
      } else {
        setTotalSuccess(0)
      }
      const failedData = response.totalCountAction2
      if (failedData) {
        setTotalFailed(failedData)
      } else {
        setTotalFailed(0)
      }
      const processData = response.totalCountAction3
      if (processData) {
        setTotalProcess(processData)
      } else {
        setTotalProcess(0)
      }

      if (response?.list?.length > 0) {

        const dataRes = response.list?.map((item) => {
          let category = '-'
          if (item?.category_id && item?.category_id?.length > 0) {
            const filterCategory =
              getCategoryData.categoryList?.children?.filter(
                (_category) =>
                  Number(_category?.categoryId) === Number(item?.category_id)
              )
            if (filterCategory?.length > 0) {
              const [categoryGet] = filterCategory
              category = categoryGet?.translatedName || '-'
            } else {
              category = item?.category_id
            }
          }
          return {
            key: item.id,
            product: {
              url: item?.images?.length > 0 ? item.images[0] : '',
              desc: item?.subject_trans,
              collectionId: item?.id,
              supplyId: item?.offer_id
            },
            supply: category,
            status: Number(item?.action),
            date: {
              account: item?.author,
              date: item?.start_time
            },
            platform: item?.platform
          }
        })
        setTotalItem(dataRes?.length || 0)
        setTableData(dataRes)
      } else {
        setTableData([])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoadingList(false)
    }
  }

  const onChangePage = (value) => {
    if (value) {
      setPageNow(Number(value))
      getReleaseRecordData({ goPage: Number(value) })
    }
  }
  const onShowSizeChange = (current, pageSize) => {
    setSizePage(pageSize)
  }
  const handleSelectAll = () => {
    const allRowKeys = tableData.map((item) => item.key)
    setSelectedRowKeys(allRowKeys)
  }
  const handleDeselectAll = () => {
    setSelectedRowKeys([])
  }

  const handleFilterSuccess = () => {
    getReleaseRecordData({ status: 1 })
  }
  const handleFilterProcess = () => {
    getReleaseRecordData({ status: 3 })
  }
  const handleFilterFailed = () => {
    getReleaseRecordData({ status: 2 })
  }

  function setAllowedCountry() {
    let platForm =  [
      {
        label: 'Nigeria',
        value: 'XPARK-NG',
        disabled: true
      },
      {
        label: 'Pakistan',
        value: 'XPARK-PK',
        disabled: true
      },
      {
        label: 'Kenya',
        value: 'XPARK-KE',
        disabled: true
      },
      {
        label: 'Ghana',
        value: 'XPARK-GH',
        disabled: true
      },
      {
        label: 'Morocco',
        value: 'XPARK-MA',
        disabled: true
      }
    ]
    let getFromLocal = JSON.parse(localStorage.getItem('allowedCountries'))
    if (getFromLocal && getFromLocal.length > 0) {
      let newPlatform = []
      for (let platFormKey in platForm) {
        let platFormNow = platForm[platFormKey].value.split("-")[1]
        for (let getFromLocalKey in getFromLocal) {
          let localFormNow = getFromLocal[getFromLocalKey].value
          if (platFormNow === localFormNow) {
            newPlatform.push({
              label: platForm[platFormKey].label,
              value: platForm[platFormKey].value,
              disable: getFromLocal[getFromLocalKey].disabled
            })
          }
        }
      }
      setPlatformOptions(newPlatform)
    }
  }

  useEffect(() => {
    getReleaseRecordData({ goPage: 1 })
  }, [filterFormData])

  useEffect(() => {
    getAuthorData()
    setAllowedCountry()
  }, []);

  const titlePage = () => {
    return (
      <Title level={2} style={{ marginBottom: 0, padding: '24px' }}>
        Release Record
      </Title>
    )
  }
  const columns = [
    {
      title: 'Product Information',
      dataIndex: 'product',
      render: (source) => (
        <div style={{ display: 'flex' }}>
          <div>
            <Image
              style={{ width: '163px', height: 'unset' }}
              src={source.url}
              preview={false}
            />
          </div>
          <div style={{ width: '200px', marginLeft: '10px' }}>
            <Col span={24}>
              <p style={{ color: 'black' }}>{source.desc}</p>
            </Col>
            <Col span={24}>
              <Col span={24}>
                <p style={{ color: '#606266', marginBottom: 'unset' }}>
                  {`采集箱产品ID： ${source.collectionId}`}
                </p>
              </Col>
              <Col span={24}>
                <p style={{ color: '#606266', marginBottom: 'unset' }}>
                  {`货源ID： ${source.supplyId}`}
                </p>
              </Col>
            </Col>
          </div>
        </div>
      )
    },
    {
      title: 'Source Supply',
      dataIndex: 'supply',
      sorter: (a, b) => {
        return a.supply - b.supply
      },
      render: (supply) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div>{supply}</div>
        </div>
      )
    },
    {
      title: 'Post Status',
      dataIndex: 'status',
      sorter: (a, b) => {
        return a.status - b.status
      },
      render: (status) => (
        <>
          {status === 1 && <div>Success</div>}
          {status === 2 && <div style={{ color: '#FA1111' }}>Failed</div>}
          {status === 3 && <div>Processing</div>}
        </>
      )
    },
    {
      title: 'Publisher/ \n Publishing Date',
      dataIndex: 'date',
      sorter: (a, b) => {
        return a.date - b.date
      },
      render: (date) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <div>{date.account}</div>
          <div>{date.date}</div>
        </div>
      )
    },
    {
      title: 'Store Name',
      dataIndex: 'platform',
      sorter: (a, b) => {
        return a.platform - b.platform
      }
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'action',
    //   render: (_, records) => (
    //     <div
    //       style={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         alignItems: 'flex-start'
    //       }}
    //     >
    //       <Link to={false} style={{ paddingInline: '16px', color: 'black' }}>
    //         Edit
    //       </Link>
    //       <Button loading={keyInDeleted === records.key} type="text" onClick={() => handleDeleteOneProduct(records.key)}>Delete</Button>
    //       <Button type="text">Release</Button>
    //       <Select bordered={false} defaultValue="1">
    //         <Option value="1">Claim to</Option>
    //       </Select>
    //     </div>
    //   )
    // }
  ]
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>
    }
    if (type === 'next') {
      return <a>Next</a>
    }
    return originalElement
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.status === 1,
      name: record.name
    })
  }

  async function handleDeleteOneProduct(e) {
    try {
      const getId = Number(e)
      if (!isNaN(getId)) {
        setKeyInDeleted(getId)
        const data = {
          id: [Number(e)]
        }
        await deleteReleaseRecord(data)
        getReleaseRecordData()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setKeyInDeleted(null)
    }
  }

  async function handleSelectedDeleteOption(e){
    if (e === DeleteEnum.confirm_delete){
      try {
        let body = {
          id : selectedRowKeys
        }
        await deleteReleaseRecord(body)
        setSelectedRowKeys([])
      } catch (err){
        console.log(err)
        setSelectedRowKeys([])
      }
    } else {
      setSelectedRowKeys([])
    }
    getReleaseRecordData()
  }

  return (
    <div style={{ width: '100%', background: '#F8F8F8', position: 'relative' }}>
      <div style={{ width: '100%' }}>{titlePage()}</div>
      <Divider style={{ color: 'black', margin: 'unset' }} />
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
      >
        <FilterReleaseRecord platFormOptions={platFormOptions} isLoadingList={isLoadingList} creatorOption={creatorOption} formRef={from} callback={(e) => setFilterFormData(e)} />
        <Row gutter={[0, 10]} style={{ marginTop: '100px' }}>
          <Col span={4} className="select-large-black">
            <BlackInputSelect
              funcParent={(e)=>{handleSelectedDeleteOption(e)}}
              value={deleteProductValue}
              placeHolder="Delete Record"
              option={[
                {
                  label: 'Confirm Delete',
                  value: 1
                },
                {
                  label: 'Abort Delete',
                  value: 0
                }
              ]}
            />
          </Col>
          <Col span={20} />
          <Col span={4}>
            {filterTab === 1 ? (
              <BlackButton
                title={`Success (${totalSuccess})`}
                functionParent={handleFilterSuccess}
              />
            ) : (
              <WhiteButton
                title={`Success (${totalSuccess})`}
                functionParent={handleFilterSuccess}
              />
            )}
          </Col>
          <Col span={4}>
            {filterTab === 2 ? (
              <BlackButton
                title={`Failed (${totalFailed})`}
                functionParent={handleFilterFailed}
              />
            ) : (
              <WhiteButton
                title={`Failed (${totalFailed})`}
                functionParent={handleFilterFailed}
              />
            )}
          </Col>
          <Col span={4}>
            {filterTab === 3 ? (
              <BlackButton
                title={`Processing (${totalProcess})`}
                functionParent={handleFilterProcess}
              />
            ) : (
              <WhiteButton
                title={`Processing (${totalProcess})`}
                functionParent={handleFilterProcess}
              />
            )}
          </Col>
          <Col span={8} />
          <Col span={4}>
            <BlackButton functionParent={openModal} title="Release Statistic" />
          </Col>
        </Row>
        <TablePublicCollection
          loading={isLoadingList}
          columns={columns}
          dataTable={tableData}
          rowSelection={rowSelection}
        />
        <StickyFooter
          sizePage={sizePage}
          totalItem={totalItem}
          handleCheckAll={handleSelectAll}
          handleUncheckAll={handleDeselectAll}
          itemRender={itemRender}
          totalPage={totalCount}
          onChangePage={onChangePage}
          page={pageNow}
          onShowSizeChange={onShowSizeChange}
          itemSelected={selectedRowKeys?.length || 0}
        />
      </div>
      <ModalReleaseRecord open={open} setOpen={setOpen} />
    </div>
  )
}

export default ReleaseRecord
