import {
  MonthlyDashboard,
  WeeklyDashboard,
  DailyDashboard,
  PeopleYard,
  WeekPerformance,
  WeeklyHomePage,
  DailyHomePage,
  WeeklyActiive,
  AvgDailyHomePage,
  MonthlyHomePage
} from '../pages'

const report = {
  path: '/report',
  primary: 'app.sider.report',
  routes: [
    {
      path: '/daily',
      primary: 'app.sider.daily.sales',
      routes: [
        {
          path: '/report/daily/dashboard',
          permission: 'daily-sales/dashboard',
          modular: 'report',
          component: DailyDashboard,
          primary: 'app.sider.daily.sales.dashboard',
          exact: true
        },
        {
          path: '/report/daily/homepage',
          permission: 'daily-sales/homepage',
          modular: 'report',
          component: DailyHomePage,
          primary: 'app.sider.weekly.sales.homepage',
          exact: true
        },
        {
          path: '/report/daily/avghomepage',
          permission: 'daily-sales/avghomepage',
          modular: 'report',
          component: AvgDailyHomePage,
          primary: 'app.sider.weekly.sales.avghomepage',
          exact: true
        }
      ]
    },
    {
      path: '/weekly',
      primary: 'app.sider.weekly.sales',
      routes: [
        {
          path: '/report/weekly/dashboard',
          permission: 'weekly-sales/dashboard',
          modular: 'report',
          component: WeeklyDashboard,
          primary: 'app.sider.weekly.sales.dashboard',
          exact: true
        },
        {
          path: '/report/weekly/active',
          permission: 'weekly-sales/active',
          modular: 'report',
          component: WeeklyActiive,
          primary: 'app.sider.weekly.sales.active',
          exact: true
        },
        {
          path: '/report/weekly/performance',
          permission: 'weekly-sales/performance',
          modular: 'report',
          component: WeekPerformance,
          primary: 'app.sider.weekly.sales.performance',
          exact: true
        },
        {
          path: '/report/weekly/homepage',
          permission: 'weekly-sales/homepage',
          modular: 'report',
          component: WeeklyHomePage,
          primary: 'app.sider.weekly.sales.avghomepage',
          exact: true
        }
      ]
    },
    {
      path: '/monthly',
      component: MonthlyDashboard,
      primary: 'app.sider.monthly.sales',
      exact: true,
      routes: [
        {
          path: '/report/monthly/dashboard',
          permission: 'monthly-sales/dashboard',
          modular: 'report',
          component: MonthlyDashboard,
          primary: 'app.sider.monthly.sales.dashboard',
          exact: true
        },
        {
          path: '/report/monthly/people-yard',
          permission: 'comprehensive/dashboard',
          modular: 'report',
          primary: 'app.sider.people-yard',
          component: PeopleYard,
          exact: true
        },
        {
          path: '/report/monthly/homepage',
          permission: 'monthly-sales/homepage',
          modular: 'report',
          component: MonthlyHomePage,
          primary: 'app.sider.weekly.sales.avghomepage',
          exact: true
        }
      ]
    }
  ]
}
export default report
