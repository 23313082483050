export const columns = [
  {
    title: 'Customer name',
    dataIndex: 'customer_id',
    key: 'customer_id'
  },
  {
    title: 'Target level',
    dataIndex: 'target_level',
    key: 'target_level'
  },
  {
    title: 'Completion time',
    dataIndex: 'completion_time',
    key: 'completion_time'
  },
  {
    title: 'Is released',
    dataIndex: 'is_released',
    key: 'is_released'
  },
  {
    title: 'updated_at',
    dataIndex: 'updated_at',
    key: 'updated_at'
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
