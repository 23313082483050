import React from 'react'
import { Table, Pagination, Row, Col, Space, Card } from 'antd'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

interface Props {
  isTableLoading: Boolean;
}
const PagingTable = ({
  isTableLoading,
  columns,
  dataSource,
  total,
  initGridData,
  current,
  pageSize,
  handler,
  pagination
}: Props) => {
  const location = useLocation()
  const history = useHistory()

  const urlPaginationHandler = (current, pageSize) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('current', current || initGridData.page)
    searchParams.set('pageSize', pageSize || initGridData.page_size)
    const query = searchParams.toString()
    history.push(`${location.pathname}?${query}`)
  }

  return (
    <>
      <Table
        bordered
        size="small"
        loading={isTableLoading}
        columns={columns}
        dataSource={dataSource}
        rowKey={(record) => record.id}
        scroll={{ x: 'max-content', y: 500 }}
        sticky
        pagination={false}
      />

      {pagination !== false && (
        <Card>
          <Row>
            <Col span={24}>
              <Space style={{ cssFloat: 'right' }}>
                <Pagination
                  size="small"
                  showSizeChanger
                  showQuickJumper
                  total={total}
                  showTotal={() => `共 ${total} 条数据`}
                  pageSize={pageSize}
                  current={current}
                  onChange={handler || urlPaginationHandler}
                  pageSizeOptions={[10, 15, 20, 50, 100]}
                  pagination={pagination}
                />
              </Space>
            </Col>
          </Row>
        </Card>
      )}
    </>
  )
}

const mapStateToProps = ({ global }) => ({
  isTableLoading: !!global.isTableLoading
})
export default connect(mapStateToProps)(PagingTable)
