import React from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'

const { Option } = Select
interface Props {
  channels: Array;
}

const ChannelSelect = ({ channels, ...props }: Props) => {
  return (
    <Select {...props} mode="multiple">
      {channels.map((channel) => (
        <Option key={channel.key}>{channel.name}</Option>
      ))}
    </Select>
  )
}

const mapStateToProps = ({ global }) => ({
  channels: global.channels
})

export default connect(mapStateToProps)(ChannelSelect)
