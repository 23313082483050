import { storage } from '../../utils'
import { UPDATE_USER_INFO, RESET_USER_INFO } from '../actions/users'

const initState = {
  userInfo: storage.userInfo.val ?? {
    token: '',
    user: {}
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case RESET_USER_INFO:
      return {
        ...state,
        userInfo: {
          token: '',
          user: {}
        }
      }
    case UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload
        }
      }
    default:
      return state
  }
}
