import React from 'react'
import { Tooltip, Tag } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getStaticText } from '../../utils'

const dailyDataFillTableColumns = [
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.country')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.country')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 200,
    align: 'center',
    render: (data) => getStaticText(data)
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.report_date')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.report_date')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'report_date',
    key: 'report_date',
    fixed: 'left',
    width: 200,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.platform')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.platform')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'platform',
    key: 'platform',
    fixed: 'left',
    width: 200,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('daily.data.fill.fields')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.data.fill.fields')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'miss_keys',
    key: 'miss_keys',
    fixed: 'left',
    align: 'center',
    render: (data) => {
      return data.split(',').map((filed) => {
        return <Tag key={filed}>{filed}</Tag>
      })
    }
  }
]

export default dailyDataFillTableColumns
