import React, { useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { PermissionButton, GridContext } from '../../../../components'
import { deleteTags } from '../services'

interface Props {
  record: Object;
}
const Actions = ({ record }: Props) => {
  const location = useLocation()

  const filterCountry = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('___country')
  }, [location])

  const { toggle, onLoad } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit')
  }

  const onDelete = () => {
    deleteTags(onLoad)({ id: record.id, country: filterCountry })
  }
  return (
    <>
      <PermissionButton
        size="small"
        onClick={onEdit}
        icon={<EditOutlined />}
        permission="bbs/tags/update"
        type="primary"
      >
        Edit
      </PermissionButton>
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <PermissionButton
          style={{ marginLeft: '5px' }}
          icon={<DeleteOutlined />}
          size="small"
          permission="bbs/tags/delete"
          type="primary"
        >
          Delete
        </PermissionButton>
      </Popconfirm>
    </>
  )
}
export default Actions
