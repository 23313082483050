/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { Col, Row, Spin } from 'antd'
import BasicInformation from './EditSelectedItem/BasicInformation'
import {getPageQuery} from '../../utils'
import { getProductDetailCollection, sendCollectionUpdate} from '../../services/1688Projects/collection'
import BlackButton from '../../components/1688ProjectComponents/ButtonComponents/BlackButton'
import WhiteButton from '../../components/1688ProjectComponents/ButtonComponents/WhiteButton'
import DescriptionProduct from "./EditSelectedItem/DescriptionProduct";
import '../../css/editProduct.css'
import SKUInformation from "./EditSelectedItem/SKUInformation";
import ProductImage from "./EditSelectedItem/ProductImage";

const EditSelectedItem = () => {
  const [productDetailData, setProductDetailData] = useState({})
  const [skuImage, setSkuImage] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)
  const [descriptionData, setDescription] = useState([])
  const [setting, setSetting] = useState([]);
  const [newSKUList, setNewSKUList] = useState([]);
  const [productImageData, setProductImageData] = useState([]);
  const params = getPageQuery();
  const [basicInformation, setBasicInformation] = useState({})

  useEffect(() => {
    loadData();
  }, [params.id]);

  async function loadData() {
    await getProductDetail();
  }

  async function getProductDetail() {
    setIsUpdate(true)
    const data = {
      id: params.id
    }
    const dataOneProduct = await getProductDetailCollection(data);
    setProductDetailData(dataOneProduct);
    const getDescriptionRes = dataOneProduct.description
    if (getDescriptionRes) {
      setDescription(getDescriptionRes)
    }
    setNewSKUList(dataOneProduct.sku_info)
    setIsUpdate(false);

    if (dataOneProduct?.images &&
      dataOneProduct?.images?.length > 0) {
      const productImages = dataOneProduct?.images?.map(
        (item, index) => {
          return {
            id: index + 1,
            image: item
          }
        }
      )
      setProductImageData([...productImages, {id: productImages.length + 1, image: null, dummy: true}])
    }
  }

  async function handleSubmitUpdate(){
    try {
      setIsUpdate(true)
      productDetailData["description"] = descriptionData
      productDetailData["sku_info"] = newSKUList;
      const newProductDetailData = {
        ...productDetailData,
        ...basicInformation,
        images: productImageData.filter((item) => item.image !== null).map((item) => item.image)
      }
      console.log("handleSubmitUpdate", newProductDetailData, productDetailData)
      const response = await sendCollectionUpdate(newProductDetailData)
      await getProductDetail()
    } catch (e) {
      console.log(e)
    } finally {
      setIsUpdate(false)
    }
  }

  return (
    <div style={{ width: '100%', background: '#F8F8F8', position: 'relative' }}>
      <Spin spinning={isUpdate && setting.length < 1}>

        <BasicInformation
          setBasicInformation={setBasicInformation}
          productDetailData={productDetailData}
        />
        <Col span={24}>

        </Col>
        <SKUInformation productDetailData={productDetailData}
                        onNewSKUList={(newSKUList) => setNewSKUList(newSKUList)}/>
        <ProductImage
          productData={productImageData}
          setProductData={setProductImageData}
          skuData={skuImage}
          setSkuData={setSkuImage}
        />
        <DescriptionProduct descriptionData={descriptionData} setDescription={setDescription} />
        {/*<SupplyInformation />*/}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBlock: '40px',
            paddingInline: '20px',
            position: 'sticky',
            bottom: 0,
            left: 0,
            background: '#F8F8F8',
            zIndex: 2
          }}
        >
          <Row gutter={[10, 10]} style={{ width: '100%' }}>
            <Col span={4}>
              {/*<BlackButton title="One Click Translation" icon={<TranslationOutlined />} />*/}
            </Col>
            <Col span={14} />
            <Col span={3}>
              <WhiteButton title="Cancel" functionParent={() => window.location.href = "/fast-delivery/collection-box"} />
            </Col>
            <Col span={3}>
              <BlackButton loading={isUpdate} functionParent={handleSubmitUpdate} title="Save Changes" />
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  )

}

export default EditSelectedItem;
