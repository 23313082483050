import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { getStaticText } from '../../utils'

const dailyTableDashboardColumns = [
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.country')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.country')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => getStaticText(data)
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.report_date')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.report_date')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'report_date',
    key: 'report_date',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.platform')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.platform')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'platform',
    key: 'platform',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: getStaticText('weekly.sales.table.month_sales_amount_gmv'),
    key: 'month_sales_amount_gmv',
    children: [
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.month_sales_amount_completion_rate'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.month_sales_amount_completion_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.month_sales_amount_completion_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_completion_rate',
        key: 'sales_amount_completion_rate',
        width: 110,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.month_sales_amount_target')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.budget.import'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.month_sales_amount_target'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_target',
        key: 'sales_amount_target',
        width: 110,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.sales.amount'),
    key: 'sales.amount',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.sales_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sales_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount',
        key: 'sales_amount',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.sales_amount_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.sales_amount_percent_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sales_amount_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_percent',
        key: 'sales_amount_percent',
        width: 180,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.gmv_last_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.gmv_last_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_last_year',
        key: 'sales_amount_last_year',
        width: 200,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.gmv_rate_year_on_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.gmv_rate_year_on_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.gmv_rate_year_on_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_year_on_year',
        key: 'sales_amount_year_on_year',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.flow'),
    key: 'flow',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.uv')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.uv')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv',
        key: 'uv',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.app_uv')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.app_uv')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'app_uv',
        key: 'app_uv',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.all_uv')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.all_uv')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'all_uv',
        key: 'all_uv',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.uv_last_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.uv_last_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv_last_year',
        key: 'uv_last_year',
        width: 110,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.uv_rate_year_on_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.uv_rate_year_on_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.uv_rate_year_on_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv_percent',
        key: 'uv_percent',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.month_active_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.month_active_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'month_active_users',
        key: 'month_active_users',
        width: 120,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.conversion.rate'),
    key: 'conversion.rate',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.conversion_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.conversion_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.conversion_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'conversion_rate',
        key: 'conversion_rate',
        width: 110,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.conversion_rate_last_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.conversion_rate_last_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.conversion_rate_last_year'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'conversion_rate_last_year',
        key: 'conversion_rate_last_year',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.conversion_rate_year_on_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.conversion_rate_year_on_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.conversion_rate_year_on_year'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'conversion_rate_year_on_year',
        key: 'conversion_rate_year_on_year',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.customer.price'),
    key: 'customer.price',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.customer_order_average')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.customer_order_average_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.customer_order_average')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'customer_order_average',
        key: 'customer_order_average',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.price_last_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.price_last_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.price_last_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'customer_order_last_year_average',
        key: 'customer_order_last_year_average',
        width: 150,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.price_rate_year_on_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.price_rate_year_on_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.price_rate_year_on_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'customer_order_year_on_year',
        key: 'customer_order_year_on_year',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.uv.worth'),
    key: 'uv.worth',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.uv_worth_1')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.uv_worth_1_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.uv_worth_1')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv_worth',
        key: 'uv_worth',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.order'),
    key: 'order',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.dashboard.table.total_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.dashboard.table.total_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'orders',
        key: 'orders',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.total_order_last_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.total_order_last_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'orders_last_year',
        key: 'orders_last_year',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.order_rate_year_on_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.order_rate_year_on_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.order_rate_year_on_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'orders_year_on_year',
        key: 'orders_year_on_year',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.total_online_paid_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.total_online_paid_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.total_online_paid_percent'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'online_paid_orders_percent',
        key: 'online_paid_orders_percent',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.dashboard.table.total_non_transsion_percent'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.dashboard.table.total_non_transsion_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.dashboard.table.total_non_transsion_percent'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_non_transsion_percent',
        key: 'total_non_transsion_percent',
        width: 120,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.phone.sales'),
    key: 'phone.sales',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_sales_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.phone_sales_amount_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_sales_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_sales_amount',
        key: 'phone_sales_amount',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.gmv_phone_last_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.gmv_phone_last_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'gmv_phone_last_year',
        key: 'gmv_phone_last_year',
        width: 150,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.gmv_phone_rate_year_on_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.gmv_phone_rate_year_on_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.gmv_phone_rate_year_on_year'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'gmv_phone_rate_year_on_year',
        key: 'gmv_phone_rate_year_on_year',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_sales_volume')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.phone_sales_volume_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_sales_volume')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_sales_volume',
        key: 'phone_sales_volume',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_sales_average')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.phone_sales_average_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_sales_average')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_sales_average',
        key: 'phone_sales_average',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.dashboard.table.product.supply'),
    key: 'product.supply',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.total_online_product')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.total_online_product')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_online_product',
        key: 'total_online_product',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.active_product_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.active_product_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.active_product_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'active_product_percent',
        key: 'active_product_percent',
        width: 110,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.dashboard.table.total_cross_border_product'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.dashboard.table.total_cross_border_product'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_cross_border_product',
        key: 'total_cross_border_product',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.dashboard.table.total_cross_border_product_percent'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.total_cross_border_product_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.dashboard.table.total_cross_border_product_percent'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_cross_border_product_percent',
        key: 'total_cross_border_product_percent',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.dashboard.table.products_others_brand_percent'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.products_others_brand_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.dashboard.table.products_others_brand_percent'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'products_others_brand_percent',
        key: 'products_others_brand_percent',
        width: 140,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.dashboard.table.ad_percentage'),
    key: 'ad_percentage',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.dm_total')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText('weekly.dashboard.sales.table.dm_total')}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.dm_total')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'dm_amount',
        key: 'dm_amount',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.dashboard.table.dm_gmv_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.dm_gmv_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.dashboard.table.dm_gmv_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'dm_gmv_percent',
        key: 'dm_gmv_percent',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.fb_score')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.fb_score')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'fb_score',
        key: 'fb_score',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.xpark.cumulative_register_totals'),
    key: 'xpark_cumulative_register_totals',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.cumulative_register_totals')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.cumulative_register_totals_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.cumulative_register_totals'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cumulative_register_totals',
        key: 'cumulative_register_totals',
        width: 150,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.APP.data'),
    key: 'APP.data',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.accumu_preassem_count')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.accumu_preassem_count')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'accumu_preassem_count',
        key: 'accumu_preassem_count',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.current_month_preassem_count')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'daily.sales.table.current_month_preassem_count'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'current_month_preassem_count',
        key: 'current_month_preassem_count',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.app_activation_number')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.app_activation_number')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'app_activation_number',
        key: 'app_activation_number',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.all_active_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.all_active_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'all_active_users',
        key: 'all_active_users',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.app_active_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.app_active_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'app_active_users',
        key: 'app_active_users',
        width: 140,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.pp_data'),
    key: 'pp_data',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.complete_of_pp_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.complete_of_pp_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'complete_of_pp_users',
        key: 'complete_of_pp_users',
        width: 130,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.new_of_pp_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.new_of_pp_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'new_of_pp_users',
        key: 'new_of_pp_users',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.complete_of_pp_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.complete_of_pp_orders_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.complete_of_pp_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'complete_of_pp_orders',
        key: 'complete_of_pp_orders',
        width: 170,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.pp_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText('weekly.sales.table.pp_orders_explain_note')}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.pp_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders',
        key: 'pp_orders',
        width: 170,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.pp_orders_execution_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.pp_orders_execution_rate_explain_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.pp_orders_execution_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.pp_orders_execution_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_execution_rate',
        key: 'pp_orders_execution_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_cancel_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_cancel_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_cancel_rate',
        key: 'pp_orders_cancel_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_dealt_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_dealt_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_dealt_rate',
        key: 'pp_orders_dealt_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_return_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_return_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_return_rate',
        key: 'pp_orders_return_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_phone_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_phone_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_phone_rate',
        key: 'pp_orders_phone_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_shiped_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_shiped_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_shiped_rate',
        key: 'pp_orders_shiped_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_ship_delay_days')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_ship_delay_days')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_ship_delay_days',
        key: 'pp_orders_ship_delay_days',
        width: 140,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.top'),
    key: 'pp_data',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.spu_top_1')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.spu_top_1')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'spu_top_1',
        key: 'spu_top_1',
        width: 300,
        align: 'center',
        render: (data) => (
          <div
            className="auth-magin"
            dangerouslySetInnerHTML={{ __html: data }}
          />
        )
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.spu_top_2')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.spu_top_2')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'spu_top_2',
        key: 'spu_top_2',
        width: 300,
        align: 'center',
        render: (data) => (
          <div
            className="auth-magin"
            dangerouslySetInnerHTML={{ __html: data }}
          />
        )
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.spu_top_3')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.spu_top_3')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'spu_top_3',
        key: 'spu_top_3',
        width: 300,
        align: 'center',
        render: (data) => (
          <div
            className="auth-magin"
            dangerouslySetInnerHTML={{ __html: data }}
          />
        )
      }
    ]
  }
]

export default dailyTableDashboardColumns
