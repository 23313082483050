import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'

interface Props {
  allowedPermissions: Array;
  permission: String;
}

const PermissionButton = ({
  allowedPermissions,
  permission,
  children,
  dispatch,
  ...state
}: Props) => {
  if (
    allowedPermissions &&
    (allowedPermissions.indexOf(permission) !== -1 ||
      allowedPermissions.indexOf('/') !== -1)
  ) {
    return <Button {...state}>{children}</Button>
  }
  return null
}

const mapStateToProps = ({ users }) => {
  return {
    allowedPermissions:
      users.userInfo && users.userInfo.user
        ? users.userInfo.user.permissions
        : []
  }
}

export default connect(mapStateToProps)(PermissionButton)
