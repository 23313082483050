import request from './request'

export const getUserList = (params) =>
  request.get('/backend/membership/user/get-list', { params })

export const getUserDetail = (params) =>
  request.get('/backend/membership/user/detail', { params })

export const getPrivilegesConfig = (params) =>
  request.get('/backend/membership/membership/privileges', { params })

export const togglePrivilege = (params) =>
  request.post('/backend/membership/membership/toggle-privilege', params)

export const getProductsForMembership = (params) =>
  request.get('/backend/membership/product/get-products-for-membership', {
    params
  })
export const getProducts = (params) =>
  request.get('/backend/xpark/products', {
    params: { ...params, scenario: 'product/dropdown-search' }
  })

export const toggleProduct = (params) =>
  request.post('/backend/membership/product/toggle-product', params)

export const editPosition = (params) =>
  request.post('/backend/membership/product/edit-position', params)

export const getMembershipDetail = (params) =>
  request.get('/backend/membership/membership/detail', { params })

export const updateMembership = (params) =>
  request.post('/backend/membership/membership/save', params)

export const getActivityRules = (params) =>
  request.get('/backend/xpark/external-system/salesRules/search', {
    params: { ...params, scenario: 'SalesRule/ActivityRuleSearch' }
  })

export const getPrivilegeConfig = (params) =>
  request.get('/backend/membership/privilege/get', { params })

export const savePrivilegeConfig = (params) =>
  request.post('/backend/membership/privilege/save', params)

export const freshCache = (params) =>
  request.post('/backend/membership/membership/fresh-cache', params)

export const getLogList = (params) =>
  request.get('/backend/membership/member-gift/get-list', { params })

export const getLogDetail = (params) =>
  request.get('/backend/membership/member-gift/detail', { params })

export const getPointHistory = (params) =>
  request.get('/backend/external-system/points-log/get-list', { params })

export const uploadFileToServer = ({ params }) => {
  return request.post('/backend/file-system/upload', params)
}
