import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'

import { PermissionButton } from '../../../../components'
import { deleteReview } from '../services'

interface Props {
  record: Object;
  onChange: () => {};
}

const Actions = ({ record, onChange }: Props) => {
  const location = useLocation()

  const filterCountry = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('___country')
  }, [location])

  const onDelete = () => {
    deleteReview(onChange)({ id: record.id, country: filterCountry })
  }
  return (
    <>
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <PermissionButton
          style={{ marginLeft: '5px' }}
          icon={<DeleteOutlined />}
          size="small"
          permission="luckyspin/activity/item/delete"
          type="primary"
        />
      </Popconfirm>
    </>
  )
}
export default Actions
