import request from './request'

export const getTotalReport = (data) =>
  request.post('/backend/disk-report/get-total-list', data)

export const getSiteTotalReport = (data) =>
  request.post('/backend/disk-report/get-site-list', data)

export const exportDashboard = (params) => {
  return request.get('/backend/disk-report/export', { params })
}

export const exportTotalBudget = (params) => {
  return request.get('/backend/disk-report/export-budget-template', { params })
}
