import React from 'react'
import Actions from './Actions'

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    width: 120,
    align: 'center'
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    align: 'center',
    width: 120
  },
  {
    title: 'Enabled',
    dataIndex: 'enabled',
    key: 'enabled',
    align: 'center',
    width: 120
  },
  {
    title: 'PDP Popup enabled',
    dataIndex: 'popup_enabled',
    key: 'popup_enabled',
    align: 'center',
    width: 120
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    width: 120,
    align: 'center'
  },
  {
    title: 'Link',
    dataIndex: 'link',
    key: 'link',
    align: 'center',
    width: 120,
    render: (link) => {
      return (
        <a href={link} target="_blank" rel="noreferrer">
          查看
        </a>
      )
    }
  },
  {
    title: 'Min cutting times',
    dataIndex: 'chances',
    key: 'chances',
    align: 'center',
    width: 120
  },
  {
    title: 'Max cutting times',
    dataIndex: 'chances_max',
    key: 'chances_max',
    align: 'center',
    width: 120
  },
  {
    title: 'Help chop times',
    dataIndex: 'help_chop_times',
    key: 'help_chop_times',
    align: 'center',
    width: 120
  },
  {
    title: 'Start time',
    dataIndex: 'start_time',
    key: 'start_time',
    width: 160,
    align: 'center'
  },
  {
    title: 'End time',
    dataIndex: 'end_time',
    width: 160,
    key: 'end_time',
    align: 'center'
  },
  {
    title: 'Product Numbers',
    dataIndex: 'product_numbers',
    width: 100,
    key: 'product_numbers',
    align: 'center'
  },
  {
    title: 'Share Numbers',
    dataIndex: 'share_numbers',
    width: 100,
    key: 'share_numbers',
    align: 'center'
  },
  {
    title: 'Chop Success',
    dataIndex: 'chop_success',
    width: 100,
    key: 'chop_success',
    align: 'center'
  },
  {
    title: 'Action',
    width: 300,
    align: 'center',
    key: 'x',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
