import Crm from '../pages/Crm/Crm'

const crm = {
  path: '/crm',
  primary: 'app.sider.crm',
  modular: 'crm',
  permission: 'jump/jump-crm',
  component: Crm,
  exact: true
}
export default crm
