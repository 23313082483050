import React, { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, Row, Col, Typography, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { getStaticText } from '../../../../utils'
import { columns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getRoleList } from '../services'
import {
  GridContextProvider,
  CreateButton,
  PagingTable
} from '../../../../components'
import Filters from './Filters'
import RoleForm from '../Edit/ui'

const { Text } = Typography

export default () => {
  const [gridData, setGridData] = useState(initGridData)
  const location = useLocation()
  const onLoad = useCallback(() => {
    getRoleList(setGridData)(new URLSearchParams(location.search))
  }, [location, setGridData])

  return (
    <GridContextProvider onLoad={onLoad}>
      <Filters />
      <Card>
        <Row>
          <Col span={24}>
            <Text>{getStaticText('component.table.header.title')}</Text>
            <Space style={{ cssFloat: 'right' }}>
              <CreateButton
                type="primary"
                icon={<PlusOutlined />}
                permission="rbac/role/create"
              />
            </Space>
          </Col>
        </Row>
      </Card>
      <PagingTable
        columns={columns}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.current}
        pageSize={gridData.page_size || 10}
      />
      <RoleForm />
    </GridContextProvider>
  )
}
