import React, { useContext, useState } from 'react'
import { Tabs, Form, Row, Col, Input, Select } from 'antd'
import { connect } from 'react-redux'
import { GridFormDrawer, GridContext } from '../../../../components'
import { getPrivilegeConfig, savePrivilegeConfig } from '../services'

const { TabPane } = Tabs

interface Props {
  allowedCountries: Array;
}

const CoinReceiveForm = ({ allCountries, allowedCountries }: Props) => {
  const [form] = Form.useForm()
  const { record } = useContext(GridContext)
  const [activeKey, setActiveKey] = useState(allowedCountries[0])

  const switchTab = (key) => {
    if (key) {
      setActiveKey(key)
    } else {
      key = activeKey
    }
    getPrivilegeConfig((ret) => {
      const data = {
        ...ret.configs
      }
      form.resetFields()
      form.setFieldsValue(data)
    })({ code: record.pr, country: key, group_code: record.level })
  }

  const onFinish = (configs) => {
    savePrivilegeConfig()({
      code: record.pr,
      group_code: record.level,
      country: activeKey,
      configs
    })
  }
  return (
    <>
      <GridFormDrawer
        onOpen={() => {
          switchTab(activeKey)
        }}
        onSave={() => form.submit()}
        type="coupon_pr_coin_exchange"
        title="Coin Exchange Settings"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
        >
          <Tabs activeKey={activeKey} type="card" onChange={switchTab}>
            {allCountries
              .filter((x) => allowedCountries.indexOf(x.countryCode) !== -1)
              .map((item) => (
                <TabPane tab={item.name} key={item.countryCode}>
                  <Row gutter={16}>
                    <Col span={16}>
                      <Form.Item label="积分兑金额比率">
                        <Form.Item name="redeem_rate">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc' }}>
                          用户使用积分时积分兑金额的比例(输入0.1表示10稅分兑1当地货币,
                          0.5表示2个积分况1当地货币)
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="积分兑换阶梯">
                        <Form.Item name="tier_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc' }}>
                          （例:100, 200, 300 &gt; 5000, 400 &gt; 8000, 600 &gt;
                          1000）按此格式配置，逗号分隔。每个阶梯表示积分可扣减的金额。
                          可以配置使用条件(300 &gt; 5000)
                          表示要使用此档(300当地货币)必须订单价格大于5000,否则此档不可使用。单独数字表示使用不受限制，订单金额大于扣减金额即可
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="下单金额获取积分比率">
                        <Form.Item name="gain_rate">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc' }}>
                          此项默认为1表示下单成功后用户可以得到的积分，1当地货币获得1积分，设为0.1表示1当地货币换0.1积分（计算后取整）
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="订单状态">
                        <Form.Item name="order_status">
                          <Select value="completed">
                            <Select.Option key="completed">
                              订单完成
                            </Select.Option>
                            <Select.Option key="confirmed">
                              订单确认
                            </Select.Option>
                          </Select>
                        </Form.Item>
                        <span style={{ color: '#ccc' }}>
                          用户下单后，订单到达此状态才向用户发放积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="完善性别获取积分">
                        <Form.Item name="fill_gender_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc' }}>
                          此项配置表示用户完善性别资料后可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="完善生日获取积分">
                        <Form.Item name="fill_birthday_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc' }}>
                          此项配置表示用户完善生日资料后可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="完善电话获取积分">
                        <Form.Item name="fill_mobile_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc' }}>
                          此项配置表示用户完善电话资料后可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="完善邮箱获取积分">
                        <Form.Item name="fill_email_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc' }}>
                          此项配置表示用户完善邮箱资料后可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="完善个人资料获取积分">
                        <Form.Item name="fill_info_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc' }}>
                          此项配置表示用户每年更新个人资料后可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="完善姓名获取积分">
                        <Form.Item name="fill_name_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc;' }}>
                          此项配置表示用户完善姓名资料后可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="完善婚姻状况资料获取积分">
                        <Form.Item name="fill_marital_status_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc;' }}>
                          此项配置表示用户完善婚姻状况资料后可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="完善爱好资料获取积分">
                        <Form.Item name="fill_hobby_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc;' }}>
                          此项配置表示用户完善爱好资料后可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="完善职业资料获取积分">
                        <Form.Item name="fill_professional_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc;' }}>
                          此项配置表示用户完善职业资料后可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="完善月收入资料获取积分">
                        <Form.Item name="fill_monthly_income_points">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc;' }}>
                          此项配置表示用户完善月收入资料后可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="BBS发帖可获取积分">
                        <Form.Item name="bbs_create_discussions">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc;' }}>
                          此项配置表示用户在BBS发帖可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="BBS评论可获取积分">
                        <Form.Item name="bbs_create_posts">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc;' }}>
                          此项配置表示用户在BBS评论可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="BBS回复可获取积分">
                        <Form.Item name="bbs_create_reply">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc;' }}>
                          此项配置表示用户在BBS回复可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="BBS点赞帖子可获取积分">
                        <Form.Item name="bbs_like_discussions">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc;' }}>
                          此项配置表示用户在BBS点赞帖子可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                    <Col span={16}>
                      <Form.Item label="BBS点赞评论可获取积分">
                        <Form.Item name="bbs_like_posts">
                          <Input />
                        </Form.Item>
                        <span style={{ color: '#ccc;' }}>
                          此项配置表示用户在BBS点赞评论可以得到的积分
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                </TabPane>
              ))}
          </Tabs>
        </Form>
      </GridFormDrawer>
    </>
  )
}

const mapStateToProps = ({ users, global }) => {
  return {
    allowedCountries:
      users.userInfo && users.userInfo.user
        ? users.userInfo.user.allowed_countries
        : [],
    allCountries: global.config
  }
}

export default connect(mapStateToProps)(CoinReceiveForm)
