import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useContext
} from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Row, Col, Input } from 'antd'
import BraftEditor from 'braft-editor'
import moment from 'moment'
import 'braft-editor/dist/index.css'

import {
  GridFormDrawer,
  CountrySelect,
  TagsSelect,
  SearchSelect,
  GridContext
} from '../../../../components'
import { getPostDetail, savePost, createPost } from '../services'
import services from '../../../../services'
import Upload from './Upload'
import Vote from './Vote'

const dateFormat = 'YYYY-MM-DD HH:mm:ss'

const BbsForm = () => {
  const controls = [
    'bold',
    'italic',
    'underline',
    'text-color',
    'separator',
    'link',
    'separator',
    'media'
  ]
  const location = useLocation()

  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const { onLoad, toggle } = useContext(GridContext)

  const filterCountry = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('___country')
  }, [location])

  const [country, setCountry] = useState('')
  const countryChange = (value) => {
    setCountry(value)
  }
  // upload url data
  const [uploadData, setUploadData] = useState([])
  // get init upload url
  const [getuploadData, setGetUploadData] = useState([])
  const [noUpload, setNoUpload] = useState(false)

  const emptyForm = () => {
    setFormData({})
    setCountry('')
    setUploadData([])
    setGetUploadData([])
    setNoUpload(false)
    form.resetFields()
  }
  const closeDrawer = () => {
    emptyForm()
    toggle({}, '')
    onLoad()
  }

  const formatResData = useCallback(
    (value) => {
      let newValue = {
        id: value.id,
        country: filterCountry,
        tags: value.attributes.discussion_tags.map((item) => item.id),
        title: value.attributes.title,
        content: BraftEditor.createEditorState(
          value.included.find((item) => item.type === 'posts').attributes
            .contentHtmlPure
        )
      }

      // judge whether has crowd_funding, related products
      if (value.attributes.crowd_funding) {
        newValue = Object.assign(newValue, {
          crowd_funding_id: {
            value: value.attributes.crowd_funding.id,
            label: value.attributes.crowd_funding.title
          }
        })
      }

      if (value.attributes.products && value.attributes.products.length) {
        newValue = Object.assign(newValue, {
          products: value.attributes.products.map((item) => ({
            value: item.product_id,
            label: item.detail.product_name
          }))
        })
      }

      // if contained vote
      if (
        value.attributes.discussion_vote &&
        value.attributes.discussion_vote.length
      ) {
        newValue = Object.assign(newValue, {
          vote_title: value.attributes.discussion_vote[0].question,
          vote_end_time: moment(
            value.attributes.discussion_vote[0].end_date,
            dateFormat
          ),
          vote_public_poll: !!value.attributes.discussion_vote[0].public_poll,
          vote_options: value.attributes.discussion_vote[0].poll_options.reduce(
            (pre, option) => {
              pre[option.id] = option.answer
              return pre
            },
            {}
          )
        })
      }

      // judge whether over 1 images
      if (value.attributes.images && value.attributes.images.length) {
        setGetUploadData(
          value.attributes.images.map((item) => {
            if (item.type === 'video/mp4') {
              return {
                url: item.videoSrc,
                video_preview: item.src
              }
            }
            return {
              url: item.src
            }
          })
        )
        setUploadData(
          value.attributes.images.reduce((pre, item, index) => {
            if (item.type === 'video/mp4' && index === 0) {
              pre.push({ video_preview: item.src })
              pre.push(item.videoSrc)
            } else if (item.type === 'video/mp4') {
              pre.push(item.videoSrc)
            } else {
              pre.push(item.src)
            }
            return pre
          }, [])
        )
      }
      setCountry(filterCountry)
      setFormData(newValue)
    },
    [filterCountry]
  )

  const openHandler = (id) => {
    setNoUpload(false)
    getPostDetail(formatResData)({ id, ___country: filterCountry })
  }

  const onFinish = (values) => {
    if (!uploadData.length) {
      setNoUpload(true)
      return
    }

    let formatValues = {
      title: values.title,
      content: values.content.toHTML(),
      crowd_funding_id: values.crowd_funding_id || '',
      products: values.products || []
    }

    // judge whether related to crowdfunding or products
    if (formatValues.crowd_funding_id === '') {
      delete formatValues.crowd_funding_id
    } else {
      formatValues.crowd_funding_id = values.crowd_funding_id.value
    }
    if (formatValues.products.length) {
      formatValues.products = formatValues.products.map((item) => ({
        id: item.value
      }))
    }

    // judge whether upload over 1 images
    if (uploadData.length === 1 && formData.id) {
      formatValues = Object.assign(formatValues, {
        cover: uploadData[0],
        images: uploadData
      })
    } else if (uploadData.length === 1) {
      formatValues = Object.assign(formatValues, { cover: uploadData[0] })
    } else if (uploadData[0].video_preview) {
      const vedioPreview = uploadData[0].video_preview
      uploadData.shift()
      formatValues = Object.assign(formatValues, {
        cover: vedioPreview,
        images: uploadData
      })
    } else if (uploadData.length > 1) {
      formatValues = Object.assign(formatValues, {
        cover: uploadData[0],
        images: uploadData
      })
    }

    // vote params
    if (values.vote_title) {
      formatValues = Object.assign(formatValues, {
        vote: {
          question: values.vote_title,
          publicPoll: values.vote_public_poll,
          endDate: values.vote_end_time,
          relationships: {
            options: Object.keys(values.vote_options).map((option) => ({
              id: option,
              name: values.vote_options[option]
            }))
          }
        }
      })
    }

    const params = {
      data: {
        type: 'discussions',
        attributes: {
          ...formatValues
        },
        relationships: {
          tags: {
            data: values.tags.map((item) => {
              return { type: 'tags', id: item }
            })
          }
        }
      }
    }

    if (formData.id) {
      params.data = Object.assign(params.data, { id: formData.id })
      savePost(closeDrawer)({
        id: formData.id,
        country: values.country,
        params
      })
    } else {
      createPost(closeDrawer)({ country: values.country, params })
    }
  }

  const uploadMedia = (param) => {
    const fd = new FormData()
    fd.append('file', param.file)
    services.bbs
      .upload({ country: filterCountry, params: fd })
      .then((res) => {
        param.success({
          url: res.url,
          meta: {
            controls: true,
            poster: res.video_preview
          }
        })
      })
      .catch(() => {
        param.error({
          msg: 'unable to upload.'
        })
      })
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Post' : 'New Post')}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please select country' }]}
            >
              <CountrySelect onChange={countryChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Tags"
              name="tags"
              rules={[{ required: true, message: 'Please select tags' }]}
            >
              <TagsSelect mode="multiple" country={country} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Post Title"
              name="title"
              rules={[
                { required: true, min: 3, message: 'Please enter post title' }
              ]}
            >
              <Input placeholder="Please enter post title" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              labelCol={{ span: 6 }}
              label="Content"
              name="content"
              rules={[
                {
                  transform: (value) => value && value.toRAW(),
                  required: true,
                  message: 'Please enter content'
                }
              ]}
            >
              <BraftEditor
                className="my-editor"
                controls={controls}
                placeholder="请输入评论内容"
                media={{ uploadFn: uploadMedia }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Upload" name="upload">
              <>
                <Upload
                  country={country}
                  getuploadData={getuploadData}
                  setUploadData={setUploadData}
                  setNoUpload={setNoUpload}
                />
                {noUpload && (
                  <div style={{ color: 'red', marginTop: -8 }}>
                    please upload image
                  </div>
                )}
              </>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Crowd Funding" name="crowd_funding_id">
              <SearchSelect
                showSearch
                placeholder="Search crowdfunding activities"
                country={country}
                fetchApi={services.system.getActivityList}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Related Product" name="products">
              <SearchSelect
                mode="multiple"
                placeholder="Search product"
                country={country}
                fetchApi={services.bbs.getBbsProduct}
              />
            </Form.Item>
          </Col>
        </Row>
        <Vote formData={formData} />
      </Form>
    </GridFormDrawer>
  )
}
export default BbsForm
