import React from 'react'

import { getStaticText } from '../../utils'

const goodsDashboardTopUvColumns = [
  {
    title: <div>{getStaticText('dashboard.goods.product_name')}</div>,
    width: 300,
    dataIndex: 'product_name',
    key: 'product_name'
  },
  {
    title: <div>{getStaticText('dashboard.goods.uv')}</div>,
    dataIndex: 'uv',
    key: 'uv'
  },
  {
    title: <div>{getStaticText('dashboard.goods.add_uv')}</div>,
    dataIndex: 'add_uv',
    key: 'add_uv'
  },
  {
    title: <div>{getStaticText('dashboard.goods.add_rate')}</div>,
    dataIndex: 'add_rate',
    key: 'add_rate'
  },
  {
    title: <div>{getStaticText('dashboard.goods.orders_uv')}</div>,
    dataIndex: 'orders_uv',
    key: 'orders_uv'
  },
  {
    title: <div>{getStaticText('dashboard.goods.confirmed_uv')}</div>,
    dataIndex: 'confirmed_uv',
    key: 'confirmed_uv'
  },
  {
    title: <div>{getStaticText('dashboard.goods.orders')}</div>,
    dataIndex: 'orders',
    key: 'orders'
  }
]

export default goodsDashboardTopUvColumns
