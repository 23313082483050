import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function FlowCategory(props) {
  const { tableData } = props
  return (
    <>
      {/* 流量-类目 */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="4" width="100" className="sticky-left sticky-second">
          流量-类目
        </th>
        <th colSpan="10">手机</th>
        <th colSpan="10">配件</th>
        <th colSpan="10">美妆</th>
        <th colSpan="10">服饰</th>
        <th colSpan="10">鞋包配饰</th>
        <th colSpan="10">家电</th>
        <th colSpan="10">家居</th>
        <th colSpan="10">运动健身</th>
        <th colSpan="10">玩具</th>
      </tr>

      {/* row2 */}
      <tr>
        <th colSpan="1">
          类目的品牌依赖度 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  根据类目的关键词分析判断，类目TOP10关键词中如果高%是品牌词，说明此类目被品牌垄断，品牌依赖度高
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">类目流量结构分析</th>
        <th colSpan="3">类目获客成本分析</th>
        {/* <th colSpan="2">类目访客流失去向分析</th> */}
        <th colSpan="2">
          类目流量结构跳失率分析 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  增加站内自然流量和活动会场，然后从GA中获取4种流量的跳失率,
                  每一种流量结构的跳失率加权平均， 总计跳量/总计流量
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="1">
          类目的品牌依赖度 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  根据类目的关键词分析判断，类目TOP10关键词中如果高%是品牌词，说明此类目被品牌垄断，品牌依赖度高
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">类目流量结构分析</th>
        <th colSpan="3">类目获客成本分析</th>
        <th colSpan="2">类目访客流失去向分析</th>
        <th colSpan="1">
          类目的品牌依赖度 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  根据类目的关键词分析判断，类目TOP10关键词中如果高%是品牌词，说明此类目被品牌垄断，品牌依赖度高
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">类目流量结构分析</th>
        <th colSpan="3">类目获客成本分析</th>
        <th colSpan="2">类目访客流失去向分析</th>
        <th colSpan="1">
          类目的品牌依赖度 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  根据类目的关键词分析判断，类目TOP10关键词中如果高%是品牌词，说明此类目被品牌垄断，品牌依赖度高
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">类目流量结构分析</th>
        <th colSpan="3">类目获客成本分析</th>
        <th colSpan="2">类目访客流失去向分析</th>
        <th colSpan="1">
          类目的品牌依赖度 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  根据类目的关键词分析判断，类目TOP10关键词中如果高%是品牌词，说明此类目被品牌垄断，品牌依赖度高
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">类目流量结构分析</th>
        <th colSpan="3">类目获客成本分析</th>
        <th colSpan="2">类目访客流失去向分析</th>
        <th colSpan="1">
          类目的品牌依赖度 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  根据类目的关键词分析判断，类目TOP10关键词中如果高%是品牌词，说明此类目被品牌垄断，品牌依赖度高
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">类目流量结构分析</th>
        <th colSpan="3">类目获客成本分析</th>
        <th colSpan="2">类目访客流失去向分析</th>
        <th colSpan="1">
          类目的品牌依赖度 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  根据类目的关键词分析判断，类目TOP10关键词中如果高%是品牌词，说明此类目被品牌垄断，品牌依赖度高
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">类目流量结构分析</th>
        <th colSpan="3">类目获客成本分析</th>
        <th colSpan="2">类目访客流失去向分析</th>
        <th colSpan="1">
          类目的品牌依赖度 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  根据类目的关键词分析判断，类目TOP10关键词中如果高%是品牌词，说明此类目被品牌垄断，品牌依赖度高
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">类目流量结构分析</th>
        <th colSpan="3">类目获客成本分析</th>
        <th colSpan="2">类目访客流失去向分析</th>
        <th colSpan="1">
          类目的品牌依赖度 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  根据类目的关键词分析判断，类目TOP10关键词中如果高%是品牌词，说明此类目被品牌垄断，品牌依赖度高
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">类目流量结构分析</th>
        <th colSpan="3">类目获客成本分析</th>
        <th colSpan="2">类目访客流失去向分析</th>
      </tr>

      {/* row3 */}
      <tr>
        <th>
          品牌热词 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Top 10 搜索 品牌词和类目词合并
                  <br />
                  Phone :500
                  <br />
                  Infinix:300
                  <br />
                  XPARK:200
                  <br />
                  来源：数据抓取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          搜索 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Google/SEM
                  代表搜索流量，第一次进入产品详情页面，给GA流量打类目标签
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          付费推广 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  自定义UMT流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          站内自然流量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  站内流量，从Banner,PLL进来的流量，非活动会场带来的流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          活动会场 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  为所有活动会场页面定义一个统一标识，用于GA流量分析
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：实际市场总费用/实际总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：预算市场总费用/预算总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前飞书类目CPA数据/去年同期飞书类目CPA数据-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>搜索</th>
        <th>付费推广</th>

        <th>
          品牌热词 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Top 10 搜索 品牌词和类目词合并
                  <br />
                  Phone :500
                  <br />
                  Infinix:300
                  <br />
                  XPARK:200
                  <br />
                  来源：数据抓取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          搜索 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Google/SEM
                  代表搜索流量，第一次进入产品详情页面，给GA流量打类目标签
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          付费推广 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  自定义UMT流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          站内自然流量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  站内流量，从Banner,PLL进来的流量，非活动会场带来的流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          活动会场 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  为所有活动会场页面定义一个统一标识，用于GA流量分析
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：实际市场总费用/实际总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：预算市场总费用/预算总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前飞书类目CPA数据/去年同期飞书类目CPA数据-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          品牌热词 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Top 10 搜索 品牌词和类目词合并
                  <br />
                  Phone :500
                  <br />
                  Infinix:300
                  <br />
                  XPARK:200
                  <br />
                  来源：数据抓取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          搜索 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Google/SEM
                  代表搜索流量，第一次进入产品详情页面，给GA流量打类目标签
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          付费推广 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  自定义UMT流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          站内自然流量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  站内流量，从Banner,PLL进来的流量，非活动会场带来的流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          活动会场 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  为所有活动会场页面定义一个统一标识，用于GA流量分析
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：实际市场总费用/实际总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：预算市场总费用/预算总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前飞书类目CPA数据/去年同期飞书类目CPA数据-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          品牌热词 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Top 10 搜索 品牌词和类目词合并
                  <br />
                  Phone :500
                  <br />
                  Infinix:300
                  <br />
                  XPARK:200
                  <br />
                  来源：数据抓取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          搜索 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Google/SEM
                  代表搜索流量，第一次进入产品详情页面，给GA流量打类目标签
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          付费推广 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  自定义UMT流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          站内自然流量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  站内流量，从Banner,PLL进来的流量，非活动会场带来的流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          活动会场 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  为所有活动会场页面定义一个统一标识，用于GA流量分析
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：实际市场总费用/实际总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：预算市场总费用/预算总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前飞书类目CPA数据/去年同期飞书类目CPA数据-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          品牌热词 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Top 10 搜索 品牌词和类目词合并
                  <br />
                  Phone :500
                  <br />
                  Infinix:300
                  <br />
                  XPARK:200
                  <br />
                  来源：数据抓取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          搜索 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Google/SEM
                  代表搜索流量，第一次进入产品详情页面，给GA流量打类目标签
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          付费推广 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  自定义UMT流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          站内自然流量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  站内流量，从Banner,PLL进来的流量，非活动会场带来的流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          活动会场 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  为所有活动会场页面定义一个统一标识，用于GA流量分析
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：实际市场总费用/实际总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：预算市场总费用/预算总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前飞书类目CPA数据/去年同期飞书类目CPA数据-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          品牌热词 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Top 10 搜索 品牌词和类目词合并
                  <br />
                  Phone :500
                  <br />
                  Infinix:300
                  <br />
                  XPARK:200
                  <br />
                  来源：数据抓取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          搜索 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Google/SEM
                  代表搜索流量，第一次进入产品详情页面，给GA流量打类目标签
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          付费推广 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  自定义UMT流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          站内自然流量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  站内流量，从Banner,PLL进来的流量，非活动会场带来的流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          活动会场 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  为所有活动会场页面定义一个统一标识，用于GA流量分析
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：实际市场总费用/实际总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：预算市场总费用/预算总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前飞书类目CPA数据/去年同期飞书类目CPA数据-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          品牌热词 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Top 10 搜索 品牌词和类目词合并
                  <br />
                  Phone :500
                  <br />
                  Infinix:300
                  <br />
                  XPARK:200
                  <br />
                  来源：数据抓取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          搜索 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Google/SEM
                  代表搜索流量，第一次进入产品详情页面，给GA流量打类目标签
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          付费推广 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  自定义UMT流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          站内自然流量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  站内流量，从Banner,PLL进来的流量，非活动会场带来的流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          活动会场 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  为所有活动会场页面定义一个统一标识，用于GA流量分析
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：实际市场总费用/实际总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：预算市场总费用/预算总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前飞书类目CPA数据/去年同期飞书类目CPA数据-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          品牌热词 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Top 10 搜索 品牌词和类目词合并
                  <br />
                  Phone :500
                  <br />
                  Infinix:300
                  <br />
                  XPARK:200
                  <br />
                  来源：数据抓取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          搜索 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Google/SEM
                  代表搜索流量，第一次进入产品详情页面，给GA流量打类目标签
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          付费推广 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  自定义UMT流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          站内自然流量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  站内流量，从Banner,PLL进来的流量，非活动会场带来的流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          活动会场 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  为所有活动会场页面定义一个统一标识，用于GA流量分析
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：实际市场总费用/实际总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：预算市场总费用/预算总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前飞书类目CPA数据/去年同期飞书类目CPA数据-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          品牌热词 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Top 10 搜索 品牌词和类目词合并
                  <br />
                  Phone :500
                  <br />
                  Infinix:300
                  <br />
                  XPARK:200
                  <br />
                  来源：数据抓取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          搜索 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  Google/SEM
                  代表搜索流量，第一次进入产品详情页面，给GA流量打类目标签
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          付费推广 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  自定义UMT流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          站内自然流量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  站内流量，从Banner,PLL进来的流量，非活动会场带来的流量
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          活动会场 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  为所有活动会场页面定义一个统一标识，用于GA流量分析
                  <br />
                  来源：GA拉取
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：实际市场总费用/实际总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  填写飞书类目CPA数据
                  <br />
                  来源：预算市场总费用/预算总订单数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前飞书类目CPA数据/去年同期飞书类目CPA数据-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row4 */}
      <tr>
        <td>
          <pre>{tableData.phone_hot_words}</pre>
        </td>
        <td>{tableData.phone_flow_structure_search}</td>
        <td>{tableData.phone_flow_structure_paid_promo}</td>
        <td>{tableData.phone_flow_structure_pp_page}</td>
        <td>{tableData.phone_flow_structure_activity}</td>
        <td>{tableData.phone_cost_on_customer_actual}</td>
        <td>{tableData.phone_cost_on_customer_budget}</td>
        <td>{tableData.phone_cost_on_customer_rate_year_on_year}</td>
        <td>{tableData.phone_flow_structure_bounce_rate_search}</td>
        <td>{tableData.phone_flow_structure_bounce_rate_paid_promo}</td>
        <td>
          <pre>{tableData.accessory_hot_words}</pre>
        </td>
        <td>{tableData.accessory_flow_structure_search}</td>
        <td>{tableData.accessory_flow_structure_paid_promo}</td>
        <td>{tableData.accessory_flow_structure_pp_page}</td>
        <td>{tableData.accessory_flow_structure_activity}</td>
        <td>{tableData.accessory_cost_on_customer_actual}</td>
        <td>{tableData.accessory_cost_on_customer_budget}</td>
        <td>{tableData.accessory_cost_on_customer_rate_year_on_year}</td>
        <td>{tableData.accessory_visitor_drain_out_actual}</td>
        <td>{tableData.accessory_visitor_drain_out_rate_year_on_year}</td>
        <td>
          <pre>{tableData.makeups_hot_words}</pre>
        </td>
        <td>{tableData.makeups_flow_structure_search}</td>
        <td>{tableData.makeups_flow_structure_paid_promo}</td>
        <td>{tableData.makeups_flow_structure_pp_page}</td>
        <td>{tableData.makeups_flow_structure_activity}</td>
        <td>{tableData.makeups_cost_on_customer_actual}</td>
        <td>{tableData.makeups_cost_on_customer_budget}</td>
        <td>{tableData.makeups_cost_on_customer_rate_year_on_year}</td>
        <td>{tableData.makeups_visitor_drain_out_actual}</td>
        <td>{tableData.makeups_visitor_drain_out_rate_year_on_year}</td>
        <td>
          <pre>{tableData.cloth_hot_words}</pre>
        </td>
        <td>{tableData.cloth_flow_structure_search}</td>
        <td>{tableData.cloth_flow_structure_paid_promo}</td>
        <td>{tableData.cloth_flow_structure_pp_page}</td>
        <td>{tableData.cloth_flow_structure_activity}</td>
        <td>{tableData.cloth_cost_on_customer_actual}</td>
        <td>{tableData.cloth_cost_on_customer_budget}</td>
        <td>{tableData.cloth_cost_on_customer_rate_year_on_year}</td>
        <td>{tableData.cloth_visitor_drain_out_actual}</td>
        <td>{tableData.cloth_visitor_drain_out_rate_year_on_year}</td>
        <td>
          <pre>{tableData.shoes_bag_hot_words}</pre>
        </td>
        <td>{tableData.shoes_bag_flow_structure_search}</td>
        <td>{tableData.shoes_bag_flow_structure_paid_promo}</td>
        <td>{tableData.shoes_bag_flow_structure_pp_page}</td>
        <td>{tableData.shoes_bag_flow_structure_activity}</td>
        <td>{tableData.shoes_bag_cost_on_customer_actual}</td>
        <td>{tableData.shoes_bag_cost_on_customer_budget}</td>
        <td>{tableData.shoes_bag_cost_on_customer_rate_year_on_year}</td>
        <td>{tableData.shoes_bag_visitor_drain_out_actual}</td>
        <td>{tableData.shoes_bag_visitor_drain_out_rate_year_on_year}</td>
        <td>
          <pre>{tableData.appliances_hot_words}</pre>
        </td>
        <td>{tableData.appliances_flow_structure_search}</td>
        <td>{tableData.appliances_flow_structure_paid_promo}</td>
        <td>{tableData.appliances_flow_structure_pp_page}</td>
        <td>{tableData.appliances_flow_structure_activity}</td>
        <td>{tableData.appliances_cost_on_customer_actual}</td>
        <td>{tableData.appliances_cost_on_customer_budget}</td>
        <td>{tableData.appliances_cost_on_customer_rate_year_on_year}</td>
        <td>{tableData.appliances_visitor_drain_out_actual}</td>
        <td>{tableData.appliances_visitor_drain_out_rate_year_on_year}</td>
        <td>
          <pre>{tableData.furniture_hot_words}</pre>
        </td>
        <td>{tableData.furniture_flow_structure_search}</td>
        <td>{tableData.furniture_flow_structure_paid_promo}</td>
        <td>{tableData.furniture_flow_structure_pp_page}</td>
        <td>{tableData.furniture_flow_structure_activity}</td>
        <td>{tableData.furniture_cost_on_customer_actual}</td>
        <td>{tableData.furniture_cost_on_customer_budget}</td>
        <td>{tableData.furniture_cost_on_customer_rate_year_on_year}</td>
        <td>{tableData.furniture_visitor_drain_out_actual}</td>
        <td>{tableData.furniture_visitor_drain_out_rate_year_on_year}</td>
        <td>
          <pre>{tableData.fitness_hot_words}</pre>
        </td>
        <td>{tableData.fitness_flow_structure_search}</td>
        <td>{tableData.fitness_flow_structure_paid_promo}</td>
        <td>{tableData.fitness_flow_structure_pp_page}</td>
        <td>{tableData.fitness_flow_structure_activity}</td>
        <td>{tableData.fitness_cost_on_customer_actual}</td>
        <td>{tableData.fitness_cost_on_customer_budget}</td>
        <td>{tableData.fitness_cost_on_customer_rate_year_on_year}</td>
        <td>{tableData.fitness_visitor_drain_out_actual}</td>
        <td>{tableData.fitness_visitor_drain_out_rate_year_on_year}</td>
        <td>
          <pre>{tableData.toys_hot_words}</pre>
        </td>
        <td>{tableData.toys_flow_structure_search}</td>
        <td>{tableData.toys_flow_structure_paid_promo}</td>
        <td>{tableData.toys_flow_structure_pp_page}</td>
        <td>{tableData.toys_flow_structure_activity}</td>
        <td>{tableData.toys_cost_on_customer_actual}</td>
        <td>{tableData.toys_cost_on_customer_budget}</td>
        <td>{tableData.toys_cost_on_customer_rate_year_on_year}</td>
        <td>{tableData.toys_visitor_drain_out_actual}</td>
        <td>{tableData.toys_visitor_drain_out_rate_year_on_year}</td>
      </tr>
    </>
  )
}

FlowCategory.propTypes = {
  tableData: shape({}).isRequired
}

export default FlowCategory
