import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Card,
  DatePicker,
  Row,
  Col,
  Typography,
  Space,
  Table,
  Select,
  Button,
  Modal,
  Divider,
  Empty
} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
// import { find } from 'lodash'

import { getStaticText, storage, checkPerms } from '../../utils'
import weeklyTableDashboardColumns from '../../config/tableColumns/weeklyTableDashboardColumns'
import services from '../../services'
import dateFormat from '../../config/dateFormat'

const { Text } = Typography
const { Option } = Select

// 周日期格式化标准
const dateFormatWeek = dateFormat.week
// 周 dashboard 权限
const permission = 'weekly-sales/export-dashboard'

const WeekDashboard = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  const platforms = useMemo(() => {
    return storage.allowedPlatforms.val || {}
  }, [])

  // 默认请求参数 默认当前周减1
  const defaultReqData = {
    country: 'All_Country',
    platform: 'All_Platform',
    defaultWeek: moment().add(1, 'days').subtract(1, 'week')
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isLoad, setIsLoad] = useState(false)
  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const defaultWeekForSearch = nextSearch.get('week_key')
  if (defaultWeekForSearch) {
    const [year, week] = defaultWeekForSearch.split('-')
    const nowDate = moment().set('year', year).set('week', week)
    if (nowDate.isValid()) {
      defaultReqData.defaultWeek = nowDate
    }
  }
  const countryForSearch = nextSearch.get('country')
  if (countryForSearch) {
    defaultReqData.country = countryForSearch
  }
  const platformForSearch = nextSearch.get('platform')
  if (platformForSearch) {
    defaultReqData.platform = platformForSearch
  }

  const [tableData, setTableData] = useState([])

  const [requestParams, setRequestParams] = useState(() => {
    return {
      platform: defaultReqData.platform
        .split(',')
        .filter((item) => !(item === '')),
      country: defaultReqData.country
        .split(',')
        .filter((item) => !(item === '')),
      week_key: defaultReqData.defaultWeek,
      format: defaultReqData.defaultWeek.format(dateFormatWeek)
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      platform: requestParams.platform.toString(),
      country: requestParams.country.toString(),
      week_key: requestParams.format
    }
  }, [requestParams])

  // 判断所有平台
  const isAllPlatform = useCallback(
    (platform) => {
      if (requestParams.platform.includes('All_Platform')) {
        platform.forEach((platform) => {
          platform.disabled = true
        })

        platform[0].disabled = false
      } else if (requestParams.platform.length === 0) {
        platform.forEach((platform) => {
          platform.disabled = false
        })
      } else platform[0].disabled = true
    },
    [requestParams]
  )

  // 过滤出可供选择的平台
  const filterPlatform = useMemo(() => {
    if (
      !requestParams.country.length ||
      requestParams.country.toString() === 'All_Country'
    ) {
      const obj = {}
      const platform = Object.values(platforms)
        .flat()
        .reduce((pre, item) => {
          if (!obj[item.value]) {
            obj[item.value] = true
            pre.push(item)
          }
          return pre
        }, [])
      isAllPlatform(platform)
      return platform
    }
    let filterArray = []
    requestParams.country.forEach((item, index) => {
      filterArray[index] = platforms[item] || []
    })
    filterArray = filterArray.reduce((a, b) =>
      a.filter((c) => {
        let isEqual = false
        b.forEach((d) => {
          if (d.value === c.value) isEqual = true
        })
        return isEqual
      })
    )
    isAllPlatform(filterArray)
    return filterArray
  }, [requestParams, platforms, isAllPlatform])

  // countries 判断 disabled 属性
  const countries = useMemo(() => {
    const country = storage.allowedCountries.val || []
    if (requestParams.country.includes('All_Country')) {
      country.forEach((country) => {
        country.disabled = true
      })
      country[0].disabled = false
    } else if (requestParams.country.length === 0) {
      country.forEach((country) => {
        country.disabled = false
      })
    } else {
      country[0].disabled = true
    }
    return country
  }, [requestParams])

  /**
   *
   * @param {number} len rowSpan num
   * @param {string} fields table column of key
   */
  // const mergeTableCell = (len, fields = []) => {
  //   let obj = weeklyTableDashboardColumns
  //   fields.forEach((field) => {
  //     if (obj.children) {
  //       obj = find(obj.children, { key: field })
  //     } else {
  //       obj = find(obj, { key: field })
  //     }
  //   })
  //   if (Object.prototype.toString.call(obj) === '[object Object]') {
  //     obj.render = (value, row, index) => {
  //       const obj = {
  //         children: value,
  //         props: {
  //           rowSpan: 1
  //         }
  //       }
  //
  //       if (index === 0) {
  //         obj.props.rowSpan = len
  //       } else if (index < len) {
  //         obj.props.rowSpan = 0
  //       }
  //
  //       return obj
  //     }
  //   }
  // }

  const fetchData = useCallback(() => {
    toggleLoading()

    services.weeklySalesDashboard
      .getWeeklySales(requestParamsTransformer)
      .then((result) => {
        if (result.current_weekly_sales) {
          result.current_weekly_sales.map((item) => {
            item['app-user-mistakes'] = 'N/A'
            return item
          })
        }

        if (result.history_weekly_sales) {
          result.history_weekly_sales.map((item) => {
            item['app-user-mistakes'] = 'N/A'
            return item
          })
        }

        setTableData([
          ...result.current_weekly_sales,
          ...result.history_weekly_sales
        ])
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country,
      platform: ['All_Platform']
    }))
  }

  const handleChangeDate = useCallback((date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      week_key: date,
      format: dateString
    }))
  }, [])

  const handlePlatformChange = (platform) => {
    setRequestParams((prevState) => ({
      ...prevState,
      platform
    }))
  }

  const filter = () => {
    fetchData()
    setUrlSearchForState()
  }

  const [exportLoading, setExportLoading] = useState(false)

  const handleExport = () => {
    setExportLoading(true)
    services.weeklySales
      .exportDashboard(requestParamsTransformer)
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
      .finally(() => {
        setExportLoading(false)
      })
  }

  // 增加Review
  const [reviewsData, setReviewsData] = useState([])
  const [modalVisible, setModalDataVisible] = useState(false)
  const toggleModal = () => {
    setModalDataVisible((prevState) => {
      if (prevState === true) {
        setReviewsData([])
      }
      return !prevState
    })
  }

  const requestReviewParams = useCallback(
    (row) => {
      return {
        country: row.country,
        type: 2, // type: 1 代表日报，type: 2 代表周报
        date: requestParamsTransformer.week_key,
        current: 1,
        pageSize: 20
      }
    },
    [requestParamsTransformer]
  )

  const setModalData = useCallback(
    (row) => {
      const param = requestReviewParams(row)
      services.common.getBadReviewDetail(param).then((result) => {
        setReviewsData(result.data.data.review_details || [])
      })
      toggleModal()
    },
    [requestReviewParams]
  )
  useEffect(() => {
    if (!isLoad) {
      fetchData()
    }
    setIsLoad(true)
  }, [fetchData, isLoad])
  // 差评数
  const newColumns = useMemo(() => {
    const reviewColumn = weeklyTableDashboardColumns.filter(
      (column) => column.key === 'bad_review_total'
    )

    if (reviewColumn && reviewColumn.length) {
      reviewColumn[0].render = (data, row) => {
        if (row.platform === 'XPARK主站')
          return (
            <span
              className="clickLabel"
              onClick={() => setModalData(row)}
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
            >
              {data}
            </span>
          )
        return data
      }
    }

    // 差评率
    const reviewRateColumn = weeklyTableDashboardColumns
      .filter((column) => column.key === 'crowd_management')[0]
      .children.filter((column) => column.key === 'bad_review_rate')[0]
      .children.filter((column) => column.key === 'bad_review_rate')

    if (reviewRateColumn && reviewRateColumn.length) {
      reviewRateColumn[0].render = (data, row) => {
        if (row.platform === 'XPARK主站')
          return (
            <span
              className="clickLabel"
              onClick={() => setModalData(row)}
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
            >
              {data}
            </span>
          )
        return data
      }
    }

    return weeklyTableDashboardColumns
  }, [setModalData])

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.platform.text')}
          </Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.platform}
            onChange={handlePlatformChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {filterPlatform.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.time.text')}
          </Text>
          <DatePicker
            style={{ width: '250px' }}
            picker="week"
            format={dateFormatWeek}
            allowClear={false}
            defaultValue={requestParams.week_key}
            onChange={handleChangeDate}
          />
        </Space>
        <Space style={{ cssFloat: 'right' }}>
          <Button type="primary" onClick={filter}>
            {getStaticText('component.search.button.filter')}
          </Button>
        </Space>
      </Card>

      <Card>
        <Row>
          <Col span={12}>
            <Text>{getStaticText('component.table.header.title')}</Text>
          </Col>
          {checkPerms(permission) && (
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button onClick={handleExport} loading={exportLoading}>
                {getStaticText('component.table.export.text')}
              </Button>
            </Col>
          )}
        </Row>
      </Card>

      <Table
        sticky
        bordered
        size="small"
        loading={isLoading}
        columns={newColumns}
        dataSource={tableData}
        rowKey={(record) => record.id || record.platform}
        scroll={{ x: 'max-content', y: 500 }}
        pagination={false}
      />

      <Modal
        title={getStaticText('bad.review.detail')}
        visible={modalVisible}
        footer={null}
        onCancel={toggleModal}
      >
        {reviewsData.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          reviewsData.map((review) => {
            return (
              <div key={review.review_id}>
                <Row>
                  <Col span={24}>
                    <Text>
                      {getStaticText('review.title')}
                      <span>：</span>
                      {review.title}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {getStaticText('review.nickname')}
                      <span>：</span>
                      {review.nickname}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {getStaticText('review.rating')}
                      <span>：</span>
                      {review.rating}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {getStaticText('review.detail')}
                      <span>：</span>
                      {review.detail}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {getStaticText('review.ppshop')}
                      <span>：</span>
                      {review.pp_user.toString()}
                    </Text>
                  </Col>
                </Row>
                <Divider />
              </div>
            )
          })
        )}
      </Modal>
    </>
  )
}

WeekDashboard.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default WeekDashboard
