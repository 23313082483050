import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getWordsList = (handler) =>
  withTableLoading((params) => services.bbs.getWordsList(params).then(handler))

export const deleteWords = (handler) =>
  withGlobalLoading((id) => services.bbs.deleteWords(id).then(handler))

export const createWords = (handler) =>
  withGlobalLoading((params) => services.bbs.createWords(params).then(handler))

export const saveWords = (handler) =>
  withGlobalLoading((params) => services.bbs.saveWords(params).then(handler))
