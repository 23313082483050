import React, { useCallback } from 'react'
import { Layout, Menu } from 'antd'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getStaticText, storage } from '../../utils'

const { Sider } = Layout
const { SubMenu } = Menu

const SiderBar = (props) => {
  const { routes, history, location, currentScope, permissions } = props
  const path = location.pathname

  const defaultOpenKeys = path
    .split('/')
    .slice(1)
    .map((pathKey) => `/${pathKey}`)

  const generatorMenus = useCallback(
    (routes) => {
      if (!currentScope.key) {
        return null
      }
      return routes.map((route) => {
        const children = route.routes ?? []
        if (route.scopes && route.scopes.indexOf(currentScope.key) === -1) {
          return
        }
        if (route.hide && route.hide === true) {
          return
        }
        if (children.length) {
          const childMenu = generatorMenus(children).filter((x) => x)
          if (childMenu.length) {
            return (
              <SubMenu title={getStaticText(route.primary)} key={route.path}>
                {childMenu}
              </SubMenu>
            )
          }
          return
        }
        if ([storage.modular.val, 'index'].indexOf(route.modular) === -1) {
          return
        }
        if (
          permissions.indexOf('/') === -1 &&
          permissions.indexOf(route.permission) === -1 &&
          route.modular !== 'index'
        ) {
          return
        }
        return (
          <Menu.Item
            key={route.path}
            onClick={() => {
              history.push(route.path)
            }}
          >
            {getStaticText(route.primary)}
          </Menu.Item>
        )
      })
    },
    [currentScope.key, permissions, history]
  )

  const getText = () => `app.sider.logo.${storage.modular.val}`

  return (
    <Sider width={300} collapsible collapsedWidth={120}>
      <div
        style={{
          color: 'white',
          height: 60,
          textAlign: 'center',
          lineHeight: '60px'
        }}
      >
        {currentScope.prefix}
        {`-${getStaticText(getText())}`}
      </div>
      <Menu
        theme="dark"
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={[path]}
        mode="inline"
      >
        {generatorMenus(routes)}
      </Menu>
    </Sider>
  )
}

SiderBar.propTypes = {
  history: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired
    })
  ).isRequired,
  location: PropTypes.shape().isRequired,
  currentScope: PropTypes.shape().isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
}

const mapStateToProps = ({ global, users }) => ({
  currentScope: global.currentScope,
  permissions: users.userInfo.user.permissions
})

export default connect(mapStateToProps)(withRouter(SiderBar))
