import React from 'react'
import Crowd from './Crowd'
import FlowPlatform from './FlowPlatform'
import FlowStructure from './FlowStructure'
import FlowCategory from './FlowCategory'
import PrivateDomain from './PrivateDomain'
import NewMedia from './NewMedia'
import Crm from './Crm'

function PeopleResult(props) {
  return (
    <>
      {/* 过程报表 */}
      <tbody>
        <tr className="table-th-color">
          <th className="table-left head-category" colSpan="211">
            <span className="sticky-left sticky-title">过程报表</span>
          </th>
        </tr>

        <Crowd {...props} />
        <FlowPlatform {...props} />
        <FlowStructure {...props} />
        <FlowCategory {...props} />
        <PrivateDomain {...props} />
        <NewMedia {...props} />
        <Crm {...props} />
      </tbody>
    </>
  )
}
export default PeopleResult
