/* eslint-disable */
import React from 'react'
import { Select, Form } from 'antd'
import '../../../css/custom-input-select.css'

const { Option } = Select

interface Props {
  placeHolder: String;
  option: Array;
  funcParent: Function;
  value: any;
  name: String;
}
const BlackInputSelect = ({
  placeHolder,
  option = [],
  funcParent,
  value,
  name
}: Props) => {
  return (
    <Form.Item>
      <Select
        onChange={funcParent}
        className="black-input-container"
        value={value}
        size="large"
        style={{
          background: 'black',
          color: 'white',
          width: '100%',
          fontSize: '15px'
        }}
        placeholder={placeHolder}
      >
        {option?.length > 0
          ? option?.map((item) => {
            return <Option value={item.value}>{item.label}</Option>
          })
          : null}
      </Select>
    </Form.Item>
  )
}

export default BlackInputSelect
