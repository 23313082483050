/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Row, Select, Table, Typography} from 'antd'
import '../../../css/sku-information.css'
import _ from "lodash";
import {ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import BlackButton from "../../../components/1688ProjectComponents/ButtonComponents/BlackButton";
import WhiteButton from "../../../components/1688ProjectComponents/ButtonComponents/WhiteButton";

const { Title } = Typography
const { Option } = Select

interface Props {
  productDetailData: Array;
  onNewSKUList : any;
}
const SKUInformation = (props: Props) => {
  const [formSKU] = Form.useForm();
  const [formSKUFilter] = Form.useForm();
  const [productDetailData, setProductDetailData] = useState((JSON.parse(JSON.stringify(props.productDetailData))));
  const [productDetailFilterData, setProductDetailFilterData] = useState((JSON.parse(JSON.stringify(props.productDetailData))));

  useEffect(() => {
    getSKUData();
  }, []);

  useEffect(() => {
    setProductDetailData(props.productDetailData);
    setProductDetailFilterData(JSON.parse(JSON.stringify(props.productDetailData)));
  }, [props.productDetailData]);

  function removeSpecification(attributeNameTrans) {
    let copyProductDetailData = JSON.parse(JSON.stringify(productDetailData));
    let sku_info = []

    for (let sku of copyProductDetailData.sku_info) {
      let skuAttributes = sku.skuAttributes
      let newSkuAttributes = []
      for (let skuAttribute of skuAttributes) {
        if (skuAttribute.attributeNameTrans !== attributeNameTrans) {
          newSkuAttributes.push(skuAttribute)
        }
      }

      sku_info.push({
        ...sku,
        skuAttributes: newSkuAttributes
      })
    }

    let newProductDetailData = JSON.parse(JSON.stringify(productDetailData));

    newProductDetailData["sku_info"] = sku_info;
    setProductDetailData(newProductDetailData);
    setProductDetailFilterData(newProductDetailData);
    formSKU.setFieldsValue(newProductDetailData);
    props.onNewSKUList(sku_info);
    formSKU.validateFields();
  }

  function moveSpecification(attributeNameTrans, moveTo: "top" | "down") {
    function moveElement(arr, fromIndex, toIndex) {
      if (toIndex >= arr.length || toIndex < 0) {
        return;
      }
      const element = arr.splice(fromIndex, 1)[0];
      arr.splice(toIndex, 0, element);
    }


    let copyProductDetailData = JSON.parse(JSON.stringify(productDetailData));

    for (let sku of copyProductDetailData.sku_info) {
      let skuAttributes = sku.skuAttributes;
      let attr = skuAttributes.map(item => item.attributeNameTrans);

      let fromIndex = attr.indexOf(attributeNameTrans);
      let toIndex = moveTo === "top" ? fromIndex - 1 : fromIndex + 1;
      if (fromIndex !== -1 && toIndex >= 0 && toIndex < skuAttributes.length) {
        moveElement(skuAttributes, fromIndex, toIndex);
      }
    }

    setProductDetailData(copyProductDetailData);
    setProductDetailFilterData(copyProductDetailData);
    formSKU.setFieldsValue(copyProductDetailData);
    props.onNewSKUList(copyProductDetailData.sku_info);
    formSKU.validateFields();
  }

  function getObjectForSubmitSKUList() {
    let submittedSkuList = formSKU.getFieldsValue();
    if (_.isEmpty(productDetailData)) {
      return [];
    }

    let newSKUInfo= [];
    for (let skuInfo of productDetailData.sku_info) {
      let skuId = skuInfo.skuId;
      let foundData = _.find(submittedSkuList, (obj) => obj.skuId == skuId);
      skuInfo["Weight"] = foundData.Weight;
      skuInfo["amountOnSale"] = Number(foundData.amountOnSale);
      skuInfo["custom_sku"] = foundData.custom_sku;
      skuInfo["sellingPrice"] = foundData.sourcePrice;
      for (let skuAttr of skuInfo.skuAttributes) {
        let key = `attr-${skuAttr.attributeId}`;
        if (foundData.hasOwnProperty(key)) {
          skuAttr["valueTrans"] = foundData[key];
        }
      }
      newSKUInfo.push(skuInfo);
    }
    return newSKUInfo;

  }

  function getSKUColumnsList() {
    if (_.isEmpty(productDetailData)) {
      return [];
    }
    let allSKUArr = getSKUAttributes(productDetailData);

    let columns = [];
    for (let i in allSKUArr) {
      let sku = allSKUArr[i];
      columns.push({
        title: sku["attributeNameTrans"],
        dataIndex: sku["attributeNameTrans"],
        width : 200,
        render: (text, record, index) =>
          <Form.Item
            noStyle={true}
            name={[index,`attr-${sku.attributeId}`]}
            label=""
            rules={[{ required: true }]}>
            <Typography>{record[`attr-${sku.attributeId}`]}</Typography>
          </Form.Item>,
      })
    }
    columns.push({
      title: 'Source Price',
      dataIndex: 'sourcePrice',
      render: (text, record, index) => {

        return (
          <Form.Item
            name={[index,`sourcePrice`]}
            noStyle={true}
            label=""
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        )
      },
    })
    columns.push({
      title: 'Stock',
      dataIndex: 'amountOnSale',
      render: (text, record, index) => <Form.Item
        name={[index,`amountOnSale`]}
        noStyle={true}
        label=""
        rules={[{ required: true }]}>
        <Input />
      </Form.Item>,
    })
    columns.push({
      title: 'Platform SKU Automatic',
      dataIndex: 'custom_sku',
      render: (text, record, index) => <Form.Item
        name={[index,`custom_sku`]}
        noStyle={true}
        label=""
        rules={[{ required: true }]}>
        <Input />
      </Form.Item>,
    })
    columns.push({
      title: 'Weight Batch',
      dataIndex: 'Weight',
      render:(text, record, index)=> <>
        <Form.Item
          name={[index,`Weight`]}
          noStyle={true}
          label=""
          rules={[{ required: true }]}>
          <Input addonAfter={
            <Form.Item name={[index, "suffix"]} noStyle>
              <Select style={{ width: 70 }}>
                <Option value="kg">KG</Option>
              </Select>
            </Form.Item>
          }/>
        </Form.Item>
        <Form.Item
          hidden={true}
          name={[index,`skuId`]}
          label=""
          rules={[{ required: false }]}>
          <Input />
        </Form.Item>
        <Form.Item
          hidden={true}
          name={[index,`specId`]}
          label=""
          rules={[{ required: false }]}>
          <Input />
        </Form.Item>
      </>
    })
    return columns;
  }



  function getSKUData() {
    let newSKUData = [];
    if (_.isEmpty(productDetailData)) {
      return [];
    }
    let allSKUArr = getSKUAttributes(productDetailData);
    for (let skuInfo of productDetailData.sku_info) {
      let newData = {};
      for (let skuAttr of allSKUArr) {
        let foundData = _.find(skuInfo.skuAttributes, (obj) => obj.attributeId === skuAttr.attributeId);
        if (foundData) {
          newData[`attr-${skuAttr.attributeId}`] = foundData.valueTrans;
        } else {
          newData[`attr-${skuAttr.attributeId}`] = "";
        }
      }
      newData["sourcePrice"] = skuInfo.hasOwnProperty("sellingPrice") ? skuInfo.sellingPrice : skuInfo.consignPrice;
      newData["amountOnSale"] = skuInfo.hasOwnProperty("amountOnSale") ? skuInfo.amountOnSale : 0;
      newData["custom_sku"] = skuInfo.hasOwnProperty("custom_sku") ? skuInfo.custom_sku : "";
      newData["Weight"] = skuInfo.hasOwnProperty("Weight") ? skuInfo.Weight : "";
      newData["suffix"] = "kg";
      newData["skuId"] = skuInfo.skuId;
      newData["specId"] = skuInfo.specId;

      newSKUData.push(newData);
    }
    formSKU.setFieldsValue(newSKUData);
    formSKUFilter.setFieldsValue(getDefaultFormData());
    return newSKUData;
  }

  function getSKUAttributes(listProduct) {
    let allSKUAttributes = {};
    if (_.isEmpty(listProduct)) {
      return [];
    }
    for (let skuInfo of listProduct.sku_info) {
      for (let skuAttr of skuInfo.skuAttributes) {
        if (!allSKUAttributes.hasOwnProperty(skuAttr.attributeId)) {
          allSKUAttributes[skuAttr.attributeId] = skuAttr;
          allSKUAttributes[skuAttr.attributeId]["values"] = [skuAttr.valueTrans];
        } else {
          if (allSKUAttributes[skuAttr.attributeId]["values"].indexOf(skuAttr.valueTrans) === -1) {
            allSKUAttributes[skuAttr.attributeId]["values"].push(skuAttr.valueTrans);
          }
        }
      }
    }
    function sortAttributes(attributes, order) {
      let orderMap = order.reduce((acc, name, index) => {
        acc[name] = index;
        return acc;
      }, {});

      return attributes.sort((a, b) => {
        return orderMap[a.attributeNameTrans] - orderMap[b.attributeNameTrans];
      });
    }

    const attributeNameTrans = listProduct.sku_info[0]?.skuAttributes?.map(item => item.attributeNameTrans)
    return sortAttributes(Object.values(allSKUAttributes), attributeNameTrans);
  }

  function generatePermutations(data) {
    const attributes = Object.keys(data.attr);
    const combinations = [];

    function generateCombinations(attrIndex, currentCombination) {
      if (attrIndex === attributes.length) {
        combinations.push(currentCombination);
        return;
      }

      const attrId = attributes[attrIndex];
      const attrName = data.attr[attrId];
      const attrValues = data[attrId].map(item => ({
        attributeId: attrId,
        attributeName: attrName,
        attributeNameTrans: attrName,
        value: item.valueTrans,
        valueTrans: item.valueTrans,
        isNew: item.isNew
      }));

      for (const value of attrValues) {
        const newCombination = [...currentCombination, value];
        generateCombinations(attrIndex + 1, newCombination);
      }
    }

    generateCombinations(0, []);
    return combinations;
  }

  function handleUpdateFilter() {
    let _formSKU = Object.values(formSKU.getFieldsValue())

    let filters = formSKUFilter.getFieldsValue();
    let permutations = generatePermutations(filters);

    let result = []
    for (const index in permutations) {
      let combination = permutations[index]
      let identify = {}
      for (const c of combination) {
        identify[`attr-${c.attributeId}`] = c.valueTrans
      }

      const flavorAttribute = _formSKU.find(attr => {
        for (const key of Object.keys(identify)) {
          if (attr[key] !== identify[key]) {
            return false
          }
        }
        return true
      })
      if (flavorAttribute) {
        result.push({
          ...productDetailData.sku_info?.[0],
          "amountOnSale": flavorAttribute?.amountOnSale,
          "skuId": index,
          "specId": flavorAttribute?.specId,
          "skuAttributes": combination,
          "sellingPrice": flavorAttribute?.sourcePrice ?? flavorAttribute?.consignPrice ?? 0,
          "consignPrice": flavorAttribute?.sourcePrice ?? flavorAttribute?.consignPrice ?? 0,
          "cargoNumber": "",
          "suffix": flavorAttribute?.suffix ?? "kg",
          "custom_sku": flavorAttribute?.custom_sku ?? "",
          "Weight": flavorAttribute?.Weight ?? "",
        })
      } else {
        result.push({
          ...productDetailData.sku_info?.[0],
          "amountOnSale": 0,
          "skuId": index,
          "skuAttributes": combination,
          "consignPrice": 0,
          "custom_sku": "",
          "Weight": "",
        })
      }
    }

    let newProductDetailData = JSON.parse(JSON.stringify(productDetailData));

    newProductDetailData["sku_info"] = result;
    setProductDetailData(newProductDetailData);
    setProductDetailFilterData(newProductDetailData);
    formSKU.setFieldsValue(newProductDetailData);
    props.onNewSKUList(result);
    formSKU.validateFields();
  }

  function onAddNewFilterSpecifications() {
    let newProductDetailData = JSON.parse(JSON.stringify(productDetailFilterData));
    let skuInfo = newProductDetailData.sku_info;
    skuInfo.push({
      "amountOnSale": "",
      "skuId" : "",
      "skuAttributes": [
        {
          "attributeId": new Date().getTime(),
          "attributeName": "",
          "attributeNameTrans": "",
          "value": "",
          "valueTrans": "",
          "isNew" : true,
          "values": []
        }
      ],
      "isNew" : true,
      "sellingPrice": "",
      "consignPrice": "",
      "cargoNumber": "",
      "custom_sku": "",
      "Weight": ""
    })
    setProductDetailFilterData(newProductDetailData);
  }

  function getDefaultFormData() {
    let defaultFormData = {};
    defaultFormData["attr"] = {};
    let skuAttributes = getSKUAttributes(productDetailFilterData);
    for (let skuAttr of skuAttributes) {
      let newValues = [];
      for (let value of skuAttr.values) {
        newValues.push({
          isNew: skuAttr.hasOwnProperty("isNew") ? skuAttr.isNew : false,
          valueTrans: value
        })
      }
      defaultFormData[skuAttr.attributeId] = newValues;
      defaultFormData["attr"][skuAttr.attributeId] = skuAttr.attributeNameTrans;
    }
    return defaultFormData;
  }


  function renderFormFilterList() {
    let list = [];
    let skuAttributes = getSKUAttributes(productDetailFilterData);

    for (let index in skuAttributes) {
      let skuAttr = skuAttributes[index];
      list.push(<>
        <Row>
          <Col span={12}>
            <Form.Item
              label=""
              noStyle={true}
              name={['attr',`${skuAttr.attributeId.toString()}`]}
              rules={[{ required: true, message: 'Missing price' }]}
            >
              <Input disabled={skuAttr.attributeName !== ""}
                     prefix={`Specification  ${Number(index) + 1}: `}
                     suffix={`${skuAttr.values.length} words`}
              />
            </Form.Item>
          </Col>
          {index > 0 &&
            <Col span={1} style={{backgroundColor : "black", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <DeleteOutlined style={{color: "white", fontSize: 18}} onClick={() => {
                removeSpecification(skuAttr.attributeNameTrans)
              }} />
            </Col>
          }
          {index < skuAttributes.length - 1 &&
            <Col span={1} style={{backgroundColor : "black", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <ArrowDownOutlined style={{color: "white", fontSize: 18}} onClick={() => {
                moveSpecification(skuAttr.attributeNameTrans, "down")
              }} />
            </Col>
          }

          {index > 0 &&
            <Col span={1} style={{backgroundColor : "black", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <ArrowUpOutlined style={{color: "white", fontSize: 18}} onClick={() => {
                moveSpecification(skuAttr.attributeNameTrans, "top")
              }} />
            </Col>
          }

        </Row>
        <Form.List name={skuAttr.attributeId.toString()}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row key={field.key} align="baseline">
                  <Col span={11}>
                    <Form.Item
                      {...field}
                      hidden={true}
                      label=""
                      noStyle={true}
                      name={[field.name, 'isNew']}
                      rules={[{ required: true, message: 'Missing price' }]}
                    >
                      <Input/>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label=""
                      noStyle={true}
                      name={[field.name, 'valueTrans']}
                      rules={[{ required: true, message: 'Missing price' }]}
                    >
                      <Input suffix={`${skuAttr.values.length} words`}/>
                    </Form.Item>
                  </Col>
                  {formSKUFilter.getFieldValue(skuAttr.attributeId.toString()).length > 1 && (
                    <Col span={1} style={{backgroundColor : "black", display: "flex", alignItems: "center", justifyContent: "center"}}>
                      <DeleteOutlined style={{color: "white", fontSize: 18}} onClick={() => remove(field.name)} />
                    </Col>
                  )}
                </Row>
              ))}
              <Col span={12}>
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block>
                    Add Options
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
        </Form.List>

      </>)
    }
    return <>
      {/*<Form form={formSKUFilter} defaultValue={getDefaultFormData()} onChange={(e) => onFormSKUFilterChange(e)}>*/}
      <Form form={formSKUFilter} defaultValue={getDefaultFormData()}>
        {list}
        <Form.Item>
          <Row gutter={16}>
            <Col span={6}>
              <WhiteButton title={<><PlusOutlined />New specifications</>}  functionParent={() => onAddNewFilterSpecifications()}/>
            </Col>
            <Col span={6}>
              <BlackButton functionParent={handleUpdateFilter} title="Save Changes" />
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  }

  return (
    <>
      {renderFormFilterList()}
      <Form
        onChange={(e) =>  props.onNewSKUList(getObjectForSubmitSKUList())}
        form={formSKU}
      >
        <Table columns={getSKUColumnsList()} dataSource={getSKUData()} />
      </Form>
    </>
  )
}

export default SKUInformation
