export const columns = [
  {
    title: 'Product Id',
    width: '80px',
    align: 'center',
    dataIndex: 'product_id',
    key: 'product_id'
  },
  {
    title: 'Name',
    key: 'name',
    align: 'center',
    render: (data) => data.summary && data.summary.name
  },
  {
    title: 'Display Name',
    dataIndex: 'extra_text',
    key: 'extra_text',
    align: 'center',
    width: 70
  },
  {
    title: 'Price',
    key: 'final_price',
    align: 'center',
    render: (data) => data.summary && data.summary.final_price_symbol
  },
  {
    title: 'Stock',
    key: 'stock',
    width: '60px',
    dataIndex: 'stock',
    align: 'center'
  },
  {
    title: 'Used',
    align: 'center',
    width: '60px',
    dataIndex: 'cut_stocked',
    key: 'cut_stocked'
  },
  {
    title: 'Share times',
    align: 'center',
    width: '80px',
    dataIndex: 'share_times',
    key: 'share_times'
  },
  {
    title: 'Chop times',
    align: 'center',
    width: '80px',
    dataIndex: 'chop_times',
    key: 'chop_times'
  },
  {
    title: 'Chop Success',
    align: 'center',
    width: '80px',
    dataIndex: 'chop_success',
    key: 'chop_success'
  },
  {
    title: 'Chop People Numbers',
    align: 'center',
    width: '80px',
    dataIndex: 'chop_people_numbers',
    key: 'chop_people_numbers'
  },
  {
    title: 'Discount Success',
    align: 'center',
    width: '80px',
    dataIndex: 'discount_success',
    key: 'discount_success'
  },
  {
    title: 'Ordered',
    align: 'center',
    width: '80px',
    dataIndex: 'ordered',
    key: 'ordered'
  },
  {
    title: 'Discount Ordered',
    align: 'center',
    width: '80px',
    dataIndex: 'discount_ordered',
    key: 'discount_ordered'
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
