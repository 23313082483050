import Jump from '../pages/Merchant/Jump'

const merchant = {
  path: '/merchant',
  primary: 'app.sider.merchant',
  modular: 'merchant',
  permission: 'jump/jump-xpark',
  exact: true,
  component: Jump
}
export default merchant
