import React from 'react'

interface Props {
  title: String;
  space: String;
  colon: Boolean;
  widthTitle: String;
}
const LabelInputTildeColon = ({ title, space, colon, widthTitle }: Props) => {
  return (
    <p
      style={{
        fontSize: '15px',
        marginBottom: 'unset',
        display: 'flex',
        alignItems: 'center',
        minWidth: '144px',
        width: widthTitle || '100%'
      }}
    >
      <div>{title}</div>
      {colon && (
        <div style={{ marginLeft: space }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            <div style={{ position: 'absolute', top: '-9px' }}>~</div>
            <div style={{ position: 'absolute', bottom: '-9px' }}>~</div>
          </div>
        </div>
      )}
    </p>
  )
}

export default LabelInputTildeColon
