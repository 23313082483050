import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function ConversionRateRepurchase(props) {
  const { tableData } = props
  return (
    <>
      {/* 转化率-复购 */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="4" className="sticky-left sticky-second">
          转化率-复购
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>和周报一致</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="16">
          P人群经营--XPARK全类目（以18个月为时间段持续更新）
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>以手机类目为例，假定: </p>
                <p>手机使用周期：10个月</p>
                <p>手机复购周期：8个月</p>
                <p>手机上架周期：6个</p>
                <p>月 数据统计周期：18个月</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="16">
          P人群经营--手机类目（以18个月为时间段持续更新）
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>以手机类目为例，假定: </p>
                <p>手机使用周期：10个月</p>
                <p>手机复购周期：8个月</p>
                <p>手机上架周期：6个</p>
                <p>月 数据统计周期：18个月</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="16">
          P人群经营--3C类目（以12个月为时间段持续更新）
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>3C类目，假定:</p>
                <p>3C使用周期：6个月</p>
                <p>3C复购周期：4个月</p>
                <p>3C上架周期：3个月</p>
                <p>数据统计周期：12个月</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="16">
          P人群经营--美妆类目（以12个月为时间段持续更新）
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>美妆类目，假定:</p>
                <p>美妆使用周期：3个月</p>
                <p>美妆复购周期：2个月</p>
                <p>美妆上架周期：6个月</p>
                <p>数据统计周期：12个月</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="16">
          P人群经营--大时尚类目（服装+鞋包配饰）（以12个月为时间段持续更新）
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>大时尚类目，假定:</p>
                <p>大时尚使用周期：3个月</p>
                <p>大时尚复购周期：2个月</p>
                <p>大时尚上架周期：3个月</p>
                <p>数据统计周期：12个月</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row2 */}
      <tr>
        <th colSpan="3">
          最近购买时长
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  最近0-8个月内购买1次及以上用户占比=最近0-8个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  最近8-10个月内购买1次及以上用户占比=最近8-10个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  最近10-18个月内购买1次及以上用户占比=最近10-18个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  例如：手机类目，从最近购买时长看，接近xxx%成交顾客最近一次购买时间超过8个月。由于手机激活后使用周期在10个月左右，最近购买时间超过8个月的顾客，非常容易被竞品转化。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          消费频率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>消费频率：</p>
                <p>购买1次用户占比=购买1次用户/18个月内购买用户总数</p>
                <p>购买2次用户占比=购买2次用户/18个月内购买用户总数</p>
                <p>
                  购买3次及以上用户占比=购买3次及以上用户/18个月内购买用户总数
                </p>
                <p>
                  例如：统计周期内，xx%的，购买一次，yy%购买两次，zz%购买3次。
                </p>
                <p>
                  数据统计期为18个月，手机使用周期为10个月，统计周期远大于产品实际使用周期。
                </p>
                <p>
                  手机类目，如果数据结果是高比例消费者购买1次而已，证明购买频次过低，消费者的第1次购买目的以尝新为主，并未打算复购，且有可能流失到其他品牌；需要提高消费频率从而提升消费者的忠诚度。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">
          购物金额
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  70美元以下购买用户数占比=70美元以下购买用户数/18个月内购买用户总数
                </p>
                <p>
                  70-100美元之间购买用户数占比=70-100美元之间购买用户数/18个月内购买用户总数s
                </p>
                <p>
                  100-150美元以上购买用户数占比=100-150美元以上购买用户数/18个月内购买用户总数
                </p>
                <p>
                  150美元以上购买用户数占比=150美元以上购买用户数/18个月内购买用户总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          复购率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=18个月内购买2次及以上人数/18个月内总购买用户数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          复购周期占比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  0-8个月的复购周期占比=0-8个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  8-10个月的复购周期占比=8-10个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  10-18个月的复购周期占比=10-18个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  例如：从复购周期来看，一般手机类目，复购顾客应该在8个月以内产生复购，如果这种顾客占总购买用户的比例低，说明需要加强运营顾客生命周期价值管理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          连带率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>客件数=成交商品件数除以成交人数</p>
                <p>例如：</p>
                <p>
                  从消费件数来看，近xx%的用户选择只购买1件商品，连带率较低；
                  另外，平均客件数可以看出连带率，如果接近1，说明存在消费者尝新的心智，但不产生其他产品的连带。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          差评率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=差评数/总评价数量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th colSpan="3">
          最近购买时长
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  最近0-8个月内购买1次及以上用户占比=最近0-8个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  最近8-10个月内购买1次及以上用户占比=最近8-10个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  最近10-18个月内购买1次及以上用户占比=最近10-18个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  例如：手机类目，从最近购买时长看，接近xxx%成交顾客最近一次购买时间超过8个月。由于手机激活后使用周期在10个月左右，最近购买时间超过8个月的顾客，非常容易被竞品转化。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          消费频率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>消费频率：</p>
                <p>购买1次用户占比=购买1次用户/18个月内购买用户总数</p>
                <p>购买2次用户占比=购买2次用户/18个月内购买用户总数</p>
                <p>
                  购买3次及以上用户占比=购买3次及以上用户/18个月内购买用户总数
                </p>
                <p>
                  例如：统计周期内，xx%的，购买一次，yy%购买两次，zz%购买3次。
                </p>
                <p>
                  数据统计期为18个月，手机使用周期为10个月，统计周期远大于产品实际使用周期。
                </p>
                <p>
                  手机类目，如果数据结果是高比例消费者购买1次而已，证明购买频次过低，消费者的第1次购买目的以尝新为主，并未打算复购，且有可能流失到其他品牌；需要提高消费频率从而提升消费者的忠诚度。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">
          购物金额
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  70美元以下购买用户数占比=70美元以下购买用户数/18个月内购买用户总数
                </p>
                <p>
                  70-100美元之间购买用户数占比=70-100美元之间购买用户数/18个月内购买用户总数s
                </p>
                <p>
                  100-150美元以上购买用户数占比=100-150美元以上购买用户数/18个月内购买用户总数
                </p>
                <p>
                  150美元以上购买用户数占比=150美元以上购买用户数/18个月内购买用户总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          复购率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=18个月内购买2次及以上人数/18个月内总购买用户数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          复购周期占比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  0-8个月的复购周期占比=0-8个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  8-10个月的复购周期占比=8-10个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  10-18个月的复购周期占比=10-18个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  例如：从复购周期来看，一般手机类目，复购顾客应该在8个月以内产生复购，如果这种顾客占总购买用户的比例低，说明需要加强运营顾客生命周期价值管理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          连带率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>客件数=成交商品件数除以成交人数</p>
                <p>例如：</p>
                <p>
                  从消费件数来看，近xx%的用户选择只购买1件商品，连带率较低；
                  另外，平均客件数可以看出连带率，如果接近1，说明存在消费者尝新的心智，但不产生其他产品的连带。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          差评率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=差评数/总评价数量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th colSpan="3">
          最近购买时长
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  最近0-8个月内购买1次及以上用户占比=最近0-8个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  最近8-10个月内购买1次及以上用户占比=最近8-10个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  最近10-18个月内购买1次及以上用户占比=最近10-18个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  例如：手机类目，从最近购买时长看，接近xxx%成交顾客最近一次购买时间超过8个月。由于手机激活后使用周期在10个月左右，最近购买时间超过8个月的顾客，非常容易被竞品转化。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          消费频率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>消费频率：</p>
                <p>购买1次用户占比=购买1次用户/18个月内购买用户总数</p>
                <p>购买2次用户占比=购买2次用户/18个月内购买用户总数</p>
                <p>
                  购买3次及以上用户占比=购买3次及以上用户/18个月内购买用户总数
                </p>
                <p>
                  例如：统计周期内，xx%的，购买一次，yy%购买两次，zz%购买3次。
                </p>
                <p>
                  数据统计期为18个月，手机使用周期为10个月，统计周期远大于产品实际使用周期。
                </p>
                <p>
                  手机类目，如果数据结果是高比例消费者购买1次而已，证明购买频次过低，消费者的第1次购买目的以尝新为主，并未打算复购，且有可能流失到其他品牌；需要提高消费频率从而提升消费者的忠诚度。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">
          购物金额
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  70美元以下购买用户数占比=70美元以下购买用户数/18个月内购买用户总数
                </p>
                <p>
                  70-100美元之间购买用户数占比=70-100美元之间购买用户数/18个月内购买用户总数s
                </p>
                <p>
                  100-150美元以上购买用户数占比=100-150美元以上购买用户数/18个月内购买用户总数
                </p>
                <p>
                  150美元以上购买用户数占比=150美元以上购买用户数/18个月内购买用户总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          复购率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=18个月内购买2次及以上人数/18个月内总购买用户数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          复购周期占比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  0-8个月的复购周期占比=0-8个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  8-10个月的复购周期占比=8-10个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  10-18个月的复购周期占比=10-18个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  例如：从复购周期来看，一般手机类目，复购顾客应该在8个月以内产生复购，如果这种顾客占总购买用户的比例低，说明需要加强运营顾客生命周期价值管理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          连带率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>客件数=成交商品件数除以成交人数</p>
                <p>例如：</p>
                <p>
                  从消费件数来看，近xx%的用户选择只购买1件商品，连带率较低；
                  另外，平均客件数可以看出连带率，如果接近1，说明存在消费者尝新的心智，但不产生其他产品的连带。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          差评率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=差评数/总评价数量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th colSpan="3">
          最近购买时长
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  最近0-8个月内购买1次及以上用户占比=最近0-8个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  最近8-10个月内购买1次及以上用户占比=最近8-10个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  最近10-18个月内购买1次及以上用户占比=最近10-18个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  例如：手机类目，从最近购买时长看，接近xxx%成交顾客最近一次购买时间超过8个月。由于手机激活后使用周期在10个月左右，最近购买时间超过8个月的顾客，非常容易被竞品转化。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          消费频率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>消费频率：</p>
                <p>购买1次用户占比=购买1次用户/18个月内购买用户总数</p>
                <p>购买2次用户占比=购买2次用户/18个月内购买用户总数</p>
                <p>
                  购买3次及以上用户占比=购买3次及以上用户/18个月内购买用户总数
                </p>
                <p>
                  例如：统计周期内，xx%的，购买一次，yy%购买两次，zz%购买3次。
                </p>
                <p>
                  数据统计期为18个月，手机使用周期为10个月，统计周期远大于产品实际使用周期。
                </p>
                <p>
                  手机类目，如果数据结果是高比例消费者购买1次而已，证明购买频次过低，消费者的第1次购买目的以尝新为主，并未打算复购，且有可能流失到其他品牌；需要提高消费频率从而提升消费者的忠诚度。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">
          购物金额
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  70美元以下购买用户数占比=70美元以下购买用户数/18个月内购买用户总数
                </p>
                <p>
                  70-100美元之间购买用户数占比=70-100美元之间购买用户数/18个月内购买用户总数s
                </p>
                <p>
                  100-150美元以上购买用户数占比=100-150美元以上购买用户数/18个月内购买用户总数
                </p>
                <p>
                  150美元以上购买用户数占比=150美元以上购买用户数/18个月内购买用户总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          复购率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=18个月内购买2次及以上人数/18个月内总购买用户数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          复购周期占比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  0-8个月的复购周期占比=0-8个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  8-10个月的复购周期占比=8-10个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  10-18个月的复购周期占比=10-18个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  例如：从复购周期来看，一般手机类目，复购顾客应该在8个月以内产生复购，如果这种顾客占总购买用户的比例低，说明需要加强运营顾客生命周期价值管理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          连带率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>客件数=成交商品件数除以成交人数</p>
                <p>例如：</p>
                <p>
                  从消费件数来看，近xx%的用户选择只购买1件商品，连带率较低；
                  另外，平均客件数可以看出连带率，如果接近1，说明存在消费者尝新的心智，但不产生其他产品的连带。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          差评率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=差评数/总评价数量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th colSpan="3">
          最近购买时长
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  最近0-8个月内购买1次及以上用户占比=最近0-8个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  最近8-10个月内购买1次及以上用户占比=最近8-10个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  最近10-18个月内购买1次及以上用户占比=最近10-18个月内购买1次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  例如：手机类目，从最近购买时长看，接近xxx%成交顾客最近一次购买时间超过8个月。由于手机激活后使用周期在10个月左右，最近购买时间超过8个月的顾客，非常容易被竞品转化。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          消费频率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>消费频率：</p>
                <p>购买1次用户占比=购买1次用户/18个月内购买用户总数</p>
                <p>购买2次用户占比=购买2次用户/18个月内购买用户总数</p>
                <p>
                  购买3次及以上用户占比=购买3次及以上用户/18个月内购买用户总数
                </p>
                <p>
                  例如：统计周期内，xx%的，购买一次，yy%购买两次，zz%购买3次。
                </p>
                <p>
                  数据统计期为18个月，手机使用周期为10个月，统计周期远大于产品实际使用周期。
                </p>
                <p>
                  手机类目，如果数据结果是高比例消费者购买1次而已，证明购买频次过低，消费者的第1次购买目的以尝新为主，并未打算复购，且有可能流失到其他品牌；需要提高消费频率从而提升消费者的忠诚度。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="4">
          购物金额
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  70美元以下购买用户数占比=70美元以下购买用户数/18个月内购买用户总数
                </p>
                <p>
                  70-100美元之间购买用户数占比=70-100美元之间购买用户数/18个月内购买用户总数
                </p>
                <p>
                  100-150美元以上购买用户数占比=100-150美元以上购买用户数/18个月内购买用户总数
                </p>
                <p>
                  150美元以上购买用户数占比=150美元以上购买用户数/18个月内购买用户总数
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          复购率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=18个月内购买2次及以上人数/18个月内总购买用户数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          复购周期占比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  0-8个月的复购周期占比=0-8个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  8-10个月的复购周期占比=8-10个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  10-18个月的复购周期占比=10-18个月内购买2次及以上用户数/18个月内总购买用户数
                </p>
                <p>
                  例如：从复购周期来看，一般手机类目，复购顾客应该在8个月以内产生复购，如果这种顾客占总购买用户的比例低，说明需要加强运营顾客生命周期价值管理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          连带率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>客件数=成交商品件数除以成交人数</p>
                <p>例如：</p>
                <p>
                  从消费件数来看，近xx%的用户选择只购买1件商品，连带率较低；
                  另外，平均客件数可以看出连带率，如果接近1，说明存在消费者尝新的心智，但不产生其他产品的连带。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          差评率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=差评数/总评价数量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row3 */}
      <tr>
        <th>最近0-8个月内购买1次及以上用户占比</th>
        <th>最近8-10个月内购买1次及以上用户占比</th>
        <th>最近10-18个内购买1次及以上用户占比</th>
        <th>购买1次用户占比</th>
        <th>购买2次用户占比</th>
        <th>购买3次及以上用户占比</th>
        <th>70美元以下购买用户数占比</th>
        <th>70-100美元之间购买用户数占比</th>
        <th>100-150美元之间购买用户数占比</th>
        <th>150美元以上购买用户数占比</th>
        <th>18个月内购买2次及以上人数/18个月内总购买用户数</th>
        <th>0-8个月的复购周期占比</th>
        <th>8-10个月的复购周期占比</th>
        <th>10-18个月的复购周期占比</th>
        <th>成交商品件数除以成交人数</th>
        <th>差评数/总评价数量_x000D_</th>

        <th>最近0-8个月内购买1次及以上用户占比</th>
        <th>最近8-10个月内购买1次及以上用户占比</th>
        <th>最近10-18个内购买1次及以上用户占比</th>
        <th>购买1次用户占比</th>
        <th>购买2次用户占比</th>
        <th>购买3次及以上用户占比</th>
        <th>70美元以下购买用户数占比</th>
        <th>70-100美元之间购买用户数占比</th>
        <th>100-150美元之间购买用户数占比</th>
        <th>150美元以上购买用户数占比</th>
        <th>18个月内购买2次及以上人数/18个月内总购买用户数</th>
        <th>0-8个月的复购周期占比</th>
        <th>8-10个月的复购周期占比</th>
        <th>10-18个月的复购周期占比</th>
        <th>成交商品件数除以成交人数</th>
        <th>差评数/总评价数量_x000D_</th>

        <th>最近0-3个月内购买1次及以上用户占比</th>
        <th>最近3-6个月内购买1次及以上用户占比</th>
        <th>最近6-12个内购买1次及以上用户占比</th>
        <th>购买1次用户占比</th>
        <th>购买2次用户占比</th>
        <th>购买3次及以上用户占比</th>
        <th>70美元以下购买用户数占比</th>
        <th>70-100美元之间购买用户数占比</th>
        <th>100-150美元之间购买用户数占比</th>
        <th>150美元以上购买用户数占比</th>
        <th>12个月内购买2次及以上人数/12个月内总购买用户数</th>
        <th>0-3个月的复购周期占比</th>
        <th>3-6个月的复购周期占比</th>
        <th>6-12个月的复购周期占比</th>
        <th>成交商品件数除以成交人数</th>
        <th>差评数/总评价数量_x000D_</th>

        <th>最近0-1个月内购买1次及以上用户占比</th>
        <th>最近1-2个月内购买1次及以上用户占比</th>
        <th>最近2-12个内购买1次及以上用户占比</th>
        <th>购买1次用户占比</th>
        <th>购买2次用户占比</th>
        <th>购买3次及以上用户占比</th>
        <th>70美元以下购买用户数占比</th>
        <th>70-100美元之间购买用户数占比</th>
        <th>100-150美元之间购买用户数占比</th>
        <th>150美元以上购买用户数占比</th>
        <th>12个月内购买2次及以上人数/12个月内总购买用户数</th>
        <th>0-1个月的复购周期占比</th>
        <th>1-2个月的复购周期占比</th>
        <th>2-12个月的复购周期占比</th>
        <th>成交商品件数除以成交人数</th>
        <th>差评数/总评价数量_x000D_</th>

        <th>最近0-1个月内购买1次及以上用户占比</th>
        <th>最近1-2个月内购买1次及以上用户占比</th>
        <th>最近2-12个内购买1次及以上用户占比</th>
        <th>购买1次用户占比</th>
        <th>购买2次用户占比</th>
        <th>购买3次及以上用户占比</th>
        <th>70美元以下购买用户数占比</th>
        <th>70-100美元之间购买用户数占比</th>
        <th>100-150美元之间购买用户数占比</th>
        <th>150美元以上购买用户数占比</th>
        <th>12个月内购买2次及以上人数/12个月内总购买用户数</th>
        <th>0-1个月的复购周期占比</th>
        <th>1-2个月的复购周期占比</th>
        <th>2-12个月的复购周期占比</th>
        <th>成交商品件数除以成交人数</th>
        <th>差评数/总评价数量_x000D_</th>
      </tr>

      {/* row4 */}
      <tr>
        <td>{tableData.last_purchase_time_1}</td>
        <td>{tableData.last_purchase_time_2}</td>
        <td>{tableData.last_purchase_time_3}</td>
        <td>{tableData.consumption_frequency_1}</td>
        <td>{tableData.consumption_frequency_2}</td>
        <td>{tableData.consumption_frequency_3}</td>
        <td>{tableData.purchase_amount_1}</td>
        <td>{tableData.purchase_amount_2}</td>
        <td>{tableData.purchase_amount_3}</td>
        <td>{tableData.purchase_amount_4}</td>
        <td>{tableData.after_buying_rate}</td>
        <td>{tableData.ratio_of_repurchase_cycle_1}</td>
        <td>{tableData.ratio_of_repurchase_cycle_2}</td>
        <td>{tableData.ratio_of_repurchase_cycle_3}</td>
        <td>{tableData.joint_rate}</td>
        <td>{tableData.bad_review_rate}</td>

        <td>{tableData.phone_last_purchase_time_1}</td>
        <td>{tableData.phone_last_purchase_time_2}</td>
        <td>{tableData.phone_last_purchase_time_3}</td>
        <td>{tableData.phone_consumption_frequency_1}</td>
        <td>{tableData.phone_consumption_frequency_2}</td>
        <td>{tableData.phone_consumption_frequency_3}</td>
        <td>{tableData.phone_purchase_amount_1}</td>
        <td>{tableData.phone_purchase_amount_2}</td>
        <td>{tableData.phone_purchase_amount_3}</td>
        <td>{tableData.phone_purchase_amount_4}</td>
        <td>{tableData.phone_after_buying_rate}</td>
        <td>{tableData.phone_ratio_of_repurchase_cycle_1}</td>
        <td>{tableData.phone_ratio_of_repurchase_cycle_2}</td>
        <td>{tableData.phone_ratio_of_repurchase_cycle_3}</td>
        <td>{tableData.phone_joint_rate}</td>
        <td>{tableData.phone_bad_review_rate}</td>

        <td>{tableData.digital_peripherals_last_purchase_time_1}</td>
        <td>{tableData.digital_peripherals_last_purchase_time_2}</td>
        <td>{tableData.digital_peripherals_last_purchase_time_3}</td>
        <td>{tableData.digital_peripherals_consumption_frequency_1}</td>
        <td>{tableData.digital_peripherals_consumption_frequency_2}</td>
        <td>{tableData.digital_peripherals_consumption_frequency_3}</td>
        <td>{tableData.digital_peripherals_purchase_amount_1}</td>
        <td>{tableData.digital_peripherals_purchase_amount_2}</td>
        <td>{tableData.digital_peripherals_purchase_amount_3}</td>
        <td>{tableData.digital_peripherals_purchase_amount_4}</td>
        <td>{tableData.digital_peripherals_after_buying_rate}</td>
        <td>{tableData.digital_peripherals_ratio_of_repurchase_cycle_1}</td>
        <td>{tableData.digital_peripherals_ratio_of_repurchase_cycle_2}</td>
        <td>{tableData.digital_peripherals_ratio_of_repurchase_cycle_3}</td>
        <td>{tableData.digital_peripherals_joint_rate}</td>
        <td>{tableData.digital_peripherals_bad_review_rate}</td>

        <td>{tableData.makeups_last_purchase_time_1}</td>
        <td>{tableData.makeups_last_purchase_time_2}</td>
        <td>{tableData.makeups_last_purchase_time_3}</td>
        <td>{tableData.makeups_consumption_frequency_1}</td>
        <td>{tableData.makeups_consumption_frequency_2}</td>
        <td>{tableData.makeups_consumption_frequency_3}</td>
        <td>{tableData.makeups_purchase_amount_1}</td>
        <td>{tableData.makeups_purchase_amount_2}</td>
        <td>{tableData.makeups_purchase_amount_3}</td>
        <td>{tableData.makeups_purchase_amount_4}</td>
        <td>{tableData.makeups_after_buying_rate}</td>
        <td>{tableData.makeups_ratio_of_repurchase_cycle_1}</td>
        <td>{tableData.makeups_ratio_of_repurchase_cycle_2}</td>
        <td>{tableData.makeups_ratio_of_repurchase_cycle_3}</td>
        <td>{tableData.makeups_joint_rate}</td>
        <td>{tableData.makeups_bad_review_rate}</td>

        <td>{tableData.big_fashion_last_purchase_time_1}</td>
        <td>{tableData.big_fashion_last_purchase_time_2}</td>
        <td>{tableData.big_fashion_last_purchase_time_3}</td>
        <td>{tableData.big_fashion_consumption_frequency_1}</td>
        <td>{tableData.big_fashion_consumption_frequency_2}</td>
        <td>{tableData.big_fashion_consumption_frequency_3}</td>
        <td>{tableData.big_fashion_purchase_amount_1}</td>
        <td>{tableData.big_fashion_purchase_amount_2}</td>
        <td>{tableData.big_fashion_purchase_amount_3}</td>
        <td>{tableData.big_fashion_purchase_amount_4}</td>
        <td>{tableData.big_fashion_after_buying_rate}</td>
        <td>{tableData.big_fashion_ratio_of_repurchase_cycle_1}</td>
        <td>{tableData.big_fashion_ratio_of_repurchase_cycle_2}</td>
        <td>{tableData.big_fashion_ratio_of_repurchase_cycle_3}</td>
        <td>{tableData.big_fashion_joint_rate}</td>
        <td>{tableData.big_fashion_bad_review_rate}</td>
      </tr>
    </>
  )
}

ConversionRateRepurchase.propTypes = {
  tableData: shape({}).isRequired
}

export default ConversionRateRepurchase
