import React from 'react'

export const createColumns = () => [
  {
    title: '变动',
    key: 'points',
    dataIndex: 'points',
    align: 'center',
    render: (points) => {
      return points > 0 ? (
        <font color="green">{`+${parseInt(points, 10)}`}</font>
      ) : (
        <font color="red">{parseInt(points, 10)}</font>
      )
    }
  },
  {
    title: '当前积分',
    dataIndex: 'total',
    key: 'item_total',
    align: 'center',
    render: (total) => (total > 0 ? parseInt(total, 10) : '-')
  },
  {
    title: '描述',
    dataIndex: 'comment',
    key: 'description',
    align: 'center',
    render: (history) => {
      try {
        const data = JSON.parse(history)
        if (!data) {
          return history
        }
        return (
          <table>
            {Object.keys(data).map((i) => {
              return (
                <tr style={{ borderBottom: '1px solid #ccc' }}>
                  <td style={{ fontWeight: 900, textAlign: 'right' }}>{i}</td>
                  <td style={{ paddingLeft: '20px' }}>{data[i]}</td>
                </tr>
              )
            })}
          </table>
        )
      } catch (e) {
        return history
      }
    }
  },
  {
    title: '创建时间',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
