import React, { useContext, useMemo } from 'react'
import {
  EyeOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'
import { useLocation } from 'react-router-dom'

import { PermissionButton, GridContext } from '../../../../components'
import { deletePost, savePost } from '../services'

interface Props {
  record: Object;
}

const Actions = ({ record }: Props) => {
  const location = useLocation()

  const filterCountry = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('___country')
  }, [location])

  const { toggle, onLoad } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit')
  }

  const onDelete = () => {
    deletePost(onLoad)({ id: record.id, country: filterCountry })
  }

  const onApprove = () => {
    savePost(onLoad)({
      id: record.id,
      country: filterCountry,
      params: {
        data: {
          type: 'discussions',
          id: record.id,
          attributes: { manual_review: 1 }
        }
      }
    })
  }

  const onRefuse = () => {
    savePost(onLoad)({
      id: record.id,
      country: filterCountry,
      params: {
        data: {
          type: 'discussions',
          id: record.id,
          attributes: { manual_review: 2 }
        }
      }
    })
  }

  return (
    <>
      <Tooltip title="edit">
        <PermissionButton
          size="small"
          onClick={onEdit}
          icon={<EyeOutlined />}
          permission="bbs/discussion/update"
          type="primary"
        />
      </Tooltip>
      <Popconfirm title="Are you sure?" onConfirm={onApprove}>
        <Tooltip title="approve">
          <PermissionButton
            style={{ marginLeft: '5px' }}
            size="small"
            icon={<CheckOutlined />}
            permission="bbs/discussion/update"
            type="primary"
          />
        </Tooltip>
      </Popconfirm>
      <Popconfirm title="Are you sure?" onConfirm={onRefuse}>
        <Tooltip title="refuse">
          <PermissionButton
            style={{ marginLeft: '5px' }}
            size="small"
            icon={<CloseOutlined />}
            permission="bbs/discussion/update"
            type="primary"
          />
        </Tooltip>
      </Popconfirm>
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <Tooltip title="delete">
          <PermissionButton
            style={{ marginLeft: '5px' }}
            icon={<DeleteOutlined />}
            size="small"
            permission="bbs/discussion/delete"
            type="primary"
            danger
          />
        </Tooltip>
      </Popconfirm>
    </>
  )
}
export default Actions
