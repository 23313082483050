import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Form, Row, Col, Input, DatePicker } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import services from '../../../../services'

import {
  GridFormDrawer,
  CountrySelect,
  GridContext,
  TranslatableForm,
  SearchSelect,
  SourceSelect
} from '../../../../components'

import {
  getActivityDetail,
  createActivity,
  updateActivity
} from '../../services'

const dateFormat = 'YYYY-MM-DD hh:mm:ss'
interface Props {
  currentCountry: String;
}

const ActivityForm = ({ currentCountry }: Props) => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const { onLoad, toggle } = useContext(GridContext)
  const [country, setCountry] = useState('')
  const [activityType, setActivityType] = useState('fee')
  const emptyForm = () => {
    setFormData({})
    form.resetFields()
  }

  const closeDrawer = () => {
    toggle({}, '')
    emptyForm()
    onLoad()
  }

  const countryChange = (value) => {
    setCountry(value)
  }

  const formatResData = useCallback((value) => {
    const newValue = Object.assign(value, {
      country: value.country,
      title: value.title,
      total: value.total,
      activity_type: value.package_id > 0 ? 'package' : 'fee',
      start_time: moment(value.start_time, dateFormat),
      end_time: moment(value.end_time, dateFormat),
      amount: value.amount,
      short_description: value.short_description,
      description: value.description,
      url_key: value.url_key,
      package_id: {
        value: value.package_id,
        label:
          `[${value.country}] ` +
          `${value.type} --- ${value.operator_code} : ${value.amount}`
      }
    })
    setCountry(newValue.country)
    setActivityType(newValue.activity_type)
    setFormData(newValue)
  }, [])

  const openHandler = (id) => {
    getActivityDetail(formatResData)(id)
  }

  const onFinish = (values) => {
    const formatValue = {
      country: values.country,
      title: values.title,
      amount: values.amount,
      total: values.total,
      start_time: values.start_time.format(dateFormat),
      end_time: values.end_time.format(dateFormat),
      short_description: values.short_description,
      description: values.description,
      url_key: values.url_key,
      package_id: values.package_id
    }
    if (formData.id) {
      updateActivity(closeDrawer)({ data: formatValue, id: formData.id })
    } else {
      createActivity(closeDrawer)({ country, data: formatValue })
    }
  }

  useEffect(() => {
    if (Object.keys(formData).length) {
      form.setFieldsValue(formData)
    }
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={(record) => (record.id ? 'Edit Activity' : 'New Activity')}
    >
      <TranslatableForm entity="topup_activity">
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="country" name="country">
                <CountrySelect onChange={countryChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="activity_type"
                label="Type"
                rules={[{ required: true, message: 'Please select Type' }]}
              >
                <SourceSelect
                  onChange={(e) => {
                    setActivityType(e)
                  }}
                  value={activityType}
                  source="topup_activity_types"
                />
              </Form.Item>
            </Col>
          </Row>
          {activityType === 'package' && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="package_id"
                  label="Package"
                  rules={[{ required: true, message: 'Please select package' }]}
                >
                  <SearchSelect
                    country={currentCountry}
                    showSearch
                    fetchApi={services.topup.getPackages}
                    idField="package_id"
                    labelRender={(item) => {
                      return `[${item.country}] ${item.biz_type} --- ${item.operator_code}: ${item.good_desc}`
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {activityType === 'fee' && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="amount"
                  label="Amount"
                  rules={[
                    { required: true, message: 'Please type the amount' }
                  ]}
                >
                  <Input
                    style={{ width: '100%' }}
                    placeholder="Please enter the amount"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please enter title' }]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder="Please enter title"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="total"
                label="quantity"
                rules={[
                  { required: true, message: 'Please type the quantity' }
                ]}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder="Please enter the quantity"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="start_time"
                label="Start time"
                rules={[{ required: true, message: 'Please enter start time' }]}
              >
                <DatePicker showTime />
              </Form.Item>
              <Form.Item
                name="end_time"
                label="End time"
                rules={[{ required: true, message: 'Please enter end time' }]}
              >
                <DatePicker showTime />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="short_description" label="Short Description">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="description" label="Description">
                <Input.TextArea row={4} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="url_key" label="Url_key">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </TranslatableForm>
    </GridFormDrawer>
  )
}
const mapStateToProps = ({ global }) => ({
  currentCountry: global.current_country
})
export default connect(mapStateToProps)(ActivityForm)
