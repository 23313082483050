import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { getStaticText } from '../../utils'

const totalReportSiteColumns = [
  {
    title: (
      <div>
        {getStaticText('total.report.table.country')}
        &nbsp;
        <Tooltip
          title={getStaticText('total.report.table.country')}
          key={getStaticText('total.report.table.country')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('total.report.table.month')}
        &nbsp;
        <Tooltip
          title={getStaticText('total.report.table.month')}
          key={getStaticText('total.report.table.month')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'month_key',
    key: 'month_key',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: getStaticText('total.report.table.uv'),
    key: 'uv',
    children: [
      {
        title: getStaticText('total.report.table.budget'),
        dataIndex: 'uv_budget',
        key: 'uv_budget',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.actual'),
        dataIndex: 'uv_actual',
        key: 'uv_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.completion_rate'),
        dataIndex: 'uv_completion_rate',
        key: 'uv_completion_rate',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.last_actual'),
        dataIndex: 'uv_last_actual',
        key: 'uv_last_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.rate_year_on_year'),
        dataIndex: 'uv_rate_year_on_year',
        key: 'uv_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('total.report.table.app_uv'),
    key: 'appuv',
    children: [
      {
        title: getStaticText('total.report.table.actual'),
        dataIndex: 'app_uv',
        key: 'app_uv',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.last_actual'),
        dataIndex: 'app_uv_last_actual',
        key: 'app_uv_last_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.rate_year_on_year'),
        dataIndex: 'app_uv_rate_year_on_year',
        key: 'app_uv_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('total.report.table.all_uv'),
    key: 'all_uv',
    children: [
      {
        title: getStaticText('total.report.table.actual'),
        dataIndex: 'all_uv',
        key: 'all_uv',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.last_actual'),
        dataIndex: 'all_uv_last_actual',
        key: 'all_uv_last_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.rate_year_on_year'),
        dataIndex: 'all_uv_rate_year_on_year',
        key: 'all_uv_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('total.report.table.gmv'),
    key: 'gmv',
    children: [
      {
        title: getStaticText('total.report.table.budget'),
        dataIndex: 'gmv_budget',
        key: 'gmv_budget',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.actual'),
        dataIndex: 'gmv_actual',
        key: 'gmv_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.completion_rate'),
        dataIndex: 'gmv_completion_rate',
        key: 'gmv_completion_rate',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.last_actual'),
        dataIndex: 'gmv_last_actual',
        key: 'gmv_last_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.rate_year_on_year'),
        dataIndex: 'gmv_rate_year_on_year',
        key: 'gmv_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('total.report.table.income'),
    key: 'income',
    children: [
      {
        title: getStaticText('total.report.table.budget'),
        dataIndex: 'income_budget',
        key: 'income_budget',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.actual'),
        dataIndex: 'income_actual',
        key: 'income_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.completion_rate'),
        dataIndex: 'income_completion_rate',
        key: 'income_completion_rate',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.last_actual'),
        dataIndex: 'income_last_actual',
        key: 'income_last_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.rate_year_on_year'),
        dataIndex: 'income_rate_year_on_year',
        key: 'income_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('total.report.table.cost'),
    key: 'cost',
    children: [
      {
        title: getStaticText('total.report.table.budget'),
        dataIndex: 'cost_budget',
        key: 'cost_budget',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.actual'),
        dataIndex: 'cost_actual',
        key: 'cost_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.completion_rate'),
        dataIndex: 'cost_completion_rate',
        key: 'cost_completion_rate',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.last_actual'),
        dataIndex: 'cost_last_actual',
        key: 'cost_last_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.rate_year_on_year'),
        dataIndex: 'cost_rate_year_on_year',
        key: 'cost_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('total.report.table.profit'),
    key: 'profit',
    children: [
      {
        title: getStaticText('total.report.table.budget'),
        dataIndex: 'profit_budget',
        key: 'profit_budget',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.actual'),
        dataIndex: 'profit_actual',
        key: 'profit_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.completion_rate'),
        dataIndex: 'profit_completion_rate',
        key: 'profit_completion_rate',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.last_actual'),
        dataIndex: 'profit_last_actual',
        key: 'profit_last_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.rate_year_on_year'),
        dataIndex: 'profit_rate_year_on_year',
        key: 'profit_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('total.report.table.outlay'),
    key: 'outlay',
    children: [
      {
        title: getStaticText('total.report.table.budget'),
        dataIndex: 'outlay_budget',
        key: 'outlay_budget',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.actual'),
        dataIndex: 'outlay_actual',
        key: 'outlay_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.completion_rate'),
        dataIndex: 'outlay_completion_rate',
        key: 'outlay_completion_rate',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.last_actual'),
        dataIndex: 'outlay_last_actual',
        key: 'outlay_last_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.rate_year_on_year'),
        dataIndex: 'outlay_rate_year_on_year',
        key: 'outlay_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('total.report.table.market_outlay'),
    key: 'market_outlay',
    children: [
      {
        title: getStaticText('total.report.table.budget'),
        dataIndex: 'market_outlay_budget',
        key: 'market_outlay_budget',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.actual'),
        dataIndex: 'market_outlay_actual',
        key: 'market_outlay_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.completion_rate'),
        dataIndex: 'market_outlay_completion_rate',
        key: 'market_outlay_completion_rate',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.last_actual'),
        dataIndex: 'market_outlay_last_actual',
        key: 'market_outlay_last_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.rate_year_on_year'),
        dataIndex: 'market_outlay_rate_year_on_year',
        key: 'market_outlay_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('total.report.table.net_profit'),
    key: 'net_profit',
    children: [
      {
        title: getStaticText('total.report.table.budget'),
        dataIndex: 'net_profit_budget',
        key: 'net_profit_budget',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.actual'),
        dataIndex: 'net_profit_actual',
        key: 'net_profit_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.completion_rate'),
        dataIndex: 'net_profit_completion_rate',
        key: 'net_profit_completion_rate',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.last_actual'),
        dataIndex: 'net_profit_last_actual',
        key: 'net_profit_last_actual',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: getStaticText('total.report.table.rate_year_on_year'),
        dataIndex: 'net_profit_rate_year_on_year',
        key: 'net_profit_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  }
]

export default totalReportSiteColumns
