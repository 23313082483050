import React from 'react'

import { getStaticText } from '../../utils'

const dashboardGmvColumns = [
  {
    title: <div>{getStaticText('dashboard.table.goods_name')}</div>,
    key: 'product_name',
    width: 200,
    render: (data) => (
      <>
        <div>
          <img
            width="50"
            src={data.small_image}
            alt=""
            onError={(e: any) => {
              e.target.src =
                'https://ng.xpark.com/media/catalog/product/placeholder/default/product_1.png'
            }}
          />
        </div>
        <div style={{ fontSize: '10px', lineHeight: '100%' }}>
          {data.product_name}
        </div>
      </>
    )
  },
  {
    title: <div>{getStaticText('dashboard.table.gmv')}</div>,
    dataIndex: 'gmv',
    width: 100,
    key: 'gmv'
  }
]

export default dashboardGmvColumns
