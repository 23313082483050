import React from 'react'
import { shape, string } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function CycleTime(props) {
  const { tableData, month, country } = props
  return (
    <>
      {/* 管理仪表盘+结果报表 */}
      <tbody>
        {/* row1 */}
        <tr>
          <th className="table-left head-category sticky-left" colSpan="116">
            <span className="sticky-left sticky-title">
              管理仪表盘+结果报表
            </span>
          </th>
        </tr>

        {/* row2 */}
        <tr className="table-th-color">
          <th rowSpan="3" colSpan="2" width="200" className="sticky-left">
            {country}
            --
            {month}
          </th>
          <th colSpan="7">TA画像</th>
          <th colSpan="2">
            人群总览&nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    Fertility=A人群+I人群+P人群+L人群人一旦定义为L，就非AIP；
                    <br />
                    一旦定义为P，就非AI；一旦定义为I，就非A。
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="3">H5 UV</th>
          <th colSpan="3">APP UV</th>
          <th colSpan="3">全网 UV</th>
          <th colSpan="3">GMV</th>
          <th colSpan="3">传音以外品牌GMV</th>
          <th colSpan="3">Infinix以外品牌GMV</th>
          <th colSpan="3">收入 &nbsp;</th>
          <th colSpan="3">
            成本 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>需要在每一个跨境产品中添加成本，用于计算</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="3">毛利</th>
          <th colSpan="3">
            总费用 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    预算费用导入到每一天，然后统计周期每天预算累计
                    <br />
                    实际费用每个月填写，然后拆分到每一天
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="3">
            市场费用 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>=市场费用+平台活动补贴费用</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="3">
            净利润 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>收入-成本-总费用</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="3">
            直链ROAS &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    总直链GMV: 从UTM 模块过来的流量生成的订单(Complete)总计金额
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="3">
            投放费用率 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>总投放费用/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
        </tr>

        {/* row3 */}
        <tr>
          {/* TA画像 */}
          <th width="300">
            地域 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {/* 地域/客单价,前三
                    <br />
                    职业属性，前10
                    <br />
                    Xpark站内搜索关键词，前10
                    <br />
                    促销敏感度：全价低，7-9折，7折以下占比 */}
                    国家-地区-用户数-GMV
                    <br />
                    来源：GA拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th width="300">
            职业 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  {/* <p>GA-&gt;受众群体-&gt;兴趣-&gt;有具体新区细分者 前10数据</p> */}
                  <p>
                    国家-兴趣-用户数-GMV
                    <br />
                    来源：GA拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th width="300">
            XPARK站内搜索关键词 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  {/* <p>XPARK站内搜索关键词排名前10</p> */}
                  <p>
                    国家-关键词-点击次数-收入-均价
                    <br />
                    来源：GA拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th width="300">
            促销敏感度 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    促销敏感度：全价，7-9折，7折以下占比
                    <br />
                    来源：数据抓取
                    <br />
                    不同促销段得订单数占总订单数得比例
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成交顾客偏好 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  {/* <p>GA Interinsting Top 10 成交数据 兴趣相似类别</p> */}
                  <p>
                    国家-偏好-用户数-GMV
                    <br />
                    来源：ga拉取
                    <br />
                    Top 10 成交数据 兴趣相似类别
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成交顾客购买力和消费力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    来源：ga拉取
                    <br />
                    Top 10，以金额排序，显示兴趣，用户数，GMV
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成交顾客跨类目购买偏好 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    手机为基准。假设百分百包含手机订单(也可以包含其他类目)GMV￥100万。手机GMV
                    90W，蓝牙GMV8W，手环：2W
                    百分比：假设百分百包含手机订单(也可以包含其他类目)用户100
                    。手机 100，蓝牙6，手环：4
                    <br />
                    手机是以及类目，其他产品是耳机类目
                    <br />
                    来源：数据抓取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 人群总览（user） */}
          <th>
            AIPL人群总量（Fertility）&nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    A: GA 中所有用户
                    <br />
                    I: 感兴趣用户：访问页面数10个 或 用户浏览时长超过30S 或 下单
                    <br />
                    P: 下单用户 Xpark来源 ，画像从GA中来，规模从Xpark来
                    <br />
                    L: 注册&下单用户 + 买两次以上用户
                    Xpark来源，画像从GA中来，规模从Xpark来
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            人群总体加深率（Advancing）&nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    6月月计算
                    <br />
                    期初6-1，期末6-30
                    <br />
                    期初：A:98300，5月月活：5-1，5-31 所有用户
                    <br />
                    期末：A-&gt;IPL 9830 人 6-1，期末6-30
                    这个期间9830（其中去了I 8830，P 500,L 500）
                    <br />
                    所以：A-&gt;IPL 10%
                    <br />
                    期初：I:8000，5月：5-1，5-31 所有I用户
                    <br />
                    期末：I-&gt;PL 500 人 6-1，期末6-30 这个期间PL 500
                    <br />
                    所以：I-&gt;PL 6%
                    <br />
                    此时期末I:8000-500+8830
                    +期间拉新I-期间流失I=16300+X(可能正负)，新的I直接从GA中获取
                    <br />
                    I-&gt;L 类似
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* UV */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：ga抓去</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据导入</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：UV/去年UV-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：周报填入</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据导入</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：UV/去年UV-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据计算</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据导入</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：UV/去年UV-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* GMV */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据导入</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：GMV/去年GMV-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 传音以外品牌GMV */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>无数据来源</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：传音以外品牌GMV/去年同期传音以外品牌GMV-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* Infinix以外品牌GMV */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>无数据来源</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：Infinix以外品牌GMV/去年同期Infinix以外品牌GMV-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 收入 */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据导入</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：收入/去年同期收入-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 成本 */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据导入</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：成本/去年同期成本-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 毛利 */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：实际收入-实际成本</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：预算收入-预算成本</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：毛利/去年同期毛利-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 总费用 */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>无来源</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：预算GMV*0.25</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：总费用/去年同期总费用-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 市场费用 */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：广告-Google,实际消耗金额+广告-FB,实际消耗金额</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：跨境预算GMV*0.05+(预算GMV-跨境预算GMV)*0.1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：市场总费用/去年同期市场总费用-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 净利润 */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：实际收入-实际成本-(实际总费用-实际市场费用)</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：预算收入-预算成本-(预算总费用-预算市场费用)</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：净利润/去年净利润-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 直链ROAS */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>未计算</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>未计算</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>直链ROAS GMV/去年同期直链ROAS GMV-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 投放费用率 */}
          <th>
            实际
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：实际总费用/实际GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            预算
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：预算总费用/预算GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            同比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：投放费用率/去年同期投放费用率-1</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
        </tr>

        {/* row4 */}
        <tr>
          <td>
            <pre>{tableData.transactive_users_profile}</pre>
          </td>
          <td>
            <pre>{tableData.transactive_users_professional}</pre>
          </td>
          <td>
            <pre>{tableData.transactive_users_search_keywords}</pre>
          </td>
          <td>
            <pre>{tableData.transactive_users_promotion_sensitivity}</pre>
          </td>
          <td>
            <pre>{tableData.transactive_users_preferance}</pre>
          </td>
          <td>
            <pre>{tableData.transactive_users_consumption}</pre>
          </td>
          <td>
            <pre>{tableData.transactive_users_catalog_preferance}</pre>
          </td>
          <td>
            <pre>{tableData.people_overall_fertility}</pre>
          </td>
          <td>
            <pre>{tableData.people_overall_advancing}</pre>
          </td>
          <td>{tableData.uv_actual}</td>
          <td>{tableData.uv_budget}</td>
          <td>{tableData.uv_rate_year_on_year}</td>
          <td>{tableData.app_uv_actual}</td>
          <td>{tableData.app_uv_budget}</td>
          <td>{tableData.app_uv_rate_year_on_year}</td>
          <td>{tableData.all_uv_actual}</td>
          <td>{tableData.all_uv_budget}</td>
          <td>{tableData.all_uv_rate_year_on_year}</td>
          <td>{tableData.gmv_actual}</td>
          <td>{tableData.gmv_budget}</td>
          <td>{tableData.gmv_rate_year_on_year}</td>
          <td>{tableData.nontranssion_gmv_actual}</td>
          <td>{tableData.nontranssion_gmv_budget}</td>
          <td>{tableData.nontranssion_gmv_rate_year_on_year}</td>
          <td>{tableData.noninfinix_gmv_actual}</td>
          <td>{tableData.noninfinix_gmv_budget}</td>
          <td>{tableData.noninfinix_gmv_rate_year_on_year}</td>
          <td>{tableData.income_actual}</td>
          <td>{tableData.income_budget}</td>
          <td>{tableData.income_rate_year_on_year}</td>
          <td>{tableData.cost_actual}</td>
          <td>{tableData.cost_budget}</td>
          <td>{tableData.cost_rate_year_on_year}</td>
          <td>{tableData.profit_actual}</td>
          <td>{tableData.profit_budget}</td>
          <td>{tableData.profit_rate_year_on_year}</td>
          <td>{tableData.outlay_actual}</td>
          <td>{tableData.outlay_budget}</td>
          <td>{tableData.outlay_rate_year_on_year}</td>
          <td>{tableData.market_outlay_actual}</td>
          <td>{tableData.market_outlay_budget}</td>
          <td>{tableData.market_outlay_rate_year_on_year}</td>
          <td>{tableData.net_profit_actual}</td>
          <td>{tableData.net_profit_budget}</td>
          <td>{tableData.net_profit_rate_year_on_year}</td>
          <td>{tableData.roas_gmv_actual}</td>
          <td>{tableData.roas_gmv_budget}</td>
          <td>{tableData.roas_gmv_rate_year_on_year}</td>
          <td>{tableData.backflow_rate_actual}</td>
          <td>{tableData.backflow_rate_budget}</td>
          <td>{tableData.backflow_rate_rate_year_on_year}</td>
        </tr>
      </tbody>
    </>
  )
}

CycleTime.propTypes = {
  tableData: shape({}).isRequired,
  month: string.isRequired,
  country: string.isRequired
}

export default CycleTime
