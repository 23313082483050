import React from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'

const { Option } = Select
interface Props {
  scopes: Array;
}

const ScopeSelect = ({ scopes, ...props }: Props) => {
  return (
    <Select {...props} mode="multiple">
      {scopes.map((scope) => (
        <Option key={scope.key}>{scope.name}</Option>
      ))}
    </Select>
  )
}

const mapStateToProps = ({ global }) => ({
  scopes: global.allScopes
})

export default connect(mapStateToProps)(ScopeSelect)
