import React from 'react'

import { getStaticText } from '../../utils'

const goodsDashboardTopGmvColumns = [
  {
    title: <div>{getStaticText('dashboard.goods.product_name')}</div>,
    width: 300,
    dataIndex: 'product_name',
    key: 'product_name'
  },
  {
    title: <div>{getStaticText('dashboard.goods.gmv')}</div>,
    dataIndex: 'gmv',
    key: 'gmv'
  },
  {
    title: <div>{getStaticText('dashboard.goods.sales_volumes')}</div>,
    dataIndex: 'sales_volumes',
    key: 'sales_volumes'
  },
  {
    title: <div>{getStaticText('dashboard.goods.orders')}</div>,
    dataIndex: 'orders',
    key: 'orders'
  },
  {
    title: <div>{getStaticText('dashboard.goods.orders_uv')}</div>,
    dataIndex: 'orders_uv',
    key: 'orders_uv'
  },
  {
    title: <div>{getStaticText('dashboard.goods.confirmed_uv')}</div>,
    dataIndex: 'confirmed_uv',
    key: 'confirmed_uv'
  },
  {
    title: <div>{getStaticText('dashboard.goods.gmv_prop')}</div>,
    dataIndex: 'gmv_prop',
    key: 'gmv_prop'
  }
]

export default goodsDashboardTopGmvColumns
