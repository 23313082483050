import React, { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, Row, Col, Typography, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { getStaticText } from '../../../../utils'
import { columns, dataSourceMap, initGridData } from './ColumnsConfig'
import { getPostList } from '../services'
import {
  GridContextProvider,
  CreateButton,
  PagingTable
} from '../../../../components'
import Filters from './Filters'
import BbsForm from '../Edit/ui'
import ReviewList from '../Review/ui'

const { Text } = Typography

export default () => {
  const [gridData, setGridData] = useState(initGridData)
  const location = useLocation()

  const onLoad = useCallback(() => {
    getPostList(setGridData)(
      new URLSearchParams(location.search || '___country=NG')
    )
  }, [location, setGridData])

  return (
    <GridContextProvider onLoad={onLoad}>
      <Filters />
      <Card>
        <Row>
          <Col span={24}>
            <Text>{getStaticText('component.table.header.title')}</Text>
            <Space style={{ cssFloat: 'right' }}>
              <CreateButton
                icon={<PlusOutlined />}
                type="primary"
                permission="bbs/discussion/store"
              />
            </Space>
          </Col>
        </Row>
      </Card>
      <PagingTable
        columns={columns}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.page}
        pageSize={gridData.page_size}
      />
      <BbsForm />
      <ReviewList onLoad={onLoad} />
    </GridContextProvider>
  )
}
