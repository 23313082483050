/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { Row, Col, Radio } from 'antd'
import LabelInput from './LabelInput'

interface Props {
  style: Object;
  title: String;
  placeHolder: String;
  size: String;
  colon: Boolean;
  space: String;
  options: Array;
  span: Number;
  callback: Function;
}
const RadioWithTitle = ({
  title,
  space,
  colon,
  widthTitle,
  options,
  span,
  callback
}: Props) => {
  const [setting, setSetting] = useState()
  useEffect(() => {
    if (callback) callback(setting)
  }, [setting]);
  return (
    <Row>
      <Col span={span || 2}>
        {LabelInput({ title, space, colon, widthTitle })}
      </Col>
      <Radio.Group onChange={(e) => setSetting(e.target.value)}>
        {options?.map((item) => {
          return <Radio value={item?.value}>{item?.label}</Radio>
        })}
      </Radio.Group>
    </Row>
  )
}

export default RadioWithTitle
