import React from 'react'

import { getStaticText } from '../../utils'

const goodsDashboardAddColumns = [
  {
    title: <div>{getStaticText('dashboard.goods.product_name')}</div>,
    width: 300,
    dataIndex: 'product_name',
    key: 'product_name'
  },
  {
    title: <div>{getStaticText('dashboard.goods.add_uv')}</div>,
    dataIndex: 'add_uv',
    key: 'add_uv',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.add_volume')}</div>,
    dataIndex: 'add_volume',
    key: 'add_volume',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.add_rate')}</div>,
    dataIndex: 'add_rate',
    key: 'add_rate'
  },
  {
    title: <div>{getStaticText('dashboard.goods.collection_uv')}</div>,
    dataIndex: 'add_to_wishlist_uv',
    key: 'add_to_wishlist_uv',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.collection_times')}</div>,
    dataIndex: 'add_to_wishlist',
    key: 'add_to_wishlist',
    sorter: true
  }
]

export default goodsDashboardAddColumns
