import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import { Table } from 'antd'
import { func } from 'prop-types'
import services from '../../../services'
import dashboardBadReviewGoodsColumns from '../../../config/tableColumns/dashboardBadReviewGoodsColumns'
import dashboardCanceledGoodsColumns from '../../../config/tableColumns/dashboardCanceledGoodsColumns'
import AddPercent from '../Tool/AddPercent'
import MyTooltip from '../Tool/MyTooltip'

const requestOptionPreass = {
  legend: {
    data: []
  },
  grid: {
    show: false,
    left: '10%',
    right: '10%'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      animation: 'false'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  series: []
}

class LeftAfterSaleFrame extends Component {
  constructor(props) {
    super(props)
    const { onRef } = props
    onRef(this.useEffect)
    this.state = {
      list: [],
      option_list: {},
      bad_review_goods: [],
      canceled_goods: [],
      isLoading: false
    }
  }

  useEffect = (params) => {
    this.fetchData(params)
  }

  fetchData = (params) => {
    services.dashboard.getAfterSale(params).then((result) => {
      // eslint-disable-next-line camelcase
      const option_list = {}
      result.map_data_list.forEach((item) => {
        option_list[item.key] = {
          ...requestOptionPreass
        }
        option_list[item.key].xAxis[0].data = item.catalog
        option_list[item.key].series = item.series
        option_list[item.key].yAxis = item.yAxis
      })
      this.setState({
        list: result.list,
        option_list,
        bad_review_goods: result.bad_review_goods,
        canceled_goods: result.canceled_goods
      })
    })
  }

  getGoodsBox = () => {
    if (this.state.list.length > 1) {
      return this.state.list.map((item) => {
        return (
          <div className="after-sale-box" key={item.key}>
            <div className="flow-box-top">
              <div className="flow-box-title">
                {item.name}
                <MyTooltip account={item.account} />
              </div>
              <div className="flow-box-value">{item.today}</div>
              <div className="flow-box-span">
                <span>较前一日</span>
                <span className="flow-box-value">
                  <AddPercent percentString={item.yesterday} />
                </span>
              </div>
            </div>
            <ReactEcharts
              style={{ width: '90%', height: '250px' }}
              option={this.state.option_list[item.key]}
              key={item.key}
            />
          </div>
        )
      })
    }
    return ''
  }

  render() {
    return (
      <>
        <div className="left-frame">
          <div className="title">
            <div className="title-name">
              售后服务分析
              <MyTooltip account="时间段内数据" />
            </div>
          </div>
          <div className="width-max">
            <div className="width-max">{this.getGoodsBox()}</div>
            <div className="width-max">
              <div className="after-sale-table">
                <Table
                  title={() => '差评商品名单'}
                  size="small"
                  bordered
                  loading={this.state.isLoading}
                  columns={dashboardBadReviewGoodsColumns}
                  dataSource={this.state.bad_review_goods}
                  rowKey={(record) => record.entity_id}
                  scroll={{ x: 'max-content', y: true }}
                  sticky
                  pagination={false}
                />
              </div>
              <div className="after-sale-table">
                <Table
                  title={() => '取消订单商品列表'}
                  size="small"
                  bordered
                  loading={this.state.isLoading}
                  columns={dashboardCanceledGoodsColumns}
                  dataSource={this.state.canceled_goods}
                  rowKey={(record) => record.entity_id}
                  scroll={{ x: 'max-content', y: true }}
                  sticky
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

LeftAfterSaleFrame.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onRef: func
}

export default LeftAfterSaleFrame
