import React, { useEffect, useState, useCallback, useMemo } from 'react'
import {
  Select,
  Card,
  Table,
  Button,
  Typography,
  Space,
  DatePicker,
  Modal,
  Row,
  Col,
  Divider,
  Empty
} from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'
import { cloneDeep } from 'lodash'

import { dailyTableColumns } from '../../config/tableColumns'
import services from '../../services'
import { getStaticText, storage, checkPerms } from '../../utils'
import { TableHeader, EditDrawer } from '../../components'
import dateFormat from '../../config/dateFormat'

const dateFormatDay = dateFormat.day
const [exportType, perm] = ['daily', 'daily-sales/edit']
const { Text } = Typography
const { RangePicker } = DatePicker
const { Option } = Select

const DailySales = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  const platforms = useMemo(() => {
    return storage.allowedPlatforms.val || {}
  }, [])

  // 默认请求参数
  const defaultReqData = {
    country: 'All_Country',
    platform: 'All_Platform',
    start_date: moment().subtract(1, 'days'),
    end_date: moment(),
    current: 1,
    pageSize: 20
  }

  const [isLoad, setIsLoad] = useState(false)
  const [tableData, setTableData] = useState([])
  const [total, setTotal] = useState(0)
  const columns = useMemo(() => [...dailyTableColumns], [])
  const [isLoading, setIsLoading] = useState(false)
  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  // edit drawerProps
  const [drawerProps, setDrawerProps] = useState({
    visible: false,
    placement: 'right',
    width: '80%'
  })

  const handleToggleDrawer = useCallback(() => {
    setDrawerProps((prevState) => ({
      ...prevState,
      visible: !prevState.visible
    }))
  }, [])

  const [editData, setEditData] = useState({})

  // 设置当前行为编辑的数据
  const setDataColumns = useCallback(
    (data) => {
      setEditData(data)
      handleToggleDrawer()
    },
    [handleToggleDrawer]
  )

  // 是否显示编辑按钮
  if (
    checkPerms(perm) &&
    columns[columns.length - 1].key !== 'table.operating'
  ) {
    columns.push({
      title: getStaticText('table.operating'),
      key: 'table.operating',
      render: (row) => {
        return (
          <Button type="primary" onClick={() => setDataColumns(row)}>
            {getStaticText('table.operating.button.text')}
          </Button>
        )
      },
      fixed: 'right',
      width: 100,
      align: 'center'
    })
  }

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const nextSearchObj = () => {
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatDay)
      : null
    const endDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('end_date'), dateFormatDay)
      : null
    return {
      country: nextSearch.get('country'),
      platform: nextSearch.get('platform'),
      start_date: startDate,
      end_date: endDate,
      current: nextSearch.get('current'),
      pageSize: nextSearch.get('pageSize')
    }
  }
  Object.keys(nextSearchObj()).forEach((item) => {
    if (nextSearchObj()[item]) defaultReqData[item] = nextSearchObj()[item]
  })

  const [requestParams, setRequestParams] = useState(() => {
    return {
      country: defaultReqData.country
        .split(',')
        .filter((item) => !(item === '')),
      platform: defaultReqData.platform
        .split(',')
        .filter((item) => !(item === '')),
      start_date: defaultReqData.start_date,
      start_format: defaultReqData.start_date.format(dateFormatDay),
      end_date: defaultReqData.end_date,
      end_format: defaultReqData.end_date.format(dateFormatDay),
      current: defaultReqData.current,
      pageSize: defaultReqData.pageSize
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      country: requestParams.country.toString(),
      platform: requestParams.platform.toString(),
      start_date: requestParams.start_format,
      end_date: requestParams.end_format,
      current: requestParams.current * 1,
      pageSize: requestParams.pageSize * 1
    }
  }, [requestParams])

  // 判断所有平台
  const isAllPlatform = useCallback(
    (platform) => {
      if (requestParams.platform.includes('All_Platform')) {
        platform.forEach((platform) => {
          platform.disabled = true
        })

        platform[0].disabled = false
      } else if (requestParams.platform.length === 0) {
        platform.forEach((platform) => {
          platform.disabled = false
        })
      } else platform[0].disabled = true
    },
    [requestParams]
  )

  // 过滤出可供选择的平台
  const filterPlatform = useMemo(() => {
    if (
      !requestParams.country.length ||
      requestParams.country.toString() === 'All_Country'
    ) {
      const obj = {}
      const platform = Object.values(platforms)
        .flat()
        .reduce((pre, item) => {
          if (!obj[item.value]) {
            obj[item.value] = true
            pre.push(item)
          }
          return pre
        }, [])
      isAllPlatform(platform)
      return platform
    }
    let filterArray = []
    requestParams.country.forEach((item, index) => {
      filterArray[index] = platforms[item] || []
    })
    filterArray = filterArray.reduce((a, b) =>
      a.filter((c) => {
        let isEqual = false
        b.forEach((d) => {
          if (d.value === c.value) isEqual = true
        })
        return isEqual
      })
    )
    isAllPlatform(filterArray)
    return filterArray
  }, [requestParams, platforms, isAllPlatform])

  // countries 判断 disabled 属性
  const countries = useMemo(() => {
    const country = storage.allowedCountries.val || []
    if (requestParams.country.includes('All_Country')) {
      country.forEach((country) => {
        country.disabled = true
      })
      country[0].disabled = false
    } else if (requestParams.country.length === 0) {
      country.forEach((country) => {
        country.disabled = false
      })
    } else {
      country[0].disabled = true
    }
    return country
  }, [requestParams])

  const fetchData = useCallback(() => {
    toggleLoading()
    services.dailySales
      .getDailySales(requestParamsTransformer)
      .then((result) => {
        const data = result.daily_sales || []
        setTableData(data)
        setTotal(result.total)
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country,
      platform: ['All_Platform'],
      current: 1
    }))
  }

  const handlePlatformChange = (platform) => {
    setRequestParams((prevState) => ({
      ...prevState,
      platform,
      current: 1
    }))
  }

  const handleDateChange = (date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      start_date: date[0],
      start_format: dateString[0],
      end_date: date[1],
      end_format: dateString[1],
      current: 1
    }))
  }

  const handleReset = () => {
    setRequestParams({
      country: ['All_Country'],
      platform: ['All_Platform'],
      start_date: moment().subtract(1, 'days'),
      start_format: moment().subtract(1, 'days').format(dateFormatDay),
      end_date: moment(),
      end_format: moment().format(dateFormatDay),
      current: 1,
      pageSize: 20
    })
  }

  const handleCurrentChange = (current, pageSize) => {
    setRequestParams((prevState) => ({
      ...prevState,
      current,
      pageSize
    }))
    // eslint-disable-next-line no-use-before-define
    filter()
  }

  // 作为编辑form的字段头
  const cloneColumns = () => {
    if (columns[columns.length - 1].key === 'table.operating') {
      const nextReportColumns = cloneDeep(columns)
      nextReportColumns.pop()
      return nextReportColumns
    }
    return columns
  }

  const [editLoading, setEditLoading] = useState(false)

  // 编辑成功回调
  const handleFinish = (values) => {
    setEditLoading(true)

    services.dailySales
      .editDailySales({
        id: values.id,
        data: JSON.stringify(values.data)
      })
      .then(() => {
        fetchData()
      })
      .finally(() => {
        handleToggleDrawer()
        setEditLoading(false)
      })
  }

  useEffect(() => {
    if (!isLoad) {
      fetchData()
    }
    setIsLoad(true)
  }, [fetchData, isLoad])

  const filter = () => {
    setTotal(0)
    setUrlSearchForState()
    fetchData()
  }

  // 增加Review
  const [reviewsData, setReviewsData] = useState([])
  const [modalVisible, setModalDataVisible] = useState(false)
  const toggleModal = () => {
    setModalDataVisible((prevState) => {
      if (prevState === true) {
        setReviewsData([])
      }
      return !prevState
    })
  }

  const requestReviewParams = useCallback((row) => {
    return {
      country: row.country.value,
      type: 1, // type: 1 代表日报，type: 2 代表周报
      date: row.report_date.value,
      current: 1,
      pageSize: 20
    }
  }, [])

  const setModalData = useCallback(
    (row) => {
      const param = requestReviewParams(row)
      services.common.getBadReviewDetail(param).then((result) => {
        setReviewsData(result.data.data.review_details || [])
      })
      toggleModal()
    },
    [requestReviewParams]
  )

  const newColumns = useMemo(() => {
    const reviewColumn = columns.filter(
      (column) => column.key === 'bad_review_total'
    )

    if (reviewColumn && reviewColumn.length) {
      reviewColumn[0].render = (data, row) => {
        if (row.platform.value === 'XPARK主站')
          return (
            <span
              className="clickLabel"
              onClick={() => setModalData(row)}
              onKeyPress={() => {}}
              role="button"
              tabIndex="0"
            >
              {data.value}
            </span>
          )
        return data.value
      }
    }

    return columns
  }, [setModalData, columns])

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.platform.text')}
          </Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.platform}
            onChange={handlePlatformChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {filterPlatform.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.time.text')}
          </Text>
          <RangePicker
            picker="day"
            value={[requestParams.start_date, requestParams.end_date]}
            format={dateFormatDay}
            onChange={handleDateChange}
            allowClear={false}
          />
        </Space>
        <Space style={{ cssFloat: 'right' }}>
          <Button type="primary" onClick={handleReset}>
            {getStaticText('component.search.button.reset')}
          </Button>
        </Space>
        <Space style={{ cssFloat: 'right' }}>
          <Button type="primary" onClick={filter}>
            {getStaticText('component.search.button.filter')}
          </Button>
        </Space>
      </Card>
      <TableHeader
        exportType={exportType}
        startDate={requestParamsTransformer.start_date}
        endDate={requestParamsTransformer.end_date}
        country={requestParamsTransformer.country}
        fetchData={fetchData}
      />
      <Table
        size="small"
        bordered
        loading={isLoading}
        columns={newColumns}
        dataSource={tableData}
        rowKey={(record) => record.id.value}
        scroll={{ x: 'max-content', y: true }}
        sticky
        pagination={{
          size: 'small',
          showSizeChanger: true,
          showQuickJumper: true,
          total,
          showTotal: () => `共${total}条数据`,
          pageSize: requestParamsTransformer.pageSize,
          current: requestParamsTransformer.current,
          onChange: handleCurrentChange
        }}
      />

      <Modal
        title={getStaticText('bad.review.detail')}
        visible={modalVisible}
        footer={null}
        onCancel={toggleModal}
      >
        {reviewsData.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          reviewsData.map((review) => {
            return (
              <div key={review.review_id}>
                <Row>
                  <Col span={24}>
                    <Text>
                      {getStaticText('review.title')}
                      <span>：</span>
                      {review.title}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {getStaticText('review.nickname')}
                      <span>：</span>
                      {review.nickname}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {getStaticText('review.rating')}
                      <span>：</span>
                      {review.rating}
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text>
                      {getStaticText('review.detail')}
                      <span>：</span>
                      {review.detail}
                    </Text>
                  </Col>
                </Row>
                <Divider />
              </div>
            )
          })
        )}
      </Modal>

      <EditDrawer
        title={getStaticText('table.operating.button.text')}
        drawerProps={drawerProps}
        onClose={handleToggleDrawer}
        columns={cloneColumns()}
        dataSource={editData}
        colNum={4}
        handleFinish={handleFinish}
        loading={editLoading}
      />
    </>
  )
}

DailySales.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default DailySales
