/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { DatePicker, Input, Form } from 'antd'
import LabelInput from './LabelInput'
import moment from "moment/moment";

interface Props {
  title?: String;
  style?: Object;
  colon?: Boolean;
  space?: String;
  formRef: any;
  name1: String;
  name2: String;
}
const DoubleDateInput = ({ title, style, colon, space, name1, name2, formRef}: Props) => {
  const [dataDateState, setDataDateState] = useState([])
  useEffect(() => {
    console.log({ dataDateState })
    const dataFormFirstDate = dataDateState[0]
    const dataFormLastDate = dataDateState[1]

    if (dataFormFirstDate) {
      const getFirstDate = moment(dataFormFirstDate).format("YYYY-MM-DD");
      if (getFirstDate) {
        formRef.setFieldsValue({
          [name1]: getFirstDate
        })
      }
    }
    if (dataFormLastDate) {
      const getLastDate = moment(dataFormLastDate).format("YYYY-MM-DD");
      if (getLastDate) {
        formRef.setFieldsValue({
          [name2]: getLastDate
        })
      }
    }

  }, [dataDateState]);

  return (
    <Input.Group
      style={{
        display: 'flex',
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#E3E2E1',
        height: '100%'
      }}
    >
      <div
        style={{
          width: style?.width || '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          paddingLeft: '10px',
          height: '100%'
        }}
      >
        {LabelInput({ title, space, colon })}
      </div>
      <Form.Item name={"date-range"} style={{ width: '100%', marginBottom : 'unset'}}>
        <DatePicker.RangePicker
          onChange={(e) => setDataDateState(e)}
          bordered={false}
          style={{ width: '100%', height: '100%' }}
        />
      </Form.Item>
      <Form.Item name={name1} hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item name={name2} hidden={true}>
        <Input />
      </Form.Item>
    </Input.Group>
  )
}

export default DoubleDateInput
