import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function CM(props) {
  const { tableData } = props
  return (
    <>
      {/* CM */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="7" className="sticky-left sticky-second">
          CM
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：排除传音体系产品，排除PP商</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          总商家数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>是店中店数，是Xpark导入</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          核心商家数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：年GMV大于USD49600</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          总上架商品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="10">
          各类目商品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：分类目数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          爆品数量
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：月销量大于5000件</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          核心商品数量（保业绩）
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                {/* <p>来源：年GMV大于USD11712</p> */}
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          尖货数量（抢流量）
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                {/* <p>来源：产品点击量能/预算流量&gt;30% 的产品数</p> */}
                <p>来源：来源未知</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          利润款数量（做连带）
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：净利润大于20%的已经销售过的产品数量，且GMV产品上架的时候有产品的利润率。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          活跃比例
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：买过的产品数/总的商品数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          跨境商品数占比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：跨境商品数/总的商品数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          传音以外品牌商品数占比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：传音以外品牌商品数/总的商品数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          Infinix以外品牌商品数占比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：Infinix以外品牌商品数/总的商品数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          核心产品售罄率
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：核心售罄商品数/核心商品数量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row2 */}
      <tr>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>手机</th>
        <th>配件</th>
        <th>美妆</th>
        <th>服饰</th>
        <th>鞋包配饰</th>
        <th>家电</th>
        <th>家居</th>
        <th>运动健身</th>
        <th>玩具</th>
        <th>其它</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.total_stores_actual}</td>
        <td>{tableData.total_stores_budget}</td>
        <td>{tableData.total_stores_rate_year_on_year}</td>
        <td>{tableData.core_total_stores_actual}</td>
        <td>{tableData.core_total_stores_budget}</td>
        <td>{tableData.core_total_stores_rate_year_on_year}</td>
        <td>{tableData.product_total_actual}</td>
        <td>{tableData.product_total_budget}</td>
        <td>{tableData.product_total_rate_year_on_year}</td>
        <td>{tableData.phone_product_total_actual}</td>
        <td>{tableData.accessory_product_total_actual}</td>
        <td>{tableData.makeups_product_total_actual}</td>
        <td>{tableData.cloth_product_total_actual}</td>
        <td>{tableData.shoes_bag_product_total_actual}</td>
        <td>{tableData.appliances_product_total_actual}</td>
        <td>{tableData.furniture_product_total_actual}</td>
        <td>{tableData.fitness_product_total_actual}</td>
        <td>{tableData.toys_product_total_actual}</td>
        <td>{tableData.others_product_total_actual}</td>
        <td>{tableData.hot_products_actual}</td>
        <td>{tableData.hot_products_budget}</td>
        <td>{tableData.hot_products_rate_year_on_year}</td>
        <td>{tableData.core_products_total_actual}</td>
        <td>{tableData.core_products_total_budget}</td>
        <td>{tableData.core_products_total_rate_year_on_year}</td>
        <td>{tableData.point_products_total_actual}</td>
        <td>{tableData.point_products_total_budget}</td>
        <td>{tableData.point_products_total_rate_year_on_year}</td>
        <td>{tableData.hot_turned_product_actual}</td>
        <td>{tableData.hot_turned_product_budget}</td>
        <td>{tableData.hot_turned_product_rate_year_on_year}</td>
        <td>{tableData.product_active_rate_actual}</td>
        <td>{tableData.product_active_rate_budget}</td>
        <td>{tableData.product_active_rate_rate_year_on_year}</td>
        <td>{tableData.crossborder_product_rate_actual}</td>
        <td>{tableData.crossborder_product_rate_budget}</td>
        <td>{tableData.crossborder_product_rate_rate_year_on_year}</td>
        <td>{tableData.nontranssion_total_product_rate_actual}</td>
        <td>{tableData.nontranssion_total_product_rate_budget}</td>
        <td>{tableData.nontranssion_total_product_rate_rate_year_on_year}</td>
        <td>{tableData.noninfinix_total_product_rate_actual}</td>
        <td>{tableData.noninfinix_total_product_rate_budget}</td>
        <td>{tableData.noninfinix_total_product_rate_rate_year_on_year}</td>
        <td>{tableData.core_product_saleout_rate_actual}</td>
        <td>{tableData.core_product_saleout_rate_budget}</td>
        <td>{tableData.core_product_saleout_rate_rate_year_on_year}</td>
      </tr>

      {/* row4 */}
      <tr className="table-th-color-none">
        <th colSpan="20">
          各类目收入
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  实际：分类目进行数据拉取
                  <br />
                  预算：无数据来源
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="20">
          各类目GMV
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  <br />
                  实际：分类目进行数据拉取
                  <br />
                  预算：无数据来源
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row5 */}
      <tr>
        <th colSpan="2">手机</th>
        <th colSpan="2">配件</th>
        <th colSpan="2">美妆</th>
        <th colSpan="2">服饰</th>
        <th colSpan="2">鞋包配饰</th>
        <th colSpan="2">家电</th>
        <th colSpan="2">家居</th>
        <th colSpan="2">运动健身</th>
        <th colSpan="2">玩具</th>
        <th colSpan="2">其它</th>

        <th colSpan="2">手机</th>
        <th colSpan="2">配件</th>
        <th colSpan="2">美妆</th>
        <th colSpan="2">服饰</th>
        <th colSpan="2">鞋包配饰</th>
        <th colSpan="2">家电</th>
        <th colSpan="2">家居</th>
        <th colSpan="2">运动健身</th>
        <th colSpan="2">玩具</th>
        <th colSpan="2">其它</th>
      </tr>

      {/* row6 */}
      <tr>
        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>

        <th>实际</th>
        <th>预算</th>
      </tr>

      {/* row7 */}
      <tr>
        <td>{tableData.phone_income_actual}</td>
        <td>{tableData.phone_income_budget}</td>

        <td>{tableData.accessory_income_actual}</td>
        <td>{tableData.accessory_income_budget}</td>

        <td>{tableData.makeups_income_actual}</td>
        <td>{tableData.makeups_income_budget}</td>

        <td>{tableData.cloth_income_actual}</td>
        <td>{tableData.cloth_income_budget}</td>

        <td>{tableData.shoes_bag_income_actual}</td>
        <td>{tableData.shoes_bag_income_budget}</td>

        <td>{tableData.appliances_income_actual}</td>
        <td>{tableData.appliances_income_budget}</td>

        <td>{tableData.furniture_income_actual}</td>
        <td>{tableData.furniture_income_budget}</td>

        <td>{tableData.fitness_income_actual}</td>
        <td>{tableData.fitness_income_budget}</td>

        <td>{tableData.toys_income_actual}</td>
        <td>{tableData.toys_income_budget}</td>

        <td>{tableData.others_income_actual}</td>
        <td>{tableData.others_income_budget}</td>

        <td>{tableData.phone_gmv_actual}</td>
        <td>{tableData.phone_gmv_budget}</td>

        <td>{tableData.accessory_gmv_actual}</td>
        <td>{tableData.accessory_gmv_budget}</td>

        <td>{tableData.makeups_gmv_actual}</td>
        <td>{tableData.makeups_gmv_budget}</td>

        <td>{tableData.cloth_gmv_actual}</td>
        <td>{tableData.cloth_gmv_budget}</td>

        <td>{tableData.shoes_bag_gmv_actual}</td>
        <td>{tableData.shoes_bag_gmv_budget}</td>

        <td>{tableData.appliances_gmv_actual}</td>
        <td>{tableData.appliances_gmv_budget}</td>

        <td>{tableData.furniture_gmv_actual}</td>
        <td>{tableData.furniture_gmv_budget}</td>

        <td>{tableData.fitness_gmv_actual}</td>
        <td>{tableData.fitness_gmv_budget}</td>

        <td>{tableData.toys_gmv_actual}</td>
        <td>{tableData.toys_gmv_budget}</td>

        <td>{tableData.others_gmv_actual}</td>
        <td>{tableData.others_gmv_budget}</td>
      </tr>
    </>
  )
}

CM.propTypes = {
  tableData: shape({}).isRequired
}

export default CM
