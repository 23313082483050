/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { Button, Col, Image, Row, Upload } from 'antd'
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  PictureFilled,
  PlusOutlined,
  RetweetOutlined
} from '@ant-design/icons'
import '../../../css/card-product-image.css'
import imagePlaceholder from "../../../assets/empty.webp";

interface Props {
  funcParent: Function;
  action: String;
  headers: any;
  data: Array;
  setData: Function;
  image: Object;
  otherFunc: Function;
}
const CardProductImage = ({
  funcParent,
  action,
  headers,
  data,
  setData,
  image,
  otherFunc
}: Props) => {
  const [imageMetaData, setImageMetaData] = useState(null)
  const [visible, setVisible] = useState(false)
  const [forRefresh, setForRefresh] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (image?.image) {
      otherFunc(image?.image, (err, img) => {
        setImageMetaData(img)
        console.log(err)
      })
    }
  }, [image])

  const handleTo = async (way) => {
    setIsLoading(true)
    try {
      let dataArray = data
      let thisId = image?.id - 1
      let toId = image?.id
      if (way === 'left') {
        thisId = image?.id - 1
        toId = image?.id - 2
      }

      if (thisId >= 0 && thisId < dataArray.length && toId >= 0 && toId < dataArray.length) {
        [dataArray[thisId], dataArray[toId]] = [dataArray[toId], dataArray[thisId]];

        dataArray = await dataArray.map((item, index) => {
          if (item?.dummy) {
            return {
              id: index + 1,
              image: item.image,
              dummy: true
            }
          }
          return {
            id: index + 1,
            image: item?.image
          }
        })
        setData(dataArray)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDeleteImage = () => {
    if (image?.id) {
      const newData = data
        .filter((item) => Number(item.id) !== Number(image?.id))
        .map((_item, index) => {
          if (_item?.dummy) {
            return {
              id: index + 1,
              image: _item.image,
              dummy: true
            }
          }
          return {
            id: index + 1,
            image: _item.image
          }
        })
      setData(newData)
    }
  }
  const renderImage = () => {
    return (
      <Image
        style={{
          objectFit: 'contain',
          width: '100%',
          height: '100%',
          aspectRatio: '10 / 10',
          filter: 'blur(10px)'
        }}
        preview={false}
        src={image?.image|| imagePlaceholder}
      />
    )
  }
  return (
    <Row gutter={[0, 0]} className="card-product">
      <Col
        span={24}
        style={{
          objectFit: 'contain',
          width: '100%',
          height: '100%',
          aspectRatio: 10 / 10,
          border: '1px',
          borderStyle: 'solid',
          borderColor: '#DADADA'
        }}
      >
        {!image?.dummy ? (
          <Image
            loading={isLoading}
            placeholder={renderImage()}
            preview={{
              visible,
              src: image?.image,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
            src={`${image?.image}?${forRefresh}`}
            style={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
              aspectRatio: '10 / 10'
            }}
            fallback={imagePlaceholder}
          />
        ) : (
          <Upload
            action={action}
            headers={headers}
            onChange={funcParent}
            style={{
              width: '100%',
              height: '100%',
              aspectRatio: 10 / 10,
              cursor: 'pointer'
            }}
          >
            <Col
              span={24}
              style={{
                width: '100%',
                height: '100%',
                aspectRatio: 10 / 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <PlusOutlined style={{ fontSize: '40px' }} />
            </Col>
          </Upload>
        )}
        {imageMetaData && (
          <div
            style={{
              fontSize: '15px',
              padding: '5px',
              background: 'rgba(236, 236, 236, .5)',
              position: 'absolute',
              bottom: 0,
              left: 0,
              visibility: !image?.dummy ? 'visible' : 'hidden'
            }}
          >
            {`${imageMetaData.naturalWidth} X ${imageMetaData.naturalHeight}`}
          </div>
        )}
      </Col>
      <Col span={5}>
        {!image?.dummy ? (
          <Button
            loading={isLoading}
            onClick={() => handleTo('left')}
            disabled={image?.id === 1}
            icon={<ArrowLeftOutlined/>}
            style={{width: '100%', visibility: 'visible'}}
          />
        ) : (
          <Button style={{width: '100%', visibility: 'hidden'}}/>
        )}
      </Col>
      <Col span={5}>
        {!image?.dummy ? (
          <Button
            loading={isLoading}
            disabled={image?.id === data?.length - 1}
            onClick={() => handleTo('right')}
            icon={<ArrowRightOutlined />}
            style={{ width: '100%', visibility: 'visible' }}
          />
        ) : (
          <Button style={{ width: '100%', visibility: 'hidden' }} />
        )}
      </Col>
      <Col span={5}>
        {!image?.dummy ? (
          <Button
            loading={isLoading}
            onClick={() => setForRefresh(Date.now())}
            icon={<RetweetOutlined />}
            style={{ width: '100%', visibility: 'visible' }}
          />
        ) : (
          <Button style={{ width: '100%', visibility: 'hidden' }} />
        )}
      </Col>
      <Col span={5}>
        {!image?.dummy ? (
          <Button
            loading={isLoading}
            disabled={!image?.image}
            onClick={() => setVisible(true)}
            icon={<PictureFilled />}
            style={{ width: '100%', visibility: 'visible' }}
          />
        ) : (
          <Button style={{ width: '100%', visibility: 'hidden' }} />
        )}
      </Col>
      <Col span={4}>
        {!image?.dummy ? (
          <Button
            loading={isLoading}
            icon={<DeleteOutlined />}
            onClick={handleDeleteImage}
            style={{ width: '100%', visibility: 'visible' }}
          />
        ) : (
          <Button style={{ width: '100%', visibility: 'hidden' }} />
        )}
      </Col>
    </Row>
  )
}

export default CardProductImage
