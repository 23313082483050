import React, { useEffect, useState, useMemo, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Form, Row, Col, Input } from 'antd'

import {
  GridFormDrawer,
  CountrySelect,
  GridContext
} from '../../../../components'
import { createWords, saveWords } from '../services'

const WordsForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const location = useLocation()

  const filterCountry = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    return searchParams.get('___country')
  }, [location])

  const { onLoad, toggle } = useContext(GridContext)
  const emptyForm = () => {
    setFormData({})
    form.resetFields()
  }

  const closeDrawer = () => {
    emptyForm()
    toggle({}, '')
    onLoad()
  }

  const openHandler = (record) => {
    setFormData({
      id: record.id,
      country: filterCountry,
      words: record.words,
      memo: record.memo
    })
  }

  const onFinish = (values) => {
    const params = {
      words: values.words,
      memo: values.memo
    }

    // if (params.memo === '') delete params.memo

    if (formData.id) {
      saveWords(closeDrawer)({
        id: formData.id,
        country: values.country,
        params
      })
    } else {
      createWords(closeDrawer)({ country: values.country, params })
    }
  }

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={
        (record) => (record.id ? 'Edit Sensitive Words' : 'New Sensitive Words')
        // eslint-disable-next-line react/jsx-curly-newline
      }
    >
      <Form form={form} onFinish={onFinish} autoComplete="off">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: 'Please select country' }]}
            >
              <CountrySelect />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Sensitive Words"
              name="words"
              rules={[
                { required: true, message: 'Please input sensitive words' }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Remarks" name="memo">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </GridFormDrawer>
  )
}
export default WordsForm
