import React, { useState, useEffect, useCallback } from 'react'
import { Select } from 'antd'

import services from '../../../services'

const { Option } = Select

interface Props {
  mode: String;
  value: String;
  onChange: Function;
  country: String;
}

const TagsSelect = ({ mode, value, onChange, country }: Props) => {
  const [tagsList, setTagsList] = useState([])

  const fetchData = useCallback(() => {
    services.bbs.getTagsList({ ___country: country }).then((res) => {
      setTagsList(res.list || [])
    })
  }, [country])

  useEffect(() => {
    if (country) {
      fetchData()
    }
  }, [fetchData, country])

  return (
    <Select
      value={value}
      onChange={onChange}
      allowClear
      mode={mode}
      disabled={!country}
      maxTagCount="responsive"
    >
      {tagsList.map((item) => (
        <Option key={item.id}>{item.name}</Option>
      ))}
    </Select>
  )
}

export default TagsSelect
