import React, { ReactNode, useState } from 'react'
import PermissionButton from './PermissionButton'
import request from '../../services/request'

interface Props {
  permission: String;
  children: ReactNode;
}

const ExportButton = ({ permission, children, params, ...props }: Props) => {
  const [loading, setLoading] = useState(false)
  const exportAction = (params) =>
    request.get(`/backend/${permission}`, { params })
  const doExport = () => {
    setLoading(true)
    exportAction(params)
      .then(({ url }) => {
        setLoading(false)
        window.location.href = url
      })
      .catch(() => setLoading(false))
  }
  return (
    <PermissionButton
      onClick={doExport}
      permission={permission}
      loading={loading}
      {...props}
    >
      {children}
    </PermissionButton>
  )
}

export default ExportButton
