import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Select } from 'antd'
import { setCurrentCountry } from '../../../store/actions/global'
import { emptyForm } from '../../../store/actions/i18n'

const { Option } = Select

interface Props {
  allowedCountries: Object;
  mode: String;
  value: String;
  onChange: Function;
}

const CountrySelect = ({
  allCountries,
  allowedCountries,
  mode,
  value,
  onChange,
  setCurrentCountry,
  emptyForm
}: Props) => {
  useEffect(() => {
    if (!value) {
      setCurrentCountry('')
      emptyForm()
    }
  }, [value, setCurrentCountry, emptyForm])
  if (!allowedCountries) {
    return null
  }
  return (
    <Select
      value={value}
      onChange={(e) => {
        setCurrentCountry(e)
        onChange(e)
      }}
      mode={mode}
      maxTagCount="responsive"
    >
      {allCountries
        .filter((x) => allowedCountries.indexOf(x.countryCode) !== -1)
        .map((item) => (
          <Option key={item.countryCode}>{item.name}</Option>
        ))}
    </Select>
  )
}

const mapStateToProps = ({ users, global }) => {
  return {
    allowedCountries:
      users.userInfo && users.userInfo.user
        ? users.userInfo.user.allowed_countries
        : [],
    allCountries: global.config
  }
}

export default connect(mapStateToProps, { setCurrentCountry, emptyForm })(
  CountrySelect
)
