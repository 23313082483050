import React, { useContext, useState } from 'react'
import { Tabs } from 'antd'
import { connect } from 'react-redux'
import { GridFormDrawer, GridContext } from '../../../../components'
import CountryProduct from './CountryProduct'
import { getProductsForMembership } from '../services'
import SelectProduct from './SelectProduct'

const { TabPane } = Tabs

interface Props {
  allowedCountries: Array;
}

const MemberProductsPicker = ({ allCountries, allowedCountries }: Props) => {
  const { record } = useContext(GridContext)
  const [data, setData] = useState({})
  const [activeKey, setActiveKey] = useState(allowedCountries[0])

  const initGridData = {
    current_page: 1,
    data: [],
    page_size: 10,
    total: 0
  }

  const switchTab = (key) => {
    if (key) {
      setActiveKey(key)
    } else {
      key = activeKey
    }
    getProductsForMembership((ret) => {
      setData({ ...data, [key]: ret })
    })({ code: record.code ? record.code : '', country: key, type: record.pr })
  }

  const paginationHandler = (current, pageSize) => {
    getProductsForMembership((ret) => {
      setData({ ...data, [activeKey]: ret })
    })({
      country: activeKey,
      code: record.code,
      type: record.pr,
      page: current,
      page_size: pageSize
    })
  }

  return (
    <>
      <GridFormDrawer
        onOpen={() => {
          switchTab(activeKey)
        }}
        // onSave={() => form.submit()}
        type="product_pr_member_products|product_pr_member_new|product_pr_member_price|product_pr_birthday_gift|product_pr_voucher|product_pr_new_trail|product_pr_gift_card|product_pr_free_purchase|product_pr_orderly_deduction|product_pr_cash_coins|product_pr_coin_offer|product_pr_lucky_draw|product_pr_crowd_founding"
        title={`Products for ${record.label}`}
      >
        <Tabs activeKey={activeKey} type="card" onChange={switchTab}>
          {allCountries
            .filter((x) => allowedCountries.indexOf(x.countryCode) !== -1)
            .map((item) => (
              <TabPane tab={item.name} key={item.countryCode}>
                <CountryProduct
                  country={item.countryCode}
                  group={record.code}
                  gridData={data[item.countryCode] || initGridData}
                  paginationHandler={paginationHandler}
                  reload={switchTab}
                />
              </TabPane>
            ))}
        </Tabs>
      </GridFormDrawer>
      <SelectProduct reload={switchTab} />
    </>
  )
}

const mapStateToProps = ({ users, global }) => {
  return {
    allowedCountries:
      users.userInfo && users.userInfo.user
        ? users.userInfo.user.allowed_countries
        : [],
    allCountries: global.config
  }
}

export default connect(mapStateToProps)(MemberProductsPicker)
