import React from 'react'

import { getStaticText } from '../../utils'

const flowDashboardUsersColumns = [
  {
    title: <div>{getStaticText('dashboard.business.name')}</div>,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: <div>{getStaticText('dashboard.business.users')}</div>,
    dataIndex: 'users',
    key: 'users'
  },
  {
    title: <div>{getStaticText('dashboard.business.add_users')}</div>,
    dataIndex: 'add_users',
    key: 'add_users'
  }
]

export default flowDashboardUsersColumns
