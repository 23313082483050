import React from 'react'

import { getStaticText } from '../../utils'
import MyTooltip from '../../pages/Dashboard/Tool/MyTooltip'

const dashboardBadReviewGoodsColumns = [
  {
    title: <div>{getStaticText('dashboard.table.goods_name')}</div>,
    key: 'name',
    width: 200,
    render: (data) => (
      <>
        <div>
          <img
            width="50"
            src={data.small_image}
            alt=""
            onError={(e: any) => {
              e.target.src =
                'https://ng.xpark.com/media/catalog/product/placeholder/default/product_1.png'
            }}
          />
        </div>
        <div style={{ fontSize: '10px', lineHeight: '100%' }}>{data.name}</div>
      </>
    )
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.table.bad_review_total')}
        <MyTooltip account="差评:评分小于5" />
      </div>
    ),
    dataIndex: 'bad_review_total',
    key: 'bad_review_total',
    align: 'center'
  }
]

export default dashboardBadReviewGoodsColumns
