import request from './request'

export const getActivityList = (params) =>
  request.get('/backend/topup/activity/get-list', { params })

export const getActivityDetail = (params) =>
  request.get('/backend/topup/activity/detail', { params })

export const saveActivity = (params) => {
  return request.post('/backend/topup/activity/save', params)
}

export const createActivity = (params) =>
  request.post('/backend/topup/activity/create', params)

export const deleteActivity = (params) =>
  request.post('/backend/topup/activity/delete', params)

// order
export const getActivityOrderList = (params) =>
  request.get('/backend/topup/activity/order/get-list', { params })

export const getActivityOrderDetail = (params) =>
  request.get('/backend/topup/activity/order/detail', { params })

export const saveActivityOrder = (params) => {
  return request.post('/backend/topup/activity/order/save', params)
}

export const createActivityOrder = (params) =>
  request.post('/backend/topup/activity/order/create', params)

export const deleteActivityOrder = (params) =>
  request.post('/backend/topup/activity/order/delete', params)

export const makeApproval = (params) =>
  request.post('/backend/topup/activity/order/make-approval', params)

export const getOrderAuditList = (params) =>
  request.post('/backend/topup/activity/audit/get-list', params)

export const getAuditDetail = (params) =>
  request.get('/backend/topup/activity/audit/detail', { params })

export const getCaptcha = (params) =>
  request.get('/backend/topup/activity/audit/captha', { params })

export const topup = (params) =>
  request.post('/backend/topup/activity/audit/topup', params)

export const rejectToClose = (params) =>
  request.post('/backend/topup/activity/audit/reject-to-close', params)

export const approveToComplete = (params) =>
  request.post('/backend/topup/activity/audit/approve-to-complete', params)

export const getPackages = (params) =>
  request.get('/backend/topup/packages/get-list', { params })
