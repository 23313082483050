import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getLogList = (handler) =>
  withTableLoading((params) =>
    services.membership.getLogList(params).then(handler)
  )

export const getLogDetail = (handler) =>
  withGlobalLoading((id) =>
    services.membership.getLogDetail({ id }).then(handler)
  )
