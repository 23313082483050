import React, { Component } from 'react'
import { Button, Space } from 'antd'
import services from '../../services'
import { getStaticText } from '../../utils'

class Jump extends Component {
  fetchData = (country) => {
    services.jump.jumpXpark({ country }).then((result) => {
      window.localStorage.clear()
      window.location.href = result.tourl
    })
  }

  render() {
    return (
      <>
        <Space>
          <Button
            className="jump-div color1"
            onClick={() => this.fetchData('ng')}
          >
            {getStaticText('app.sider.merchant.ng')}
          </Button>
          <Button
            className="jump-div color2"
            onClick={() => this.fetchData('eg')}
          >
            {getStaticText('app.sider.merchant.eg')}
          </Button>
          <Button
            className="jump-div color3"
            onClick={() => this.fetchData('pk')}
          >
            {getStaticText('app.sider.merchant.pk')}
          </Button>
          <Button
            className="jump-div color4"
            onClick={() => this.fetchData('ma')}
          >
            {getStaticText('app.sider.merchant.ma')}
          </Button>
          <Button
            className="jump-div color5"
            onClick={() => this.fetchData('gh')}
          >
            {getStaticText('app.sider.merchant.gh')}
          </Button>
          <Button
            className="jump-div color6"
            onClick={() => this.fetchData('ke')}
          >
            {getStaticText('app.sider.merchant.ke')}
          </Button>
        </Space>
      </>
    )
  }
}

Jump.propTypes = {}

export default Jump
