import React, { useContext } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import { PermissionButton, GridContext } from '../../../../components'

interface Props {
  record: Object;
}
const Actions = ({ record }: Props) => {
  const { toggle } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'detail')
  }
  return (
    <>
      <Tooltip title="edit">
        <PermissionButton
          size="small"
          onClick={onEdit}
          icon={<EditOutlined />}
          permission="topup/activity/audit/detail"
          type="primary"
        />
      </Tooltip>
    </>
  )
}
export default Actions
