import { DailySales, WeeklySales, DailyDataFill } from '../pages'

const updateReport = {
  path: '/update-report',
  primary: 'app.sider.data.maintenance',
  routes: [
    {
      path: '/update-report/daily-sale',
      modular: 'report',
      permission: 'daily-sales/get-list',
      primary: 'app.sider.daily.sales',
      component: DailySales,
      exact: true
    },
    {
      path: '/update-report/weekly-sale',
      modular: 'report',
      permission: 'weekly-sales/get-list',
      primary: 'app.sider.weekly.sales',
      component: WeeklySales,
      exact: true
    },
    {
      path: '/update-report/daily-data-fill',
      modular: 'report',
      permission: 'daily-sales/miss-list',
      primary: 'app.sider.daily.data.fill',
      component: DailyDataFill,
      exact: true
    }
  ]
}
export default updateReport
