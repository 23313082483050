import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Card,
  DatePicker,
  Row,
  Col,
  Typography,
  Space,
  Table,
  Select,
  Button
} from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import { getStaticText, storage } from '../../utils'
import services from '../../services'
import dateFormat from '../../config/dateFormat'
import weeklyTableActiveColumns from '../../config/tableColumns/weeklyTableActiveColumns'
import { EditDrawer } from '../../components'

const { Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

// 周日期格式化标准
const dateFormatWeek = dateFormat.week

const WeeklyActiive = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  // 默认请求参数 默认当前周减12
  const defaultReqData = {
    country: 'All_Country',
    start_week: moment().subtract('week', 2),
    end_week: moment().subtract('week', 1)
  }

  const [isLoad, setIsLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const nextSearchObj = () => {
    return {
      country: nextSearch.get('country'),
      start_week: nextSearch.get('start_week')
        ? moment(nextSearch.get('start_week'), dateFormatWeek)
        : null,
      end_week: nextSearch.get('end_week')
        ? moment(nextSearch.get('end_week'), dateFormatWeek)
        : null
    }
  }
  Object.keys(nextSearchObj()).forEach((item) => {
    if (nextSearchObj()[item]) defaultReqData[item] = nextSearchObj()[item]
  })

  const [tableData, setTableData] = useState([])

  const [requestParams, setRequestParams] = useState(() => {
    return {
      country: defaultReqData.country
        .split(',')
        .filter((item) => !(item === '')),
      start_week: defaultReqData.start_week,
      end_week: defaultReqData.end_week,
      start_format: defaultReqData.start_week.format(dateFormatWeek),
      end_format: defaultReqData.end_week.format(dateFormatWeek)
    }
  })

  // 作为编辑form的字段头
  const cloneColumns = () => {
    const newColumns = []
    weeklyTableActiveColumns.forEach((row) => {
      if (row.children) {
        row.children.forEach((children) => {
          if (children.edit === true) {
            newColumns.push(children)
          }
        })
      } else if (row.edit === true) {
        newColumns.push(row)
      }
    })
    return newColumns
  }

  // edit drawerProps
  const [drawerProps, setDrawerProps] = useState({
    visible: false,
    placement: 'right',
    width: '80%'
  })

  const handleToggleDrawer = useCallback(() => {
    setDrawerProps((prevState) => ({
      ...prevState,
      visible: !prevState.visible
    }))
  }, [])

  const [editLoading, setEditLoading] = useState(false)

  const [editData, setEditData] = useState({})

  // 设置当前行为编辑的数据
  const setDataColumns = useCallback(
    (data) => {
      const constnewdata = {}
      Object.keys(data).forEach((value) => {
        constnewdata[value] = {
          value: data[value],
          visible: true,
          writable: true
        }
      })
      setEditData(constnewdata)
      handleToggleDrawer()
    },
    [handleToggleDrawer]
  )

  // 是否显示编辑按钮
  if (
    weeklyTableActiveColumns[weeklyTableActiveColumns.length - 1].key !==
    'table.operating'
  ) {
    weeklyTableActiveColumns.push({
      title: getStaticText('table.operating'),
      key: 'table.operating',
      render: (row) => {
        return (
          <Button type="primary" onClick={() => setDataColumns(row)}>
            {getStaticText('table.operating.button.text')}
          </Button>
        )
      },
      fixed: 'right',
      width: 100,
      align: 'center'
    })
  }

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      country: requestParams.country.toString(),
      start_week: requestParams.start_format,
      end_week: requestParams.end_format
    }
  }, [requestParams])

  // countries 判断 disabled 属性
  const countries = useMemo(() => {
    const countrys = storage.allowedCountries.val || []
    if (requestParams.country.length) {
      countrys.forEach((country) => {
        country.disabled = !requestParams.country.includes(country.value)
      })
    } else {
      countrys.forEach((country) => {
        country.disabled = false
      })
    }
    return countrys
  }, [requestParams])

  const fetchData = useCallback(() => {
    toggleLoading()

    services.weeklySales
      .getWeeklyActive(requestParamsTransformer)
      .then((result) => {
        setTableData(result)
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country
    }))
  }
  // 编辑成功回调
  const handleFinish = (values) => {
    setEditLoading(true)

    services.weeklySales
      .editWeeklySales({
        id: values.id,
        data: JSON.stringify(values.data)
      })
      .then(() => {
        fetchData()
      })
      .finally(() => {
        handleToggleDrawer()
        setEditLoading(false)
      })
  }

  useEffect(() => {
    if (!isLoad) {
      fetchData()
    }
    setIsLoad(true)
  }, [fetchData, isLoad])

  const handleChangeDate = useCallback((date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      start_week: date[0],
      end_week: date[1],
      start_format: dateString[0],
      end_format: dateString[1]
    }))
  }, [])

  const filter = () => {
    fetchData()
    setUrlSearchForState()
  }

  const [exportLoading, setExportLoading] = useState(false)

  const handleExport = () => {
    setExportLoading(true)

    services.weeklySales
      .exportWeeklyActive(requestParamsTransformer)
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
      .finally(() => {
        setExportLoading(false)
      })
  }

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.time.text')}
          </Text>
          <RangePicker
            picker="week"
            value={[requestParams.start_week, requestParams.end_week]}
            format={dateFormatWeek}
            allowClear={false}
            onChange={handleChangeDate}
          />
        </Space>
        <Space style={{ cssFloat: 'right' }}>
          <Button type="primary" onClick={filter}>
            {getStaticText('component.search.button.filter')}
          </Button>
        </Space>
      </Card>

      <Card>
        <Row>
          <Col span={12}>
            <Text>{getStaticText('component.table.header.title')}</Text>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button onClick={handleExport} loading={exportLoading}>
              {getStaticText('component.table.export.text')}
            </Button>
          </Col>
        </Row>
      </Card>

      <Table
        sticky
        bordered
        size="small"
        loading={isLoading}
        columns={weeklyTableActiveColumns}
        dataSource={tableData}
        rowKey={(record) => record.country + record.week_key}
        scroll={{ x: 'max-content' }}
        pagination={false}
      />

      <EditDrawer
        title={getStaticText('table.operating.button.text')}
        drawerProps={drawerProps}
        onClose={handleToggleDrawer}
        columns={cloneColumns()}
        dataSource={editData}
        colNum={4}
        handleFinish={handleFinish}
        loading={editLoading}
      />
    </>
  )
}

WeeklyActiive.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default WeeklyActiive
