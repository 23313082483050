import React from 'react'

import { getStaticText } from '../../utils'

const businessDashboardPvColumns = [
  {
    title: <div>{getStaticText('dashboard.business.name')}</div>,
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: <div>{getStaticText('dashboard.business.pv')}</div>,
    dataIndex: 'users',
    key: 'users'
  },
  {
    title: <div>{getStaticText('dashboard.business.add_pv')}</div>,
    dataIndex: 'add_users',
    key: 'add_users'
  },
  {
    title: <div>{getStaticText('dashboard.business.gmv')}</div>,
    dataIndex: 'gmv',
    key: 'gmv'
  },
  {
    title: <div>{getStaticText('dashboard.business.add_gmv')}</div>,
    dataIndex: 'add_gmv',
    key: 'add_gmv'
  }
]

export default businessDashboardPvColumns
