import React, { useContext } from 'react'
import { Row, Col, Card, Space, Popconfirm } from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  PagingTable,
  PermissionButton,
  GridContext
} from '../../../../../../../components'
import { deleteBanner } from '../../../services'
import BannerEditForm from '../Edit/ui'

interface Props {
  cat: Number;
  gridData: Object;
  reload: (id: Number) => {};
}

interface ActionProps {
  record: Object;
  reload: (id: Number) => {};
}

const Actions = ({ record, reload, cat }: ActionProps) => {
  const { toggle } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit_banner')
  }
  const onLoad = () => reload(cat)
  const onDelete = () => {
    deleteBanner(onLoad)(record.id)
  }
  return (
    <>
      <PermissionButton
        size="small"
        onClick={onEdit}
        icon={<EditOutlined />}
        permission="crowd-founding/banner/save"
        type="primary"
      >
        Edit
      </PermissionButton>
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <PermissionButton
          style={{ marginLeft: '5px' }}
          icon={<DeleteOutlined />}
          size="small"
          permission="crowd-founding/category/delete"
          type="primary"
        >
          Delete
        </PermissionButton>
      </Popconfirm>
    </>
  )
}

export const columns = (reload, cat) => [
  {
    title: '图片',
    dataIndex: 'img_url',
    key: 'img_url',
    align: 'center',
    render: (url) => <img src={url} alt="Banner" height="100" />
  },
  {
    title: '名称',
    dataIndex: 'title',
    key: 'title',
    align: 'center',
    width: 150
  },
  {
    title: '链接',
    dataIndex: 'url',
    key: 'url',
    width: 100,
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: '',
    width: 200,
    align: 'center',
    key: 'x',
    render: (record) => <Actions record={record} reload={reload} cat={cat} />
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}

const BannerGrid = ({ cat, gridData, reload }: Props) => {
  const { toggle } = useContext(GridContext)

  if (!cat) {
    return null
  }

  return (
    <>
      <Card>
        <Row>
          <Col span={24}>
            <Space style={{ float: 'right' }}>
              <PermissionButton
                onClick={() => toggle({}, 'create_banner')}
                permission="crowd-founding/banner/create"
                icon={<PlusOutlined />}
                type="primary"
              >
                Create
              </PermissionButton>
            </Space>
          </Col>
        </Row>
      </Card>
      <PagingTable
        columns={columns(reload, cat)}
        dataSource={dataSourceMap(gridData.data)}
        total={gridData.total}
        initGridData={initGridData}
        current={gridData.page}
        scroll={{ x: 'max-content', y: true }}
        pageSize={gridData.page_size}
      />
      <BannerEditForm cat={cat} reload={reload} />
    </>
  )
}

export default BannerGrid
