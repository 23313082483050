import React from 'react'
import { Button, Col, Row } from 'antd'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'
import LabelInput from '../inputComponent/LabelInput'

interface Props {
  clickFunction: Function;
  setMonthlySortStatus: Function;
  status: Number;
  loading: Boolean;
}
const SortExclusive = ({ clickFunction, title, status, loading }: Props) => {
  return (
    <Col span={5}>
      <Button
        loading={loading}
        type="text"
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={clickFunction}
      >
        <Row>
          <Col
            span={20}
            style={{
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <LabelInput title={title} />
          </Col>
          <Col span={4}>
            <Row style={{ marginTop: '1px' }}>
              <Col span={24}>
                <CaretUpOutlined
                  style={{ color: status === 1 ? '#1890FF' : '#BFBFBF' }}
                />
              </Col>
              <Col span={24} style={{ marginTop: '-12px' }}>
                <CaretDownOutlined
                  style={{ color: status === 2 ? '#1890FF' : '#BFBFBF' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Button>
    </Col>
  )
}

export default SortExclusive
