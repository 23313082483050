import React, { useEffect } from 'react'
import {
  shape,
  bool,
  string,
  func,
  arrayOf,
  number,
  oneOfType
} from 'prop-types'
import { Col, Drawer, Form, Input, Row, Button, Space, InputNumber } from 'antd'
import { cloneDeep } from 'lodash'
import { getStaticText } from '../../utils'

const FormItem = Form.Item

// 字段能调用的组件map
const fieldMap = {
  string: { component: Input, props: {} },
  number: {
    component: InputNumber,
    props: {
      style: { width: '100%' },
      formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      parser: (value) => value.replace(/(,*)/g, '')
    }
  }
}

const PromotionDrawer = (props) => {
  const {
    drawerProps,
    columns,
    dataSource,
    onClose,
    colNum,
    handleFinish,
    loading,
    title
  } = props

  const colSpan = 24 / colNum

  const [form] = Form.useForm()

  useEffect(() => {
    const cloneDataSource = cloneDeep(dataSource)

    // 取出数据做格式化处理
    const formValue = cloneDataSource.reduce((prev, next) => {
      prev[next.key] = next.value
      if (typeof next.value === 'string') {
        prev[next.key] = next.value.replace(/(,*)/g, '')
      }

      return prev
    }, {})

    // 设置form字段数据
    form.setFieldsValue(formValue)
  }, [form, dataSource])

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        handleFinish(values)
      })
      .catch((error) => {
        form.scrollToField(error.errorFields[0].name.toString())
      })
  }

  const handleClose = () => {
    if (!loading) onClose()
  }

  const footer = (
    <div style={{ textAlign: 'right' }}>
      <Space>
        <Button onClick={handleClose}>
          {getStaticText('component.edit.modal.cancel.text')}
        </Button>
        <Button type="primary" onClick={onFinish} loading={loading}>
          {getStaticText('component.edit.modal.ok.text')}
        </Button>
      </Space>
    </div>
  )

  /**
   * 根据字段类型获取组件
   *
   * @param {String} type
   * @param {Object} prop
   * @returns
   */
  const getComponentForType = (value, prop = {}) => {
    const type = typeof value
    const componentType = fieldMap[type] || { component: Input, props: {} }
    const Component = componentType.component
    return <Component {...componentType.props} {...prop} />
  }

  // render每个form字段
  const renderField = (column) => {
    return (
      <FormItem
        label={column.label}
        name={column.key}
        initialValue={column.value}
        rules={[
          {
            required: false
          }
        ]}
      >
        {column.value ? (
          getComponentForType(column.value, {
            disabled: column.key === 'id'
          })
        ) : (
          <Input />
        )}
      </FormItem>
    )
  }

  return (
    <div className="edit-drawer">
      <Drawer
        title={title}
        key={drawerProps.placement}
        {...drawerProps}
        onClose={handleClose}
        footer={footer}
        getContainer={false}
        destroyOnClose
      >
        <Form layout="vertical" form={form} name="edit">
          <div className="column-group">
            <Row gutter={20}>
              {columns.map((column) => {
                return (
                  <Col key={column.key} span={colSpan}>
                    {renderField(column)}
                  </Col>
                )
              })}
            </Row>
          </div>
        </Form>
      </Drawer>
    </div>
  )
}

PromotionDrawer.propTypes = {
  drawerProps: shape({
    width: oneOfType([string, number]),
    visible: bool,
    placement: string
  }).isRequired,
  columns: arrayOf(shape()).isRequired,
  dataSource: arrayOf(shape()).isRequired,
  onClose: func,
  colNum: number,
  handleFinish: func,
  loading: bool,
  title: string
}

PromotionDrawer.defaultProps = {
  onClose: () => {},
  colNum: 2,
  handleFinish: () => {},
  loading: false,
  title: getStaticText('table.operating.button.text')
}

export default PromotionDrawer
