import { message } from 'antd'

const interruptedPromises = {}

export const addPromisePair = (requestId, resolve, reject) => {
  interruptedPromises[requestId] = { resolve, reject }
}

export const rejectRequest = (requestId, e) => {
  if (interruptedPromises[requestId]) {
    interruptedPromises[requestId].reject(
      e || new Error('The request has been cancelled!')
    )
    if (!e) {
      message.error('The request has been cancelled!')
    }
  }
}

export const acceptRequest = (requestId, data) => {
  if (interruptedPromises[requestId]) {
    interruptedPromises[requestId].resolve(data)
  }
}
