import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function UnitPricePerCustomer(props) {
  const { tableData } = props
  return (
    <>
      {/* 客单价 */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="3" className="sticky-left sticky-second">
          客单价
        </th>
        <th colSpan="3">
          平台总体客单价分析
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>=总销售量/总GMV</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="6">
          类目价格带分析-手机
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>核心价格就是类目中人群经营的价格区间</p>
                <p>核心产品数是后台加一个字段标识产品是核心产品</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="6">
          类目价格带分析-配件
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  通过对总体客单价分析以及各类目下客单价分析，判断产品布局和产品定价是否合理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="6">
          类目价格带分析-美妆
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  通过对总体客单价分析以及各类目下客单价分析，判断产品布局和产品定价是否合理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="6">
          类目价格带分析-服饰
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  通过对总体客单价分析以及各类目下客单价分析，判断产品布局和产品定价是否合理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="6">
          类目价格带分析-鞋包配饰
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  通过对总体客单价分析以及各类目下客单价分析，判断产品布局和产品定价是否合理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="6">
          类目价格带分析-家电
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  通过对总体客单价分析以及各类目下客单价分析，判断产品布局和产品定价是否合理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="6">
          类目价格带分析-家居
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  通过对总体客单价分析以及各类目下客单价分析，判断产品布局和产品定价是否合理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="6">
          类目价格带分析-运动健身
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  通过对总体客单价分析以及各类目下客单价分析，判断产品布局和产品定价是否合理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="6">
          类目价格带分析-玩具
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  通过对总体客单价分析以及各类目下客单价分析，判断产品布局和产品定价是否合理。
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row2 */}
      <tr>
        <th>实际</th>
        <th>预算</th>
        <th>同比</th>
        <th>
          第1大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  以订单GMV排序，取60%的订单,从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在[0.6*实际GMV~0.9*实际GMV]区间中的价格的最值+该区间GMV-60%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          1价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第2大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，取30%的订单，从这些订单种获取该类目的最大值和最小值，如果订单区间中有2个区间端，计算每个区间的订单数，以订单数多的为准
                  <br />
                  产品的GMV在小于0.6*实际GMV区间中的价格的最值+该区间GMV-30%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          2价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第3大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，10%的订单，从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在大于0.9*实际GMV区间中的价格的最值+该区间GMV-10%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          3价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          第1大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  以订单GMV排序，取60%的订单,从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在[0.6*实际GMV~0.9*实际GMV]区间中的价格的最值+该区间GMV-60%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          1价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第2大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，取30%的订单，从这些订单种获取该类目的最大值和最小值，如果订单区间种有2个区间端，计算每个区间的订单数，以订单数多的为准
                  <br />
                  产品的GMV在小于0.6*实际GMV区间中的价格的最值+该区间GMV-30%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          2价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第3大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，10%的订单，从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在大于0.9*实际GMV区间中的价格的最值+该区间GMV-10%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          3价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          第1大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  以订单GMV排序，取60%的订单,从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在[0.6*实际GMV~0.9*实际GMV]区间中的价格的最值+该区间GMV-60%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          1价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第2大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，取30%的订单，从这些订单种获取该类目的最大值和最小值，如果订单区间种有2个区间端，计算每个区间的订单数，以订单数多的为准
                  <br />
                  产品的GMV在小于0.6*实际GMV区间中的价格的最值+该区间GMV-30%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          2价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第3大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，10%的订单，从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在大于0.9*实际GMV区间中的价格的最值+该区间GMV-10%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          3价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          第1大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  以订单GMV排序，取60%的订单,从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在[0.6*实际GMV~0.9*实际GMV]区间中的价格的最值+该区间GMV-60%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          1价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第2大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，取30%的订单，从这些订单种获取该类目的最大值和最小值，如果订单区间种有2个区间端，计算每个区间的订单数，以订单数多的为准
                  <br />
                  产品的GMV在小于0.6*实际GMV区间中的价格的最值+该区间GMV-30%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          2价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第3大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，10%的订单，从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在大于0.9*实际GMV区间中的价格的最值+该区间GMV-10%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          3价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          第1大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  以订单GMV排序，取60%的订单,从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在[0.6*实际GMV~0.9*实际GMV]区间中的价格的最值+该区间GMV-60%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          1价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第2大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，取30%的订单，从这些订单种获取该类目的最大值和最小值，如果订单区间种有2个区间端，计算每个区间的订单数，以订单数多的为准
                  <br />
                  产品的GMV在小于0.6*实际GMV区间中的价格的最值+该区间GMV-30%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          2价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第3大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，10%的订单，从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在大于0.9*实际GMV区间中的价格的最值+该区间GMV-10%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          3价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          第1大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  以订单GMV排序，取60%的订单,从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在[0.6*实际GMV~0.9*实际GMV]区间中的价格的最值+该区间GMV-60%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          1价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第2大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，取30%的订单，从这些订单种获取该类目的最大值和最小值，如果订单区间种有2个区间端，计算每个区间的订单数，以订单数多的为准
                  <br />
                  产品的GMV在小于0.6*实际GMV区间中的价格的最值+该区间GMV-30%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          2价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第3大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，10%的订单，从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在大于0.9*实际GMV区间中的价格的最值+该区间GMV-10%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          3价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          第1大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  以订单GMV排序，取60%的订单,从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在[0.6*实际GMV~0.9*实际GMV]区间中的价格的最值+该区间GMV-60%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          1价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第2大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，取30%的订单，从这些订单种获取该类目的最大值和最小值，如果订单区间种有2个区间端，计算每个区间的订单数，以订单数多的为准
                  <br />
                  产品的GMV在小于0.6*实际GMV区间中的价格的最值+该区间GMV-30%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          2价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第3大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，10%的订单，从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在大于0.9*实际GMV区间中的价格的最值+该区间GMV-10%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          3价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          第1大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  以订单GMV排序，取60%的订单,从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在[0.6*实际GMV~0.9*实际GMV]区间中的价格的最值+该区间GMV-60%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          1价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第2大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，取30%的订单，从这些订单种获取该类目的最大值和最小值，如果订单区间种有2个区间端，计算每个区间的订单数，以订单数多的为准
                  <br />
                  产品的GMV在小于0.6*实际GMV区间中的价格的最值+该区间GMV-30%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          2价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第3大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，10%的订单，从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在大于0.9*实际GMV区间中的价格的最值+该区间GMV-10%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          3价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          第1大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  以订单GMV排序，取60%的订单,从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在[0.6*实际GMV~0.9*实际GMV]区间中的价格的最值+该区间GMV-60%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          1价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第2大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，取30%的订单，从这些订单种获取该类目的最大值和最小值，如果订单区间种有2个区间端，计算每个区间的订单数，以订单数多的为准
                  <br />
                  产品的GMV在小于0.6*实际GMV区间中的价格的最值+该区间GMV-30%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          2价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          第3大价格带
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以订单GMV排序，10%的订单，从这些订单种获取该类目的最大值和最小值
                  <br />
                  产品的GMV在大于0.9*实际GMV区间中的价格的最值+该区间GMV-10%
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          3价格带对应核心产品数
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  以产品单价作为排序，升序排列，平均分成三类，最后导出三个价格带
                  <br />
                  该价格带的产品数
                  <br />
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.average_price_actual}</td>
        <td>{tableData.average_price_budget}</td>
        <td>{tableData.average_price_rate_year_on_year}</td>

        <td>{tableData.phone_price_range_1}</td>
        <td>{tableData.phone_price_range_products_1}</td>
        <td>{tableData.phone_price_range_2}</td>
        <td>{tableData.phone_price_range_products_2}</td>
        <td>{tableData.phone_price_range_3}</td>
        <td>{tableData.phone_price_range_products_3}</td>

        <td>{tableData.accessory_price_range_1}</td>
        <td>{tableData.accessory_price_range_products_1}</td>
        <td>{tableData.accessory_price_range_2}</td>
        <td>{tableData.accessory_price_range_products_2}</td>
        <td>{tableData.accessory_price_range_3}</td>
        <td>{tableData.accessory_price_range_products_3}</td>

        <td>{tableData.makeups_price_range_1}</td>
        <td>{tableData.makeups_price_range_products_1}</td>
        <td>{tableData.makeups_price_range_2}</td>
        <td>{tableData.makeups_price_range_products_2}</td>
        <td>{tableData.makeups_price_range_3}</td>
        <td>{tableData.makeups_price_range_products_3}</td>

        <td>{tableData.cloth_price_range_1}</td>
        <td>{tableData.cloth_price_range_products_1}</td>
        <td>{tableData.cloth_price_range_2}</td>
        <td>{tableData.cloth_price_range_products_2}</td>
        <td>{tableData.cloth_price_range_3}</td>
        <td>{tableData.cloth_price_range_products_3}</td>

        <td>{tableData.shoes_bag_price_range_1}</td>
        <td>{tableData.shoes_bag_price_range_products_1}</td>
        <td>{tableData.shoes_bag_price_range_2}</td>
        <td>{tableData.shoes_bag_price_range_products_2}</td>
        <td>{tableData.shoes_bag_price_range_3}</td>
        <td>{tableData.shoes_bag_price_range_products_3}</td>

        <td>{tableData.appliances_price_range_1}</td>
        <td>{tableData.appliances_price_range_products_1}</td>
        <td>{tableData.appliances_price_range_2}</td>
        <td>{tableData.appliances_price_range_products_2}</td>
        <td>{tableData.appliances_price_range_3}</td>
        <td>{tableData.appliances_price_range_products_3}</td>

        <td>{tableData.furniture_price_range_1}</td>
        <td>{tableData.furniture_price_range_products_1}</td>
        <td>{tableData.furniture_price_range_2}</td>
        <td>{tableData.furniture_price_range_products_2}</td>
        <td>{tableData.furniture_price_range_3}</td>
        <td>{tableData.furniture_price_range_products_3}</td>

        <td>{tableData.fitness_price_range_1}</td>
        <td>{tableData.fitness_price_range_products_1}</td>
        <td>{tableData.fitness_price_range_2}</td>
        <td>{tableData.fitness_price_range_products_2}</td>
        <td>{tableData.fitness_price_range_3}</td>
        <td>{tableData.fitness_price_range_products_3}</td>

        <td>{tableData.toys_price_range_1}</td>
        <td>{tableData.toys_price_range_products_1}</td>
        <td>{tableData.toys_price_range_2}</td>
        <td>{tableData.toys_price_range_products_2}</td>
        <td>{tableData.toys_price_range_3}</td>
        <td>{tableData.toys_price_range_products_3}</td>
      </tr>
    </>
  )
}

UnitPricePerCustomer.propTypes = {
  tableData: shape({}).isRequired
}

export default UnitPricePerCustomer
