import React from 'react'
import { shape } from 'prop-types'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

function ConversionRateDeal(props) {
  const { tableData } = props
  return (
    <>
      {/* 转化率-成交 */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="3" className="sticky-left sticky-second">
          转化率-成交
        </th>
        <th colSpan="3">下单人数（未付款）</th>
        <th colSpan="3">下单转化率（未付款）</th>
        <th colSpan="3">成交人数（完成付款）</th>
        <th colSpan="3">成交转化率（完成付款）</th>
      </tr>

      {/* row2 */}
      <tr>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无数据来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：下单人数/去年同期下单人数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际下单人数/UV</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无数据来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：下单转化率/去年同期下单转化率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际下单总人数-实际下单未付款人数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无数据来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：成交人数/去年同期成交人数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：成交人数/UV</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：无数据来源</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：成交转化率/去年同期成交转化率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.people_amount_unpaid_actual}</td>
        <td>{tableData.people_amount_unpaid_budget}</td>
        <td>{tableData.people_amount_unpaid_rate_year_on_year}</td>
        <td>{tableData.people_amount_unpaid_rate_actual}</td>
        <td>{tableData.people_amount_unpaid_rate_budget}</td>
        <td>{tableData.people_amount_unpaid_rate_rate_year_on_year}</td>
        <td>{tableData.people_amount_paid_actual}</td>
        <td>{tableData.people_amount_paid_budget}</td>
        <td>{tableData.people_amount_paid_rate_year_on_year}</td>
        <td>{tableData.people_amount_paid_rate_actual}</td>
        <td>{tableData.people_amount_paid_rate_budget}</td>
        <td>{tableData.people_amount_paid_rate_rate_year_on_year}</td>
      </tr>
    </>
  )
}

ConversionRateDeal.propTypes = {
  tableData: shape({}).isRequired
}

export default ConversionRateDeal
