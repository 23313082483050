/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { Col, Divider, Form, Popover, Row, Table, Typography } from 'antd'
import {AppstoreOutlined, CaretDownOutlined, CaretUpOutlined, ExclamationCircleFilled} from '@ant-design/icons'
import FilterOrderHistory from '../../components/1688ProjectComponents/OrderHistory/FilterOrderHistory'
import BlackInputSelect from '../../components/1688ProjectComponents/inputComponent/BlackInputSelect'
import StickyFooter from '../../components/1688ProjectComponents/FooterComponents/StickyFooter'
import BlackButton from '../../components/1688ProjectComponents/ButtonComponents/BlackButton'

const { Title } = Typography

interface GetProduct {
  status: Number | null;
  goPage: Number | null;
}

const OrderHistory = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sizePage, setSizePage] = useState(10)
  const [totalItem, setTotalItem] = useState(0)
  const [dataRaw, setDataRaw] = useState(null)
  const [dataTable, setDataTable] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNow, setPageNow] = useState(1)
  const [formFilter, setFormFilter] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    fetchGetOrderList()
  }, [formFilter]);

  async function fetchGetOrderList({  goPage = 1 }: GetProduct = {}) {
    try {

      setIsLoading(true)
      const data = settingParamsPayload(goPage , formFilter)
      // const response = await getOrderList(data)
      const response = null
      setDataRaw(response)
      const totalRecords = response.data.total_records
      if (totalRecords) {
        setTotalCount(Number(totalRecords))
      } else {
        setTotalCount(0)
      }

      const dataFromSetting = settingDataForTable(response)
      setDataTable(dataFromSetting)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  function settingDataForTable(response) {
    const data = response.data
    const dataReturn = []
    if (data) {
      for (let order of data) {
        dataReturn.push({
          key: order.sdk_uuid || '',
          product: {
            sdk_uuid: order.sdk_uuid || '',
            entity_id: order.entity_id || '',
          },
          shipping: {
            shipping_address: order.shipping_address,
            shipping_name: order.shipping_name
          },
          amount: order.shipping_amount,
          recipient: {
            customer_email: order.customer_email,
            customer_id: order.customer_id
          },
          time: order.confirmed_at
        })
      }
    }

    return dataReturn
  }

  function settingParamsPayload(goPage, _formFilter) {
    const data = {}
    if (goPage) {
      data.current_page = Number(goPage)
      setPageNow(Number(goPage))
    }
    if (sizePage) {
      data.page_size = sizePage
    }
    console.log({ sizePage })
    if (_formFilter) {
      const status = _formFilter.status
      if (status) {
        data.status = status
      }

    }
    return data

  }

  const onShowSizeChange = (current, pageSize) => {
    setSizePage(pageSize)
  }

  const hide = () => {
    setOpen(false)
  }
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen)
  }

  const handleSelectAll = () => {
    const allRowKeys = dataTable.map((item) => item.key)
    setSelectedRowKeys(allRowKeys)
  }
  const handleDeselectAll = () => {
    setSelectedRowKeys([])
  }

  const onChangePage = (value) => {
    if (value) {
      setPageNow(Number(value))
      fetchGetOrderList({ goPage: Number(value) })
    }
  }


  const titlePage = () => {
    return (
      <Title level={2} style={{ marginBottom: 0, padding: '24px' }}>
        Order History
      </Title>
    )
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  }
  const renderContent = () => {
    return (
      <Row>
        <Col span={24}>
          <Row>
            <Col span={4}>
              <ExclamationCircleFilled style={{ fontSize: '20px' }} />
            </Col>
            <Col span={20}>
              You can click here to set the product information to be displayed
            </Col>
            <Col span={24}>
              <Row justify="end">
                <Col span={20} />
                <Col span={4}>
                  <BlackButton functionParent={hide} title="Ok" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
  const columns = [
    {
      title: () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div> Product Information </div>
            <div>
              <Popover
                placement="bottomLeft"
                content={renderContent}
                open={open}
                onOpenChange={handleOpenChange}
              >
                <AppstoreOutlined
                  style={{ fontSize: '20px', cursor: 'pointer' }}
                />
              </Popover>
            </div>
          </div>
        )
      },
      dataIndex: 'product',
      render: (text) => {
        return (
          <Row>
            <Col span={24}>
              SDK UUID: {text.sdk_uuid}
            </Col>
            <Col>
              Entity ID: {text.entity_id}
            </Col>
          </Row>
        )
      },
    },
    {
      title: 'Shipping information',
      dataIndex: 'shipping',
      render: (text) => {
        return (
          <Row>
            <Col span={24}>
              Customer email: {text.customer_email}
            </Col>
            <Col>
              Customer id: {text.customer_id}
            </Col>
          </Row>
        )
      },
    },
    {
      title: 'Order amount',
      dataIndex: 'amount',
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      render: (text) => {
        return (
          <Row>
            <Col span={24}>
              Shipping address: {text.shipping_address}
            </Col>
            <Col>
              Shipping name: {text.shipping_name}
            </Col>
          </Row>
        )
      },
    },
    {
      title: 'Package number &\n Logistic information',
      dataIndex: 'logistic'
    },
    {
      title: 'Time',
      dataIndex: 'time'
    }
  ]

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>
    }
    if (type === 'next') {
      return <a>Next</a>
    }
    return originalElement
  }



  return (
    <div
      style={{ width: '100%', background: '#F8F8F8', position: 'relative' }}
      className="pub-collection-box"
    >
      <div style={{ width: '100%' }}>{titlePage()}</div>
      <Divider style={{ color: 'black', margin: 'unset' }} />
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
      >
        <Form form={form} onFieldsChange={() => setFormFilter(form.getFieldsValue())}>
          <FilterOrderHistory setFormFilter={setFormFilter} fetchGetOrderList={fetchGetOrderList} formRef={form} />
          <Row gutter={[0, 10]} style={{ marginTop: '50px' }}>
            <Col span={2}>
              <BlackInputSelect placeHolder="More" />
            </Col>
            <Col span={10} />
            <Col span={12}>
              <Row style={{ width: '100%' }}>
                <Form.Item
                  label="Sort"
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  style={{ width: '100%' }}
                >
                  <Row style={{ width: '100%' }}>
                    <Col span={7}>
                      <BlackInputSelect
                        placeHolder="Order time"
                        option={[
                          {
                            label: <div> Order time <CaretUpOutlined /></div>,
                            value: null
                          },
                          {
                            label: <div> Order time <CaretDownOutlined /></div>,
                            value: null
                          },
                          {
                            label: <div> Payment time <CaretUpOutlined /></div>,
                            value: null
                          },
                          {
                            label: <div> Payment time <CaretDownOutlined /></div>,
                            value: null
                          },
                          {
                            label: <div> Time to <br/> print form <CaretUpOutlined /></div>,
                            value: null
                          },
                          {
                            label: <div> Time to <br/> print form <CaretDownOutlined /></div>,
                            value: null
                          },
                          {
                            label: <div> Shipping form <CaretUpOutlined /></div>,
                            value: null
                          },
                          {
                            label: <div> Shipping form <CaretDownOutlined /></div>,
                            value: null
                          },

                        ]}
                      />
                    </Col>
                    <Col span={7}>
                      <BlackInputSelect placeHolder="Export Order" />
                    </Col>
                    <Col span={10}>
                      <BlackInputSelect placeHolder="Synchronize order" />
                    </Col>
                  </Row>
                </Form.Item>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                dataSource={dataTable}
                loading={isLoading}
                pagination={false}
                columns={columns}
                rowSelection={rowSelection}
                scroll={{
                  y: 240
                }}
              />
            </Col>
          </Row>
        </Form>
        <StickyFooter
          sizePage={sizePage}
          totalItem={totalItem}
          handleCheckAll={handleSelectAll}
          handleUncheckAll={handleDeselectAll}
          itemRender={itemRender}
          totalPage={totalCount}
          onChangePage={onChangePage}
          page={pageNow}
          onShowSizeChange={onShowSizeChange}
          itemSelected={selectedRowKeys?.length || 0}
        />
      </div>
    </div>
  )
}

export default OrderHistory
