export const I18N_EMPTY_FORM = 'I18N_EMPTY_FORM'
export const I18N_SET_FORM_DATA = 'I18N_SET_FORM_DATA'
export const I18N_UPDATE_FIELD = 'I18N_UPDATE_FIELD'

export const emptyForm = () => ({
  type: I18N_EMPTY_FORM
})

export const setFormData = (data) => ({
  type: I18N_SET_FORM_DATA,
  payload: data
})

export const updateField = (locale, field, value) => ({
  type: I18N_UPDATE_FIELD,
  payload: { locale, field, value }
})
