import React from 'react'
import { Button } from 'antd'

interface Props {
  title: String;
  icon: Function;
  functionParent: Function;
  loading: Boolean;
  color: String;
}
const GrayButton = ({
  title,
  icon,
  functionParent,
  loading,
  color = 'black'
}: Props) => {
  return (
    <Button
      size="large"
      style={{
        background: '#DADADA',
        color,
        width: '100%',
        fontSize: '15px'
      }}
      loading={loading}
      onClick={functionParent}
      icon={icon || false}
    >
      {title}
    </Button>
  )
}

export default GrayButton
