/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { Modal, Row, Col, Card, Button, Table, Form } from 'antd'
import { ArrowRightOutlined, SearchOutlined } from '@ant-design/icons'
import DoubleDateInput from '../inputComponent/DoubleDateInput'
import RegularSelectInput from '../inputComponent/RegularSelectInput'
import BlackButton from '../ButtonComponents/BlackButton'
import StickyFooter from '../FooterComponents/StickyFooter'
import { getStatisticRelease } from '../../../services/1688Projects/collection'

interface Props {
  setOpen: Function;
  open: Boolean;
}

interface GetProduct {
  goPage: Number | null;
  dataForm: any;
}
const ModalReleaseRecord = ({ open, setOpen }: Props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [form] = Form.useForm()
  const [sizePage, setSizePage] = useState(10)
  const [totalItem, setTotalItem] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [pageNow, setPageNow] = useState(1)
  const [successNum, setSuccessNum] = useState(0)

  async function getStatisticReleaseData({ goPage = 1, dataForm }: GetProduct = {}) {
    try {
      const data = {}
      if (sizePage) {
        data.pageSize = sizePage
      }
      if (goPage) {
        data.beginPage = goPage
        setPageNow(goPage)
      } else {
        data.beginPage = 1
        setPageNow(1)
      }

      if (dataForm) {
        const firstDate = dataForm.date1
        const lastDate = dataForm.date2
        const siteData = dataForm['filter[publish_site]']
        if (firstDate) {
          data['filter[start_time]'] = firstDate
        }
        if (lastDate) {
          data['filter[end_time]'] = lastDate
        }
        if (siteData) {
          data['filter[publish_site]'] = siteData
        }
      }
      const response = await getStatisticRelease(data)
      const totalRecords = response.totalRecords
      if (totalRecords) {
        setTotalCount(totalRecords)
      } else {
        setTotalCount(0)
      }
      const totalSuccess = response.totalAction
      if (totalSuccess) {
        setSuccessNum(totalSuccess)
      } else {
        setSuccessNum(0)
      }
      const dataRes = response.list
      if (dataRes) {
        const dataForTable = []
        for (let i = 0; i < dataRes.length; i++) {
          const dataEdit = {
            key: i + 1,
            name: dataRes[i].author,
            statistic_dimension: dataRes[i].publish_site,
            success_release: dataRes[i].count_action_1
          }
          dataForTable.push(dataEdit)
        }
        setTotalItem(dataForTable.length)
        setDataTable(dataForTable)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onChangePage = (value) => {
    if (value) {
      setPageNow(Number(value))
      getStatisticReleaseData({ goPage: Number(value) })
    }
  }

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a className="button-pagination previous">Previous</a>
    }
    if (type === 'next') {
      return <a className="button-pagination next">Next</a>
    }
    return originalElement
  }

  const handleSelectAll = () => {
    const allRowKeys = dataTable.map((item) => item.key)
    setSelectedRowKeys(allRowKeys)
  }
  const handleDeselectAll = () => {
    setSelectedRowKeys([])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name
    })
  }

  const TitleCard = () => {
    return (
      <div
        style={{
          fontSize: '32px',
          fontWeight: 'bold'
        }}
      >
        Release Statistic
      </div>
    )
  }
  const columns = [
    {
      title: 'Account Name',
      dataIndex: 'name'
    },
    {
      title: 'Statistic Dimension',
      dataIndex: 'statistic_dimension',
      sorter: (a, b) => {
        return a.statistic_dimension - b.statistic_dimension
      }
    },
    {
      title: 'Number of \n Success Release',
      dataIndex: 'success_release',
      sorter: (a, b) => {
        return a.success_release - b.success_release
      }
    }
  ]

  const handleFinishFilter = () => {
    const data = form.getFieldsValue()

    getStatisticReleaseData({ dataForm:data  })
  }

  const onShowSizeChange = (current, pageSize) => {
    setSizePage(pageSize)
  }

  useEffect(() => {
    getStatisticReleaseData()
  }, []);

  return (
    <Modal
      onCancel={() => setOpen(false)}
      centered
      open={open}
      onOk={() => setOpen(false)}
      width={1800}
      footer={false}
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Card style={{ background: '#F8F8F8' }} title={<TitleCard />}>
            <Row gutter={[10, 10]}>
              <Col
                span={24}
                style={{
                  background: 'white',
                  paddingBlock: '10px',
                  marginBottom: '40px'
                }}
              >
                <Form
                  form={form}
                  onChange={() => console.log({ form: form.getFieldsValue() })}
                  onFinish={handleFinishFilter}
                >
                  <Row gutter={[10, 10]}>
                    <Col span={10}>
                      <DoubleDateInput
                        title="Time Limit"
                        colon
                        style={{ width: '220px' }}
                        space="58px"
                        callback={(e)=>{console.log(e)}}
                        name1="date1"
                        name2="date2"
                        formRef={form}
                        // handleOnChangeDate={(data)=>{handleOnchange(data)}}
                      />
                    </Col>
                    <Col span={10}>
                      <RegularSelectInput
                        title="Site"
                        style={{ width: '211px' }}
                        space="120px"
                        colon
                        name="filter[publish_site]"
                        placeHolder="-Please Select-"
                        options={[
                          {
                            label: 'All',
                            value: null
                          },
                          {
                            label: 'Nigeria',
                            value: 'XPARK-NG'
                          },
                          {
                            label: 'Pakistan',
                            value: 'XPARK-PK'
                          },
                          {
                            label: 'Kenya',
                            value: 'XPARK-KE'
                          },
                          {
                            label: 'Ghana',
                            value: 'XPARK-G'
                          },
                          {
                            label: 'Morocco',
                            value: 'XPARK-MA'
                          }
                        ]}
                      />
                    </Col>
                    <Col span={2}>
                      <Button
                        size="large"
                        style={{
                          background: 'black',
                          color: 'white',
                          width: '100%'
                        }}
                        htmlType="submit"
                        icon={<SearchOutlined />}
                      >
                        Search
                      </Button>
                    </Col>
                    <Col span={2}>
                      <Col span={5}>
                        <Button size="large" onClick={() => form.resetFields()}>Reset</Button>
                      </Col>
                    </Col>
                  </Row>
                </Form>

              </Col>
              <Col span={24}>
                <Row>
                  <Col span={7}>
                    <div style={{ background: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                      ${`Total number of successful releases: ${successNum}`}
                    </div>
                  </Col>
                  <Col span={13} />
                  <Col span={4}>
                    <BlackButton icon={<ArrowRightOutlined />} title="Export" />
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={dataTable}
                  rowSelection={rowSelection}
                  scroll={{
                    y: 240
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <StickyFooter
        handleCheckAll={handleSelectAll}
        handleUncheckAll={handleDeselectAll}
        sizePage={sizePage}
        totalItem={totalItem}
        itemRender={itemRender}
        totalPage={totalCount}
        onChangePage={onChangePage}
        page={pageNow}
        onShowSizeChange={onShowSizeChange}
        itemSelected={selectedRowKeys?.length || 0}
      />
    </Modal>
  )
}

export default ModalReleaseRecord
