/* eslint-disable */
import React from 'react'
import { Button, Col, Form, Row } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import RegularInput from '../inputComponent/RegularInput'
import DoubleInputWithUnit from '../inputComponent/DoubleInputWithUnit'
import RegularSelectInput from '../inputComponent/RegularSelectInput'
import SuffixInput from "../inputComponent/SuffixInput";
import SuffixInputTitleNoBorder from "../inputComponent/SuffixInputTitleNoBorder";
import RegularNumberInput from "../inputComponent/RegularNumberInput";

const FilterPublicCollection = ({ formRef, isloading, creatorOption, callback, platFormOptions }) => {

  const handleOnFinish = () => {
    const dataFormFilter = formRef.getFieldsValue()
    if (callback) callback(dataFormFilter)
  }

  const handleChangeForm = () => {
    const data = formRef.getFieldsValue()
    console.log({ data })
  }

  return (
    <Form form={formRef} onFinish={handleOnFinish} onChange={handleChangeForm}>
      <Row
        gutter={[10, 10]}
        style={{
          background: 'white',
          paddingInline: '5px',
          paddingBlock: '10px'
        }}
      >
        <Col span={12}>
          <RegularInput
            title="Product Title"
            style={{ width: '200px' }}
            size="large"
            placeHolder="Please enter the product title, separated by commas"
            colon
            space="52px"
            name="filter[product_title]"
          />
        </Col>
        <Col span={12}>
          <RegularNumberInput
            title="Supply ID"
            style={{ width: '200px' }}
            size="large"
            placeHolder="Please enter the product title, separated by commas"
            space="64px"
            colon
            name="filter[supply_id]"
          />
          {/*<RegularInput*/}
          {/*  title="Supply ID"*/}
          {/*  style={{ width: '200px' }}*/}
          {/*  size="large"*/}
          {/*  placeHolder="Please enter the product title, separated by commas"*/}
          {/*  space="64px"*/}
          {/*  colon*/}

          {/*/>*/}
        </Col>
        <Col span={12}>
          <DoubleInputWithUnit
            title="Supply Price"
            placeHolder1="Minimum Value"
            placeHolder2="Maximum Value"
            unit="Yuan"
            colon
            style={{ width: '200px' }}
            space="52px"
            name1="filter[min_price]"
            name2="filter[max_price]"
          />
        </Col>
        <Col span={12}>
          <RegularSelectInput
            title="Creator"
            style={{ width: '200px' }}
            space="77px"
            colon
            name="filter[author]"
            options={creatorOption}
          />
        </Col>
        <Col span={12}>
          <RegularSelectInput
            title="Claiming Platform"
            style={{ width: '200px' }}
            space="16px"
            colon
            name="filter[platform]"
            options={platFormOptions}
          />
        </Col>
        <Col span={12} />
        <Col span={24} />
        <Col span={24} />
        <Col span={24} />
        <Col span={24} />
        <Col span={24} />
        <Col span={24} />
        <Col span={2}>
          <Button
            size="large"
            style={{ background: 'black', color: 'white' }}
            icon={<SearchOutlined />}
            htmlType="submit"
            loading={isloading}
          >
            Search
          </Button>
        </Col>
        <Col span={5}>
          <Button size="large" onClick={() => formRef.resetFields()}>Reset</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default FilterPublicCollection
