import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function FlowStructure(props) {
  const { tableData } = props
  return (
    <>
      {/* 流量-结构（user） */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="3" width="100" className="sticky-left sticky-second">
          流量-结构（user） &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>UTM Media定义成下拉属性，值就是DM/TR等</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">流量结构-DM</th>
        <th colSpan="3">流量结构-传音系</th>
        <th colSpan="3">流量结构-私域</th>
        <th colSpan="3">流量结构-GTM</th>
        <th colSpan="3">流量结构-社媒</th>
        <th colSpan="3">流量结构-活动会场</th>
      </tr>

      {/* row2 */}
      <tr>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：DM流量/去年同期DM流量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：传音系流量/去年同期传音系流量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：私域流量/去年同期私域流量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：GTM流量/去年同期GTM流量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：社媒流量/去年同社媒流量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据拉取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：活动会场流量/去年同期活动会场流量-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.dm_session_actual}</td>
        <td>{tableData.dm_session_budget}</td>
        <td>{tableData.dm_session_rate_year_on_year}</td>
        <td>{tableData.transsion_session_actual}</td>
        <td>{tableData.transsion_session_budget}</td>
        <td>{tableData.transsion_session_rate_year_on_year}</td>
        <td>{tableData.private_session_actual}</td>
        <td>{tableData.private_session_budget}</td>
        <td>{tableData.private_session_rate_year_on_year}</td>
        <td>{tableData.gtm_session_actual}</td>
        <td>{tableData.gtm_session_budget}</td>
        <td>{tableData.gtm_session_rate_year_on_year}</td>
        <td>{tableData.social_med_session_actual}</td>
        <td>{tableData.social_med_session_budget}</td>
        <td>{tableData.social_med_session_rate_year_on_year}</td>
        <td>{tableData.activity_session_actual}</td>
        <td>{tableData.activity_session_budget}</td>
        <td>{tableData.activity_session_rate_year_on_year}</td>
      </tr>
    </>
  )
}

FlowStructure.propTypes = {
  tableData: shape({}).isRequired
}

export default FlowStructure
