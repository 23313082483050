import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Card, Space } from 'antd'
import {
  CountryFilter,
  TextFilter,
  SubmitButton,
  ResetButton
} from '../../../../../components'

const Filters = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const history = useHistory()
  const [params, setParams] = useState({
    ___country: searchParams.get('___country') || 'NG',
    name: searchParams.get('name') || ''
  })
  const paramChanged = (name) => (value) => {
    setParams({ ...params, [name]: value })
  }
  const handleSubmit = (params) => () => {
    const strParams = new URLSearchParams(params).toString()
    history.push(`${location.pathname}?${strParams}`)
  }
  const handleReset = (initParams, setParams) => () => {
    setParams(initParams)
  }

  return (
    <Card
      style={{
        marginBottom: 20
      }}
    >
      <Space>
        <CountryFilter
          mode="single"
          value={params.country}
          onChange={paramChanged('___country')}
        />
        <TextFilter
          name="Name"
          value={params.name}
          onChange={paramChanged('name')}
        />
      </Space>
      <ResetButton onReset={handleReset({}, setParams)} />
      <SubmitButton onSubmit={handleSubmit(params)} />
    </Card>
  )
}

export default Filters
