/* eslint-disable */
import React, {useState, useEffect} from 'react'
import {Button, Col, Form, Row} from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import RegularInput from '../inputComponent/RegularInput'
import RegularSelectInput from '../inputComponent/RegularSelectInput'
import DoubleInputWithUnit from '../inputComponent/DoubleInputWithUnit'
import DoubleDateInput from '../inputComponent/DoubleDateInput'
import {getAuthorList} from "../../../services/1688Projects/collection";
import RegularNumberInput from "../inputComponent/RegularNumberInput";

const sourcePlatformList = [
  { value: "XPARK-NG", label: "XPARK-NG" },
  { value: "XPARK-GH", label: "XPARK-GH" },
  { value: "XPARK-KE", label: "XPARK-KE" },
  { value: "XPARK-PK", label: "XPARK-PK" },
  { value: "XPARK-MA", label: "XPARK-MA" }
];
const FilterCollectingBox = ({handleOnChange, filterData, isLoading, platFormOptions}) => {
  const [form] = Form.useForm()
  const [listAuthor, setListAuthor] = useState([{}])

  useEffect(() => {
    fetchListAuthor().then(r => {});
  }, []);

  function handleOnFinish(){
    const filter = form.getFieldsValue();
    console.log("filter", filter);
    filterData = filter;
    handleOnChange(filter)
  }

  function handleResetForm(){
    form.resetFields()
  }
  async function fetchListAuthor(){
    let result : any = await getAuthorList({});
    let filterData = [];
    if (result.length > 0){
      for (let key in result){
        let eachData = result[key]
        filterData.push({
          value : eachData.author,
          label : eachData.author
        })
      }
    }
    console.log("result", filterData);
    setListAuthor(filterData)
  }


  return (
    <Form form={form} onFinish={handleOnFinish}>
      <Row
        gutter={[10, 10]}
        style={{
          background: 'white',
          paddingInline: '5px',
          paddingBlock: '10px'
        }}
      >
        <Col span={12}>
          <RegularSelectInput
            title="Related Source"
            name={"filter[platform]"}
            placeHolder={"Please Select Related Source"}
            style={{ width: '221px' }}
            space="52px"
            colon
            options={platFormOptions}
          />
        </Col>
        <Col span={12}>
          <RegularSelectInput
            title="Creator"
            name={"filter[author]"}
            placeHolder={"Please Select Creator"}
            style={{ width: '221px' }}
            space="100px"
            options={listAuthor}
            colon
          />
        </Col>
        <Col span={12}>
          <RegularInput
            title="Product Title"
            name={"filter[product_title]"}
            style={{ width: '221px' }}
            size="large"
            placeHolder="Please enter the product title, separated by commas"
            colon
            space="71px"
          />
        </Col>
        <Col span={12}>
          <RegularNumberInput
            title="Supply ID"
            name={"filter[supply_id]"}
            style={{ width: '221px' }}
            size="large"
            placeHolder="Please enter the product title, separated by commas"
            colon
            space="85px"
          />
        </Col>
        <Col span={12}>
          <DoubleInputWithUnit
            title="Supply Price"
            placeHolder1="Minimum Value"
            placeHolder2="Maximum Value"
            name1={"filter[min_price]"}
            name2={"filter[max_price]"}
            unit="Yuan"
            colon
            style={{ width: '171px' }}
            widthTitle="221px"
            space="70px"
          />
        </Col>
        <Col span={12}>
          <DoubleDateInput
            title="Time Created"
            colon
            style={{ width: '220px' }}
            space="58px"
            name1={"filter[start_time]"}
            name2={"filter[end_time]"}
            formRef={form}/>
        </Col>
        <Col span={2}>
          <Form.Item >
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              style={{ background: 'black', color: 'white' }}
              icon={<SearchOutlined />}
              loading={isLoading}
            >
              Search
            </Button>
          </Form.Item>
        </Col>
        <Col span={1}>
          <Col span={5}>
            <Button size="large" onClick={()=>{handleResetForm()}}>Reset</Button>
          </Col>
        </Col>
        <Col span={10} />
      </Row>
    </Form>

  )
}

export default FilterCollectingBox
