import React, { ReactNode, useState, useEffect } from 'react'

const defaultValues = {
  record: {},
  action: '',
  toggle: () => {}
}
export const GridContext = React.createContext(defaultValues)

interface Props {
  children: ReactNode;
}
const drawerStack = []

export const GridContextProvider = ({ children, onLoad }: Props) => {
  const [record, setRecord] = useState({})
  const [action, setAction] = useState('')
  const toggle = (record, action) => {
    if (action) {
      drawerStack.push({ record, action })
    } else {
      drawerStack.pop()
      if (drawerStack.length) {
        record = drawerStack[drawerStack.length - 1].record
        action = drawerStack[drawerStack.length - 1].action
      }
    }
    setRecord(record)
    setAction(action)
  }
  useEffect(() => {
    onLoad()
  }, [onLoad])
  return (
    <GridContext.Provider
      value={{ record, action, toggle, onLoad, drawerStack }}
    >
      {children}
    </GridContext.Provider>
  )
}
