import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  Card,
  DatePicker,
  Row,
  Col,
  Typography,
  Space,
  Table,
  Button,
  Upload
} from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

import { getStaticText, storage, checkPerms } from '../../utils'
import services from '../../services'
import dateFormat from '../../config/dateFormat'
import { totalReportColumns } from '../../config/tableColumns'

const { Text } = Typography

const dateFormatDay = dateFormat.year
// 总盘 权限
const exportPerm = 'disk-report/export'
const exportBudgetPerm = 'disk-report/export-budget-template'
const importBudgetPerm = 'disk-report/import-budget'

const TotalReport = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  const [isLoad, setIsLoad] = useState(false)
  const { token } = storage.userInfo.val
  const version = storage.currentScope.val.key
  const [isLoading, setIsLoading] = useState(false)

  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  let defaultDate = moment()

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const dataForSearch = nextSearch.get('start_date')
  if (dataForSearch) {
    const nowDate = moment(dataForSearch, dateFormatDay)
    if (nowDate.isValid()) {
      defaultDate = nowDate
    }
  }

  const [tableData, setTableData] = useState([])

  const [requestParams, setRequestParams] = useState(() => {
    return {
      start_date: defaultDate,
      start_date_format: defaultDate.format(dateFormatDay),
      end_date: defaultDate,
      end_date_format: defaultDate.format(dateFormatDay)
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      start_date: requestParams.start_date_format,
      end_date: requestParams.end_date_format
    }
  }, [requestParams])

  const fetchData = useCallback(() => {
    toggleLoading()

    services.totalReport
      .getTotalReport(requestParamsTransformer)
      .then((result) => {
        setTableData(result.disk_report || [])
      })
      .finally(() => {
        toggleLoading()
      })
  }, [requestParamsTransformer, toggleLoading])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  const handleChangeDate = useCallback((date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      start_date: date,
      start_date_format: dateString,
      end_date: date,
      end_date_format: dateString
    }))
  }, [])

  useEffect(() => {
    if (!isLoad) {
      fetchData()
    }
    setIsLoad(true)
  }, [fetchData, isLoad])

  const filter = () => {
    setUrlSearchForState()
    fetchData()
  }

  const [exportLoading, setExportLoading] = useState(false)

  const [fileList, setFileList] = useState([])
  const handleUpload = (info) => {
    const { file } = info
    setFileList(info.fileList.filter((file) => !!file.status))
    if (file.response) {
      fetchData()
    }
  }

  const handleExportBudget = () => {
    setExportLoading(true)
    services.totalReport
      .exportTotalBudget(requestParamsTransformer)
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
      .finally(() => {
        setExportLoading(false)
      })
  }

  const handleExport = () => {
    setExportLoading(true)
    services.totalReport
      .exportDashboard(requestParamsTransformer)
      .then((result) => {
        const { url } = result
        window.location.href = url
      })
      .finally(() => {
        setExportLoading(false)
      })
  }

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.time.text')}</Text>
          <DatePicker
            style={{ width: '250px' }}
            picker="year"
            format={dateFormatDay}
            allowClear={false}
            defaultValue={requestParams.start_date}
            onChange={handleChangeDate}
          />
        </Space>
        <Space style={{ cssFloat: 'right' }}>
          <Button type="primary" onClick={filter}>
            {getStaticText('component.search.button.filter')}
          </Button>
        </Space>
      </Card>

      <Card>
        <Row>
          <Col span={12}>
            <Text>{getStaticText('component.table.header.title')}</Text>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {checkPerms(importBudgetPerm) && (
              <Upload
                accept=".xlsx,.xls"
                showUploadList={false}
                headers={{
                  Authorization: `Bearer ${token}`,
                  'X-Version': version
                }}
                action="/backend/disk-report/import-budget"
                fileList={fileList}
                onChange={handleUpload}
              >
                <Button type="primary" style={{ marginRight: '10px' }}>
                  {getStaticText('component.table.import_budget')}
                </Button>
              </Upload>
            )}
            {checkPerms(exportBudgetPerm) && (
              <Button
                onClick={handleExportBudget}
                loading={exportLoading}
                style={{ marginRight: '10px' }}
              >
                {getStaticText('component.table.export_budget_template')}
              </Button>
            )}
            {checkPerms(exportPerm) && (
              <Button onClick={handleExport} loading={exportLoading}>
                {getStaticText('component.table.export.text')}
              </Button>
            )}
          </Col>
        </Row>
      </Card>

      <Table
        sticky
        bordered
        size="small"
        loading={isLoading}
        columns={totalReportColumns}
        dataSource={tableData}
        rowKey={(record) => record.month_key.value}
        scroll={{ x: 'max-content', y: 500 }}
        pagination={false}
      />
    </>
  )
}

TotalReport.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default TotalReport
