import request from './request'

export const getActivityList = (params) =>
  request.get('/backend/luckyspin/activity/get-list', { params })

export const getActivityDetail = (params) =>
  request.get('/backend/luckyspin/activity/detail', { params })

export const saveActivity = (params) => {
  return request.post('/backend/luckyspin/activity/save', params)
}

export const createActivity = (params) =>
  request.post('/backend/luckyspin/activity/create', params)

export const deleteActivity = (params) =>
  request.post('/backend/luckyspin/activity/delete', params)

// item
export const getActivityItemList = (params) =>
  request.get('/backend/luckyspin/activity/item/get-list', { params })

export const getActivityItemDetail = (params) =>
  request.get('/backend/luckyspin/activity/item/detail', { params })

export const saveActivityItem = (params) => {
  return request.post('/backend/luckyspin/activity/item/save', params)
}

export const createActivityItem = (params) =>
  request.post('/backend/luckyspin/activity/item/create', params)

export const deleteActivityItem = (params) =>
  request.post('/backend/luckyspin/activity/item/delete', params)

// fortunate
export const getFortunateList = (params) =>
  request.get('/backend/luckyspin/activity/fortunate/get-list', { params })

export const deleteFortunateItem = (params) =>
  request.get('/backend/luckyspin/activity/fortunate/delete', { params })

export const exportFortunate = (params) => {
  return request.post('/backend/luckyspin/activity/fortunate/export', {
    ...params
  })
}

export const getActivityRules = (params) =>
  request.get('/backend/xpark/external-system/salesRules/search', {
    params: { ...params, scenario: 'SalesRule/ActivityRuleSearch' }
  })

export const getThemes = () =>
  request.get('/backend/activity-frontend/api/get-themes')
