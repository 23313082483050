import React from 'react'

import { Button, Space } from 'antd'
import { getStaticText } from '../../../utils'

interface Props {
  onReset: Function;
}

const ResetButton = ({ onReset }: Props) => (
  <Space style={{ cssFloat: 'right' }}>
    <Button type="secondary" onClick={onReset}>
      {getStaticText('component.search.button.reset')}
    </Button>
  </Space>
)

export default ResetButton
