/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { Input } from 'antd'
import LabelInput from './LabelInput'

interface Props {
  style: Object;
  title: String;
  placeHolder: String;
  size: String;
  colon: Boolean;
  space: String;
  callback: Function;
}
const RegularTitleNoBorderInput = ({
  style,
  title,
  placeHolder = false,
  size = 'large',
  colon,
  space,
  callback
}: Props) => {
  const [setting, setSetting] = useState('')
  useEffect(() => {
    if (callback) callback(setting)
  }, [setting]);
  return (
    <Input.Group
      style={{
        display: 'flex'
      }}
    >
      <div
        style={{
          width: style?.width || '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start'
        }}
      >
        {LabelInput({ title, space, colon })}
      </div>
      <Input
        style={{
          width: '100%',
          border: '1px',
          borderStyle: 'solid',
          borderColor: '#E3E2E1'
        }}
        onChange={(e) => setSetting(e.target.value)}
        bordered={false}
        size={size}
        placeholder={placeHolder}
      />
    </Input.Group>
  )
}

export default RegularTitleNoBorderInput
