import request from './request'

export const getPeopleYard = (params) =>
  request.get('/backend/comprehensive/dashboard', { params })

export const exportPeopleYard = (params) =>
  request.get('/backend/comprehensive/export', { params })

export const editPeopleYard = (params) =>
  request.get('/backend/comprehensive/edit', { params })

export const savePeopleYard = (data) =>
  request.post('/backend/comprehensive/save', data)

export const goodsPeopleGroup = (data) =>
  request.post('/backend/goods/people-group', data)

export const getNameList = (data) =>
  request.post('/backend/goods/get-list', data)
