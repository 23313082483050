import request from './request'

export const getDailySales = (data) =>
  request.post('/backend/daily-sales/get-list', data)

export const editDailySales = (data) =>
  request.post('/backend/daily-sales/edit', data)

export const exportDailySales = (params) =>
  request.get('/backend/daily-sales/export', { params })

export const exportDailySalesTemplate = () =>
  request.get('/backend/daily-sales/export-template')

export const getDashboard = (data) =>
  request.post('/backend/daily-sales/dashboard', data)

export const exportDashboard = (params) =>
  request.get('/backend/daily-sales/export-dashboard', { params })

export const getdailyHomePage = (data) =>
  request.post('/backend/daily-sales/home-page', data)

export const getExportHomePage = (data) =>
  request.post('/backend/daily-sales/export-home-page', data)

export const getdailyAvgHomePage = (data) =>
  request.post('/backend/daily-sales/avg-home-page', data)

export const getExportAvgHomePage = (data) =>
  request.post('/backend/daily-sales/export-avg-home-page', data)
