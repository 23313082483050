/* eslint-disable */
import React, {useEffect, useState} from 'react'
import { Input } from 'antd'
import LabelInput from './LabelInput'

interface Props {
  title: String;
  placeHolder1: String;
  size: String;
  unit: String;
  space: String;
  colon: Boolean;
  style: Object;
  widthTitle: String;
  callback: Function;
}
const DoubleInputDoubleUnit = ({
  title,
  placeHolder1 = false,
  placeHolder2 = false,
  size = 'large',
  unit1 = 'Yuan',
  unit2 = 'USD',
  space,
  colon,
  style,
  widthTitle,
  callback
}: Props) => {
  const [setting, setSetting] = useState({})
  useEffect(() => {
    if (callback) callback(setting)
  }, [setting]);
  return (
    <Input.Group
      compact
      style={{
        display: 'flex'
      }}
    >
      <div
        style={{
          width: style?.width || '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          paddingLeft: '10px'
        }}
      >
        {LabelInput({ title, space, colon, widthTitle })}
      </div>
      <Input
        onChange={(e) => setSetting((prev) => ({ ...prev ,one: e.target.value}))}
        bordered={false}
        size={size}
        placeholder={placeHolder1}
        style={{
          width: '100%',
          border: '1px',
          borderStyle: 'solid',
          borderColor: '#E3E2E1'
        }}
        addonAfter={unit1}
      />
      <div style={{ fontSize: '24px', marginInline: '10px' }}>/</div>
      <Input
        onChange={(e) => setSetting((prev) => ({ ...prev ,two: e.target.value}))}
        style={{
          width: '100%',
          border: '1px',
          borderStyle: 'solid',
          borderColor: '#E3E2E1'
        }}
        bordered={false}
        addonAfter={unit2}
        size={size}
        placeholder={placeHolder2}
      />
    </Input.Group>
  )
}

export default DoubleInputDoubleUnit
