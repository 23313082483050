import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getStaticText } from '../../../utils'

const DashboardDailyColumns = [
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.country')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.country')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    align: 'center',
    width: 100,
    fixed: 'left',
    render: (data) => getStaticText(data)
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.spu_top_1')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.spu_top_1')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'spu_top_1',
    key: 'spu_top_1',
    align: 'center',
    width: 500,
    render: (data) => (
      <div className="auth-magin" dangerouslySetInnerHTML={{ __html: data }} />
    )
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.spu_top_2')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.spu_top_2')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'spu_top_2',
    key: 'spu_top_2',
    align: 'center',
    width: 500,
    render: (data) => (
      <div className="auth-magin" dangerouslySetInnerHTML={{ __html: data }} />
    )
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.spu_top_3')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.spu_top_3')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'spu_top_3',
    key: 'spu_top_3',
    align: 'center',
    width: 500,
    render: (data) => (
      <div className="auth-magin" dangerouslySetInnerHTML={{ __html: data }} />
    )
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.sales_amount')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.sales_amount')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'sales_amount',
    key: 'sales_amount',
    align: 'center',
    width: 100
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.orders')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.orders')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'orders',
    key: 'orders',
    align: 'center',
    width: 110
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.uv')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText('component.remarks.data.source.data.ga_note')}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.uv')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'uv',
    key: 'uv',
    align: 'center',
    width: 100
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.app_uv')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText('component.remarks.data.source.data.ga_note')}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.app_uv')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'app_uv',
    key: 'app_uv',
    align: 'center',
    width: 100
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.all_uv')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText('component.remarks.data.source.data.ga_note')}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.all_uv')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'all_uv',
    key: 'all_uv',
    align: 'center',
    width: 100
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.conversion_rate')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'dashboard.daily.table.conversion_rate_explain_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'dashboard.daily.table.conversion_rate_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.conversion_rate')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'conversion_rate',
    key: 'conversion_rate',
    align: 'center',
    width: 100
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.order_average')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'dashboard.daily.table.customer_order_average_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.order_average')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'order_average',
    key: 'order_average',
    align: 'center',
    width: 120
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.user_average')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'dashboard.daily.table.user_average_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.user_average')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'user_average',
    key: 'user_average',
    align: 'center',
    width: 120
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.online_paid_orders_percent')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'dashboard.daily.table.online_paid_orders_percent_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText(
            'dashboard.daily.table.online_paid_orders_percent'
          )}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'online_paid_orders_percent',
    key: 'online_paid_orders_percent',
    align: 'center',
    width: 140
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.total_online_product')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'dashboard.daily.table.total_online_product_explain_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.total_online_product')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'total_online_product',
    key: 'total_online_product',
    align: 'center',
    width: 140
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.active_product_percent')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'dashboard.daily.table.active_product_percent_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.active_product_percent')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'active_product_percent',
    key: 'active_product_percent',
    align: 'center',
    width: 140
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.phone_sales_volume')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'dashboard.daily.table.phone_sales_volume_explain_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.phone_sales_volume')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'phone_sales_volume',
    key: 'phone_sales_volume',
    align: 'center',
    width: 130
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.advertising_amount')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText(
                  'dashboard.daily.table.advertising_amount_calculation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.advertising_amount')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'advertising_amount',
    key: 'advertising_amount',
    align: 'center',
    width: 140
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.roi')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.calculated.results_note'
                )}
              </p>
              <p>
                {getStaticText('dashboard.daily.table.roi_calculation_note')}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.roi')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'roi',
    key: 'roi',
    align: 'center',
    width: 100
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.bad_review_total')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.bad_review_total')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'bad_review_total',
    key: 'bad_review_total',
    align: 'center',
    width: 100
  }
]

export default DashboardDailyColumns
