/* eslint-disable */
import React from 'react'
import { Button, Col, Row, Form } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import RegularSelectInput from '../inputComponent/RegularSelectInput'
import RegularInput from '../inputComponent/RegularInput'
interface GetProduct {
  getPricingData: Function;
}
const FilterPricingTemplateComponents = ({ getPricingData, isLoadingTable, platFormOptions }) => {
  const [form] = Form.useForm()

  const handleOnFinish = () => {
    console.log(form.getFieldsValue())
    const dataForm = form.getFieldsValue()
    getPricingData({ dataForm })
  }

  return (
    <Form form={form} onFinish={handleOnFinish}>
      <Row
        gutter={[10, 10]}
        style={{
          background: 'white',
          paddingTop: '5px',
          paddingBlock: '10px',
          paddingBottom: '50px'
        }}
      >
        <Col span={24}>
          <div
            style={{
              fontSize: '15px',
              padding: '10px',
              border: '1px',
              borderStyle: 'solid',
              borderColor: '#E3E2E1'
            }}
          >
            Pricing template is a tool to help merchants accurately set freight
            hidden prices & pre/post-discount sales prices in batches
          </div>
        </Col>
        <Col span={12}>
          <RegularSelectInput
            title="Site"
            style={{ width: '200px' }}
            space="117px"
            colon
            name="filter[site]"
            options={platFormOptions}
          />
        </Col>
        <Col span={12}>
          <RegularInput
            title="Template Name"
            style={{ width: '200px' }}
            size="large"
            placeHolder="Please enter template name"
            colon
            space="38px"
            name="filter[template_name]"
          />
        </Col>
        <Col span={2}>
          <Button
            size="large"
            style={{
              background: 'black',
              color: 'white',
              width: '100%'
            }}
            icon={<SearchOutlined />}
            htmlType="submit"
            loading={isLoadingTable}
          >
            Search
          </Button>
        </Col>
        <Col span={1}>
          <Col span={5}>
            <Button size="large" onClick={() => form.resetFields()}>Reset</Button>
          </Col>
        </Col>
        <Col span={10} />
      </Row>
    </Form>
  )
}

export default FilterPricingTemplateComponents
