import React, { useContext } from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { PermissionButton, GridContext } from '../../../../../components'
import { deleteCategory } from '../services'

interface Props {
  record: Object;
}
const Actions = ({ record }: Props) => {
  const { toggle, onLoad } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit')
  }
  const onActivity = () => {
    toggle(record, 'select_activity')
  }
  const onDelete = () => {
    deleteCategory(onLoad)(record.id)
  }
  return (
    <>
      <PermissionButton
        size="small"
        onClick={onActivity}
        icon={<EditOutlined />}
        permission="crowd-founding/category/save"
        type="primary"
      >
        Product
      </PermissionButton>
      <PermissionButton
        size="small"
        style={{ marginLeft: '5px' }}
        onClick={onEdit}
        icon={<EditOutlined />}
        permission="crowd-founding/category/save"
        type="primary"
      >
        Edit
      </PermissionButton>
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <PermissionButton
          style={{ marginLeft: '5px' }}
          icon={<DeleteOutlined />}
          size="small"
          permission="crowd-founding/category/delete"
          type="primary"
        >
          Delete
        </PermissionButton>
      </Popconfirm>
    </>
  )
}
export default Actions
