import React from 'react'

import { getStaticText } from '../../utils'

const goodsDashboardConversionColumns = [
  {
    title: <div>{getStaticText('dashboard.goods.product_name')}</div>,
    width: 300,
    dataIndex: 'product_name',
    key: 'product_name'
  },
  {
    title: <div>{getStaticText('dashboard.goods.orders')}</div>,
    dataIndex: 'orders',
    key: 'orders',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.orders_uv')}</div>,
    dataIndex: 'orders_uv',
    key: 'orders_uv',
    sorter: true
  },
  {
    title: (
      <div>{getStaticText('dashboard.goods.online_confirmed_orders')}</div>
    ),
    dataIndex: 'orders_online_paid',
    key: 'orders_online_paid',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.online_confirmed_uv')}</div>,
    dataIndex: 'orders_online_paid_uv',
    key: 'orders_online_paid_uv',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.dealt_orders')}</div>,
    dataIndex: 'orders_dealt',
    key: 'orders_dealt',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.dealt_uv')}</div>,
    dataIndex: 'orders_dealt_uv',
    key: 'orders_dealt_uv',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.gmv')}</div>,
    dataIndex: 'gmv',
    key: 'gmv',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.per_price')}</div>,
    dataIndex: 'per_price',
    key: 'per_price',
    sorter: true
  },
  {
    title: <div>{getStaticText('dashboard.goods.gmv_prop')}</div>,
    dataIndex: 'gmv_prop',
    key: 'gmv_prop'
  }
]

export default goodsDashboardConversionColumns
