import services, {
  withTableLoading,
  withGlobalLoading,
  withTranslation
} from '../../../services'

export const getPrivilegesConfig = (handler) =>
  withTableLoading((params) =>
    services.membership.getPrivilegesConfig(params).then(handler)
  )

export const togglePrivilege = (handler) =>
  withGlobalLoading((params) =>
    services.membership.togglePrivilege(params).then(handler)
  )

export const getProductsForMembership = (handler) =>
  withGlobalLoading((params) =>
    services.membership.getProductsForMembership(params).then(handler)
  )

export const getProducts = (handler) =>
  withGlobalLoading((params) =>
    services.membership.getProducts(params).then(handler)
  )

export const toggleProduct = (handler) =>
  withGlobalLoading((params) =>
    services.membership.toggleProduct(params).then(handler)
  )

export const editPosition = (handler) =>
  withGlobalLoading((params) =>
    services.membership.editPosition(params).then(handler)
  )

export const getMembershipDetail = (handler) =>
  withGlobalLoading((params) =>
    services.membership.getMembershipDetail(params).then(handler)
  )

export const updateMembership = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.membership.updateMembership(params).then(handler)
    )
  )

export const getPrivilegeConfig = (handler) =>
  withGlobalLoading((params) =>
    services.membership.getPrivilegeConfig(params).then(handler)
  )

export const savePrivilegeConfig = (handler) =>
  withGlobalLoading((params) =>
    services.membership.savePrivilegeConfig(params).then(handler)
  )

export const freshCache = (handler) => (params) =>
  services.membership.freshCache(params).then(handler)
export const { getActivityRules } = services.membership

export const { uploadFileToServer } = services.membership
