import { parse } from 'querystring'

export const transFormData = (data, symbol = ',') => {
  const regExp = new RegExp(symbol, 'g')
  if (typeof data !== 'object' && regExp.test(data)) {
    return data.replace(regExp, '')
  }
  return data
}

export const getPageQuery = () => parse(window.location.href.split('?')[1])
