import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function PrivateDomain(props) {
  const { tableData } = props
  return (
    <>
      {/* 私域 */}
      {/* row1 */}
      <tr className="table-th-color">
        <th rowSpan="3" width="100" className="sticky-left sticky-second">
          私域 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>私域就是网红裂变</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">累计粉丝人数</th>
        <th colSpan="3">本期新增粉丝数</th>
        <th colSpan="3">
          累计粉丝群数量 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>手工填写</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          本期新增粉丝群数量&nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>手工填写</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          累计XP总人数 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>一二级网红数据</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">本期XP新增人数</th>
        <th colSpan="3">
          本期老XP引入新注册XP数 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>本周通过老XP引入新注册XP用户数量</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">累计XP在XPARK上完成订单数</th>
        <th colSpan="3">本期XP在XPARK完成的订单数</th>
        <th colSpan="3">累计XP在XPARK上完成订单的GMV</th>
        <th colSpan="3">本期XP在XPARK完成的订单GMV</th>
        <th colSpan="3">
          新XP首单转化率 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>XP:XP下级第一次下单用户数/XP总用户数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th colSpan="3">
          私域订单退货率 &nbsp;
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：退货单数/总订单数(PP商计算子订单数)</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row2 */}
      <tr>
        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前累计粉丝人数/去年同期累计粉丝人数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：本期新增粉丝人数/去年同期新增粉丝人数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>实际</th>
        <th>预算</th>
        <th>同比</th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：当前累计XP人数/去年同期累计XP人数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：本期新增XP人数/去年同期新增XP人数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：本期老XP引入新注册XP数/去年同期老XP引入新注册XP数-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：累计XP在XPARK上完成订单数/去年同期累计XP在XPARK上完成订单数-1
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：本期XP在XPARK完成的订单数/去年同期XP在XPARK完成的订单数-1
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：累计XP在XPARK上完成订单的GMV/去年同期累计XP在XPARK上完成订单的GMV-1
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：数据抓取</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>
                  来源：本期XP在XPARK完成的订单GMV/去年同期XP在XPARK完成的订单GMV-1
                </p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际新下单XP用户数/累计XP总人数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：新XP首单转化率/去年同期新XP首单转化率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>

        <th>
          实际
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：实际XP在Xpark上退单数/XP完成订单数</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          预算
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>未计算</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
        <th>
          同比
          <Tooltip
            title={() => (
              <div className="toolTipStyle">
                <p>来源：私域订单退货率/去年同期私域订单退货率-1</p>
              </div>
            )}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </th>
      </tr>

      {/* row3 */}
      <tr>
        <td>{tableData.cumulative_of_fans_actual}</td>
        <td>{tableData.cumulative_of_fans_budget}</td>
        <td>{tableData.cumulative_of_fans_rate_year_on_year}</td>
        <td>{tableData.new_of_fans_actual}</td>
        <td>{tableData.new_of_fans_budget}</td>
        <td>{tableData.new_of_fans_rate_year_on_year}</td>
        <td>{tableData.cumulative_of_fan_base_actual}</td>
        <td>{tableData.cumulative_of_fan_base_budget}</td>
        <td>{tableData.cumulative_of_fan_base_rate_year_on_year}</td>
        <td>{tableData.new_of_fan_base_actual}</td>
        <td>{tableData.new_of_fan_base_budget}</td>
        <td>{tableData.new_of_fan_base_rate_year_on_year}</td>
        <td>{tableData.total_xp_users_actual}</td>
        <td>{tableData.total_xp_users_budget}</td>
        <td>{tableData.total_xp_users_rate_year_on_year}</td>
        <td>{tableData.new_of_xp_users_actual}</td>
        <td>{tableData.new_of_xp_users_budget}</td>
        <td>{tableData.new_of_xp_users_rate_year_on_year}</td>
        <td>{tableData.new_users_with_old_users_actual}</td>
        <td>{tableData.new_users_with_old_users_budget}</td>
        <td>{tableData.new_users_with_old_users_rate_year_on_year}</td>
        <td>{tableData.cumulative_of_complete_xp_orders_actual}</td>
        <td>{tableData.cumulative_of_complete_xp_orders_budget}</td>
        <td>{tableData.cumulative_of_complete_xp_orders_rate_year_on_year}</td>
        <td>{tableData.complete_of_xp_orders_in_xpark_actual}</td>
        <td>{tableData.complete_of_xp_orders_in_xpark_budget}</td>
        <td>{tableData.complete_of_xp_orders_in_xpark_rate_year_on_year}</td>
        <td>{tableData.cumulative_of_complete_xp_gmv_actual}</td>
        <td>{tableData.cumulative_of_complete_xp_gmv_budget}</td>
        <td>{tableData.cumulative_of_complete_xp_gmv_rate_year_on_year}</td>
        <td>{tableData.complete_of_xp_gmv_in_xpark_actual}</td>
        <td>{tableData.complete_of_xp_gmv_in_xpark_budget}</td>
        <td>{tableData.complete_of_xp_gmv_in_xpark_rate_year_on_year}</td>
        <td>{tableData.new_xp_first_conversion_rate_actual}</td>
        <td>{tableData.new_xp_first_conversion_rate_budget}</td>
        <td>{tableData.new_xp_first_conversion_rate_rate_year_on_year}</td>
        <td>{tableData.xp_refund_rate_actual}</td>
        <td>{tableData.xp_refund_rate_budget}</td>
        <td>{tableData.xp_refund_rate_rate_year_on_year}</td>
      </tr>
    </>
  )
}

PrivateDomain.propTypes = {
  tableData: shape({}).isRequired
}

export default PrivateDomain
