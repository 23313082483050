import _1688Exclusive from '../pages/CommonFunction/1688Exclusive'
import PublicCollectionBox from '../pages/CommonFunction/PublicCollectionBox'

const commonFunction = {
  path: '/common-function',
  primary: 'app.sider.common-function',
  modular: 'product',
  permission: 'common-function',
  routes: [
    {
      path: '/common-function/1688-exclusive',
      primary: 'app.sider.common-function-1688-exclusive',
      component: _1688Exclusive,
      modular: 'product',
      permission: 'common-function/1688-exclusive',
      exact: true
    },
    {
      path: '/common-function/public-collection-box',
      primary: 'app.sider.common-function-public-collection-box',
      component: PublicCollectionBox,
      modular: 'product',
      permission: 'common-function/public-collection-box',
      exact: true
    }
  ]
}

export default commonFunction
