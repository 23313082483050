import React from 'react'
import { Button } from 'antd'

interface Props {
  title: String;
  icon: Function;
  functionParent?: Function;
  loading: Boolean;
}
const BlackButton = (props: Props) => {
  return (
    <Button
      loading={props.loading}
      className="black-select-button"
      size="large"
      style={{
        background: 'black',
        color: 'white',
        width: '100%',
        fontSize: '15px'
      }}
      onClick={props.functionParent}
      icon={props.icon || false}
    >
      {props.title}
    </Button>
  )
}

export default BlackButton
