import React, { useContext } from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { Popconfirm } from 'antd'
import { PermissionButton, GridContext } from '../../../../components'
import { deleteRewrite } from '../services'

interface Props {
  record: Object;
}
const Actions = ({ record }: Props) => {
  const { toggle, onLoad } = useContext(GridContext)
  const onEdit = () => {
    toggle(record, 'edit')
  }
  const onDelete = () => {
    deleteRewrite(onLoad)(record.id)
  }
  return (
    <>
      <PermissionButton
        size="small"
        onClick={onEdit}
        icon={<EditOutlined />}
        permission="rbac/user/save"
        type="primary"
      >
        Edit
      </PermissionButton>
      <Popconfirm title="Are you sure?" onConfirm={onDelete}>
        <PermissionButton
          style={{ marginLeft: '5px' }}
          icon={<DeleteOutlined />}
          size="small"
          permission="rbac/user/delete"
          type="primary"
        >
          Delete
        </PermissionButton>
      </Popconfirm>
    </>
  )
}
export default Actions
