import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getSurveyList = (handler) =>
  withTableLoading((params) => services.bbs.getSurveyList(params).then(handler))

export const getSurveyDetail = (handler) =>
  withGlobalLoading((params) =>
    services.bbs.getSurveyDetail(params).then(handler)
  )

export const deleteSurvey = (handler) =>
  withGlobalLoading((id) => services.bbs.deleteSurvey(id).then(handler))

export const createSurvey = (handler) =>
  withGlobalLoading((params) => services.bbs.createSurvey(params).then(handler))

export const saveSurvey = (handler) =>
  withGlobalLoading((params) => services.bbs.saveSurvey(params).then(handler))
