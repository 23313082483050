import React from 'react'

import { getStaticText } from '../../utils'

const goodsDashboardTopAddColumns = [
  {
    title: <div>{getStaticText('dashboard.goods.product_name')}</div>,
    width: 300,
    dataIndex: 'product_name',
    key: 'product_name'
  },
  {
    title: <div>{getStaticText('dashboard.goods.add_volume')}</div>,
    dataIndex: 'add_volume',
    key: 'add_volume'
  },
  {
    title: <div>{getStaticText('dashboard.goods.add_uv')}</div>,
    dataIndex: 'add_uv',
    key: 'add_uv'
  },
  {
    title: <div>{getStaticText('dashboard.goods.add_per_volume')}</div>,
    dataIndex: 'add_per_volume',
    key: 'add_per_volume'
  },
  {
    title: <div>{getStaticText('dashboard.goods.add_goods_uv')}</div>,
    dataIndex: 'uv',
    key: 'uv'
  },
  {
    title: <div>{getStaticText('dashboard.goods.add_rate')}</div>,
    dataIndex: 'add_rate',
    key: 'add_rate'
  }
]

export default goodsDashboardTopAddColumns
