import React from 'react'

import { getStaticText } from '../../utils'
import MyTooltip from '../../pages/Dashboard/Tool/MyTooltip'

const dashboardFlowColumns = [
  {
    title: (
      <div>
        {getStaticText('dashboard.table.source_note')}
        <MyTooltip account="时间段内数据" />
      </div>
    ),
    dataIndex: 'name',
    key: 'name',
    width: 120,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.table.uv')}
        <MyTooltip account="GA获取" />
      </div>
    ),
    dataIndex: 'uv',
    key: 'uv',
    width: 100,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('dashboard.table.buy_rate')}
        <MyTooltip account="下单用户/UV" />
      </div>
    ),
    dataIndex: 'buy_rate',
    key: 'buy_rate',
    width: 110,
    align: 'center'
  }
]

export default dashboardFlowColumns
