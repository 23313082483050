/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {Input, Form, InputNumber} from 'antd'
import LabelInput from './LabelInput'

interface Props {
  style: Object;
  title: String;
  placeHolder: String;
  size: String;
  colon: Boolean;
  space: String;
  suffix: String;
  callback: Function;
  name: String;
  formRef: any;
}
const RegularNumberInput = ({
                       style,
                       title,
                       placeHolder = false,
                       size = 'large',
                       colon,
                       space,
                       suffix,
                       callback,
                       name
                     }: Props) => {
  const [setting, setSetting] = useState('')

  useEffect(() => {
    if (callback) callback(setting)
  }, [setting])

  return (
    <Input.Group
      style={{
        display: 'flex',
        border: '1px',
        borderStyle: 'solid',
        borderColor: '#E3E2E1'
      }}
    >
      <div
        style={{
          width: style?.width || '80px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          paddingLeft: '10px'
        }}
      >
        {LabelInput({ title, space, colon })}
      </div>
      <Form.Item name={name} style={{ width: '100%', marginBottom: 'unset' }}>
        <InputNumber
          onChange={(e) => setSetting(e)}
          style={{ width: '100%' }}
          bordered={false}
          size={size}
          placeholder={placeHolder}
          suffix={suffix}
        />
      </Form.Item>
    </Input.Group>
  )
}

export default RegularNumberInput
