/* eslint-disable */
import React from 'react'
import { Checkbox, Col, Divider, Row, Select, Typography, Upload } from 'antd'
import {
  ArrowRightOutlined,
  DeleteOutlined,
  FileTextOutlined,
  RetweetOutlined
} from '@ant-design/icons'
import BlackButton from '../../../components/1688ProjectComponents/ButtonComponents/BlackButton'
import CardProductImage from '../../../components/1688ProjectComponents/EditCollection/CardProductImage'
import store from '../../../store'
import '../../../css/card-product-image.css'

const { Title } = Typography
const getToken = () => store.getState().users.userInfo.token
const token = {
  authorization: `Bearer ${getToken()}`
}
interface Props {
  productData: Array;
  setProductData: Function;
}
const ProductImage = ({
  productData = [{ id: null, image: null }],
  setProductData
}: Props) => {

  const getMetaData = (url, cb) => {
    const img = new Image()
    img.onload = () => cb(null, img)
    img.onerror = (err) => cb(err)
    img.src = url
  }
  const handleUploadProductImage = async (value) => {
    try {
      if (value?.file?.response && value?.file?.response?.code === 200) {
        const filterProductData = productData.filter((item) => !item?.dummy)
        const id = filterProductData.length + 1
        const newImage = [
          {
            id,
            image: value?.file?.response?.data?.url
          },
          {
            id: id + 1,
            image: null,
            dummy: true
          }
        ]
        const data = filterProductData.concat(newImage)
        if (newImage) {
          setProductData(data)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const titlePage = () => {
    return (
      <Title level={2} style={{ marginBottom: 0, padding: '24px' }}>
        Product Image
      </Title>
    )
  }
  return (
    <>
      <div style={{ width: '100%' }}>{titlePage()}</div>
      <Divider style={{ color: 'black', margin: 'unset' }} />
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
      >
        <Row
          gutter={[10, 10]}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <Col
            span={4}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <div>Product Image</div>
            <div>:</div>
          </Col>
          <Col span={4} className="upload-image-1688">
            <Upload
              action="/backend/file-system/upload"
              headers={token}
              onChange={handleUploadProductImage}
              style={{ width: '100%' }}
              showUploadList={false}
            >
              <BlackButton title="+ Upload image" />
            </Upload>
          </Col>
          <Col span={4}>
            <BlackButton icon={<DeleteOutlined />} title="Batch deletion" />
          </Col>
          <Col span={4}>
            <BlackButton icon={<FileTextOutlined />} title="Edit image" />
          </Col>
          <Col span={4}>
            <BlackButton icon={<RetweetOutlined />} title="Image translation" />
          </Col>
          <Col span={4}>
            <BlackButton icon={<ArrowRightOutlined />} title="Export images" />
          </Col>
          <Col
            span={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <div>
              0 Image
              <br />
              selected
            </div>
          </Col>
          <Col span={4}>
            <Select
              defaultValue="1"
              style={{
                width: '100%'
              }}
            >
              <Select.Option value="1">
                <Checkbox>Select All</Checkbox>
              </Select.Option>
            </Select>
          </Col>
          <Col span={4}>
            <Checkbox>Show large image preview</Checkbox>
          </Col>
          <Col span={4}>
            <Checkbox>Show image size</Checkbox>
          </Col>
          <Col span={4} />
          <Col span={4} />
          <Col span={4} />
          <Col span={20}>
            <p
              style={{
                fontSize: '15px',
                color: '#909090'
              }}
            >
              Image format: JPG, JPEG, PNG; Image size cannot exceed 3MB;
              Recommended image size: 800x800; Image can be dragged and moved.
            </p>
          </Col>
          <Col span={4} />
          <Col span={20}>
            <Row style={{ width: '100%' }} gutter={[10, 10]}>

              {productData.map((item) => {
                return (
                  <Col span={3} key={item.id}>
                    <CardProductImage
                      funcParent={handleUploadProductImage}
                      action="/backend/file-system/upload"
                      headers={token}
                      image={item}
                      data={productData}
                      setData={setProductData}
                      otherFunc={getMetaData}
                    />
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ProductImage
