import { Creatings } from '../pages'

const game = {
  path: '/game',
  primary: 'app.sider.game',
  modular: 'market',
  permission: 'game',
  component: Creatings,
  exact: true
}
export default game
