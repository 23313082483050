import React, { useState, useCallback, useEffect } from 'react'
import { Upload, Modal } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import store from '../../../../store'

interface Props {
  country: String;
  getuploadData: Array;
  setUploadData: Function;
  setNoUpload: Function;
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

const getToken = () => store.getState().users.userInfo.token
const token = {
  authorization: `Bearer ${getToken()}`
}

const UploadList = ({
  country,
  getuploadData,
  setUploadData,
  setNoUpload
}: Props) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState([])

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    )
  }

  const formatValue = useCallback((value) => {
    return value.map((item) => {
      return item.response ? item.response.data.url : item.url
    })
  }, [])

  const handleChange = ({ fileList }) => {
    setFileList(fileList)
    setUploadData(formatValue(fileList))
    if (fileList.length) {
      setNoUpload(false)
    }
  }

  useEffect(() => {
    setFileList(getuploadData)
  }, [getuploadData])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  return (
    <>
      <Upload
        action={`/backend/bbs/upload?___country=${country}`}
        headers={token}
        disabled={!country}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </Upload>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

export default UploadList
