/* eslint-disable */
import React from 'react'
import { Col, Row, Select } from 'antd'

interface Props {
  spanCol: Number;
  option: Array;
  title: String;
  functionFilter: Function;
  loading: Boolean;
}
const SelectFilter = ({
  spanCol,
  option,
  title,
  functionFilter,
  loading
}: Props) => {
  return (
    <Col span={spanCol}>
      <Row>
        <Col span={8} style={{ fontSize: '16px', fontWeight: 'bold' }}>
          {title}
        </Col>
        <Col span={16}>
          <Select
            loading={loading}
            style={{ width: '100%' }}
            showSearch
            placeholder="- Select -"
            onChange={functionFilter}
          >
            {option?.map((item, index) => {
              return (
                <Select.Option value={item.value} key={index}>{item.label}</Select.Option>
                )
            })}
          </Select>
        </Col>
      </Row>
    </Col>
  )
}

export default SelectFilter
