import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  List,
  Space,
  Spin,
  Typography,
  Button,
  Card,
  Row,
  Col,
  message
} from 'antd'
import PropTypes from 'prop-types'
import { PromotionDrawer } from '../../components'

import { getStaticText } from '../../utils'
import services from '../../services'

const { Text, Title } = Typography

function PromotionSetting(props) {
  const {
    history,
    location: { pathname, search }
  } = props

  // 默认请求参数
  const defaultReqData = {
    current: '1',
    pageSize: 10
  }

  const [isLoading, setIsLoading] = useState(false)
  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  // const [formDate, setFormData] = useState([])

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const nextSearchObj = () => {
    return {
      current: nextSearch.get('current'),
      pageSize: nextSearch.get('pageSize')
    }
  }
  Object.keys(nextSearchObj()).forEach((item) => {
    if (nextSearchObj()[item]) defaultReqData[item] = nextSearchObj()[item]
  })

  const [requestParams, setRequestParams] = useState(() => {
    return {
      current: defaultReqData.current,
      pageSize: defaultReqData.pageSize
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      current: requestParams.current * 1,
      pageSize: requestParams.pageSize * 1
    }
  }, [requestParams])

  const formatTime = (dataList) => {
    dataList.map((item) => {
      // eslint-disable-next-line prefer-destructuring
      item.created_at = item.created_at.split(' ')[0]
      // eslint-disable-next-line prefer-destructuring
      item.start_date = item.start_date.split(' ')[0]
      // eslint-disable-next-line prefer-destructuring
      item.end_date = item.end_date.split(' ')[0]
      return item
    })
    return dataList
  }
  const addDate = useMemo(
    () => [
      'name',
      'country',
      'platform',
      'description',
      'start_date',
      'end_date'
    ],
    []
  )

  const [dataList, setDataList] = useState([])
  const [typeEdit, setTypeEdit] = useState(0)

  const getPromotionSchema = useCallback(() => {
    toggleLoading()

    services.settings
      .getPromotionSchema(requestParamsTransformer)
      .then((result) => {
        setDataList(formatTime(result.data))
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  const handleCurrentChange = (current, pageSize) => {
    setRequestParams((prevState) => ({
      ...prevState,
      current,
      pageSize
    }))
  }

  useEffect(() => {
    getPromotionSchema()
    setUrlSearchForState()
  }, [getPromotionSchema, setUrlSearchForState])

  // edit drawerProps
  const [drawerProps, setDrawerProps] = useState({
    visible: false,
    placement: 'right',
    width: '60%'
  })

  const handleToggleDrawer = useCallback(() => {
    setDrawerProps((prevState) => ({
      ...prevState,
      visible: !prevState.visible
    }))
  }, [])

  const [editData, setEditData] = useState({})

  const formatEditData = useCallback(
    (add = false) => {
      let filterArray = Object.keys(editData).filter(
        (item) => !(item === 'created_at' || item === 'promotion_type')
      )
      if (add) {
        filterArray = add
      }
      // console.log(add, filterArray)
      const array = filterArray.map((item) => {
        return {
          label: getStaticText(item),
          key: item,
          value: editData[item] || ''
        }
      })
      return array
    },
    [editData]
  )

  const typeEditData = useMemo(() => {
    if (typeEdit) {
      return formatEditData(addDate)
    }
    return formatEditData()
  }, [addDate, typeEdit, formatEditData])

  const handEditDraw = useCallback(
    (item) => {
      setEditData(item)
      setTypeEdit(0)
      handleToggleDrawer()
    },
    [handleToggleDrawer]
  )

  const handleAdd = useCallback(() => {
    setEditData(addDate)
    setTypeEdit(1)
    handleToggleDrawer()
  }, [handleToggleDrawer, addDate])

  const [editLoading, setEditLoading] = useState(false)

  // 编辑成功回调
  const handleFinish = (values) => {
    setEditLoading(true)

    services.settings
      .savePromotionSchema({ data: values })
      .then(() => {
        getPromotionSchema()
        setEditLoading(false)
        message.success('Success')
      })
      .catch(() => {
        getPromotionSchema()
        message.error('error')
      })
      .finally(() => {
        setEditLoading(false)
        handleToggleDrawer()
      })
  }

  return (
    <>
      <Card style={{ marginLeft: 24, marginRight: 24 }}>
        <Title level={5} style={{ float: 'left' }}>
          {getStaticText('component.table.header.title.promotion')}
        </Title>
        <Space style={{ float: 'right' }}>
          <Button type="primary" onClick={handleAdd}>
            {getStaticText('component.table.add.text')}
          </Button>
        </Space>
      </Card>
      {isLoading ? (
        <Spin />
      ) : (
        <List
          itemLayout="vertical"
          size="large"
          pagination={{
            size: 'small',
            showSizeChanger: true,
            showQuickJumper: true,
            // total,
            // showTotal: () => `共${total}条数据`,
            pageSize: requestParamsTransformer.pageSize,
            current: requestParamsTransformer.current,
            onChange: handleCurrentChange
          }}
          dataSource={dataList}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <Card>
                <Row>
                  <Col span={5}>
                    <Text>名称：</Text>
                    {item.name}
                  </Col>
                  <Col span={5}>
                    <Text>国家：</Text>
                    {item.country}
                  </Col>
                  <Col span={5}>
                    <Text>平台：</Text>
                    {item.platform}
                  </Col>
                  <Col span={5}>
                    <Text>描述：</Text>
                    {item.description}
                  </Col>
                  <Col span={4} style={{ textAlign: 'right' }}>
                    <Button
                      type="primary"
                      onClick={() => {
                        handEditDraw(item)
                      }}
                    >
                      {getStaticText('table.operating.button.text')}
                    </Button>
                  </Col>
                  <Col span={5}>
                    <Text>创建日期：</Text>
                    {item.created_at}
                  </Col>
                  <Col span={5}>
                    <Text>开始日期：</Text>
                    {item.start_date}
                  </Col>
                  <Col span={5}>
                    <Text>结束日期：</Text>
                    {item.end_date}
                  </Col>
                </Row>
              </Card>
              <Space align="baseline" />
            </List.Item>
          )}
        />
      )}

      <PromotionDrawer
        title={getStaticText('table.operating.button.text')}
        drawerProps={drawerProps}
        onClose={handleToggleDrawer}
        columns={typeEditData}
        dataSource={typeEditData}
        colNum={2}
        handleFinish={handleFinish}
        loading={editLoading}
      />
    </>
  )
}

PromotionSetting.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default PromotionSetting
