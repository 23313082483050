import React from 'react'
import Actions from './Actions'

export const columns = [
  {
    title: '国家',
    dataIndex: 'country',
    key: 'country',
    width: '80px',
    align: 'center'
  },
  {
    title: '名称',
    dataIndex: 'title',
    key: 'title',
    align: 'center'
  },
  {
    title: '链接',
    dataIndex: 'link',
    key: 'link',
    width: 100,
    align: 'center',
    render: (link) => (
      <a href={link} target="_blank" rel="noreferrer">
        查看
      </a>
    )
  },
  {
    title: '是否可见',
    dataIndex: 'visible',
    key: 'visible',
    width: 100,
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: '',
    width: 300,
    align: 'center',
    key: 'x',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
