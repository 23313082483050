import React, { useState } from 'react'
import { Upload, Card } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

const { Dragger } = Upload

const UploadSales = () => {
  const [fileList, setFileList] = useState([])

  const handleUpload = (info) => {
    const { file } = info
    setFileList(info.fileList.filter((file) => !!file.status))
    if (file.response) {
      window.location.href = file.response.data.url
    }
  }
  return (
    <div
      style={{
        backgroundColor: '#2d3a4b',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Card style={{ width: 500 }}>
        <Dragger
          accept=".xlsx,.xls"
          action="/backend/ben-report/convert"
          fileList={fileList}
          onChange={handleUpload}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">请点击或拖拽上传原始文件</p>
        </Dragger>
      </Card>
    </div>
  )
}

export default UploadSales
