import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../../services'

export const getOrderList = (handler) =>
  withTableLoading((params) =>
    services.system.getOrderList(params).then(handler)
  )

export const getOrdersDetail = (handler) =>
  withGlobalLoading((id) =>
    services.system.getOrdersDetail({ id }).then(handler)
  )

export const deleteOrders = (handler) =>
  withGlobalLoading((id) => services.system.deleteOrders({ id }).then(handler))

export const updateOrders = (handler) =>
  withGlobalLoading((params) =>
    services.system.updateOrders(params).then(handler)
  )

export const getOrdersItemList = (handler) =>
  withTableLoading((params) =>
    services.system.getOrdersItemList(params).then(handler)
  )

export const getOrdersItemDetail = (handler) =>
  withGlobalLoading((id) =>
    services.system.getOrdersItemDetail({ id }).then(handler)
  )

export const deleteOrdersItem = (handler) =>
  withGlobalLoading((id) =>
    services.system.deleteOrdersItem({ id }).then(handler)
  )

export const updateOrdersItem = (handler) =>
  withGlobalLoading((params) =>
    services.system.updateOrdersItem(params).then(handler)
  )
