import React from 'react'
import Actions from './Actions'
import Reviews from './Review'

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center'
  },
  {
    title: 'Nickname',
    dataIndex: 'nickname',
    key: 'nickname',
    align: 'center',
    width: 100
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    align: 'center'
  },
  {
    title: 'Quality Inspection',
    key: 'quality_inspection',
    align: 'center',
    width: 120,
    render: (data) => {
      if (data.is_admin_created === 1) {
        return '管理员发帖'
      }
      if (data.quality_inspection === 0) {
        if (!data.quality_reason) return '未检测'
        return '已检测未通过'
      }
      if (data.quality_inspection === 1) {
        if (!data.quality_reason) return '已检测已通过'
        return '已检测未通过'
      }
    }
  },
  {
    title: 'Inspection Result',
    dataIndex: 'quality_reason',
    key: 'quality_reason',
    align: 'center',
    width: 100,
    render: (data) => {
      if (data) {
        return (
          <ol>
            {JSON.parse(data).map((value, key) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <li key={key}>
                  <a href={value.url} target="_blank" rel="noreferrer">
                    link
                    {key}
                  </a>
                </li>
              )
              // eslint-disable-next-line
            })}
          </ol>
        )
      }
    }
  },
  {
    title: 'Examine State',
    dataIndex: 'manual_review',
    key: 'manual_review',
    align: 'center',
    width: 100,
    render: (data) => {
      if (data === 0) return '未审核'
      if (data === 1) return '已通过'
      if (data === 2) return '未通过'
    }
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center'
  },
  {
    title: 'Page Views',
    dataIndex: 'page_views',
    key: 'page_views',
    width: 200,
    align: 'center'
  },
  {
    title: 'Comment Count',
    dataIndex: '',
    key: 'comment_count',
    width: 100,
    align: 'center',
    render: (record) => <Reviews record={record} />
  },
  {
    title: 'Likes Count',
    dataIndex: 'likes_count',
    key: 'likes_count',
    width: 100,
    align: 'center'
  },
  {
    title: 'Action',
    dataIndex: '',
    width: 140,
    key: 'x',
    fixed: 'right',
    align: 'center',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 15,
  total: 0
}

export const dataSourceMap = (result) => result || []
