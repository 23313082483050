import React from 'react'
import {
  MinusOutlined,
  RiseOutlined,
  FallOutlined,
  WarningOutlined
} from '@ant-design/icons'
import { Popover, Spin } from 'antd'
import ReactECharts from 'echarts-for-react'
import Actions from './Actions'

interface PriceUnitProps {
  record: Object;
  plat: String;
}
const colors = ['#00ff00', '#999999', '#ff0000']

interface TrendChartProps {
  trendData: Object;
}

const TrendChart = ({ trendData }: TrendChartProps) => {
  if (!trendData || !trendData.length) {
    return <Spin size="large" />
  }
  const options = {
    grid: { top: 8, right: 8, bottom: 24, left: 80 },
    xAxis: {
      type: 'category',
      data: trendData.map((e) => e.crawl_date)
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: trendData.map((e) => e.price),
        type: 'line',
        smooth: true
      }
    ],
    tooltip: {
      trigger: 'axis'
    }
  }
  return <ReactECharts style={{ width: '500px' }} option={options} />
}

const PriceUnit = ({ record, plat, trendData, loadTrend }: PriceUnitProps) => {
  if (!record.platforms || record.platforms.split(',').indexOf(plat) === -1) {
    return ''
  }
  if (record[plat]) {
    return (
      <span style={{ color: colors[record[plat].trend + 1] }}>
        <Popover
          title="Trend"
          style={{ width: '500px' }}
          content={() => (
            <TrendChart
              record={record}
              plat={plat}
              trendData={trendData && trendData[plat]}
              loadTrend={loadTrend}
            />
          )}
          onVisibleChange={(v) => {
            if (v && (!trendData || !trendData[plat])) {
              loadTrend(record.id, plat)
            }
          }}
        >
          {record[plat].price}
        </Popover>
        &nbsp;&nbsp;
        {record[plat].trend === 0 && <MinusOutlined />}
        {record[plat].trend === 1 && <RiseOutlined />}
        {record[plat].trend === -1 && <FallOutlined />}
        &nbsp;&nbsp;
        {record[plat].memo && (
          <Popover
            title={() => (
              <>
                <WarningOutlined style={{ color: '#ff00ff' }} />
                &nbsp;&nbsp;Warning
              </>
            )}
            content={record[plat].memo}
            // onConfirm={confirm}
            // onCancel={cancel}
            okText="OK"
            cancelText="No"
          >
            <WarningOutlined style={{ color: '#ff0000' }} />
          </Popover>
        )}
      </span>
    )
  }
  return 'N/A'
}

export const columns = ({ trendData, loadTrend }) => {
  const cols = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      align: 'center'
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    }
  ]

  const plats = ['Jumia', 'Kikuu', 'XPark', 'Jd', 'Lazada', 'Orisdi', 'Daraz']
  plats.forEach((e) => [
    cols.push({
      title: e,
      key: e,
      render: (record) => (
        <PriceUnit
          record={record}
          plat={e}
          trendData={trendData && trendData[record.id]}
          loadTrend={loadTrend}
        />
      )
    })
  ])

  cols.push({
    title: 'Action',
    dataIndex: '',
    width: 200,
    key: 'x',
    align: 'center',
    render: (record) => <Actions record={record} />
  })
  return cols
}

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
