import React, { useEffect, useState, useCallback, useMemo } from 'react'
import {
  Select,
  Card,
  Table,
  Button,
  Typography,
  Space,
  DatePicker
} from 'antd'
import PropTypes from 'prop-types'
import moment from 'moment'

import { brandCrowdColumns } from '../../config/tableColumns'
import services from '../../services'
import { getStaticText, storage } from '../../utils'
import dateFormat from '../../config/dateFormat'

const dateFormatMonth = dateFormat.month
const { Text } = Typography
const { RangePicker } = DatePicker
const { Option } = Select
const CrowdLike = (props) => {
  const {
    history,
    location: { pathname, search }
  } = props

  // 默认请求参数
  const defaultReqData = {
    country: 'All_Country',
    start_date: moment(),
    end_date: moment()
  }

  const [tableData, setTableData] = useState([])
  const columns = [...brandCrowdColumns]
  const [isLoading, setIsLoading] = useState(false)
  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const nextSearchObj = () => {
    const startDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('start_date'), dateFormatMonth)
      : null
    const endDate = nextSearch.get('start_date')
      ? moment(nextSearch.get('end_date'), dateFormatMonth)
      : null
    return {
      country: nextSearch.get('country'),
      start_date: startDate,
      end_date: endDate
    }
  }
  Object.keys(nextSearchObj()).forEach((item) => {
    if (nextSearchObj()[item]) defaultReqData[item] = nextSearchObj()[item]
  })

  const [requestParams, setRequestParams] = useState(() => {
    return {
      country: defaultReqData.country
        .split(',')
        .filter((item) => !(item === '')),
      start_date: defaultReqData.start_date,
      start_format: defaultReqData.start_date.format(dateFormatMonth),
      end_date: defaultReqData.end_date,
      end_format: defaultReqData.end_date.format(dateFormatMonth)
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      country: requestParams.country.toString(),
      start_date: requestParams.start_format,
      end_date: requestParams.end_format
    }
  }, [requestParams])

  const addIndex = (arr) => arr.map((item, index) => ({ ...item, key: index }))

  const fetchData = useCallback(() => {
    toggleLoading()
    services.brand
      .getBrandCrowd(requestParamsTransformer)
      .then((result) => {
        const data = result.data || []
        setTableData(addIndex(data))
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  // 添加 global 和 All_Country 并判断 disabled 属性
  const countries = useMemo(() => {
    const country = storage.allowedCountries.val || []
    if (requestParams.country.includes('GLOBAL')) {
      country.forEach((country) => {
        country.disabled = true
      })
      country.push({
        label: getStaticText('GLOBAL'),
        value: 'GLOBAL',
        disabled: false
      })
    } else if (requestParams.country.length === 0) {
      country.forEach((country) => {
        country.disabled = false
      })
      country.push({
        label: getStaticText('GLOBAL'),
        value: 'GLOBAL',
        disabled: false
      })
    } else {
      country.push({
        label: getStaticText('GLOBAL'),
        value: 'GLOBAL',
        disabled: true
      })
    }

    if (requestParams.country.includes('All_Country')) {
      country.forEach((country) => {
        country.disabled = true
      })
      country.unshift({
        label: getStaticText('All_Country'),
        value: 'All_Country',
        disabled: false
      })
    } else if (requestParams.country.length === 0) {
      country.forEach((country) => {
        country.disabled = false
      })
      country.unshift({
        label: getStaticText('All_Country'),
        value: 'All_Country',
        disabled: false
      })
    } else {
      country.unshift({
        label: getStaticText('All_Country'),
        value: 'All_Country',
        disabled: true
      })
    }
    return country
  }, [requestParams])

  useEffect(() => {
    setUrlSearchForState()
    fetchData()
  }, [fetchData, setUrlSearchForState])

  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country
    }))
  }

  const handleDateChange = (date, dateString) => {
    setRequestParams((prevState) => ({
      ...prevState,
      start_date: date[0],
      start_format: dateString[0],
      end_date: date[1],
      end_format: dateString[1]
    }))
  }

  const handleReset = () => {
    setRequestParams({
      country: ['All_Country'],
      start_date: moment(),
      start_format: moment().format(dateFormatMonth),
      end_date: moment(),
      end_format: moment().format(dateFormatMonth)
    })
    countries.forEach((country) => {
      country.disabled = country.value !== 'GLOBAL'
    })
  }

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.time.text')}
          </Text>
          <RangePicker
            picker="month"
            value={[requestParams.start_date, requestParams.end_date]}
            format={dateFormatMonth}
            onChange={handleDateChange}
            allowClear={false}
          />
        </Space>
        <Space style={{ float: 'right' }}>
          <Button type="primary" onClick={handleReset}>
            {getStaticText('component.search.button.reset')}
          </Button>
        </Space>
      </Card>
      <Table
        size="small"
        bordered
        loading={isLoading}
        columns={columns}
        dataSource={tableData}
        rowKey={(record) => record.key}
        scroll={{ x: 'max-content', y: true }}
        sticky
      />
    </>
  )
}

CrowdLike.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired
}

export default CrowdLike
