export const storageSchema = [
  {
    key: 'userInfo',
    valueType: 'Object',
    storageType: 'local'
  },
  {
    key: 'config',
    valueType: 'Array',
    storageType: 'local'
  },
  {
    key: 'allowedCountries',
    valueType: 'Array',
    storageType: 'local'
  },
  {
    key: 'allowedPlatforms',
    valueType: 'Object',
    storageType: 'local'
  },
  {
    key: 'allowedPatterns',
    valueType: 'Array',
    storageType: 'local'
  },
  {
    key: 'disk_catalog_list',
    valueType: 'Array',
    storageType: 'local'
  },
  {
    key: 'currentScope',
    valueType: 'Object',
    storageType: 'local'
  },
  {
    key: 'allScopes',
    valueType: 'Array',
    storageType: 'local'
  },
  {
    key: 'i18n_schemas',
    valueType: 'Object',
    storageType: 'local'
  },
  {
    key: 'modular',
    valueType: 'String',
    storageType: 'local'
  },
  {
    key: 'channels',
    valueType: 'Array',
    storageType: 'local'
  }
]
