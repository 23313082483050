import React from 'react'
import { OptionColumn } from '../../../../../components'
import Actions from './Actions'

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    width: 100,
    align: 'center'
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    align: 'center',
    width: 100
  },
  {
    title: 'Product',
    dataIndex: 'product_name',
    key: 'product_name',
    width: 250,
    align: 'center'
  },
  {
    title: 'Customer email',
    dataIndex: 'sharer_email',
    key: 'sharer_email',
    width: 150,
    align: 'center'
  },
  {
    title: 'Share IP',
    dataIndex: 'sharer_ip',
    key: 'sharer_ip',
    width: 150,
    align: 'center'
  },
  {
    title: 'Customer name',
    align: 'center',
    width: 150,
    render: (data) => {
      return (
        <span>
          <span
            style={{
              display: 'inline-block',
              borderRadius: '50%',
              marginRight: '10px'
            }}
          >
            <img alt="" src={data.sharer_avatar} width="30" />
          </span>
          {data.sharer_name}
        </span>
      )
    }
  },
  {
    title: 'Link',
    dataIndex: 'link',
    key: 'link',
    align: 'center',
    width: 100,
    render: (link) => {
      return (
        <a href={link} target="_blank" rel="noreferrer">
          查看
        </a>
      )
    }
  },
  {
    title: 'Joint Times',
    dataIndex: 'joint_times',
    key: 'joint_times',
    width: 100,
    align: 'center'
  },
  {
    title: 'Need Cut Times',
    dataIndex: 'need_cut_times',
    key: 'need_cut_times',
    align: 'center',
    width: 150
  },
  {
    title: 'Share Time',
    dataIndex: 'created_at',
    key: 'created_at',
    align: 'center',
    width: 150
  },
  {
    title: 'Cutted price in total',
    dataIndex: 'cutted_price_in_total',
    key: 'cutted_price_in_total',
    align: 'center',
    width: 150
  },
  {
    title: 'Order No',
    dataIndex: 'order_no',
    key: 'order_no',
    width: 150,
    align: 'center'
  },
  {
    title: 'Status',
    dataIndex: 'share_status',
    align: 'center',
    key: 'share_status',
    width: 100,
    render: (value) => <OptionColumn source="cut_share_status" value={value} />
  },
  {
    title: 'Actions',
    key: 'x',
    fixed: 'right',
    align: 'center',
    width: 100,
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
