import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { getStaticText } from '../../utils'

const monthlyTableDashboardColumns = [
  {
    title: (
      <div>
        {getStaticText('weekly.sales.table.country')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('weekly.sales.table.country')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => getStaticText(data)
  },
  {
    title: (
      <div>
        {getStaticText('weekly.sales.table.platform')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('weekly.sales.table.platform')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'platform',
    key: 'platform',
    fixed: 'left',
    width: 120,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.year')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.processing_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.year')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'year',
    key: 'year',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.month')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.processing_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.month')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'month',
    key: 'month',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: getStaticText('weekly.sales.table.month_sales_amount_gmv'),
    key: 'month_sales_amount_gmv',
    children: [
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.month_sales_amount_completion_rate'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.month_sales_amount_completion_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.month_sales_amount_completion_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'month_sales_amount_completion_rate',
        key: 'month_sales_amount_completion_rate',
        width: 110,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.month_sales_amount_target')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.budget.import'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.month_sales_amount_target'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'month_sales_amount_target',
        key: 'month_sales_amount_target',
        width: 110,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.sales.amount'),
    key: 'sales.amount',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.sales_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sales_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount',
        key: 'sales_amount',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.sales_amount_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.sales_amount_percent_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sales_amount_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_percent',
        key: 'sales_amount_percent',
        width: 180,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.flow'),
    key: 'flow',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.uv')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.uv')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv',
        key: 'uv',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.app_uv')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.app_uv')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'app_uv',
        key: 'app_uv',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.all_uv')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.all_uv')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'all_uv',
        key: 'all_uv',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.month_active_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.month_active_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'month_active_users',
        key: 'month_active_users',
        width: 120,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.conversion.rate'),
    key: 'conversion.rate',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.conversion_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.conversion_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.conversion_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'conversion_rate',
        key: 'conversion_rate',
        width: 110,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.customer.price'),
    key: 'customer.price',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.customer_order_average')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.customer_order_average_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.customer_order_average')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'customer_order_average',
        key: 'customer_order_average',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.uv.worth'),
    key: 'uv.worth',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.uv_worth_1')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.uv_worth_1_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.uv_worth_1')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv_worth',
        key: 'uv_worth',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.crowd_management'),
    key: 'crowd_management',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.last_purchase_time')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.last_purchase_time_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.last_purchase_time_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.last_purchase_time_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.last_purchase_time')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'last_purchase_time',
        children: [
          {
            title: (
              <div>
                0-8月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.last_purchase_time_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.last_purchase_time_1')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'last_purchase_time_1',
            key: 'last_purchase_time_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                8-10月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.last_purchase_time_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.last_purchase_time_2')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'last_purchase_time_2',
            key: 'last_purchase_time_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                10-18月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.last_purchase_time_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.last_purchase_time_3')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'last_purchase_time_3',
            key: 'last_purchase_time_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.consumption_frequency')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.consumption_frequency_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.consumption_frequency_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.consumption_frequency_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.consumption_frequency')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'consumption_frequency',
        children: [
          {
            title: (
              <div>
                1次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.consumption_frequency_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.consumption_frequency_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'consumption_frequency_1',
            key: 'consumption_frequency_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.consumption_frequency_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.consumption_frequency_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'consumption_frequency_2',
            key: 'consumption_frequency_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                3次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.consumption_frequency_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.consumption_frequency_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'consumption_frequency_3',
            key: 'consumption_frequency_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.purchase_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText('weekly.sales.table.purchase_amount_note')}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_4'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_5'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.purchase_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'purchase_amount',
        children: [
          {
            title: (
              <div>
                70美元以下 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('weekly.sales.table.purchase_amount_1')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.purchase_amount_1')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'purchase_amount_1',
            key: 'purchase_amount_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                70-100美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('weekly.sales.table.purchase_amount_2')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.purchase_amount_2')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'purchase_amount_2',
            key: 'purchase_amount_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                100-150美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('weekly.sales.table.purchase_amount_4')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.purchase_amount_4')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'purchase_amount_3',
            key: 'purchase_amount_3',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                150美元以上 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('weekly.sales.table.purchase_amount_5')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.purchase_amount_5')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'purchase_amount_4',
            key: 'purchase_amount_4',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.after_buying_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.after_buying_rate_calculation_note_1'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.after_buying_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'after_buying_rate',
        key: 'after_buying_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.ratio_of_repurchase_cycle')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.ratio_of_repurchase_cycle_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.ratio_of_repurchase_cycle_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.ratio_of_repurchase_cycle_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.ratio_of_repurchase_cycle'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'ratio_of_repurchase_cycle',
        children: [
          {
            title: (
              <div>
                0-8月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.ratio_of_repurchase_cycle_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.ratio_of_repurchase_cycle_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'ratio_of_repurchase_cycle_1',
            key: 'ratio_of_repurchase_cycle_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                8-10月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.ratio_of_repurchase_cycle_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.ratio_of_repurchase_cycle_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'ratio_of_repurchase_cycle_2',
            key: 'ratio_of_repurchase_cycle_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                10-18月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.ratio_of_repurchase_cycle_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.ratio_of_repurchase_cycle_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'ratio_of_repurchase_cycle_3',
            key: 'ratio_of_repurchase_cycle_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.joint_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_explain_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sports_joint_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'joint_rate',
        key: 'joint_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.bad_review_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.bad_review_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.bad_review_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'bad_review_rate',
        align: 'center',
        children: [
          {
            title: (
              <div>
                差评详情 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('monthly.sales.table.bad_review_rate')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('monthly.sales.table.bad_review_rate')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'bad_review_rate',
            key: 'bad_review_rate',
            width: 120,
            align: 'center'
          }
        ]
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.phone_management'),
    key: 'phone_management',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_last_purchase_time')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.last_purchase_time_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.last_purchase_time_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.last_purchase_time_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_last_purchase_time')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_last_purchase_time',
        key: 'phone_last_purchase_time',
        children: [
          {
            title: (
              <div>
                0-8月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.last_purchase_time_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.last_purchase_time_1')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_last_purchase_time_1',
            key: 'phone_last_purchase_time_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                8-10月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.last_purchase_time_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.last_purchase_time_2')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_last_purchase_time_2',
            key: 'phone_last_purchase_time_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                10-18月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.last_purchase_time_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.last_purchase_time_3')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_last_purchase_time_3',
            key: 'phone_last_purchase_time_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_consumption_frequency')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.consumption_frequency_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.consumption_frequency_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.consumption_frequency_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.phone_consumption_frequency'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_consumption_frequency',
        key: 'phone_consumption_frequency',
        children: [
          {
            title: (
              <div>
                1次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.consumption_frequency_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.consumption_frequency_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_consumption_frequency_1',
            key: 'phone_consumption_frequency_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.consumption_frequency_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.consumption_frequency_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_consumption_frequency_2',
            key: 'phone_consumption_frequency_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                3次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.consumption_frequency_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.consumption_frequency_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_consumption_frequency_3',
            key: 'phone_consumption_frequency_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_purchase_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText('weekly.sales.table.purchase_amount_note')}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_4'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_5'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_purchase_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'phone_purchase_amount',
        children: [
          {
            title: (
              <div>
                70美元以下 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('weekly.sales.table.purchase_amount_1')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.purchase_amount_1')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_purchase_amount_1',
            key: 'phone_purchase_amount_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                70-100美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('weekly.sales.table.purchase_amount_2')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.purchase_amount_2')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_purchase_amount_2',
            key: 'phone_purchase_amount_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                100-150美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('weekly.sales.table.purchase_amount_4')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.purchase_amount_4')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_purchase_amount_3',
            key: 'phone_purchase_amount_3',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                150美元以上 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('weekly.sales.table.purchase_amount_5')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('weekly.sales.table.purchase_amount_5')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_purchase_amount_4',
            key: 'phone_purchase_amount_4',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_after_buying_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.after_buying_rate_calculation_note_1'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_after_buying_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_after_buying_rate',
        key: 'phone_after_buying_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.phone_ratio_of_repurchase_cycle'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.ratio_of_repurchase_cycle_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.ratio_of_repurchase_cycle_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.ratio_of_repurchase_cycle_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.phone_ratio_of_repurchase_cycle'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'phone_ratio_of_repurchase_cycle',
        children: [
          {
            title: (
              <div>
                0-8月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.ratio_of_repurchase_cycle_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.ratio_of_repurchase_cycle_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_ratio_of_repurchase_cycle_1',
            key: 'phone_ratio_of_repurchase_cycle_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                8-10月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.ratio_of_repurchase_cycle_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.ratio_of_repurchase_cycle_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_ratio_of_repurchase_cycle_2',
            key: 'phone_ratio_of_repurchase_cycle_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                10-18月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.ratio_of_repurchase_cycle_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.ratio_of_repurchase_cycle_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_ratio_of_repurchase_cycle_3',
            key: 'phone_ratio_of_repurchase_cycle_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_joint_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_explain_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sports_joint_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_joint_rate',
        key: 'phone_joint_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_bad_review_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.bad_review_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_bad_review_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'phone_bad_review_rate',
        align: 'center',
        children: [
          {
            title: (
              <div>
                差评详情 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('monthly.sales.table.bad_review_rate')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('monthly.sales.table.bad_review_rate')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'phone_bad_review_rate',
            key: 'phone_bad_review_rate',
            width: 120,
            align: 'center'
          }
        ]
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.digital_peripherals_management'),
    key: 'digital_peripherals_management',
    children: [
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.digital_peripherals_last_purchase_time'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.digital_peripherals_last_purchase_time_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.digital_peripherals_last_purchase_time_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.digital_peripherals_last_purchase_time_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.digital_peripherals_last_purchase_time'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'digital_peripherals_last_purchase_time',
        children: [
          {
            title: (
              <div>
                0-3月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_last_purchase_time_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_last_purchase_time_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_last_purchase_time_1',
            key: 'digital_peripherals_last_purchase_time_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                3-6月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_last_purchase_time_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_last_purchase_time_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_last_purchase_time_2',
            key: 'digital_peripherals_last_purchase_time_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                6-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_last_purchase_time_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_last_purchase_time_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_last_purchase_time_3',
            key: 'digital_peripherals_last_purchase_time_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.digital_peripherals_consumption_frequency'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.digital_peripherals_consumption_frequency_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.digital_peripherals_consumption_frequency_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.digital_peripherals_consumption_frequency_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.digital_peripherals_consumption_frequency'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'digital_peripherals_consumption_frequency',
        children: [
          {
            title: (
              <div>
                1次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_consumption_frequency_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_consumption_frequency_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_consumption_frequency_1',
            key: 'digital_peripherals_consumption_frequency_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_consumption_frequency_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_consumption_frequency_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_consumption_frequency_2',
            key: 'digital_peripherals_consumption_frequency_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                3次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_consumption_frequency_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_consumption_frequency_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_consumption_frequency_3',
            key: 'digital_peripherals_consumption_frequency_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.digital_peripherals_purchase_amount'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText('weekly.sales.table.purchase_amount_note')}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_4'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_5'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.digital_peripherals_purchase_amount'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'digital_peripherals_purchase_amount',
        children: [
          {
            title: (
              <div>
                70美元以下 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_purchase_amount_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_purchase_amount_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_purchase_amount_1',
            key: 'digital_peripherals_purchase_amount_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                70-100美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_purchase_amount_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_purchase_amount_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_purchase_amount_2',
            key: 'digital_peripherals_purchase_amount_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                100-150美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_purchase_amount_4'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_purchase_amount_4'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_purchase_amount_3',
            key: 'digital_peripherals_purchase_amount_3',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                150美元以上 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_purchase_amount_5'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_purchase_amount_5'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_purchase_amount_4',
            key: 'digital_peripherals_purchase_amount_4',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.digital_peripherals_after_buying_rate'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.digital_peripherals_after_buying_rate_calculation_note_1'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.digital_peripherals_after_buying_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'digital_peripherals_after_buying_rate',
        key: 'digital_peripherals_after_buying_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'digital_peripherals_ratio_of_repurchase_cycle',
        children: [
          {
            title: (
              <div>
                0-3月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_ratio_of_repurchase_cycle_1',
            key: 'digital_peripherals_ratio_of_repurchase_cycle_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                3-6月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_ratio_of_repurchase_cycle_2',
            key: 'digital_peripherals_ratio_of_repurchase_cycle_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                6-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.digital_peripherals_ratio_of_repurchase_cycle_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_ratio_of_repurchase_cycle_3',
            key: 'digital_peripherals_ratio_of_repurchase_cycle_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.digital_peripherals_joint_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_explain_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sports_joint_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'digital_peripherals_joint_rate',
        key: 'digital_peripherals_joint_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.digital_peripherals_bad_review_rate'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.bad_review_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.digital_peripherals_bad_review_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'digital_peripherals_bad_review_rate',
        align: 'center',
        children: [
          {
            title: (
              <div>
                差评详情 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('monthly.sales.table.bad_review_rate')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('monthly.sales.table.bad_review_rate')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'digital_peripherals_bad_review_rate',
            key: 'digital_peripherals_bad_review_rate',
            width: 120,
            align: 'center'
          }
        ]
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.big_fashion_management'),
    key: 'big_fashion_management',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.big_fashion_last_purchase_time')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.big_fashion_last_purchase_time'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'big_fashion_last_purchase_time',
        children: [
          {
            title: (
              <div>
                0-1月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_last_purchase_time_1',
            key: 'big_fashion_last_purchase_time_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                1-2月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_last_purchase_time_2',
            key: 'big_fashion_last_purchase_time_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_last_purchase_time_3',
            key: 'big_fashion_last_purchase_time_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.big_fashion_consumption_frequency'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.big_fashion_consumption_frequency'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'big_fashion_consumption_frequency',
        children: [
          {
            title: (
              <div>
                1次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_consumption_frequency_1',
            key: 'big_fashion_consumption_frequency_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_consumption_frequency_2',
            key: 'big_fashion_consumption_frequency_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                3次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_consumption_frequency_3',
            key: 'big_fashion_consumption_frequency_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.big_fashion_purchase_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText('weekly.sales.table.purchase_amount_note')}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_4'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_5'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.big_fashion_purchase_amount'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'big_fashion_purchase_amount',
        children: [
          {
            title: (
              <div>
                70美元以下 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_purchase_amount_1',
            key: 'big_fashion_purchase_amount_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                70-100美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_purchase_amount_2',
            key: 'big_fashion_purchase_amount_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                100-150美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_4'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_4'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_purchase_amount_3',
            key: 'big_fashion_purchase_amount_3',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                150美元以上 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_5'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_5'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_purchase_amount_4',
            key: 'big_fashion_purchase_amount_4',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.big_fashion_after_buying_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_after_buying_rate_calculation_note_1'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.big_fashion_after_buying_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'big_fashion_after_buying_rate',
        key: 'big_fashion_after_buying_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'big_fashion_ratio_of_repurchase_cycle',
        children: [
          {
            title: (
              <div>
                0-1月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_ratio_of_repurchase_cycle_1',
            key: 'big_fashion_ratio_of_repurchase_cycle_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                1-2月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_ratio_of_repurchase_cycle_2',
            key: 'big_fashion_ratio_of_repurchase_cycle_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_ratio_of_repurchase_cycle_3',
            key: 'big_fashion_ratio_of_repurchase_cycle_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.big_fashion_joint_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_explain_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sports_joint_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'big_fashion_joint_rate',
        key: 'big_fashion_joint_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.big_fashion_bad_review_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.bad_review_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.big_fashion_bad_review_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'big_fashion_bad_review_rate',
        align: 'center',
        children: [
          {
            title: (
              <div>
                差评详情 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('monthly.sales.table.bad_review_rate')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('monthly.sales.table.bad_review_rate')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'big_fashion_bad_review_rate',
            key: 'big_fashion_bad_review_rate',
            width: 120,
            align: 'center'
          }
        ]
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.makeups_management'),
    key: 'makeups_management',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.makeups_last_purchase_time')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.makeups_last_purchase_time'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'makeups_last_purchase_time',
        children: [
          {
            title: (
              <div>
                0-1月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_last_purchase_time_1',
            key: 'makeups_last_purchase_time_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                1-2月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_last_purchase_time_2',
            key: 'makeups_last_purchase_time_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_last_purchase_time_3',
            key: 'makeups_last_purchase_time_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.makeups_consumption_frequency')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.makeups_consumption_frequency'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'makeups_consumption_frequency',
        children: [
          {
            title: (
              <div>
                1次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_consumption_frequency_1',
            key: 'makeups_consumption_frequency_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_consumption_frequency_2',
            key: 'makeups_consumption_frequency_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                3次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_consumption_frequency_3',
            key: 'makeups_consumption_frequency_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.makeups_purchase_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText('weekly.sales.table.purchase_amount_note')}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_4'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_5'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.makeups_purchase_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'makeups_purchase_amount',
        children: [
          {
            title: (
              <div>
                70美元以下 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_purchase_amount_1',
            key: 'makeups_purchase_amount_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                70-100美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_purchase_amount_2',
            key: 'makeups_purchase_amount_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                100-150美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_4'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_4'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_purchase_amount_3',
            key: 'makeups_purchase_amount_3',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                150美元以上 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_5'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_5'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_purchase_amount_4',
            key: 'makeups_purchase_amount_4',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.makeups_after_buying_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_after_buying_rate_calculation_note_1'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.makeups_after_buying_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'makeups_after_buying_rate',
        key: 'makeups_after_buying_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.makeups_ratio_of_repurchase_cycle'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.makeups_ratio_of_repurchase_cycle'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'makeups_ratio_of_repurchase_cycle',
        children: [
          {
            title: (
              <div>
                0-1月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_ratio_of_repurchase_cycle_1',
            key: 'makeups_ratio_of_repurchase_cycle_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                1-2月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_ratio_of_repurchase_cycle_2',
            key: 'makeups_ratio_of_repurchase_cycle_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_ratio_of_repurchase_cycle_3',
            key: 'makeups_ratio_of_repurchase_cycle_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.makeups_joint_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_explain_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sports_joint_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'makeups_joint_rate',
        key: 'makeups_joint_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.makeups_bad_review_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.bad_review_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.makeups_bad_review_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'makeups_bad_review_rate',
        align: 'center',
        children: [
          {
            title: (
              <div>
                差评详情 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('monthly.sales.table.bad_review_rate')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('monthly.sales.table.bad_review_rate')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'makeups_bad_review_rate',
            key: 'makeups_bad_review_rate',
            width: 120,
            align: 'center'
          }
        ]
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.sports_management'),
    key: 'sports_management',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.sports_last_purchase_time')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.sports_last_purchase_time'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'sports_last_purchase_time',
        children: [
          {
            title: (
              <div>
                0-1月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_last_purchase_time_1',
            key: 'sports_last_purchase_time_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                1-2月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_last_purchase_time_2',
            key: 'sports_last_purchase_time_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_last_purchase_time_3',
            key: 'sports_last_purchase_time_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.sports_consumption_frequency')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.sports_consumption_frequency'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'sports_consumption_frequency',
        children: [
          {
            title: (
              <div>
                1次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_consumption_frequency_1',
            key: 'sports_consumption_frequency_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_consumption_frequency_2',
            key: 'sports_consumption_frequency_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                3次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_consumption_frequency_3',
            key: 'sports_consumption_frequency_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_purchase_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText('weekly.sales.table.purchase_amount_note')}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_4'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_5'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_purchase_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'sports_purchase_amount',
        children: [
          {
            title: (
              <div>
                70美元以下 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_purchase_amount_1',
            key: 'sports_purchase_amount_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                70-100美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_purchase_amount_2',
            key: 'sports_purchase_amount_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                100-150美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_4'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_4'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_purchase_amount_3',
            key: 'sports_purchase_amount_3',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                150美元以上 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_5'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_5'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_purchase_amount_4',
            key: 'sports_purchase_amount_4',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.sports_after_buying_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_after_buying_rate_calculation_note_1'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sports_after_buying_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sports_after_buying_rate',
        key: 'sports_after_buying_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.sports_ratio_of_repurchase_cycle'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.sports_ratio_of_repurchase_cycle'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'sports_ratio_of_repurchase_cycle',
        children: [
          {
            title: (
              <div>
                0-1月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_ratio_of_repurchase_cycle_1',
            key: 'sports_ratio_of_repurchase_cycle_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                1-2月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_ratio_of_repurchase_cycle_2',
            key: 'sports_ratio_of_repurchase_cycle_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_ratio_of_repurchase_cycle_3',
            key: 'sports_ratio_of_repurchase_cycle_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.sports_joint_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_explain_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sports_joint_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sports_joint_rate',
        key: 'sports_joint_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.sports_bad_review_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.bad_review_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sports_bad_review_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'sports_bad_review_rate',
        align: 'center',
        children: [
          {
            title: (
              <div>
                差评详情 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('monthly.sales.table.bad_review_rate')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('monthly.sales.table.bad_review_rate')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'sports_bad_review_rate',
            key: 'sports_bad_review_rate',
            width: 120,
            align: 'center'
          }
        ]
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.baby_toys_management'),
    key: 'baby_toys_management',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.baby_toys_last_purchase_time')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.baby_toys_last_purchase_time'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'baby_toys_last_purchase_time',
        children: [
          {
            title: (
              <div>
                0-1月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_last_purchase_time_1',
            key: 'baby_toys_last_purchase_time_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                1-2月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_last_purchase_time_2',
            key: 'baby_toys_last_purchase_time_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_last_purchase_time_3',
            key: 'baby_toys_last_purchase_time_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.baby_toys_consumption_frequency'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.baby_toys_consumption_frequency'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'baby_toys_consumption_frequency',
        children: [
          {
            title: (
              <div>
                1次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_consumption_frequency_1',
            key: 'baby_toys_consumption_frequency_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_consumption_frequency_2',
            key: 'baby_toys_consumption_frequency_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                3次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_consumption_frequency_3',
            key: 'baby_toys_consumption_frequency_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_purchase_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText('weekly.sales.table.purchase_amount_note')}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_4'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_5'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_purchase_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'baby_toys_purchase_amount',
        children: [
          {
            title: (
              <div>
                70美元以下 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_purchase_amount_1',
            key: 'baby_toys_purchase_amount_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                70-100美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_purchase_amount_2',
            key: 'baby_toys_purchase_amount_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                100-150美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_4'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_4'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_purchase_amount_3',
            key: 'baby_toys_purchase_amount_3',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                150美元以上 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_5'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_5'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_purchase_amount_4',
            key: 'baby_toys_purchase_amount_4',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.baby_toys_after_buying_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_after_buying_rate_calculation_note_1'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.baby_toys_after_buying_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'baby_toys_after_buying_rate',
        key: 'baby_toys_after_buying_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.baby_toys_ratio_of_repurchase_cycle'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.baby_toys_ratio_of_repurchase_cycle'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'baby_toys_ratio_of_repurchase_cycle',
        children: [
          {
            title: (
              <div>
                0-1月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_ratio_of_repurchase_cycle_1',
            key: 'baby_toys_ratio_of_repurchase_cycle_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                1-2月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_ratio_of_repurchase_cycle_2',
            key: 'baby_toys_ratio_of_repurchase_cycle_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_ratio_of_repurchase_cycle_3',
            key: 'baby_toys_ratio_of_repurchase_cycle_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.baby_toys_joint_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_explain_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sports_joint_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'baby_toys_joint_rate',
        key: 'baby_toys_joint_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.baby_toys_bad_review_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.bad_review_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.baby_toys_bad_review_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'baby_toys_bad_review_rate',
        align: 'center',
        children: [
          {
            title: (
              <div>
                差评详情 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('monthly.sales.table.bad_review_rate')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('monthly.sales.table.bad_review_rate')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'baby_toys_bad_review_rate',
            key: 'baby_toys_bad_review_rate',
            width: 120,
            align: 'center'
          }
        ]
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.other_management'),
    key: 'other_management',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.other_last_purchase_time')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_last_purchase_time_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.other_last_purchase_time')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'other_last_purchase_time',
        children: [
          {
            title: (
              <div>
                0-1月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_last_purchase_time_1',
            key: 'other_last_purchase_time_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                1-2月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_last_purchase_time_2',
            key: 'other_last_purchase_time_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_last_purchase_time_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_last_purchase_time_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_last_purchase_time_3',
            key: 'other_last_purchase_time_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.other_consumption_frequency')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_consumption_frequency_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.other_consumption_frequency'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'other_consumption_frequency',
        children: [
          {
            title: (
              <div>
                1次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_consumption_frequency_1',
            key: 'other_consumption_frequency_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_consumption_frequency_2',
            key: 'other_consumption_frequency_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                3次 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_consumption_frequency_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_consumption_frequency_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_consumption_frequency_3',
            key: 'other_consumption_frequency_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_purchase_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText('weekly.sales.table.purchase_amount_note')}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_4'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.purchase_amount_calculation_note_5'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_purchase_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'other_purchase_amount',
        children: [
          {
            title: (
              <div>
                70美元以下 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_purchase_amount_1',
            key: 'other_purchase_amount_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                70-100美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_purchase_amount_2',
            key: 'other_purchase_amount_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                100-150美元 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_4'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_4'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_purchase_amount_3',
            key: 'other_purchase_amount_3',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                150美元以上 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_purchase_amount_5'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_purchase_amount_5'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_purchase_amount_4',
            key: 'other_purchase_amount_4',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.other_after_buying_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_after_buying_rate_calculation_note_1'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.other_after_buying_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'other_after_buying_rate',
        key: 'other_after_buying_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.other_ratio_of_repurchase_cycle'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_1'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_2'
                    )}
                  </p>
                  <br />
                  <p>
                    {getStaticText(
                      'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_calculation_note_3'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.other_ratio_of_repurchase_cycle'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'other_ratio_of_repurchase_cycle',
        children: [
          {
            title: (
              <div>
                0-1月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_1'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_ratio_of_repurchase_cycle_1',
            key: 'other_ratio_of_repurchase_cycle_1',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                1-2月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_2'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_ratio_of_repurchase_cycle_2',
            key: 'other_ratio_of_repurchase_cycle_2',
            width: 120,
            align: 'center'
          },
          {
            title: (
              <div>
                2-12月 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText(
                          'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3'
                        )}
                      </p>
                    </div>
                  )}
                  key={getStaticText(
                    'weekly.sales.table.big_fashion_ratio_of_repurchase_cycle_3'
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_ratio_of_repurchase_cycle_3',
            key: 'other_ratio_of_repurchase_cycle_3',
            width: 120,
            align: 'center'
          }
        ]
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.other_joint_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_explain_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.joint_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.sports_joint_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'other_joint_rate',
        key: 'other_joint_rate',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.other_bad_review_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>{getStaticText('weekly.sales.table.calculation_note')}</p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.bad_review_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.other_bad_review_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        key: 'other_bad_review_rate',
        align: 'center',
        children: [
          {
            title: (
              <div>
                差评详情 &nbsp;
                <Tooltip
                  title={() => (
                    <div className="toolTipStyle">
                      <p>
                        {getStaticText('monthly.sales.table.bad_review_rate')}
                      </p>
                    </div>
                  )}
                  key={getStaticText('monthly.sales.table.bad_review_rate')}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            ),
            dataIndex: 'other_bad_review_rate',
            key: 'other_bad_review_rate',
            width: 120,
            align: 'center'
          }
        ]
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.order'),
    key: 'order',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.dashboard.table.total_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.dashboard.table.total_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_orders',
        key: 'total_orders',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.total_online_paid_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.total_online_paid_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.total_online_paid_percent'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_online_paid_percent',
        key: 'total_online_paid_percent',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.dashboard.table.total_non_transsion_percent'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.dashboard.table.total_non_transsion_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.dashboard.table.total_non_transsion_percent'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_non_transsion_percent',
        key: 'total_non_transsion_percent',
        width: 120,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.phone.sales'),
    key: 'phone.sales',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_sales_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.phone_sales_amount_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_sales_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_sales_amount',
        key: 'phone_sales_amount',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_sales_volume')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.phone_sales_volume_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_sales_volume')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_sales_volume',
        key: 'phone_sales_volume',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.phone_sales_average')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.phone_sales_average_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.phone_sales_average')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_sales_average',
        key: 'phone_sales_average',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.dashboard.table.product.supply'),
    key: 'product.supply',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.total_online_product')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.total_online_product')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_online_product',
        key: 'total_online_product',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.active_product_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.active_product_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.active_product_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'active_product_percent',
        key: 'active_product_percent',
        width: 110,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.dashboard.table.total_cross_border_product'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.dashboard.table.total_cross_border_product'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_cross_border_product',
        key: 'total_cross_border_product',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.dashboard.table.total_cross_border_product_percent'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.total_cross_border_product_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.dashboard.table.total_cross_border_product_percent'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_cross_border_product_percent',
        key: 'total_cross_border_product_percent',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.dashboard.table.products_others_brand_percent'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.products_others_brand_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.dashboard.table.products_others_brand_percent'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'products_others_brand_percent',
        key: 'products_others_brand_percent',
        width: 140,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.ad.fb'),
    key: 'ad.fb',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.ad_fb_consumption')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.ad_fb_consumption')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_fb_consumption',
        key: 'ad_fb_consumption',
        width: 130,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.ad_fb_sales_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.ad_fb_sales_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_fb_sales_amount',
        key: 'ad_fb_sales_amount',
        width: 130,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.ad_fb_roi')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.ad_google_roi_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.ad_fb_roi')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_fb_roi',
        key: 'ad_fb_roi',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.ad.google'),
    key: 'ad.google',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.ad_google_consumption')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.ad_google_consumption')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_google_consumption',
        key: 'ad_google_consumption',
        width: 130,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.ad_google_sales_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.ad_google_sales_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_google_sales_amount',
        key: 'ad_google_sales_amount',
        width: 130,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.ad_google_roi')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.ad_google_roi_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.ad_google_roi')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_google_roi',
        key: 'ad_google_roi',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.dashboard.table.ad_percentage'),
    key: 'ad_percentage',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.dm_total')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText('weekly.dashboard.sales.table.dm_total')}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.dm_total')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'dm_total',
        key: 'dm_total',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.dashboard.table.dm_gmv_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.dm_gmv_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.dashboard.table.dm_gmv_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'dm_gmv_percent',
        key: 'dm_gmv_percent',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.fb_score')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.fb_score')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'fb_score',
        key: 'fb_score',
        width: 100,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.xpark.cumulative_register_totals'),
    key: 'xpark_cumulative_register_totals',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.cumulative_register_totals')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.month.cumulative_register_totals_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.cumulative_register_totals'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cumulative_register_totals',
        key: 'cumulative_register_totals',
        width: 150,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.APP.data'),
    key: 'APP.data',
    children: [
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.xpark_use_time')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.xpark_use_time')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'xpark_use_time',
        key: 'xpark_use_time',
        width: 200,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.old_user_ratio')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.old_user_ratio')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'old_user_ratio',
        key: 'old_user_ratio',
        width: 100,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.accumu_preassem_count')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.accumu_preassem_count')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'accumu_preassem_count',
        key: 'accumu_preassem_count',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.current_month_preassem_count')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'monthly.sales.table.current_month_preassem_count'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'current_month_preassem_count',
        key: 'current_month_preassem_count',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.app_activation_number')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.app_activation_number')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'app_activation_number',
        key: 'app_activation_number',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.all_active_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.all_active_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'all_active_users',
        key: 'all_active_users',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.app_active_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.app_active_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'app_active_users',
        key: 'app_active_users',
        width: 140,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.private_pool_data'),
    key: 'private_pool_data',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.cumulative_of_fans')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.cumulative_of_fans')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cumulative_of_fans',
        key: 'cumulative_of_fans',
        width: 120,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.new_of_fans')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.new_of_fans')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'new_of_fans',
        key: 'new_of_fans',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.cumulative_of_fan_base')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.cumulative_of_fan_base')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cumulative_of_fan_base',
        key: 'cumulative_of_fan_base',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.new_of_fan_base')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.new_of_fan_base')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'new_of_fan_base',
        key: 'new_of_fan_base',
        width: 170,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.cumulative_of_portrait_short_video'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.cumulative_of_portrait_short_video'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cumulative_of_portrait_short_video',
        key: 'cumulative_of_portrait_short_video',
        width: 170,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.new_of_portrait_short_video')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.new_of_portrait_short_video'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'new_of_portrait_short_video',
        key: 'new_of_portrait_short_video',
        width: 190,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.new_media_data'),
    key: 'new_media_data',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.cumulative_of_kol_cooperation')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.cumulative_of_kol_cooperation'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cumulative_of_kol_cooperation',
        key: 'cumulative_of_kol_cooperation',
        width: 150,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.new_of_kol_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.new_of_kol_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'new_of_kol_users',
        key: 'new_of_kol_users',
        width: 150,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.cumulative_of_kol_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.cumulative_of_kol_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cumulative_of_kol_orders',
        key: 'cumulative_of_kol_orders',
        width: 160,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.kol_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.kol_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'kol_orders',
        key: 'kol_orders',
        width: 160,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.roi_new_social_media')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.roi_new_social_media')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'roi_new_social_media',
        key: 'roi_new_social_media',
        width: 170,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.uv_new_social_media')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.uv_new_social_media')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv_new_social_media',
        key: 'uv_new_social_media',
        width: 160,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.delivery_data'),
    key: 'delivery_data',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.total_xp_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.total_xp_users_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.total_xp_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_xp_users',
        key: 'total_xp_users',
        width: 130,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.new_of_xp_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.new_of_xp_users_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.new_of_xp_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'new_of_xp_users',
        key: 'new_of_xp_users',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.cumulative_of_xp_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.cumulative_of_xp_orders_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.cumulative_of_xp_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cumulative_of_xp_orders',
        key: 'cumulative_of_xp_orders',
        width: 150,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.complete_of_xp_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.complete_of_xp_orders_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.complete_of_xp_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'complete_of_xp_orders',
        key: 'complete_of_xp_orders',
        width: 150,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'weekly.sales.table.cumulative_of_complete_xp_orders'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.cumulative_of_complete_xp_orders_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'weekly.sales.table.cumulative_of_complete_xp_orders'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cumulative_of_complete_xp_orders',
        key: 'cumulative_of_complete_xp_orders',
        width: 220,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText(
              'monthly.sales.table.complete_of_xp_orders_in_xpark'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.complete_of_xp_orders_in_xpark_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'monthly.sales.table.complete_of_xp_orders_in_xpark'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'complete_of_xp_orders_in_xpark',
        key: 'complete_of_xp_orders_in_xpark',
        width: 220,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.new_users_with_old_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.new_users_with_old_users_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'monthly.sales.table.new_users_with_old_users'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'new_users_with_old_users',
        key: 'new_users_with_old_users',
        width: 150,
        align: 'center'
      }
    ]
  },
  {
    title: getStaticText('weekly.sales.table.pp_data'),
    key: 'pp_data',
    children: [
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.complete_of_pp_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.complete_of_pp_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'complete_of_pp_users',
        key: 'complete_of_pp_users',
        width: 130,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.new_of_pp_users')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.new_of_pp_users')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'new_of_pp_users',
        key: 'new_of_pp_users',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('weekly.sales.table.complete_of_pp_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'weekly.sales.table.complete_of_pp_orders_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('weekly.sales.table.complete_of_pp_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'complete_of_pp_orders',
        key: 'complete_of_pp_orders',
        width: 170,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.pp_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.pp_orders_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('monthly.sales.table.pp_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders',
        key: 'pp_orders',
        width: 170,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('monthly.sales.table.pp_orders_execution_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.pp_orders_execution_rate_explain_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'monthly.sales.table.pp_orders_execution_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'monthly.sales.table.pp_orders_execution_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_execution_rate',
        key: 'pp_orders_execution_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_cancel_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_cancel_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_cancel_rate',
        key: 'pp_orders_cancel_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_dealt_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_dealt_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_dealt_rate',
        key: 'pp_orders_dealt_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_return_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_return_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_return_rate',
        key: 'pp_orders_return_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_phone_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_phone_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_phone_rate',
        key: 'pp_orders_phone_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_shiped_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_shiped_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_shiped_rate',
        key: 'pp_orders_shiped_rate',
        width: 140,
        align: 'center'
      },
      {
        title: (
          <div>
            {getStaticText('pp_orders_ship_delay_days')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('pp_orders_ship_delay_days')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pp_orders_ship_delay_days',
        key: 'pp_orders_ship_delay_days',
        width: 140,
        align: 'center'
      }
    ]
  }
]

export default monthlyTableDashboardColumns
