import React, { useEffect, useState, useContext } from 'react'
import { Form, Row, Col, Input, Checkbox } from 'antd'
import { GridFormDrawer, GridContext, SourceSelect } from '../../../components'

import {
  getPriceCompareDetail,
  createPriceCompare,
  updatePriceCompare,
  getCountryConfigs
} from '../services'

const PriceCompareForm = () => {
  const [form] = Form.useForm()
  const [formData, setFormData] = useState({})
  const [configs, setConfigs] = useState(false)
  const [country, setCountry] = useState('NG')

  const initForm = (data) => {
    setFormData(data)
    setCountry(data.country)
  }

  const openHandler = getPriceCompareDetail(initForm)
  const { record, toggle, onLoad } = useContext(GridContext)
  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }

  const emptyForm = () => {
    initForm({})
    form.resetFields()
  }

  const onFinish = (values) => {
    let close = true
    if (formData.id) {
      values.platforms.forEach((p) => {
        if (!values[p]) {
          close = false
        }
      })
      updatePriceCompare(close ? closeDrawer : initForm)({
        data: values,
        id: formData.id
      })
    } else {
      createPriceCompare(initForm)({ data: values })
    }
  }

  useEffect(() => {
    getCountryConfigs(setConfigs)()
  }, [setConfigs])

  useEffect(() => {
    form.setFieldsValue(formData)
  }, [form, formData])

  const countryChanged = (c) => {
    setCountry(c)
  }

  const platformChanged = () => {
    if (formData.platform_configs) {
      form.submit()
    }
  }

  return (
    <GridFormDrawer
      onOpen={(record) => (record.id ? openHandler(record.id) : emptyForm())}
      onSave={() => form.submit()}
      type="edit|create"
      title={(record) => {
        return record.id ? 'Edit Price Comparison' : 'Create Price Comparison'
      }}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        {configs && (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Countries"
                  name="country"
                  rules={[{ required: true, message: 'Please select country' }]}
                >
                  <SourceSelect
                    disabled={record.id}
                    onChange={countryChanged}
                    source="toolkit_price_compare_counties"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="name" label="Product Name">
                  <Input placeholder="Please enter product name" />
                </Form.Item>
              </Col>
            </Row>
            {configs[country] && (
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="platforms" label="Platforms">
                    <Checkbox.Group>
                      {configs[country].map((c) => {
                        return (
                          <Checkbox
                            value={c}
                            style={{ lineHeight: '32px' }}
                            onChange={platformChanged}
                          >
                            {c}
                          </Checkbox>
                        )
                      })}
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
            )}
            {formData.platform_configs && <hr />}
            {formData.platform_configs &&
              formData.platform_configs.map((conf) => {
                return (
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name={conf.platform}
                        label={`${conf.platform} URL`}
                        validateStatus={conf.memo ? 'error' : false}
                        help={conf.memo}
                      >
                        <Input addonBefore={conf.domain} />
                      </Form.Item>
                    </Col>
                  </Row>
                )
              })}
          </>
        )}
      </Form>
    </GridFormDrawer>
  )
}
export default PriceCompareForm
