import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getTagsList = (handler) =>
  withTableLoading((params) => services.bbs.getTagsList(params).then(handler))

export const deleteTags = (handler) =>
  withGlobalLoading((id) => services.bbs.deleteTags(id).then(handler))

export const createTags = (handler) =>
  withGlobalLoading((params) => services.bbs.createTags(params).then(handler))

export const saveTags = (handler) =>
  withGlobalLoading((params) => services.bbs.saveTags(params).then(handler))
