import React, { useContext, useState } from 'react'
import BraftEditor from 'braft-editor'
import {
  Tabs,
  Form,
  Row,
  Col,
  Input,
  Select,
  Tooltip,
  Button,
  Switch
} from 'antd'
import { connect } from 'react-redux'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import {
  GridFormDrawer,
  GridContext,
  SearchSelect
} from '../../../../components'
import CountryProduct from '../Product/CountryProduct'
import SelectProduct from '../Product/SelectProduct'
import {
  getProductsForMembership,
  getPrivilegeConfig,
  getActivityRules,
  savePrivilegeConfig,
  uploadFileToServer
} from '../services'

const { TabPane } = Tabs
const { Option } = Select

interface Props {
  allowedCountries: Array;
}

const NewReceiveForm = ({ allCountries, allowedCountries }: Props) => {
  const defaultTargetConfig = {
    title: '',
    description: '',
    cond_type: 'order',
    cond_value: '',
    target_type: 'coupon',
    target_value: ''
  }
  const defaultRuleConfig = {
    title: '',
    description: '',
    rule: ''
  }

  const { onLoad, toggle, record } = useContext(GridContext)
  const [data, setData] = useState({ target_config: [defaultTargetConfig] })
  const [useEditor, setUseEditor] = useState(false)
  const [products, setProducts] = useState({})
  const [activeKey, setActiveKey] = useState(allowedCountries[0])
  const [richtext, setRichText] = useState('')
  const [editorState, setEditorState] = useState(
    BraftEditor.createEditorState(null)
  )

  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }
  const initGridData = {
    current_page: 1,
    data: [],
    page_size: 10,
    total: 0
  }

  const switchTab = (key) => {
    if (key) {
      setActiveKey(key)
    } else {
      key = activeKey
    }
    getPrivilegeConfig((ret) => {
      const newData = { ...data, [key]: ret.configs }
      setData(newData)
      setUseEditor(!!ret.configs.use_editor)
      setRichText(ret.configs.content)
      setEditorState(
        ret.configs.content
          ? BraftEditor.createEditorState(ret.configs.content)
          : BraftEditor.createEditorState(null)
      )
    })({ code: record.pr, country: key })

    getProductsForMembership((ret) => {
      setProducts({ ...products, [key]: ret })
    })({ code: record.code ? record.code : '', country: key, type: record.pr })
  }

  const paginationHandler = (current, pageSize) => {
    getProductsForMembership((ret) => {
      setData({ ...products, [activeKey]: ret })
    })({
      country: activeKey,
      code: record.code,
      type: record.pr,
      page: current,
      page_size: pageSize
    })
  }

  const uploadMedia = (param) => {
    const fd = new FormData()
    fd.append('file', param.file)
    uploadFileToServer({ params: fd })
      .then((res) => {
        param.success({
          url: res.url,
          meta: {
            controls: true,
            poster: res.video_preview
          }
        })
      })
      .catch(() => {
        param.error({
          msg: 'unable to upload.'
        })
      })
  }

  const switchEditor = (e) => {
    setUseEditor(e)
    if (e === false) {
      setRichText(editorState.toHTML())
    } else {
      setEditorState(BraftEditor.createEditorState(richtext))
    }
  }

  const onFinish = () => {
    savePrivilegeConfig(closeDrawer)({
      code: record.pr,
      country: activeKey,
      configs: {
        ...data[activeKey],
        use_editor: useEditor ? 1 : 0,
        content: useEditor ? editorState.toHTML() : richtext
      }
    })
  }

  const addNewRuleRow = () => {
    setData({
      ...data,
      [activeKey]: {
        ...data[activeKey],
        rules:
          data[activeKey] && data[activeKey].rules
            ? [...data[activeKey].rules, defaultRuleConfig]
            : [defaultTargetConfig, defaultRuleConfig]
      }
    })
  }

  const deleteRuleRow = (ind) => {
    const rows = data[activeKey].rules
    rows.splice(ind, 1)
    setData({
      ...data,
      [activeKey]: {
        ...data[activeKey],
        rules: rows
      }
    })
  }

  const addNewRow = () => {
    setData({
      ...data,
      [activeKey]: {
        ...data[activeKey],
        target_config:
          data[activeKey] && data[activeKey].target_config
            ? [...data[activeKey].target_config, defaultTargetConfig]
            : [defaultTargetConfig, defaultTargetConfig]
      }
    })
  }

  const deleteRow = (ind) => {
    const rows = data[activeKey].target_config
    rows.splice(ind, 1)
    setData({
      ...data,
      [activeKey]: {
        ...data[activeKey],
        target_config: rows
      }
    })
  }

  const ruleItemChange = (activeKey, ind, col, val) => {
    const config = (data[activeKey] || {}).rules || [defaultRuleConfig]
    if (typeof val === 'object' && typeof val.target !== 'undefined') {
      config[ind][col] = val.target.value
    } else {
      config[ind][col] = val
    }
    setData({
      ...data,
      [activeKey]: {
        ...data[activeKey],
        rules: config
      }
    })
  }

  const ruleItemValue = (activeKey, ind, col) =>
    ((data[activeKey] || {}).rules || [defaultRuleConfig])[ind][col]

  const itemChange = (activeKey, ind, col, val) => {
    const config = (data[activeKey] || {}).target_config || [
      defaultTargetConfig
    ]
    if (typeof val === 'object' && typeof val.target !== 'undefined') {
      config[ind][col] = val.target.value
    } else {
      config[ind][col] = val
    }
    setData({
      ...data,
      [activeKey]: {
        ...data[activeKey],
        target_config: config
      }
    })
  }

  const itemValue = (activeKey, ind, col) =>
    ((data[activeKey] || {}).target_config || [defaultTargetConfig])[ind][col]

  return (
    <>
      <GridFormDrawer
        onOpen={() => {
          switchTab(activeKey)
        }}
        onSave={onFinish}
        type="coupon_pr_member_new|coupon_pr_member_products|coupon_pr_lucky_draw|coupon_pr_crowd_founding|coupon_pr_free_purchase|coupon_pr_voucher|coupon_pr_member_price|coupon_pr_new_trail"
        title={`Settings for ${record.pr}`}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          // form={form}
          // onFinish={onFinish}
          // onFieldsChange={handleFormChange}
          autoComplete="off"
        >
          <Tabs activeKey={activeKey} type="card" onChange={switchTab}>
            {allCountries
              .filter((x) => allowedCountries.indexOf(x.countryCode) !== -1)
              .map((item) => (
                <TabPane tab={item.name} key={item.countryCode}>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        label="优惠券规则"
                        rules={[
                          { required: true, message: 'Please enter rule id' }
                        ]}
                      >
                        <Col span={24}>
                          {(
                            (data[activeKey] || {}).rules || [defaultRuleConfig]
                          ).map((tconf, ind) => (
                            <Row gutter="24" style={{ marginBottom: '10px' }}>
                              <Col span={24}>
                                <Input.Group compact>
                                  <Input
                                    style={{ width: '25%' }}
                                    placeholder="目标名称"
                                    value={ruleItemValue(
                                      activeKey,
                                      ind,
                                      'title'
                                    )}
                                    onChange={(v) => {
                                      ruleItemChange(activeKey, ind, 'title', v)
                                    }}
                                  />
                                  <Input
                                    style={{ width: '25%' }}
                                    placeholder="目标描述"
                                    value={ruleItemValue(
                                      activeKey,
                                      ind,
                                      'description'
                                    )}
                                    onChange={(v) => {
                                      ruleItemChange(
                                        activeKey,
                                        ind,
                                        'description',
                                        v
                                      )
                                    }}
                                  />
                                  <SearchSelect
                                    style={{ width: '15%' }}
                                    showSearch
                                    country={item.countryCode}
                                    fetchApi={getActivityRules}
                                    idField="rule_id"
                                    labelRender={(item) => item.name}
                                    value={ruleItemValue(
                                      activeKey,
                                      ind,
                                      'rule'
                                    )}
                                    onChange={(v) => {
                                      ruleItemChange(activeKey, ind, 'rule', v)
                                    }}
                                  />
                                  {ind === 0 && (
                                    <Tooltip title="添加行">
                                      <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={addNewRuleRow}
                                      />
                                    </Tooltip>
                                  )}
                                  {ind !== 0 && (
                                    <Tooltip title="删除行">
                                      <Button
                                        icon={<MinusOutlined />}
                                        onClick={() => deleteRuleRow(ind)}
                                      />
                                    </Tooltip>
                                  )}
                                </Input.Group>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item
                        label="目标达成模块"
                        rules={[
                          { required: true, message: 'Please enter rule id' }
                        ]}
                      >
                        <Col span={24}>
                          {(
                            (data[activeKey] || {}).target_config || [
                              defaultTargetConfig
                            ]
                          ).map((tconf, ind) => (
                            <Row gutter="24" style={{ marginBottom: '10px' }}>
                              <Col span={24}>
                                <Input.Group compact>
                                  <Input
                                    style={{ width: '25%' }}
                                    placeholder="目标名称"
                                    value={itemValue(activeKey, ind, 'title')}
                                    onChange={(v) => {
                                      itemChange(activeKey, ind, 'title', v)
                                    }}
                                  />
                                  <Input
                                    style={{ width: '25%' }}
                                    placeholder="目标描述"
                                    value={itemValue(
                                      activeKey,
                                      ind,
                                      'description'
                                    )}
                                    onChange={(v) => {
                                      itemChange(
                                        activeKey,
                                        ind,
                                        'description',
                                        v
                                      )
                                    }}
                                  />
                                  <Select
                                    defaultValue="order"
                                    style={{ width: '10%' }}
                                    value={itemValue(
                                      activeKey,
                                      ind,
                                      'cond_type'
                                    )}
                                    onChange={(v) => {
                                      itemChange(activeKey, ind, 'cond_type', v)
                                    }}
                                  >
                                    <Option value="order">订单数大于</Option>
                                    <Option value="points">积分数大于</Option>
                                  </Select>
                                  <Input
                                    style={{ width: '10%' }}
                                    value={itemValue(
                                      activeKey,
                                      ind,
                                      'cond_value'
                                    )}
                                    onChange={(v) => {
                                      itemChange(
                                        activeKey,
                                        ind,
                                        'cond_value',
                                        v
                                      )
                                    }}
                                  />
                                  <Select
                                    defaultValue="coupon"
                                    style={{ width: '10%' }}
                                    value={itemValue(
                                      activeKey,
                                      ind,
                                      'target_type'
                                    )}
                                    onChange={(v) => {
                                      itemChange(
                                        activeKey,
                                        ind,
                                        'target_type',
                                        v
                                      )
                                    }}
                                  >
                                    <Option value="coupon">发放优惠券</Option>
                                  </Select>
                                  <SearchSelect
                                    style={{ width: '15%' }}
                                    showSearch
                                    country={item.countryCode}
                                    fetchApi={getActivityRules}
                                    idField="rule_id"
                                    labelRender={(item) => item.name}
                                    value={itemValue(activeKey, ind, 'rule_id')}
                                    onChange={(v) => {
                                      itemChange(activeKey, ind, 'rule_id', v)
                                    }}
                                  />
                                  {ind === 0 && (
                                    <Tooltip title="添加行">
                                      <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={addNewRow}
                                      />
                                    </Tooltip>
                                  )}
                                  {ind !== 0 && (
                                    <Tooltip title="删除行">
                                      <Button
                                        icon={<MinusOutlined />}
                                        onClick={() => deleteRow(ind)}
                                      />
                                    </Tooltip>
                                  )}
                                </Input.Group>
                              </Col>
                            </Row>
                          ))}
                        </Col>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item label="使用编缉器" valuePropName="checked">
                    <Switch checked={useEditor} onChange={switchEditor} />
                  </Form.Item>
                  <Row gutter={24}>
                    <Col span={24}>
                      <Form.Item labelCol={{ span: 4 }} label="着陆页配置">
                        {useEditor && (
                          <BraftEditor
                            className="my-editor"
                            // controls={controls}
                            placeholder="Content"
                            media={{ uploadFn: uploadMedia }}
                            value={editorState}
                            onChange={(v) => {
                              setEditorState(v)
                            }}
                          />
                        )}
                        {!useEditor && (
                          <Input.TextArea
                            value={richtext}
                            onChange={(e) => setRichText(e.target.value)}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <CountryProduct
                    country={item.countryCode}
                    group={record.code}
                    gridData={products[item.countryCode] || initGridData}
                    paginationHandler={paginationHandler}
                    reload={switchTab}
                  />
                </TabPane>
              ))}
          </Tabs>
        </Form>
      </GridFormDrawer>
      <SelectProduct reload={switchTab} />
    </>
  )
}

const mapStateToProps = ({ users, global }) => {
  return {
    allowedCountries:
      users.userInfo && users.userInfo.user
        ? users.userInfo.user.allowed_countries
        : [],
    allCountries: global.config
  }
}

export default connect(mapStateToProps)(NewReceiveForm)
