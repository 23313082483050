/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {Button, message, Modal, Table} from "antd";
import { getPricingTemplate } from "../../../services/1688Projects/exclusive";
import { sendPricingTemplate } from '../../../services/1688Projects/collection'

const ModalSetPricingTemplate = ({ selectedRowKeys, isModalOpen, handleOk, handleCancel, handleDeselectAll }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [getPricing, setGetPricing] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageNow, setPageNow] = useState(1)

  useEffect(() => {
    getPricingData()
  }, []);

  const columnsModal = [
    {
      title: 'Template Name',
      dataIndex: 'name'
    },
    {
      title: 'Site',
      dataIndex: 'site'
    },
    {
      title: 'Creator',
      dataIndex: 'creator'
    },
    {
      title: 'Creation Time',
      dataIndex: 'createTime',
      render: (createTime) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <div>{createTime.date}</div>
          <div>{createTime.time}</div>
        </div>
      )
    },
    {
      title: 'Action',
      render: (_, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
         <Button onClick={() => handleGetPricingData(record.key)}>Select</Button>
        </div>
      )
    }
  ]

  const handleGetPricingData = async (e) => {
    setIsLoading(true)
    try {
      const data = {}
      const allId = []
      if (selectedRowKeys?.length > 0) {
        for (let newNum of selectedRowKeys) {
          allId.push(Number(newNum))
        }
        data.id = allId
        data.pricing_template_id = Number(e)
        const response = await sendPricingTemplate(data)

        message.success('Success selected template!').then(() => {
          handleDeselectAll()
        })
        handleOk()
      }
    } catch (e) {
      message.error('Error data failed to select!')
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const onChangePage = (value) => {
    if (value) {
      setPageNow(Number(value))
      getPricingData({ goPage: Number(value) })
    }
  }

  async function getPricingData ({ goPage = 1 }: GetProduct = {}){
    try {
      setIsLoading(true)
      const data = {}
      if (goPage) {
        data.beginPage = goPage
      }

      data.pageSize = 10
      const response = await getPricingTemplate(data)
      if (response?.totalRecords) {
        setTotalCount(response.totalRecords)
      } else {
        setTotalCount(0)
      }
      if (response?.list?.length > 0) {
        const newData = response.list

        const dataPayload = newData.map((item) => {
          const dataReturn = {
            key: item.id || '-',
            name: item.template_name || '-',
            site: item.site || '-',
            // remark: item.profit_method || '-',
            creator: item?.author || '-',
            createTime: {
              date: item?.created_at?.split(' ')[0] || '',
              time: item?.created_at?.split(' ')[1] || ''
            }
          }
          return dataReturn
        })
        setGetPricing(dataPayload)
      } else {
        setTotalCount(0)
        setGetPricing([])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }




  return (
    <Modal
      footer={false}
      width={1500}
      centered
      title="Template Selling Price"
      open={isModalOpen} onOk={handleOk}
      onCancel={handleCancel}
    >
      <Table
        loading={isLoading}
        columns={columnsModal}
        dataSource={getPricing}
        scroll={{
          y: 240,
        }}
        centered
        current={pageNow}
        total
        pagination={{
          onChange: onChangePage,
          current: pageNow,
          total:totalCount || 0,
          size: 10
        }}
      />
    </Modal>
  )
}

export default ModalSetPricingTemplate
