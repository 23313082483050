import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import { Table } from 'antd/lib'
import { func } from 'prop-types'
import services from '../../../services'
import dashboardFlowColumns from '../../../config/tableColumns/dashboardFlowColumns'
import AddPercent from '../Tool/AddPercent'
import MyTooltip from '../Tool/MyTooltip'

const requestOptionPreass = {
  legend: {
    data: []
  },
  grid: {
    show: false,
    top: '10%',
    left: '10%',
    right: '10%',
    bottom: '20%'
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      animation: 'false'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisTick: {
        alignWithLabel: true
      },
      data: []
    }
  ],
  series: []
}

class LeftFlowFrame extends Component {
  constructor(props) {
    super(props)
    const { onRef } = props
    onRef(this.useEffect)
    this.state = {
      list: [],
      option_list: {},
      pc_flow_top: [],
      app_flow_top: [],
      isLoading: false
    }
  }

  useEffect = (params) => {
    this.fetchData(params)
  }

  fetchData = (params) => {
    services.dashboard.getFlow(params).then((result) => {
      // eslint-disable-next-line camelcase
      const option_list = {}
      result.map_data_list.forEach((item) => {
        option_list[item.key] = {
          ...requestOptionPreass
        }
        option_list[item.key].xAxis[0].data = item.catalog
        option_list[item.key].series = item.series
        option_list[item.key].yAxis = item.yAxis
      })
      this.setState({
        list: result.list,
        option_list,
        pc_flow_top: result.pc_flow_top,
        app_flow_top: result.app_flow_top
      })
    })
  }

  getFlowBox = () => {
    if (this.state.list.length > 1) {
      return this.state.list.map((item) => {
        return (
          <div className="flow-box" key={item.key}>
            <div className="flow-box-top">
              <div className="flow-box-title">
                {item.name}
                <MyTooltip account="GA获取" />
              </div>
              <div className="flow-box-value">{item.today}</div>
              <div className="flow-box-span">
                <span>
                  较前一日
                  <MyTooltip account="较前一时间段" />
                </span>
                <span className="flow-box-value">
                  <AddPercent percentString={item.yesterday} />
                </span>
              </div>
            </div>
            <ReactEcharts
              style={{ width: '90%' }}
              option={this.state.option_list[item.key]}
              key={item.key}
            />
          </div>
        )
      })
    }
    return ''
  }

  render() {
    return (
      <>
        <div className="left-frame">
          <div className="title">
            <div className="title-name">
              流量分析
              <MyTooltip account="时间段内数据" />
            </div>
          </div>

          <div className="width-max">
            <div className="width-max">{this.getFlowBox()}</div>
            <div className="width-max">
              <div className="flow-table">
                <Table
                  title={() => 'PC流量来源TOP5'}
                  size="small"
                  bordered
                  loading={this.state.isLoading}
                  columns={dashboardFlowColumns}
                  dataSource={this.state.pc_flow_top}
                  rowKey={(record) => record.name}
                  scroll={{ x: 'max-content', y: true }}
                  sticky
                  pagination={false}
                />
              </div>
              <div className="flow-table">
                <Table
                  title={() => 'APP流量来源TOP5'}
                  size="small"
                  bordered
                  loading={this.state.isLoading}
                  columns={dashboardFlowColumns}
                  dataSource={this.state.app_flow_top}
                  rowKey={(record) => record.name}
                  scroll={{ x: 'max-content', y: true }}
                  sticky
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

LeftFlowFrame.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onRef: func
}

export default LeftFlowFrame
