import services, {
  withTableLoading,
  withGlobalLoading,
  withTranslation
} from '../../services'

export const getCutActivityList = (handler) =>
  withTableLoading((params) =>
    services.share.getCutActivityList(params).then(handler)
  )

export const getCutActivityDetail = (handler) =>
  withGlobalLoading((id) =>
    services.share.getCutActivityDetail({ id }).then(handler)
  )

export const deleteCutActivity = (handler) =>
  withGlobalLoading((id) =>
    services.share.deleteCutActivity({ id }).then(handler)
  )

export const createCutActivity = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.share.createCutActivity(params).then(handler)
    )
  )

export const updateCutActivity = (handler) =>
  withTranslation(
    withGlobalLoading((params) =>
      services.share.saveCutActivity(params).then(handler)
    )
  )

export const getCutActivityShareList = (handler) =>
  withTableLoading((params) =>
    services.share.getCutActivityShareList(params).then(handler)
  )

export const getCutActivityShareDetail = (handler) =>
  withGlobalLoading((id) =>
    services.share.getCutActivityShareDetail({ id }).then(handler)
  )

export const getCutShareRecordList = (handler) =>
  withTableLoading((params) =>
    services.share.getCutShareRecordList(params).then(handler)
  )

export const getCutActivityProducts = (handler) =>
  withGlobalLoading((params) =>
    services.share.getCutActivityProducts(params).then(handler)
  )

export const toggleProduct = (handler) =>
  withGlobalLoading((params) =>
    services.share.toggleProduct(params).then(handler)
  )
export const saveProductAttributes = (handler) =>
  withGlobalLoading((params) =>
    services.share.saveProductAttributes(params).then(handler)
  )
export const getProducts = (handler) =>
  withGlobalLoading((params) =>
    services.membership.getProducts(params).then(handler)
  )
export const getCutActivityStatistics = (handler) =>
  withGlobalLoading((params) =>
    services.share.getCutActivityStatistics(params).then(handler)
  )
