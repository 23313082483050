import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getStaticText } from '../../utils'

const dailyTableColumns = [
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.country')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.country')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => getStaticText(data.value)
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.report_date')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.report_date')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'report_date',
    key: 'report_date',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.platform')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.platform')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'platform',
    key: 'platform',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.promotion_text')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.fill.inmanually_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.promotion_text')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'promotion_text',
    key: 'promotion_text',
    width: 120,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.platform_resource_used')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.fill.inmanually_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.platform_resource_used')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'platform_resource_used',
    key: 'platform_resource_used',
    width: 140,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.discounts')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.fill.inmanually_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.discounts')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'discounts',
    key: 'discounts',
    width: 110,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: getStaticText('daily.sales.table.day.sales.amount'),
    key: 'day_sales_amount',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.sales_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.sales_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount',
        key: 'sales_amount',
        width: 140,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.sales_amount_last_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.sales_amount_last_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_last_year',
        key: 'sales_amount_last_year',
        width: 190,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.sales_amount_year_on_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.sales_amount_year_on_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.sales_amount_year_on_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_year_on_year',
        key: 'sales_amount_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.monthly.sales.completion'),
    key: 'monthly_sales_completion',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.sales_volume_accumation')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.sales_volume_accumation_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.sales_volume_accumation')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_volume_accumation',
        key: 'sales_volume_accumation',
        width: 120,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.sales_volume_target')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.sales_volume_target')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_volume_target',
        key: 'sales_volume_target',
        width: 110,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.sales_volume_completion_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.sales_volume_completion_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'daily.sales.table.sales_volume_completion_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_volume_completion_rate',
        key: 'sales_volume_completion_rate',
        width: 120,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.sales_amount_accumation')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.sales_amount_accumation')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_accumation',
        key: 'sales_amount_accumation',
        width: 120,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.sales_amount_target')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.budget.import'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.sales_amount_target')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_target',
        key: 'sales_amount_target',
        width: 120,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.sales_amount_completion_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.sales_amount_completion_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'daily.sales.table.sales_amount_completion_rate'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'sales_amount_completion_rate',
        key: 'sales_amount_completion_rate',
        width: 140,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.flow'),
    key: 'flow',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.uv')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.uv')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv',
        key: 'uv',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.uv_last_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.uv_last_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv_last_year',
        key: 'uv_last_year',
        width: 110,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.uv_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.uv_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.uv_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv_percent',
        key: 'uv_percent',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.pv')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.pv')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'pv',
        key: 'pv',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.conversion.rate'),
    key: 'conversion.rate',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.conversion_rate')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.conversion_rate_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.conversion_rate')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'conversion_rate',
        key: 'conversion_rate',
        width: 120,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.conversion_rate_last_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.conversion_rate_last_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.conversion_rate_last_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'conversion_rate_last_year',
        key: 'conversion_rate_last_year',
        width: 140,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.conversion_rate_year_on_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.conversion_rate_year_on_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'daily.sales.table.conversion_rate_year_on_year'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'conversion_rate_year_on_year',
        key: 'conversion_rate_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.customer.price'),
    key: 'customer.price',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.customer_order_average')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.customer_order_average_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.customer_order_average')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'customer_order_average',
        key: 'customer_order_average',
        width: 120,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText(
              'daily.sales.table.customer_order_last_year_average'
            )}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.customer_order_last_year_average_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'daily.sales.table.customer_order_last_year_average'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'customer_order_last_year_average',
        key: 'customer_order_last_year_average',
        width: 160,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.customer_order_year_on_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.customer_order_year_on_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'daily.sales.table.customer_order_year_on_year'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'customer_order_year_on_year',
        key: 'customer_order_year_on_year',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.uv.worth'),
    key: 'uv.worth',
    align: 'center',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.uv_worth')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.uv_worth_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.uv_worth')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'uv_worth',
        key: 'uv_worth',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.order'),
    key: 'order',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.orders_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.orders_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'orders_amount',
        key: 'orders_amount',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'orders',
        key: 'orders',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.orders_average')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.orders_average_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.orders_average')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'orders_average',
        key: 'orders_average',
        width: 120,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.orders_last_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.orders_last_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'orders_last_year',
        key: 'orders_last_year',
        width: 140,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.orders_year_on_year')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.orders_year_on_year_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.orders_year_on_year')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'orders_year_on_year',
        key: 'orders_year_on_year',
        width: 120,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.online_paid_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.online_paid_orders_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.online_paid_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'online_paid_orders',
        key: 'online_paid_orders',
        width: 140,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.online_paid_orders_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.online_paid_orders_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText(
                'daily.sales.table.online_paid_orders_percent'
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'online_paid_orders_percent',
        key: 'online_paid_orders_percent',
        width: 140,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.cod_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText('daily.sales.table.cod_orders_explain_note')}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.cod_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cod_orders',
        key: 'cod_orders',
        width: 120,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.cod_orders_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.cod_orders_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.cod_orders_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cod_orders_percent',
        key: 'cod_orders_percent',
        width: 110,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.cod_return_orders')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.cod_return_orders_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.cod_return_orders')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cod_return_orders',
        key: 'cod_return_orders',
        width: 140,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.cod_return_orders_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.cod_return_orders_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.cod_return_orders_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'cod_return_orders_percent',
        key: 'cod_return_orders_percent',
        width: 120,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.phone.sales'),
    key: 'phone.sales',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.phone_sales_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.phone_sales_amount_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.phone_sales_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_sales_amount',
        key: 'phone_sales_amount',
        width: 110,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.phone_sales_volume')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.phone_sales_volume_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.phone_sales_volume')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_sales_volume',
        key: 'phone_sales_volume',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.phone_sales_average')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.phone_sales_average_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.phone_sales_average')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'phone_sales_average',
        key: 'phone_sales_average',
        width: 110,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.product.supply'),
    key: 'product.supply',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.total_online_product')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.total_online_product_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.total_online_product')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'total_online_product',
        key: 'total_online_product',
        width: 130,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.new_online_product')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.new_online_product_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.new_online_product')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'new_online_product',
        key: 'new_online_product',
        width: 110,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.active_product')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.data.capture_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.active_product_explain_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.active_product')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'active_product',
        key: 'active_product',
        width: 110,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.active_product_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.active_product_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.active_product_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'active_product_percent',
        key: 'active_product_percent',
        width: 110,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.score')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.fill.inmanually_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.score')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'score',
    key: 'score',
    width: 100,
    align: 'center',
    render: (data) => data.value
  },
  {
    title: getStaticText('daily.sales.table.ad.fb'),
    key: 'ad.fb',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.ad_fb_consumption')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.ad_fb_consumption')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_fb_consumption',
        key: 'ad_fb_consumption',
        width: 130,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.ad_fb_sales_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.ad_fb_sales_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_fb_sales_amount',
        key: 'ad_fb_sales_amount',
        width: 130,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.ad_fb_roi')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.ad_fb_roi_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.ad_fb_roi')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_fb_roi',
        key: 'ad_fb_roi',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.ad.google'),
    key: 'ad.google',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.ad_google_consumption')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.ad_google_consumption')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_google_consumption',
        key: 'ad_google_consumption',
        width: 130,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.ad_google_sales_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.ad_google_sales_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_google_sales_amount',
        key: 'ad_google_sales_amount',
        width: 130,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.ad_google_roi')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.ad_google_roi_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.ad_google_roi')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_google_roi',
        key: 'ad_google_roi',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.dm'),
    key: 'dm',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.dm_amount')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.dm_amount_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.dm_amount')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'dm_amount',
        key: 'dm_amount',
        width: 100,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.dm_gmv_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.dm_gmv_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.dm_gmv_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'dm_gmv_percent',
        key: 'dm_gmv_percent',
        width: 100,
        align: 'center',
        render: (data) => data.value
      }
    ]
  },
  {
    title: getStaticText('daily.sales.table.ad.uv.amount'),
    key: 'ad.uv.amount',
    children: [
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.ad_uv')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.fill.inmanually_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.ad_uv')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_uv',
        key: 'ad_uv',
        width: 130,
        align: 'center',
        render: (data) => data.value
      },
      {
        title: (
          <div>
            {getStaticText('daily.sales.table.ad_uv_percent')}
            &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    {getStaticText('component.remarks.data.source_note')}
                    {getStaticText(
                      'component.remarks.data.source.calculated.results_note'
                    )}
                  </p>
                  <p>
                    {getStaticText(
                      'daily.sales.table.ad_uv_percent_calculation_note'
                    )}
                  </p>
                </div>
              )}
              key={getStaticText('daily.sales.table.ad_uv_percent')}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </div>
        ),
        dataIndex: 'ad_uv_percent',
        key: 'ad_uv_percent',
        width: 160,
        align: 'center',
        render: (data) => data.value
      }
    ]
  }
  /* {
    title: (
      <div>
        {getStaticText('dashboard.daily.table.bad_review_total')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.data.capture_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('dashboard.daily.table.bad_review_total')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'bad_review_total',
    key: 'bad_review_total',
    align: 'center',
    width: 100
  } */
]

export default dailyTableColumns
