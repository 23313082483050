import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getRewriteList = (handler) =>
  withTableLoading((params) =>
    services.system.getRewriteList(params).then(handler)
  )

export const getRewriteDetail = (handler) =>
  withGlobalLoading((id) =>
    services.system.getRewriteDetail({ id }).then(handler)
  )

export const deleteRewrite = (handler) =>
  withGlobalLoading((id) => services.system.deleteRewrite({ id }).then(handler))

export const createRewrite = (handler) =>
  withGlobalLoading((params) =>
    services.system.createRewrite(params).then(handler)
  )

export const updateRewrite = (handler) =>
  withGlobalLoading((params) =>
    services.system.updateRewrite(params).then(handler)
  )
