import React from 'react'
import { Typography, Row, Col } from 'antd'

import { connect } from 'react-redux'
import { getStaticText } from '../../utils'

const { Title, Text } = Typography

interface Props {
  allCountries: Object;
}

const GlobalTips = ({ allCountries }: Props) => {
  const getCurrencys = (country) => {
    const config = allCountries.find((config) => {
      return config.countryCode === country
    })
    if (!config) {
      return ''
    }
    return `${config.name} 1(USD) = ${parseFloat(
      config.currency_rate_from_usd
    ).toFixed(3)}(${config.currency_code})`
  }

  return (
    <>
      <Title level={5}>{getStaticText('app.tips')}</Title>
      <div
        className="floatf"
        style={{
          width: '400px'
        }}
      >
        <Row>
          <Col>
            <Text type="warning">
              1. 每个国家日、周、月报表因为时区原因，有一定差异
            </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text type="warning">2. 周，按照周天-下周六进行计算 </Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text type="warning">
              3. 翻年的周，按照后一年w1中计算。也是计算7天的数据
            </Text>
          </Col>
        </Row>
      </div>
      <div
        className="floatf"
        style={{
          width: '70px'
        }}
      >
        <Row>
          <Text type="warning">4. 汇率</Text>
        </Row>
      </div>
      <div
        className="floatf"
        style={{
          width: '280px'
        }}
      >
        <Row>
          <Text type="warning">{getCurrencys('NG')}</Text>
        </Row>
        <Row>
          <Text type="warning">{getCurrencys('PK')}</Text>
        </Row>
        <Row>
          <Text type="warning">{getCurrencys('EG')}</Text>
        </Row>
      </div>
      <div
        className="floatf"
        style={{
          width: '280px'
        }}
      >
        <Row>
          <Text type="warning">{getCurrencys('GH')}</Text>
        </Row>
        <Row>
          <Text type="warning">{getCurrencys('MA')}</Text>
        </Row>
        <Row>
          <Text type="warning">{getCurrencys('KE')}</Text>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = ({ global }) => ({
  allCountries: global.config
})

export default connect(mapStateToProps)(GlobalTips)
