import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Tree, Form, Button, Space, Select, Input } from 'antd'
import { DownOutlined, DeleteTwoTone, PlusOutlined } from '@ant-design/icons'
import { cloneDeep } from 'lodash'

const Questions = (props) => {
  const { Option } = Select
  const { initSurveyData, surveyData, setSurveyData, setHasUpdate } = props

  const questionType = useMemo(
    () => [
      {
        value: 'single',
        label: '单选'
      },
      {
        value: 'many',
        label: '多选'
      },
      {
        value: 'answer',
        label: '问答'
      }
    ],
    []
  )

  // update cloneSurveyData
  const setCloneSurveyData = useCallback(
    (obj, path, action, value) => {
      const arr = path.split('.')
      const length = arr.length - 1
      arr.reduce((cur, key, index) => {
        if (index === length) {
          switch (action) {
            case 'update_question':
              if (!key) {
                cur.name = value
              } else {
                cur[key].name = value
              }
              break
            case 'update_question_type':
              if (!key) {
                if (cur.type === 'answer')
                  cur.options = { a: { answer: '', score: '' } }
                else if (value === 'answer') delete cur.options
                cur.type = value
              } else {
                if (cur[key].type === 'answer')
                  cur[key].options = { a: { answer: '', score: '' } }
                else if (value === 'answer') delete cur[key].options
                cur[key].type = value
              }
              break
            case 'update_option':
              cur[key].answer = value
              break
            case 'update_option_score':
              cur[key].score = value
              break
            case 'add_option':
              cur[key] = Object.assign(cur[key], value)
              break
            case 'delete_option':
              Object.keys(cur).forEach((option, index) => {
                if (
                  index >= key.charCodeAt(0) - 'a'.charCodeAt(0) &&
                  index < Object.values(cur).length - 1
                ) {
                  cur[option] =
                    cur[String.fromCharCode(option.charCodeAt(0) + 1)]
                }
              })
              delete cur[
                String.fromCharCode(
                  'a'.charCodeAt(0) + Object.values(cur).length - 1
                )
              ]
              break
            case 'add_question':
              cur[key] = Object.assign(cur[key], value)
              break
            case 'delete_question':
              delete cur[key].sub
              break
            default:
          }
        }
        return cur[key]
      }, obj)
      setHasUpdate(true)
    },
    [setHasUpdate]
  )

  const getAttributeUrl = useCallback((indexArr) => {
    return indexArr.reduce((str, option, index) => {
      if (index === 0) {
        str += `options.${String.fromCharCode(
          'a'.charCodeAt(0) + Number(option) - 1
        )}`
      } else {
        str += `.sub.options.${String.fromCharCode(
          'a'.charCodeAt(0) + Number(option) - 1
        )}`
      }
      return str
    }, '')
  }, [])

  const getSubAttributeUrl = useCallback((indexArr) => {
    return indexArr.reduce((str, option, index) => {
      if (index === 0) {
        str += `options.${String.fromCharCode(
          'a'.charCodeAt(0) + Number(option) - 1
        )}.sub`
      } else {
        str += `.options.${String.fromCharCode(
          'a'.charCodeAt(0) + Number(option) - 1
        )}.sub`
      }
      return str
    }, '')
  }, [])

  // add option
  const addOption = useCallback(
    (data) => {
      const clonedSurveyData = cloneDeep(surveyData)
      const keyArr = data.key.split('-')
      const addOptionIndex = keyArr[keyArr.length - 1]
      keyArr.pop()
      // the serial number of options under multiple questions in turn
      const optionIndex = keyArr.filter((_, index) => (index + 1) % 2 === 0)

      const attributeUrl = optionIndex.reduce((str, option) => {
        str += `.${String.fromCharCode(
          'a'.charCodeAt(0) + Number(option) - 1
        )}.sub.options`
        return str
      }, 'options')

      setCloneSurveyData(clonedSurveyData, attributeUrl, 'add_option', {
        [String.fromCharCode('a'.charCodeAt(0) + Number(addOptionIndex) - 1)]: {
          answer: '',
          score: ''
        }
      })
      setSurveyData(clonedSurveyData)
    },
    [surveyData, setCloneSurveyData, setSurveyData]
  )

  // delete sub question
  const removeQuestion = useCallback(
    (data) => {
      const clonedSurveyData = cloneDeep(surveyData)
      const keyArr = data.key.split('-')
      // the serial number of options under multiple questions in turn
      const optionIndex = keyArr.filter((_, index) => (index + 1) % 2 === 0)

      const attributeUrl = getAttributeUrl(optionIndex)
      setCloneSurveyData(clonedSurveyData, attributeUrl, 'delete_question')
      setSurveyData(clonedSurveyData)
    },
    [surveyData, getAttributeUrl, setCloneSurveyData, setSurveyData]
  )

  // delete option
  const removeOption = useCallback(
    (data) => {
      const clonedSurveyData = cloneDeep(surveyData)
      const keyArr = data.key.split('-')
      // the serial number of options under multiple questions in turn
      const optionIndex = keyArr.filter((_, index) => (index + 1) % 2 === 0)
      const attributeUrl = getAttributeUrl(optionIndex)
      setCloneSurveyData(clonedSurveyData, attributeUrl, 'delete_option')
      setSurveyData(clonedSurveyData)
    },
    [surveyData, getAttributeUrl, setCloneSurveyData, setSurveyData]
  )

  // add sub question
  const addQuestion = useCallback(
    (data) => {
      const clonedSurveyData = cloneDeep(surveyData)
      const keyArr = data.key.split('-')

      const optionIndex = keyArr.filter((_, index) => (index + 1) % 2 === 0)
      const attributeUrl = getAttributeUrl(optionIndex)
      setCloneSurveyData(clonedSurveyData, attributeUrl, 'add_question', {
        sub: initSurveyData
      })
      setSurveyData(clonedSurveyData)
    },
    [
      surveyData,
      getAttributeUrl,
      setCloneSurveyData,
      setSurveyData,
      initSurveyData
    ]
  )

  // update question name
  const updateQuestion = useCallback(
    (key, e) => {
      const clonedSurveyData = cloneDeep(surveyData)
      const keyArr = key.split('-')
      // the serial number of options under multiple questions in turn
      const optionIndex = keyArr.filter((_, index) => (index + 1) % 2 === 0)
      const attributeUrl = getSubAttributeUrl(optionIndex)
      setCloneSurveyData(
        clonedSurveyData,
        attributeUrl,
        'update_question',
        e.target.value
      )
      setSurveyData(clonedSurveyData)
    },
    [surveyData, getSubAttributeUrl, setCloneSurveyData, setSurveyData]
  )

  // update question type
  const updateQuestionType = useCallback(
    (key, value) => {
      const clonedSurveyData = cloneDeep(surveyData)
      const keyArr = key.split('-')
      // the serial number of options under multiple questions in turn
      const optionIndex = keyArr.filter((_, index) => (index + 1) % 2 === 0)
      const attributeUrl = getSubAttributeUrl(optionIndex)
      setCloneSurveyData(
        clonedSurveyData,
        attributeUrl,
        'update_question_type',
        value
      )
      setSurveyData(clonedSurveyData)
    },
    [surveyData, getSubAttributeUrl, setCloneSurveyData, setSurveyData]
  )

  const renderQuestion = useCallback(
    (data) => {
      return (
        <Space>
          <Form.Item
            className="survey-question"
            name={['questions', data.key, 'question']}
            onBlur={(event) => updateQuestion(data.key, event)}
            rules={[{ required: true }]}
          >
            <Input style={{ width: 300 }} placeholder="question name" />
          </Form.Item>
          <Form.Item
            className="survey-question"
            name={['questions', data.key, 'type']}
            initialValue="single"
          >
            <Select onChange={(value) => updateQuestionType(data.key, value)}>
              {questionType.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {data.key !== '1' && (
            <DeleteTwoTone
              style={{ fontSize: 16 }}
              onClick={() => removeQuestion(data)}
            />
          )}
        </Space>
      )
    },
    [questionType, removeQuestion, updateQuestion, updateQuestionType]
  )

  // update option answer
  const updateOption = useCallback(
    (key, e) => {
      const clonedSurveyData = cloneDeep(surveyData)
      const keyArr = key.split('-')
      // the serial number of options under multiple questions in turn
      const optionIndex = keyArr.filter((_, index) => (index + 1) % 2 === 0)
      const attributeUrl = getAttributeUrl(optionIndex)
      setCloneSurveyData(
        clonedSurveyData,
        attributeUrl,
        'update_option',
        e.target.value
      )
      setSurveyData(clonedSurveyData)
    },
    [surveyData, getAttributeUrl, setCloneSurveyData, setSurveyData]
  )

  // update option score
  const updateOptionScore = useCallback(
    (key, e) => {
      const clonedSurveyData = cloneDeep(surveyData)
      const keyArr = key.split('-')
      const optionIndex = keyArr.filter((_, index) => (index + 1) % 2 === 0)
      const attributeUrl = getAttributeUrl(optionIndex)
      setCloneSurveyData(
        clonedSurveyData,
        attributeUrl,
        'update_option_score',
        e.target.value
      )
      setSurveyData(clonedSurveyData)
    },
    [surveyData, getAttributeUrl, setCloneSurveyData, setSurveyData]
  )

  const renderOption = useCallback(
    (data) => {
      const keyArr = data.key.split('-')
      const optionIndex = keyArr[keyArr.length - 1]
      const optionLabel = String.fromCharCode(
        'a'.charCodeAt(0) + Number(optionIndex) - 1
      ).toUpperCase()

      return (
        <Space>
          <Form.Item
            className="survey-question"
            label={optionLabel}
            name={['questions', data.key, 'option']}
            onBlur={(event) => updateOption(data.key, event)}
            rules={[{ required: true }]}
          >
            <Input style={{ width: 300 }} placeholder="option name" />
          </Form.Item>
          <Form.Item
            className="survey-question"
            label="得分"
            name={['questions', data.key, 'score']}
            onBlur={(event) => updateOptionScore(data.key, event)}
            rules={[
              {
                required: true,
                type: 'number',
                min: 1,
                transform: (value) => Number(value)
              }
            ]}
          >
            <Input style={{ width: 100 }} placeholder="score" />
          </Form.Item>
          {!data.children.length && (
            <Button
              type="dashed"
              onClick={() => addQuestion(data)}
              icon={<PlusOutlined />}
            >
              Add Sub Question
            </Button>
          )}
          {optionIndex !== '1' && (
            <DeleteTwoTone
              style={{ fontSize: 16 }}
              onClick={() => removeOption(data)}
            />
          )}
        </Space>
      )
    },
    [removeOption, addQuestion, updateOption, updateOptionScore]
  )

  const renderAddOption = useCallback(
    (data) => {
      return (
        <>
          <Button
            type="dashed"
            onClick={() => addOption(data)}
            block
            icon={<PlusOutlined />}
          >
            Add Option
          </Button>
        </>
      )
    },
    [addOption]
  )

  const formatTreeData = useCallback(
    (survey = surveyData, key = '1') => {
      const arr = []
      // question
      const questionObj = {
        title: (data) => renderQuestion(data),
        key,
        type: 'single',
        children: []
      }
      // options
      const optionObj = {
        title: (data) => renderOption(data),
        key: `${key}-1`,
        children: []
      }
      // add option button
      const addOptionObj = {
        title: (data) => renderAddOption(data),
        key: `${key}-1`
      }
      // question without options
      if (survey.type === 'answer') {
        arr.push({ ...questionObj, type: 'answer' })
        return arr
      }

      const children = []
      if (survey.options) {
        Object.values(survey.options).forEach((item, index) => {
          if (item.sub) {
            const subArr = formatTreeData(item.sub, `${key}-${index + 1}-1`)
            children.push({
              ...optionObj,
              key: `${key}-${index + 1}`,
              children: subArr
            })
          } else {
            children.push({ ...optionObj, key: `${key}-${index + 1}` })
          }
        })
        children.push({
          ...addOptionObj,
          key: `${key}-${Object.values(survey.options).length + 1}`
        })
      }
      arr.push({
        ...questionObj,
        type: survey.type,
        key: `${key}`,
        children
      })
      return arr
    },
    [renderOption, renderQuestion, renderAddOption, surveyData]
  )

  return (
    <>
      <Form.Item name="questions">
        <Tree
          className="survey-tree"
          showLine={{ showLeafIcon: false }}
          selectable={false}
          switcherIcon={<DownOutlined />}
          defaultExpandedKeys={['0-0-0']}
          treeData={formatTreeData()}
          showIcon
          defaultExpandAll
        />
      </Form.Item>
    </>
  )
}

Questions.propTypes = {
  initSurveyData: PropTypes.shape().isRequired,
  surveyData: PropTypes.shape().isRequired,
  setSurveyData: PropTypes.func.isRequired,
  setHasUpdate: PropTypes.func.isRequired
}

export default Questions
