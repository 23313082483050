import React, { useState, useContext } from 'react'
import { Row, Col, Timeline } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons'

import {
  GridFormDrawer,
  GridContext,
  OptionColumn,
  PermissionButton
} from '../../../../components'
import './pair-table.css'

import {
  getAuditDetail,
  approveToComplete,
  rejectToClose,
  topup
} from '../../services'

const AuditDetail = () => {
  const [detail, setDetail] = useState({})
  const { onLoad, toggle } = useContext(GridContext)

  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }

  const openHandler = (id) => {
    getAuditDetail(setDetail)(id)
  }

  const handleTopUp = () => {
    topup(closeDrawer)(detail.id)
  }
  const handleApprove = () => {
    approveToComplete(closeDrawer)(detail.id)
  }
  const handleReject = () => {
    rejectToClose(closeDrawer)(detail.id)
  }

  return (
    <GridFormDrawer
      onTest={closeDrawer}
      onOpen={(record) => openHandler(record.id)}
      type="detail"
      title={() => 'Order Auditing'}
    >
      <Row gutter={24}>
        <Col span={8} offset={1}>
          <Row gutter={24}>
            <Col span={12} className="pair-table field">
              Customer email
            </Col>
            <Col span={12} className="pair-table value">
              <span>{detail.customer_email}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} className="pair-table field">
              Phone Number
            </Col>
            <Col span={12} className="pair-table value">
              <span>{detail.phone_number}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} className="pair-table field">
              Country Code
            </Col>
            <Col span={12} className="pair-table value">
              <span>{detail.country}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} className="pair-table field">
              Order No
            </Col>
            <Col span={12} className="pair-table value">
              <span>{detail.order_no}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} className="pair-table field">
              Operator Code
            </Col>
            <Col span={12} className="pair-table value">
              <span>{detail.operator_code}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} className="pair-table field">
              Topup Type
            </Col>
            <Col span={12} className="pair-table value">
              <span>{detail.activity_type}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} className="pair-table field">
              Package Id
            </Col>
            <Col span={12} className="pair-table value">
              <span>{detail.package_id}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} className="pair-table field">
              Amount
            </Col>
            <Col span={12} className="pair-table value">
              <span>{detail.activity_amount}</span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} className="pair-table field last">
              Status
            </Col>
            <Col span={12} className="pair-table value last">
              <span>{detail.status}</span>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: '20px' }}>
            {detail.status === '1' && (
              <Col span={24} align="center">
                <PermissionButton
                  onClick={handleApprove}
                  permission="topup/activity/audit/approve-to-complete"
                >
                  Approve to complete
                </PermissionButton>
                <PermissionButton
                  style={{ marginLeft: '5px' }}
                  onClick={handleReject}
                  permission="topup/activity/audit/reject-to-close"
                >
                  Reject and close
                </PermissionButton>
                <PermissionButton
                  type="primary"
                  style={{ marginLeft: '5px' }}
                  onClick={handleTopUp}
                  permission="topup/activity/audit/topup"
                >
                  Topup Online
                </PermissionButton>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={13} offset={2}>
          <Timeline>
            <Timeline.Item>
              <div className="time">{detail.created_at}</div>
              <div className="user">{detail.customer_email}</div>
              <div className="log">{`Placed this order by ${detail.source}`}</div>
            </Timeline.Item>
            {detail.logs &&
              detail.logs.map((item) => (
                <Timeline.Item>
                  <div className="time">{item.created_at}</div>
                  <div className="user">{item.username}</div>
                  {item.log && <div className="log">{item.log}</div>}
                  {!item.log && (
                    <div>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.value_changed }}
                      />
                    </div>
                  )}
                </Timeline.Item>
              ))}
            <Timeline.Item
              color="gray"
              dot={<ClockCircleOutlined style={{ fontSize: '24px' }} />}
            >
              <OptionColumn
                source="topup_order_statuses"
                value={detail.status}
              />
            </Timeline.Item>
          </Timeline>
        </Col>
      </Row>
    </GridFormDrawer>
  )
}
export default AuditDetail
