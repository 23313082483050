/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Divider,
  message,
  Modal,
  Row,
  Spin,
  Typography
} from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import '../../css/exclusive.css'
import FilterExclusive from '../../components/1688ProjectComponents/1688Exclusive/FilterExclusive'
import CardExclusive from '../../components/1688ProjectComponents/1688Exclusive/CardExclusive'
import StickyFooter from '../../components/1688ProjectComponents/FooterComponents/StickyFooter'
import {
  getCategory,
  getProductList,
  sendCollectProduct
} from '../../services/1688Projects/exclusive'

const { Title } = Typography

const _1688Exclusive = () => {
  const [open, setOpen] = useState(false)
  const [checked, setChecked] = useState({})
  const [categoryFilter, setCategoryFilter] = useState([])
  const [productList, setProductList] = useState([])
  const [productLoading, setProductLoading] = useState(false)
  const [pageNow, setPageNow] = useState(1)
  const [categoryId, setCategoryId] = useState(null)
  const [totalPage, setTotalPage] = useState(0)
  const [sizePage, setSizePage] = useState(20)
  const [monthlySortStatus, setMonthlySortStatus] = useState(0)
  const [rePurchaseStatus, setRePurchaseStatus] = useState(0)
  const [priceStatus, setPriceStatus] = useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [loadingCollect, setLoadingCollect] = useState(false)
  const [totalItem, setTotalItem] = useState(0)
  const [idProductLoading, setIdProductLoading] = useState(null)
  const [performanceOption, setPerformanceOption] = useState([])
  const [deliveryOption, setDeliveryOption] = useState([])
  const [performanceValue, setPerformanceValue] = useState(null)
  const [deliveryValue, setDeliveryValue] = useState(null)
  const [priceRange, setPriceRange] = useState({
    startPrice: null,
    endPrice: null
  })

  useEffect(() => {
    if (priceRange?.startPrice || priceRange?.endPrice) {
      const getData = setTimeout(() => {
        getProductListData({ goPage: 1 })
        setPageNow(1)
      }, 2000)
      return () => clearTimeout(getData)
    }
  }, [priceRange]);

  const getCategoryData = () => {
    getCategory()
      .then((res) => {
        if (res && res?.categoryList?.children?.length > 0) {
          const dataRes = res?.categoryList?.children?.map((data) => {
            return {
              label: data.translatedName,
              value: data.categoryId,
              ...data
            }
          })
          setCategoryFilter([{ label: 'All', value: 'All' }, ...dataRes])
        }
        if (res && res?.filterMappings?.performance_quality?.length > 0) {
          const dataRes = res?.filterMappings?.performance_quality?.map(
            (item) => {
              return {
                label: item?.name,
                value: item?.value
              }
            }
          )
          setPerformanceOption([{ label: 'All', value: 'All' }, ...dataRes])
        }
        if (res && res?.filterMappings?.performance_guarantee?.length > 0) {
          const dataRes = res?.filterMappings?.performance_guarantee?.map(
            (item) => {
              return {
                label: item?.name,
                value: item?.value
              }
            }
          )
          setDeliveryOption([{ label: 'All', value: 'All' }, ...dataRes])
        }
      })
      .catch((err) => {
        console.log({ err })
      })
  }

  const handleSendCollectProduct = (value) => {
    const data = {
      offerId: [value]
    }
    setIdProductLoading(value)
    setLoadingCollect(true)
    sendCollectProduct(data)
      .then(() => {
        message.success('Success collect!')
      })
      .catch((err) => {
        console.log(err)
        message.success('Failed to collect!')
      })
      .finally(() => {
        setLoadingCollect(false)
        setIdProductLoading(null)
      })
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleOk = () => {
    window.location.href = '/common-function/public-collection-box'
    setOpen(false)
  }

  const handleUncheckAll = () => {
    const updatedChecked = {}
    productList.forEach((item) => {
      updatedChecked[item.id] = false
    })
    setChecked(updatedChecked)
  }

  const showModal = async () => {
    setLoadingCollect(true)
    const data =
      Object.keys(checked)
        .filter((key) => checked[key])
        .map((item) => {
          return Number(item)
        }) || []
    try {
      if (data && data?.length > 0) {
        await sendCollectProduct({ offerId: data })
        handleUncheckAll()
      }
    } catch (e) {
      console.log(e)
      message.error('Failed to collect!')
    } finally {
      setLoadingCollect(false)
    }
    setOpen(true)
  }

  const iconModel = () => {
    return (
      <ExclamationCircleFilled style={{ fontSize: '40px', color: 'orange' }} />
    )
  }

  const titlePage = () => {
    return (
      <Title level={2} style={{ marginBottom: 0, padding: '24px' }}>
        1688 Exclusive
      </Title>
    )
  }
  const footerModal = () => {
    return (
      <div>
        <Button
          key="back"
          type="default"
          onClick={handleCancel}
          loading={loadingCollect}
        >
          Continue
        </Button>
        <Button
          loading={loadingCollect}
          key="submit"
          type="primary"
          onClick={handleOk}
          style={{ fontSize: '15px', background: 'black' }}
        >
          Go to Public collection box
        </Button>
      </div>
    )
  }
  const handleCheckboxChange = (id) => {
    setChecked((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }))
  }
  const handleCheckAll = () => {
    const updatedChecked = {}
    productList.forEach((item) => {
      updatedChecked[item.id] = true
    })
    setChecked(updatedChecked)
  }

  const handleSortMonthly = () => {
    if (monthlySortStatus === 0) {
      setMonthlySortStatus(1)
    } else if (monthlySortStatus === 1) {
      setMonthlySortStatus(2)
    } else if (monthlySortStatus === 2) {
      setMonthlySortStatus(0)
    }
    setPriceStatus(0)
    setRePurchaseStatus(0)
  }
  const handleSortRePurchase = () => {
    if (rePurchaseStatus === 0) {
      setRePurchaseStatus(1)
    } else if (rePurchaseStatus === 1) {
      setRePurchaseStatus(2)
    } else if (rePurchaseStatus === 2) {
      setRePurchaseStatus(0)
    }
    setPriceStatus(0)
    setMonthlySortStatus(0)
  }
  const handleSortPrice = () => {
    if (priceStatus === 0) {
      setPriceStatus(1)
    } else if (priceStatus === 1) {
      setPriceStatus(2)
    } else if (priceStatus === 2) {
      setPriceStatus(0)
    }
    setRePurchaseStatus(0)
    setMonthlySortStatus(0)
  }
  const onShowSizeChange = (current, pageSize) => {
    setSizePage(pageSize)
    setPageNow(1)
  }
  const onChangePage = (value) => {
    if (value) {
      setPageNow(Number(value))
    }
  }
  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a className="button-pagination previous">Previous</a>
    }
    if (type === 'next') {
      return <a className="button-pagination next">Next</a>
    }
    return originalElement
  }
  const RenderButtonModalOpen = () => {
    return (
      <Col span={24}>
        <Row justify="center">
          <Button
            loading={loadingCollect}
            type="default"
            style={{
              background: 'black',
              color: 'white',
              fontSize: '16px',
              height: '60px'
            }}
            onClick={showModal}
          >
            Start Collecting
          </Button>
        </Row>
      </Col>
    )
  }
  useEffect(() => {
    getCategoryData()
  }, [])

  const getProductListData = () => {

    setProductLoading(true)
    const data = {}
    if (pageNow) {
      data.beginPage = pageNow
    }
    const dataStartPrice = priceRange.startPrice
    const dataEndPrice = priceRange.endPrice
    if (dataStartPrice) {
      data.priceStart = dataStartPrice
    }
    if (dataEndPrice) {
      data.priceEnd = dataEndPrice
    }
    if (categoryId && categoryId !== 'All') {
      data.categoryId = categoryId
    }
    if (deliveryValue && deliveryValue !== 'All') {
      data.filter = `${deliveryValue},${
        performanceValue && performanceValue !== 'All' ? performanceValue : ''
      }`
    } else if (performanceValue && performanceValue !== 'All') {
      data.filter = `${performanceValue},${
        deliveryValue && deliveryValue !== 'All' ? deliveryValue : ''
      }`
    }
    if (sizePage) {
      data.pageSize = sizePage
    }

    if (monthlySortStatus === 1) {
      data.sort = '{"monthSold":"asc"}'
    } else if (monthlySortStatus === 2) {
      data.sort = '{"monthSold":"desc"}'
    }

    if (rePurchaseStatus === 1) {
      data.sort = '{"rePurchaseRate":"asc"}'
    } else if (rePurchaseStatus === 2) {
      data.sort = '{"rePurchaseRate":"desc"}'
    }

    if (priceStatus === 1) {
      data.sort = '{"price":"asc"}'
    } else if (priceStatus === 2) {
      data.sort = '{"price":"desc"}'
    }

    if (searchValue && searchValue?.length > 0) {
      data.keyword = searchValue
    }
    getProductList(data)
      .then((res) => {
        if (res && res?.data?.length > 0) {
          const dataRes = res?.data.map((data) => {
            return {
              id: data.offerId,
              image: data?.imageUrl,
              tag: data?.sellerIdentities,
              whiteImage: data?.whiteImage,
              price: data?.priceInfo,
              monthlySales: data?.monthSold,
              repurchase: data?.repurchaseRate,
              desc: data?.subjectTrans
            }
          })
          setTotalItem(dataRes?.length || 0)

          setProductList(dataRes)
          setTotalPage(res?.totalRecords)
        } else {
          setTotalItem(0)
          setProductList([])
          setTotalPage(0)
        }
      })
      .catch((err) => {
        console.log({ err })
      })
      .finally(() => {
        setProductLoading(false)
      })
  }

  useEffect(() => {

    getProductListData()
    // eslint-disable-next-line
  }, [
    pageNow,
    categoryId,
    sizePage,
    monthlySortStatus,
    rePurchaseStatus,
    priceStatus,
    deliveryValue,
    performanceValue,
    searchValue
  ])

  return (
    <div
      style={{ width: '100%', background: '#F8F8F8', position: 'relative' }}
      className="exclusive-container"
    >
      <div style={{ width: '100%' }}>{titlePage()}</div>
      <Divider style={{ color: 'black', margin: 'unset' }} />
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
      >
        <div style={{ display: 'flex' }}>
          <div>
            <Button
              type="default"
              style={{
                background: 'black',
                color: 'white',
                fontSize: '16px',
                height: '60px'
              }}
            >
              Selected Goods
            </Button>
          </div>
        </div>
        <FilterExclusive
          loading={productLoading}
          dataCategory={categoryFilter}
          setCategoryId={setCategoryId}
          setPage={setPageNow}
          setMonthlySortStatus={setMonthlySortStatus}
          handleSortMonthly={handleSortMonthly}
          monthlySortStatus={monthlySortStatus}
          handleSortRePurchase={handleSortRePurchase}
          rePurchaseStatus={rePurchaseStatus}
          handleSortPrice={handleSortPrice}
          priceStatus={priceStatus}
          setSearchValue={setSearchValue}
          performanceOption={performanceOption}
          deliveryOption={deliveryOption}
          setDeliveryValue={setDeliveryValue}
          setPerformanceValue={setPerformanceValue}
          setPriceRange={setPriceRange}
        />
        <Spin spinning={productLoading}>
          <Row gutter={[5, 20]} style={{ minHeight: '400px' }}>
            {productList?.map((el) => {
              return (
                <CardExclusive
                  idProductLoading={idProductLoading}
                  offerId={el.offerId}
                  id={el.id}
                  data={el}
                  showModal={showModal}
                  handleCheckboxChange={handleCheckboxChange}
                  checkedData={checked}
                  collectFunction={handleSendCollectProduct}
                  loading={loadingCollect}
                />
              )
            })}
          </Row>
        </Spin>
      </div>
      <StickyFooter
        totalItem={totalItem}
        itemSelected={
          Object.values(checked).filter((val) => val === true).length || 0
        }
        pageSizeOption={['20', '40', '80']}
        localeSet=""
        itemRender={itemRender}
        handleCheckAll={handleCheckAll}
        handleUncheckAll={handleUncheckAll}
        onShowSizeChange={onShowSizeChange}
        ModalCom={RenderButtonModalOpen}
        onChangePage={onChangePage}
        page={pageNow}
        totalPage={totalPage}
        sizePage={sizePage}
      />
      <Modal
        footer={footerModal()}
        title={iconModel()}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          The submitted collection task will automatically claim to the selected
          platform &nbsp;
          <span style={{ fontWeight: 'bold' }}>[Collection Box]</span>
          &nbsp;after completion. You can go to the&nbsp;
          <span style={{ fontWeight: 'bold' }}>Collection Box</span>
          &nbsp;to edit and publish the products.
        </p>
      </Modal>
    </div>
  )
}

export default _1688Exclusive
