import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { getStaticText } from '../../utils'

const brandCrowdColumns = [
  {
    title: (
      <div>
        {getStaticText('monthly.sales.table.country')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('monthly.sales.table.country')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('goods.people_group')}</div>,
    dataIndex: 'people_group',
    key: 'people_group',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('goods.age')}</div>,
    dataIndex: 'age',
    key: 'age',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('goods.preference')}</div>,
    dataIndex: 'preference',
    key: 'preference',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('goods.professional')}</div>,
    dataIndex: 'professional',
    key: 'professional',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('goods.territory')}</div>,
    dataIndex: 'territory',
    key: 'territory',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('goods.goods_name')}</div>,
    dataIndex: 'goods_name',
    key: 'goods_name',
    fixed: 'left',
    width: 100,
    align: 'center'
  }
]

export default brandCrowdColumns
