import React, { useContext, useState } from 'react'
import { GridFormDrawer, GridContext } from '../../../../../components'
import CountryProduct from './CountryProduct'
import {
  getCutActivityProducts,
  saveProductAttributes
} from '../../../services'
import SelectProduct from './SelectProduct'

const CutProductForm = () => {
  const { record, toggle, onLoad } = useContext(GridContext)
  const [data, setData] = useState(null)
  const [editData, setEditData] = useState({})

  const initGridData = {
    current_page: 1,
    data: [],
    page_size: 10,
    total: 0
  }

  const closeDrawer = () => {
    toggle({}, '')
    onLoad()
  }

  const onOpen = () => {
    getCutActivityProducts((ret) => {
      setData(ret)
    })({ activity_id: record.id })
  }

  const paginationHandler = (current, pageSize) => {
    getCutActivityProducts((ret) => {
      setData(ret)
    })({
      id: record.id,
      page: current,
      page_size: pageSize
    })
  }
  const setProductAttribute = (productId, attr, value) => {
    const key = `product_${productId}`
    setEditData({
      ...editData,
      [key]: { ...editData[key], product_id: productId, [attr]: value }
    })
  }

  return (
    <>
      <GridFormDrawer
        onOpen={onOpen}
        onSave={() => {
          saveProductAttributes(closeDrawer)({ data: editData })
        }}
        onClose={closeDrawer}
        type="cut_activity_products"
        title="Select Activity Product"
      >
        <CountryProduct
          country={record.country}
          gridData={data || initGridData}
          paginationHandler={paginationHandler}
          setProductAttribute={setProductAttribute}
          reload={onOpen}
        />
      </GridFormDrawer>
      <SelectProduct reload={onOpen} />
    </>
  )
}

export default CutProductForm
