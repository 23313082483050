/* eslint-disable */
import React, {useEffect} from 'react'
import {Col, Input, InputNumber, Row, Select} from 'antd'
import SelectFilter from './SelectFilter'
import SortExclusive from './SortExclusive'

interface Props {
  dataCategory: Array;
  setCategoryId: Function;
  setPage: Function;
  handleSortMonthly: Function;
  monthlySortStatus: Number;
  handleSortRePurchase: Function;
  rePurchaseStatus: Function;
  handleSortPrice: Function;
  setSearchValue: Function;
  handleSearch: Function;
  loading: Boolean;
  performanceOption: Array;
  deliveryOption: Array;
  setDeliveryValue: Function;
  setPerformanceValue: Function;
  setPriceRange: Function;
}
const { Search } = Input

const FilterExclusive = ({
  dataCategory = [],
  setCategoryId,
  setPage,
  handleSortMonthly,
  monthlySortStatus,
  handleSortRePurchase,
  rePurchaseStatus,
  handleSortPrice,
  priceStatus,
  setSearchValue,
  loading,
  performanceOption,
  deliveryOption,
  setDeliveryValue,
  setPerformanceValue,
  setPriceRange
}: Props) => {
  const handleChangeSearch = (e) => {
    console.log(e)
    setSearchValue(e)
    setPage(1)
  }

  const handleFilterCategory = (e) => {
    setCategoryId(Number(e))
    if (setPage) {
      setPage(1)
    }
  }

  const handleFilterPrice = (e, type) => {
    const getData = setTimeout(() => {
      if (type === 'start') {
        setPriceRange((prev) => ({...prev, startPrice: e}))
      }
      if (type === 'end') {
        setPriceRange((prev) => ({...prev, endPrice: e}))
      }
      console.log(e)
    }, 2000)
    return () => clearTimeout(getData)
  }
  const handleFilterPerformance = (e) => {
    console.log(e)
    setPerformanceValue(e)
  }
  const handleFilterDeliveryTime = (e) => {
    setDeliveryValue(e)
  }

  return (
    <Row style={{ marginBlock: '30px' }} gutter={[20, 26]}>
      <Col span={4} />
      <Col span={16}>
        <Search
          loading={loading}
          className="search-exclusive"
          allowClear
          placeHolder="Please enter keywords or names to search for products"
          enterButton="Search"
          size="large"
          style={{ height: '60px', fontSize: '16px' }}
          onSearch={handleChangeSearch}
        />
      </Col>
      <Col span={4} />
      <SelectFilter
        loading={loading}
        spanCol={8}
        option={dataCategory}
        title="Category"
        functionFilter={handleFilterCategory}
      />
      <Col span={16} />
      <Col span={8}>
        <Row>
          <Col span={8} style={{ fontSize: '16px', fontWeight: 'bold' }}>
            Price
          </Col>
          <Col span={16}>
            <Row>
              <Col span={12}>
                <InputNumber style={{ width: '100%' }} placeholder="start price" onChange={(e) => handleFilterPrice(e, 'start')} />
              </Col>
              <Col span={12}>
                <InputNumber style={{ width: '100%' }} placeholder="end price" onChange={(e) => handleFilterPrice(e, 'end')} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <SelectFilter
        spanCol={8}
        option={performanceOption}
        title="Performance"
        functionFilter={handleFilterPerformance}
      />
      <SelectFilter
        spanCol={8}
        option={deliveryOption}
        title="Delivery Time"
        functionFilter={handleFilterDeliveryTime}
      />
      <Col span={14}>
        <Row>
          <Col span={4} style={{ fontSize: '16px', fontWeight: 'bold' }}>
            Sort
          </Col>
          <SortExclusive
            loading={loading}
            clickFunction={handleSortMonthly}
            title="Monthly sales"
            status={monthlySortStatus}
          />
          <SortExclusive
            loading={loading}
            clickFunction={handleSortRePurchase}
            title="Repurchase Rate"
            status={rePurchaseStatus}
          />
          <SortExclusive
            loading={loading}
            clickFunction={handleSortPrice}
            title="Wholesale price"
            status={priceStatus}
          />
        </Row>
      </Col>
    </Row>
  )
}

export default FilterExclusive
