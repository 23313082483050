import { TopupGrid, TopupOrderGrid, TopupAuditGrid } from '../pages'

const topup = {
  path: '/topup',
  primary: 'app.sider.recharge',
  modular: 'market',
  hideGlobalTips: true,
  scopes: ['xpark'],
  exact: true,
  routes: [
    {
      path: '/topup/activity',
      modular: 'market',
      permission: 'topup/activity/get-list',
      primary: 'app.sider.topup',
      component: TopupGrid,
      exact: true
    },
    {
      path: '/topup/order',
      modular: 'market',
      permission: 'topup/activity/order/get-list',
      primary: 'app.sider.topup.order',
      component: TopupOrderGrid,
      exact: true
    },
    {
      path: '/topup/audit',
      modular: 'market',
      permission: 'topup/activity/audit/get-list',
      primary: 'app.sider.topup.audit',
      component: TopupAuditGrid,
      exact: true
    }
  ]
}
export default topup
