import React from 'react'
import { Space, Typography } from 'antd'

import SearchSelect from '../SearchSelect'
import services from '../../../../services'

interface Props {
  country: String;
}

const { Text } = Typography

const ProductFilter = ({ country, onChange, mode, defaultValue }: Props) => {
  if (!country) {
    return null
  }

  const handleChange = (v) => {
    onChange(btoa(JSON.stringify(v)))
  }
  return (
    <>
      <Space>
        <Text>Product</Text>
        <div style={{ minWidth: '300px' }}>
          <SearchSelect
            showSearch
            country={country}
            fetchApi={services.bbs.getBbsProduct}
            onChange={handleChange}
            mode={mode}
            value={defaultValue ? JSON.parse(atob(defaultValue)) : undefined}
          />
        </div>
      </Space>
    </>
  )
}

export default ProductFilter
