import React from 'react'
import { shape } from 'prop-types'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

function Category(props) {
  const { tableData } = props
  return (
    <>
      {/* 类目 */}
      <tbody>
        {/* row1 */}
        <tr className="table-th-color">
          <th rowSpan="4" colSpan="2" className="sticky-left">
            类目
          </th>
          <th colSpan="16">手机</th>
          <th colSpan="16">配件</th>
          <th colSpan="16">美妆</th>
          <th colSpan="16">服饰</th>
          <th colSpan="16">鞋包配饰</th>
          <th colSpan="16">家居</th>
          <th colSpan="16">家电</th>
          <th colSpan="16">运动健身</th>
          <th colSpan="16">玩具</th>
          <th colSpan="16">跨境电商</th>
          <th colSpan="16">笔电</th>
          <th colSpan="16">TV</th>
          <th colSpan="16">潮鞋</th>
        </tr>

        {/* row2 */}
        <tr>
          {/* 手机 */}
          <th>
            类目规模 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交GMV
                    <br />
                    来源：数据拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            类目gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：类目站内成交GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成长性 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    同比
                    <br />
                    来源：今年类目GMV/去年同期类目GMV-1
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            跨境电商GMV
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交的跨境电商GMV
                    <br />
                    来源：数据抓取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="4">
            类目布局 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    案例：在五大面部护肤类目中，面膜类目GMV排名第二，访客数排名第一，转化率排名第二（仅次于洁面），支付买家数第一（说明面膜属于高频消费品），客单价排名第四（仅高于洁面），UV价值排名第三；说明面膜类目适合用于设计产品引流，再利用高客单、高利润的产品去承接流量和提升整体利润率；同时，面膜也适合与其他类目产品进行搭配销售。
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="6">
            促销驱动力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    根据直降，买赠，满减三种促销方式，判断哪种方式对流量/转化率/客单价的影响最明显，从而引发GMV的显著提升。
                    br 只针对订单中有折扣，买赠，满减的订单经行计算
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆发力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    全球的4月份都是平销，所以日销：4月份每天平均的销售
                    <br />
                    S级别大促：活动期间日平均GMV/平销月分平均日GMV
                    <br />
                    报表中增加一个模块，添加S级别大促时间，时间如果跨月，按照月份拆分GMV
                    <br />
                    爆发力：这个月内S级别大促的总GMV/去年同区间GMV
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆款数 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>期间内销售数量超过5000(可配置)的产品数据，统计到SPU级别</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 配件 */}
          <th>
            类目规模 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交GMV
                    <br />
                    来源：数据拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            类目gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：类目站内成交GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成长性 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    同比
                    <br />
                    来源：今年类目GMV/去年同期类目GMV-1
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            跨境电商GMV
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交的跨境电商GMV
                    <br />
                    来源：数据抓取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="4">
            类目布局 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    案例：在五大面部护肤类目中，面膜类目GMV排名第二，访客数排名第一，转化率排名第二（仅次于洁面），支付买家数第一（说明面膜属于高频消费品），客单价排名第四（仅高于洁面），UV价值排名第三；说明面膜类目适合用于设计产品引流，再利用高客单、高利润的产品去承接流量和提升整体利润率；同时，面膜也适合与其他类目产品进行搭配销售。
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="6">
            促销驱动力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    根据直降，买赠，满减三种促销方式，判断哪种方式对流量/转化率/客单价的影响最明显，从而引发GMV的显著提升。
                    br 只针对订单中有折扣，买赠，满减的订单经行计算
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆发力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    全球的4月份都是平销，所以日销：4月份每天平均的销售
                    <br />
                    S级别大促：活动期间日平均GMV/平销月分平均日GMV
                    <br />
                    报表中增加一个模块，添加S级别大促时间，时间如果跨月，按照月份拆分GMV
                    <br />
                    爆发力：这个月内S级别大促的总GMV/去年同区间GMV
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆款数 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>期间内销售数量超过5000(可配置)的产品数据，统计到SPU级别</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 美妆 */}
          <th>
            类目规模 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交GMV
                    <br />
                    来源：数据拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            类目gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：类目站内成交GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成长性 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    同比
                    <br />
                    来源：今年类目GMV/去年同期类目GMV-1
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            跨境电商GMV
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交的跨境电商GMV
                    <br />
                    来源：数据抓取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="4">
            类目布局 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    案例：在五大面部护肤类目中，面膜类目GMV排名第二，访客数排名第一，转化率排名第二（仅次于洁面），支付买家数第一（说明面膜属于高频消费品），客单价排名第四（仅高于洁面），UV价值排名第三；说明面膜类目适合用于设计产品引流，再利用高客单、高利润的产品去承接流量和提升整体利润率；同时，面膜也适合与其他类目产品进行搭配销售。
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="6">
            促销驱动力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    根据直降，买赠，满减三种促销方式，判断哪种方式对流量/转化率/客单价的影响最明显，从而引发GMV的显著提升。
                    br 只针对订单中有折扣，买赠，满减的订单经行计算
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆发力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    全球的4月份都是平销，所以日销：4月份每天平均的销售
                    <br />
                    S级别大促：活动期间日平均GMV/平销月分平均日GMV
                    <br />
                    报表中增加一个模块，添加S级别大促时间，时间如果跨月，按照月份拆分GMV
                    <br />
                    爆发力：这个月内S级别大促的总GMV/去年同区间GMV
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆款数 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>期间内销售数量超过5000(可配置)的产品数据，统计到SPU级别</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 鞋包配饰 */}
          <th>
            类目规模 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交GMV
                    <br />
                    来源：数据拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            类目gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：类目站内成交GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成长性 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    同比
                    <br />
                    来源：今年类目GMV/去年同期类目GMV-1
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            跨境电商GMV
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交的跨境电商GMV
                    <br />
                    来源：数据抓取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="4">
            类目布局 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    案例：在五大面部护肤类目中，面膜类目GMV排名第二，访客数排名第一，转化率排名第二（仅次于洁面），支付买家数第一（说明面膜属于高频消费品），客单价排名第四（仅高于洁面），UV价值排名第三；说明面膜类目适合用于设计产品引流，再利用高客单、高利润的产品去承接流量和提升整体利润率；同时，面膜也适合与其他类目产品进行搭配销售。
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="6">
            促销驱动力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    根据直降，买赠，满减三种促销方式，判断哪种方式对流量/转化率/客单价的影响最明显，从而引发GMV的显著提升。
                    br 只针对订单中有折扣，买赠，满减的订单经行计算
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆发力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    全球的4月份都是平销，所以日销：4月份每天平均的销售
                    <br />
                    S级别大促：活动期间日平均GMV/平销月分平均日GMV
                    <br />
                    报表中增加一个模块，添加S级别大促时间，时间如果跨月，按照月份拆分GMV
                    <br />
                    爆发力：这个月内S级别大促的总GMV/去年同区间GMV
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆款数 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>期间内销售数量超过5000(可配置)的产品数据，统计到SPU级别</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 家居 */}
          <th>
            类目规模 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交GMV
                    <br />
                    来源：数据拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            类目gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：类目站内成交GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成长性 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    同比
                    <br />
                    来源：今年类目GMV/去年同期类目GMV-1
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            跨境电商GMV
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交的跨境电商GMV
                    <br />
                    来源：数据抓取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="4">
            类目布局 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    案例：在五大面部护肤类目中，面膜类目GMV排名第二，访客数排名第一，转化率排名第二（仅次于洁面），支付买家数第一（说明面膜属于高频消费品），客单价排名第四（仅高于洁面），UV价值排名第三；说明面膜类目适合用于设计产品引流，再利用高客单、高利润的产品去承接流量和提升整体利润率；同时，面膜也适合与其他类目产品进行搭配销售。
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="6">
            促销驱动力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    根据直降，买赠，满减三种促销方式，判断哪种方式对流量/转化率/客单价的影响最明显，从而引发GMV的显著提升。
                    br 只针对订单中有折扣，买赠，满减的订单经行计算
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆发力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    全球的4月份都是平销，所以日销：4月份每天平均的销售
                    <br />
                    S级别大促：活动期间日平均GMV/平销月分平均日GMV
                    <br />
                    报表中增加一个模块，添加S级别大促时间，时间如果跨月，按照月份拆分GMV
                    <br />
                    爆发力：这个月内S级别大促的总GMV/去年同区间GMV
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆款数 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>期间内销售数量超过5000(可配置)的产品数据，统计到SPU级别</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 家电 */}
          <th>
            类目规模 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交GMV
                    <br />
                    来源：数据拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            类目gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：类目站内成交GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成长性 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    同比
                    <br />
                    来源：今年类目GMV/去年同期类目GMV-1
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            跨境电商GMV
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交的跨境电商GMV
                    <br />
                    来源：数据抓取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="4">
            类目布局 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    案例：在五大面部护肤类目中，面膜类目GMV排名第二，访客数排名第一，转化率排名第二（仅次于洁面），支付买家数第一（说明面膜属于高频消费品），客单价排名第四（仅高于洁面），UV价值排名第三；说明面膜类目适合用于设计产品引流，再利用高客单、高利润的产品去承接流量和提升整体利润率；同时，面膜也适合与其他类目产品进行搭配销售。
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="6">
            促销驱动力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    根据直降，买赠，满减三种促销方式，判断哪种方式对流量/转化率/客单价的影响最明显，从而引发GMV的显著提升。
                    br 只针对订单中有折扣，买赠，满减的订单经行计算
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆发力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    全球的4月份都是平销，所以日销：4月份每天平均的销售
                    <br />
                    S级别大促：活动期间日平均GMV/平销月分平均日GMV
                    <br />
                    报表中增加一个模块，添加S级别大促时间，时间如果跨月，按照月份拆分GMV
                    <br />
                    爆发力：这个月内S级别大促的总GMV/去年同区间GMV
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆款数 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>期间内销售数量超过5000(可配置)的产品数据，统计到SPU级别</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 运动健身 */}
          <th>
            类目规模 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交GMV
                    <br />
                    来源：数据拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            类目gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：类目站内成交GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成长性 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    同比
                    <br />
                    来源：今年类目GMV/去年同期类目GMV-1
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            跨境电商GMV
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交的跨境电商GMV
                    <br />
                    来源：数据抓取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="4">
            类目布局 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    案例：在五大面部护肤类目中，面膜类目GMV排名第二，访客数排名第一，转化率排名第二（仅次于洁面），支付买家数第一（说明面膜属于高频消费品），客单价排名第四（仅高于洁面），UV价值排名第三；说明面膜类目适合用于设计产品引流，再利用高客单、高利润的产品去承接流量和提升整体利润率；同时，面膜也适合与其他类目产品进行搭配销售。
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="6">
            促销驱动力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    根据直降，买赠，满减三种促销方式，判断哪种方式对流量/转化率/客单价的影响最明显，从而引发GMV的显著提升。
                    br 只针对订单中有折扣，买赠，满减的订单经行计算
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆发力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    全球的4月份都是平销，所以日销：4月份每天平均的销售
                    <br />
                    S级别大促：活动期间日平均GMV/平销月分平均日GMV
                    <br />
                    报表中增加一个模块，添加S级别大促时间，时间如果跨月，按照月份拆分GMV
                    <br />
                    爆发力：这个月内S级别大促的总GMV/去年同区间GMV
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆款数 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>期间内销售数量超过5000(可配置)的产品数据，统计到SPU级别</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>

          {/* 玩具 */}
          <th>
            类目规模 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交GMV
                    <br />
                    来源：数据拉取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            类目gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：类目站内成交GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            成长性 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    同比
                    <br />
                    来源：今年类目GMV/去年同期类目GMV-1
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            跨境电商GMV
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    站内成交的跨境电商GMV
                    <br />
                    来源：数据抓取
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="4">
            类目布局 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    案例：在五大面部护肤类目中，面膜类目GMV排名第二，访客数排名第一，转化率排名第二（仅次于洁面），支付买家数第一（说明面膜属于高频消费品），客单价排名第四（仅高于洁面），UV价值排名第三；说明面膜类目适合用于设计产品引流，再利用高客单、高利润的产品去承接流量和提升整体利润率；同时，面膜也适合与其他类目产品进行搭配销售。
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th colSpan="6">
            促销驱动力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    根据直降，买赠，满减三种促销方式，判断哪种方式对流量/转化率/客单价的影响最明显，从而引发GMV的显著提升。
                    br 只针对订单中有折扣，买赠，满减的订单经行计算
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆发力 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>
                    全球的4月份都是平销，所以日销：4月份每天平均的销售
                    <br />
                    S级别大促：活动期间日平均GMV/平销月分平均日GMV
                    <br />
                    报表中增加一个模块，添加S级别大促时间，时间如果跨月，按照月份拆分GMV
                    <br />
                    爆发力：这个月内S级别大促的总GMV/去年同区间GMV
                  </p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            爆款数 &nbsp;
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>期间内销售数量超过5000(可配置)的产品数据，统计到SPU级别</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
        </tr>

        {/* row3 */}
        <tr>
          {/* 手机 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 配件 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 美妆 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 服饰 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 鞋包配饰 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 家居 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 家电 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 运动健身 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 玩具 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 跨境 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 笔电 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* TV */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>

          {/* 笔电 */}
          <th>USD</th>
          <th>%</th>
          <th>%</th>
          <th>USD</th>
          <th>
            引流类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            核心类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            形象类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            利润类目
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            直降价gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－直降价GMV/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            送赠品gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－送赠品/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：数据抓取</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>
            满减券gmv占比
            <Tooltip
              title={() => (
                <div className="toolTipStyle">
                  <p>来源：促销驱动力－滿减券/总GMV</p>
                </div>
              )}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </th>
          <th>%</th>
          <th>个</th>
        </tr>
        {/* row4 */}
        <tr>
          <td>{tableData.phone_gmv_actual}</td>
          <td>{tableData.phone_gmv_rate_actual}</td>
          <td>{tableData.phone_gmv_rate_year_on_year}</td>
          <td>{tableData.phone_cross_gmv_actual}</td>
          <td>{tableData.phone_layout_flow}</td>
          <td>{tableData.phone_layout_core}</td>
          <td>{tableData.phone_layout_appearence}</td>
          <td>{tableData.phone_layout_profit}</td>
          <td>{tableData.phone_promo_debate}</td>
          <td>{tableData.phone_promo_debate_rate}</td>
          <td>{tableData.phone_promo_gift}</td>
          <td>{tableData.phone_promo_gift_rate}</td>
          <td>{tableData.phone_promo_coupon}</td>
          <td>{tableData.phone_promo_coupon_rate}</td>
          <td>{tableData.phone_burst_rate}</td>
          <td>{tableData.phone_hot_products_actual}</td>
          <td>{tableData.accessory_gmv_actual}</td>
          <td>{tableData.accessory_gmv_rate_actual}</td>
          <td>{tableData.accessory_gmv_rate_year_on_year}</td>
          <td>{tableData.accessory_cross_gmv_actual}</td>
          <td>{tableData.accessory_layout_flow}</td>
          <td>{tableData.accessory_layout_core}</td>
          <td>{tableData.accessory_layout_appearence}</td>
          <td>{tableData.accessory_layout_profit}</td>
          <td>{tableData.accessory_promo_debate}</td>
          <td>{tableData.accessory_promo_debate_rate}</td>
          <td>{tableData.accessory_promo_gift}</td>
          <td>{tableData.accessory_promo_gift_rate}</td>
          <td>{tableData.accessory_promo_coupon}</td>
          <td>{tableData.accessory_promo_coupon_rate}</td>
          <td>{tableData.accessory_burst_rate}</td>
          <td>{tableData.accessory_hot_products_actual}</td>
          <td>{tableData.makeups_gmv_actual}</td>
          <td>{tableData.makeups_gmv_rate_actual}</td>
          <td>{tableData.makeups_gmv_rate_year_on_year}</td>
          <td>{tableData.makeups_cross_gmv_actual}</td>
          <td>{tableData.makeups_layout_flow}</td>
          <td>{tableData.makeups_layout_core}</td>
          <td>{tableData.makeups_layout_appearence}</td>
          <td>{tableData.makeups_layout_profit}</td>
          <td>{tableData.makeups_promo_debate}</td>
          <td>{tableData.makeups_promo_debate_rate}</td>
          <td>{tableData.makeups_promo_gift}</td>
          <td>{tableData.makeups_promo_gift_rate}</td>
          <td>{tableData.makeups_promo_coupon}</td>
          <td>{tableData.makeups_promo_coupon_rate}</td>
          <td>{tableData.makeups_burst_rate}</td>
          <td>{tableData.makeups_hot_products_actual}</td>
          <td>{tableData.cloth_gmv_actual}</td>
          <td>{tableData.cloth_gmv_rate_actual}</td>
          <td>{tableData.cloth_gmv_rate_year_on_year}</td>
          <td>{tableData.cloth_cross_gmv_actual}</td>
          <td>{tableData.cloth_layout_flow}</td>
          <td>{tableData.cloth_layout_core}</td>
          <td>{tableData.cloth_layout_appearence}</td>
          <td>{tableData.cloth_layout_profit}</td>
          <td>{tableData.cloth_promo_debate}</td>
          <td>{tableData.cloth_promo_debate_rate}</td>
          <td>{tableData.cloth_promo_gift}</td>
          <td>{tableData.cloth_promo_gift_rate}</td>
          <td>{tableData.cloth_promo_coupon}</td>
          <td>{tableData.cloth_promo_coupon_rate}</td>
          <td>{tableData.cloth_burst_rate}</td>
          <td>{tableData.cloth_hot_products_actual}</td>
          <td>{tableData.shoes_bag_gmv_actual}</td>
          <td>{tableData.shoes_bag_gmv_rate_actual}</td>
          <td>{tableData.shoes_bag_gmv_rate_year_on_year}</td>
          <td>{tableData.shoes_cross_gmv_actual}</td>
          <td>{tableData.shoes_bag_layout_flow}</td>
          <td>{tableData.shoes_bag_layout_core}</td>
          <td>{tableData.shoes_bag_layout_appearence}</td>
          <td>{tableData.shoes_bag_layout_profit}</td>
          <td>{tableData.shoes_bag_promo_debate}</td>
          <td>{tableData.shoes_bag_promo_debate_rate}</td>
          <td>{tableData.shoes_bag_promo_gift}</td>
          <td>{tableData.shoes_bag_promo_gift_rate}</td>
          <td>{tableData.shoes_bag_promo_coupon}</td>
          <td>{tableData.shoes_bag_promo_coupon_rate}</td>
          <td>{tableData.shoes_bag_burst_rate}</td>
          <td>{tableData.shoes_bag_hot_products_actual}</td>
          <td>{tableData.furniture_gmv_actual}</td>
          <td>{tableData.furniture_gmv_rate_actual}</td>
          <td>{tableData.furniture_gmv_rate_year_on_year}</td>
          <td>{tableData.furniture_cross_gmv_actual}</td>
          <td>{tableData.furniture_layout_flow}</td>
          <td>{tableData.furniture_layout_core}</td>
          <td>{tableData.furniture_layout_appearence}</td>
          <td>{tableData.furniture_layout_profit}</td>
          <td>{tableData.furniture_promo_debate}</td>
          <td>{tableData.furniture_promo_debate_rate}</td>
          <td>{tableData.furniture_promo_gift}</td>
          <td>{tableData.furniture_promo_gift_rate}</td>
          <td>{tableData.furniture_promo_coupon}</td>
          <td>{tableData.furniture_promo_coupon_rate}</td>
          <td>{tableData.furniture_burst_rate}</td>
          <td>{tableData.furniture_hot_products_actual}</td>
          <td>{tableData.appliances_gmv_actual}</td>
          <td>{tableData.appliances_gmv_rate_actual}</td>
          <td>{tableData.appliances_gmv_rate_year_on_year}</td>
          <td>{tableData.appliances_cross_gmv_actual}</td>
          <td>{tableData.appliances_layout_flow}</td>
          <td>{tableData.appliances_layout_core}</td>
          <td>{tableData.appliances_layout_appearence}</td>
          <td>{tableData.appliances_layout_profit}</td>
          <td>{tableData.furniture_promo_debate}</td>
          <td>{tableData.furniture_promo_debate_rate}</td>
          <td>{tableData.appliances_promo_gift}</td>
          <td>{tableData.appliances_promo_gift_rate}</td>
          <td>{tableData.appliances_promo_coupon}</td>
          <td>{tableData.appliances_promo_coupon_rate}</td>
          <td>{tableData.appliances_burst_rate}</td>
          <td>{tableData.appliances_hot_products_actual}</td>
          <td>{tableData.fitness_gmv_actual}</td>
          <td>{tableData.fitness_gmv_rate_actual}</td>
          <td>{tableData.fitness_gmv_rate_year_on_year}</td>
          <td>{tableData.fitness_cross_gmv_actual}</td>
          <td>{tableData.fitness_layout_flow}</td>
          <td>{tableData.fitness_layout_core}</td>
          <td>{tableData.fitness_layout_appearence}</td>
          <td>{tableData.fitness_layout_profit}</td>
          <td>{tableData.fitness_promo_debate}</td>
          <td>{tableData.fitness_promo_debate_rate}</td>
          <td>{tableData.fitness_promo_gift}</td>
          <td>{tableData.fitness_promo_gift_rate}</td>
          <td>{tableData.fitness_promo_coupon}</td>
          <td>{tableData.fitness_promo_coupon_rate}</td>
          <td>{tableData.fitness_burst_rate}</td>
          <td>{tableData.fitness_hot_products_actual}</td>
          <td>{tableData.toys_gmv_actual}</td>
          <td>{tableData.toys_gmv_rate_actual}</td>
          <td>{tableData.toys_gmv_rate_year_on_year}</td>
          <td>{tableData.toys_cross_gmv_actual}</td>
          <td>{tableData.toys_layout_flow}</td>
          <td>{tableData.toys_layout_core}</td>
          <td>{tableData.toys_layout_appearence}</td>
          <td>{tableData.toys_layout_profit}</td>
          <td>{tableData.toys_promo_debate}</td>
          <td>{tableData.toys_promo_debate_rate}</td>
          <td>{tableData.toys_promo_gift}</td>
          <td>{tableData.toys_promo_gift_rate}</td>
          <td>{tableData.toys_promo_coupon}</td>
          <td>{tableData.toys_promo_coupon_rate}</td>
          <td>{tableData.toys_burst_rate}</td>
          <td>{tableData.toys_hot_products_actual}</td>
          <td>{tableData.cross_bord_gmv_actual}</td>
          <td>{tableData.cross_bord_gmv_rate_actual}</td>
          <td>{tableData.cross_bord_gmv_rate_year_on_year}</td>
          <td>{tableData.cross_bord_cross_gmv_actual}</td>
          <td>{tableData.cross_bord_layout_flow}</td>
          <td>{tableData.cross_bord_layout_core}</td>
          <td>{tableData.cross_bord_layout_appearence}</td>
          <td>{tableData.cross_bord_layout_profit}</td>
          <td>{tableData.cross_bord_promo_debate}</td>
          <td>{tableData.cross_bord_promo_debate_rate}</td>
          <td>{tableData.cross_bord_promo_gift}</td>
          <td>{tableData.cross_bord_promo_gift_rate}</td>
          <td>{tableData.cross_bord_promo_coupon}</td>
          <td>{tableData.cross_bord_promo_coupon_rate}</td>
          <td>{tableData.cross_bord_burst_rate}</td>
          <td>{tableData.cross_bord_hot_products_actual}</td>
          <td>{tableData.laptop_gmv_actual}</td>
          <td>{tableData.laptop_gmv_rate_actual}</td>
          <td>{tableData.laptop_gmv_rate_year_on_year}</td>
          <td>{tableData.laptop_cross_gmv_actual}</td>
          <td>{tableData.laptop_layout_flow}</td>
          <td>{tableData.laptop_layout_core}</td>
          <td>{tableData.laptop_layout_appearence}</td>
          <td>{tableData.laptop_layout_profit}</td>
          <td>{tableData.laptop_promo_debate}</td>
          <td>{tableData.laptop_promo_debate_rate}</td>
          <td>{tableData.laptop_promo_gift}</td>
          <td>{tableData.laptop_promo_gift_rate}</td>
          <td>{tableData.laptop_promo_coupon}</td>
          <td>{tableData.laptop_promo_coupon_rate}</td>
          <td>{tableData.laptop_burst_rate}</td>
          <td>{tableData.laptop_hot_products_actual}</td>
          <td>{tableData.tv_gmv_actual}</td>
          <td>{tableData.tv_gmv_rate_actual}</td>
          <td>{tableData.tv_gmv_rate_year_on_year}</td>
          <td>{tableData.tv_cross_gmv_actual}</td>
          <td>{tableData.tv_layout_flow}</td>
          <td>{tableData.tv_layout_core}</td>
          <td>{tableData.tv_layout_appearence}</td>
          <td>{tableData.tv_layout_profit}</td>
          <td>{tableData.tv_promo_debate}</td>
          <td>{tableData.tv_promo_debate_rate}</td>
          <td>{tableData.tv_promo_gift}</td>
          <td>{tableData.tv_promo_gift_rate}</td>
          <td>{tableData.tv_promo_coupon}</td>
          <td>{tableData.tv_promo_coupon_rate}</td>
          <td>{tableData.tv_burst_rate}</td>
          <td>{tableData.tv_hot_products_actual}</td>
          <td>{tableData.fashion_sh_gmv_actual}</td>
          <td>{tableData.fashion_sh_gmv_rate_actual}</td>
          <td>{tableData.fashion_sh_gmv_rate_year_on_year}</td>
          <td>{tableData.fashion_sh_cross_gmv_actual}</td>
          <td>{tableData.fashion_sh_layout_flow}</td>
          <td>{tableData.fashion_sh_layout_core}</td>
          <td>{tableData.fashion_sh_layout_appearence}</td>
          <td>{tableData.fashion_sh_layout_profit}</td>
          <td>{tableData.fashion_sh_promo_debate}</td>
          <td>{tableData.fashion_sh_promo_debate_rate}</td>
          <td>{tableData.fashion_sh_promo_gift}</td>
          <td>{tableData.fashion_sh_promo_gift_rate}</td>
          <td>{tableData.fashion_sh_promo_coupon}</td>
          <td>{tableData.fashion_sh_promo_coupon_rate}</td>
          <td>{tableData.fashion_sh_burst_rate}</td>
          <td>{tableData.fashion_sh_hot_products_actual}</td>
        </tr>
      </tbody>
    </>
  )
}

Category.propTypes = {
  tableData: shape({}).isRequired
}

export default Category
