import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  Card,
  Space,
  Typography,
  Select,
  Row,
  Col,
  Button,
  Spin,
  message
} from 'antd'
import PropTypes from 'prop-types'
import { withTheme } from '@rjsf/core'
import { Theme as AntDTheme } from '@rjsf/antd'

import { getStaticText, storage } from '../../utils'
import services from '../../services'

const { Text } = Typography
const { Option } = Select

const Form = withTheme(AntDTheme)

function SettingDemo(props) {
  const {
    history,
    location: { pathname, search }
  } = props

  const platforms = useMemo(() => {
    return storage.allowedPlatforms.val || {}
  }, [])

  // 默认请求参数
  const defaultReqData = {
    country: 'GLOBAL',
    platform: 'All_Platform'
  }

  const [isLoading, setIsLoading] = useState(false)
  const toggleLoading = useCallback(() => {
    setIsLoading((prevState) => !prevState)
  }, [])

  const [formSchema, setFormSchema] = useState({})
  const [formData, setFormData] = useState({})

  // 从URL获取参数
  const nextSearch = new URLSearchParams(search)
  const nextSearchObj = () => {
    return {
      country: nextSearch.get('country'),
      platform: nextSearch.get('platform')
    }
  }
  Object.keys(nextSearchObj()).forEach((item) => {
    if (nextSearchObj()[item]) defaultReqData[item] = nextSearchObj()[item]
  })

  const [requestParams, setRequestParams] = useState(() => {
    return {
      country: defaultReqData.country
        .split(',')
        .filter((item) => !(item === '')),
      platform: defaultReqData.platform
        .split(',')
        .filter((item) => !(item === ''))
    }
  })

  // 转换请求的参数
  const requestParamsTransformer = useMemo(() => {
    return {
      country: requestParams.country.toString(),
      platform: requestParams.platform.toString()
    }
  }, [requestParams])

  // 添加 global 并判断 disabled 属性
  const countries = useMemo(() => {
    const country = storage.allowedCountries.val || []
    country.shift()
    country.push({
      label: getStaticText('GLOBAL'),
      value: 'GLOBAL',
      disabled: false
    })
    return country
  }, [])

  // 过滤出可供选择的平台
  const filterPlatform = useMemo(() => {
    if (requestParams.country.toString() === 'GLOBAL')
      return [
        {
          label: getStaticText('All_Platform'),
          value: 'All_Platform',
          disabled: false
        }
      ]
    let filterPlatformArray = []
    filterPlatformArray = platforms[requestParams.country] || []
    return filterPlatformArray
  }, [requestParams, platforms])

  const fetchSchema = useCallback(() => {
    toggleLoading()

    services.settings
      .getSettingSchema(requestParamsTransformer)
      .then((result) => {
        const schema = result.schema || {}
        const data = result.formData || {}

        setFormSchema(schema)
        setFormData(data)
      })
      .finally(() => {
        toggleLoading()
      })
  }, [toggleLoading, requestParamsTransformer])

  const setUrlSearchForState = useCallback(() => {
    const params = new URLSearchParams(requestParamsTransformer)

    history.push(`${pathname}?${params.toString()}`)
  }, [requestParamsTransformer, history, pathname])

  const [editLoading, setEditLoading] = useState(false)
  const handleSubmit = ({ formData: data }) => {
    setEditLoading(true)
    setFormData(data)
    services.settings
      .saveSettingSchema({
        data,
        ...requestParamsTransformer
      })
      .then(() => {
        setEditLoading(false)
        message.success('Success')
      })
      .catch(() => {
        fetchSchema()
        message.error('error')
      })
      .finally(() => {
        setEditLoading(false)
      })
  }

  const handleCountryChange = (country) => {
    setRequestParams((prevState) => ({
      ...prevState,
      country
    }))
  }

  const handlePlatformChange = (platform) => {
    setRequestParams((prevState) => ({
      ...prevState,
      platform
    }))
  }

  useEffect(() => {
    fetchSchema()
    setUrlSearchForState()
    return () => {}
  }, [fetchSchema, setUrlSearchForState])

  return (
    <>
      <Card
        style={{
          marginBottom: 20
        }}
      >
        <Space>
          <Text>{getStaticText('component.search.country.text')}</Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.country}
            onChange={handleCountryChange}
            // mode="multiple"
            maxTagCount="responsive"
          >
            {countries.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
          <Text style={{ marginLeft: 20 }}>
            {getStaticText('component.search.platform.text')}
          </Text>
          <Select
            style={{ width: 220 }}
            value={requestParams.platform}
            onChange={handlePlatformChange}
            // mode="multiple"
            maxTagCount="responsive"
          >
            {filterPlatform.map((item) => (
              <Option key={item.value} disabled={item.disabled}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Space>
      </Card>

      {/* <Card>
        <Text>{getStaticText('component.table.header.title')}</Text>
      </Card> */}

      <Card>
        <Row justify="center">
          <Col span={10}>
            {isLoading ? (
              <Spin />
            ) : (
              <Form
                schema={formSchema}
                formData={formData}
                onSubmit={handleSubmit}
              >
                <div>
                  <Button htmlType="submit" loading={editLoading}>
                    {getStaticText('submit')}
                  </Button>
                </div>
              </Form>
            )}
          </Col>
        </Row>
      </Card>
    </>
  )
}

SettingDemo.propTypes = {
  history: PropTypes.shape().isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired
  }).isRequired
}

export default SettingDemo
