import React from 'react'
import Actions from './Actions'

export const columns = [
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country',
    align: 'center'
  },
  {
    title: 'Redirect Type',
    dataIndex: 'redirect_type',
    key: 'redirect_type',
    align: 'center'
  },
  {
    title: 'Url Key',
    dataIndex: 'url_key',
    key: 'url_key',
    align: 'center'
  },
  {
    title: 'Target Url',
    dataIndex: 'target_url',
    key: 'target_url',
    align: 'center'
  },
  {
    title: 'Target Id',
    dataIndex: 'target_id',
    key: 'target_id',
    align: 'center'
  },
  {
    title: 'Page Type',
    dataIndex: 'page_type',
    key: 'page_type',
    align: 'center'
  },
  {
    title: 'Action',
    dataIndex: '',
    width: 200,
    key: 'x',
    align: 'center',
    render: (record) => <Actions record={record} />
  }
]

export const initGridData = {
  current_page: 1,
  data: [],
  page_size: 10,
  total: 0
}

export const dataSourceMap = (result) => {
  return result.map((i) => i)
}
