/* eslint-disable */
import React, {useEffect} from 'react'
import {Col, Divider, Form, Row, Typography} from 'antd'
import RegularInput from '../../../components/1688ProjectComponents/inputComponent/RegularInput'

const { Title } = Typography
interface Props {
  productDetailData: any;
  setBasicInformation: Function;
}

const BasicInformation = (props: Props) => {
  const [formBasicInformation] = Form.useForm();

  useEffect(() => {
    if (props.productDetailData?.subject_trans) formBasicInformation.setFieldValue("subject_trans", props.productDetailData?.subject_trans)
    if (props.productDetailData?.category_id) formBasicInformation.setFieldValue("category_id", props.productDetailData?.category_id)
  }, [props.productDetailData]);

  function handleFormChange() {
    props.setBasicInformation(formBasicInformation.getFieldsValue())
  }

  const titlePage = () => {
    return (
      <Title level={2} style={{ marginBottom: 0, padding: '24px' }}>
        Basic Information
      </Title>
    )
  }
  return (
    <>
      <div style={{ width: '100%' }}>{titlePage()}</div>
      <Divider style={{ color: 'black', margin: 'unset' }} />
      <div
        style={{ display: 'flex', flexDirection: 'column', padding: '24px 0' }}
      >
        <Form form={formBasicInformation} onValuesChange={() => handleFormChange()}>
          <Row gutter={[0, 10]}>
            <Col span={12}>
              <RegularInput
                name="subject_trans"
                title="Product Title"
                style={{ width: '200px' }}
                colon
                space="52px"
                placeHolder="Please enter the product title, separated by commas"
              />
            </Col>
            <Col span={12} />
            <Col span={12}>
              <RegularInput
                name="category_id"
                title="Supply ID"
                style={{ width: '200px' }}
                colon
                space="70px"
                placeHolder="Please enter the Supply ID, separated by commas"
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default BasicInformation
