import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getUserList = (handler) =>
  withTableLoading((params) =>
    services.membership.getUserList(params).then(handler)
  )

export const getUserDetail = (handler) =>
  withGlobalLoading((id) =>
    services.membership.getUserDetail({ id }).then(handler)
  )

export const getPointHistory = (handler) =>
  withGlobalLoading((params) =>
    services.membership.getPointHistory(params).then(handler)
  )
