import React from 'react'
import { Space, Typography } from 'antd'

import SourceSelect from '../SourceSelect'

interface Props {
  onChange: () => {};
}

const { Text } = Typography

const SourceFilter = ({ label, source, onChange, defaultValue }: Props) => {
  if (!source) {
    return null
  }

  const handleChange = (v) => {
    onChange(v)
  }

  return (
    <>
      <Space>
        <Text>{label}</Text>
        <div style={{ width: '200px' }}>
          <SourceSelect
            style={{ width: '100%' }}
            source={source}
            onChange={handleChange}
            value={defaultValue}
          />
        </div>
      </Space>
    </>
  )
}

export default SourceFilter
