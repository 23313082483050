import request from '../request'

export const getProductDetail = (params) =>
  request.get('/backend/alibaba/selection/getProductDetail', { params })

export const getProductDetailCollection = (params) =>
  request.get('/backend/alibaba/box/getProductDetail', { params })

export const sendPubBoxClaim = (data) =>
  request.post('/backend/alibaba/pubBox/claim', data)

export const sendCollectionUpdate = (data) =>
  request.post('/backend/alibaba/box/updateProduct', data)
export const getPubBoxProductList = (params) =>
  request.get('/backend/alibaba/pubBox/getProductList', { params })

export const getBoxProductList = (params) =>
  request.get('/backend/alibaba/box/getProductList', { params })

export const sendRelease = (data) =>
  request.post('/backend/alibaba/box/publish', data)

export const deleteProduct = (data) =>
  request.post('/backend/alibaba/pubBox/deleteProduct', data)

export const deleteProductColBox = (data) =>
  request.post('/backend/alibaba/box/deleteProduct', data)

export const editCategory = (data) =>
  request.post('/backend/alibaba/box/updateProductCategory', data)

export const getReleaseRecord = (params) =>
  request.get('/backend/alibaba/releaseRecord/getReleaseRecordList', { params })

export const getStatisticRelease = (params) =>
  request.get('/backend/alibaba/ReleaseRecord/GetReleaseRecordStatisticList', {
    params
  })

export const getAuthorPubBox = (params) =>
  request.get('/backend/alibaba/pubBox/getAuthorList', { params })

export const getAuthorList = (params) =>
  request.get('/backend/alibaba/box/getAuthorList', { params })

export const deleteReleaseRecord = (body) =>
  request.post('/backend/alibaba/releaseRecord/deleteReleaseRecord', body)

export const sendPricingTemplate = (data) =>
  request.post('/backend/alibaba/box/setSellingPrice', data)

export const calculateSellingPriceTemplate = (params) =>
  request.get('/backend/alibaba/pricingTemplate/calculatePrice', { params })

export const getOrderList = (params) =>
  request.get('/backend/alibaba/order/getOrderList', { params })

export const getOrderDetail = (params) =>
  request.get('/backend/alibaba/order/getOrderDetail', { params })
