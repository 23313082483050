import React, { useContext } from 'react'
import { GridContext } from '../../../../components'

interface Props {
  record: Object;
}

const Reviews = ({ record }: Props) => {
  const { toggle } = useContext(GridContext)
  const onReview = () => {
    toggle(record, 'review')
  }

  return (
    <>
      {record.comment_count > 1 ? (
        <span
          tabIndex="0"
          role="button"
          className="bbs-review"
          onClick={onReview}
          onKeyPress={() => {}}
        >
          {record.comment_count - 1}
        </span>
      ) : (
        record.comment_count - 1
      )}
    </>
  )
}
export default Reviews
