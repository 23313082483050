import { CutActivityGrid, CutShareGrid, Creatings } from '../pages'

const share = {
  path: '/share',
  primary: 'app.sider.share',
  routes: [
    {
      path: '/share/cut',
      modular: 'market',
      permission: 'share-cut',
      primary: 'app.sider.share.cut',
      exact: true,
      routes: [
        {
          path: '/share/cut/activity',
          modular: 'market',
          permission: 'cut/activity/get-list',
          primary: 'app.sider.share.cut.activity',
          component: CutActivityGrid,
          exact: true
        },
        {
          path: '/share/cut/share',
          modular: 'market',
          permission: 'cut/share/get-list',
          primary: 'app.sider.share.cut.share',
          component: CutShareGrid,
          exact: true
        }
      ]
    },
    {
      path: '/share/group-buy',
      modular: 'market',
      permission: 'share-group-buy',
      primary: 'app.sider.share.group-buy',
      component: Creatings,
      exact: true
    }
  ]
}
export default share
