import React from 'react'
import { Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { getStaticText } from '../../utils'

const yearGroupColumns = [
  {
    title: (
      <div>
        {getStaticText('daily.sales.table.country')}
        &nbsp;
        <Tooltip
          title={() => (
            <div className="toolTipStyle">
              <p>
                {getStaticText('component.remarks.data.source_note')}
                {getStaticText(
                  'component.remarks.data.source.system.generation_note'
                )}
              </p>
            </div>
          )}
          key={getStaticText('daily.sales.table.country')}
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </div>
    ),
    dataIndex: 'country',
    key: 'country',
    fixed: 'left',
    width: 100,
    align: 'center',
    render: (data) => (getStaticText(data) ? getStaticText(data) : data)
  },
  {
    title: <div>{getStaticText('echarts.year.people_group_amount_a')}</div>,
    dataIndex: 'people_group_amount_a',
    key: 'people_group_amount_a',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('echarts.year.people_group_amount_i')}</div>,
    dataIndex: 'people_group_amount_i',
    key: 'people_group_amount_i',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('echarts.year.people_group_amount_p')}</div>,
    dataIndex: 'people_group_amount_p',
    key: 'people_group_amount_p',
    fixed: 'left',
    width: 100,
    align: 'center'
  },
  {
    title: <div>{getStaticText('echarts.year.people_group_amount_l')}</div>,
    dataIndex: 'people_group_amount_l',
    key: 'people_group_amount_l',
    fixed: 'left',
    width: 100,
    align: 'center'
  }
]

export default yearGroupColumns
