/* eslint-disable */
import React, { useEffect, useState } from 'react'
import {Modal, Row, Col, Select, Card, Divider, Form, Button} from 'antd'
import {CalculatorOutlined, SearchOutlined} from '@ant-design/icons'
import BlackButton from '../ButtonComponents/BlackButton'
import WhiteButton from '../ButtonComponents/WhiteButton'
import GrayButton from '../ButtonComponents/GrayButton'
import SuffixInput from '../inputComponent/SuffixInput'
import SuffixInputTitleNoBorder from '../inputComponent/SuffixInputTitleNoBorder'
import RegularInput from '../inputComponent/RegularInput'
import RegularSelectInput from '../inputComponent/RegularSelectInput'
import LabelInput from '../inputComponent/LabelInput'
import RadioWithTitle from '../inputComponent/RadioWithTitle'
import RegularSwitch from '../inputComponent/RegularSwitch'
import RegularTitleNoBorderInput from '../inputComponent/RegularTitleNoBorderInput'
import DoubleInputDoubleUnit from '../inputComponent/DoubleInputDoubleUnit'
import { sendCreatePricing } from '../../../services/1688Projects/exclusive'
import {calculateSellingPriceTemplate} from "../../../services/1688Projects/collection";

interface Props {
  setOpen: Function;
  open: Boolean;
  getPricingData: Function;
  setPageNow: Function;
}


const ModalPricingTemplate = ({ open, setOpen, getPricingData, setPageNow, loading }: Props) => {
  const [buttonOpen, setButtonOpen] = useState(false)
  const [dataForm, setDataForm] = useState({})
  const [form_calculator] = Form.useForm();
  const [calculationResult, setCalculationResult] = useState({
    "profit_amount": 0,
    "absolute_value_of_vat": 0,
    "exchange_fee": 0,
    "entity_fee": 0,
    "selling_price": 0
  })


  const handleButton = () => {
    setButtonOpen((prev) => !prev)
  }
  const handleCreate = async () => {
    try {
      const dataPayload = dataForm
      const dataDiscount = dataForm?.discount?.replace("%","") || null
      if (dataDiscount && dataDiscount?.length > 0) {
        dataPayload.discount = `${dataDiscount}%`
      }
      const response = await sendCreatePricing(dataPayload)
      await getPricingData()
      setPageNow(1)
      setOpen(false)
    } catch (e) {
      console.log(e)
    }
  }
  const FooterButton = () => {
    return (
      <Row gutter={[10, 10]}>
        <Col span={6} />
        <Col span={6}>
          <GrayButton title="Selling price trial calculation" />
        </Col>
        <Col span={3}>
          <WhiteButton title="Cancel" functionParent={() => setOpen(false)} />
        </Col>
        <Col span={3}>
          <BlackButton loading={loading} functionParent={handleCreate} title="Create" />
        </Col>
        <Col span={6} />
      </Row>
    )
  }
  const ButtonExtra = () => {
    if (buttonOpen) {
      return (
        <GrayButton
          icon={<CalculatorOutlined />}
          title="Selling price calculation"
          functionParent={handleButton}
        />
      )
    }
    return (
      <BlackButton
        icon={<CalculatorOutlined />}
        title="Selling price calculation"
        functionParent={handleButton}
      />
    )
  }
  const TitleCard = () => {
    return (
      <div
        style={{
          fontSize: '32px',
          fontWeight: 'bold'
        }}
      >
        Create a Pricing Template
      </div>
    )
  }

  async function handleCalculatePrice(){
    let formCalculator = form_calculator.getFieldsValue();
    let result = await calculateSellingPriceTemplate(formCalculator);
    setCalculationResult(result)
  }

  function handleResetButton(){
    form_calculator.resetFields()
    setCalculationResult({
      "profit_amount": 0,
      "absolute_value_of_vat": 0,
      "exchange_fee": 0,
      "entity_fee": 0,
      "selling_price": 0
    })
  }
  return (
    <Modal
      closable={false}
      centered
      open={open}
      onOk={() => setOpen(false)}
      width={1800}
      footer={<FooterButton />}
    >
      <Row gutter={[10, 10]}>
        <Col span={18}>
          <Card extra={<ButtonExtra />} title={<TitleCard />}>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <div
                  style={{
                    width: '100%',
                    background: '#DADADA',
                    fontSize: '15px',
                    padding: '10px',
                    fontWeight: 500
                  }}
                >
                  Basic Information
                </div>
              </Col>
              <Col span={24}>
                <SuffixInput
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, template_name: e }))}
                  title="Template Name"
                  style={{ width: '129px' }}
                  size="large"
                  placeHolder="Please enter template name"
                  colon
                  space="11px"
                  suffix="0/40"
                />
              </Col>
              <Col span={24}>
                <SuffixInputTitleNoBorder
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, exchange_rate: e }))}
                  title="Exchange Rate 1CNY (RMB)"
                  style={{ width: '293px' }}
                  size="large"
                  placeHolder="Please enter template name"
                  colon
                  space="9px"
                  suffix="CNY"
                />
              </Col>
              <Col span={12}>
                <RegularSelectInput
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, site: e }))}
                  title="Site"
                  style={{ width: '129px' }}
                  size="large"
                  placeHolder="Please select site"
                  colon
                  space="91px"
                  options={[
                    {
                      label: 'Nigeria',
                      value: 'Nigeria'
                    },
                    {
                      label: 'Pakistan',
                      value: 'Pakistan'
                    },
                    {
                      label: 'Kenya',
                      value: 'Kenya'
                    },
                    {
                      label: 'Ghana',
                      value: 'Ghana'
                    },
                    {
                      label: 'Moroco',
                      value: 'Moroco'
                    }
                  ]}
                />
              </Col>
              <Col span={12} />
              <Col span={24} style={{ height: '50px' }} />
              <Col span={24}>
                <Row
                  style={{
                    width: '100%',
                    background: '#DADADA',
                    fontSize: '15px',
                    padding: '10px',
                    fontWeight: 500
                  }}
                  align="middle"
                >
                  <Col span={16}>Pricing Parameters</Col>
                  <Col span={4}>
                    <Select
                      defaultValue="1"
                      bordered={false}
                      style={{ width: '100%' }}
                    >
                      <Select.Option value="1">
                        Chinese Yuan (CNY)
                      </Select.Option>
                    </Select>
                  </Col>
                  <Col span={4}>
                    <Select
                      defaultValue="1"
                      bordered={false}
                      style={{ width: '100%' }}
                    >
                      <Select.Option value="1">Kilogram (Kg)</Select.Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col span={24} style={{ paddingLeft: '10px' }}>
                <LabelInput title="Profit Information" />
              </Col>
              <Col span={12}>
                <RegularInput
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, discount: e }))}
                  title="Discount"
                  style={{ width: '129px' }}
                  space="26px"
                  placeHolder="Please enter value between 1-9,99"
                  colon
                />
              </Col>
              <Col span={12}>
                <SuffixInput
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, profit_rate: isNaN(Number(e)) ? null : Number(e) }))}
                  title="Profit Rate"
                  style={{ width: '129px' }}
                  size="large"
                  placeHolder="Please enter template name"
                  colon
                  space="47px"
                  suffix="%"
                />
              </Col>
              <Col span={24} style={{ paddingLeft: '10px' }}>
                <LabelInput title="Cost Information" />
              </Col>
              <Col span={24}>
                <RadioWithTitle
                  span={4}
                  title="Shipping Fee (hidden price)"
                  options={[
                    {
                      label: 'Based on first weight',
                      value: 1
                    },
                    {
                      label: 'Flat shipping fee',
                      value: 2
                    }
                  ]}
                />
              </Col>
              <Col span={3} />
              <Col span={4}>
                <RegularSwitch
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, volumetric_weight: e === true ? 1 : 0 }))}
                  title="Volumetric weight"
                />
              </Col>
              <Col span={6} />
              <Col span={11}>
                <RegularTitleNoBorderInput
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, light_throw_coefficient: isNaN(Number(e)) ? null : Number(e) }))}
                  title="Light throw coefficient"
                  placeHolder="Enter Value"
                  style={{ width: '269px' }}
                  colon
                  space="7px"
                />
              </Col>
              <Col span={3} />
              <Col span={10}>
                <DoubleInputDoubleUnit
                  callback={(e) => setDataForm((prevState) => ({  ...prevState,first_weight_price: isNaN(Number(e.one)) ? null : Number(e.one) , first_weight_type: isNaN(Number(e.two)) ? null : Number(e.two)}))}
                  title="First Weight Price"
                  placeHolder1="Enter value"
                  placeHolder2="Enter value"
                  unit1="CNY"
                  unit2="KG"
                  colon
                  style={{ width: '151px' }}
                  widthTitle="221px"
                  space="12px"
                />
              </Col>
              <Col span={1} />
              <Col span={10}>
                <DoubleInputDoubleUnit
                  callback={(e) => setDataForm((prevState) => ({  ...prevState,renewal_price:isNaN(Number(e.one)) ? null : Number(e.one), renewal_type: isNaN(Number(e.two)) ? null : Number(e.two) }))}
                  title="Renewal Price"
                  placeHolder1="Enter value"
                  placeHolder2="Enter value"
                  unit1="CNY"
                  unit2="KG"
                  colon
                  style={{ width: '151px' }}
                  widthTitle="221px"
                  space="12px"
                />
              </Col>
              <Col span={24}>
                <RadioWithTitle
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, weight_value: e }))}
                  span={4}
                  title="Weight value"
                  options={[
                    {
                      label: 'SKU Weight',
                      value: 1
                    },
                    {
                      label: 'Product Package Weight',
                      value: 2
                    }
                  ]}
                />
              </Col>
              <Col span={12}>
                <SuffixInput
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, platform_commission_rate: isNaN(Number(e)) ? null : Number(e) }))}
                  title="VAT %"
                  style={{ width: '142px' }}
                  size="large"
                  placeHolder="Please enter value"
                  colon
                  space="63px"
                  suffix="%"
                />
              </Col>
              <Col span={12}>
                <SuffixInput
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, withdrawal_fee: isNaN(Number(e)) ? null : Number(e) }))}
                  title="Withdrawal Fee"
                  style={{ width: '349px' }}
                  size="large"
                  placeHolder="Please enter value"
                  colon
                  space="85px"
                  suffix="%"
                />
              </Col>
              <Col span={12}>
                <SuffixInput
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, expert_commission_rate: isNaN(Number(e)) ? null : Number(e) }))}
                  title="Entity Fee %"
                  style={{ width: '142px' }}
                  size="large"
                  placeHolder="Please enter value"
                  colon
                  space="21px"
                  suffix="%"
                />
              </Col>
              <Col span={12}>
                <SuffixInput
                  callback={(e) => setDataForm((prevState) => ({  ...prevState, other_fee: isNaN(Number(e)) ? null : Number(e) }))}
                  title="Other Fee"
                  style={{ width: '349px' }}
                  size="large"
                  placeHolder="Please enter value"
                  colon
                  space="121px"
                  suffix="CNY"
                />
              </Col>
            </Row>
          </Card>
        </Col>
        {buttonOpen ? (
          <Col span={6}>
            <Form form={form_calculator} onFinish={ async()=>{
              await handleCalculatePrice()}}>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Divider plain>Input Parameter</Divider>
              </Col>
              <Col span={24}>
                <SuffixInput
                  title="Product Price"
                  style={{ width: '256px' }}
                  size="large"
                  placeHolder=""
                  colon
                  space="0px"
                  suffix="CNY"
                  name={"price"}
                />
              </Col>
              <Col span={24}>
                <SuffixInput
                  title="Weight"
                  style={{ width: '256px' }}
                  size="large"
                  placeHolder=""
                  colon
                  space="0px"
                  suffix="Kg"
                  name={"product_weight"}
                />
              </Col>
              <Col span={24}>
                <SuffixInput
                  title="Product Price per Weight"
                  style={{ width: '256px' }}
                  size="large"
                  placeHolder=""
                  colon
                  space="0px"
                  suffix="Kg"
                  name={"product_price_per_weight"}
                />
              </Col>
              <Col span={24}>
                <SuffixInput
                  title="Logistics Service Fee"
                  style={{ width: '256px' }}
                  size="large"
                  placeHolder=""
                  colon
                  space="0px"
                  suffix="CNY"
                  name={"logistics_service_fee"}
                />
              </Col>
              <Col span={24}>
                <SuffixInput
                  title="Profit Margin"
                  style={{ width: '256px' }}
                  size="large"
                  placeHolder=""
                  colon
                  space="0px"
                  suffix="%"
                  name={"profit_margin"}
                />
              </Col>
              <Col span={24}>
                <SuffixInput
                  title="VAT Percentage"
                  style={{ width: '256px' }}
                  size="large"
                  placeHolder=""
                  colon
                  space="0px"
                  suffix="%"
                  name={"vat_percentage"}
                />
              </Col>
              <Col span={24}>
                <SuffixInput
                  title="Exchange Rate"
                  style={{ width: '256px' }}
                  size="large"
                  placeHolder=""
                  colon
                  space="0px"
                  suffix="%"
                  name={"exchange_rate"}
                />
              </Col>
              <Col span={24}>
                <SuffixInput
                  title="Entity Fee Percentage"
                  style={{ width: '256px' }}
                  size="large"
                  placeHolder=""
                  colon
                  space="0px"
                  suffix="%"
                  name={"entity_fee_percentage"}
                />
              </Col>
              <Col span={24}>
                <Row gutter={[10, 10]} style={{
                  display : "flex",
                  flexDirection : "row",
                  justifyContent : "right"
                }}>
                  <Col >
                    <Button size="large" onClick={()=>{handleResetButton()}}>Reset</Button>
                  </Col>
                  <Col>
                    <Form.Item >
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        style={{ background: 'black', color: 'white' }}
                      >
                        Calculate
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Divider plain>Calculation Result</Divider>
              </Col>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div>Product Selling Price</div>
                <div>{calculationResult.selling_price}</div>
              </Col>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div>Product Profit</div>
                <div>{calculationResult.profit_amount}</div>
              </Col>
              <Col span={24}>
                <Divider plain>Detailed Fee</Divider>
              </Col>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div>VAT</div>
                <div>{calculationResult.absolute_value_of_vat}</div>
              </Col>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div>Exchange Fee</div>
                <div>{calculationResult.exchange_fee}</div>
              </Col>
              <Col
                span={24}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <div>Entity Fee</div>
                <div>{calculationResult.entity_fee}</div>
              </Col>
            </Row>
            </Form>
          </Col>
        ) : (
          <Col span={6}>
            <Row>
              <Col span={24}>
                <p
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    marginBottom: 'unset'
                  }}
                >
                  Selling price calculation formula
                </p>
                <p style={{ fontSize: '15px' }}>
                  Calculate selling price based on
                  <br />
                  [selling price profit margin]
                </p>
              </Col>
              <Col span={24}>
                <p style={{ fontSize: '15px' }}>
                  Product selling price = [Product purchase price + freight
                  (hidden price) + other expenses] / [(1-profit rate-handling
                  rate) × discount rate]
                </p>
              </Col>
              <Col span={24}>
                <p style={{ fontSize: '15px' }}>
                  Calculate selling price based on [cost profit rate] Product
                  selling price = [Product purchase price × (1 + profit margin)
                  + freight (hidden price) + other charges] / [(1-handling rate)
                  × discount rate]
                </p>
              </Col>
              <Col span={24}>
                <p style={{ fontSize: '15px' }}>
                  Calculate selling price based on [fixed profit] Product
                  selling price = [Product purchase price + profit + freight
                  (hiding price) + other expenses] / [(1-handling rate) ×
                  discount rate]
                </p>
              </Col>
              <Col span={24}>
                <p
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    marginBottom: 'unset'
                  }}
                >
                  Product purchase price
                </p>
                <p style={{ fontSize: '15px' }}>
                  1. When publishing global products to the store, you can
                  choose to use the source price or the global product price for
                  the purchase price.
                </p>
              </Col>
              <Col span={24}>
                <p style={{ fontSize: '15px' }}>
                  2. When publishing products in ordinary stores, purchase price
                  system - use source price
                </p>
              </Col>
              <Col span={24}>
                <p
                  style={{
                    fontSize: '15px',
                    fontWeight: 'bold',
                    marginBottom: 'unset'
                  }}
                >
                  Handling fee
                </p>
                <p style={{ fontSize: '15px' }}>
                  Including: platform commission rate, withdrawal fee, expert
                  commission rate
                </p>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Modal>
  )
}

export default ModalPricingTemplate
