import React from 'react'
import ActivityAssess from './ActivityAssess'
import PpShop from './PpShop'

function PeopleResult(props) {
  return (
    <>
      <tbody>
        <ActivityAssess {...props} />
        <PpShop {...props} />
      </tbody>
    </>
  )
}
export default PeopleResult
