import services, {
  withTableLoading,
  withGlobalLoading
} from '../../../services'

export const getRoleList = (handler) =>
  withTableLoading((params) => services.rbac.getRoleList(params).then(handler))

export const getRoleDetail = (handler) =>
  withGlobalLoading((id) => services.rbac.getRoleDetail({ id }).then(handler))

export const deleteRole = (handler) =>
  withGlobalLoading((id) => services.rbac.deleteRole({ id }).then(handler))

export const getPermissionNodes = (handler) =>
  withGlobalLoading(() => services.rbac.getPermissionNodes().then(handler))

export const createRole = (handler) =>
  withGlobalLoading((params) => services.rbac.createRole(params).then(handler))

export const updateRole = (handler) =>
  withGlobalLoading((params) => services.rbac.saveRole(params).then(handler))
